import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Form, useActionData, useLoaderData, useLocation, useSearchParams } from "@remix-run/react";
import { sdk } from "@tamarack/sdk";
import type { ErrorResponse } from "@tamarack/shared/errors";
import { getErrorMessage } from "@tamarack/shared/errors";
import LoginForm from "@tamarack/ui/LoginForm";
import { useEnv } from "../env";
import {
  createUserSession,
  deleteSessionCookieHeaders,
  getSession,
  logout,
} from "../sessions.server";
import { useNavigation } from "../utils/hooks";
import { REDIRECT_TO_KEY, getTenantFromUrl, loaderSearchParams } from "../utils/url";
import { validateLogin } from "../utils/validations";
import { responseError } from "@tamarack/shared/errors";

type TenantLoaderData = {
  tenantId?: string;
  tenantName?: string;
  error?: string;
};

type ActionData = {
  error?: string;
};

export async function loader({ request }: LoaderFunctionArgs) {
  let tenantName = getTenantFromUrl(request.url);
  const headers = await deleteSessionCookieHeaders(request);

  try {
    if (!tenantName) {
      return json<TenantLoaderData>({}, { headers });
    }

    const session = await getSession(request);
    const api = sdk(session);

    const tenant = (await api.getTenantByName({ name: tenantName })).value;

    if (!tenant) {
      return json({ error: `Invalid tenant name ${tenantName}` }, { headers });
    }

    return json<TenantLoaderData>(
      {
        tenantId: tenant.id,
        tenantName,
      },
      { headers }
    );
  } catch (e: any) {
    switch (e.response?.status ?? e.status) {
      case 404: {
        return responseError(`Could not find tenant: ${tenantName}`, e);
      }

      // Expired token probably
      case 401: {
        return logout(request);
      }

      // Unknown
      default: {
        throw new Response(e.response?.statusText ?? e.message, {
          status: 400,
        });
      }
    }
  }
}

export async function action({ request }: ActionFunctionArgs) {
  const session = await getSession(request);
  const api = sdk(session, {}, request.headers);

  const url = new URL(request.url);
  const searchParams = loaderSearchParams(request);

  const redirectTo = searchParams.get(REDIRECT_TO_KEY) ?? "/";
  const body = await request.formData();
  const subdomain = body.get("subdomain");

  // Entered tenant subdomain
  if (subdomain) {
    try {
      const tenant = (
        await api.getTenantByName({
          name: subdomain as string,
        })
      ).value;

      if (!tenant) {
        return { error: `Invalid subdomain ${subdomain}` };
      }

      const redirectUrl = `${url.protocol}${tenant.name}.${url.host}${url.pathname}${url.search}`;

      return redirect(redirectUrl, 302);
    } catch (e) {
      throw { error: "Invalid subdomain" };
    }
  }

  ///// Loggin in

  const bodyObject = Object.fromEntries(body.entries());

  // Validate data
  try {
    await validateLogin(bodyObject);
  } catch (e: any) {
    return json<ActionData>({ error: "Invalid username or password" });
  }

  try {
    const authenticateResponse = await api.createAuthenticationToken({
      tenantId: bodyObject.tenantId as string,
      username: bodyObject.username as string,
      password: bodyObject.password as string,
    });

    return createUserSession({
      request,
      accessToken: authenticateResponse.accessToken,
      remember: true,
      redirectTo,
      trustedUserToken: authenticateResponse.trustedUserToken,
    });
  } catch (e: any) {
    return json<ActionData>(
      { error: getErrorMessage("Invalid username or password", e) },
      { status: e.status ?? 500 }
    );
  }
}

export default function LoginPage() {
  const data = useLoaderData<TenantLoaderData>();
  const actionData = useActionData<ErrorResponse>();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const env = useEnv();

  const isSubmitting = navigation.submitting || navigation.loading;
  const redirectTo = searchParams.get(REDIRECT_TO_KEY);

  return (
    <LoginForm
      tenantId={data.tenantId}
      tenantName={data.tenantName}
      error={data.error ?? actionData?.error}
      loading={isSubmitting}
      testUsername={env.TEST_USERNAME}
      testPassword={env.TEST_PASSWORD}
      testTenant={env.TEST_TENANT}
      Form={Form}
      redirectTo={redirectTo}
      pathname={location.pathname}
      search={location.search}
    />
  );
}
