import { twMerge } from "tailwind-merge";

type WideForestProps = {
  fill?: string;
  className?: string;
};

export default function WideForest({ fill, className }: WideForestProps) {
  return (
    <div
      className={twMerge(
        "ranslate-y-[15px] width-[calc(100%+20px)] absolute bottom-[-80px] left-1/2 z-[-1] w-[calc(100%+50px)] -translate-x-1/2",
        className
      )}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 2592 1058"
        enableBackground="new 0 0 2592 1058"
        xmlSpace="preserve"
      >
        <path
          className="fill-tm-primary"
          fill={fill}
          d="M2473.711,16.36c0.376,0.196,1.118,0.58,1.493,0.777c0.495-0.265,1.485-0.785,1.98-1.041
	c-0.179,0.299-0.537,0.887-0.717,1.186c-0.171,2.679-0.504,5.342-1.101,7.953c1.894,0.171,4.173,0.026,5.111,2.142
	c2.227-1.28,4.079-3.106,6.093-4.71c-0.375,3.046-2.142,7.364,1.331,9.31c-3.371,4.54-4.557,10.53-8.832,14.396
	c-0.316,3.635-3.311,5.581-5.982,7.509c-0.435,1.365-0.905,2.722-1.297,4.079c1.109-0.009,2.244-0.034,3.413-0.051
	c0.913-3.439,2.475-6.647,4.267-9.719c1.306,1.835,0.64,3.985,0.103,5.973c0.444-0.128,1.34-0.375,1.783-0.503
	c-3.031,6.308-2.902,6.024-3.072,7.799c0.657-0.333,1.34-0.674,2.056-1.007c0.171-0.683,0.495-2.039,0.657-2.722
	c0.35,0.708,1.058,2.116,1.408,2.825c0.452-0.256,1.365-0.751,1.817-1.007c-1.587,2.799-3.328,5.572-3.994,8.764
	c3.661-0.111,5.99-3.14,9.029-4.71c-0.973,2.099-2.227,4.045-3.61,5.888c-0.068,1.152-0.094,2.313-0.077,3.473
	c1.548-0.793,5.801-3.206,9.48-3.814c-0.307,1.263-0.964,2.372-1.604,3.465c-0.632-0.026-1.886-0.068-2.509-0.094
	c-0.333,0.41-0.999,1.229-1.331,1.638c-0.503,0.043-1.51,0.128-2.022,0.171c-3.301,2.715-16.976,19.837-17.664,20.838
	c-3.405,2.611-5.905,6.195-7.894,9.95c1.971,0.265,3.806-0.324,5.265-1.724c0.196,0.171,0.597,0.495,0.802,0.666
	c1.271-1.297,2.594-2.577,4.019-3.755c-2.253,3.703-4.898,7.134-7.467,10.607c1.98-0.307,3.959-0.683,5.965-1.024
	c0.358-1.57,1.485-1.596,2.807-0.973c2.987-2.321,4.25-6.699,8.2-7.697c0.819,2.816,2.338,5.367,4.514,7.356
	c0.23-2.543-0.119-5.333,1.562-7.484c0.265,1.092,0.555,2.193,0.862,3.294c0.273-0.623,0.811-1.886,1.084-2.517
	c-0.068,2.799-1.271,5.393-1.485,8.166c0.623,1.579,1.988,2.953,3.806,2.244c1.126,0.137,2.27,0.282,3.465,0.427
	c0.87-3.721,1.946-7.433,4.071-10.658c0.068,1.118,0.17,2.236,0.307,3.354c-2.603,4.241-3.183,9.25-4.13,14.02
	c0.956-0.068,1.954-0.137,2.978-0.205c1.502-3.482,3.43-6.81,4.25-10.547c1.374-1.545,2.858-2.995,4.531-4.233
	c-0.034,1.203,0,2.406,0,3.618c-1.937,2.697-4.207,5.427-4.25,8.926c1.459-0.691,2.944-1.391,4.446-2.074
	c-0.094-0.299-0.281-0.905-0.375-1.203c1.621-0.922,3.243-1.92,4.429-3.43c1.622-0.486,3.26-0.913,4.958-1.263
	c-1.698,1.971-3.524,3.806-5.368,5.606c0.23,0.725,0.487,1.442,0.777,2.167c1.075-0.324,2.142-0.64,3.277-0.956
	c0.034,0.657,0.094,1.954,0.128,2.611c0.776,0.333,1.587,0.666,2.423,1.007c0.256,2.287,0.631,4.565,1.092,6.835
	c0.99-0.734,1.988-1.468,3.038-2.193c0.631-3.652,0.7-8.32,4.463-10.283c-0.401,2.679-1.058,5.444-0.111,8.098
	c1.86-0.213,3.712,0.034,5.555,0.486c1.971-1.963,1.374-4.804,1.425-7.313c0.879,0.546,1.826,1.092,2.782,1.621
	c1.331-0.998,2.927-1.459,4.565-1.835c-0.136-0.299-0.418-0.887-0.563-1.186c0.393-0.171,1.178-0.503,1.57-0.666
	c-0.632-1.536-1.203-3.063-1.775-4.591c0.546,0.06,1.63,0.196,2.176,0.265c-0.751-1.306-1.459-2.603-2.142-3.908
	c1.519,0.384,3.046,0.785,4.608,1.178c-0.341-1.101-0.64-2.202-0.913-3.302c1.297,1.195,2.492,2.5,3.652,3.857
	c0.443,0.077,1.323,0.23,1.766,0.307c1.254,2.987,3.874,5.111,5.137,8.124c1.527,0.495,2.995,1.22,4.395,2.074
	c0.042-1.579,0.102-3.166,0.188-4.745c-1.792-0.93-3.567-1.946-4.668-3.661c0.845-0.102,2.526-0.29,3.371-0.393
	c-0.393-0.742-0.76-1.476-1.101-2.21c1.493-0.444,3.004-0.913,4.54-1.391c-0.734-1.058-1.476-2.108-2.167-3.149
	c0.572,0.017,1.732,0.051,2.304,0.068c-0.29-0.367-0.862-1.118-1.152-1.493c0.35-0.299,1.058-0.896,1.408-1.195
	c0.862,0.418,1.758,0.836,2.688,1.254c-0.017-1.297,0-2.603,0.051-3.9c0.316,0.247,0.939,0.742,1.254,0.998
	c0.845-0.683,1.749-1.357,2.671-2.014c0.315-0.964,0.674-1.929,1.075-2.884c-0.393-0.316-1.178-0.947-1.57-1.271
	c-0.034-0.529-0.094-1.596-0.128-2.133c1.272,0.137,3.354,1.587,4.071-0.179c-0.452-1.408-1.118-2.731-1.681-4.07
	c-0.708-0.128-2.133-0.384-2.841-0.512c0.93-1.442,2.679-1.374,4.224-1.69c-0.76-1.314-1.485-2.628-2.185-3.951
	c1.144-0.367,2.321-0.717,3.541-1.05c-1.347-3.31-0.244-1.847-4.045-7.706c0.23-0.375,0.683-1.109,0.905-1.476
	c-0.299-0.427-0.905-1.263-1.203-1.681c0.452-0.777,0.93-1.545,1.442-2.321c2.099-0.734,4.25-1.365,6.409-2.014v119.518
	l-0.435,0.205l0.051,0.401l0.384-0.145v1.766c-1.493-0.657-2.552-1.818-3.175-3.277c-1.911,0.324-3.652,1.075-5.222,2.15
	c-0.546,2.355-1.775,5.111,0.111,7.202c0.674,0.367,2.031,1.092,2.705,1.451c-0.93,0.282-2.116,0.521-2.944,0.768
	c-0.009,0.333-0.026,0.981-0.043,1.306c0.734-0.094,1.562-0.179,2.364-0.265c2.569-2.176,4.821-4.762,6.204-7.876v18.219
	c-0.068-0.649-0.196-1.954-0.265-2.603c-3.959,0.051-1.195,3.132-0.572,4.719c-1.186,0.529-2.347,1.033-3.413,1.664
	c0.913,1.792,2.287,3.354,4.25,4.079v25.779c-2.261-0.256-2.372,3.354,0,2.381v10.377c-2.338-0.998-2.244,2.876,0,1.596v131.669
	c-2.654,2.449-4.258,5.675-5.965,8.781c-0.435-1.459-0.043-2.91,0.469-4.284c-1.579,1.638-3.106,3.319-4.608,5.001
	c-1.041-0.427-2.057-0.845-3.046-1.246c2.51,5.77,2.812,1.813,13.15,8.252v7.322c-1.271-0.614-2.176-0.375-2.731,0.734
	c0.589,1.058,1.502,1.254,2.731,0.597v8.422c0,0-17.552,9.138-19.166,9.856c1.485-1.314,3.047-2.594,4.344-4.13
	c-0.623-0.051-1.877-0.154-2.5-0.205c-1.425,0.768-2.91,1.434-4.429,1.869c3.976-2.901,8.635-4.582,13.141-6.46
	c0.026-0.614,0.068-1.835,0.094-2.449c-5.931,0.649-11.656,2.458-17.459,3.729c1.092-0.87,2.253-1.707,3.43-2.526
	c-0.887-0.162-2.671-0.469-3.558-0.623c1.843-0.307,3.712-0.435,5.624-0.546c0-0.393,0-1.169,0-1.562
	c-2.304-0.009-4.591-0.017-6.852-0.017c0-0.418,0.017-1.271,0.026-1.69c3.925-0.017,7.868-0.009,11.819-0.009
	c-0.213-0.452-0.648-1.357-0.87-1.818c4.079,0.657,9.062,2.321,12.646-0.563c-5.564-0.648-10.717,1.828-21.461-3.422
	c0.589-0.06,1.766-0.188,2.355-0.247c-0.248-0.282-0.751-0.845-0.999-1.126c1.442-1.399,2.799-2.893,4.147-4.403
	c-1.084-0.905-2.185-1.775-3.268-2.603c-0.026-0.546-0.077-1.638-0.103-2.185c-0.998,0.717-2.005,1.434-2.961,2.133
	c1.399-2.62,3.149-5.035,4.582-7.646c-1.143,0.435-2.253,0.87-3.328,1.323c0,0.631-0.017,1.886-0.026,2.517
	c-0.469,0.196-1.408,0.597-1.877,0.802c0.512-1.801,1.084-3.584,1.63-5.376c-2.509,2.654-4.89,5.444-7.731,7.731
	c2.44-4.258,2.219-9.19,1.86-13.918c-2.125,1.399-2.347,4.053-3.03,6.272c0.785,1.562,1.408,3.2,1.784,4.941
	c-1.306-1.05-2.543-2.15-3.781-3.226c0.211,2.232,0.51,4.088,1.084,6.477c-1.067-1.297-2.219-2.534-3.755-3.209
	c0.307,1.05,0.649,2.099,1.041,3.149c-0.384-0.009-1.152-0.034-1.536-0.043c-0.512-2.705-0.981-5.419-1.297-8.149
	c-2.62,0.802-0.759,3.652-0.648,5.487c-0.401,0.034-1.195,0.085-1.596,0.119c-0.077,1.801-0.154,3.601-0.256,5.402
	c-2.654-2.961-1.314-7.1-0.606-10.539c-0.367,0.009-1.109,0.017-1.476,0.026c-0.7,4.13-0.256,8.798-2.637,12.416
	c-0.537-4.369-0.196-8.764,0.751-13.039c-4.839,3.123-3.055,10.607-7.979,13.79c0.512-1.545,1.152-3.055,1.834-4.548
	c-0.546-1.143-1.101-2.278-1.596-3.405c-0.64,0.879-1.263,1.766-1.852,2.645c0.358-6.852,4.787-12.749,5.581-19.533
	c-3.652,3.396-4.676,8.619-7.194,12.8c1.715,1.237,0.077,2.415-0.717,3.524c-1.74-4.958,2.355-9.054,3.439-13.628
	c-4.847,1.963-4.599,8.397-8.917,11.034c0.538-1.621,1.237-3.2,1.954-4.77c-0.444,0.051-1.34,0.154-1.783,0.205
	c1.033-1.946,5.367-8.764,6.528-10.342c-2.321,0.879-4.369,2.253-6.374,3.652c-0.967,12.55-10.473,21.097-13.082,25.327
	c-0.589-0.009-1.783-0.034-2.372-0.043c1.664-1.988,3.669-3.814,4.583-6.323c-2.466,2.415-4.523,5.257-7.475,7.117
	c-0.478,0.051-1.434,0.137-1.912,0.179c-1.57,1.638-3.175,3.234-4.898,4.685c0.742-3.362,3.669-5.547,6.477-7.202
	c0.529-0.947,1.058-1.894,1.63-2.842c0.358,0.222,1.067,0.666,1.425,0.887c2.259-2.136,8.811-9.972,10.82-14.967
	c-3.661,2.876-5.214,7.501-8.568,10.641c-0.154-0.179-0.444-0.538-0.589-0.717l-1.801-0.222c1.357-1.911,3.106-3.507,4.753-5.188
	c-0.58-0.316-1.732-0.939-2.313-1.246c4.193-2.036,4.372-1.513,11.102-11.145c-0.64,0-1.912-0.009-2.552-0.009
	c-3.593,3.763-9.131,5.658-11.426,10.539c-0.359-0.247-1.075-0.742-1.434-0.998c-3.208,2.082-5.965,6.34-10.231,5.419
	c-1.647,0.819-3.277,1.638-4.924,2.364c1.715-1.519,3.439-3.063,4.941-4.83c-2.534,0.905-4.941,2.116-7.484,2.953
	c3.26-2.475,6.912-4.352,10.419-6.46c0.359-1.357,0.726-2.714,1.126-4.062c-3.567,2.807-7.688,5.274-11.904,6.69
	c-0.171-0.256-0.512-0.777-0.683-1.041c-0.307,0.171-0.905,0.521-1.212,0.691c0.111-0.998,0.248-1.988,0.418-2.978
	c-1.357,0.751-2.705,1.502-4.036,2.202c2.321-3.712,6.733-4.599,10.266-6.707c-0.06-0.256-0.179-0.751-0.248-0.998
	c-3.78,0.205-7.885,1.579-11.196-0.964c1.451-0.119,2.969-0.179,4.489-0.239c0.358-0.41,1.067-1.246,1.417-1.655
	c0.555-0.009,1.664-0.026,2.219-0.034c0.017-0.41,0.06-1.229,0.085-1.63c2.799,1.323,5.598-0.179,7.33-2.483
	c-3.49,0.538-7.441-0.06-10.18,2.637c0.265-1.195,0.606-2.372,0.956-3.55c-4.715-0.053-15.961-2.458-17.408-3.123
	c1.75-0.87,3.669-1.033,5.555-0.41c-0.23-0.324-0.7-0.981-0.93-1.314c-1.493-0.427-2.961-0.862-4.352-1.425
	c0.418-1.468,2.065-1.178,3.277-1.476c1.126-0.811,2.261-1.638,3.413-2.475c1.98,1.28,4.25,1.818,6.613,2.022
	c-0.042-1.041-0.077-2.082-0.085-3.123c0.7-0.017,2.082-0.051,2.773-0.068c-0.597-2.893-3.294-0.87-4.881-0.503
	c-1.562-0.521-3.089-1.075-4.565-1.681c2.424-0.145,4.864-0.128,7.33-0.085c-0.017-0.495-0.051-1.493-0.06-1.988
	c-1.143,0.299-2.201,0.614-3.268,0.939c-0.29-0.384-0.853-1.161-1.135-1.553c0.742,0,2.227,0,2.969,0
	c-0.307-0.427-0.913-1.28-1.212-1.707c-0.614,0.017-1.826,0.043-2.432,0.06c0.469-1.092,0.99-2.185,1.502-3.277
	c1.169,0.273,2.338,0.546,3.567,0.819c0.026-0.657,0.06-1.963,0.085-2.62c-0.427-0.034-1.271-0.094-1.69-0.128
	c0.094-0.435,0.273-1.297,0.367-1.724c-1.476,0.299-2.944,0.597-4.386,0.836c1.323-1.118,2.969-1.655,4.676-2.014
	c-0.034-0.998-0.026-1.997,0.009-2.987c-0.589-0.111-1.767-0.341-2.364-0.452c2.867-2.415,6.775-2.671,10.325-3.268
	c-0.776-0.41-1.51-0.811-2.21-1.212c-4.216,1.323-8.645,1.417-12.937,2.261c1.212-3.746,5.538-2.091,8.406-3.465
	c-1.015-0.922-1.98-1.843-2.944-2.748c-0.128,0.486-0.401,1.476-0.538,1.963c-1.084,0.026-2.133,0.06-3.149,0.102
	c-2.133,1.715-4.301,3.413-6.69,4.727c1.971-2.918,4.164-5.709,6.903-7.962c-1.698-1.698-3.464,0.486-5,1.212
	c-0.904-0.247-1.783-0.486-2.628-0.725c0.938-0.802,2.295-1.297,2.884-2.475c-0.009-1.143-0.034-2.27-0.077-3.396
	c-0.717,0.162-2.15,0.478-2.867,0.64c0.521-0.495,1.57-1.476,2.091-1.971c-0.537-0.213-1.604-0.623-2.142-0.828
	c1.28-0.853,2.654-1.69,3.994-2.526c-0.256-0.717-0.469-1.425-0.649-2.125c-1.476,1.596-2.893,3.234-4.386,4.787
	c-0.58-0.478-1.126-0.956-1.63-1.434c1.126-1.254,2.261-2.551,3.345-3.874c-1.434-0.478-2.808-0.939-4.19-1.382
	c-1.508,2.548-1.687,3.508-3.644,5.905c-0.17-1.434-0.29-2.867-0.358-4.301c-4.258,2.159-7.33,5.7-10.24,9.361
	c0.009-0.674,0.025-2.031,0.025-2.705c-0.777,0.452-1.527,0.922-2.244,1.391c-0.29,1.937-0.811,3.823-1.519,5.649
	c-0.213-0.666-0.657-1.98-0.879-2.637c-0.708,1.067-1.408,2.15-2.056,3.226c-0.077-1.22-0.128-2.449-0.145-3.661
	c-0.359-0.213-1.084-0.632-1.442-0.845c-0.29,2.21,0.393,4.924-1.613,6.485c-0.154-1.34-0.256-2.671-0.316-3.994
	c-0.393-0.273-1.16-0.819-1.553-1.092c-0.051,0.631-0.171,1.911-0.23,2.551c-0.785,0.742-1.527,1.502-2.244,2.27
	c0.597-2.253,0.734-4.591,0.76-6.912c-0.444-0.333-1.34-0.998-1.784-1.331c0,0.708,0,2.116,0,2.816l-0.776-0.051
	c-0.111-1.365-0.205-2.722-0.29-4.079c-1.271,2.313-1.689,4.907-1.715,7.509l-0.563,0.009c0.009-2.688-0.111-5.367-0.469-8.03
	c-1.229,0.811-2.398,1.63-3.575,2.449c-0.359,0.845-0.691,1.698-0.999,2.543c-0.111-1.152-0.171-2.304-0.196-3.447
	c0.836-0.427,1.707-0.845,2.594-1.263c-0.222-0.384-0.683-1.169-0.905-1.553c0.367-0.853,0.76-1.707,1.178-2.551l-0.896-0.119
	c-1.039,3.118-0.503,1.532-1.954,4.719c-0.256-2.466,0.777-4.702,1.801-6.861c-1.212,0.375-2.372,0.828-3.473,1.34
	c1.195-1.57,0.358-2.987-0.905-4.147c-1.937,2.449-3.635,5.129-6.042,7.151c0.632-1.442,1.357-2.85,2.091-4.267
	c-3.268,1.766-5.291,4.915-8.141,7.159c0.58-1.493,1.271-2.944,1.997-4.386c-0.316-0.478-0.939-1.434-1.255-1.911
	c-2.389,2.082-2.552,5.146-2.039,8.081c-0.58,0.623-1.126,1.254-1.638,1.894c-0.461-1.306-0.896-2.594-1.297-3.891
	c-0.529,0.077-1.596,0.23-2.125,0.316c-0.623-1.485-1.212-2.953-1.783-4.429c-1.758,0.896-3.524,1.783-5.222,2.705
	c0.222-0.922,0.657-2.765,0.879-3.678c-0.478,0.316-1.416,0.956-1.894,1.271c1.365-3.772,0.674-7.791,0.785-11.699
	c-3.405,4.932-8.405,8.525-11.375,13.756c0.615,0.111,1.843,0.341,2.458,0.452c0.683,0.683,1.4,1.357,2.15,2.031
	c-0.93,1.519-1.834,3.046-2.705,4.582c-0.512,0.034-1.553,0.094-2.074,0.119c-0.341,0.384-1.024,1.152-1.365,1.536
	c-1.519,0.247-2.987,0.512-4.446,0.794c-0.068,0.555-0.213,1.664-0.282,2.219c0.973-0.717,1.937-1.442,2.952-2.176
	c1.306,0.375,2.611,0.785,3.926,1.289c-0.529,1.101-1.297,2.065-1.98,3.046c-2.057,0.017-4.079,0.196-6.024,0.751
	c-1.818-0.742-4.053-2.415-5.538-0.222c0.811,0.068,2.432,0.213,3.242,0.282c0.248,0.717,0.538,1.442,0.845,2.167
	c-2.637,0.29-1.425,2.287-0.87,3.883c1.263,0.06,2.56,0.145,3.891,0.239c-1.024,0.973-2.082,1.886-3.115,2.799
	c-1.553-1.527-3.063-3.089-4.523-4.685c-0.521,0-1.553-0.017-2.073-0.026c-0.99,1.058-1.954,2.099-2.902,3.157
	c0.888,2.33,2.432,4.847,0.999,7.305c0.222,0.324,0.674,0.973,0.896,1.297c-1.323,0.947-2.654,1.894-3.917,2.859
	c1.946,0.879,3.994,0.102,5.999-0.213c-0.427,1.596-1.22,3.038-2.15,4.378c1.323,0.205,2.713,0.427,4.087,0.657
	c-0.205-0.461-0.614-1.382-0.819-1.843c0.999-0.674,2.048-1.357,3.089-2.048c-0.819-0.077-2.475-0.247-3.302-0.333
	c0.384-0.768,0.794-1.519,1.229-2.27c1.203-0.128,2.466-0.247,3.72-0.384c-0.845-0.256-2.526-0.777-3.362-1.033
	c1.672-3.046,5.163-3.516,8.201-2.33c2.082-0.495,4.215-0.802,6.366-1.118c1.399,0.299,2.773,0.58,4.232,0.879
	c-0.981,0.862-1.937,1.715-2.876,2.577c0.973,0.444,1.963,0.887,2.978,1.348c-1.263,1.212-2.688,2.253-4.207,3.081
	c0.401,0.666,0.836,1.331,1.314,1.997c-1.092,1.365-1.835,3.379-3.567,3.977c-2.705-0.572-5.453-1.399-8.184-0.572
	c0.802,0.563,1.69,1.118,2.569,1.673c1.152-0.273,2.355-0.956,3.652-0.674c0.324,1.297-1.109,2.125-1.621,3.157
	c1.126,0.316,2.295,0.649,3.481,0.99c0.017,0.691,0.051,2.074,0.077,2.773c0.537-0.87,1.109-1.741,1.724-2.594
	c0.623,0.051,1.886,0.171,2.509,0.222c0.546-0.947,1.015-1.946,1.399-2.995c0.862,0.102,1.792,0.213,2.731,0.333
	c0.58-0.478,1.195-0.947,1.852-1.417c0.282,0.179,0.853,0.555,1.135,0.742c1.502-1.843,3.849-2.27,6.101-2.517
	c-0.452,1.246-0.981,2.458-1.826,3.473c-0.111,0.418-0.316,1.246-0.427,1.664c-0.376,0.35-1.144,1.041-1.519,1.382
	c-0.154,1.101-0.273,2.193-0.359,3.302c0.512-0.299,1.536-0.896,2.048-1.195c0.444,0.324,1.331,0.981,1.775,1.306
	c-1.519,2.133-3.311,4.045-5.495,5.478c-0.785-0.145-1.536-0.282-2.261-0.418c-0.691,2.193-0.563,4.727-1.98,6.622
	c-2.108-0.068-4.147-0.631-6.195-0.905c1.263,2.313,3.959,2.551,6.229,3.328c-2.364,1.724-4.881,3.234-7.057,5.18
	c1.374,1.033,2.876,1.946,4.369,2.859c0.409-0.922,0.845-1.835,1.314-2.748c0.384,0.179,1.152,0.529,1.536,0.708
	c0.034-0.538,0.094-1.613,0.128-2.15c1.092-0.418,2.236-0.828,3.388-1.246c0.41,0.282,1.237,0.845,1.656,1.135
	c-0.06,0.998-0.094,2.005-0.094,3.012c2.372-0.529,4.77-0.99,7.16-1.562c-0.435,1.434-0.862,2.859-1.271,4.292
	c-1.075,0.017-2.116,0.043-3.132,0.068c-0.298,0.358-0.887,1.067-1.177,1.417c-1.86,0.333-3.686,0.759-5.436,1.374
	c1.4,1.186,3.2,1.434,5.001,1.673c-0.316,0.307-0.956,0.922-1.271,1.22c-1.468,0.324-2.893,0.674-4.292,1.075
	c-1.86-1.058-3.661-2.176-5.436-3.311c-0.077,1.05-0.119,2.099-0.128,3.157c0.486,0.162,1.468,0.486,1.954,0.64
	c-1.434,0.759-2.884,1.476-4.292,2.193c0.521,1.63,1.084,3.268,1.604,4.915c-2.261,1.135-4.574,0.896-6.656-0.401
	c-1.809-1.348-2.756,1.161-3.814,2.142c-1.63-0.836-3.115-1.911-4.702-2.765c1.28,3.388,5.368,3.652,8.346,4.651
	c-0.103,0.597-0.307,1.792-0.401,2.389c0.794,0.068,2.398,0.222,3.192,0.299c-1.101,1.058-2.432,1.929-3.294,3.157
	c-0.154,2.952-2.586,4.898-4.932,6.281c0.342,0.7,0.717,1.417,1.118,2.133c-0.751,0.102-2.253,0.299-3.004,0.401
	c0.623,0.785,1.229,1.536,1.886,2.364c-0.717,1.015-1.562,1.92-2.33,2.859c-0.111-0.529-0.341-1.596-0.452-2.125
	c-0.478,0.23-1.442,0.7-1.92,0.93c0.802,0.998,1.613,1.971,2.483,2.97c1.817-0.162,4.181,0.23,4.497-2.244
	c0.546,0.043,1.638,0.137,2.185,0.188c-0.239,1.587-0.435,3.183-0.691,4.77c0.29,1.382,0.538,2.756,0.905,4.156
	c0.495-1.237,1.015-2.475,1.528-3.721c0.631,0.111,1.886,0.341,2.509,0.452c0.316-1.502,0.615-3.012,0.956-4.506
	c1.374-2.27,2.534-4.659,4.036-6.861c-0.674,6.127-2.68,12.049-3.678,18.142c3.678-4.13,2.859-10.334,6.212-14.729
	c0.527,7.495-5.124,10.926,3.097,15.77c0.213-0.529,0.649-1.596,0.862-2.133c0.606,0.512,1.826,1.527,2.432,2.039
	c0.887-2.176,1.741-4.378,2.466-6.622c0.384,0,1.144,0,1.528,0c-0.589,1.852-1.16,3.703-1.698,5.564
	c1.306-1.536,2.611-3.089,4.224-4.326c-0.469,1.485-1.024,2.944-1.519,4.412c0.699-0.41,2.108-1.229,2.807-1.638
	c-1.135,1.92-2.355,3.806-3.328,5.803c1.963-1.084,3.832-2.423,5.444-4.019c1.391,0.93,2.782,1.869,4.198,2.825
	c-0.913,0.87-2.688,1.22-2.697,2.714c3.687,0.495,6.613-3.166,10.462-2.398c0.828-0.171,1.681-0.341,2.577-0.503
	c-0.29-0.393-0.862-1.169-1.143-1.562c0.554-0.068,1.655-0.205,2.21-0.273c0.521-0.563,1.084-1.092,1.707-1.596
	c0.239,0.435,0.726,1.306,0.973,1.741c0.597-0.589,1.212-1.169,1.895-1.741c1.135,2.509,4.275,2.313,6.528,3.166
	c0.23-0.068,0.683-0.196,0.913-0.256c1.912,0.87,3.908,1.545,5.999,2.039c-0.793-0.99-1.51-1.937-2.278-2.876
	c-1.809-0.597-3.985-0.998-4.685-3.029c1.741,0.256,3.456,0.845,5.086,1.621c0.222-1.271,0.938-2.398,1.126-3.669
	c-0.572-1.519-1.707-2.705-2.679-3.942c-0.154-1.024-0.341-2.048-0.452-3.055c-0.52-0.077-1.553-0.239-2.065-0.316
	c-2.022-1.348-4.173-2.466-6.323-3.533c-0.119-0.452-0.358-1.357-0.486-1.809c-1.775-1.246-3.345-2.756-4.471-4.599
	c-0.546-0.034-1.647-0.102-2.193-0.137c0.341-0.478,1.015-1.434,1.357-1.912c3.166,0.657,6.28,1.604,9.48,2.227
	c-1.033-1.135-2.057-2.253-3.072-3.354c0.666-0.529,1.365-1.041,2.099-1.553c-0.103-0.469-0.29-1.408-0.392-1.877
	c-1.152-1.442-3.533-2.517-3.149-4.634c1.365,0.393,2.551,1.229,3.789,1.98c1.041,1.451,2.15,2.85,3.268,4.275
	c0.504,1.229,1.084,2.415,1.758,3.584c0.23-2.458,0.367-4.907,0.478-7.364c0.777,0.367,1.587,0.742,2.423,1.126
	c0.145,4.053-0.085,10.035,4.659,11.571c-1.169-2.995-1.835-6.178-0.282-9.173c-0.469,0.196-1.416,0.58-1.886,0.777
	c0.41-1.169,0.828-2.338,1.297-3.507c0.734,0.299,1.502,0.597,2.304,0.905c-0.649,2.697-2.176,6.886,1.348,8.243
	c-0.273-2.278,0.128-4.523,1.109-6.588c2.398,0.614,4.053,2.304,4.992,4.574c-0.819,2.423-1.579,4.864-2.065,7.381
	c0.606-0.401,13.647-18.155,17.246-21.239c0.563,0.623,1.152,1.246,1.783,1.877c-0.486,1.954-0.725,3.951-0.777,5.965
	c0.589-0.265,1.767-0.794,2.355-1.058c-0.7,1.169-1.383,2.33-2.073,3.482c0.418,0.171,1.246,0.521,1.664,0.691
	c-0.7,3.917-3.456,7.117-4.335,11.008c0.999-0.734,1.63-1.818,2.185-2.918c0.316-0.256,0.947-0.759,1.254-1.007
	c-0.034,0.725-0.111,2.185-0.145,2.918c0.529-0.102,1.579-0.299,2.108-0.401c-1.792,5.444-5.001,10.163-7.697,15.147
	c4.267-3.266,14.671-15.079,19.516-15.428c-0.742-0.836-1.476-1.664-2.133-2.492c2.475,0.529,4.924,1.254,7.356,2.057
	c-1.144-1.536-2.253-3.072-3.26-4.668c1.638,0.981,3.268,2.031,4.889,3.106c0.598-0.435,1.22-0.862,1.886-1.28
	c1.95,4.303,6.854,11.736,11.051,14.259c-1.271-3.345-2.936-6.562-5.12-9.387c-0.478-1.451-0.939-2.901-1.365-4.352
	c-1.306-1.57-2.534-3.2-3.507-4.983c1.374,1.084,2.679,2.27,3.985,3.49c0.367-1.766,1.681-2.099,3.328-1.741
	c-0.461-0.802-0.879-1.604-1.271-2.406c0.879-0.631,1.792-1.254,2.731-1.869c0.35,1.34,0.742,2.688,1.144,4.028
	c1.331,1.007,2.287,2.389,3.217,3.789c0.017-0.742,0.043-2.219,0.051-2.961c0.316-0.299,0.938-0.905,1.254-1.203
	c-0.29-1.86-0.452-3.738-0.401-5.615c0.947,1.604,1.673,3.337,2.304,5.111c0.111-2.022,0.375-4.062,1.476-5.811
	c0.085,0.819,0.256,2.458,0.35,3.268c0.324-0.427,0.973-1.272,1.297-1.698c0.742,0.248,1.527,0.495,2.347,0.751
	c-0.358,0.956-0.683,1.92-0.973,2.893c2.97-1.365,3.345-5.001,5.641-7.083c0.085,0.572,0.247,1.707,0.333,2.278
	c0.905-0.41,1.843-0.811,2.816-1.195c-15.903,25.393-9.994,24.04-8.994,23.151c0.153-1.067-0.051-2.142-0.077-3.2
	c0.538,1.963,1.041,3.985,0.64,6.033c0.469-0.008,1.417-0.034,1.886-0.043c5.196-14.922,1.746-13.143,6.571-16.836
	c-0.563,2.304-1.118,4.625-1.434,6.98c0.947-0.828,1.946-1.647,2.961-2.458c-1.22,5.513-2.978,10.863-4.719,16.222
	c4.207-3.857,4.804-10.701,10.146-13.705c-1.177,3.038-3.166,5.632-4.668,8.491c3.294-2.671,5.512-6.647,9.54-8.405
	c-3.581,5.005-5.54,4.21-4.002,14.43c-0.222,1.246-0.427,2.509-0.563,3.772c0.41,0.009,1.22,0.017,1.621,0.017
	c0.845-4.156,1.075-8.414,2.005-12.553c0.324-0.307,0.981-0.913,1.305-1.22c-0.682,4.19-0.64,8.465-1.323,12.655
	c3.806-3.371,2.995-9.557,6.861-13.022c-1.365,8.593-5.854,16.179-9.771,23.791c4.789-3.68,15.75-15.249,16.751-16.034
	c-2.223,8.828-14.575,12.319-17.647,23.049c2.671-1.434,5.035-3.362,7.467-5.197c4.309-1.698,8.252-4.318,12.809-5.444
	c-2.697,2.859-7.253,3.831-8.294,8.115c3.251-1.237,5.018-4.582,8.243-5.922c-0.973,1.34-1.988,2.637-2.961,3.959
	c2.807-0.939,5.564-2.031,8.439-2.825c-2.603,2.722-6.4,3.226-9.711,4.531c-1.459,1.502-2.338,3.422-3.405,5.18
	c0.085,0.597,0.205,1.195,0.358,1.801c-1.05,1.655-2.048,3.328-3.004,5.018c0.333,0.282,1.007,0.862,1.348,1.152
	c-0.854,0.435-1.673,0.879-2.458,1.331c0.06,0.649,0.171,1.954,0.222,2.603c0.478-0.614,1.425-1.835,1.903-2.441
	c0.068,0.623,0.145,1.263,0.213,1.911c-0.887,1.033-1.784,2.014-2.637,3.029c2.466-1.263,5.129-2.074,7.962-1.98l-0.094,0.828
	c-3.917,0.7-7.552,2.364-11.401,3.226c0.905,0.478,1.784,0.947,2.756,1.434c0.521-0.341,1.562-1.007,2.082-1.348
	c-0.393,0.785-0.759,1.57-1.092,2.372c2.253,0.546,4.472,1.306,6.63,2.236c0.708-2.739,1.263-5.854,3.866-7.484
	c-0.128,3.61-1.579,6.972-2.176,10.505c1.741,0.094,3.268-0.913,4.83-1.638c-0.162,0.674-0.469,2.005-0.623,2.671
	c0.554-0.179,1.672-0.538,2.227-0.717c-0.776,1.527-1.544,3.055-2.176,4.634c2.722-0.666,4.267-3.021,5.837-5.129
	c-0.384-1.442-0.845-2.867-0.964-4.335c0.572-0.853,1.288-1.604,2.014-2.372c0.35,0.521,1.05,1.553,1.4,2.074
	c1.143-0.154,2.304-0.307,3.516-0.444c0.469-1.092,0.99-2.159,1.408-3.285c-0.469-0.213-1.408-0.649-1.886-0.862
	c0.939-0.58,1.877-1.161,2.842-1.784c-2.408-1.964-1.858-0.773-5.555-5.973c0.845-0.606,1.835-1.178,2.756-1.766
	c0.546,0.102,1.638,0.307,2.176,0.41c-0.597-0.87-1.144-1.732-1.69-2.586c-2.039,0.768-4.232,1.007-6.357,0.845
	c4.011-3.115,9.429-3.234,14.182-4.608c-0.196-1.186-0.486-2.389-0.563-3.567c0.981,0.998,1.937,2.039,2.918,3.098
	c1.527-0.939,3.072-1.877,4.642-2.799c0.034-0.614,0.094-1.835,0.128-2.449c0.504,0.341,1.502,1.015,2.005,1.348
	c0.896-2.321,1.553-4.727,2.236-7.125v12.194c-2.663,0.341-5.171,1.323-7.339,2.876c0.649,0.068,1.937,0.188,2.586,0.256
	c-0.486,0.777-0.93,1.562-1.348,2.355c2.014-0.068,4.062-0.094,6.101-0.316v1.502c-2.475-1.28-2.415,3.507,0,2.483v5.129
	c-1.348-1.382-2.637-2.79-3.823-4.292c-0.666,0.043-1.997,0.119-2.663,0.162c1.75,2.304,4.719,3.226,6.486,5.572v549.751H16V582.931
	c1.143-1.143,2.321-2.287,3.029-3.763c-1.024,0.307-2.031,0.657-3.029,1.033v-29.918c0.41,0.196,1.237,0.597,1.647,0.794
	c0.836-0.23,1.638-0.546,2.406-0.964c-0.282-0.666-0.751-1.101-1.425-1.323c-0.879,0.299-1.758,0.623-2.628,0.973V530.69
	c0.631,0.657,1.254,1.314,1.886,1.98c1.758-0.205,3.499-0.555,5.12-1.289c-2.116-0.956-4.292-1.835-6.127-3.294
	c0.956,0,2.859,0.009,3.814,0.009c-1.493-0.64-3.004-1.237-4.506-1.852c0.7-0.358,2.116-1.067,2.816-1.425c-0.751,0-2.253,0-3.004,0
	v-0.956c2.244,1.28,2.253-2.825,0-1.545v-18.987c1.545-0.085,3.098-0.094,4.634,0.137c0.845,0.802,1.681,1.775,2.961,1.818
	c0.418-1.195,0.683-2.441,0.981-3.669c-1.545-0.162-3.098-0.239-4.634,0.051c-1.323-0.486-2.654-0.998-3.942-1.579v-0.922
	c2.261,1.289,2.261-2.773,0-1.536v-5.581c1.041,0.64,2.065,1.306,3.089,1.988c1.28-0.316,2.56-0.64,3.84-0.947
	c-0.555,0.862-1.118,1.724-1.681,2.594c1.007-0.077,2.005-0.154,3.004-0.222c0.307-0.7,0.614-1.391,0.93-2.074
	c-0.589-0.563-1.169-1.109-1.758-1.655c-2.611-0.068-4.89-1.314-6.741-3.089c0.717,0.068,2.15,0.222,2.867,0.299
	c-0.418-0.802-0.828-1.596-1.237-2.389c-0.572-0.094-1.732-0.29-2.313-0.393v-6.409c5.385,2.228,4.968,2.564,9.796,2.244
	c-0.418,0.922-0.836,1.852-1.263,2.782c2.219-0.102,4.429-0.23,6.63-0.444c-0.956-1.007-1.997-1.911-3.098-2.739
	c0.145-0.324,0.452-0.973,0.597-1.289c-4.07-2.116-8.508-3.337-12.663-5.231v-11.307c1.007,2.825,3.678,4.096,6.426,4.651
	c0.145-0.623,0.435-1.869,0.58-2.492c0.623-0.469,1.246-0.93,1.86-1.399c-0.896-0.486-1.792-0.964-2.688-1.442
	c-0.06,0.691-0.188,2.057-0.247,2.739c-1.519-1.997-2.492-4.318-3.43-6.622c1.775,0.478,3.601,0.606,5.41,0.265
	c1.877,0.87,3.541-0.137,5.069-1.169c-0.222,0.734-0.452,1.476-0.674,2.227c0.922,0.794,1.826,1.587,2.714,2.432
	c-3.226-0.247-6.153-1.852-9.395-1.988c1.352,1.964,7.578,3.296,9.907,3.695c0-1.843,0.017-3.669,0.034-5.504
	c-2.091-1.647-3.797-3.721-5.888-5.359c-3.004-1.195-6.323-1.271-9.446-2.031c4.949-1.34,9.66,1.664,14.635,1.28
	c-0.316-1.306-1.263-2.048-2.466-2.466c-0.725-0.759-1.442-1.519-2.159-2.27c1.297-0.683,2.56-1.493,3.038-2.97
	c-0.828,0.026-2.492,0.068-3.328,0.085c0.085-0.606,0.256-1.818,0.333-2.423c-2.586-1.399-4.412-3.712-6.195-5.965
	c-0.862,0.213-1.715,0.427-2.569,0.649c-0.384-0.631-1.143-1.886-1.519-2.509v-4.292c3.217,0.751,6.537,1.348,9.779,0.375
	c0.836,0.674,1.664,1.365,2.569,1.954c1.067,0.171,1.126-1.254,1.69-1.818c-2.352-0.629-7.595-2.264-9.463-3.857
	c2.082,0.222,4.147,0.597,6.229,0.819c-2.765-3.106-7.757-1.886-10.505-5.111c5.188-0.691,9.438,2.961,14.421,3.627
	c-0.444-1.195-0.879-2.398-1.442-3.533c-1.749-0.282-3.533-0.239-5.299-0.367c1.621-1.195,3.618-1.382,5.564-1.493
	c-0.068-1.741-0.111-3.473-0.154-5.205c-1.254,0.273-2.517,0.555-3.772,0.845c-0.06-0.299-0.171-0.896-0.222-1.195
	c0.538-0.222,1.621-0.657,2.167-0.87c-3.601-2.423-7.851-3.558-11.563-5.769v-1.34c0.401-0.777,1.212-2.338,1.613-3.123
	c0.794,1.135,1.519,2.313,2.381,3.405c1.655,0.666,3.524,0.589,5.274,0.879c0.555-0.717,1.101-1.417,1.655-2.116
	c-3.285-1.391-6.571-2.79-9.899-4.105c-0.256,0.563-0.768,1.69-1.024,2.253v-3.379c2.159,0.41,4.326,0.819,6.519,0.998
	c-1.399-0.811-2.867-1.519-4.318-2.219c1.323-0.759,2.671-1.493,4.011-2.219c-2.116-0.546-4.241-1.118-6.195-2.125
	c-0.009,0.393-0.017,1.169-0.017,1.562v-2.227c2.125,0.213,4.25,0.546,6.298,1.195c0.922-0.708,1.835-1.417,2.748-2.133
	c-2.594-0.358-5.069-1.229-7.381-2.441c2.449-1.169,4.557-2.876,6.391-4.864c-1.306-0.384-2.628-0.708-3.942-1.015
	c0.247-0.256,0.759-0.768,1.015-1.024c-1.024-0.614-2.048-1.237-3.055-1.869c1.348-0.205,2.705-0.41,4.053-0.597
	c0.367,0.324,1.101,0.964,1.468,1.289c1.323-0.102,2.645-0.222,3.968-0.341c-0.964-1.766-3.038-1.399-4.71-1.621
	c0.026-0.367,0.077-1.118,0.102-1.485c-0.529-0.026-1.579-0.077-2.108-0.102c0.87-1.289,1.741-2.569,2.628-3.831
	c-0.256-0.179-0.759-0.538-1.007-0.717c-1.203-1.553-2.637-2.901-4.164-4.122c1.408,0.401,2.816,0.811,4.233,1.203
	c0.939,0.887,1.877,1.783,2.825,2.679c0.188-0.597,0.563-1.801,0.751-2.398c0.495,0.094,1.485,0.282,1.98,0.375
	c0.41-0.939,0.828-1.877,1.254-2.799c-1.161-1.254-2.304-2.526-3.413-3.823c-0.563-0.077-1.69-0.222-2.253-0.29l-0.017-1.34
	c0.674,0.111,2.031,0.316,2.705,0.418c-1.28-2.005-2.253-4.198-2.039-6.639c1.391-0.085,2.782-0.188,4.173-0.307
	c-0.93-0.845-2.048-1.399-3.191-1.886c0.384-0.998,0.777-1.988,1.152-2.978c0.828,0.666,1.647,1.331,2.483,1.997
	c-0.239-0.785-0.469-1.562-0.708-2.33c0.427-2.338,0.913-4.676,2.039-6.793c0.077,1.161,0.145,2.33,0.205,3.507
	c1.425,0.546,2.85,1.101,4.275,1.655c0.009-0.785,0.017-2.347,0.026-3.132c0.401-0.213,1.212-0.631,1.613-0.845
	c0.051,1.476,0.094,2.961,0.137,4.454c1.323-0.29,2.637-0.589,3.951-0.879c-1.63,1.178-3.371,2.27-4.574,3.917
	c1.741,0.128,3.49,0.196,5.239,0.282c-0.486,0.299-1.459,0.887-1.946,1.186c0.102,1.143,0.196,2.287,0.282,3.439
	c0.862,1.118,1.715,2.261,2.569,3.405c0.811,0.358,1.63,0.725,2.441,1.101c-0.64,0.077-1.929,0.23-2.577,0.307
	c-0.828,0.666-1.664,1.331-2.5,1.988c-0.119,0.973-0.247,1.946-0.367,2.935c0.427,0.034,1.28,0.119,1.698,0.154
	c0.009,0.41,0.009,1.22,0.009,1.63c-1.169-0.282-2.338-0.572-3.533-0.759c0.435,1.195,1.152,2.253,1.826,3.328
	c-0.606,0.085-1.809,0.265-2.406,0.358c0.188,1.604,0.341,3.217,0.307,4.838c1.613,0.333,3.635,1.365,4.361-0.836
	c1.51-0.034,3.012-0.068,4.514-0.102c-0.751-0.666-1.502-1.331-2.261-1.988c-0.367-1.084-0.742-2.15-1.126-3.226
	c1.109-0.367,2.219-0.725,3.337-1.067c0.418,0.93,0.845,1.877,1.254,2.816c0.828,0.017,1.647,0.043,2.475,0.068
	c1.143-0.469,2.287-0.913,3.439-1.34c-0.845-0.93-1.715-1.835-2.526-2.79c2.355-0.111,4.727-0.102,7.031-0.64
	c-1.34-0.87-2.85-0.913-4.343-0.614c-0.896-2.577-4.599-2.372-5.103-5.043c0.538,0.239,1.621,0.717,2.159,0.947
	c-0.043-1.067-0.085-2.142-0.128-3.2c-0.725-0.393-1.451-0.768-2.176-1.152c-0.068-0.631-0.213-1.894-0.282-2.526
	c1.382,0.435,2.765,0.87,4.156,1.289c1.092,1.126,2.167,2.27,3.234,3.43c1.306,0.768,2.594,1.562,3.874,2.372
	c0.23-0.503,0.683-1.51,0.905-2.014c2.27-0.034,4.412,0.631,6.323,1.835c0.887-0.93,1.766-1.86,2.654-2.782
	c1.271,0.7,2.543,1.382,3.823,2.082c0.452-1.041,1.143-2.014,1.323-3.14c-0.708-0.887-1.502-1.698-2.278-2.509
	c0.29-0.742,0.58-1.476,0.879-2.202c-1.237,0.725-2.466,1.451-3.712,2.15c0.273-0.555,0.819-1.664,1.092-2.219
	c-0.188-0.273-0.563-0.828-0.742-1.101c0.205-0.614,0.631-1.843,0.845-2.458c-0.759,0.623-1.519,1.254-2.27,1.903
	c-0.41,0-1.229,0.017-1.638,0.017c-0.981-1.391-2.048-2.722-3.14-4.019c0.905-0.341,1.818-0.683,2.731-1.015
	c2.27,1.143,4.634,2.057,7.066,2.816c0.384,0.461,1.161,1.382,1.545,1.843c1.05-0.64,2.022-1.399,3.012-2.116
	c-0.64-1.195-1.271-2.389-1.894-3.584c-2.483,0.119-4.932,0.512-7.339,1.126l0.051-0.666c2.517-0.887,5.026-1.835,7.663-2.355
	c0-0.41,0.009-1.22,0.009-1.63c-2.159,0.171-4.318,0.384-6.451,0.768c-0.879-1.826-3.055-1.254-4.685-1.51l0.043-0.708
	c0.683,0,2.048,0,2.731,0c-0.879-1.126-1.758-2.253-2.628-3.388c-0.606-0.017-1.826-0.043-2.432-0.051
	c-0.333-0.393-0.99-1.178-1.323-1.57c0.631-0.128,1.894-0.384,2.526-0.512c-1.041-0.606-2.108-1.178-3.183-1.732
	c0.247-0.265,0.742-0.794,0.99-1.058l-0.981-0.623l0.461-0.563c0.503,0.58,1.007,1.178,1.51,1.775
	c0.631,0.009,1.894,0.043,2.526,0.06c-0.299,0.367-0.905,1.109-1.203,1.476c1.826,0.239,3.644,0.572,5.402,1.118
	c-0.751,1.007-1.502,2.022-2.244,3.038c1.485-0.154,2.978-0.256,4.48-0.273c0.358-0.563,0.717-1.126,1.075-1.681
	c0.896,0.299,1.783,0.606,2.679,0.913c1.237-0.99,2.449-1.997,3.584-3.098c-0.529-0.282-1.587-0.845-2.116-1.126
	c0.64-1.314,1.451-2.526,2.193-3.78c-1.562-0.017-3.115-0.034-4.676-0.043c0-1.126,0.009-2.253,0.009-3.371
	c-0.563,0-1.698-0.017-2.261-0.017c-0.205-1.024-0.452-2.022-0.734-3.012c-1.152-0.316-2.338-0.444-3.507-0.606
	c-1.809-1.263-3.558-2.611-5.06-4.233c2.27-0.068,3.925,1.425,5.7,2.577c1.306-0.119,2.569-0.503,3.84-0.777
	c0.316,0.401,0.947,1.212,1.263,1.613c1.647,0.401,3.285,0.828,4.932,1.237c-0.7,1.596-0.026,2.961,1.084,4.147
	c1.357-0.085,2.722-0.162,4.087-0.23c0.111-0.606,0.341-1.818,0.461-2.423c-1.28-0.009-2.56-0.009-3.84,0
	c-0.043-1.459-0.085-2.91-0.111-4.352c-1.092,0.427-2.176,0.853-3.26,1.289c0.188-0.555,0.572-1.664,0.759-2.219
	c-2.005-1.775-4.25-3.251-6.357-4.89c1.911,0.435,3.959,0.964,5.333-0.887c0.742,0.145,1.493,0.299,2.244,0.452
	c1.673-0.341,3.371-0.452,5.077-0.529c-1.28-1.374-2.534-2.765-3.772-4.173c-1.246-0.06-2.5-0.119-3.746-0.171
	c1.417-1.553,2.799-3.132,4.053-4.804c-1.493-0.461-2.995-0.905-4.489-1.323l0.009-0.819c0.418-0.017,1.254-0.06,1.673-0.085
	c-0.512-0.546-1.033-1.092-1.553-1.638c-0.316,0.196-0.956,0.572-1.271,0.768c-1.792-0.401-3.618-0.563-5.444-0.657
	c-0.299-1.101-0.606-2.193-0.913-3.285c1.604,0,3.217,0,4.83,0c0.043-0.7,0.128-2.091,0.171-2.79
	c0.649,0.606,1.297,1.212,1.937,1.826c2.202,0.623,4.463,1.058,6.767,0.922c-1.587-0.913-3.337-1.485-5.094-1.963
	c-0.631-0.913-1.271-1.818-1.92-2.722c0.99-0.64,1.971-1.289,2.935-1.954c0.478-0.034,1.434-0.119,1.911-0.162
	c0.734,1.22,1.476,2.449,2.219,3.669c0.777-0.58,1.57-1.152,2.313-1.766c-1.041-1.126-2.142-2.219-3.046-3.456
	c0.964-3.004-1.237-5.837-3.601-7.441c0.811-0.939,1.673-1.843,2.338-2.893c-0.102-1.237-1.801-1.493-2.551-2.278
	c0.717-0.828,1.434-1.664,2.15-2.483c1.562-0.913,2.389-2.355,2.466-4.156c1.877-0.93,1.442-3.2,1.758-4.898
	c1.007,0.589,2.014,1.169,3.029,1.749c0.043-1.126,0.085-2.253,0.119-3.379l0.785,0.026c-0.068,0.299-0.196,0.905-0.265,1.203
	c1.399,0.836,2.748,1.766,4.062,2.731c0.145-0.631,0.427-1.886,0.563-2.517c0.802,1.246,1.613,2.483,2.441,3.703
	c-0.435,0.034-1.306,0.085-1.741,0.111c0,0.7,0,2.099,0,2.799c-0.811-0.725-1.621-1.442-2.432-2.159
	c-0.427,0.87-0.845,1.741-1.263,2.611c1.929,0.043,3.866,0.06,5.794,0.111c-0.597,0.41-1.809,1.22-2.415,1.621
	c1.655,1.28,3.61,1.92,5.692,2.005l0.119,0.58c-0.555,0.026-1.647,0.068-2.202,0.094c0.068,2.031,0.06,4.139,1.033,5.99
	c0.666-0.316,1.988-0.939,2.654-1.254c-0.657,1.007-1.382,1.997-1.946,3.072c0.026,1.545,1.229,2.756,1.894,4.079
	c-0.256,0.529-0.768,1.579-1.024,2.108c0.956-0.094,1.903-0.196,2.85-0.282c0.23,0.734,0.435,1.476,0.614,2.227
	c-1.459,0.905-3.072,1.502-4.625,2.202c0.222,0.512,0.683,1.527,0.905,2.039c1.399-0.068,2.782-0.358,4.156-0.597
	c0.418,0.759,0.845,1.519,1.271,2.278c-0.879,0.435-1.749,0.896-2.611,1.348c0.512,0.29,1.536,0.87,2.048,1.152
	c-1.28,0.367-2.569,0.742-3.84,1.126c0.708,1.246,2.637,2.304,1.86,3.934c-1.562,0.41-3.157,0.725-4.71,1.186
	c1.143,0.802,2.304,1.613,3.456,2.432c2.159-1.724,4.617-3.055,7.356-3.567c-0.742,1.289-1.502,2.569-2.219,3.866
	c1.306-0.367,2.603-0.751,3.908-1.126c-0.401,0.819-0.811,1.647-1.212,2.483c-2.517,0.137-4.89,1.229-6.383,3.311
	c0.128,1.143,0.435,2.261,0.631,3.413c-1.271-0.691-2.295-1.681-2.961-2.97c-0.759,0.41-1.527,0.828-2.287,1.237
	c-1.169-0.085-2.338-0.111-3.507-0.034c1.519,1.041,3.063,2.048,4.591,3.081c0.384-0.273,1.152-0.811,1.536-1.084
	c0.461,0.299,1.382,0.887,1.843,1.186c-0.085,1.024-0.171,2.048-0.265,3.081c0.725,0.307,1.451,0.614,2.176,0.922
	c1.553-2.005,4.019-1.527,6.221-1.306c-1.493,1.169-3.362,1.399-5.197,1.545c-1.937,1.766-5.205,2.901-4.864,6.05
	c2.116,1.954,4.395,3.738,6.912,5.154c1.749-1.587,3.541-3.183,4.838-5.18c0.691-0.427,1.382-0.853,2.082-1.271
	c0,0.691,0,2.074,0,2.756c0.401-0.239,1.212-0.734,1.613-0.981c0.862,0.401,1.732,0.802,2.603,1.203
	c0.026-1.425,0.043-2.85,0.068-4.267c0.845-0.751,1.69-1.502,2.534-2.244c0.009,1.613,0.017,3.226,0.06,4.847
	c1.579,0.597,1.715,2.21,1.997,3.644c0.538,0.649,1.084,1.289,1.63,1.946c0.546,0.026,1.638,0.077,2.185,0.102
	c0.384,1.118,0.819,2.227,1.118,3.379c-0.486,0.435-1.468,1.314-1.954,1.758c1.186,0.017,2.364,0.034,3.55,0.06
	c-0.051,0.64-0.145,1.903-0.196,2.543c0.614,0.657,1.22,1.323,1.843,1.997c-1.195,0.896-2.347,1.843-3.499,2.799
	c0.265,0.461,0.777,1.374,1.033,1.835c-0.316,0.503-0.947,1.51-1.263,2.014c1.306,1.058,2.62,2.099,3.951,3.123
	c-1.28,0.452-2.577,0.853-3.883,1.246c-0.478,1.34-1.323,2.441-2.577,3.174c1.28,1.05,2.697,1.911,4.181,2.654
	c1.186-0.452,2.381-0.879,3.584-1.289c0.683,1.229,1.408,2.441,1.946,3.746c-0.512,0.836-1.118,1.613-1.707,2.406
	c-0.495-0.196-1.476-0.589-1.971-0.777c-0.102,1.203-0.171,2.423-0.154,3.644c-1.579-0.427-3.166-0.853-4.762-1.186
	c1.126,1.186,2.364,2.261,3.635,3.302c1.451-0.299,2.91-0.563,4.378-0.794c0.085,2.475-1.655,3.866-3.985,4.045
	c0.7,1.084,1.408,2.159,2.116,3.251c0.956-0.998,1.911-2.005,2.85-3.012c1.638,0.316,3.243,0.759,4.821,1.271
	c-1.817,1.641-3.596,2.996-9.847,5.504c0.751,0.529,1.502,1.067,2.261,1.604c-0.947,0.495-1.894,0.981-2.842,1.476
	c0.282,0.35,0.836,1.058,1.118,1.408c-1.638,0.486-3.285,0.947-4.915,1.451c1.724,2.423,4.582,0.111,6.571-0.64
	c0.401,0.29,1.212,0.87,1.613,1.161c0.597-0.572,1.186-1.152,1.783-1.715c1.399,0.666,2.62,0.751,2.739-1.186
	c0.341,0.29,1.024,0.87,1.357,1.161c0.435-0.316,1.297-0.947,1.724-1.271c0,0.683,0,2.048,0,2.731
	c-1.314,0.009-2.637,0.017-3.951,0.034c-1.664,1.724-3.328,3.447-4.941,5.222c2.517-0.529,5.06-0.99,7.646-1.067
	c-1.519,0.708-3.055,1.382-4.48,2.261c-2.074,0.606-4.318,1.024-5.965,2.543c3.533,0.265,7.552,0.563,9.446,4.062
	c-0.905,0.828-1.809,1.673-2.705,2.509c-0.026,0.785-0.06,1.579-0.085,2.381c0.444-0.009,1.323-0.009,1.766-0.017
	c-0.589,0.964-1.169,1.929-1.749,2.901c-0.751,0.486-1.502,0.99-2.236,1.502c1.169,0.154,2.227-0.265,3.217-0.819
	c0.631-0.051,1.911-0.154,2.543-0.205c-0.282,1.647-0.58,3.311-0.939,4.958c0.435,0.358,1.289,1.075,1.715,1.425
	c-0.666,0.572-1.331,1.143-1.988,1.724c1.143,2.202,2.688,4.156,4.361,5.982c-0.188,0.273-0.572,0.819-0.759,1.101
	c1.186,2.645,1.809,5.504,1.749,8.422c-0.708,0.631-1.408,1.271-2.099,1.92c0.589,0.051,1.775,0.145,2.364,0.196
	c-0.512,0.384-1.545,1.152-2.057,1.545c1.442,2.022,3.089,3.9,4.881,5.632c0.06,0.452,0.171,1.374,0.222,1.835
	c0.777,0.606,1.545,1.22,2.321,1.835c-0.99,0.631-1.98,1.28-2.97,1.937c2.253-0.043,4.506-0.23,6.69-0.811
	c-0.017,0.35-0.043,1.067-0.051,1.417c-1.485,0.051-2.97,0.111-4.446,0.273c0.41,0.836,0.845,1.664,1.306,2.483
	c-3.251-0.521-6.409,0.299-8.585,2.85c2.15,0.333,4.318,0.026,6.477,0c2.637,0.7,4.557,2.859,6.647,4.506
	c-3.593,0.862-7.509,0.162-10.923,1.621c0.222,0.939,0.444,1.903,0.657,2.859c0.495,0.145,1.468,0.444,1.963,0.589
	c0.094-0.691,0.299-2.082,0.401-2.782c0.683,1.143,1.34,2.287,1.997,3.456c2.543-0.93,4.89-2.347,6.647-4.429
	c0.589,1.271,1.86,1.562,3.123,1.783c-1.195,0.794-2.347,1.647-3.388,2.645c1.758-0.546,3.439-1.297,5.094-2.091
	c0.324,0.239,0.973,0.725,1.297,0.964c0.427-0.529,1.289-1.596,1.724-2.125c0.427,0.922,1.05,1.801,1.178,2.85
	c-0.751,1.22-1.707,2.304-2.603,3.422c0.896,0.435,1.792,0.87,2.688,1.314c-1.476,1.442-4.659,2.56-2.825,5.069
	c-0.768-0.708-1.545-1.417-2.313-2.108c-1.075,0.026-2.142,0.06-3.2,0.094c-0.41,0.828-0.819,1.664-1.229,2.5
	c0.367,0.836,0.734,1.69,1.092,2.543c-1.015,0.529-2.039,1.075-3.055,1.63c1.86,0.512,3.712,1.041,5.555,1.621
	c-1.135,0.512-2.27,1.015-3.405,1.51c0.324,1.399,0.649,2.799,0.973,4.215c1.314-0.666,2.586-1.434,3.703-2.406
	c1.604-0.222,3.217-0.444,4.83-0.614c-0.734,0.836-1.468,1.681-2.202,2.526c-0.717,0.119-2.142,0.367-2.85,0.486
	c1.886,1.638,4.275,2.372,6.63,3.089c-0.41,1.135-0.811,2.27-1.212,3.413c-1.587-0.085-3.166-0.171-4.753-0.273
	c-1.033,0.572-2.074,1.135-3.115,1.707c1.417,0.478,2.842,0.93,4.275,1.365c1.041,0.683,2.091,1.365,3.14,2.039
	c-2.875,0.858-4.714,1.366-6.767,2.219c0.887,1.084,1.835,2.125,2.731,3.217c-1.434,0.137-2.765-0.119-3.669-1.297
	c-0.649,0.589-2.748,0.23-2.569,1.459c1.161,0.828,2.423,1.51,3.652,2.236c-0.836,0.051-2.492,0.137-3.319,0.179
	c0.265,1.51,0.503,3.14,2.074,3.883c-0.964,1.203-1.954,2.389-3.021,3.516c-0.384,0.87-0.759,1.749-1.143,2.628
	c2.611,0.495,5.222,1.007,7.799,1.655c-1.28,0.563-1.92,1.459-1.92,2.705c2.133,0.179,4.284,0.077,6.417,0.094
	c-0.99,1.493-2.022,2.961-3.004,4.471c2.261-0.452,4.105-1.86,5.632-3.524c1.613-0.367,3.226-0.742,4.855-1.084
	c-0.717,1.391-1.459,2.773-2.193,4.173c0.546,0.06,1.638,0.179,2.185,0.239c-0.998,0.998-2.005,1.997-3.004,3.004
	c-1.118,0.299-2.236,0.614-3.354,0.939c1.067,0.683,2.15,1.374,3.217,2.057c1.988-1.084,4.267-1.05,6.46-0.964
	c-2.261,2.202-5.026,3.729-8.021,4.685c0.384,0.768,0.768,1.545,1.161,2.33c-1.237-0.418-2.466-0.836-3.703-1.237
	c0.358,0.887,0.725,1.783,1.084,2.679c-0.572,0.085-1.724,0.265-2.295,0.35c0.691,0.666,1.399,1.323,2.108,1.988
	c-0.947,0.828-1.903,1.664-2.842,2.509c2.21,0.802,4.437,1.596,6.656,2.381c-2.065,0.538-3.797,1.758-5.103,3.439
	c-1.135-0.119-2.278-0.265-3.405-0.324c0.802,1.297,2.21,1.911,3.558,2.517c2.15-0.887,4.489-0.981,6.793-0.87
	c-1.015,0.691-2.031,1.382-3.063,2.057c0.623,0.358,1.86,1.067,2.475,1.417c-2.005,1.041-4.053,2.022-6.11,2.978
	c0.128,0.563,0.375,1.698,0.503,2.261c3.405-1.22,6.494-3.456,10.283-3.345c-1.638,1.826-3.354,3.584-4.864,5.521
	c1.724,0.102,3.447,0.179,5.171,0.273c-1.664,1.186-3.362,2.338-5.009,3.576c1.126,0.239,2.253,0.486,3.379,0.742
	c-0.375,0.922-0.742,1.835-1.118,2.765c0.188,0.58,0.384,1.161,0.572,1.758c-1.109,0.563-2.202,1.178-3.251,1.86
	c1.348,0.188,2.705,0.367,4.062,0.538l-0.068,0.828c-2.364,0.247-4.719,0.563-7.083,0.853c-0.367,0.546-1.109,1.621-1.476,2.159
	c0.998,0.512,1.997,1.015,3.004,1.519c0.401,0.589,0.802,1.178,1.212,1.784c-1.041,1.015-2.065,2.039-3.038,3.132
	c0.811-0.137,2.415-0.41,3.217-0.546c0.384,0.316,1.143,0.947,1.519,1.263c1.178-0.444,2.355-0.887,3.541-1.306
	c0.162-0.913,0.324-1.818,0.478-2.714c0.708-0.478,1.408-0.939,2.116-1.399c0.913,0.495,1.826,0.99,2.748,1.502
	c0.717-0.222,1.442-0.444,2.176-0.649c-0.358,1.519-1.434,3.49,0.836,4.002c-0.324,0.811-0.631,1.63-0.939,2.466
	c-0.794,0.247-1.579,0.512-2.364,0.776c0.435,1.758,0.879,3.516,1.306,5.291c-1.092,2.5-1.988,6.289,0.546,8.201
	c-0.708,0.077-2.142,0.247-2.85,0.324c0.529,0.981,1.067,1.954,1.621,2.935c-1.015,0.435-2.005,0.922-2.978,1.442
	c-0.316,0.811,0.666,1.425,0.93,2.159c2.048-1.075,4.241-0.324,6.306,0.222c-2.005,2.927-6.016,0.964-8.969,1.399
	c0.102,0.486,0.29,1.451,0.393,1.937c1.604,0.367,3.277,0.717,4.548,1.869c3.473,0.913,6.98,0.085,10.428-0.469
	c5.854-0.324,10.453,3.755,15.778,5.419c-0.247-1.058-0.486-2.108-0.725-3.157c2.5,0.879,4.992,2.731,7.731,1.519
	c1.51,1.937,3.942,1.579,6.127,1.562c-0.077-0.657-0.239-1.98-0.316-2.637c-2.782-1.835-5.931-2.978-9.301-2.722
	c-0.171-1.007-0.333-2.005-0.486-2.987c-2.295,0.418-4.634,0.529-6.972,0.486c-0.179-1.05-0.35-2.099-0.521-3.132
	c-1.809-0.043-3.61-0.051-5.41-0.026c-0.145-1.527-0.299-3.046-0.444-4.557c7.933-0.19,11.45,5.123,14.72-0.213
	c1.724-0.085,3.456-0.145,5.188-0.188c2.773-2.082,5.854-3.669,8.789-5.478c-1.954-1.246-4.13-2.057-6.315-2.799
	c0.964-0.802,1.946-1.596,2.927-2.381c-0.111-1.024-0.213-2.039-0.299-3.055c0.802-0.478,1.587-0.973,2.355-1.502
	c-1.024-3.004-3.695,0.213-5.069,1.22c0.589,0.205,1.749,0.614,2.33,0.819c-1.596,1.553-2.987,3.294-4.446,4.966
	c-2.099-0.35-4.079-1.161-6.05-1.903c-1.946,0.205-3.891,0.316-5.837,0.384c3.217-3.379,8.158-2.509,11.844-4.881
	c-0.256-0.495-0.777-1.493-1.033-1.997c0.307-1.067,0.614-2.116,0.922-3.166c1.306-0.179,2.611-0.367,3.917-0.538
	c-0.811-0.76-1.63-1.493-2.449-2.219c0.614-0.87,1.229-1.741,1.843-2.611c-1.084,0.205-2.159,0.418-3.234,0.648
	c0.29,1.485,0.486,3.021,0.179,4.54c-1.783-0.085-3.575-0.137-5.359-0.179c-4.067-2.806-4.694-2.392-8.098-3.473
	c3.038-1.126,5.632-3.081,8.013-5.222c-1.69-0.538-3.396-1.007-5.103-1.468c0.017-0.666,0.043-2.014,0.06-2.68
	c1.63,0.503,3.277,1.016,4.924,1.502c3.507-1.289,7.083-0.213,9.737,2.278c-0.555,0.324-1.664,0.981-2.219,1.306
	c2.022,0.196,4.036,0.418,6.042,0.768c-0.145,0.725-0.444,2.167-0.589,2.893c2.509-0.777,6.63,0.41,7.39-2.961
	c-2.15,0.401-4.275,0.905-6.417,1.374c1.101-1.135,2.202-2.261,3.311-3.379c2.091-0.085,4.454-0.256,4.608-2.876
	c-2.671,0.188-5.342,0.862-7.057,3.089c-0.555,0.051-1.673,0.154-2.236,0.205c1.297-2.475,4.224-2.807,6.485-3.942
	c0.802-1.502-1.391-2.526-2.031-3.729c-0.188,0.666-0.563,1.997-0.759,2.662c-1.024,0.401-2.039,0.828-3.081,1.203
	c-0.017-0.521-0.051-1.562-0.068-2.082c-1.357,0.939-2.739,1.852-4.139,2.731c-0.512-0.99-1.024-1.98-1.536-2.961
	c-2.927,1.195-4.779-1.476-7.262-2.389c0.145-0.555,0.435-1.681,0.58-2.244c-3.516,0.017-7.962-0.017-9.882-3.541
	c4.915-0.051,9.634,1.417,14.379,2.483c7.195-1.422,8.52-4.596,9.634-6.357c-0.614-0.051-1.843-0.154-2.458-0.213
	c-0.154,0.563-0.452,1.681-0.597,2.236c-2.79,1.442-5.811,0.196-7.919-1.818c-0.913-0.094-1.954,0.205-2.705-0.486
	c0.913-0.922,1.886-1.775,2.842-2.654c-1.613,0.06-3.226,0.154-4.83,0.256c-0.401-0.273-1.186-0.819-1.579-1.092
	c-1.084-0.017-2.167-0.017-3.251-0.017c-1.033-1.203-2.074-2.398-3.081-3.61c4.164,1.374,8.311,3.174,12.809,2.876
	c0.35,0.333,1.05,0.998,1.408,1.323c2.458,0.563,4.89,1.212,7.313,1.903c-0.777-2.389,0.563-4.233,2.21-5.803
	c-1.604-0.051-3.209-0.085-4.813-0.12c0.384-0.717,0.777-1.434,1.169-2.142c1.417-0.521,2.816-1.101,4.173-1.775
	c-0.981-0.444-1.963-0.87-2.935-1.297c-1.306-2.236-3.746-3.2-6.187-3.576c-1.075-0.776-2.15-1.545-3.209-2.321
	c7.079-0.407,10.591,5.66,13.662,0.179c-1.613-0.094-3.209-0.137-4.813-0.154c0.111-0.469,0.341-1.408,0.461-1.869
	c-0.981,0.41-1.946,0.828-2.918,1.246c-0.043-0.631-0.137-1.903-0.179-2.543c-0.282,0.111-0.836,0.333-1.118,0.435
	c-2.364-1.126-4.796-2.159-6.989-3.593c1.698-0.171,3.43-0.196,5.129,0.085c1.186,1.05,2.773,1.169,4.267,1.451
	c1.033,1.843,2.935,2.398,4.932,2.543c-0.367-0.845-0.734-1.681-1.101-2.509c-0.956-0.64-1.894-1.271-2.833-1.903
	c0.589-0.136,1.766-0.41,2.364-0.555c1.28-1.092,2.731-1.963,4.207-2.765c-3.567-1.545-7.253-2.825-10.701-4.625
	c0.538-0.179,1.613-0.538,2.15-0.708c-0.742-0.896-1.493-1.783-2.236-2.662c2.611,0.051,5.163,0.631,7.475,1.852
	c-0.111,0.614-0.341,1.843-0.461,2.449c0.947,0.529,1.886,1.067,2.842,1.596c-0.734-1.314-1.476-2.62-2.227-3.908
	c1.203-0.879,2.415-1.741,3.652-2.569c0.068-0.981,0.128-1.954,0.196-2.91c-0.828,0.205-1.655,0.418-2.483,0.649
	c-0.7-0.418-1.399-0.828-2.099-1.229c1.246-0.068,2.5-0.119,3.763-0.162c-0.307-0.418-0.922-1.237-1.229-1.655
	c0.777-0.529,1.553-1.05,2.33-1.57c-1.818-0.845-3.678-1.587-5.564-2.236c-1.271-1.297-2.645-2.475-4.019-3.635
	c1.451,0.452,2.901,0.939,4.343,1.434c0.375-0.256,1.109-0.759,1.485-1.007c0.469,0.35,1.408,1.05,1.877,1.399
	c0.623-0.87,1.246-1.732,1.869-2.594c-0.819-0.188-2.458-0.572-3.277-0.759c1.015-0.683,2.022-1.365,3.046-2.022
	c-1.886-0.717-3.78-1.408-5.641-2.176c1.596-0.512,3.191-1.015,4.787-1.51c-0.35-0.444-1.041-1.314-1.382-1.749
	c0.538,0.043,1.604,0.145,2.142,0.188c-0.026-1.007-0.051-2.014-0.068-3.012c-2.295-0.717-4.634-1.485-6.477-3.089
	c2.304,0.358,4.463,1.263,6.613,2.116c-0.435-4.915-0.905-9.847-1.007-14.788c0.41,0.009,1.22,0.009,1.63,0.009
	c0.512,5.026-0.887,10.428,1.527,15.113c-0.546,0.7-1.084,1.408-1.621,2.116c1.212,0.324,2.423,0.623,3.644,0.939
	c0,0.333,0.009,0.981,0.009,1.306c-0.546,0.128-1.638,0.367-2.185,0.486c1.903,1.749,4.267,1.903,6.519,0.734
	c-0.009,0.316-0.026,0.939-0.034,1.254c-1.971,0.341-3.925,0.777-5.879,1.22c-0.273,0.427-0.819,1.271-1.092,1.69
	c1.903-0.35,3.814-0.7,5.751-0.853c-1.468,1.579-3.661,1.963-5.589,2.688c0.256,0.401,0.785,1.203,1.041,1.604
	c1.434-0.213,2.867-0.418,4.301-0.64c0.674,1.263-0.026,2.483-0.521,3.678c-0.93,0.418-1.852,0.853-2.773,1.289
	c1.562,0.794,3.072,1.698,4.446,2.799c-2.415,1.203-6.903,0.674-6.733,4.429c1.152,0.435,2.313,0.896,3.473,1.357
	c1.545-0.094,3.089-0.171,4.634-0.222c0.717,0.717,1.451,1.434,2.185,2.142c-0.708,0.913-1.408,1.835-2.091,2.773
	c-0.478-0.034-1.434-0.094-1.911-0.128c-2.167,1.22-4.378,2.372-6.502,3.652c3.78,1.741,7.782-0.768,11.767,0
	c-1.067,1.007-2.125,2.022-3.183,3.055c0.964,0.555,1.929,1.109,2.901,1.673c0,0.521-0.009,1.562-0.017,2.082
	c-0.939,0.213-1.877,0.435-2.807,0.657c0.068,0.469,0.205,1.408,0.273,1.877c-0.768,0.068-2.295,0.213-3.063,0.29
	c1.69,1.988,4.215,1.408,6.477,1.143c0.077,0.273,0.239,0.828,0.324,1.101c-0.512,0.06-1.545,0.179-2.057,0.239
	c0.009,0.606,0.026,1.835,0.034,2.44c1.843-0.196,4.489-1.766,5.589,0.555c-2.611,0.145-5.205,0.614-7.398,2.133
	c0.631,0.247,1.903,0.725,2.534,0.964c0.094,0.512,0.273,1.527,0.358,2.039c1.664-0.358,3.337-0.632,5.035-0.734
	c-1.434,1.613-2.85,3.234-4.318,4.821c0.777,0.401,1.562,0.802,2.355,1.22c2.876-1.314,5.973-2.022,9.079-2.569
	c-0.265,0.47-3.186,5.934-3.26,6.016c-2.714,0.674-5.521,0.06-8.269,0.009c-0.009,0.631-0.009,1.894-0.009,2.534
	c3.567,1.399,7.339,0.23,11.025,0.188c-0.759,1.314-1.553,2.611-2.364,3.9c-1.323-0.538-2.637-1.067-3.951-1.587
	c0.452,0.87,0.896,1.749,1.357,2.637c1.109,0.026,2.219,0.068,3.337,0.111c0.265,0.384,0.794,1.169,1.058,1.562
	c-0.683,0.077-2.048,0.222-2.731,0.299c0.009,0.623,0.009,1.877,0.017,2.5c0.922-0.247,1.852-0.469,2.782-0.708
	c1.263,0.393,2.526,0.794,3.789,1.212c-2.517,1.493-5.641,0.913-8.235,2.168c-1.271,0.666-2.381,1.596-3.669,2.244
	c-1.852-0.094-3.669-0.546-5.495-0.776c1.621,3.081,5.76,2.662,8.61,3.977c2.679-0.444,5.359,0.094,8.021,0.435
	c-0.358,1.596-0.683,3.209-0.964,4.83c-0.751,0.717-1.502,1.442-2.253,2.167c-0.06,0.64-0.196,1.92-0.256,2.56
	c-0.93-0.538-1.852-1.075-2.765-1.604c-3.661,0.896-7.373,1.724-11.17,1.596c0.614,1.067,1.613,1.271,2.748,0.973
	c2.048,0.555,4.079,1.161,6.135,1.681c0.555-0.691,1.118-1.374,1.681-2.048c5.794,0.93,11.153-2.697,16.896-1.894
	c-1.724,1.63-3.499,3.209-5.171,4.898c1.425,0.196,2.85,0.393,4.275,0.597c-1.186,1.51-2.364,3.055-3.772,4.378
	c-1.297-0.521-2.509-1.186-3.729-1.835c-0.563,0.435-1.118,0.887-1.681,1.34c-0.572,0.026-1.715,0.085-2.287,0.111
	c0.009,0.486,0.034,1.442,0.043,1.929c-1.587,0.077-3.174,0.188-4.745,0.384c1.015,0.725,2.193,0.905,3.413,0.683
	c2.057,0.887,4.002,2.185,6.263,2.5c3.328-0.939,6.451-2.637,9.958-2.91c-0.58,1.937-2.441,2.398-4.173,2.807
	c-1.596,2.918,2.219,3.627,4.233,4.361c0.299,1.331,0.606,2.671,0.947,4.002c-2.748,1.101-5.103,2.995-6.673,5.513
	c0.077-0.256,0.23-0.76,0.307-1.007c-0.785,0.196-2.355,0.58-3.149,0.777c1.186,0.939,2.381,1.86,3.558,2.825
	c-1.485-0.307-2.944-0.606-4.412-0.922c0.017,0.657,0.034,1.306,0.06,1.988c-1.758,0.068-3.499,0.265-5.231,0.521
	c-0.794-0.759-1.57-1.519-2.355-2.261c-0.862,0.981-1.724,1.963-2.577,2.961c-0.649-0.555-1.297-1.101-1.937-1.638
	c-0.265,0.145-0.802,0.427-1.067,0.572c0.239,0.794,0.486,1.596,0.725,2.406c3.089-1.434,6.502-0.29,8.858,1.971
	c-1.843,0.008-3.669-0.179-5.478-0.452c-0.017,0.469-0.043,1.425-0.06,1.903c0.529-0.094,1.596-0.265,2.125-0.35
	c0.418,0.572,1.263,1.707,1.69,2.278c-1.109,0.811-2.244,1.604-3.294,2.492c0.597,0.777,1.246,1.519,1.903,2.261
	c1.749-0.171,3.499-0.29,5.257-0.384c0.239-0.41,0.717-1.22,0.956-1.63c3.883-0.93,7.629-2.577,11.708-2.389
	c-0.205,1.314-0.401,2.628-0.58,3.951c-2.816,0.717-5.726,0.776-8.516-0.077c0.777,1.357,1.579,2.714,2.415,4.045
	c-1.075-0.956-2.142-1.954-3.447-2.586c-0.956,0.444-1.886,0.93-2.79,1.468c0.469,0.393,1.391,1.169,1.852,1.553
	c1.254-0.137,2.526-0.137,3.098,1.246c-1.57,0.102-3.132,0.179-4.693,0.239c-0.06,0.504-0.171,1.51-0.23,2.014
	c-1.399-0.904-2.739-2.509-4.574-1.493c0.555,0.367,1.664,1.084,2.219,1.442c-0.205,0.256-0.623,0.759-0.828,1.007
	c1.357,0.503,2.705,1.007,4.07,1.51c-0.009-0.734-0.034-2.202-0.051-2.935c4.676-0.188,9.344,0.307,14.02,0.128
	c-2.074,2.611-4.924,4.335-7.458,6.434c-0.418,0.017-1.263,0.06-1.681,0.077c1.084,0.964,2.185,1.929,3.294,2.884
	c-0.717,0.324-1.425,0.657-2.142,1.007c0.341,0.828,0.691,1.655,1.041,2.5c-1-0.261-5.777-1.244-7.996-3.849
	c1.391,0.265,2.799,0.538,4.198,0.811c-1.425-1.545-3.729-2.193-5.666-1.271c-0.256,1.664,1.178,2.679,2.091,3.84
	c1.101,0.555,2.193,1.143,3.277,1.749c0.324-0.102,0.981-0.324,1.314-0.427c3.465,1.69,7.117-0.828,10.726,0.35
	c0.137-0.546,0.401-1.63,0.529-2.176c1.229,0.239,2.466,0.486,3.703,0.734c-0.256,1.809-0.657,3.618-1.408,5.299
	c-3.302-1.459-6.588,0.529-9.719,1.468c1.041,1.109,2.091,2.21,3.14,3.345c-0.853-0.111-2.56-0.316-3.422-0.418
	c0.375,1.417,0.768,2.842,1.178,4.267c-0.674,0.597-1.34,1.212-2.005,1.835c-0.469-0.384-1.399-1.152-1.869-1.536
	c-0.947,0.444-1.877,0.905-2.807,1.365c1.485,0.657,2.935,1.434,4.267,2.398c4.736-1.28,9.583-0.538,13.995,1.476
	c-0.41,0-1.237-0.009-1.647-0.009c0.461,0.683,1.374,2.039,1.826,2.722c0.811-0.64,1.621-1.263,2.432-1.886
	c0.512,0.512,1.024,1.041,1.545,1.57c-0.7-0.111-2.099-0.341-2.799-0.452c-0.085,0.358-0.256,1.084-0.341,1.442
	c-3.703-0.563-7.287,0.759-10.923,1.22c-0.418,1.442-0.845,2.884-1.246,4.344c11.066-1.585,5.332-2.767,13.713-0.555
	c-2.065,1.809-3.968,3.883-6.4,5.205c-1.545,0.171-3.081-0.265-4.599-0.375c0.444,1.698,1.792,2.287,3.447,2.321
	c0.947,0.904,1.869,1.826,2.782,2.773c3.507-0.102,7.1-2.961,10.428-0.99c-1.946,0.171-3.9,0.282-5.845,0.418
	c-0.196,0.469-0.589,1.408-0.785,1.877c-1.988-0.674-3.524,0.196-4.591,1.886c4.053,0.205,8.004-1.101,12.066-0.819
	c-5.877,2.204-8.894,3.922-13.858,3.115c7.42,7.42,0.75,4.139,6.417,8.32c0.188,1.323,0.375,2.645,0.572,3.977
	c-1.391-0.623-2.876-0.998-4.403-0.956c0.913,2.082,2.884,2.901,5.069,2.859c0.691-1.143,1.408-2.244,2.185-3.311
	c-0.367,1.195-0.7,2.423-1.237,3.575c-1.399,0.444-2.859,0.623-4.284,0.956c1.323,1.357,3.234,1.254,4.983,1.314
	c0.239,0.375,0.708,1.118,0.939,1.493c-1.178-0.452-2.364-0.888-3.541-1.314c0.128,0.529,0.393,1.57,0.521,2.099
	c-1.331-1.007-3.456-2.662-4.079,0.009c-0.768,0.12-2.295,0.367-3.063,0.495c0.785,0.623,1.579,1.246,2.381,1.877
	c1.015-2.202,3.874-2.167,4.676,0.213c1.647,0.051,3.302,0.094,4.966,0.162c-1.271,1.109-2.56,2.202-3.866,3.277
	c4.383,3.155,3.004,3.541,13.559,0.41c0.546,0.674,1.067,1.391,1.562,2.133c1.084,0.111,2.167,0.222,3.26,0.35
	c-1.314,0.529-2.62,1.05-3.925,1.596c-0.452-0.401-1.357-1.186-1.809-1.579c-0.922,0.845-1.835,1.698-2.748,2.569
	c0.256,0.213,0.751,0.649,1.007,0.87c-0.691,0.154-1.374,0.324-2.057,0.503c-0.171,0.631-0.512,1.903-0.674,2.534
	c-0.461-0.393-1.374-1.186-1.826-1.579c-1.459,0.154-2.927,0.154-4.386,0.29c0.119,0.768,0.273,1.544,0.444,2.33
	c-1.357-0.102-2.705-0.213-4.053-0.29c0.026,1.195,0.051,2.398,0.077,3.61c2.022,0.725,4.062,1.408,6.101,2.099
	c-0.828,0.265-1.655,0.546-2.475,0.828c-0.282-0.324-0.853-0.973-1.135-1.306c-1.263,0.034-2.526,0.077-3.78,0.137
	c0.367,2.466,1.698,4.557,3.541,6.204c0.435-0.273,1.314-0.836,1.758-1.118c3.191,1.536,6.75,1.391,10.121,0.649
	c-1.143,0.973-2.295,1.928-3.422,2.918c2.014-0.546,4.403-0.623,5.982-2.142c-0.102-3.618-0.009-7.262,0.998-10.752
	c0.597-0.094,1.801-0.265,2.406-0.35c0.247-1.954,0.555-3.883,1.024-5.786c1.126,0.017,2.261,0.043,3.396,0.068
	c0.06-0.521,0.196-1.579,0.265-2.099c1.212-1.357,2.355-2.756,3.507-4.147c0.683,0.802,1.382,1.613,2.082,2.432
	c0.606,1.911,0.939,4.113,2.79,5.291c-0.265,0.956-0.538,1.911-0.802,2.884c0.93-0.273,1.869-0.529,2.807-0.785
	c-0.41,0.811-0.828,1.621-1.246,2.449c0.691,0.93,1.382,1.877,2.082,2.825c1.067,0.759,2.125,1.544,3.174,2.347
	c-0.35,0.981-0.691,1.963-1.033,2.961c0.674,0.512,1.348,1.033,2.031,1.562c0.427,1.818,0.896,3.644,1.451,5.444
	c-1.041,1.69-0.521,3.541,0.111,5.274c0.606,0,1.801-0.009,2.406-0.009c0.759,1.476,1.51,2.978,2.304,4.471
	c2.159-0.939,4.335-1.852,6.468-2.859c0.64,0.572,1.28,1.161,1.929,1.758c1.852,0.017,3.712,0.034,5.581,0.068
	c1.152-1.63,2.278-3.277,3.465-4.881c-0.998-0.495-1.988-0.99-2.97-1.485c-0.606,0.401-1.212,0.811-1.801,1.237
	c0.905-1.331,1.775-2.679,2.611-4.036c-1.178-0.128-2.355-0.239-3.533-0.341c-0.324-0.324-0.964-0.973-1.289-1.297
	c-1.818,0.486-3.635,0.964-5.478,1.348c2.441-2.091,6.579-1.937,7.868-5.222c-2.825,0.154-5.564,0.828-8.252,1.638
	c0.273-0.375,0.828-1.143,1.101-1.519c0.58-0.077,1.741-0.222,2.321-0.299c0-0.495-0.009-1.485-0.009-1.98
	c-5.608,2.608-5.885,3.399-9.89-0.085c-0.478-0.068-1.425-0.205-1.903-0.282c-0.307-0.375-0.922-1.135-1.229-1.51
	c1.451-0.461,2.91-0.913,4.369-1.34c0.444,0.29,1.323,0.879,1.766,1.169c0.478-0.23,1.451-0.7,1.929-0.93
	c-1.075-0.887-2.15-1.784-3.2-2.688c1.536,0.085,3.072,0.213,4.617,0.333c0.435-1.553,0.862-3.106,1.246-4.651
	c-1.545,1.493-3.038,3.046-4.727,4.395c0.333-1.604,0.87-3.123,1.451-4.625c-1.536,0.614-3.038,1.314-4.48,2.15
	c-0.341-0.819-0.683-1.63-1.024-2.423c-0.435,0.222-1.306,0.666-1.741,0.896c-0.068-0.401-0.213-1.203-0.282-1.604
	c-1.963-0.384-3.9-0.811-5.82-1.323c1.545-0.273,2.97-0.845,4.258-1.707c1.417,1.783,3.482,3.055,5.803,2.219
	c-1.135-2.142-2.586-4.087-4.173-5.914c0.836-0.137,2.509-0.41,3.345-0.546c-0.64-1.212-1.877-1.638-3.123-1.937
	c0.7-0.683,1.391-1.365,2.082-2.039c0.734,0.367,1.459,0.725,2.202,1.109c-0.282,0.623-0.836,1.86-1.109,2.483
	c1.263-0.282,2.534-0.555,3.806-0.845c0.589,0.708,1.178,1.425,1.775,2.159c1.417,0.341,2.825,0.708,4.241,1.092
	c0.418-1.109,0.853-2.21,1.306-3.294c-1.971-1.86-4.292-3.294-6.98-3.797c0.486-0.623,0.973-1.237,1.468-1.852
	c-1.442-1.101-2.859-2.21-4.267-3.328c0.119-0.29,0.358-0.87,0.478-1.152c-0.529-1.69-0.998-3.388-1.442-5.086
	c-2.645-0.162-5.376-0.521-7.518-2.227c1.186-0.136,2.389-0.247,3.593-0.333c0.068-0.401,0.213-1.195,0.29-1.587
	c-1.075-0.435-2.142-0.862-3.2-1.289c-0.128-0.683-0.367-2.057-0.486-2.739c0.529,0.282,1.596,0.853,2.133,1.143
	c0.521-0.29,1.562-0.862,2.082-1.152c-0.068,1.024-0.154,2.031-0.23,3.063c1.638,0.853,2.97-0.555,4.292-1.314
	c0.913,1.084,1.843,2.185,2.782,3.268c-0.461,0.99-0.922,1.997-1.382,3.012c1.007-0.23,2.022-0.435,3.038-0.64
	c1.271,1.212,2.927,0.7,4.454,0.418c0.128-0.538,0.393-1.621,0.521-2.159c0.649-0.913,1.289-1.826,1.92-2.731
	c-1.399-0.094-2.79-0.179-4.181-0.247c0.956-0.64,1.92-1.271,2.884-1.886c0.009-0.538,0.009-1.613,0.009-2.15
	c-0.324,0.162-0.964,0.503-1.289,0.674c-0.785-3.098-4.582-0.657-6.741-0.922c0.427-0.435,1.271-1.314,1.69-1.758
	c-0.034-0.546-0.111-1.63-0.145-2.176c-0.546-0.273-1.638-0.819-2.185-1.092c0.691-0.99,1.451-1.929,2.202-2.867
	c2.099,0.563,3.558,2.125,5.018,3.627c1.271,0.666,2.031-0.572,2.79-1.348c1.033,0.674,2.065,1.34,3.115,2.014
	c0.196-0.538,0.597-1.621,0.794-2.167c2.099,1.306,0.899,0.651,4.813,1.946c2.014-0.299,4.028-0.623,6.016-1.033
	c5.567,4.896,5.543,3.658,9.984,3.123c0.265,1.007,0.597,1.997,0.998,2.978c1.835,0.725,3.797,1.05,5.709,1.536
	c-0.077-1.143-0.154-2.287-0.239-3.422c-1.007,0.401-2.005,0.802-3.004,1.212c0.614-0.853,1.229-1.69,1.86-2.509
	c-3.081-1.681-5.239-4.565-8.32-6.212c0.435-0.111,1.297-0.333,1.732-0.435c-0.205-0.922-0.401-1.826-0.589-2.722
	c2.313,2.594,5.623,3.755,8.849,4.779c0.23-1.758,0.486-3.507,0.785-5.248c1.067,1.212,2.142,2.458,3.251,3.644
	c-0.051-2.944-0.145-5.982-1.374-8.704c-3.008,0.204-4.23-0.226-5.026,1.493c0.879-0.034,2.645-0.111,3.533-0.154
	c-0.435,0.503-1.289,1.519-1.724,2.022c-1.442-1.092-2.91-3.814-4.335-1.041c2.313,0.316,2.722,1.809,0.759,2.603
	c-3.089-2.475-7.518-2.97-10.931-0.87c-1.451-0.435-2.918-0.845-4.275-1.493c0.606-0.998,1.425-1.775,2.466-2.33
	c-0.299-0.358-0.887-1.058-1.178-1.417c-1.638,0.375-3.26,0.794-4.873,1.254c-0.034-0.631-0.111-1.886-0.154-2.509
	c-0.879,0.717-1.749,1.468-2.62,2.21c-1.655,0.367-3.174,1.024-3.823,2.739c-0.316-0.725-0.648-1.459-0.964-2.159
	c1.374-0.657,2.586-1.553,3.712-2.56c0.068-0.563,0.196-1.69,0.265-2.253c-2.005,0.307-4.002,0.606-5.99,0.956
	c-0.512-0.674-1.033-1.331-1.545-1.988c1.536-0.896,3.541-1.493,3.985-3.473c-2.022,0.512-3.951,1.314-5.828,2.21
	c0.99-1.323,1.963-2.645,2.91-3.994c-1.903,0.435-3.78,0.939-5.683,1.382c-0.512-1.562-1.681-2.569-3.174-3.14
	c0.085-0.913,0.188-1.826,0.29-2.722c-0.725-0.051-2.159-0.162-2.876-0.222c-0.239-1.033-0.469-2.057-0.708-3.072
	c2.543,1.493,5.282,2.645,7.697,4.369c0.87,0.128,1.758,0.265,2.637,0.41c-0.213-0.444-0.631-1.314-0.845-1.749
	c0.529,0.009,1.596,0.034,2.125,0.043c0.845,1.348,1.707,2.688,2.56,4.045c1.63-0.469,3.251-0.947,4.881-1.434
	c-0.452,1.323-0.589,2.722-0.273,4.104c1.015-0.102,2.039-0.205,3.063-0.29c0.35-0.904,0.7-1.8,1.067-2.688
	c5.233,0.395,10.501,2.295,10.505,2.295c2.278-1.536,3.703-3.994,5.717-5.828c0.333,1.246,0.691,2.534,1.033,3.806
	c-0.631-0.137-1.894-0.401-2.526-0.529c-0.734,0.768-1.476,1.536-2.227,2.295c9.341,3.969,12.641-0.01,18.628,1.86
	c-0.614-3.439-1.869-6.81-4.011-9.583c-0.521,0.333-1.57,0.99-2.099,1.323c-0.563-1.818-0.939-3.678-1.169-5.555
	c-0.606,0.102-1.826,0.299-2.432,0.401c0.478-1.741,0.896-3.482,1.254-5.24c-0.486-0.034-1.451-0.094-1.929-0.128
	c-0.051,0.572-0.145,1.715-0.196,2.287c-1.041,0.922-2.074,1.852-3.081,2.816c0.051-0.555,0.154-1.664,0.205-2.219
	c-0.572,0.06-1.715,0.196-2.278,0.256c-2.074-3.161-11.937,0.23-14.387,0.614c-0.512-1.92-0.956-3.857-1.357-5.794
	c-1.673,0.068-3.345,0.077-5.009,0.026c1.724-1.084,3.934-1.468,5.214-3.174c-1.553,0.205-3.072,0.503-4.591,0.862
	c-1.075-1.024-2.133-2.065-3.174-3.106c0.367-0.469,1.092-1.408,1.459-1.869c-0.725-0.742-1.434-1.459-2.142-2.193
	c0.478,0.009,1.434,0.026,1.911,0.034c0.222-2.406,1.929-4.079,3.516-5.692c0.939,0.657,1.869,1.323,2.816,1.988
	c-0.512-1.254-1.041-2.5-1.562-3.738c2.057,0.691,4.122,1.399,6.05,2.415c0.905,0.034,1.818,0.077,2.722,0.119
	c-0.145,1.186-0.273,2.381-0.375,3.584c1.22-1.109,2.423-2.227,3.61-3.362c0.794,0.631,1.587,1.28,2.389,1.929
	c-1.015,0.666-1.997,1.382-2.987,2.082c0.981,0.384,1.971,0.777,2.97,1.169c0.009-0.606,0.017-1.835,0.026-2.449
	c0.512-0.222,1.545-0.683,2.057-0.913c3.294,1.314,7.262-0.29,10.189,2.005c-1.442,0.102-2.867,0.196-4.301,0.29
	c0.282,0.384,0.845,1.152,1.126,1.536c1.348,0.256,2.697,0.529,4.045,0.836c2.399-0.667,5.441-0.399,7.663,0.188
	c-0.503-1.485-0.998-2.961-1.519-4.42c2.039,0.222,4.079,0.469,6.11,0.811c0.324-0.708,0.657-1.399,0.99-2.099
	c-3.371,0.077-7.296-0.973-10.138,1.374c-1.135,0.034-2.253,0.068-3.371,0.094c2.253-2.569,6.485-3.738,6.639-7.68
	c-2.236,0.964-4.531,1.886-7.006,1.971c1.075-0.691,2.227-1.186,3.465-1.493c-0.222-0.265-0.683-0.802-0.905-1.067
	c0.794-1.229,1.502-2.509,2.057-3.857c-0.486,0.154-1.451,0.478-1.937,0.631c-2.654,3.661-5.99,7.262-10.581,8.303
	c1.971-2.44,4.907-3.891,6.69-6.502c0.845-0.708,1.69-1.399,2.543-2.082c-0.29-0.734-0.58-1.451-0.87-2.167
	c-1.818,1.476-3.499,3.123-5.419,4.471c-0.418-0.367-1.263-1.092-1.69-1.459c-0.913,0.58-1.826,1.169-2.731,1.775
	c0.614-1.254,1.22-2.5,1.818-3.755c-4.709,3.645-1.294,1.352-5.035,3.746c-0.546-1.485-1.425-2.714-3.063-2.995
	c-0.956,1.024-1.903,2.057-2.867,3.081c-0.597,0.068-1.195,0.145-1.792,0.23c0.708-0.555,1.408-1.092,2.116-1.63
	c0.128-0.452,0.384-1.357,0.512-1.809c1.246-1.016,4.122-1.638,2.714-3.729c-3.473,2.133-5.086,6.511-9.131,7.927
	c1.911-2.15,3.755-4.403,4.685-7.168c-5.586,0.871-3.918,0.414-8.823,2.543c-0.222-0.401-0.666-1.203-0.887-1.613
	c-2.765,1.331-5.316-0.393-7.817-1.451c1.22-1.51,2.441-3.021,3.635-4.54c0.836,0.282,1.681,0.563,2.526,0.853
	c-3.884-9.276-6.342-9.528-6.17-12.186c3.482,1.63,6.366,5.623,10.65,4.045c-0.06,0.427-0.171,1.297-0.222,1.724
	c-0.7,0.358-2.082,1.058-2.782,1.408c2.475,0.777,5.018-0.179,7.518-0.282c-1.101-1.186-2.219-2.347-3.268-3.567
	c3.541,1.51,7.031,3.482,11.042,2.85c0.444-0.853,0.896-1.707,1.357-2.551c1.306,0.034,2.628,0.077,3.951,0.111
	c-0.811,0.828-2.483,1.519-1.485,2.893c3.2,0.316,6.178,1.502,8.9,3.191c0.512-1.067,1.015-2.125,1.519-3.183
	c-0.759-0.777-1.519-1.562-2.261-2.33c1.954,0.145,3.917,0.358,5.871,0.648c1.442-2.961-2.398-3.217-4.454-3.465
	c1.476-0.691,2.492-1.911,3.183-3.362c-1.442,0.35-2.748,1.033-3.968,1.852c-0.29-0.171-0.862-0.503-1.152-0.674
	c-1.604,1.075-3.251,2.526-5.333,1.869c1.399-1.382,2.842-2.714,4.215-4.122c-1.596-0.512-3.183-1.015-4.77-1.527
	c-1.015,0.785-2.022,1.587-3.029,2.389c0.418-1.186,0.819-2.372,1.195-3.558c2.372-1.399,4.326-3.388,5.572-5.837
	c-9.056,4.7-3.369,4.804-17.655,4.087c0.768-0.922,1.545-1.826,2.304-2.748c-3.33,0.786-1.454,0.462-6.255,0.657
	c-0.23-0.23-0.683-0.7-0.905-0.939c0.35-0.597,1.067-1.783,1.417-2.381c-0.973,0.282-1.937,0.58-2.893,0.887
	c-2.048-0.862-4.002-1.92-5.837-3.149c1.826,0.051,3.661,0.145,5.504,0.094c-0.324-0.444-0.956-1.331-1.271-1.775
	c0.401-0.529,1.203-1.596,1.604-2.125c-1.212-2.543-4.326-3.558-5.99-5.828c1.604-0.017,3.106,0.521,4.497,1.289
	c1.212-0.794,2.398-1.613,3.61-2.406c0.034,0.7,0.102,2.116,0.137,2.816c1.169-0.128,2.347-0.247,3.524-0.341
	c0.589-0.623,1.178-1.237,1.775-1.843c-2.253-0.794-4.497-1.604-6.682-2.551c0.819-0.12,2.458-0.367,3.277-0.495
	c-1.126-1.519-2.236-3.063-3.285-4.616c3.575,1.263,7.168,2.534,10.607,4.156c0.239,0.35,0.708,1.058,0.947,1.408
	c2.074-1.015,4.181-1.946,6.289-2.893c-0.316,1.041-0.614,2.082-0.896,3.149c0.529-1.084,1.007-2.21,1.51-3.294
	c2.466,1.647,5.009,3.328,8.073,3.55c-0.162-1.271-0.324-2.534-0.478-3.797c1.109,0,2.219,0.009,3.336,0.026
	c0.29,0.435,0.87,1.297,1.161,1.724c1.587-0.051,3.174-0.154,4.753-0.418c0.017,0.358,0.051,1.058,0.068,1.408
	c2.816-1.075,5.734-1.98,8.798-1.911c0.606-0.521,1.212-1.041,1.826-1.553c0.529-0.068,1.587-0.205,2.125-0.282
	c0.29-0.683,0.589-1.357,0.887-2.022c1.314,0.128,2.637,0.171,3.959,0.094c-0.393-0.486-1.178-1.451-1.57-1.929
	c0.461-1.263,0.879-2.534,1.186-3.831c-0.981,1.075-1.852,2.253-2.679,3.456c-0.597-0.128-1.801-0.375-2.406-0.503
	c-0.657,1.007-1.297,2.039-1.946,3.063c-2.466,0.555-4.932,1.118-7.364,1.792c-3.055-0.555-7.1,1.084-9.233-1.86
	c1.05-1.28,2.671-1.835,4.028-2.705c0.64,0.657,1.271,1.331,1.911,2.022c0.879-0.785,1.758-1.553,2.628-2.338
	c0.41,0.009,1.22,0.009,1.621,0.009c0.819,0.973,1.655,1.946,2.492,2.927c1.143-0.444,2.287-0.87,3.439-1.297
	c-0.196-0.324-0.589-0.964-0.794-1.289c1.399-1.067,1.587-2.278-0.137-2.995c-1.289,1.05-2.611,2.048-3.951,3.038
	c-0.051-0.648-0.154-1.937-0.205-2.586c-2.176,0.828-4.352,1.664-6.571,2.381l0.555-2.33c-1.997,0.222-4.147,0.418-5.683-1.135
	c-0.555,0.555-1.092,1.126-1.63,1.707c1.263,0.529,2.534,1.109,3.806,1.647c-1.041,0.751-2.065,1.519-3.081,2.304
	c-1.929,0.162-3.849,0.418-5.734,0.811c0.222-0.623,0.666-1.86,0.887-2.475c-0.845,0.213-1.681,0.444-2.526,0.623
	c-0.119-0.512-0.367-1.527-0.486-2.039c-1.203,0.017-2.406,0.043-3.601,0.06c-0.777,0.751-1.553,1.51-2.321,2.278
	c-0.401-0.939-0.794-1.86-1.186-2.782c0.205-0.367,0.614-1.118,0.819-1.493c-1.826-0.802-3.678-1.553-5.589-2.099
	c-1.152-0.939-2.287-1.886-3.388-2.867c-0.23,0.043-0.7,0.111-0.939,0.154c-0.265-0.265-0.794-0.794-1.058-1.058
	c-1.647-0.188-3.285-0.486-4.77-1.237c1.067-0.614,2.15-1.22,3.234-1.809c-1.502-1.126-2.995-2.261-4.463-3.413
	c0.657-0.751,1.289-1.51,1.903-2.287c-0.452-0.375-1.365-1.109-1.818-1.476c0.051-0.239,0.154-0.725,0.196-0.964
	c0.845,0.247,1.69,0.495,2.543,0.759c0.282-0.452,0.845-1.357,1.126-1.809c2.964,0.655,7.511,7.689,13.935,7.049
	c0.051,0.572,0.154,1.724,0.205,2.295c0.649,0.017,1.946,0.06,2.594,0.085c-0.401-1.058-0.794-2.091-1.178-3.123
	c2.193-0.555,4.403-1.05,6.647-1.374c1.852-1.536,4.915-2.398,4.847-5.291c-1.084,0.666-2.133,1.382-3.166,2.116
	c-0.742-0.265-1.468-0.529-2.202-0.785c-0.828,0.555-1.647,1.143-2.517,1.638c-1.587-1.613-3.499-2.731-5.811-2.782
	c-0.717-0.981-1.382-2.005-2.202-2.884c-2.687-0.121-4.992,0.412-6.187,0.631c0.725-1.186,1.493-2.347,2.27-3.49
	c-2.313-1.007-4.71-1.783-6.963-2.91c-0.068-1.203,1.323-1.485,2.039-2.142c3.217,0,6.46,0.06,9.591,0.939
	c1.519,1.143,3.081,2.244,4.625,3.379c0.017-0.461,0.051-1.382,0.068-1.843c1.306,0.017,2.62,0.034,3.934,0.06
	c1.041,1.109,2.099,2.219,3.157,3.337c1.732-0.375,3.465-0.734,5.214-1.084c0.7-0.512,1.425-0.981,2.167-1.417
	c1.988,0.819,3.806,1.997,5.743,2.953c-0.213-1.152-0.418-2.287-0.631-3.422c1.604-1.553,3.499-3.055,3.951-5.367
	c0.316-0.299,0.956-0.879,1.28-1.169c-0.768-0.572-1.536-1.126-2.295-1.681c-1.408,0.495-2.833,0.956-4.258,1.408
	c-0.503,0.76-0.99,1.528-1.485,2.304c-0.666-0.64-1.314-1.271-1.971-1.894c-0.111,0.452-0.333,1.365-0.444,1.826
	c-0.367-0.23-1.109-0.691-1.476-0.922c-1.263,0.375-2.534,0.734-3.806,1.092c0.418-1.229,0.862-2.432,1.289-3.644
	c-1.553,0.375-3.123,0.725-4.676,1.067c0.375-0.99,0.768-1.971,1.16-2.953c-0.444,0.179-1.323,0.538-1.766,0.717
	c-0.171-1.451-2.261-3.9-0.06-4.608c2.449,1.067,5.06,1.519,7.74,1.408c-0.026-0.444-0.068-1.34-0.085-1.792
	c-1.178,0.23-2.347,0.469-3.507,0.708c0.222-0.973,0.452-1.946,0.691-2.91c-1.186-1.169-2.364-2.338-3.516-3.524
	c-0.461,0.034-1.374,0.102-1.835,0.145c-0.119-0.683-0.35-2.048-0.469-2.731c-2.679,0.401-5.376,0.836-8.09,0.631
	c1.169-0.683,2.381-1.289,3.635-1.801c-0.93-0.87-1.732-1.86-2.526-2.833c-3.234-0.87-6.468-1.843-9.489-3.302
	c0.495-0.179,1.476-0.555,1.963-0.742c-1.28-2.168-2.551-4.352-3.618-6.63c1.715,0.401,3.439,0.819,5.171,1.203
	c1.647,1.741,3.61,3.209,6.093,3.413c1.084,1.058,2.15,2.142,3.209,3.243c0.657-0.222,1.314-0.444,1.98-0.657
	c0.307,0.341,0.922,1.024,1.22,1.365c0.87-0.341,1.741-0.674,2.611-0.998c-0.043,0.725-0.137,2.185-0.188,2.91
	c1.877-0.136,3.763-0.384,5.564-0.981c0.64-1.109,1.314-2.202,1.988-3.285c-0.998-0.128-1.98-0.239-2.97-0.341
	c-4.659,1.408-9.122-0.905-12.774-3.593c0.521-0.828,1.041-1.647,1.562-2.458c1.109,0.35,2.21,0.751,3.354,1.041
	c0.93-0.324,1.229-1.391,1.775-2.108c1.527,0.469,3.046,0.196,4.454-0.529c0.137,0.418,0.418,1.263,0.555,1.681
	c0.324-0.111,0.973-0.333,1.297-0.435c0.649,0.589,1.306,1.186,1.971,1.792c2.142-1.604,5.18-2.441,6.195-5.154
	c-0.905,0.452-1.792,0.913-2.671,1.399c-6.445-1.938-7.434-1.42-9.591-2.688c-0.299-1.698,1.638-2.176,2.662-3.072
	c-3.012-0.691-6.135-1.69-8.303-4.019c0.666-0.171,1.988-0.512,2.645-0.674c-0.418-0.222-1.271-0.649-1.69-0.862
	c0.256-1.186,0.495-2.381,0.759-3.55c1.442,0.649,2.85,1.365,4.233,2.133c0.674-0.461,1.348-0.905,2.022-1.348
	c-0.435-0.529-1.314-1.587-1.758-2.116c-0.648,0.247-1.289,0.503-1.92,0.768c-0.811-0.785-1.621-1.57-2.423-2.347
	c0.589-0.674,1.169-1.34,1.766-2.005c-0.23-0.717-0.452-1.434-0.666-2.142c-0.418-0.316-1.246-0.956-1.664-1.28
	c0.179-0.503,0.521-1.527,0.691-2.031c1.792,0.324,3.49,1.101,4.019,3.004c0.947-0.333,1.894-0.666,2.85-0.981
	c-0.137,0.358-0.41,1.084-0.538,1.451c2.543,1.297,4.471,3.422,6.221,5.641c1.903-0.802,3.849-1.579,5.922-1.86
	c1.135,1.015,2.449,1.792,3.891,2.321c0.222-0.486,0.657-1.451,0.879-1.929c-0.99-0.58-1.988-1.143-2.918-1.783
	c0.273-0.606,0.802-1.809,1.075-2.415c-2.304-0.213-4.437,0.802-6.579,1.485l-0.034-0.905c0.418,0,1.263-0.009,1.681-0.017
	c0.034-0.708,0.085-2.142,0.119-2.85c-1.178,0.418-2.338,0.853-3.499,1.289c0.205-0.589,0.606-1.775,0.811-2.372
	c-0.87,0.777-1.698,1.587-2.517,2.406c-3.806-1.135-7.424-2.807-10.675-5.086c1.809-0.265,3.627-0.478,5.436-0.759
	c0.29,0.273,0.862,0.819,1.152,1.092c1.195-0.213,2.389-0.418,3.584-0.631c0.068,0.444,0.205,1.34,0.273,1.792
	c0.896-0.384,1.792-0.768,2.705-1.135c-0.35-0.853-0.683-1.69-1.007-2.534c0.913,0.265,1.826,0.529,2.756,0.802
	c2.031-1.229,3.413,0.939,4.932,1.971c0.708-0.87,1.417-1.732,2.133-2.586c0.905,0.521,1.809,1.041,2.731,1.562
	c0.93-0.657,1.869-1.306,2.825-1.946c-0.188-0.29-0.563-0.879-0.751-1.178c0.41-0.964,0.819-1.937,1.22-2.901
	c-1.698,0.375-3.379,0.794-5.06,1.186c-0.896,0.811-1.775,1.621-2.662,2.432c-0.077-0.666-0.239-2.005-0.316-2.68
	c-2.031,0.597-4.079,1.152-6.093,1.775c-0.358-0.239-1.067-0.717-1.425-0.956c-0.316,0.179-0.947,0.529-1.254,0.7
	c-1.084-0.265-2.159-0.512-3.226-0.751c0.299-0.358,0.905-1.067,1.203-1.425c-1.451-0.759-2.91-1.493-4.378-2.193l-0.077,1.28
	l-0.896-0.043c0.469-0.845,0.947-1.69,1.425-2.526c-1.681,0.068-3.721,1.451-5.129-0.051c0.87-0.759,1.724-1.527,2.586-2.295
	c-0.751-0.068-2.244-0.196-2.995-0.265c-0.777-1.101-1.553-2.21-2.295-3.319c1.289,0.333,2.577,0.683,3.874,1.015
	c0.009,0.418,0.009,1.263,0.017,1.681c0.435-0.333,1.314-0.998,1.749-1.323c0.7,0.649,1.408,1.297,2.116,1.963
	c0.7-0.333,1.399-0.666,2.108-0.981c0.444,0.299,1.323,0.905,1.758,1.203c0.444-0.333,1.331-0.99,1.775-1.314
	c-0.333-0.41-0.998-1.229-1.34-1.638c0.503-0.734,0.998-1.468,1.502-2.193c-4.405,0.551-4.253,0.811-10.505-0.35l-0.077-0.691
	c0.725-0.009,2.159-0.017,2.884-0.026c-0.068-0.503-0.196-1.519-0.256-2.022c-2.381-1.963-5.137-3.456-7.253-5.743
	c1.374,0.333,2.739,0.725,4.105,1.092c-0.606-0.708-1.212-1.408-1.826-2.099c-0.418-1.698-0.845-3.388-1.229-5.086
	c1.485,0.384,2.978,0.802,4.471,1.22c0.009,1.007,0.026,2.014,0.043,3.038c1.946,0.171,3.883,0.563,5.555,1.621
	c2.594-0.87,5.18-1.732,7.825-2.432c-0.29-0.922-0.572-1.826-0.836-2.731c-1.664-1.143-3.149-2.526-4.446-4.053
	c1.536,0.282,3.081,0.649,4.608,1.024c0.043-0.546,0.119-1.647,0.162-2.202c2.859-1.519,4.89-4.062,6.289-6.938
	c-2.466,0.589-4.975,0.358-7.39-0.333c0.623-0.87,1.246-1.724,1.869-2.577c-1.161,0.358-2.313,0.734-3.473,1.101
	c0.444-0.896,0.896-1.783,1.348-2.671c-0.811-0.017-2.415-0.051-3.217-0.077c0.009-0.273,0.017-0.828,0.026-1.101
	c-1.297-1.041-2.099-2.492-2.816-3.942c-0.597-0.043-1.792-0.111-2.389-0.145c0.41-0.879,0.828-1.749,1.254-2.62
	c-0.725-0.819-1.459-1.638-2.176-2.466c2.185-1.323,3.934,0.922,5.581,2.057c-0.333,0.435-0.998,1.314-1.331,1.758
	c1.843-0.666,3.908-1.101,5.188-2.722c0.691-0.154,2.065-0.452,2.748-0.597c-2.039-2.099-5.026-2.731-7.253-4.574
	c2.022,0.495,4.011,1.135,6.016,1.707c-0.913-2.313-2.961-3.686-5.001-4.907c1.621-0.35,3.251-0.708,4.855-1.143
	c0.384,0.273,1.152,0.811,1.536,1.075c0.589-0.495,1.178-0.981,1.766-1.468c-0.717-0.751-1.434-1.493-2.142-2.236
	c1.818,0.051,3.635,0.111,5.47,0.119c-2.227-2.91-6.4-2.432-9.651-2.731c1.323-0.964,2.637-1.937,3.925-2.944
	c-0.563-0.094-1.673-0.273-2.236-0.367c1.408-1.502,0.469-3.354,0.137-5.052c-0.674-0.486-1.348-0.973-2.014-1.459
	c-0.862-1.28-1.741-2.543-2.586-3.823c2.261,0.35,5.282,3.439,6.903,0.563c-1.28-0.196-2.551-0.384-3.823-0.555
	c-0.077-0.913-0.154-1.818-0.222-2.722c-0.708-0.751-1.408-1.51-2.091-2.27c-0.495-0.017-1.485-0.043-1.971-0.06
	c-0.768-1.203-1.519-2.423-2.202-3.669c1.579,0.853,3.098,1.809,4.659,2.697c0.162-0.819,0.307-1.63,0.444-2.441
	c0.597,0.333,1.792,0.981,2.389,1.314c-0.41,0.623-0.819,1.246-1.22,1.869c2.065-0.358,4.198,0.162,6.238-0.452
	c-0.034,0.666-0.111,1.98-0.154,2.645c0.922-0.845,1.852-1.681,2.773-2.517c1.357,0.495,2.714,0.973,4.087,1.459
	c0.717-1.135,1.451-2.27,2.176-3.396c-0.631-0.043-1.894-0.128-2.526-0.171c-1.067-1.101-2.193-2.133-3.174-3.302
	c0.563-0.725,1.186-1.382,1.801-2.048c-2.577-1.075-3.746-3.644-4.924-5.982c1.033-1.459,1.92-3.012,2.748-4.599
	c-0.589-0.068-1.775-0.213-2.364-0.282c0.444-0.418,1.34-1.254,1.783-1.673c-0.521-0.777-1.033-1.553-1.545-2.33
	c0.435-0.29,1.306-0.879,1.741-1.169c0.828,0.358,1.664,0.725,2.5,1.092c0.017-0.597,0.06-1.792,0.077-2.381
	c-0.358-0.299-1.075-0.896-1.434-1.195c0.649-1.954-0.094-3.925-0.213-5.888c0.64-1.109,1.519-2.048,2.33-3.012
	c0.068-0.469,0.205-1.399,0.273-1.869c0.375-0.341,1.118-1.033,1.493-1.374c1.05-3.43,1.092-7.023,0.964-10.564
	c0.879-0.759,1.766-1.51,2.662-2.253c-0.23-0.213-0.708-0.648-0.947-0.862c0.478-0.845,0.964-1.698,1.451-2.543
	c0.239,1.084,0.486,2.167,0.742,3.251c-0.145,0.316-0.427,0.939-0.572,1.246c0.307,0.043,0.93,0.111,1.246,0.154
	c0.64,0.657,1.289,1.314,1.954,1.963c-0.273,1.092-0.538,2.185-0.785,3.285c0.836-0.316,1.681-0.64,2.517-0.956
	c0.367,0.273,1.101,0.836,1.468,1.118c-1.109,0.486-2.116,1.143-2.603,2.33c1.459-0.222,2.918-0.538,4.309-1.05
	c1.135,0.631,2.278,1.263,3.43,1.877c-0.606-1.519-1.28-3.004-2.048-4.446c0.461-0.043,1.374-0.119,1.835-0.162
	c-2.808-4.235-1.369-5.635,0.128-10.052c0.606,0,1.826,0,2.432,0c-0.324-0.486-0.981-1.468-1.306-1.963
	c0.631-0.58,1.271-1.161,1.911-1.741l0.137,1.109l-1.357,0.102c0.444,0.273,1.34,0.811,1.792,1.075
	c0.41,1.058,0.93,2.091,1.809,2.85c-0.256,0.333-0.777,0.998-1.041,1.331c0.375,0.282,1.118,0.862,1.493,1.143
	c0.427-0.145,1.289-0.444,1.715-0.589c-0.666,1.604-1.399,3.183-1.937,4.838c0.444,0.162,1.331,0.495,1.775,0.657
	c-1.015,1.399-2.005,2.816-2.987,4.241c0.649,0.503,1.314,0.99,2.005,1.459c1.161-0.64,2.236-1.434,3.345-2.159
	c-0.324,1.476-0.623,2.953-0.896,4.446c-0.922,0.853-1.843,1.715-2.756,2.586c0.469,0.068,1.417,0.196,1.886,0.256
	c-0.026,0.794-0.043,1.587-0.043,2.389c-1.075,0.486-2.15,0.981-3.209,1.485c2.185,2.517,5.265,0.375,7.663-0.563
	c-1.075,1.536-3.695,2.645-2.978,4.864c-0.145,0.819-0.29,1.647-0.427,2.475c1.997-1.28,3.9-2.867,6.315-3.302
	c-3.378,3.241-3.251,2.982-4.983,3.866c0.341,2.526-1.587,4.83-1.067,7.245c1.749,0.017,3.567-0.469,5.231,0.29
	c-0.922,0.785-1.835,1.57-2.739,2.364c0.879,0.068,2.645,0.188,3.524,0.247c-0.768,0.708-1.519,1.425-2.27,2.142
	c0.546,0.162,1.63,0.478,2.176,0.64c-1.698,0.922-3.029,2.304-4.181,3.831c0.051,0.879,0.111,1.766,0.154,2.654
	c0.222,0.247,0.674,0.742,0.896,0.99c0.358-0.299,1.075-0.879,1.434-1.169c-0.375,1.015-0.742,2.039-1.109,3.072
	c0.947-0.393,1.894-0.785,2.842-1.169c-0.273,0.435-0.819,1.314-1.092,1.758l1.988,0.077c0.265,2.628-1.399,4.284-3.686,5.231
	c0.725,0.256,2.176,0.768,2.901,1.033c-0.862,0.58-1.724,1.152-2.577,1.741c0.171,0.324,0.521,0.981,0.7,1.314
	c-1.109,0-2.21,0-3.311,0.009c-0.273,0.375-0.811,1.118-1.084,1.493c1.084,0.674,2.176,1.348,3.268,2.031
	c2.014-0.179,4.122-0.23,5.325,1.69c-0.265,0.478-0.802,1.434-1.075,1.911c1.911,0.265,3.695-0.35,5.308-1.34
	c-0.393-0.333-1.169-0.998-1.562-1.331c0.324-2.876-1.101-5.248-2.97-7.27c2.048,1.357,4.079,2.739,6.178,4.019
	c-0.725-3.123-1.749-6.298-1.058-9.515c-0.597-0.683-1.186-1.357-1.766-2.022c1.101-0.085,2.21-0.162,3.319-0.23
	c-1.041-1.058-2.099-2.091-3.174-3.098c0.521-0.026,1.562-0.068,2.082-0.094c-0.256-0.973-0.512-1.937-0.759-2.893
	c0.051-0.247,0.154-0.742,0.205-0.998c-0.375-0.751-0.742-1.493-1.092-2.236c0.922-1.348-0.026-2.475-0.802-3.55
	c1.195-2.398,1.579-5.137,0.597-7.689c1.169,1.101,2.381,2.167,3.61,3.209c0.862-0.896,1.732-1.783,2.603-2.671
	c-0.256-0.708-0.503-1.408-0.742-2.108c0.134-1.885,0.225-3.92-2.33-8.09c0.64-0.162,2.594,1.246,2.338-0.094
	c-0.589-2.492,1.28-4.318,2.048-6.494c-0.111-1.169-0.222-2.338-0.341-3.499c0.341-1.434,0.691-2.867,1.143-4.275
	c-0.009,1.289-0.051,2.577-0.111,3.866c0.188,0.29,0.563,0.853,0.759,1.143c-0.23,0.998-0.213,2.005,0.051,3.004
	c-0.358,0.239-1.058,0.725-1.408,0.973c0.998,1.169,2.082,2.278,3.311,3.217c0.034,0.418,0.094,1.254,0.128,1.664
	c0.913,0.418,1.826,0.845,2.739,1.271c-0.222,3.2-1.229,6.263-2.594,9.148l0.555,0.256c-0.324,0.939-0.631,1.877-0.93,2.825
	c1.997-0.589,3.635-1.835,4.932-3.43c-0.306,3.653-0.088,4.844,0.111,6.204c-0.375,0.717-0.734,1.451-1.084,2.185
	c-0.137,1.485-0.316,2.953-0.452,4.446c1.024-0.162,2.057-0.324,3.098-0.486c0.683-1.024,1.382-2.022,2.116-3.012
	c-0.179,0.461-0.538,1.391-0.708,1.852c0.282,1.186,0.597,2.372,0.768,3.593c-1.417,0.41-2.927,0.597-4.13,1.519
	c-0.572-0.35-1.724-1.058-2.304-1.417c0.469,0.785,0.981,1.536,1.553,2.253c1.442-0.043,2.859-0.341,4.301-0.495
	c0.017,2.884-2.193,4.378-4.702,5.069c1.314,0.077,2.637,0.171,3.959,0.282c-1.664,0.683-3.345,1.331-5.018,1.997
	c0.631,0.512,1.263,1.033,1.911,1.553c-0.657,0.828-1.314,1.655-1.937,2.509c1.545-0.222,2.995-0.785,4.25-1.724
	c-0.384-0.444-1.161-1.323-1.545-1.758c1.246-0.418,2.5-0.845,3.763-1.254c-0.273,0.546-0.819,1.63-1.084,2.167
	c1.348-0.555,2.705-1.126,4.053-1.698c0.068,0.495,0.188,1.485,0.256,1.98c-1.067,1.195-2.142,2.372-3.354,3.422
	c0.154-0.572,0.461-1.724,0.614-2.304c-0.469,0.034-1.408,0.085-1.869,0.111c-0.751,0.964-1.51,1.92-2.278,2.876
	c1.271,0.503,2.526,1.058,3.755,1.681c-0.572,1.323-1.109,2.662-1.647,4.002c-0.213-0.29-0.648-0.879-0.862-1.169
	c-3.067,5.238-2.84,4.74-0.222,5.47c1.391-1.05,3.106-1.05,4.753-0.956c-1.442,0.768-2.961,1.374-4.506,1.886
	c-0.717,1.348-1.425,2.705-2.125,4.07c1.058,0,2.125,0.017,3.2,0.034c1.015,1.118,2.082,2.202,2.995,3.422
	c-0.734,2.065-3.447,1.468-5.154,1.758c0.333,1.229,0.649,2.466,0.956,3.712c2.628,0.683,5.222,1.758,7.987,1.724
	c-0.845,0.725-1.69,1.459-2.526,2.202c1.232,1.151,11.503-1.65,12.749-1.886c-1.34,1.169-2.765,2.244-4.19,3.302
	c-1.22,0.375-2.441,0.785-3.644,1.195c-0.7,1.374-1.408,2.739-2.142,4.105c-0.546-0.239-1.647-0.717-2.193-0.947
	c0.862,2.33,3.721,1.519,5.641,1.946c-0.358,1.007-0.708,2.022-1.041,3.038c-0.555,0.666-1.092,1.331-1.63,1.997
	c1.92-0.009,3.857-0.009,5.794-0.017c0.666-0.666,1.348-1.314,2.031-1.963c0.026,0.649,0.068,1.963,0.094,2.62
	c-1.382,1.169-2.816,2.287-4.343,3.26c-1.664-0.256-3.337-0.444-4.992-0.614c-0.034,0.358-0.102,1.092-0.137,1.459
	c-1.468,0.256-2.901,0.657-4.292,1.169c1.033,0.913,2.082,1.826,3.14,2.731c0.384-0.478,1.152-1.425,1.536-1.894
	c0.393,0.358,1.169,1.084,1.562,1.451c0.589-0.171,1.775-0.521,2.364-0.7l-0.068,0.657c-0.717,0.324-2.159,0.973-2.876,1.297
	c2.142,1.015,5.53,0.913,5.931,3.917c0.529-0.939,1.041-1.886,1.545-2.842c2.458-1.852,2.207-1.208,7.211-4.028
	c0.119,2.014,0.29,4.028,0.717,6.007c-1.596,2.142-2.253,4.693-2.389,7.322c-1.306,0.077-2.611,0.162-3.9,0.239
	c-0.009,0.58-0.017,1.732-0.026,2.304c-1.647-0.316-3.285-0.657-4.924-0.973c0.87,1.357,1.775,2.705,2.662,4.062
	c-1.63-0.043-3.251-0.051-4.881,0c0.307,1.22,0.623,2.441,0.939,3.669c1.894-0.435,3.84-1.195,5.786-0.478
	c0.649-0.538,1.289-1.075,1.946-1.596c0,0.597-0.009,1.783-0.017,2.381c-4.812,2.394-4.758,2.007-6.059,7.245
	c1.63,0.051,3.26,0.102,4.89,0.188c-1.152,2.458-3.831,3.081-6.135,3.968c1.135,0.896,2.492,0.683,3.797,0.341
	c0.205,0.316,0.614,0.956,0.819,1.271c0.853-0.879,1.724-1.749,2.594-2.611c0.06,0.495,0.171,1.485,0.222,1.98
	c0.631-0.009,1.894-0.043,2.517-0.06c-0.486,1.084-2.057,2.031-1.613,3.328c0.512,0.35,1.519,1.041,2.022,1.391
	c-0.009-0.435-0.034-1.297-0.051-1.724c0.905,1.118,1.826,2.219,2.756,3.328c1.161-0.768,2.338-1.519,3.507-2.261
	c0.043,0.486,0.128,1.451,0.179,1.929c0.367,0.008,1.118,0.017,1.493,0.026c-0.299,1.937-0.393,3.908-0.196,5.871
	c-1.468,1.075-2.884,2.236-4.19,3.499c-0.171,0.922-0.358,1.835-0.538,2.765c0.247,0.384,0.742,1.143,0.99,1.527
	c-0.683,0-2.057,0.009-2.748,0.009c0.06,1.34,0.128,2.688,0.188,4.036c1.109,0.324,2.227,0.666,3.345,1.024
	c-0.239,0.529-0.708,1.579-0.939,2.099c1.741-0.998,3.456-2.039,5.188-3.063c-0.7-0.913-1.399-1.826-2.065-2.739
	c2.483,1.297,4.907,2.756,7.62,3.524c-1.195-1.161-2.586-2.065-4.002-2.893c1.143-0.768,2.202-1.724,3.507-2.236
	c1.57,0.205,3.106,0.64,4.676,0.956c-1.51-0.981-3.149-1.724-4.659-2.688c-0.512-1.877,0.495-3.524,1.493-5.018
	c-0.589-0.708-1.169-1.417-1.741-2.116c0.649-0.23,1.937-0.708,2.577-0.947c-1.254-1.664-2.381-3.447-2.731-5.538
	c1.348,0.29,2.714,0.623,4.07,0.973c-0.913-1.306-1.809-2.611-2.62-3.968c-0.384-0.068-1.161-0.222-1.553-0.299
	c-0.759-1.186-1.545-2.355-2.313-3.524c1.229,0.683,2.466,1.382,3.703,2.091c0.444-0.333,1.34-0.998,1.783-1.331
	c-0.495-0.7-0.99-1.399-1.476-2.091c0.358-1.374,0.734-2.731,1.126-4.087c-0.589-0.853-1.161-1.698-1.724-2.543
	c0.529-0.265,1.596-0.802,2.125-1.067c-0.316-0.461-0.939-1.365-1.254-1.818c0.981-1.101,1.323-3.823,3.2-3.371
	c1.084,2.671,1.135,5.709,2.756,8.175c0.077,0.512,0.23,1.536,0.307,2.048c-0.674,0.427-1.331,0.853-1.988,1.289
	c0.495,0.375,1.485,1.126,1.988,1.493c-0.717,0.802-1.434,1.596-2.125,2.415c1.63,0.358,3.294,0.23,4.949,0
	c-0.819,1.28-1.621,2.568-2.466,3.84c0.469,0.913,0.93,1.826,1.408,2.756c-1.792,1.178-3.814,2.219-4.83,4.215
	c1.101-0.222,2.219-0.444,3.328-0.674c1.229,1.033,2.5,2.022,3.669,3.132c-0.145,1.161-0.444,2.295-0.666,3.447
	c1.382,0.435,2.679,1.05,3.678,2.15c-2.21,0.026-4.753-0.836-6.656,0.7c1.126,0.725,2.287,1.476,3.43,2.21
	c-0.666,0.171-1.997,0.512-2.662,0.683c2.323,2.231,14.002,3.751,15.002,3.84c0.964-0.614,1.212-1.877,1.818-2.79
	c-7.267-2.677-6.792-2.869-11.375-5.572c2.91-0.154,5.828-0.179,8.755-0.179c-0.631-0.794-1.254-1.579-1.877-2.364
	c0.87-0.452,1.749-0.905,2.628-1.34c-0.077-0.998-0.145-1.988-0.196-2.978c-0.35-0.265-1.058-0.794-1.408-1.058
	c0-0.606,0.009-1.818,0.009-2.423c0.572-0.026,1.715-0.068,2.287-0.085c0.666-0.742,1.348-1.468,2.031-2.185
	c-1.007-1.937-1.084-4.07-0.367-6.101c-1.971-2.372-4.292-4.471-7.194-5.598c-0.222-0.341-0.657-1.015-0.879-1.348
	c0.503-0.017,1.493-0.043,1.988-0.06c1,0.751,6.111,5.937,8.525,2.219c1.57,0.418,3.14,0.862,4.719,1.323
	c-0.265,0.444-0.777,1.323-1.033,1.766c2.458-0.393,4.881-0.964,7.305-1.536c-0.811-2.944-4.07-0.145-5.897,0.128
	c0.469-1.015,0.947-2.031,1.417-3.046c-1.323,0.068-6.263,0.299-7.398,0.333c0.247-0.384,0.734-1.161,0.981-1.553
	c-0.555-0.162-1.664-0.478-2.219-0.64c0.589-0.7,1.22-1.357,1.852-2.022c-1.51-0.802-2.987-1.63-4.48-2.432
	c0.265-0.341,0.794-1.015,1.058-1.357c-0.538-0.034-1.613-0.102-2.15-0.137c-0.7-0.725-1.391-1.442-2.099-2.142l-0.162-0.401
	c0.486,0.06,1.459,0.188,1.946,0.256c1.169-1.579,2.799-0.7,4.258-0.273c-0.282,0.495-0.836,1.476-1.118,1.971
	c1.314,1.178,2.27,3.191,4.224,3.337c1.22-1.451-0.452-2.176-1.468-2.876c-0.068-1.246-0.128-2.475-0.171-3.712
	c0.759-0.375,1.519-0.742,2.278-1.109c0.435,0.845,0.904,1.681,1.417,2.5c1.544-0.06,3.046-0.452,4.557-0.785
	c0.392-0.964,0.802-1.92,1.229-2.876c-0.486,0.017-1.459,0.06-1.946,0.077c0.265-1.314,0.546-2.62,0.845-3.925
	c-0.384-0.333-1.143-1.007-1.527-1.34c-0.043-1.041-0.094-2.074-0.128-3.106c0.589-0.845,1.195-1.681,1.801-2.517
	c-1.143-0.034-2.278-0.051-3.405-0.068c0.009-1.212,0.026-2.423,0.051-3.627c0.546,0.23,1.63,0.7,2.176,0.93
	c1.143-0.375,2.261-0.777,3.413-1.143c-0.93-1.246-2.15-2.159-3.695-2.449c-0.683-0.614-1.357-1.229-2.022-1.835
	c1.152-0.171,2.304-0.341,3.482-0.486c0.222-1.143,0.384-2.295,0.247-3.456c0.401,0.06,1.212,0.162,1.613,0.222
	c-0.154-0.316-0.469-0.93-0.631-1.237c0.307-1.57,0.119-3.157-0.102-4.71c0.427-0.205,1.297-0.614,1.732-0.828
	c-0.393-0.538-1.178-1.604-1.562-2.142c1.775-1.126,3.686-2.014,5.7-2.637c-0.247,2.654-1.101,5.325-0.478,7.987
	c-0.137,0.102-0.401,0.316-0.538,0.418c3.234,1.092,2.807,4.881,3.618,7.569c0.768-0.828,1.536-1.655,2.313-2.466
	c0.213,0.393,0.64,1.195,0.862,1.596c-1.717,2.576-3.154,0.811,0.828,6.741c0.452-0.205,1.357-0.623,1.809-0.836
	c-0.623,1.007-1.246,2.014-1.86,3.029c-1.485,0.418-2.952,0.862-4.412,1.314c1.101,1.101,2.202,2.202,3.311,3.319
	c0.026,1.553,0.128,3.106,0.222,4.676c-0.734,0.171-2.21,0.521-2.944,0.7c1.289,1.229,2.679,2.364,4.104,3.456
	c-0.35,1.135-0.7,2.27-1.05,3.413c2.021,0.715,1.147,0.496,5.658,2.867c-0.768,0.051-2.295,0.154-3.063,0.196
	c0.009,0.529,0.009,1.57,0.009,2.099c-1.229,0.606-4.156,0.691-3.268,2.722c2.236,0.282,4.497,0.256,6.758,0.452
	c-1.195,0.299-2.398,0.58-3.593,0.853c0.265,0.367,0.794,1.092,1.058,1.451c-0.853-0.358-1.69-0.708-2.526-1.058
	c-0.051,0.521-0.162,1.545-0.213,2.065c-0.546-0.188-1.63-0.563-2.176-0.751c0.162,0.751,0.495,2.253,0.666,3.004
	c1.963-0.265,3.934-0.512,5.914-0.777c-0.469,1.033-0.922,2.082-1.365,3.132c-0.845,0.316-1.673,0.64-2.5,0.973
	c-0.683-0.324-1.348-0.649-2.014-0.956c-0.068,1.143-0.128,2.287-0.188,3.447c2.321-0.469,4.685-0.597,7.048-0.614
	c-0.068,0.99-0.119,1.98-0.162,2.987c1.903,0.768,2.807,2.313,2.679,4.361c1.698-0.188,3.405-0.307,5.129-0.401
	c-0.162-0.299-0.495-0.896-0.657-1.195c0.495-2.347,0.674-5.769-1.911-6.938c0.29-0.717,0.589-1.425,0.896-2.125
	c0.99,0.666,1.997,1.323,3.021,1.98c-0.563-1.246-1.126-2.475-1.681-3.703c-1.152,0.009-2.295,0.017-3.43,0.034
	c1.178-1.57,2.33-3.166,3.524-4.727c-0.964-1.246-1.903-2.509-2.825-3.78c1.271-0.418,2.551-0.811,3.84-1.186
	c-0.29-0.35-0.879-1.058-1.169-1.417c1.22-0.401,2.458-0.794,3.703-1.152c0.486-1.579,0.964-3.149,1.468-4.71
	c-0.589-0.469-1.169-0.93-1.741-1.382c1.792-2.688-0.102-5.76,0.043-8.678c0.486-0.341,1.451-1.024,1.929-1.374
	c-0.29-0.444-0.87-1.34-1.161-1.783c1.502,0.085,3.021,0.205,4.54,0.307l-0.009,0.862c-0.427-0.094-1.289-0.282-1.715-0.375
	c-0.162,3.507,0.111,7.006,0.393,10.505c-0.282,0.375-0.836,1.143-1.118,1.519c-0.068,1.143-0.12,2.304-0.154,3.465
	c0.606,0.094,1.818,0.282,2.423,0.367c0.666,0.785,0.913,1.843,1.331,2.782c-1.032,0.614-2.031,1.271-3.038,1.929
	c2.748,0.256,5.495,0.819,8.269,0.589c-1.092,0.717-2.167,1.442-3.268,2.133c-0.213-0.307-0.631-0.922-0.845-1.229
	c-0.256-0.094-0.768-0.282-1.032-0.367c0.316,1.732,0.853,3.43,1.425,5.111c1.101,0.358,2.202,0.717,3.319,1.084
	c-0.213,0.265-0.657,0.785-0.879,1.05c1.254,0.785,2.483,1.604,3.721,2.432c-1.237,1.05-2.483,2.099-3.738,3.123
	c0.35,0.947,0.708,1.894,1.067,2.859c1.306-0.939,2.603-1.903,3.891-2.876c0.316,0.418,0.947,1.271,1.263,1.69
	c-1.152,0.384-2.295,0.768-3.43,1.143c-0.316,1.34-0.623,2.688-0.947,4.036c1.553,0.017,3.115,0.043,4.685,0.128
	c-2.031,1.092-4.181,1.954-6.366,2.679c0.034,1.007,0.06,2.022,0.094,3.038c5.745,6.026,10.182,2.934,13.551,1.57
	c-0.282,0.478-0.862,1.442-1.152,1.929c-0.521,0.009-1.562,0.034-2.082,0.043c-0.776,0.836-1.553,1.664-2.321,2.517
	c-1.289,0.017-2.577,0.043-3.849,0.077c-0.06-0.521-0.171-1.562-0.23-2.091c-0.563,0.674-1.126,1.348-1.672,2.031
	c0.29,0.444,0.87,1.323,1.161,1.758c-0.35,0.401-1.058,1.195-1.417,1.587c0.717,0.845,1.434,1.707,2.159,2.569
	c0.742-0.23,2.219-0.683,2.961-0.905c-1.271,0.802-2.586,1.536-3.9,2.261c0.247,0.273,0.734,0.828,0.981,1.101
	c1.818-0.555,3.644-1.084,5.487-1.579c-0.973,1.348-2.116,2.594-2.918,4.07c0.23,1.289,0.606,2.543,0.939,3.823
	c1.357-0.435,2.722-0.862,4.062-1.391c0.179-1.459-0.785-2.662-1.323-3.917c1.476,0.205,2.978,0.375,4.48,0.35
	c-0.375-0.461-1.135-1.382-1.51-1.843c0.435-0.316,1.306-0.956,1.732-1.271c-0.742-1.024-1.476-2.056-2.185-3.089
	c-0.905-0.06-1.801-0.111-2.688-0.154c-0.401-0.905-0.794-1.801-1.186-2.688c2.253,0.375,4.514,0.802,6.793,1.118
	c0.043-1.101,0.085-2.193,0.137-3.285c-0.478-0.119-1.434-0.375-1.92-0.495c1.101-0.742,2.202-1.493,3.294-2.253
	c1.911,0.23,3.823,0.469,5.76,0.495c-1.502-1.101-3.507-1.408-4.907-2.586c-0.635-1.904-0.475-2.689-1.314-8.175
	c0.666-0.034,1.988-0.094,2.654-0.119c-0.862-0.896-1.724-1.792-2.569-2.671c0.836-1.758,1.51-3.592,1.852-5.513
	c0.504-0.538,1.016-1.075,1.536-1.604c-0.563-0.41-1.681-1.237-2.244-1.647c0.512-0.93,1.084-1.818,1.732-2.662
	c0.555-2.509,1.698-4.813,3.063-6.972c-0.905-0.461-1.801-0.905-2.688-1.348c-0.009-0.546-0.026-1.638-0.043-2.185
	c0.725,0.324,1.451,0.649,2.193,0.981c0.299-0.589,0.896-1.775,1.186-2.364c0.026,1.527,0.051,3.072,0.068,4.617
	c1.101,0.768,2.202,1.536,3.336,2.278c0.085-2.509,0.12-5.018,0.017-7.526c-1.417-1.391-2.594-2.995-3.703-4.625
	c0.461-0.077,1.365-0.23,1.826-0.307c0.367-1.69,0.794-3.362,1.22-5.026c0.614-0.068,1.843-0.188,2.458-0.256
	c-0.282,0.512-0.845,1.545-1.126,2.056c0.631,0.623,1.28,1.254,1.937,1.886c0.162,1.408,0.324,2.833,0.188,4.258
	c0.222,0.358,0.674,1.084,0.905,1.442c-0.742,0-2.227,0-2.97,0.009c0.299,2.517,1.237,5.094,0.461,7.629
	c1.587,1.519,4.685,2.381,4.83,4.821c-1.186,1.033-2.492,1.894-3.755,2.825c0.922,0.375,1.869,0.708,2.842,1.007
	c1.323-0.648,2.415-1.672,3.652-2.475c0.657,0.341,1.306,0.725,1.954,1.126c-1.032,0.563-2.056,1.118-3.089,1.681
	c0.41,0.802,0.811,1.604,1.229,2.423c-1.348,0.307-2.714,0.597-4.053,0.913c1.254,1.203,2.654,2.415,4.284,0.973
	c0.734,0.247,1.476,0.521,2.219,0.811c-0.418,0.631-0.845,1.263-1.289,1.877c0,0.247-0.009,0.734-0.017,0.981
	c-1.135,1.382-2.304,2.748-3.473,4.113c2.057,0.196,4.147,0.444,6.033,1.365c-2.142,1.152-4.412,2.159-6.221,3.84
	c1.468,0.085,2.953,0.162,4.437,0.213c0.666-0.674,1.348-1.348,2.039-2.005c0.461,0.35,1.391,1.058,1.852,1.417
	c-0.956,1.024-1.911,2.057-2.85,3.098c-1.502,0.034-3.004,0.077-4.489,0.128c2.125,2.082,4.343,4.105,6.025,6.588
	c0.128-0.034,0.393-0.102,0.529-0.128c0.725,1.758,2.586,1.971,4.25,2.278c-0.58,0.58-1.161,1.169-1.732,1.766
	c0.188,0.324,0.572,0.973,0.759,1.297c0.683,0.034,2.057,0.111,2.748,0.145c-0.35,0.862-0.7,1.724-1.041,2.594
	c0.853,2.21,1.784,4.625,4.275,5.419c0.068-2.125-2.321-5.547,1.033-6.101c-0.555-0.759-1.092-1.51-1.621-2.253
	c0.333-0.273,1.015-0.811,1.357-1.075c-0.879-2.833-0.068-5.914-1.442-8.644c2.586-0.341,0.853-2.773,0.623-4.335
	c0.939-0.299,1.877-0.58,2.816-0.87c-0.41,0.862-0.811,1.732-1.212,2.603c1.476-0.333,3.234-0.119,4.429-1.246
	c0.444-2.219-1.741-3.311-3.149-4.48c0.111-0.282,0.333-0.828,0.444-1.109c-0.657-1.442-0.691-2.978-0.145-4.463
	c0.657-3.558-2.15-6.921-0.794-10.368c-0.341-0.973-0.666-1.946-0.981-2.918c3.456,1.391,5.581,4.617,7.62,7.569
	c1.425-0.23,2.859-0.461,4.292-0.717c-1.195-1.05-2.56-1.869-3.977-2.551c-0.205-2.108,0.137-4.437-1.493-6.084
	c-0.085-0.922-0.154-1.835-0.213-2.748c0.401,0.179,1.203,0.538,1.604,0.717c0.384-0.691,0.776-1.365,1.186-2.039
	c1.126,0.683,2.338,1.152,3.644,1.408c-3.652-2.33-5.734-6.101-7.612-9.873c2.91,1.314,5.385,3.473,6.912,6.298
	c0.222-0.171,0.674-0.529,0.896-0.7c-0.832-2.214-0.114-2.665,0.358-12.51c0.853-0.7,1.715-1.408,2.577-2.116
	c1.143,0.7,2.304,1.399,3.49,2.074c-0.06-1.203-0.12-2.398-0.171-3.584c-0.384-0.307-1.135-0.905-1.519-1.203
	c0.452-1.212,0.99-2.534-0.794-2.739c0.418-1.024,0.956-2.057,0.631-3.191c-1.075,0.256-2.116,0.606-3.149,0.939
	c0.213-1.212,0.401-2.423,0.589-3.627c-1.178-0.981-2.159-2.15-2.995-3.413c3.948,2.329,3.568,2.266,4.924,3.721
	c-0.111-1.758-0.819-3.302-2.091-4.497c0.666,0.026,1.98,0.077,2.637,0.102c0.009-0.58,0.034-1.749,0.043-2.338
	c0.427-0.341,1.28-1.015,1.707-1.357c0.384,0.341,1.152,1.007,1.536,1.34c0.299-0.478,0.887-1.451,1.186-1.937
	c-0.307-0.427-0.913-1.271-1.22-1.698h0.956l0.717,1.374c0.512-1.638,0.171-3.251-0.461-4.787c1.041-1.058,2.116-2.082,3.226-3.072
	c-0.58-2.261-0.623-4.574,0.051-6.818c-0.538-0.495-1.067-0.99-1.587-1.476c0.546-1.835,1.058-3.678,1.596-5.521
	c1.963,0.708,4.028,1.229,6.042,0.333c-0.691,1.05-1.553,1.963-2.91,2.057c0.734,0.828,1.476,1.673,2.21,2.517l-1.058,0.06
	l-0.213-1.289c-0.205,0.393-0.623,1.169-0.828,1.562c1.135,0.725,2.278,1.493,3.405,2.27c-0.12,0.299-0.358,0.905-0.478,1.212
	c1.519,2.347,1.382,5.137,1.613,7.808c0.794,0.742,1.707,1.357,2.594,2.005c-0.691,0.094-2.074,0.273-2.765,0.358
	c0.162,1.323,0.341,2.645,0.529,3.977c0.674,0,2.039-0.009,2.722-0.009c0.273,2.833,1.271,5.717,0.393,8.55
	c1.152,0.828,2.321,1.647,3.499,2.458c-0.674,0.094-2.039,0.273-2.722,0.367c0.751,0.623,1.519,1.254,2.295,1.877
	c-0.7,2.756-1.109,5.598-1.126,8.457c1.058,0.529,2.005,1.297,2.85,2.15c-1.237,2.697-1.365,5.717-1.084,8.636
	c1.57-0.043,3.14-0.068,4.719-0.085c-0.529,1.587-1.05,3.174-1.562,4.779c2.133-1.51,4.079-3.251,5.939-5.086
	c-0.324,2.142-0.776,4.258-1.246,6.374c-1.399-0.068-3.311-1.741-4.318-0.196c0.452,0.35,1.365,1.033,1.818,1.374l0.145,0.538
	c-0.461-0.171-1.374-0.521-1.826-0.691c-0.367,1.673-0.708,3.337-1.015,5.026c0.375-0.239,1.126-0.708,1.502-0.939
	c0.58,1.86,2.5,1.604,4.062,1.801c-0.964,1.203-2.995,2.099-2.611,3.908c1.254-0.145,2.483-0.418,3.746-0.623
	c-1.126,0.87-2.338,1.638-3.328,2.654c-0.162,1.075-0.179,2.167-0.23,3.268c0.41,1.545,0.358,3.157-0.068,4.702
	c0.077,0.316,0.23,0.947,0.307,1.263c-0.538,0.009-1.621,0.026-2.159,0.026c0.009,0.358,0.017,1.067,0.026,1.417
	c-1.476,0.29-4.19-0.358-3.584,2.065c2.159,0.384,4.642,1.135,6.451-0.597c1.587-0.162,3.191-0.299,4.804-0.401
	c-0.247-0.444-0.742-1.34-0.99-1.783c1.451-2.065,3.422-3.695,5.333-5.316c-0.503,2.193-0.538,4.924-2.782,6.161
	c0.333,0.631,0.666,1.271,1.015,1.92c-1.178,1.886-2.381,3.763-3.575,5.649c0.913-0.256,2.748-0.759,3.669-1.007
	c-0.777,1.28-1.545,2.577-2.287,3.883c-0.316-0.256-0.939-0.768-1.254-1.024c-0.469,0.674-0.939,1.348-1.391,2.039
	c-0.282-0.333-0.836-0.99-1.118-1.323c0.316,2.074,0.614,4.147,0.922,6.229c2.176-1.852,4.164-3.917,5.879-6.212
	c-0.188,1.527-0.367,3.063-0.58,4.599c0.401-0.051,1.203-0.145,1.604-0.196c0.785-0.845,1.596-1.664,2.415-2.475
	c0.87,0.469,1.749,0.939,2.637,1.425c0.307-0.137,0.913-0.41,1.22-0.546c0.239,0.538,0.725,1.613,0.964,2.15
	c-0.179,0.299-0.538,0.896-0.717,1.195c0.461,1.237,0.905,2.492,1.357,3.746c0.026,0.691,0.06,1.391,0.102,2.099
	c-1.946,1.237-3.883,2.526-5.444,4.25c0.828,0,2.475,0,3.302,0c-0.017,1.16-0.043,2.313-0.068,3.482
	c1.109,0.384,2.227,0.777,3.354,1.178c-0.205-0.418-0.606-1.246-0.802-1.664c0.606-2.33,1.092-4.685,1.69-7.006
	c1.024-0.41,2.057-0.819,3.106-1.212c0.137-0.794,0.282-1.587,0.444-2.372c0.76,0.913,1.587,1.775,2.5,2.569
	c-0.922,1.169-1.852,2.338-2.773,3.499c0.956-0.222,1.903-0.503,2.893-0.632c0.734,0.777,1.425,1.63,2.125,2.458
	c-0.316,1.126-0.614,2.261-0.879,3.405c1.51-0.896,3.012-1.818,4.488-2.782c-0.58,1.92-0.922,3.9-1.101,5.896
	c-0.418,0.41-1.263,1.22-1.69,1.63c-0.341-0.196-1.024-0.572-1.365-0.76c-0.401,0.12-1.203,0.367-1.596,0.495
	c0.188,0.367,0.563,1.109,0.751,1.476c0.87-0.222,1.766-0.435,2.662-0.648c0.905,0.794,1.835,1.562,2.782,2.338
	c2.091-0.751,4.309-0.93,6.528-0.998c-1.007,1.033-2.347,1.826-3.029,3.123c-0.23,1.434-0.23,2.901-0.444,4.361
	c1.101,0.23,2.202,0.469,3.319,0.691c-0.009,0.469-0.026,1.408-0.034,1.877c-0.418,0.358-1.254,1.084-1.664,1.442
	c-0.017,0.469-0.043,1.417-0.051,1.894c-1.254,0.461-2.483,0.939-3.712,1.451c0.717,0.418,2.133,1.263,2.85,1.681
	c-0.666,0.529-1.323,1.058-1.963,1.596c-0.486,1.536-1.553,2.432-3.2,2.321c0.128,1.271,0.265,2.552,0.418,3.84
	c-2.202,0.512-4.471,0.495-6.682,0.111c-1.033,2.091-3.43,0.435-5.129,0.58c1.297,1.493,3.14,2.159,4.983,2.739
	c0.375,0.99,0.734,2.014,1.203,3.004c0.529-0.171,1.587-0.503,2.108-0.674c-0.333,0.802-0.657,1.613-0.973,2.432
	c0.538,0.017,1.63,0.043,2.167,0.06c-0.145,0.529-0.435,1.587-0.58,2.108c2.108-2.543,4.292-5.069,5.837-8.004
	c-0.179-0.444-0.538-1.323-0.725-1.766c2.569,1.92,4.156-1.485,5.939-2.876c0.008-0.99,0.034-1.963,0.06-2.935
	c2.611-1.51,5.154-3.208,7.1-5.547c-0.427,1.032-0.853,2.065-1.297,3.098c-2.381,1.468-2.347,4.454-2.884,6.895
	c-1.536,0.964-1.271,2.543-0.87,4.07c-1.092,1.101-2.185,2.202-3.26,3.328c1.118-0.188,2.261-0.35,3.388-0.529
	c0.299,0.538,0.614,1.075,0.939,1.621c-0.657,0.683-1.289,1.365-1.911,2.065c-1.237,0.239-2.466,0.495-3.686,0.759
	c-1.161-0.87-2.347-1.801-3.712-2.27c1.382,2.97,3.823,5.274,7.117,5.879c-1.647,1.749-2.807,4.002-4.975,5.205
	c0.572-1.579,1.178-3.14,1.724-4.719c-2.603,1.024-3.908,3.465-5.052,5.837c0.503,0.171,1.502,0.512,2.005,0.683
	c0-0.742,0-2.219,0-2.961c1.348,1.434,1.348,3.251,1.033,5.077c2.739-0.981,3.2-4.335,5.769-5.513
	c0.162-0.316,0.486-0.93,0.649-1.246c2.065-1.459,0.606-3.823,0.316-5.76c1.707,0.418,3.43,0.802,5.171,1.152
	c0.043-0.563,0.145-1.69,0.188-2.253c1.434-0.964,2.654-2.193,3.652-3.593c0.734-0.469,1.476-0.939,2.227-1.391
	c0-0.597,0-1.801,0-2.398l0.811,0.009c0.017,0.563,0.068,1.69,0.094,2.253c0.452,0.435,0.913,0.879,1.391,1.34
	c-0.265,1.323-0.418,2.662-0.538,4.019c-0.427,0.034-1.271,0.111-1.69,0.145c0.017,1.126,1.169,2.586,0.299,3.533
	c-1.698,0.324-3.439,0.358-5.146,0.521c0.776,0.742,1.579,1.493,2.389,2.236c0.205,0.922,0.41,1.843,0.64,2.782
	c-2.261,0.691-3.635,2.372-3.917,4.719c-1.502,0.973-3.004,1.946-4.523,2.893c0.188,0.392,0.555,1.186,0.734,1.579
	c0.87-0.486,1.741-0.964,2.62-1.442c0.154,0.725,0.461,2.185,0.614,2.91c1.562-1.297,3.106-2.603,4.693-3.874
	c0.213,2.944-1.382,5.35-3.2,7.484c-0.102,2.5-0.222,5.248-2.449,6.844c-0.64,1.408-1.28,2.825-1.971,4.207
	c-0.094,0.529-0.273,1.587-0.367,2.116c-0.845,0.375-1.681,0.751-2.5,1.152c0.819,0.734,1.587,1.528,2.313,2.372
	c-1.135-0.495-2.261-0.998-3.371-1.519c0.64,1.058,1.382,2.099,1.929,3.234c-0.418,1.271-1.041,2.466-1.596,3.686
	c0.853-0.051,1.698-0.12,2.577-0.162c-0.111,0.469-0.316,1.417-0.427,1.886c0.99-0.393,1.98-0.794,2.978-1.186
	c0.572,1.835,1.033,3.797-0.435,5.35c-0.947-1.186-2.278-1.783-3.652-2.236c0.145,0.478,0.427,1.442,0.572,1.92
	c-0.282,0.777-0.555,1.562-0.811,2.355c1.041,1.016,2.057,2.082,3.2,3.012c1.417-1.852,2.842-3.686,4.378-5.436
	c-0.12-0.879-0.273-1.749-0.452-2.594c0.222-0.683,0.452-1.357,0.691-2.022c1.306-0.768,2.603-1.562,3.917-2.321
	c0.375-0.99,0.768-1.971,1.161-2.944c1.271-0.205,2.543-0.503,3.84-0.606c-0.751,1.109-1.468,2.236-2.202,3.362
	c0.239,0.341,0.708,1.033,0.939,1.382c-0.222,0.435-0.657,1.297-0.879,1.732c0.486-0.077,1.468-0.239,1.954-0.316
	c0.896-1.348,1.801-2.68,2.679-4.036c0.546,0.077,1.647,0.23,2.193,0.307c-0.819,0.563-1.63,1.126-2.432,1.69
	c0.205,0.546,0.606,1.63,0.811,2.176c-0.546,0.051-1.647,0.162-2.202,0.213c0.444,0.418,1.331,1.271,1.775,1.69
	c-0.853,1.988-4.139,4.139-2.227,6.349c-0.384,0.99-1.672,1.886-1.015,3.046c1.05,0.316,2.125,0.597,3.217,0.853
	c-0.964,1.553-1.971,3.081-3.106,4.514c0.128,1.05,0.282,2.116,0.435,3.174c-1.033-0.222-2.057-0.444-3.072-0.657
	c0.904,1.169,1.792,2.33,2.679,3.541c-1.544-0.461-3.046-0.913-4.565-1.408c-1.34,1.758-2.543,3.618-3.465,5.632
	c1.886-0.836,3.635-1.937,5.367-3.046c0.452,0.29,1.357,0.87,1.809,1.161c2.389-3.046,3.311-6.733,4.147-10.428
	c0.623-0.026,1.86-0.094,2.483-0.128c0.444-1.621,0.87-3.243,1.297-4.855c0.657,0.009,1.963,0.034,2.611,0.043
	c-0.444-0.973-0.896-1.937-1.331-2.901c0.811,0.427,1.638,0.87,2.466,1.314c-1.673-3.678,2.458-6.263,4.89-8.303
	c-0.563,1.212-1.161,2.406-1.8,3.584c0.256,2.372-0.811,4.591-1.408,6.835c0.367,0.29,1.109,0.87,1.476,1.161
	c0.35,3.26-1.792,5.743-3.942,7.859c1.314,0.623,2.62,1.254,3.942,1.911c-2.432,1.604-5.188,0.828-7.791,0.205
	c1.178,1.596,2.952,2.475,4.693,3.328c1.058-0.307,2.116-0.606,3.191-0.887c1.34-1.195,2.722-2.338,4.156-3.422
	c0.009,0.503,0.017,1.519,0.026,2.031c-0.785,0.87-1.493,1.809-2.091,2.825c-1.041,0.742-1.903,1.655-2.176,2.953
	c-0.171-1.229-0.734-2.21-1.698-2.935c-0.102,0.802-0.307,2.406-0.41,3.208c0.256,0.427,0.776,1.271,1.032,1.698
	c-1.408-0.29-2.79-0.58-4.181-0.87c-1.041,0.572-2.082,1.152-3.115,1.741c0.384-0.495,1.169-1.468,1.562-1.963
	c-1.399-0.393-2.867-0.358-4.301-0.256c0.802,1.016,1.621,2.022,2.449,3.046c-1.306-0.683-2.611-1.34-3.908-1.971
	c0.785,1.809,2.219,3.234,3.294,4.864c-1.178-1.126-2.458-2.697-3.78-0.674c1.237,0.862,2.492,1.724,3.738,2.62
	c-1.886-0.418-3.763-0.811-5.658-1.067c-0.708-0.742-1.408-1.485-2.082-2.21c-1.382,0.119-2.765,0.247-4.13,0.401
	c0.768,1.434,2.005,2.219,3.678,1.963c2.714,2.432,5.495,5.948,3.686,9.754c-1.007-0.478-2.005-0.956-3.004-1.425
	c-0.017,0.427-0.043,1.28-0.051,1.707c-0.341-0.12-1.015-0.35-1.348-0.469c-0.725,0.188-1.442,0.384-2.142,0.589
	c-0.478-0.845-0.939-1.681-1.399-2.509c-1.229-0.188-2.483-0.393-3.695-0.563c1.143,1.903,2.338,3.789,3.652,5.589
	c-1.024-0.341-2.031-0.674-3.046-0.981c-1.118-1.067-2.253-2.116-3.371-3.157c-0.299,1.579-0.58,3.183-0.879,4.779
	c1.084,0.956,2.159,1.954,3.234,2.953c0.247,1.22,0.495,2.432,0.785,3.669c-1.195-0.427-2.389-0.836-3.575-1.229
	c0.171,1.289,0.35,2.586,0.521,3.891c-2.389-3.261-1.842-4.034-5.274-8.568c-1.271,1.323-2.244,2.995-3.78,4.036
	c-1.28,0.282-2.577,0.401-3.866,0.572c0.077,0.35,0.222,1.058,0.29,1.408c0.683,0.051,2.048,0.162,2.731,0.222l0.051,1.818
	c-0.572-0.085-1.724-0.247-2.295-0.333c0.239,0.572,0.725,1.698,0.973,2.261c0.657-0.051,1.954-0.154,2.611-0.213
	c-0.555,0.674-1.109,1.357-1.647,2.048c-0.478-0.546-1.434-1.638-1.903-2.185c-0.887,0.862-1.544,1.911-2.244,2.918
	c0.802,0.555,1.621,1.126,2.441,1.698c-0.077,0.623-0.239,1.86-0.316,2.483c-0.384,0.444-1.143,1.34-1.527,1.792
	c1.058,1.826,2.313,0.188,3.439-0.572c1.075,0.188,2.142,0.367,3.234,0.572c0.034,0.64,0.102,1.92,0.128,2.56
	c0.649,0.239,1.306,0.486,1.971,0.742c-0.154-2.424-0.512-4.847-0.375-7.27c0.777-0.93,2.057-1.135,3.106-1.638
	c-0.068-0.503-0.205-1.519-0.273-2.031c0.597-0.034,1.783-0.102,2.372-0.145c-0.58,1.69-1.186,3.379-1.775,5.077
	c1.417,1.946,1.408,4.241,0.418,6.391c-0.802,0.58-1.579,1.203-2.313,1.869c-0.154,1.152,0.956,1.826,1.536,2.688
	c0.085,0.444,0.256,1.34,0.341,1.784c0.981,0.452,1.971,0.913,2.961,1.382c-0.521,1.126-1.169,2.176-2.244,2.859
	c0.188,0.478,0.384,0.964,0.589,1.459c-1.783,2.235-2.253,2.967-3.601,5.402c1.314,0.58,2.637,1.161,3.977,1.741
	c-0.026-0.785-0.085-2.338-0.111-3.123c0.99-0.495,1.988-0.973,2.995-1.451c0.589,0.307,1.766,0.939,2.355,1.246
	c0.666-1.792,1.34-3.593,2.014-5.376c0.239,1.015,0.486,2.039,0.725,3.072c0.666-0.384,1.348-0.76,2.031-1.135
	c0.06-0.64,0.179-1.929,0.239-2.577c0.828,0.657,1.647,1.314,2.483,1.988c0.998-1.058,2.039-2.057,3.098-3.038
	c0.555,0.461,1.656,1.374,2.21,1.835c0.896-1.306,1.8-2.603,2.671-3.917c0.418-0.068,1.246-0.196,1.664-0.265
	c1.63-3.106,4.361-5.299,7.433-6.869c-1.161,1.852-2.543,3.558-3.61,5.478c0.23,3.123,2.125,0.41,3.226-0.657
	c0.29,0.555,0.879,1.673,1.169,2.227c1.109-0.145,2.219-0.375,3.337-0.606c-0.341,0.452-1.016,1.357-1.357,1.809
	c3.523,3.376,1.709,2.941,8.695,3.072c-0.811-3.157-4.54-4.232-5.521-7.432c0.742-0.802,1.485-1.604,2.253-2.398
	c-0.008-0.435-0.026-1.314-0.034-1.758c-3.496-1.543-5.792-1.292-8.721-9.327c3.465,1.562,6.315,4.224,9.847,5.649
	c0.691,0.043,2.082,0.128,2.773,0.171l-0.196,0.23c-0.589,0.102-1.766,0.307-2.355,0.41c0,2.372,2.057,1.374,3.55,1.169
	c0.145,0.99,0.58,1.809,1.306,2.466c0.708-0.461,1.408-0.93,2.108-1.408c-0.597-0.469-1.783-1.417-2.372-1.886
	c0.521-0.034,1.579-0.102,2.099-0.145c-0.154-0.666-0.469-2.005-0.623-2.671c-1.05,0.222-2.091,0.452-3.115,0.691
	c-0.947-1.451-1.869-2.91-2.697-4.412c2.313,1.016,4.523,3.439,7.168,1.877c-0.119,0.717-0.35,2.159-0.469,2.876
	c1.903-0.905,2.125-2.876,2.406-4.702c-1.229,0.273-2.432,0.563-3.635,0.853c-0.333-1.604-1.203-2.645-2.961-2.372
	c0-0.358-0.009-1.084-0.017-1.451c-0.486-0.111-1.468-0.316-1.954-0.427c-0.495-0.649-0.973-1.289-1.442-1.92
	c-0.427-1.715-1.34-3.183-2.739-4.241c-0.802,0.06-2.406,0.196-3.208,0.265c0.597-0.367,1.792-1.109,2.398-1.476
	c-0.862-0.179-1.715-0.35-2.56-0.503c-0.435-1.246-0.905-2.492-1.34-3.712c4.062,2.551,7.962,6.195,13.107,5.931
	c-0.051-0.657-0.162-1.963-0.222-2.62c-0.666-0.068-2.005-0.205-2.679-0.273c0.521-0.742,1.05-1.468,1.596-2.193
	c-2.526-1.434-3.968-3.934-5.043-6.528c1.869,0.495,3.746,0.964,5.658,1.348c-0.785-1.519-1.647-2.987-2.731-4.292
	c0.341-0.776,0.7-1.545,1.067-2.304c1.058,1.092,1.954,2.33,2.492,3.78c0.589-0.094,1.195-0.188,1.809-0.265
	c1.05,0.981,2.125,1.946,3.2,2.91c0.427-0.23,1.28-0.7,1.707-0.93c0.307,0.836,0.614,1.673,0.939,2.526
	c0.41-0.034,1.229-0.102,1.647-0.137c-0.196,0.41-0.58,1.212-0.777,1.621c0.794,0.401,1.596,0.819,2.406,1.246
	c0.879-1.237,1.784-2.458,2.705-3.669c-0.418-0.23-1.263-0.7-1.681-0.93c-0.154-0.597-0.461-1.792-0.606-2.398
	c-1.271,0.119-2.5,0.393-3.746,0.546c0.128-0.981,0.341-1.946,0.538-2.901c-8.417-0.922-12.436-6.239-13.304-8.926
	c2.082,1.263,4.113,2.765,6.605,3.132c-1.297-1.382-2.748-2.594-4.224-3.763c1.015,0.145,2.022,0.29,3.046,0.461
	c1.596-0.623,3.234-1.126,4.915-1.468c0.401,1.425,0.819,2.859,1.263,4.292c0.341-0.128,1.033-0.384,1.374-0.512
	c1.544,1.033,3.115,2.321,5.111,2.202c-1.365-1.434-2.799-2.765-4.173-4.164c0.64-0.222,1.903-0.657,2.543-0.87
	c-0.981-0.384-1.946-0.76-2.901-1.118c-1.152-1.69-1.946-3.576-2.099-5.624c0.87,0.393,1.758,0.802,2.654,1.22
	c0.017-0.162,0.06-0.503,0.085-0.674c-0.717-0.64-1.417-1.28-2.108-1.929c-1.254-0.435-2.5-0.87-3.738-1.314
	c-1.161-2.125-2.492-4.147-3.669-6.255c0.988,0.436,12.033,6.913,12.757,8.047c1.024-0.068,2.057-0.145,3.098-0.213
	c-1.954-3.575-6.229-4.122-9.54-5.854c-1.408-3.217-4.984-5.052-5.487-8.747c1.69,0.614,3.089,1.758,4.181,3.191
	c1.306,0.759,2.603,1.536,3.908,2.338c0.7-0.708,1.417-1.408,2.142-2.099c2.253,1.707,4.574,3.55,7.467,4.011
	c-2.193-2.261-4.625-4.241-6.886-6.409c-0.478-2.637-2.364-4.514-4.804-5.436c0.009-0.324,0.034-0.981,0.043-1.314
	c-1.869-0.606-3.277-1.963-4.489-3.43c2.722-0.282,5.35,1.365,7.979,0.947c-0.23-1.502-0.606-2.97-0.904-4.437
	c0.947,0.546,1.911,1.118,2.876,1.69c0.751,0.034,2.236,0.111,2.978,0.154c-0.896-0.998-1.801-1.988-2.705-2.953
	c-1.075-0.452-2.057-1.05-2.722-2.005c-0.461-0.034-1.374-0.102-1.826-0.136c-0.111-0.503-0.341-1.51-0.452-2.014
	c-1.195-0.546-2.381-1.092-3.533-1.647c0.418-0.307,1.271-0.905,1.698-1.212c2.022,1.109,3.831,2.603,5.956,3.575
	c-1.229-2.825-3.866-4.676-5.129-7.467c1.51,0.802,2.987,1.707,4.395,2.705c1.22,0.068,2.441,0.137,3.686,0.213
	c-0.358-0.776-0.7-1.544-1.033-2.304c-0.99-0.623-1.971-1.254-2.927-1.886c0.845-0.06,2.551-0.171,3.396-0.23
	c0.393-4.113-3.627-5.999-4.949-9.412c1.57,0.392,3.14,0.836,4.719,1.263c-0.009,0.546-0.034,1.621-0.043,2.167
	c1.365,0.384,2.739,0.819,4.173,0.998c0.41-0.683,0.631-1.399,0.657-2.176c-1.118-0.589-2.278-1.058-3.413-1.57
	c-1.553-2.62-5.393-2.978-5.999-6.255c-0.538-0.683-1.067-1.357-1.596-2.022c0.691-0.009,2.074-0.017,2.765-0.026
	c0.375,0.828,0.759,1.673,1.143,2.517c1.459,0.427,2.927,0.836,4.42,1.246c0.094,0.393,0.273,1.195,0.367,1.596
	c0.452,0.154,1.357,0.452,1.809,0.597c-0.077-0.469-0.222-1.425-0.299-1.894c-1.51-1.152-3.012-2.304-4.446-3.524
	c1.664,0.623,3.285,1.374,4.975,1.971l0.256-1.007c-2.014-1.442-4.301-2.475-6.152-4.139c2.15,0.503,4.267,1.178,6.4,1.809
	c-2.21-2.534-4.975-4.514-7.142-7.083c2.867,1.016,5.555,2.569,8.593,3.081c-0.58-1.553-1.758-2.637-3.123-3.465
	c2.355,0.026,1.596-1.562,0.887-3.012c1.203-0.811,2.406-1.621,3.61-2.432c-1.664-2.765-3.533-5.41-5.709-7.799
	c2.227,0.375,5,2.782,6.827,0.393c-1.365-1.109-2.748-2.193-4.13-3.26c1.229-0.06,2.261-0.427,3.115-1.118
	c-0.845-1.476-3.371-2.551-2.099-4.523c0.546,0.307,1.647,0.922,2.202,1.229c-0.043-0.998-0.145-1.997-0.247-2.978
	c0.196-0.299,0.589-0.913,0.785-1.22c-0.452-0.333-1.348-0.99-1.801-1.323c0.179-1.161,0.316-2.33,0.623-3.456
	c0.939-0.41,1.92-0.725,2.944-0.947c-0.888-0.828-1.766-1.673-2.628-2.5c-0.085-0.87-0.162-1.724-0.222-2.569
	c-0.7-1.135-1.357-2.287-1.954-3.456c1.348,0.742,2.645,1.587,3.968,2.406c-0.358-3.285-0.887-6.537-1.152-9.822
	c0.828,0.384,1.655,0.785,2.5,1.178c0.154-1.425,0.265-2.859,0.563-4.25c2.159-0.913,1.801,2.756,2.603,4.011
	c0.503-0.017,1.502-0.051,1.997-0.068c-1.417,3.217,0.085,6.579,0.094,9.899c0.469-0.171,1.417-0.512,1.894-0.683
	c-0.631,1.237-1.314,2.466-1.775,3.789c0.307,1.519,1.058,2.901,1.698,4.318c-0.265,0.384-0.802,1.169-1.067,1.553
	c0.341,0.452,1.024,1.348,1.374,1.792c-0.469,0.35-1.391,1.041-1.852,1.391c0.418,0.418,1.254,1.254,1.673,1.672
	c-1.297,0.964-2.603,1.929-3.883,2.918c2.133,1.596,3.601-1.954,5.82-0.256c-0.93,0.964-1.869,1.911-2.79,2.876
	c0.418,0.171,1.263,0.495,1.69,0.657c-0.145,0.29-0.435,0.853-0.572,1.143c2.551,1.28-0.87,3.584-0.905,5.427
	c1.135-0.486,2.278-0.964,3.43-1.451c-0.964,0.888-1.937,1.766-2.884,2.671c-0.043,0.649-0.119,1.946-0.162,2.594
	c-0.973,0.29-1.929,0.589-2.884,0.904c1.792,2.799,5.641,1.382,8.166,3.174c0.017,0.939,0.196,1.98,0.316,2.944
	c0.87-1.749,2.15-3.251,3.849-4.241c-0.256-0.29-0.768-0.87-1.024-1.161c1.306-0.452,2.62-0.887,3.951-1.306
	c-0.631-0.879-1.271-1.749-1.903-2.603c1.007-1.186,1.988-2.381,2.935-3.601c-1.109-0.043-2.185-0.06-3.277-0.094
	c0.273-0.41,0.819-1.229,1.101-1.638c1.237,0.452,2.492,0.845,3.797,1.135c-1.621-1.331-3.371-2.492-5.069-3.703
	c-0.486-0.094-1.468-0.273-1.954-0.367c-0.401-0.913-0.794-1.826-1.178-2.731c0.751,0.708,1.502,1.425,2.253,2.15
	c1.98,0.017,3.917-0.427,5.615-1.51c-0.606-0.333-1.818-0.998-2.423-1.331c0.23-1.246,0.444-2.492,0.674-3.721
	c-1.51-0.973-2.952-2.057-3.994-3.533c1.656,0.384,3.294,0.828,4.924,1.331c-0.128-2.628,0.154-5.257-0.119-7.876
	c-1.28-0.862-2.526-1.783-3.763-2.679c1.178,0.085,2.381,0.094,3.575-0.043c-0.239-1.468-0.939-2.944-0.606-4.429
	c0.555-0.99,1.28-1.869,1.937-2.79c1.263,1.399,2.56,2.773,3.703,4.284c-0.777,2.492,0.213,5.521,2.816,6.468
	c-0.742,0.657-1.468,1.323-2.176,1.997c-0.068,1.195-0.282,2.364-0.521,3.533c0.358,0,1.067-0.017,1.425-0.026
	c0.247,1.408,0.853,2.628,2.287,3.183c-0.623,0.469-1.237,0.956-1.843,1.442c-0.06,1.271-0.111,2.56-0.137,3.857
	c0.819-0.29,1.647-0.572,2.492-0.845c0.358,1.365,0.742,2.731,1.135,4.105c-1.041,0.478-2.074,0.964-3.098,1.468
	c0.93,1.502,2.27,2.654,3.797,3.541c0.034,0.546,0.094,1.638,0.128,2.185c-0.469,0.247-1.4,0.742-1.86,0.99
	c0.316,0.708,0.64,1.417,0.973,2.133c0.947-0.307,1.903-0.614,2.867-0.896c-1.058,1.195-2.125,2.381-3.183,3.575
	c0.742,0,2.227,0.009,2.97,0.009c-0.623,1.289-1.229,2.577-1.826,3.883c0.606,0.128,1.818,0.384,2.415,0.503
	c0.179,1.929,0.401,3.857,0.751,5.769c-0.956,0.64-1.92,1.28-2.85,1.954c1.306-0.256,2.637-0.512,3.959-0.802
	c0.196,0.41,0.589,1.22,0.794,1.621c-1.271,0.538-2.56,1.058-3.797,1.63c2.108,1.314,4.241-0.717,6.298-1.161
	c-0.862,2.125-4.07,3.994-2.577,6.477c0.828-0.836,1.664-1.656,2.517-2.466c0.102,0.794,0.324,2.389,0.435,3.183
	c-0.947,0.265-1.877,0.546-2.799,0.828c0.034,1.323,0.06,2.654,0.102,3.985c0.947,0.273,1.911,0.555,2.876,0.836
	c-0.777-1.178-1.596-2.304-2.423-3.43c1.306-0.597,2.62-1.263,4.053-1.536c0.179,1.408,0.273,2.833,0.401,4.258
	c-0.896,1.451-2.057,2.705-3.268,3.9c0.913,2.807-0.486,5.521-1.109,8.218c1.621-0.58,3.208-1.186,4.855-1.715
	c-1.126,1.024-2.236,2.065-3.405,3.055c-0.546,1.297-1.092,2.603-1.613,3.917c0.811-0.034,2.432-0.102,3.251-0.137
	c-0.964,1.271-1.749,2.944-3.516,3.191c0.649,0.802,1.289,1.621,1.963,2.441c0.077-0.785,0.23-2.355,0.299-3.14
	c1.246,0.299,2.517,0.58,3.746,1.015c-0.734,2.449-4.403,1.749-4.898,4.395c3.423-1.57,3.06-1.712,8.44-3.388
	c-1.033,1.399-2.082,2.773-3.123,4.173c1.271,0.034,2.534-0.026,3.823-0.094c-0.085,0.58-0.256,1.724-0.333,2.295
	c0.922,0.691,1.869,1.399,2.807,2.116c-1.826,0.751-3.635,1.528-5.427,2.338c0.512,0.666,1.058,1.34,1.604,2.022
	c-2.039,2.577-3.806,5.367-5.12,8.38c2.142-1.135,4.258-2.338,6.528-3.217c-0.247,0.683-0.742,2.056-0.99,2.739
	c1.143,0.077,2.304,0.162,3.473,0.256c-1.519,1.212-2.953,2.534-4.053,4.139c1.638,0.154,3.294,0.282,4.958,0.384
	c-0.777,1.075-1.553,2.142-2.295,3.243c0.981-0.358,1.98-0.708,2.978-1.05c0.35,0.222,1.041,0.674,1.391,0.896
	c0.427-2.475,0.922-4.941,1.442-7.398c1.766,1.015,1.374,3.26,2.014,4.932c1.058,0.981,2.295,1.766,3.465,2.637
	c-0.179,0.375-0.538,1.135-0.717,1.51c0.819,0.418,1.638,0.836,2.483,1.271c0.017-1.161,0.051-2.304,0.094-3.447
	c-0.631-0.538-1.237-1.067-1.843-1.587c1.314-0.794,2.62-1.613,3.934-2.415c-0.375-0.401-1.126-1.195-1.502-1.596
	c0.512-0.128,1.536-0.384,2.048-0.503c-0.009-1.536-0.009-3.055-0.017-4.565c-1.143,0.725-2.287,1.451-3.43,2.167
	c-0.725-2.15-1.34-4.326-1.963-6.494c0.478,0.256,1.442,0.777,1.92,1.033c2.611,0.58,5.171,1.399,7.791,1.971
	c-0.7-1.22-1.382-2.432-2.065-3.644c1.485-0.043,2.978-0.077,4.48-0.111c-0.017-0.512-0.06-1.536-0.077-2.048
	c-1.673-1.903-3.183-3.917-4.779-5.871c0.29-0.836,0.597-1.673,0.905-2.5c1.408,0.307,2.816,0.648,4.233,1.015
	c-1.033-1.476-2.057-2.953-3.055-4.437c2.56,0.538,5.094,1.28,7.467,2.432c1.007-0.179,2.022-0.35,3.021-0.631
	c-0.333-0.853-1.058-1.417-1.655-2.031c-0.427,0.307-1.271,0.913-1.69,1.212c-1.357-1.152-2.662-2.338-3.908-3.575
	c-0.862-0.444-1.707-0.879-2.543-1.306c0.785-0.034,2.338-0.111,3.115-0.154c-0.307-0.418-0.93-1.246-1.237-1.664
	c0.247-0.358,0.734-1.067,0.973-1.417c-0.973-0.913-1.929-1.818-2.867-2.722c1.229-0.444,2.483-0.862,3.755-1.254
	c0.683,0.683,1.391,1.357,2.108,2.031c-0.7-1.186-1.442-2.347-2.176-3.499c-1.101,0-2.202,0.008-3.285,0.026
	c-0.017-0.41-0.043-1.22-0.051-1.63c1.237,0.333,2.475,0.657,3.755,0.964c-0.802-1.297-1.698-2.526-2.918-3.43
	c0.393-0.819,0.794-1.621,1.203-2.423c0.973,0.58,1.971,1.178,2.961,1.783c0.205-0.136,0.606-0.41,0.811-0.538
	c-0.683-0.794-1.348-1.587-1.997-2.381c-1.067,0.265-2.116,0.717-3.226,0.572c-0.29-0.956-0.538-1.92-0.742-2.876
	c-0.384-0.367-1.152-1.101-1.536-1.468c0.316-0.7,0.649-1.391,0.99-2.082c-0.427-0.853-0.828-1.698-1.22-2.534
	c0.555-0.068,1.681-0.205,2.244-0.273c-0.708-0.597-1.399-1.178-2.091-1.758c0.896-1.092,1.792-2.185,2.671-3.294
	c-0.666-1.408-0.87-2.944-1.015-4.454c2.065,1.246,1.647,3.814,2.133,5.854c1.246-1.331,2.406-2.79,2.5-4.702
	c0.913,0.341,1.843,0.674,2.782,1.007c-0.461-1.92-2.381-2.321-3.934-2.97c0.273-0.418,0.819-1.246,1.092-1.664
	c-1.237,0.085-2.458,0.162-3.669,0.256c0.384-0.853,0.785-1.698,1.186-2.543c-0.649-0.043-1.946-0.12-2.594-0.162
	c-0.427-1.152-0.853-2.295-1.263-3.43c1.126,0.358,2.27,0.725,3.422,1.092c0.341,0.393,1.041,1.169,1.382,1.562
	c1.493-0.068,2.987-0.162,4.497-0.256c-1.348-1.195-2.807-2.244-4.284-3.26c1.237,0.051,2.492,0.111,3.746,0.179l-0.026-1.007
	c-0.597,0.051-1.8,0.145-2.398,0.196c-0.913-0.427-1.818-0.845-2.714-1.246c0.905-1.314,1.792-2.645,2.671-3.968
	c-1.894-3.021-3.738-6.11-6.315-8.61c1.271,0,2.56,0,3.857,0.017c-0.111-1.707-0.188-3.413-0.205-5.103
	c1.476,0.563,1.69,2.005,1.886,3.396c2.987-1.835,5.606,0.913,8.235,2.082c0.41-1.408,0.785-2.825,1.161-4.233
	c-1.126,0.64-2.219,1.323-3.302,2.014c-1.86-1.911-3.413-4.079-4.616-6.451c0.444-0.341,1.323-1.032,1.758-1.374
	c-0.026-0.495-0.094-1.485-0.128-1.971c1.161-1.732,0.964-3.661,0.034-5.419c0.717-0.009,2.15-0.026,2.867-0.043
	c-1.05-1.118-2.091-2.227-3.123-3.345c1.271-0.026,2.552-0.043,3.84-0.051c-0.87-2.313-1.903-4.719-4.412-5.623
	c0.401-0.375,0.811-0.742,1.246-1.101c0.819,0.691,1.655,1.391,2.517,2.074c-0.631-1.715-1.331-3.396-2.057-5.06
	c1.775,1.493,3.541,3.311,6.084,3.115c-0.265-0.478-0.794-1.434-1.067-1.911c-0.529-0.009-1.587-0.026-2.116-0.043
	c-0.401-0.845-0.785-1.681-1.169-2.517c0.538-0.06,1.63-0.171,2.176-0.23c-1.007-1.365-2.108-2.654-2.995-4.079
	c0.393-0.691,0.819-1.365,1.271-2.014c0.393,1.041,0.785,2.082,1.195,3.14c0.435-0.324,1.314-0.956,1.749-1.28
	c0.068,0.589,0.205,1.758,0.265,2.347c1.382-1.033,2.739-2.108,4.087-3.183c-0.017,0.589-0.034,1.783-0.051,2.381
	c-0.35,0.358-1.041,1.067-1.391,1.425c-0.205,1.638-0.486,3.319-2.21,4.019c1.161-0.026,2.33-0.026,3.524-0.017
	c-0.836,1.835-0.717,3.695,1.075,4.915c-0.273,1.297-0.572,2.594-0.888,3.883c0.742,0.887,1.493,1.783,2.27,2.679
	c0.119-0.64,0.375-1.911,0.495-2.543c1.51,0.785,2.799,1.809,1.775,3.635c0.094,0.23,0.29,0.691,0.384,0.922
	c-0.367,1.306-0.708,2.62-1.015,3.951c-0.828,0.776-1.621,1.587-2.406,2.398c1.033,0.247,2.082,0.503,3.14,0.759
	c0.614,0.964,1.263,1.937,1.92,2.901c-0.623,0.725-1.22,1.485-1.766,2.27c0.154,1.186,1.357,1.707,2.125,2.492
	c-0.708-0.017-2.133-0.051-2.842-0.077c-2.767,6.444-1.45,3.637-3.635,7.134c2.876-0.827,4.418-3.422,10.095-0.674
	c-0.282,0.478-0.853,1.442-1.143,1.929c0.512,1.655,0.384,3.354-0.068,5.009c-1.203,0.486-2.389,0.998-3.567,1.493
	c2.628,0.29,1.519,2.603,1.246,4.326c0.896-0.657,1.8-1.314,2.714-1.963c0.614,0.299,1.246,0.597,1.886,0.913
	c0.307,0.64,0.623,1.28,0.956,1.937c-1.391,0.546-2.97,1.075-2.85,2.893c-0.751,0.094-2.253,0.273-3.004,0.358
	c1.246,0.64,2.483,1.246,3.755,1.877c-0.393,2.193-2.176,3.465-3.977,4.497c0.7,0.956,1.417,1.894,2.159,2.85
	c1.109-0.597,2.219-1.195,3.354-1.775c0.282,0.819,0.546,1.655,0.777,2.517c-0.742,1.988-2.859,2.773-4.497,3.857
	c0.956,0.546,1.903,1.075,2.884,1.63c-0.708,0.41-1.399,0.828-2.074,1.246c0.273,0.427,0.811,1.289,1.084,1.715
	c-0.427,0.034-1.28,0.094-1.707,0.119c-0.017,0.717-0.043,2.142-0.051,2.859c-0.341-0.444-1.015-1.323-1.357-1.758
	c-0.401-0.077-1.203-0.222-1.604-0.29c0.068,0.802,0.137,1.613,0.222,2.432c0.324,0.043,0.973,0.12,1.297,0.162
	c0.145,1.365,0.759,2.483,1.843,3.345c-0.077-0.853-0.213-2.551-0.29-3.405c1.681-0.384,3.379-0.734,5.086-1.041
	c-1.126,2.568-2.014,5.231-2.756,7.936c0.913-0.375,1.826-0.734,2.765-1.092c-0.41,1.084-0.802,2.176-1.186,3.277
	c1.28-0.503,2.577-0.998,3.866-1.502c-0.401,1.459-0.802,2.927-1.186,4.395c-1.126,0.051-2.244,0.111-3.345,0.171
	c0.043,1.186,0.102,2.381,0.179,3.576c1.621,0.205,3.49,0.307,3.371,2.483c1.519-1.314,3.046-2.62,4.565-3.942
	c0.393,2.773-0.589,5.316-2.637,7.202c0.316,0.341,0.93,1.024,1.237,1.365c-0.597,0.051-1.801,0.162-2.398,0.222
	c-0.017,1.271-0.034,2.551-0.043,3.84c1.135-0.017,2.278-0.017,3.439-0.009c-0.452,1.186-0.922,2.372-1.417,3.541
	c0.179,0.256,0.529,0.768,0.7,1.024c-1.186,0.529-2.347,1.084-3.499,1.664c0.512,0.307,1.536,0.922,2.048,1.229
	c-0.546,1.613-1.041,3.234-1.493,4.881c0.973-0.026,2.91-0.077,3.874-0.102c-1.246,0.973-2.466,1.954-3.695,2.935
	c0.708,0.316,1.434,0.631,2.176,0.964c1.22-1.161,2.458-2.304,3.669-3.49c1.109-0.427,2.227-0.862,3.354-1.289
	c-0.282,1.118-0.555,2.244-0.802,3.379c-1.613,1.101-3.157,2.304-4.506,3.712c1.024-0.299,2.048-0.597,3.081-0.913
	c-0.333,1.05-0.674,2.099-0.896,3.191c1.485,0.939,2.987,0.23,4.412-0.418c-0.393-1.374-0.777-2.739-1.109-4.105
	c0.939,0.973,1.852,1.988,2.765,3.012c0.469-0.418,1.391-1.237,1.852-1.647c0.29,1.203,0.58,2.423,0.879,3.652
	c-0.794-0.06-2.364-0.171-3.149-0.222c0.282,1.297,0.853,2.441,2.168,3.004c-0.026,0.478-0.085,1.434-0.12,1.911
	c0.768,0.401,1.545,0.811,2.338,1.229c0.034-2.577-0.393-5.538,1.399-7.654c0.358,1.161,0.725,2.347,1.092,3.533
	c-0.154,0.751-0.299,1.51-0.427,2.278c1.084-0.614,2.159-1.246,3.251-1.869c0.572,1.323,1.109,2.628,1.681,3.977
	c0.998-1.118,1.98-2.253,2.987-3.362c-3.251-0.521-1.126-3.499-0.853-5.427c1.843,2.236,2.927,4.966,4.599,7.339
	c1.203-0.785,2.466-1.476,3.78-2.074c-1.485,3.081-1.894,6.468-2.808,9.728c-0.623-0.051-1.869-0.154-2.492-0.196
	c0.563,1.246,0.708,2.552,0.009,3.789c-0.751-0.683-1.485-1.365-2.219-2.039c-0.29,0.435-0.887,1.289-1.178,1.724
	c0.401,1.562,0.734,3.149,1.058,4.745c0.819,0.87,1.647,1.775,2.466,2.688c-0.981,0.964-1.946,1.946-2.893,2.935
	c0.606,0,1.826,0.009,2.432,0.009c0.836-0.674,1.698-1.331,2.569-1.971c0.23-0.546,0.478-1.092,0.742-1.621
	c-1.886-0.836-0.99-2.714-0.777-4.216c-0.862,0.427-1.715,0.862-2.551,1.306c1.783-2.739,3.328-5.615,4.591-8.636
	c0.904,1.417,1.835,2.833,2.773,4.25c-0.973,0.717-1.963,1.425-2.918,2.142c1.024,0.956,2.048,1.911,3.081,2.893
	c0.64-0.828,1.306-1.638,1.971-2.449c-0.102-0.324-0.316-0.973-0.427-1.297c1.587-1.485,3.081-3.055,4.642-4.565
	c0.111,0.631,0.324,1.903,0.435,2.534c-0.486,0.06-1.451,0.196-1.937,0.256c1.399,1.775,2.679,3.635,3.712,5.658
	c-1.178-0.623-2.338-1.254-3.473-1.894c0.145,1.126,0.324,2.27,0.512,3.405c0.768,0.119,2.304,0.341,3.064,0.452
	c-1.382,1.715-3.379,2.441-5.538,2.534c0.486,2.159,0.862,4.352,1.058,6.571c0.648-1.246,1.826-1.852,3.14-2.185
	c1.459-1.911,2.995-3.797,5.077-5.069c0.264,0.435,0.794,1.314,1.058,1.749c-0.367,1.024-0.708,2.074-1.033,3.123
	c-0.691,1.067-1.365,2.133-1.997,3.234c0.905-0.367,1.826-0.725,2.756-1.084c0.444-1.801,0.845-3.721,2.185-5.094
	c1.766,0.128,2.586,1.997,3.849,3.021c-0.307-1.553-0.615-3.089-0.913-4.634c-1.843-0.085-3.669-0.367-5.18-1.485
	c1.493,0.009,3.004,0.128,4.523,0.111c0.452-0.905,0.87-1.809,1.254-2.731c0.384-0.239,1.161-0.725,1.544-0.964
	c-1.348-0.691-2.697-1.374-4.036-2.039c1.424-1.872,2.198-1.429,2.739-4.412c-0.674-0.691-1.34-1.382-1.98-2.065
	c-0.512-0.043-1.536-0.119-2.057-0.162c0-0.435-0.009-1.297-0.009-1.732c2.159,1.101,4.489,1.451,6.647,0.068
	c-2.731-3.106-6.98-4.796-8.627-8.789c2.065,1.178,4.036,2.603,6.409,3.123c-0.956-4.653-0.703-2.572-0.896-8.201
	c0.913,0.367,1.835,0.742,2.765,1.135c-0.435-2.825-0.657-5.658-0.973-8.491c0.427-0.128,1.28-0.375,1.707-0.503
	c-0.904-0.247-1.809-0.495-2.696-0.725c-0.068-0.99-0.128-1.971-0.171-2.952c1.579,0.375,3.174,0.683,4.796,0.964
	c0.657,0.623,1.323,1.246,2.014,1.869c-0.282-1.186-0.555-2.364-0.819-3.533c1.732-0.657,3.575-1.229,4.966-2.534
	c-0.623-0.102-1.852-0.316-2.475-0.418c0.324-0.452,0.964-1.348,1.289-1.792c-1.05-0.777-2.091-1.545-3.123-2.304
	c0.623-0.094,1.869-0.282,2.492-0.375c-2.219-1.724-4.958-2.569-7.176-4.25c-0.504-1.101-0.375-2.33-0.384-3.482
	c0.691,0.546,1.391,1.109,2.099,1.681c1.374-0.247,2.739-0.478,4.139-0.683c-0.017-0.691-0.068-2.074-0.085-2.765
	c-0.555,0.06-1.656,0.171-2.21,0.23c-0.341-0.614-1.007-1.835-1.348-2.449l-0.051,2.21c-0.606-1.408-1.186-2.816-1.775-4.207
	c-1.186-0.887-2.313-1.911-2.466-3.473c1.869,1.016,3.652,2.193,5.53,3.217c0.674-0.836,1.365-1.664,2.065-2.475
	c0.111,0.538,0.333,1.621,0.444,2.159c1.579,0.06,3.166,0.137,4.77,0.239c-0.137-0.939-0.247-1.86-0.35-2.782
	c-0.896,0.008-1.783,0.026-2.654,0.051c-1.621-2.492-3.106-5.06-4.642-7.595c1.425,0.691,2.97,0.614,4.463,0.145
	c0.068,0.418,0.222,1.263,0.29,1.681c0.555-0.828,1.126-1.647,1.715-2.449c-1.502-0.631-2.833-1.544-4.079-2.56
	c0.947-0.521,1.903-1.033,2.867-1.527c-0.529-2.441,0.887-5.905-2.278-6.869c0.734-0.538,1.485-1.067,2.193-1.655
	c0.017-1.724-0.546-3.379-0.845-5.043c1.476,0.751,2.944,1.519,4.446,2.27c0.589-2.048,0.802-4.164,0.862-6.289l0.555,0.009
	c0.205,1.289,0.435,2.594,0.708,3.891c0.136-0.802,0.41-2.415,0.546-3.217c0.307,0.282,0.913,0.845,1.22,1.135
	c0.358-1.434,0.922-2.79,1.664-4.062c-0.486,1.698-0.23,3.294,1.118,4.523c-0.247,1.724-0.486,3.456-0.691,5.205
	c0.657-0.239,1.963-0.717,2.62-0.956c-0.034,0.58-0.085,1.749-0.111,2.33c-0.768,0.623-1.51,1.271-2.236,1.92
	c0.794,0.102,2.389,0.29,3.183,0.393c0.546,0.725,1.109,1.468,1.681,2.21c-0.444,0.341-1.34,1.024-1.783,1.365
	c0.009,0.589,0.026,1.775,0.034,2.372c1.442-0.367,2.893-0.734,4.361-1.058c-1.212,1.749-2.449,3.49-3.703,5.205
	c-0.247,1.459-0.563,2.918-0.905,4.369c1.357-0.384,2.697-0.76,4.096-1.067c-0.58,2.611-3.064,3.208-5.359,3.507
	c0.811,1.818,0.862,4.548,3.379,4.881c-0.375-1.178-0.751-2.347-1.126-3.507c0.896,0.529,1.809,1.084,2.731,1.638
	c-0.435,1.417-0.845,2.842-1.195,4.292c1.041-0.853,2.108-1.69,3.157-2.543c1.254,0.12,2.509,0.205,3.789,0.265
	c-0.683,1.237-1.348,2.492-1.98,3.763c-0.981-0.034-1.954-0.06-2.91-0.085c-0.068,0.478-0.222,1.425-0.299,1.894
	c-0.973-0.555-1.929-1.101-2.876-1.647c-0.828,0.956-1.724,1.946-1.613,3.328c0.93-0.375,1.869-0.751,2.816-1.135
	c0.154,0.418,0.469,1.263,0.631,1.681c0.589-0.546,1.195-1.084,1.809-1.604c2.705,1.246,5.598-0.06,8.388-0.162
	c-2.313,1.528-4.506,3.294-5.743,5.845c-1.289,0.068-2.62,0.128-3.874,0.23c0.777,1.186,1.621,2.321,2.543,3.439
	c-0.87,1.101-1.766,2.261-3.209,2.611c-0.162,0.256-0.495,0.776-0.666,1.041c-1.058-0.845-2.108-1.664-3.157-2.492
	c-0.614,0.546-1.22,1.101-1.818,1.664c1.434,0.828,2.884,1.673,4.378,2.432c2.822-1.595,0.961-0.165,6.519-3.541
	c0.23,2.219,1.749,1.536,3.183,0.811c0.572,0.546,1.161,1.092,1.766,1.664c0.683,0.077,2.048,0.23,2.731,0.307
	c-1.041,2.918-4.369,2.108-6.613,1.365c-1.118,0.094-2.219,0.205-3.311,0.316c0.026,0.529,0.077,1.587,0.102,2.116
	c1.869-0.205,3.942-0.503,5.572,0.683c-0.692,1.347-1.376,2.928-1.775,3.652c-1.937-0.435-3.823-1.075-5.743-1.527
	c0.358,0.896,0.734,1.801,1.118,2.714c-0.188,0.324-0.572,0.99-0.76,1.314c0.358,0.341,1.092,1.016,1.451,1.357
	c0.384-0.299,1.143-0.905,1.527-1.203c0.341,0.444,1.024,1.331,1.365,1.775c2.219-0.051,4.429-0.068,6.665-0.034
	c-1.468,1.638-3.576,2.321-5.555,3.072c0.503,0.981,1.152,1.877,1.903,2.714c-1.459,0.324-2.441,1.254-2.859,2.688
	c0.563,0,1.698,0,2.261,0c1.101-1.271,2.637-1.954,4.122-2.654c0.299,1.741,0.64,3.482,0.99,5.231
	c-1.545,0.836-3.106,1.647-4.659,2.449c1.98,1.63,4.471,2.133,6.972,1.613c0.205,0.623,0.418,1.263,0.648,1.911
	c1.033-0.247,2.082-0.461,3.14-0.64c-1.562,2.509-4.48,3.447-7.287,3.567c0.316,0.742,0.649,1.493,0.99,2.253
	c-1.05,0.085-2.082,0.171-3.098,0.273c-0.043,0.58-0.137,1.749-0.179,2.33c0.666-0.077,1.997-0.239,2.662-0.316
	c0.341,0.392,1.033,1.195,1.374,1.587c2.688-1.169,5.427-2.261,8.311-2.85c-0.99,0.939-2.065,1.784-3.115,2.645
	c0.375,0.358,1.135,1.092,1.51,1.459c-1.937,0.393-3.874,0.785-5.82,1.126c0.384,0.589,0.777,1.186,1.178,1.784
	c0.973,0.631,1.963,1.28,2.944,1.937c-1.98,0.009-3.951-0.008-5.897,0.282c0.538,1.331,1.289,2.569,2.014,3.823
	c-0.7-0.094-1.391-0.179-2.074-0.247c-1.348,0.768-2.731,1.493-4.096,2.219c0.666,0.572,1.314,1.135,2.005,1.724
	c-1.647,0.427-3.302,0.87-4.924,1.348c1.502,1.698,3.584,1.306,5.504,0.64c0.956,0.333,1.92,0.683,2.901,1.05
	c0.529,1.161,1.212,2.236,2.466,2.773c0.009,0.384,0.034,1.152,0.051,1.545c0.964-0.649,1.937-1.297,2.918-1.937
	c0.811,0.751,1.613,1.502,2.441,2.278c-0.819,0.836-1.613,1.681-2.415,2.526c-1.869,0.247-3.669,0.768-5.35,1.596
	c0.538,0.358,1.621,1.067,2.159,1.425c0.009,0.546,0.026,1.638,0.034,2.185c-1.007-0.077-1.997-0.145-2.97-0.196
	c-1.493-0.674-3.072-2.475-4.796-1.493c-0.307,3.578,7.209,4.156,11.725,2.039c0.094,0.29,0.282,0.87,0.384,1.161
	c-1.05,0.418-3.746,0.111-3.174,1.946c0.555,0.188,1.664,0.58,2.21,0.777c-0.546-0.051-1.638-0.137-2.184-0.188
	c-1.784,2.039-3.405,0.128-4.975-0.973c-0.119,3.362,2.833,4.907,5.496,6.025c-0.614,0.102-1.826,0.299-2.44,0.393
	c1.92,2.039,4.838,2.27,7.373,3.089c-1.041,1.596-2.27,3.055-3.644,4.378c1.254-0.461,2.398-0.051,3.422,0.734
	c0.521,0.094,1.562,0.282,2.082,0.375c-0.333,0.418-0.998,1.246-1.331,1.664c0.546,0.64,1.647,1.911,2.193,2.552
	c-2.031,0.094-3.422-1.297-4.693-2.662c-0.435,0.401-0.862,0.819-1.271,1.246c0.913,0.41,1.835,0.836,2.773,1.263
	c-0.708,0.879-1.4,1.766-2.082,2.662c0.597,0.017,1.792,0.051,2.389,0.068c0.341-0.375,1.016-1.109,1.357-1.485
	c0.888,0.324,1.784,0.657,2.697,0.99c-0.811,1.519-1.647,3.021-2.628,4.429c0.444,0.307,1.34,0.913,1.783,1.22
	c-2.755,5.04-2.946,2.189,0.102,8.474c-1.818,2.022-0.99,4.514-0.256,6.818c2.057,0.734,4.207,0.614,6.289-0.008
	c-0.666,1.289-1.442,2.509-2.304,3.669c1.135,0.12,2.278,0.247,3.43,0.367c0.999-5.628,1.631-4.482-4.105-9.216
	c1.109,0.393,2.227,0.802,3.371,1.212c1.152-0.845,2.389-2.227,3.635-0.623c1.254-0.222,2.517-0.435,3.797-0.623
	c0.316,0.375,0.947,1.118,1.254,1.493c1.075-0.026,2.159-0.034,3.251-0.008c-0.512,1.203-1.007,2.406-1.493,3.618
	c0.802,0.077,2.406,0.222,3.209,0.29c-0.213,0.384-0.657,1.143-0.87,1.519c-0.64,0.017-1.92,0.051-2.56,0.068
	c-0.631,1.186-1.254,2.389-1.877,3.592c-1.57-0.947-3.132-1.903-4.693-2.833c0.41-0.35,1.237-1.05,1.655-1.399
	c0.41,0.469,1.237,1.399,1.647,1.86c0.896-0.777,1.801-1.536,2.696-2.313c-2.074-0.384-4.173-0.358-6.221,0.077
	c-0.324,3.183,0.282,6.349,2.944,8.397c0.631,0.196,1.894,0.589,2.526,0.785c-1.374,0.572-2.773,1.109-4.13,1.69
	c2.79,2.074,6.229,2.347,9.523,1.476c0.12,0.375,0.358,1.135,0.478,1.519c0.828-0.367,1.664-0.725,2.517-1.075
	c-0.29,1.075-0.58,2.159-0.862,3.251c0.239,0.29,0.708,0.879,0.947,1.169c-0.452,0.896-0.888,1.801-1.323,2.705
	c1.075-0.35,2.168-0.674,3.277-0.981c-1.801,2.343-3.848,2.444-9.506,5.564c-0.606,0.794-1.203,1.604-1.784,2.423
	c1.186,0.623,2.398,1.212,3.652,1.749c0.324,0.29,0.99,0.87,1.323,1.161c1.229-0.282,2.423-0.58,3.695-0.785
	c-1.135,1.237-2.406,2.33-3.644,3.465c-0.614,0.051-1.835,0.162-2.441,0.213c0.717,1.263,1.485,3.038,2.782,1.118
	c0.487,0.017,0.981,0.051,1.493,0.094c0.819-0.521,1.656-1.041,2.551-1.468c0.734,1.357,1.365,2.79,2.005,4.215
	c0.358-0.495,1.067-1.476,1.425-1.971c2.423-1.075,5.018-1.664,7.689-1.451c-0.905,1.903-1.988,3.721-3.14,5.487
	c1.792,0.981,3.328,2.347,4.779,3.797c-2.867,1.357-5.905,2.714-9.148,2.039c0.316,1.118,0.657,2.261,1.016,3.396
	c-0.597-0.017-1.792-0.051-2.389-0.068c-0.273,0.461-0.828,1.382-1.101,1.835c-0.324-0.256-0.956-0.785-1.272-1.05
	c-0.683,1.391-1.468,2.748-1.963,4.233c0.606,0.717,1.246,1.425,1.92,2.125c-0.606-0.256-1.835-0.768-2.441-1.024
	c-0.247,0.239-0.751,0.725-0.998,0.964c-0.333,1.348-0.666,2.705-1.007,4.062c0.282-0.196,0.836-0.58,1.118-0.768
	c0.708,0.947,1.732,1.527,2.85,1.946c0.367,0.324,1.101,0.973,1.468,1.289c0.597,0.017,1.809,0.034,2.406,0.051
	c-0.213-0.555-0.649-1.655-0.87-2.21c1.323-0.102,2.645-0.222,3.985-0.324c0.393,0.759,0.785,1.536,1.195,2.33
	c1.254,0.23,2.517,0.486,3.789,0.768c-0.836,0.956-1.638,1.946-2.449,2.918c0.282,0.41,0.836,1.22,1.109,1.63
	c-0.188,0.358-0.555,1.067-0.742,1.425c0.358,0.725,0.734,1.468,1.118,2.219c-0.956,3.499,0.324,6.997,1.297,10.342
	c-0.051,2.756,1.655,5.001,2.833,7.356c2.278,0,4.582,0.154,6.869-0.094c0.205-0.529,0.623-1.57,0.828-2.091
	c0.179,0.375,0.529,1.126,0.708,1.51c1.186,0.597,1.988,1.655,2.688,2.773c-0.486,0.324-1.442,0.973-1.92,1.297
	c-1.058-0.324-2.091-0.666-3.149-0.862c-0.247,0.521-0.742,1.562-0.99,2.082c-0.589-0.759-1.178-1.519-1.741-2.253
	c-0.452,1.22-0.896,2.458-1.323,3.703c2.423-0.026,4.855-0.29,7.194-0.981c-0.026,2.133-2.432,5.538,0.58,6.502
	c-0.359,1.024-0.717,2.031-1.041,3.072c-0.691-0.256-1.365-0.495-2.031-0.734c-0.529,1.809-1.058,3.627-1.596,5.444
	c0.913,0.964,1.826,1.937,2.722,2.961c-1.365-0.444-2.696-0.913-4.019-1.382c-0.802,0.802-1.613,1.604-2.321,2.5
	c-0.009,1.562,0.512,3.063,0.836,4.599c1.664-0.538,3.345-1.041,5.026-1.587c0.188,1.519,0.444,3.029,0.76,4.548
	c0.879-0.742,1.758-1.485,2.654-2.202c0.614,0.981,1.22,1.971,1.86,2.97c0.324-0.41,0.964-1.246,1.289-1.664
	c0.324,1.451,0.708,2.918,1.092,4.378c1.655-0.41,3.277-0.99,4.787-1.826c-0.939,2.722-3.763,2.526-6.144,2.62
	c-0.538,0.239-1.613,0.717-2.15,0.964c-0.128,2.552,1.331,4.634,2.901,6.494l-1.109-0.094l0.009,0.939
	c0.324-0.162,0.981-0.486,1.306-0.649c0.998,1.391,2.184,2.68,3.627,3.669c0.102-1.425,0.196-2.833,0.281-4.241
	c0.939,0.521,1.664,1.801,2.782,1.886c1.152-1.041,2.176-2.21,3.294-3.285c-1.4-2.005-3.43-3.405-5.009-5.222
	c2.031,0.811,3.977,1.835,5.931,2.833c-0.29-0.7-0.87-2.082-1.161-2.773c0.845,0.034,1.724,0.111,2.603,0.171
	c0.026-0.358,0.068-1.067,0.094-1.425c-1.724,0.068-3.439,0.128-5.146,0.299c-0.384-1.57-0.785-3.14-1.126-4.693
	c1.715,1.28,3.473,2.534,5.368,3.584c-0.171-1.562-1.835-3.866,0.674-4.275c-0.307-1.425-0.589-2.85-0.828-4.258
	c0.794-0.444,1.604-0.87,2.423-1.297c0.256,0.375,0.777,1.135,1.033,1.51c0.06-0.7,0.188-2.099,0.247-2.799
	c4.812,5.447,4.808,8.106,5.888,10.3c-0.026,0.964-0.085,1.92-0.085,2.927c1.894,0.418,3.661-0.179,5.333-1.075
	c-1.527,2.662-3.038,5.376-3.925,8.337c-0.606,0.887-1.186,1.792-1.758,2.705c0.401-0.12,1.195-0.367,1.596-0.486
	c0.512,1.007,1.485,2.364,2.739,1.399c0.418-1.041,0.751-2.099,1.22-3.106c0.444,0.205,1.34,0.614,1.783,0.819
	c-3.227,4.734-9.147,5.496-6.195,7.68c1.255-0.836,2.372-1.843,3.533-2.816c-0.017,1.101-0.026,2.21-0.026,3.328
	c1.024,0.307,2.065,0.631,3.115,0.973c0.154-0.179,0.478-0.538,0.64-0.717c0.017-1.766,0.529-3.473,1.161-5.094
	c-0.452-0.896-0.896-1.775-1.331-2.645c0.887,0.606,1.766,1.254,2.714,1.835c0.265-1.271,0.478-2.534,0.717-3.78
	c0.776-0.546,1.544-1.109,2.304-1.69c-0.094-1.143-0.452-2.236-0.725-3.311c0.461-0.623,0.947-1.229,1.442-1.826
	c0.806,6.706,0.129,5.379,0.956,8.201c-0.29,0.768-0.572,1.553-0.836,2.338c0.435,0.026,1.306,0.068,1.741,0.094l0.009-0.913
	l0.725-0.247c-0.521,1.34-0.64,2.765-0.333,4.181c-0.913,1.63-2.014,3.285-2.099,5.214c1.434,1.357,3.439,1.698,5.257,2.304
	c-0.504,0.324-1.51,0.973-2.022,1.297c0.162,1.664,0.094,3.524,1.442,4.779c-0.649,1.229-1.263,2.483-1.826,3.763
	c2.074,2.15,3.371,4.873,5.043,7.33c0.111-0.461,0.333-1.399,0.444-1.86c0.461,0.964,0.93,1.929,1.425,2.918
	c1.621-0.026,3.251-0.068,4.898-0.102c0.307,1.101,0.614,2.219,0.939,3.345c0.546-0.051,1.655-0.154,2.21-0.196
	c-2.243,4.383-3.015,4.994-1.058,10.769c0.998,0.102,2.978,0.299,3.968,0.401c-0.657,0.273-1.971,0.828-2.628,1.101
	c0.017,0.495,0.06,1.485,0.085,1.98c0.998-0.299,2.014-0.589,3.038-0.879c-0.06-1.92-0.23-3.831-0.87-5.641
	c2.287-1.741-0.435-3.294-1.707-4.48c0.717,0.384,2.159,1.169,2.876,1.553c-0.418-1.323-0.845-2.628-1.271-3.917
	c0.051-0.614,0.128-1.22,0.213-1.809c1.101-0.333,2.219-0.657,3.345-0.981c0.538,0.811,1.092,1.647,1.655,2.483
	c4.253-2.617,1.756-0.821,6.938-3.712c-0.188-0.316-0.546-0.93-0.725-1.237c1.041-0.145,2.082-0.316,3.132-0.521
	c1.348-1.459,1.519-3.592,2.142-5.402c0.333-0.358,0.998-1.067,1.331-1.417c0.051-0.512,0.145-1.527,0.196-2.031
	c0.674-0.051,2.014-0.154,2.688-0.196c-0.034-1.271-0.051-2.526-0.043-3.772c1.067,0.512,2.133,1.033,3.208,1.579
	c-0.367-1.408-0.691-2.807-0.973-4.215c0.973-0.99,1.954-1.98,2.935-2.97c0.589-0.017,1.758-0.051,2.338-0.068
	c0,0.691-0.017,2.065-0.026,2.756c1.493,0.751,3.055,1.399,4.506,2.278c1.016,2.031,1.536,4.386,1.109,6.656
	c1.263,1.98,2.381,4.062,3.302,6.238c0.478-0.248,0.973-0.486,1.485-0.708c1.544,2.654,5.376,1.289,6.844-0.777
	c0.102,0.597,0.307,1.8,0.41,2.398c0.853-0.051,2.56-0.162,3.405-0.213c-0.648-0.29-1.954-0.853-2.603-1.144
	c1.212-1.835,2.509-3.618,3.891-5.333c-0.597-0.324-1.775-0.973-2.372-1.306c0.145-0.504,0.418-1.51,0.555-2.014
	c-0.469-0.375-1.417-1.135-1.894-1.519c0.58-0.41,1.178-0.802,1.792-1.186c0.102-1.621,0.248-3.217,0.375-4.813
	c-0.879-3.627,3.465-5.726,3.448-9.344c1.032-1.118,2.048-2.244,3.098-3.336c0.026,1.51,0.051,3.038,0.085,4.565
	c0.384,0,1.152,0,1.536,0c0.034-0.427,0.111-1.289,0.145-1.715l0.615,0.009c0.162,2.56,0.742,5.069,1.545,7.509
	c-0.401,0.785-0.777,1.587-1.143,2.406c2.039,2.85,3.371,6.093,3.849,9.583c-0.324,0.282-0.99,0.853-1.323,1.143
	c-0.333,1.399-0.674,2.807-0.998,4.233c1.416-0.256,3.268,0.196,4.147-1.289c0.264-1.468-0.341-2.893-0.623-4.292
	c2.065,0.742,4.13,1.536,6.229,2.253c-0.666-1.203-1.34-2.364-2.022-3.524c1.203-3.268,1.604-6.741,2.534-10.078
	c0.657,0.478,1.323,0.964,2.005,1.468c0.691-1.024,1.246-2.193,2.27-2.936c2.33,1.528,2.671,4.685,3.661,7.108
	c0.58,0.085,1.749,0.239,2.338,0.316c-0.196,2.423-1.425,5.24,0.538,7.27c-0.171,0.136-0.521,0.392-0.7,0.529
	c2.859,1.43,1.103,0.884,5.547,10.445c0.7,0.503,1.408,1.024,2.125,1.562c2.458,0.154,4.924,0.256,7.407,0.273
	c-0.12-2.039-0.282-4.062-0.495-6.067l0.631,0.102c0.256,2.953,1.101,5.811,1.98,8.636c1.562-1.348,4.728-2.091,3.9-4.71
	c1.246-1.553,0.623-3.482,0.614-5.257c0.137-2.039,0.589-4.028,0.964-6.016c0.461,2.56,1.263,5.052,1.86,7.595
	c0.495,2.842-0.247,6.246,2.21,8.388c0.811-0.205,1.63-0.435,2.458-0.683c-0.162-0.401-0.503-1.203-0.674-1.604
	c0.171-0.452,0.495-1.374,0.666-1.835c0.307-0.077,0.922-0.222,1.229-0.299c-0.631-2.458,0.802-4.71,1.647-6.912
	c-0.109-1.855,0.955-11.823,1.075-12.826c1.289,1.092,2.577,2.202,3.908,3.285c-0.691-2.662-1.212-5.35-1.306-8.09l0.691-0.017
	c-0.051,2.978,2.176,4.608,4.728,5.513c-0.256-0.418-0.751-1.254-0.998-1.664c0.469-0.521,0.956-1.024,1.459-1.519
	c-0.179-0.367-0.529-1.101-0.708-1.468c0.495,0,1.485,0,1.98,0c-0.299-0.427-0.879-1.271-1.169-1.698
	c0.026-0.538,0.085-1.613,0.12-2.15c-1.024-0.666-2.022-1.34-2.995-2.022c1.562,0.282,3.132,0.597,4.71,0.896
	c-0.017-0.597-0.043-1.8-0.06-2.398c-0.427-0.247-1.28-0.734-1.707-0.981c0.026-1.212,0.06-2.406,0.102-3.601
	c0.495,0.009,1.476,0.009,1.963,0.009c-0.043,0.452-0.119,1.365-0.162,1.826c0.589-0.495,1.203-0.973,1.826-1.434
	c-0.495-1.169-0.939-2.321-1.374-3.482c0.922-0.76,1.869-1.502,2.824-2.244c-0.239-0.35-0.708-1.05-0.947-1.399
	c0.392-0.819,0.794-1.63,1.203-2.432c-0.913-0.444-1.809-0.87-2.697-1.289c-0.41-0.802-0.794-1.587-1.169-2.372
	c1.169,0.691,2.355,1.391,3.558,2.082c0.009-0.657,0.034-1.971,0.043-2.628c-1.698-1.092-2.987-2.62-3.814-4.429
	c2.014,1.024,4.181,1.715,6.417,2.176c-0.009-0.504-0.017-1.519-0.017-2.031c-0.427-0.333-1.271-0.998-1.69-1.331
	c0.717-1.05,1.425-2.099,2.142-3.157c0.555-0.06,1.672-0.188,2.227-0.247c-0.461-1.707-0.922-3.413-1.331-5.103
	c-1.007-0.017-1.988-0.017-2.961-0.009c-0.333-1.033-0.631-2.031-0.947-3.038c0.486-0.333,1.451-0.998,1.937-1.331
	c-0.068,0.725-0.205,2.176-0.273,2.901c0.495-0.034,1.476-0.102,1.963-0.136c0.469-0.76,1.084-1.357,1.843-1.792
	c0.205,0.418,0.623,1.246,0.828,1.664c0.751-0.247,1.519-0.486,2.295-0.708c-1.05-1.451-2.065-2.918-3.046-4.386
	c0.58,0.102,1.741,0.307,2.321,0.41c-0.597-2.782-3.106-6.05-0.964-8.653c-0.051-0.708-0.154-2.116-0.205-2.825
	c1.246,0.87,2.552,1.655,3.977,2.278c-0.649-1.323-1.57-2.449-2.543-3.507c2.253-0.222,4.736-0.503,6.383,1.468
	c0.384-1.024,0.734-2.074,1.144-3.072c1.69,1.826,3.473,3.567,5.231,5.342c-0.64,0.137-1.928,0.41-2.577,0.546
	c0.171,1.084,0.316,2.15,0.555,3.251c0.691-0.572,1.374-1.152,2.065-1.732c0.35,0.239,1.041,0.717,1.382,0.956
	c0.401-1.954,0.666-3.934,0.896-5.905c0.981-1.143,1.954-2.278,2.995-3.371c-0.196-0.913-0.358-1.818-0.503-2.705
	c0.845,1.297,1.638,2.628,2.423,3.977c-0.307,2.261-0.768,4.523-0.589,6.818c1.041,1.647,2.372,3.106,3.379,4.804
	c-0.683,0.546-1.357,1.109-2.005,1.681c1.98,0.239,1.826,1.553,1.126,3.012c0.282,0.794,0.589,1.604,0.905,2.423
	c0.427,0.102,1.288,0.307,1.715,0.418c0.768,1.92,2.295,3.268,4.045,4.318c0.947-0.896,1.928-1.783,2.901-2.671
	c-2.193-0.99-4.787-1.528-6.212-3.635c2.244,0.555,4.446,1.271,6.665,1.954c-0.375-1.229-0.742-2.449-1.075-3.669
	c-0.546,0.009-1.656,0.009-2.202,0.009c-0.708-1.263-1.391-2.534-2.031-3.797c1.297,0.759,2.569,1.579,3.874,2.372
	c-0.358-0.964-0.708-1.92-1.05-2.867c0.452-0.009,1.348-0.034,1.801-0.043c-1.408-1.638-2.714-3.362-3.849-5.18
	c1.485,0.358,2.987,0.759,4.497,1.143c0.051-1.613,0.111-3.217,0.205-4.813c1.05,0.375,2.441,0.401,3.089,1.51
	c0.205,1.28,0.222,2.594,0.324,3.917c0.444-0.247,1.34-0.725,1.792-0.973c-0.41,1.758-0.862,3.516-1.28,5.274
	c1.306-0.708,2.62-1.408,3.985-2.022c-1.57,1.946-3.294,3.772-4.898,5.7c1.374-0.606,2.756-1.246,4.147-1.86
	c-0.691,0.905-1.374,1.801-2.048,2.705c-0.077,1.067-0.128,2.142-0.162,3.226c1.272-0.648,2.543-1.28,3.857-1.877
	c-1.596,1.835-3.883,3.405-4.045,6.05c1.144-1.826,3.217-1.724,5.111-1.638c-0.922,0.759-1.877,1.468-2.85,2.142
	c-0.256,0.393-0.768,1.169-1.024,1.562c0.341,0.802,0.7,1.604,1.067,2.432c1.331-0.273,2.679-0.538,4.028-0.819
	c-0.418,1.289-0.836,2.586-1.203,3.891c-1.024,0.845-2.039,1.681-3.055,2.517c0.614,0.597,1.246,1.212,1.894,1.835
	c0.649,0.043,1.946,0.12,2.594,0.162c-1.092,0.819-2.185,1.621-3.251,2.449c0.597,0.06,1.775,0.188,2.364,0.256
	c-0.674,0.589-1.34,1.195-1.997,1.801c-0.026,1.203-0.043,2.415-0.026,3.644c2.039,0.358,4.028,0.998,5.922,1.886
	c0.162-0.307,0.487-0.913,0.649-1.22c-1.408-1.442-2.654-3.021-3.635-4.762c1.451,0.222,2.944,0.495,4.42,0.76
	c-1.707-2.372-4.147-4.224-5.231-7.006c2.176,0.845,3.866,2.5,5.606,4.011c0.853-0.529,1.715-1.067,2.586-1.604
	c-0.469-0.879-1.05-1.698-1.545-2.509c-0.102-2.321-0.58-4.608-2.039-6.468c0.99,0.427,2.005,0.853,3.038,1.271
	c-0.657-2.022-1.724-3.849-2.884-5.598c1.434,0.358,2.884,0.742,4.344,1.126c-1.621-1.835-3.226-3.703-4.565-5.743
	c1.246,0.649,2.5,1.306,3.763,1.988c-0.247-1.673-0.461-3.319-0.734-4.966c0.213,0.256,0.631,0.768,0.845,1.024
	c0.367-0.29,1.092-0.862,1.451-1.152c0.828,0.725,1.672,1.468,2.534,2.21c0.248-0.742,0.495-1.485,0.734-2.219
	c-2.313-1.28-4.497-2.807-6.05-4.966c0.691,0.213,2.073,0.657,2.765,0.87c1.254-2.782,0.154-5.786-0.094-8.661
	c1.246,0.939,2.5,1.886,3.78,2.807c-0.58-1.434-1.186-2.842-1.783-4.241c0.76-2.005,0.657-4.13-0.119-6.093
	c1.169,0.265,2.415,0.777,3.618,0.247c-1.007-0.973-2.364-1.732-2.56-3.226c0.666-1.34,1.51-2.586,2.321-3.831
	c-0.077-0.503-0.239-1.502-0.324-1.997c-0.478-0.333-1.433-0.998-1.911-1.331c0.776-0.7,1.562-1.382,2.372-2.065
	c-0.341-1.374-0.674-2.731-1.033-4.07c0.666-0.998,1.323-1.988,2.005-2.97c-0.375,0.077-1.126,0.222-1.493,0.299
	c0.512-1.604,0.981-3.217,1.476-4.813c0.384,1.698,0.836,3.439,1.459,5.103c-0.171,0.324-0.529,0.981-0.7,1.306
	c0.444,0.589,0.905,1.195,1.382,1.801c-0.529,0.913-1.032,1.835-1.527,2.765c0.333-0.009,1.007-0.017,1.34-0.017
	c-0.444,2.56,0.649,5.06,0.512,7.646c0.589,0.469,1.203,0.964,1.835,1.459c-0.853,1.246-1.681,2.509-2.475,3.789
	c1.254-0.307,2.526-0.648,3.831-0.862c0.427,1.195,0.64,2.44,0.939,3.703c-1.416-0.435-2.79-0.879-4.173-1.331
	c-0.316,1.015-0.606,2.031-0.887,3.063c0.674-0.444,2.014-1.331,2.688-1.775c0.538,1.382,1.016,2.782,1.562,4.19
	c-0.529,0.503-1.041,1.024-1.536,1.562c0.017,0.913,0.043,1.835,0.077,2.765c1.05-0.401,2.099-0.802,3.183-1.195
	c-0.495,0.904-0.947,1.843-1.391,2.773c0.247,1.502,0.444,3.021,0.631,4.548c1.954,0.444,1.289,2.142,0.99,3.567
	c0.154,0.393,0.452,1.169,0.606,1.562c-1.323,0.631-2.62,1.297-3.9,1.98c1.101,0.051,2.227,0.102,3.362,0.162
	c0.7-0.819,1.442-1.596,2.219-2.347c1.468,0.435,0.154,2.116,0.239,3.14c0.367,0.307,1.101,0.93,1.459,1.237
	c-0.717,2.039-1.963,3.789-3.866,4.907c1.656,0.179,3.337,0.29,4.966,0.751c-1.092,1.152-2.159,2.313-3.243,3.464
	c0.606,0.06,1.801,0.162,2.398,0.222c-0.64,0.964-1.28,1.92-1.894,2.893c1.169,1.169,2.441,2.509,4.096,1.203
	c0.017-1.92,0.077-3.831,0.256-5.726c1.195,1.058,1.604,2.441,0.913,3.925c-0.077,2.295-0.128,4.634,0.563,6.861
	c-0.188,0.393-0.555,1.169-0.742,1.553c1.314,0.768,2.628,1.536,3.959,2.338c0-2.415,0.649-4.727,2.014-6.716
	c-0.597-0.614-1.169-1.203-1.724-1.784c1.596-0.964,2.825-2.611,2.031-4.523c0.06-0.265,0.188-0.802,0.248-1.075
	c-0.154-2.142,0.128-4.275,0.555-6.357c-0.427-4.267,0.525-14.204,0.589-15.206l0.64-0.111c0.025,2.042,0.675,14.685,2.44,17.502
	c-0.623,0.751-1.254,1.502-1.877,2.253c0.759,0.043,2.287,0.137,3.055,0.188c-0.23,1.254-0.418,2.543-0.631,3.823
	c0.939,2.884,2.731,5.888,1.63,8.994c0.41,0.64,0.836,1.288,1.288,1.946c0.051-0.503,0.145-1.51,0.196-2.014
	c0.862,0.375,1.741,0.751,2.637,1.135c-0.294-2.176-0.425-3.306-0.7-6.178c0.367-0.29,1.092-0.862,1.451-1.143
	c-0.017,1.161-0.017,2.33,0,3.507c0.358-0.188,1.075-0.58,1.434-0.776c1.911,3.951,3.644,8.021,3.959,12.467
	c1.69-0.853,2.577-2.27,2.313-4.198c-0.76-1.007-1.459-2.014-2.133-3.038c0.87,0.469,1.775,0.973,2.671,1.485
	c0.239-0.486,0.734-1.451,0.981-1.929c-0.12-0.265-0.341-0.785-0.461-1.05c0.708-2.278,1.237-4.608,1.672-6.946
	c1.007,1.161,2.057,2.304,3.132,3.43c-0.23-3.594,0.167-3.949-1.741-7.339c0.768-1.186,1.57-2.355,2.389-3.507
	c-0.572-3.652,0.179-7.228,1.4-10.667l0.589-0.043c0.017,0.7,0.051,2.099,0.068,2.79c1.101-0.435,2.219-0.87,3.354-1.289
	c-0.017-1.084-0.009-2.167,0.009-3.234c0.666,0.06,1.98,0.188,2.637,0.247c-0.264,0.768-0.521,1.553-0.751,2.347
	c0.973,4.233,3.669,8.405,2.68,12.868c0.401,0,1.195,0,1.587,0c-0.649,0.828-1.314,1.647-1.869,2.543
	c0.538,0.896,1.186,1.732,1.826,2.611c-0.316,0.418-0.939,1.263-1.255,1.69c0.367,0.384,1.101,1.152,1.468,1.536
	c-0.359,0.461-1.075,1.365-1.434,1.826c0.666-0.009,2.005-0.017,2.671-0.026c2.645,3.166,1.417,7.202,0.401,10.761
	c0.529,1.476,1.178,2.935,1.451,4.506c-0.546,0.956-1.203,1.843-1.809,2.765c1.468-0.154,2.953-0.299,4.446-0.418
	c-0.282,0.845-0.546,1.698-0.802,2.569c0.563,0.085,1.69,0.273,2.253,0.358c0.922-1.707,1.818-3.43,2.731-5.146
	c-0.623-0.06-1.877-0.179-2.5-0.239c-0.026-1.399-0.051-2.799-0.06-4.164c0.956,0.623,1.911,1.289,2.935,1.869
	c0.299-0.964,0.597-1.92,0.905-2.867c0.606-0.64,1.007-1.391,1.186-2.244c-0.418-2.859,0.136-5.734,0.213-8.585l0.606-0.017
	c0.111,0.947,0.29,1.929,0.452,2.901c-0.316,2.799-0.35,5.649-1.109,8.388c0.964,0.828,1.928,1.638,2.893,2.534
	c-0.427,0.282-1.271,0.845-1.698,1.126c-0.162,0.973-0.333,1.937-0.452,2.935c0.922,0.529,1.86,1.092,2.808,1.656
	c0.478-0.256,1.417-0.777,1.894-1.032c0.213,1.348-0.384,2.56-0.87,3.78c-0.99-0.981-1.911-1.929-2.859-2.884
	c0.009,0.58,0.034,1.724,0.043,2.296c1.613,1.305,3.277,2.56,5.077,3.627c-0.717,0.009-2.159,0.026-2.876,0.034
	c0,0.845,0,2.534,0,3.379c3.191-0.043,6.716-0.905,9.506,1.143c0.58-1.417,1.161-2.816,1.758-4.215
	c-0.119-0.333-0.35-1.007-0.469-1.348c0.29-1.98,0.461-3.977,0.674-5.948c0.802,1.903,1.058,4.548,3.465,5.154
	c-0.375-1.084-0.751-2.15-1.126-3.208c0.759-1.22,1.536-2.441,2.33-3.644c0.256-1.365,0.461-2.825,1.741-3.618
	c-0.939-0.939-1.877-1.869-2.773-2.807c0.495,0.145,1.493,0.435,1.988,0.58c0.853-0.836,1.715-1.664,2.594-2.483
	c-0.469-1.126-0.913-2.253-1.34-3.371c0.299-1.331,0.572-2.654,0.87-3.968c-2.074,0.094-4.164,0.017-5.717-1.502
	c0.529-0.888,1.067-1.775,1.621-2.645c0.478-0.094,1.425-0.29,1.903-0.384c0.717-0.691,1.442-1.374,2.185-2.048
	c-1.22-0.64-2.492-1.186-3.755-1.638c-0.674-1.732-1.246-3.499-1.783-5.257c0.708-1.331,1.715-2.859-0.495-3.166
	c0.307-0.973,0.615-1.946,0.956-2.901c1.451-0.981,2.893-2.065,3.644-3.712c-0.947,0.273-1.877,0.572-2.782,0.888
	c-0.64-0.674-1.271-1.331-1.886-1.98c0.691-2.022,1.502-4.053,3.174-5.478c0.034-0.358,0.111-1.067,0.154-1.425
	c-2.859-0.06-3.789-2.441-4.54-4.745c0.478-0.614,0.981-1.22,1.493-1.809c0.077-0.785,0.154-1.57,0.265-2.338
	c-0.375-0.29-1.143-0.879-1.519-1.178c0.486-0.742,0.981-1.476,1.502-2.202c-1.246-0.384-2.475-0.777-3.695-1.169
	c1.34-0.836,2.765-1.587,4.002-2.594c-0.469-0.503-1.408-1.519-1.886-2.031c0.068-1.365,0.145-2.807,1.621-3.388
	c0.503-3.234,1.007-6.477,1.203-9.745c0.41,0.247,1.22,0.751,1.621,0.998c0.128,1.63,0.171,3.268,0.094,4.915
	c0.316-0.085,0.939-0.265,1.255-0.358c0.828,2.014,1.69,4.019,2.389,6.101c2.125,1.442,4.275,2.859,6.52,4.139
	c-1.51,0.708-3.029,1.417-4.369,2.415c0.828,1.519,1.698,3.038,2.057,4.779c0.375,0.043,1.143,0.12,1.527,0.162
	c-0.034,0.486-0.085,1.468-0.119,1.954c1.852,0.521,1.57,1.869,0.87,3.26c0.213,0.367,0.64,1.109,0.853,1.476
	c0.009,3.268-0.316,6.682,0.981,9.779c0.017,0.273,0.06,0.811,0.077,1.075c1.442,1.263,2.773,2.645,4.087,4.062
	c-0.546,0.529-1.075,1.058-1.587,1.604c0.324,0.538,0.666,1.092,1.024,1.655c-0.196,1.015-0.384,2.022-0.529,3.063
	c1.809-0.444,3.635-0.828,5.495-1.178c-0.359-1.007-0.717-2.005-1.05-2.995c2.227-1.638,3.789-4.011,4.548-6.665
	c-0.913,0.691-1.775,1.434-2.586,2.244c-1.647,0.282-3.268,0.58-4.89,0.913c0.401-0.691,0.819-1.365,1.263-2.031
	c1.34-0.503,2.645-1.126,3.874-1.894c-1.451-0.12-2.893-0.213-4.318-0.273c0.503-0.452,1.51-1.348,2.005-1.801
	c-0.469-0.333-1.425-0.99-1.903-1.323c-0.333,0.068-0.981,0.213-1.314,0.282c-0.281-0.316-0.845-0.947-1.118-1.263
	c1.05-0.017,2.125-0.026,3.217-0.017c-0.171-1.801,0.666-2.978,2.509-3.328c-1.451-1.152-3.183-1.809-4.932-2.27
	c0.964-0.563,1.963-1.109,2.961-1.656c-0.478-0.247-1.434-0.742-1.92-0.981c1.101-1.621,2.159-3.277,2.825-5.12
	c-1.016-0.486-2.022-0.956-3.012-1.425c0.597-0.623,1.212-1.229,1.843-1.835c0.026-0.529,0.077-1.579,0.111-2.108
	c0.41-0.316,1.246-0.93,1.664-1.237c-0.299-0.393-0.887-1.186-1.186-1.587c0.956-0.811,1.929-1.604,2.918-2.406
	c-0.879-2.355-0.964-4.864-0.41-7.305c1.319,1.7,1.214,3.705,1.672,5.931c-0.734,2.79,0.845,5.41,1.237,8.132
	c-0.546,0.947-1.203,1.818-1.801,2.722c1.579-0.427,3.157-0.845,4.779-1.203c-0.742,1.22-1.485,2.424-2.236,3.635
	c1.314,0.017,2.645,0.051,3.994,0.111c-1.886,1.451-4.002,2.577-6.426,2.611c0.222,0.273,0.674,0.811,0.896,1.084
	c1.988-0.265,4.002-0.393,6.033-0.452c-0.7,0.563-1.382,1.135-2.057,1.715c0.333,0.435,0.981,1.314,1.306,1.758
	c-0.41,0.009-1.237,0.034-1.655,0.043c-0.196,1.946-0.683,3.857-1.758,5.521c-0.12,1.604-0.196,3.209-0.239,4.838
	c0.495-0.239,1.485-0.717,1.98-0.956c-0.06-0.657-0.179-1.963-0.239-2.611c0.683,0,2.031,0,2.714,0.009
	c-0.205,0.299-0.597,0.896-0.802,1.195c1.254,0.853,2.449,1.818,3.618,2.833c-1.519,0.341-2.995,0.717-4.472,1.092
	c0.427,1.468,0.87,2.944,1.34,4.429c0.845-0.401,1.698-0.802,2.577-1.186c0.384,0.461,1.143,1.382,1.527,1.843
	c-1.331,0.008-2.637,0.034-3.934,0.06c0,0.555,0.009,1.664,0.009,2.219c1.237,0.393,2.492,0.811,3.755,1.254
	c-0.742,0.666-1.468,1.331-2.176,2.005c-0.137,3.499,1.852,6.46,3.661,9.284c-1.041,1.621-2.236,3.132-3.524,4.557
	c0.435,0.495,0.896,1.007,1.374,1.536c0.546-0.375,1.638-1.118,2.185-1.493c-0.239,0.888-0.478,1.783-0.691,2.697
	c0.734,0.563,1.485,1.135,2.253,1.724c0,0.785-0.009,2.355-0.009,3.14c0.384,0.009,1.143,0.034,1.527,0.043l-0.896,0.247
	c0.128,1.946,0.247,3.9,0.427,5.871c2.105-2.626,4.325-8.354-1.451-15.147c1.306-0.017,2.662,0.009,4.019,0.034
	c2.304,2.039,4.48,4.215,6.451,6.596c0.717-0.111,1.382-0.478,2.091-0.708c-0.691-1.067-1.306-2.082-1.954-3.115
	c-0.708,0.162-2.116,0.478-2.816,0.64c0.845-0.768,1.724-1.502,2.628-2.21c0.359-0.87,0.751-1.732,1.152-2.577
	c-1.382-0.87-2.645-1.869-3.789-3.004c0.887,0.247,1.783,0.503,2.696,0.794c0.316-0.41,0.956-1.229,1.272-1.638
	c-0.358-0.358-1.067-1.075-1.425-1.434c0.444-0.862,0.896-1.715,1.365-2.56c-0.794-0.367-1.57-0.717-2.338-1.058
	c0.12,0.648,0.358,1.963,0.478,2.611c-1.715-0.93-3.482-1.698-5.026-2.842c0.111-1.399,0.418-2.773,0.674-4.139
	c0.76,0.222,1.579,0.478,2.381,0.717c0.538,0.427,1.092,0.853,1.672,1.297c0.102-0.555,0.299-1.672,0.401-2.227
	c0.922,0.947,1.843,1.911,2.782,2.884c0.316-0.29,0.93-0.87,1.237-1.161c0.325-5.612,0.647-7.14,1.263-9.643
	c1.934,3.554,0.367,2.274,2.5,16.563c0.717,0.034,2.15,0.111,2.867,0.154c-1.109,1.818-1.963,3.78-2.415,5.871
	c1.152-0.043,2.321-0.068,3.499-0.102c0.486,0.299,1.459,0.904,1.945,1.203c1.361-5.671,0.279-8.418-1.963-11.298
	c1.246,0.017,2.5,0.034,3.78,0.06c-0.691-0.606-1.374-1.203-2.039-1.783c-0.435,0.282-1.297,0.836-1.732,1.118
	c-0.333-0.256-0.99-0.768-1.323-1.024c-0.043-0.879-0.094-1.758-0.12-2.611c1.169-0.026,2.355-0.043,3.558-0.06
	c-1.143-1.169-2.389-2.21-3.806-2.978c1.664-0.435,2.662-1.749,3.473-3.166c-1.169-1.51-2.253-3.055-3.354-4.574
	c1.067-0.102,2.133-0.213,3.251-0.282c-0.495-1.331-1.604-2.79-0.145-3.96c-0.845-0.99-1.664-1.98-2.466-2.97
	c0.879,0.307,1.766,0.631,2.671,0.964c0.043-1.775,0.094-3.558,0.265-5.308c0.802,1.399,1.382,2.918,1.801,4.497
	c0.742-0.759,1.476-1.51,2.27-2.236c-0.384,1.971-0.777,3.942-1.109,5.931c-0.76,0.742-1.485,1.51-2.193,2.295
	c2.022-0.222,4.062-0.529,6.084-0.879c-0.981,0.922-1.963,1.843-2.927,2.782c0.563,0.546,1.143,1.109,1.741,1.69
	c-0.657,0.435-1.297,0.879-1.92,1.34c0.299,0.427,0.905,1.28,1.212,1.707c-0.418,0.964-0.811,1.946-1.186,2.935
	c1.69-0.657,3.371-1.34,5.086-1.988c-1.22,1.032-2.492,1.997-3.61,3.132c0.111,2.142,0.094,4.292-0.145,6.443
	c1.34-0.102,2.671-0.444,4.002-0.785c-1.476,1.067-2.79,2.321-3.891,3.763c0.879,0.119,2.628,0.35,3.507,0.469
	c-0.981,0.674-1.954,1.348-2.918,2.022c0.085,1.229,0.205,2.475,0.282,3.721c0.299,0.247,0.896,0.734,1.195,0.981
	c0.87-0.375,1.766-0.751,2.671-1.101c-0.734-1.186-1.459-2.381-2.082-3.601c2.611,0.7,4.736,2.398,6.81,4.07
	c-0.171-2.176-1.57-3.772-3.089-5.154c0.683-0.094,2.057-0.273,2.739-0.367c-0.299-0.896-0.615-1.784-0.93-2.645
	c0.375,0.077,1.126,0.23,1.51,0.307c-0.205-1.271-0.171-2.577-0.529-3.789c-1.348-1.698-3.208-2.995-3.968-5.094
	c1.613,1.084,3.183,2.244,4.873,3.26c-0.64-1.331-1.263-2.637-1.911-3.934l0.87,0.043l0.128,1.178
	c0.478-0.503,0.981-0.99,1.502-1.468c-2.014-1.058-3.951-2.287-5.35-4.079c1.621,0.811,3.166,1.758,4.796,2.62
	c-0.085-2.722-0.111-5.436,1.092-7.953c-2.159-1.092-0.529-2.449,0.495-3.652c-0.452-0.358-1.357-1.058-1.809-1.408
	c0.279-0.409,2.802-4.094,3.302-4.745c0.469,1.536,0.956,3.106,1.442,4.659c-0.563,0.998-1.118,1.997-1.621,3.029
	c0.964-0.393,1.946-0.785,2.936-1.169c-0.683,1.741-1.331,3.49-1.971,5.248c0.708,0.017,2.125,0.06,2.833,0.085
	c-0.41,0.444-1.237,1.331-1.647,1.775c-0.094,0.512-0.265,1.536-0.358,2.048c-0.614,1.109-1.203,2.236-1.698,3.405
	c3.055-0.939,3.644-5.367,7.236-5.094c-1.203,1.954-2.381,3.934-3.482,5.948c2.842,0.324-0.145,2.654-0.469,4.036
	c1.579-1.169,3.175-2.364,5.043-3.072c-0.939,0.922-1.894,1.809-2.867,2.679c0.359,1.365,0.708,2.739,1.058,4.139
	c1.05-0.367,2.099-0.725,3.191-1.05c-0.546,2.176-0.956,4.378-1.22,6.613c0.546-0.273,1.638-0.802,2.184-1.067
	c-2.015,2.681-2.006,3.186-1.621,6.818c1.408-0.87,2.748-1.843,4.147-2.748c-0.002,0.002-6.468,10.974-6.468,10.974
	c1.877-1.271,3.516-2.842,5.291-4.241c0.111-0.683,0.341-2.039,0.452-2.714c1.732,0.905,1.28,2.935,1.442,4.574
	c-1.109,1.374-2.099,2.85-2.995,4.369c0.725,0.043,2.176,0.128,2.901,0.171c0.299-1.442,0.546-2.901,0.597-4.361
	c0.521,0.23,1.562,0.691,2.082,0.922c-0.521-1.98-0.998-3.968-1.476-5.939c1.468-2.551,3.413-4.77,5.367-6.946
	c-0.981-0.614-2.074-1.237-3.046-1.766c1.323,0.179,2.637,0.282,3.985,0.427c-0.068,1.007-0.102,2.039-0.145,3.072
	c0.299,0.29,0.904,0.87,1.212,1.161c0-1.143,0.017-2.287,0.06-3.413c0.503-0.469,1.041-0.93,1.596-1.382
	c-0.845-0.794-1.621-1.587-2.389-2.398c0.785-1.911,0.768-3.994,0.836-6.007c0.683,0.41,1.382,0.828,2.099,1.263
	c1.323,2.995,3.362,5.572,5.171,8.294c0.222-0.265,0.666-0.785,0.887-1.05c-0.751-2.355-2.193-4.992-0.128-7.1
	c0.503,1.314,1.024,2.645,1.536,3.994c0.367-0.316,1.101-0.93,1.468-1.237c-0.051-1.929-0.102-3.84-0.162-5.751
	c1.587,0.717,3.2,1.399,4.855,2.022c0.683-0.964,1.425-1.886,2.15-2.833c-1.741-0.026-3.465-0.034-5.171-0.043
	c0-0.461,0.009-1.374,0.009-1.835c0.367,0.282,1.101,0.845,1.468,1.135c0.265-1.553,0.597-3.072,1.016-4.574
	c-0.794-1.826-1.638-3.61-2.5-5.367c1.545,0.614,3.047,1.374,4.506,2.227c-0.614-1.835-1.058-3.712-1.365-5.598
	c-1.596-2.022-2.799-4.497-0.998-6.827c0.196,0.964,0.392,1.946,0.614,2.935c0.367,0,1.118,0.009,1.493,0.009
	c0-1.033,0.026-2.057,0.077-3.072c0.538-0.597,1.092-1.186,1.672-1.766c-1.237-2.526-1.297-5.478,0.478-7.765
	c1.326,6.844,1.044,2.616,1.203,9.054c0.998-0.572,1.937-1.451,3.183-1.459c1.144,3.9-1.365,7.791-0.085,11.648
	c-0.853,0.981-1.69,1.971-2.483,2.987c2.338,0.341,3.601-1.92,4.958-3.413c1.075-1.399-1.237-2.56-1.69-3.78
	c0.802,0.017,2.406,0.051,3.209,0.068c-0.418-0.862-0.819-1.724-1.203-2.569c0.794-1.28,1.613-2.543,2.44-3.806
	c-0.136,0.486-0.427,1.468-0.563,1.954c1.186,1.05,2.381,2.108,3.627,3.14c-0.452-1.306-0.896-2.577-1.314-3.857
	c0.87,0.247,1.766,0.512,2.671,0.785c-1.586-9.206,1.928-2.572-2.048-11.247c0.853,0.418,1.724,0.845,2.62,1.28
	c0.776-1.212,1.587-2.406,2.381-3.618c-1.263-0.307-2.5-0.461-3.763-0.469c-0.034-0.845-0.034-1.681-0.026-2.5
	c1.067-1.169,2.15-2.338,3.311-3.43c-0.333-0.691-0.631-1.374-0.913-2.039c-0.41,0.256-1.229,0.776-1.638,1.041
	c1.921-6.641,2.698-5.797,3.371-9.532c-0.026,2.287,0.034,4.591-0.256,6.878c0.751,0.324,1.528,0.666,2.33,1.007
	c-0.759,0.836-1.527,1.673-2.236,2.543c0.461,0.017,1.4,0.043,1.869,0.06c-0.572,0.375-1.118,0.759-1.647,1.161
	c0.41,0.922,0.836,1.86,1.28,2.816c0.922-0.333,1.852-0.649,2.807-0.939c-1.007,0.768-2.014,1.519-3.021,2.261
	c0.222,0.299,0.657,0.905,0.879,1.203c-0.136,0.29-0.418,0.879-0.563,1.169c1.502,1.374,0.947,3.268,0.521,4.992
	c0.836,0.794,1.587,1.732,2.244,2.731c-0.623,1.34-1.212,2.705-1.758,4.088c0.759-0.239,2.287-0.717,3.046-0.964
	c-0.785,1.246-1.553,2.5-2.295,3.772c0.444,0.188,1.34,0.58,1.783,0.777c1.681-1.442,2.577-3.524,3.567-5.453
	c-0.896,0.179-2.705,0.538-3.61,0.717c1.075-1.058,2.261-2.039,3.396-3.046c0.486-2.039,0.981-4.079,1.434-6.118
	c0.333-0.179,1.007-0.538,1.34-0.717c0.708,2.586,0.324,5.231,0.222,7.868c0.435,0.205,1.306,0.614,1.741,0.819
	c-0.341,0.367-1.032,1.092-1.374,1.459c0.29,0.469,0.879,1.417,1.178,1.886c-0.324,0.99-0.648,1.971-0.922,2.987
	c0.452,0.026,1.348,0.068,1.792,0.094c-0.077,1.673-0.23,3.379-1.289,4.762c0.563,1.835,1.135,3.669,1.681,5.521
	c1.203-0.819,2.406-1.638,3.618-2.466c0.76,0.606,1.545,1.212,2.347,1.826c-1.032-2.108-2.133-4.173-3.038-6.323
	c1.749-1.374,3.396-3.183,3.55-5.521c-0.418-1.058-1.05-2.014-1.63-2.953c0.333-0.794,0.691-1.57,1.058-2.338
	c-0.845-0.128-2.534-0.375-3.379-0.504c1.177-0.742,2.321-1.485,3.567-2.167c-1.442-0.973-3.021-1.681-4.668-2.15
	c-0.043-2.082-0.085-4.164-0.102-6.229c2.099,0.512,4.019,1.758,3.977,4.147c1.22,0.93,2.483,1.818,3.78,2.705
	c-0.034-1.493-0.034-2.978-0.026-4.446c-0.41-0.034-1.22-0.094-1.63-0.128c-0.085-1.263-0.145-2.5-0.196-3.738
	c-0.674-0.93-1.323-1.869-1.937-2.799c-0.956-0.384-1.895-0.768-2.807-1.135c-0.222-2.389-0.572-4.745-1.126-7.057
	c2.082,1.442,4.685,4.378,7.322,3.285c0.922-1.229-0.154-2.645-0.367-3.908c0.956,0.393,1.937,0.785,2.935,1.161
	c-0.529-1.775-1.98-2.688-3.627-3.191c-0.597-0.546-1.186-1.075-1.749-1.596c-0.392-1.391-0.785-2.773-1.152-4.147
	c2.184,0.478,4.326,1.254,6.016,2.799c0.913-0.444,1.852-0.87,2.807-1.289c-0.759-1.417-1.519-2.816-2.278-4.198
	c0.017-0.256,0.043-0.768,0.051-1.024c-0.58-2.005-1.126-4.011-1.604-6.025c1.476,0.478,2.978,0.99,4.48,1.502
	c-0.128-1.417-0.213-2.807-0.324-4.207c0.316-0.205,0.93-0.623,1.246-0.828c-1.724-1.843-1.22-4.344-1.988-6.528
	c0.102-2.731,1.963-5.24,1.4-7.97c0.401-0.512,0.819-1.007,1.271-1.485c-0.307-0.145-0.922-0.452-1.237-0.597
	c0.239-1.826,0.486-3.652,1.024-5.41c1.325-4.089,0.207-8.415,0.981-11.418c-0.307-1.536-0.614-3.098-0.785-4.617
	c1.86,1.8,3.456,3.925,5.683,5.35c-0.273-1.527-0.563-3.029-0.862-4.523c1.57,0.905,3.217,1.715,4.949,2.338
	c0-2.125-1.212-3.558-3.115-4.275c-0.879-0.93-2.014-1.485-3.26-1.579c0.051-0.358,0.136-1.075,0.188-1.434
	c1.536,0.469,3.081,0.922,4.668,1.34c-0.683-0.973-1.348-1.954-1.98-2.935c0.299-1.058,0.819-2.039,1.306-3.021
	c-1.69-0.785-2.645-2.176-2.637-4.045c10.03,5.614,1.25,1.229,15.394,5.555c-2.227-3.371-6.443-4.608-8.721-7.893
	c2.082,0.111,4.796,2.185,6.502,0.23c-0.819-1.434-2.568-1.237-3.874-1.741c-0.623,0.247-1.22,0.503-1.792,0.768
	c-0.376-0.427-1.118-1.263-1.493-1.681c-0.606-0.051-1.818-0.162-2.432-0.213c0.171-1.502,0.29-3.012,0.444-4.506
	c0.76,0.879,1.536,1.766,2.338,2.654c1.732,0.452,3.43,1.05,5.086,1.792c1.783-1.178,3.712-2.33,4.71-4.326
	c-1.946,0.614-3.823,1.391-5.692,2.167c0.333-1.314,0.691-2.628,1.058-3.925c-1.468,0.683-2.91,1.4-4.352,2.108
	c-0.734-1.058-1.459-2.116-2.167-3.149c-0.734-0.785-2.253-1.178-2.108-2.432c0.486-0.23,1.459-0.691,1.946-0.93
	c-0.094-0.384-0.29-1.169-0.384-1.562c0.802,0.435,1.621,0.887,2.458,1.34c0.026-1.758,0.145-3.507,0.324-5.248
	c0.41,0.222,1.212,0.666,1.613,0.887c-0.009-0.725-0.034-2.176-0.043-2.901c3.533,0.787,3.114,0.178,8.235,0.742
	c-0.427-1.638-0.811-3.251-1.254-4.864c-1.493,0.913-2.987,1.826-4.48,2.714c0.188-0.998,0.444-1.971,0.691-2.953
	c-1.493-0.776-2.935-1.562-4.36-2.398c0.529-0.887,1.118-1.758,1.69-2.62c0.7,0.939,1.434,1.894,2.167,2.859
	c0.7-0.222,1.434-0.427,2.176-0.631c0.939,1.246,1.903,2.637,3.695,2.551c0-0.683,0.009-2.065,0.009-2.756
	c-0.333,0.111-0.99,0.333-1.323,0.444c-0.759-1.05-1.519-2.091-2.253-3.123c-0.615,0-1.843-0.009-2.458-0.009
	c-0.563-1.536-0.887-3.132-0.009-4.599c0.819,0.751,1.647,1.519,2.509,2.295c-0.162-1.109-0.239-2.185-0.359-3.268
	c0.213-1.263,0.41-2.534,0.649-3.78c0.367-0.008,1.118-0.034,1.493-0.043c-0.555-1.698-1.084-3.396-1.621-5.086
	c0.324-0.35,0.973-1.058,1.297-1.417c1.357,0.964,2.748,1.886,4.241,2.688c-0.615-1.886-1.28-3.746-1.937-5.598
	c0.435-0.171,1.297-0.503,1.732-0.666c0.785,1.297,1.758,2.424,3.123,3.217c-1.178-2.859-2.671-5.564-3.721-8.465
	c0.606,0.017,1.826,0.043,2.441,0.06c0.768,0.913,1.587,1.843,2.406,2.756c0.461-1.545,0.836-3.2,2.082-4.335
	c0.444-1.169-0.077-3.089,1.212-3.695c0.981,1.289,1.835,2.859,3.661,3.081c-0.469-1.109-0.939-2.21-1.408-3.285
	c0.529-0.538,1.084-1.058,1.664-1.579c2.048,2.406,2.867,5.385,2.731,8.516c0.589,0.128,1.775,0.375,2.372,0.504
	c0.077,0.614,0.222,1.843,0.299,2.458c0.529-0.324,1.596-0.956,2.125-1.271c-0.154,1.527-0.469,3.046-1.485,4.258
	c-0.35,2.782-4.326,2.722-4.54,5.666c4.412-0.521,5.316-6.511,9.574-7.219c0.239,1.374-0.154,2.731-0.529,4.045
	c-1.835,0.657-4.506,0.708-4.745,3.191c0.64-0.051,1.911-0.154,2.551-0.205c-2.338,1.613-4.779,3.089-7.364,4.25
	c-0.29,0.563-0.555,1.143-0.794,1.732c1.007,0.213,2.031,0.444,3.08,0.691c0.068-0.7,0.205-2.099,0.282-2.79
	c1.024,0.29,2.116,0.614,3.183,0.939c1.937-2.082,3.9-4.48,6.98-4.71c-0.205,3.524-5.572,5.367-3.362,9.045
	c-1.101,0.512-2.074,1.656-3.362,1.408c-0.683-1.05-1.212-2.142-1.852-3.174c-0.947,0.597-1.809,1.271-2.662,1.954
	c2.142,0.435,3.891,1.459,3.9,3.866c2.022-0.572,4.053-1.152,6.067-1.818c-0.93,1.792-2.978,3.524-1.954,5.734
	c2.799-0.87,4.232-3.575,5.956-5.726c-0.06,1.22-0.111,2.449-0.154,3.686c0.265,0.427,0.802,1.289,1.067,1.715
	c-2.074,1.946-5.751,2.372-6.4,5.538c2.577-0.99,5.043-2.236,7.688-3.081c-1.152,2.953-4.412,3.396-7.117,3.942
	c-0.068,0.538-0.222,1.621-0.299,2.159c0.358-0.051,1.067-0.162,1.417-0.213c0.358,0.631,1.075,1.894,1.433,2.526
	c-0.742,0.461-1.451,0.939-2.15,1.425c1.621,0.777,3.43,1.476,4.403,3.132c0.29,0,0.879-0.017,1.169-0.026
	c0.512-0.853,1.084-1.673,1.707-2.466c0.862,0.171,1.732,0.393,2.611,0.649c-1.152,3.157-3.311,5.709-5.752,7.945
	c1.237,0.12,2.509,0.145,3.789,0.12c-0.273,0.333-0.828,0.981-1.101,1.306c-1.357,0.427-2.714,0.836-4.036,1.254
	c0,1.032,0.017,2.065,0.06,3.106c1.681-1.246,3.388-2.458,5.128-3.635c-0.188,0.606-0.563,1.809-0.751,2.415
	c1.408-0.887,2.816-1.8,4.258-2.679c-0.256,0.572-0.777,1.724-1.041,2.295c0.247,0.375,0.725,1.118,0.973,1.493
	c-1.203,0.725-2.372,1.459-3.524,2.202c0.512,0.316,1.545,0.93,2.065,1.237c-0.538,0.085-1.604,0.247-2.142,0.324
	c0.23,0.973,0.478,1.946,0.742,2.935c-0.794-0.751-1.562-1.493-2.295-2.236c-1.186,1.126-2.389,2.236-3.499,3.422
	c0.649,0.555,1.357,1.067,2.082,1.613c1.288-0.956,2.637-1.843,4.011-2.697c-0.307,1.024-0.597,2.048-0.879,3.089
	c2.466-0.845,4.873-1.886,7.194-3.098c-0.879,1.579-1.758,3.166-2.458,4.83c2.33-0.64,3.942-2.483,5.658-4.07
	c-0.461,1.297-0.896,2.603-1.34,3.908c-1.724,0.896-3.379,1.911-5.001,2.961c-0.196,0.888-0.375,1.792-0.538,2.697
	c-2.799-1.092-5.828-0.836-8.346,0.785c1.024,0.93,2.082,1.86,3.157,2.799c-0.606,0.273-1.818,0.802-2.423,1.075
	c0.034,0.495,0.102,1.485,0.137,1.98c0.452,0.068,1.348,0.205,1.801,0.273c0.188-0.486,0.563-1.468,0.759-1.954
	c1.621-0.734,3.243-1.502,4.855-2.304c0.051,0.623,0.154,1.869,0.205,2.492c-1.05,0.99-2.065,2.005-3.046,3.055
	c0.973,1.357,0.623,2.722-0.265,3.985c1.05,0.239,2.125,0.503,3.226,0.768c0.794-1.169,1.63-2.313,2.475-3.448
	c0.597,0,1.783-0.008,2.381-0.008c0.623-0.7,1.28-1.391,1.954-2.065c-0.09,5.738-4.853,6.391-7.27,7.262
	c0.751,0.785,1.519,1.579,2.296,2.389c-1.289,0.077-2.56,0.162-3.815,0.247c0.307,0.435,0.905,1.297,1.203,1.724
	c1.357-0.384,2.722-0.785,4.113-1.169c-0.93,2.142-1.843,4.292-2.654,6.477c1.212-0.06,2.457-0.119,3.72-0.154
	c0.367,0.486,1.109,1.459,1.485,1.946c2.167-1.178,4.053-2.978,6.554-3.49c0.085-0.256,0.248-0.759,0.333-1.015
	c0.265,0.145,0.802,0.435,1.067,0.58c1.288-1.246,2.679-2.449,4.489-2.884c-0.64,1.604-1.4,3.157-2.313,4.634
	c-0.393-0.171-1.178-0.495-1.562-0.657c-0.521,0.563-1.016,1.143-1.493,1.732c-1.143,0.623-2.253,1.271-3.336,1.954
	c1.195,0.154,2.415,0.316,3.652,0.478c-0.188,0.435-0.572,1.297-0.759,1.732c0.521-0.102,1.562-0.29,2.091-0.393
	c0.597-0.76,1.237-1.502,1.886-2.236c0.248,0.691,0.734,2.082,0.981,2.773c-0.478,0.196-1.417,0.597-1.894,0.802
	c-0.597,1.809-1.485,3.499-2.321,5.214c0.776,0.461,1.579,0.905,2.423,1.34c-0.964,0.862-1.937,1.724-2.876,2.611
	c1.28,1.664,1.57,3.712,1.604,5.769c-0.435,0.307-1.314,0.939-1.749,1.246c0.128,0.273,0.393,0.811,0.521,1.075
	c-0.486,1.254-0.922,2.526-1.348,3.806c0.631,0.102,1.903,0.307,2.534,0.401c-0.324,0.751-0.623,1.51-0.905,2.278
	c-0.504-0.256-1.493-0.759-1.988-1.016c0.23,0.589,0.7,1.766,0.93,2.347c-0.273,0.708-0.529,1.417-0.76,2.142
	c0.521-0.06,1.562-0.188,2.091-0.247c1.007-1.306,2.099-2.551,3.285-3.721c-0.316,2.423-1.86,4.736-1.084,7.236
	c0.632,0.026,1.895,0.085,2.517,0.119c0.111,1.178,0.248,2.364,0.41,3.558c0.879-0.58,1.749-1.178,2.671-1.758
	c0.563-0.034,1.69-0.119,2.253-0.154c-0.708-1.212-1.408-2.432-2.074-3.635c1.22,0.316,2.483,0.674,3.738,1.032
	c-0.179-1.067-0.307-2.108-0.444-3.149c-0.427-0.555-0.828-1.092-1.22-1.621c1.126-0.503,2.253-1.007,3.422-1.493
	c-0.717-0.905-1.434-1.801-2.125-2.68c0.136-1.058,0.247-2.125,0.435-3.157c0.742,0.333,1.51,0.674,2.287,1.024
	c0.742-1.058,1.502-2.108,2.253-3.166c-0.649-0.068-1.937-0.213-2.586-0.29c-0.674-0.956-1.331-1.886-1.988-2.816
	c-0.034-0.393-0.094-1.169-0.128-1.562c1.058-0.401,2.091-0.828,3.166-1.246c-0.589-0.324-1.775-0.956-2.364-1.28
	c0.213-0.333,0.631-0.998,0.845-1.331c-0.742-1.502-0.708-3.115-0.085-4.625c-0.239-0.529-0.734-1.587-0.973-2.116
	c2.944,0.811,6.622,3.703,9.276,0.802c-1.758-0.529-3.541-0.947-5.231-1.596c-0.282-1.118-0.384-2.253-0.495-3.371
	c0.649-0.64,1.323-1.271,2.014-1.894c-1.118-1.493-1.877-3.191-2.167-5.018c-0.964-0.461-1.869-0.896-2.79-1.331
	c0.572-0.307,1.698-0.922,2.27-1.229c-0.136-1.69-1.297-3.021-2.014-4.471c0.691-0.043,2.073-0.137,2.773-0.179
	c0.017-0.606,0.068-1.826,0.094-2.432c1.263-0.06,2.543-0.111,3.849-0.154c-1.468-1.783-4.326-3.132-3.925-5.794
	c1.024-1.92,2.5-3.533,3.695-5.342c-1.246-0.913-2.475-1.843-3.644-2.79c1.254,0.333,2.526,0.683,3.814,1.041
	c-0.41-1.229-1.058-2.321-2.202-2.944c0.717-0.401,1.468-0.794,2.236-1.178c-0.213-2.654-0.29-5.316-0.469-7.97
	c1.86-1.476,0.145-3.772-0.102-5.581c0.273-0.461,0.828-1.365,1.101-1.818c-0.213-0.29-0.64-0.879-0.853-1.169
	c0.307-0.265,0.93-0.777,1.237-1.041c0.614-2.91,0.981-5.888,1.783-8.764c1.34,2.492,0.998,5.316,0.555,8.004
	c0.512,0.171,1.536,0.512,2.039,0.691c-0.196,0.375-0.606,1.135-0.811,1.519c0.265,0.427,0.811,1.28,1.075,1.707
	c-0.35,0.734-0.683,1.468-0.99,2.219c0.495,0.068,1.493,0.205,1.988,0.273c0.136,0.589,0.393,1.775,0.521,2.372
	c-0.768,0.905-1.519,1.801-2.261,2.714c0.256,0.435,0.768,1.323,1.024,1.766c-0.06,0.546-0.171,1.638-0.222,2.185
	c0.905-0.23,1.826-0.444,2.773-0.649c-1.664,2.039-3.337,4.07-4.855,6.212c1.348-0.606,2.696-1.229,4.07-1.852
	c-2.67,4.959-0.034,0.7-4.335,7.27c2.611-0.41,4.122-2.85,6.596-3.635c-0.478,1.229-4.309,3.012-1.032,3.729
	c-0.836,0.623-1.621,1.263-2.406,1.912c0.333,0.904,0.683,1.818,1.041,2.739c0.785-0.256,1.613-0.495,2.432-0.725
	c-1.485,1.544-2.893,3.166-4.019,4.992c1.698-0.759,3.388-1.536,5.129-2.244c-0.845,1.195-1.656,2.406-2.517,3.576
	c-0.333,1.28-0.649,2.569-0.956,3.857c1.348-0.077,2.705-0.145,4.105-0.196c-0.273,0.614-0.819,1.835-1.084,2.449
	c0.7-0.188,2.091-0.572,2.79-0.759c-0.606,1.024-1.203,2.065-1.775,3.106c-2.057-0.819-5.393-0.051-3.849,2.739
	c-0.239,0.64-0.452,1.28-0.648,1.937c1.254-0.247,2.193,0.06,2.833,0.93c-0.401,0.418-1.212,1.263-1.613,1.681
	c-0.017,0.495-0.06,1.476-0.077,1.971c1.246-0.717,2.458-1.519,3.499-2.534c1.178-0.546,2.406-0.998,3.686-1.374
	c-1.254,2.099-3.447,3.217-5.47,4.403c0.017,0.196,0.06,0.597,0.085,0.802c1.015,0.299,2.082,0.469,3.208,0.503
	c-0.367,0.623-0.708,1.254-1.024,1.894c1.229,0.725,2.5,1.468,3.755,2.202c-0.947,0.657-1.818,1.365-2.705,2.065
	c-2.116-1.058-3.652,0.009-4.71,1.869c1.271,0.06,2.569,0.128,3.891,0.196c0,1.621,0.017,3.26,0.077,4.898
	c2.748-0.196,5.487-0.555,8.252-0.845c-1.109,1.468-2.33,2.867-3.285,4.429c0.41,0.7,0.87,1.391,1.374,2.082
	c-0.188,1.186-0.41,2.338-0.503,3.55c1.169-0.802,2.099-1.877,2.97-3.004c-0.205-1.229-0.375-2.449-0.512-3.661
	c0.461,0.307,1.382,0.922,1.835,1.237c-0.316,0.768-0.623,1.545-0.913,2.33c0.734,0.649,1.485,1.306,2.27,1.963
	c-0.734,3.473-1.152,7.305,0.862,10.462c-0.401,0.981-0.794,1.98-1.126,2.995c1.783,1.587,3.14,3.567,4.275,5.675
	c1.067,0.213,2.159,0.444,3.268,0.683c-0.154-0.742-0.469-2.227-0.623-2.97c1.067-0.239,2.176-0.521,3.311-0.649
	c1.084,1.152,2.057,2.406,3.098,3.627c-0.043-1.28-0.06-2.543-0.068-3.806c1.075,0.307,2.176,0.623,3.302,0.93
	c0.068-0.614,0.196-1.852,0.256-2.466c-1.928-1.135-3.806-2.355-5.53-3.746c2.014,0.171,4.053,0.597,6.059,1.075
	c-0.06-0.316-0.179-0.939-0.239-1.254c0.947-1.528,0.06-2.355-1.408-2.756c0.495-0.154,1.502-0.461,2.005-0.614
	c-0.12-1.161-0.213-2.304-0.29-3.439c0.905,0.478,1.835,0.956,2.782,1.434c-0.188-0.913-0.358-1.826-0.512-2.722
	c0.461-0.077,1.374-0.239,1.835-0.316c0.358-0.742,0.734-1.468,1.135-2.176c-0.358-1.408-0.7-2.833-0.93-4.241
	c0.358,0.555,1.075,1.672,1.425,2.227c0.393,0.068,1.186,0.205,1.579,0.273c0.862,0.734,1.775,1.451,2.714,2.167
	c0.742,1.186,1.493,2.372,2.261,3.575c0.631,0.068,1.886,0.205,2.517,0.273c-1.348,1.442-2.79,2.79-4.463,3.831
	c0.282,1.203,0.589,2.415,0.922,3.644c-0.384,1.399-0.734,2.807-1.041,4.241c0.486,0.009,1.468,0.026,1.963,0.026
	c0-1.075,0.06-2.125,0.094-3.183c0.768-0.35,1.57-0.683,2.389-1.007c-0.205-0.393-0.597-1.161-0.794-1.553
	c0.7-0.811,1.451-1.604,2.202-2.389c0.12,0.503,0.35,1.527,0.461,2.031c0.572-0.034,1.715-0.111,2.287-0.145
	c-0.162,2.057,0.017,4.113,0.307,6.161c0.683,0.35,1.382,0.717,2.099,1.084c0.589-0.896,1.229-1.775,1.86-2.654
	c1.178,0.341,2.381,0.683,3.609,1.015c0.017-0.64,0.043-1.929,0.06-2.569c-1.288-0.188-2.551-0.367-3.797-0.538
	c0.623-0.589,1.263-1.161,1.92-1.724c0.034-0.529,0.102-1.596,0.136-2.133c-0.478-0.009-1.417-0.017-1.894-0.026
	c1.485-1.118,1.98-2.722,1.766-4.523c-1.647-1.331-3.14-2.816-4.523-4.378c2.27,0.196,5.043,3.524,6.613,0.401
	c-0.879-1.118-3.208-1.536-2.799-3.234c0.956-0.922,2.074-1.664,3.183-2.424c-0.555-0.589-1.084-1.161-1.579-1.724
	c1.041-0.879,2.108-1.741,3.157-2.645c-0.708-0.094-2.116-0.29-2.816-0.384c-0.708-0.683-1.382-1.34-2.039-1.988
	c2.679-0.674,2.697-3.584,3.448-5.743c-0.248-0.384-0.742-1.161-0.99-1.545c0.614-0.077,1.826-0.239,2.44-0.316
	c-0.495-1.016-0.973-2.031-1.416-3.021c0.896-1.852,1.374-3.849,1.331-5.905c1.877,3.046,2.099,6.733,2.577,10.206
	c-0.486,1.391-1.237,2.671-1.741,4.053c1.101-0.785,2.099-1.69,2.995-2.731c0.026,1.988-0.12,3.985-0.555,5.931
	c0.094,0.265,0.29,0.785,0.384,1.041c-0.657,0.742-1.289,1.485-1.903,2.244c0.845,0.768,1.758,1.485,2.765,2.091
	c-0.418,1.459-0.76,2.927-1.024,4.42c1.186-0.648,2.389-1.314,3.584-1.997c-0.802,1.459-0.469,3.021-0.009,4.531
	c-0.76,0.742-1.502,1.493-2.202,2.27c0.87,0.077,2.62,0.23,3.499,0.307c-0.597,1.715-1.263,3.405-1.98,5.069
	c1.818,0.99,2.825,2.645,2.995,4.719c0.188,0.265,0.563,0.777,0.751,1.033c-0.145,2.304,1.135,4.156,2.927,5.504
	c-0.162-1.544-0.316-3.072-0.418-4.599c1.997,1.894,2.731,4.634,4.335,6.835c0.392,1.195,0.802,2.406,1.237,3.627
	c-0.776,0.887-1.553,1.783-2.295,2.688c0.29,0.93,0.563,1.86,0.896,2.816c0.512,0.213,1.536,0.657,2.048,0.879
	c-0.154,1.118-0.444,2.227-0.64,3.362c1.246,0.418,2.5,0.862,3.78,1.331c0.777-1.075,1.544-2.159,2.372-3.2
	c0.111,1.246,0.23,2.534,0.281,3.814c0.359,0.188,1.067,0.58,1.425,0.768c-1.801,1.869-2.56,4.361-2.978,6.869
	c0.563,0.102,1.681,0.307,2.236,0.41c0.768,0.811,1.579,1.638,2.398,2.458c0.401-0.299,1.203-0.896,1.604-1.195
	c0.435,0.324,1.306,0.973,1.741,1.297c-0.615,0.802-1.212,1.63-1.775,2.458c0.708,0.213,2.125,0.623,2.833,0.828
	c-0.759,1.161-1.51,2.33-2.227,3.507c0.606,0.461,1.229,0.93,1.877,1.417c0.401-0.819,0.819-1.621,1.254-2.415
	c0.623-0.017,1.869-0.043,2.492-0.06c-0.725-0.708-1.408-1.399-2.082-2.082c0.93-0.631,1.929-1.229,2.893-1.843
	c-0.64-0.094-1.929-0.273-2.569-0.367c0,0,1.562-14.607,1.562-14.609c-0.444-0.094-1.331-0.282-1.775-0.375
	c0.64,0.026,1.92,0.068,2.56,0.094c-0.742-1.988-1.468-4.446,0.521-5.956c0.119,1.562,0.213,3.149,0.299,4.745
	c0.128,0.265,0.392,0.785,0.529,1.05c-0.214,2.3,0.05,4.223,0.341,6.426c1.476,0.418,2.961,0.836,4.471,1.246
	c-0.222-0.444-0.674-1.331-0.896-1.775c0.196-1.058,0.392-2.099,0.521-3.157c0.64-1.425,1.672-2.953,0.461-4.395
	c0.504-1.212,1.041-2.406,1.621-3.584c-0.614-0.418-1.212-0.819-1.775-1.212c1.331-2.253,2.603-4.685,2.193-7.39
	c-0.367-0.077-1.109-0.247-1.476-0.324c0.631-1.05,1.323-2.065,1.988-3.089c0.452,0.657,0.93,1.331,1.425,2.022
	c-0.614,0.751-1.212,1.51-1.775,2.287c0.41,0.452,1.246,1.348,1.656,1.8c0.333,1.472,1.279,4.023,0.469,8.269
	c0.375,0.265,1.118,0.802,1.493,1.075c-0.273,0.392-0.819,1.169-1.084,1.562c0.538,1.024,1.109,2.057,1.698,3.098
	c-0.76,0.691-1.493,1.4-2.21,2.116c1.05,1.007,2.116,2.031,3.234,3.012c-0.171,1.886,0.734,4.608-1.869,5.094
	c0.853,0.717,1.698,1.442,2.586,2.193c0.529-0.299,1.579-0.888,2.099-1.186c-0.956,1.374-1.92,2.748-2.859,4.139
	c1.22-0.811,2.424-1.638,3.652-2.458c-0.802,1.69-1.604,3.388-2.287,5.129c1.135-0.265,2.287-0.503,3.473-0.717
	c-0.981,1.57-2.867,1.86-4.437,2.432c1.246,0.819,2.551,1.587,3.891,2.313c-1.075,0.691-2.116,1.417-3.106,2.202
	c2.449,0.051,4.659-1.016,6.818-2.065c-0.973,2.082-2.415,3.874-4.395,5.052c0.444,0.41,0.922,0.828,1.425,1.263
	c-0.205,0.435-0.606,1.314-0.802,1.749c1.271,0.324,2.551,0.623,3.865,0.93c0.529-1.05,1.118-2.065,1.715-3.081
	c-0.546-1.323-1.092-2.645-1.579-3.959c1.169,0.41,2.355,0.828,3.558,1.254c-0.913-1.8-1.818-3.584-2.671-5.393
	c0.717-0.034,2.159-0.111,2.884-0.145c-0.418-2.005-0.751-4.028-1.007-6.033c0.947,0.41,1.929,0.819,2.927,1.229
	c-0.367-0.99-0.725-1.971-1.067-2.935c0.375,0.06,1.126,0.171,1.502,0.23c-0.392-1.638-0.691-3.294-0.93-4.932
	c-0.606-0.734-1.195-1.459-1.749-2.176c1.084,0.358,2.193,0.734,3.32,1.109c-0.427-1.203-0.853-2.381-1.271-3.55
	c0.469-0.008,1.408-0.034,1.877-0.051c-0.222-0.478-0.666-1.451-0.879-1.929c0.469-1.698,0.956-3.379,1.519-5.035
	c-0.998-1.169-1.988-2.338-2.918-3.516c1.527,0.529,3.063,1.135,4.591,1.775c-0.99-3.354,0.248-6.835-0.555-10.214
	c0.486-0.478,0.998-0.956,1.536-1.408c-0.239-0.401-0.717-1.203-0.956-1.604c0.418-1.289,0.811-2.586,1.254-3.866
	c0.358,1.186,0.734,2.389,1.126,3.601c-0.947,2.108-0.026,4.275,0.529,6.349c-0.077,2.56-1.408,5.811,0.734,7.842
	c0.845-1.536,1.86-2.97,2.987-4.318c0.128-2.142,0.179-4.42,1.749-6.076c0.329-7.196,0.707-4.305,1.067-9.839
	c0.375,0.009,1.126,0.034,1.502,0.051c1.489,8.14-1.501-4.495,3.857,18.022c1.929,0.572,5.316,3.362,6.118,0.094
	c-0.845,0.009-2.534,0.017-3.379,0.017c1.118-0.853,2.27-1.673,3.413-2.534c-1.314-0.068-2.594-0.111-3.866-0.145
	c0.41-1.024,0.819-2.048,1.271-3.046c-0.58-0.375-1.749-1.109-2.33-1.476c0.392-0.41,0.819-0.802,1.271-1.186
	c1.152,0.555,2.338,0.145,3.499-0.222c-1.28-1.271-2.62-2.466-3.686-3.891c-0.145-1.382,1.741-1.135,2.56-1.715
	c-0.102-1.954-0.213-3.9-0.435-5.828c-1.4-1.186-2.227-2.825-1.997-4.668c-0.239-0.486-0.725-1.442-0.964-1.928
	c1.818,1.425,3.797,2.807,6.238,2.842c-0.734-0.759-1.425-1.493-2.116-2.219c1.348-1.152,0.273-2.637-0.009-3.951
	c1.058,0.367,2.133,0.725,3.243,1.084c-0.597-0.853-1.169-1.69-1.732-2.509c0.162-2.133,0.162-4.284-0.179-6.391
	c0.145-0.307,0.427-0.922,0.572-1.229c-0.282-2.62-0.085-5.231,0.196-7.825c0.384-0.691,0.794-1.382,1.22-2.057
	c-0.375,4.693,2.039,9.207,5.965,11.742c-0.41-2.398-0.845-4.796-1.237-7.177c0.956,0.64,1.929,1.289,2.927,1.954
	c-1.297-1.929-1.946-4.164-1.766-6.477c-0.751-1.476-1.493-2.944-2.184-4.42c0.904,0.913,1.783,1.852,2.696,2.807
	c1.417,0.06,2.85,0.119,4.309,0.162c-0.623-0.606-1.22-1.203-1.792-1.783c0.742-1.903-0.145-3.695-0.922-5.393
	c1.28-0.333,2.568-0.631,3.891-0.913c0.358-0.785,0.742-1.562,1.144-2.321c0.905,0.418,1.826,0.845,2.773,1.272
	c-0.546,2.031-0.768,4.42-2.773,5.598c0.956,0.964,1.792,2.031,2.517,3.226c0.555-0.009,1.655-0.017,2.202-0.026
	c-0.708-1.007-1.57-1.92-2.415-2.756c0.102-0.418,0.316-1.237,0.418-1.655c0.649-0.162,1.946-0.495,2.594-0.666
	c-0.068,1.579-0.102,3.166-0.094,4.762c1.621-0.316,2.68-1.348,3.226-2.944c0.205,1.459,0.384,2.918,0.58,4.403
	c-0.64,0.819-1.254,1.655-1.843,2.5c0.572,0.171,1.698,0.504,2.27,0.666c-0.751,0.905-1.493,1.809-2.184,2.748
	c1.527-0.265,3.106-0.521,4.659-0.819c0.051,1.203,0.145,2.432,0.247,3.661c0.452,0.256,1.365,0.768,1.818,1.024
	c-0.563,1.178-1.075,2.381-1.57,3.593c-1.331,1.152-2.645,2.347-3.567,3.857c3.106-0.384,4.489-3.524,6.955-5.052
	c-0.802,1.621-2.005,2.961-3.243,4.241c0.7-0.068,2.099-0.196,2.799-0.265c-0.743,1.058-1.493,2.116-2.21,3.183
	c-1.382,0.99-2.398,2.347-3.268,3.78c0.99-0.358,2.005-0.717,3.038-1.075c-0.307,1.058-0.58,2.125-0.828,3.191
	c2.876-2.185,5.018-5.282,8.363-6.869c-1.186,1.826-2.347,3.678-3.098,5.726c0.35,2.816-2.415,5.009-1.544,7.816
	c-0.265,0.691-0.504,1.391-0.717,2.108c-0.58,0.034-1.758,0.111-2.347,0.154c0,0.384-0.009,1.152-0.009,1.536
	c0.529,0.009,1.596,0.017,2.125,0.017c-2.133,2.082-3.994,4.471-5.077,7.27c4.106-1.395,11.002-11.708,13.304-13.662
	c-0.563,1.638-1.152,3.268-1.766,4.89c0.162,0.265,0.486,0.785,0.649,1.041c-1.032,1.579-1.98,3.2-2.807,4.89
	c1.109-0.324,2.236-0.648,3.379-0.964c-2.073,2.884-4.096,6.101-3.78,9.83c1.143-1.553,1.963-3.302,2.944-4.949
	c1.877,0.87,1.263,2.722-0.495,3.362c0.742,0.444,1.51,0.896,2.296,1.365c1.647-2.748,3.149-5.743,5.922-7.552
	c-0.452,2.509-0.999,5-1.493,7.501c0.905-0.64,1.852-1.254,2.807-1.877c0.981,0.273,1.997,0.538,3.029,0.802
	c-1.212-1.357-2.628-2.483-4.07-3.541c1.288-0.87,2.372-2.048,2.739-3.601c-0.486-0.282-1.442-0.836-1.929-1.109
	c2.022-1.672,4.343-2.918,6.75-3.977c-0.136-0.239-0.41-0.734-0.555-0.981c0.666-0.734,1.34-1.459,2.022-2.185
	c-1.681,0.367-3.345,0.76-4.992,1.109c0.213-2.62,1.536-4.855,3.925-6.033c-1.126-1.075-2.227-2.159-3.251-3.294
	c0.615-0.017,1.852-0.06,2.466-0.077c-0.196-0.35-0.589-1.05-0.785-1.399c0.521-0.956,1.246-1.835,1.493-2.91
	c-0.939-0.324-1.878-0.563-2.807-0.794c0.205-0.35,0.623-1.067,0.836-1.417c-0.034-0.546-0.102-1.647-0.137-2.193
	c1.365-0.026,3.49,1.783,4.275-0.162c-1.468-0.99-3.038-1.775-4.557-2.611c0.563-0.247,1.69-0.751,2.253-0.998
	c-0.256-1.041-0.614-2.039-0.887-3.038c0.649-0.009,1.954-0.017,2.603-0.017c-1.433-3.354,1.229-7.373-1.672-10.138
	c1.195-1.809,1.638-3.9,1.818-6.025c0.734,0.375,1.519,0.819,2.296,1.254c0.051-0.529,0.162-1.596,0.222-2.125
	c-0.461,0.094-1.391,0.282-1.852,0.375c0.341-1.877,0.725-3.746,1.101-5.615c0.538,0.008,1.604,0.034,2.142,0.043
	c0.273-0.922,0.572-1.843,0.896-2.748c-0.102-0.299-0.29-0.905-0.384-1.203c0.58-1.391-0.077-4.267,2.167-3.917
	c0.281,4.344-0.452,8.764,0.717,13.039c-0.29,1.314-0.683,2.645-0.58,4.011c0.725,1.084,1.911,1.143,3.012,0.563
	c-0.794-5.581-0.29-11.238-0.435-16.845c0.828,0.444,1.672,0.896,2.543,1.365c2.27-1.741,5.171-1.459,7.817-2.091
	c-0.853,1.544-1.656,3.123-2.398,4.727c1.109-0.384,2.236-0.768,3.379-1.169c0.623,0.017,1.86,0.043,2.483,0.06
	c-0.247-0.35-0.742-1.033-0.998-1.382c0.751-0.93,1.502-1.852,2.287-2.765c-0.853,0.179-1.69,0.35-2.492,0.495
	c-1.937-2.125-4.002-4.181-6.665-5.333c0.026-0.435,0.068-1.306,0.085-1.741c1.05-0.546,2.125-1.084,3.225-1.613
	c0.043-0.529,0.128-1.596,0.179-2.133c-0.999,0.358-1.98,0.717-2.944,1.084c0.717-1.024,1.476-2.022,2.244-3.012
	c-1.613-0.401-3.26-0.649-4.77-1.246c-0.418-0.879-0.691-1.792-0.811-2.739c-0.87,0.333-1.724,0.666-2.552,1.007
	c0.256-0.393,0.759-1.186,1.016-1.587c-0.358-0.265-1.067-0.802-1.417-1.067c1.101-0.964,2.278-1.886,3.268-2.995
	c-0.555-0.265-1.656-0.794-2.202-1.058c-0.265-0.367-0.793-1.092-1.058-1.459c-0.06-1.016-0.145-2.031-0.171-3.029
	c0.597-0.597,1.22-1.195,1.869-1.775c0.666,0.29,1.357,0.589,2.074,0.896c0.085-0.666,0.265-1.997,0.358-2.662
	c-2.807-1.271-1.715-4.343-1.724-6.75c0.529-0.452,1.075-0.887,1.664-1.314c1.417-0.333,3.14-0.307,4.096-1.638
	c0.128-1.084-0.418-2.091-0.623-3.106c1.058-1.826,0.64-3.524-0.981-4.821c-0.512-2.603-0.23-5.641,2.509-6.835
	c-0.06-1.22-0.094-2.432-0.102-3.627c-0.23-0.265-0.7-0.802-0.93-1.067c-0.043-1.169-0.392-2.261-1.143-3.14
	c0.427-0.239,1.297-0.7,1.724-0.939c-0.094-1.033-0.136-2.065-0.119-3.081c0.913,0.222,1.818,0.478,2.773,0.742l-0.631-1.928
	c1.058,0.265,2.142,0.529,3.243,0.785c-1.109-2.688-3.806-3.84-6.067-5.316c1.109,0.521,2.227,1.067,3.362,1.63
	c0.017-1.69,0.102-3.354,0.222-5.018c0.41,0.316,1.22,0.956,1.621,1.271c1.621-0.196,4.096,1.399,5.12-0.393
	c-0.708-2.125-1.587-4.463-3.755-5.461c-0.964-2.057-0.904-4.156,0.478-6.007c-0.998-0.896-1.852-1.843-2.696-2.816
	c0.469-0.469,1.408-1.408,1.877-1.886c0.358,1.34,0.648,2.662,0.998,4.028c0.76,0.154,1.545,0.316,2.355,0.486
	c0.58,1.408,1.195,2.816,1.826,4.241c0.444-0.265,1.331-0.802,1.766-1.067c0.111,1.178,0.282,2.355,0.495,3.541
	c1.758-0.956,1.775-3.183,2.33-4.864c0.299,0.111,0.905,0.324,1.203,0.435c-0.051-0.811-0.085-1.621-0.077-2.415
	c-0.93,0.008-1.834,0.026-2.714,0.051c0.154,0.503,0.461,1.502,0.614,2.005c-1.118,0.034-2.227,0.077-3.302,0.128
	c-0.179-2.278-0.435-4.531-0.751-6.775c-0.777-0.094-2.321-0.265-3.089-0.358c0.657-0.666,1.169-1.562,2.125-1.886
	c0.819,0.324,1.596,0.87,2.389,1.365c-0.273,0.563-0.819,1.681-1.092,2.244c1.22,0.589,2.449,1.178,3.721,1.775
	c-0.145-0.444-0.435-1.331-0.572-1.775c0.469-0.606,0.973-1.212,1.493-1.809c-0.828-2.389-1.382-4.847-1.886-7.305
	c1.314-0.085,2.662-0.171,4.028-0.247c0.085-1.143,0.23-2.27,0.435-3.388c1.604,0.043,3.2,0.282,4.83,0.452
	c-0.35-0.879-0.674-1.758-0.964-2.62c-0.845,0.41-1.672,0.836-2.466,1.263c0.469-1.809,0.896-3.635,1.263-5.47
	c0.529-0.538,1.084-1.067,1.664-1.579c0.743,0.333,1.51,0.674,2.304,1.024c0.043,1.084,0.102,2.176,0.188,3.277
	c1.033-0.879,2.065-1.749,3.123-2.62c0.384,1.732-0.649,4.693,1.937,4.872c1.527-1.34,3.294-2.756,3.994-4.736
	c-0.299-1.229-0.896-2.381-1.203-3.567c1.229-0.137,2.509-0.188,3.814-0.247c0.043-0.444,0.119-1.34,0.162-1.792
	c-1.323-0.384-2.603-0.777-3.883-1.178c-0.171,0.546-0.503,1.638-0.674,2.185c-0.631-1.306-0.99-2.679-1.527-3.994
	c1.98,1.741,2.893-0.58,3.883-2.014c-0.512-0.017-1.536-0.051-2.048-0.068c0.35-0.136,1.067-0.41,1.416-0.546
	c-0.657-0.956-1.288-1.911-1.886-2.876c0.486,0.401,1.468,1.195,1.954,1.587c0.188-0.333,0.572-1.016,0.759-1.348
	c-0.145-1.246-0.341-2.5-0.418-3.729c0.819,1.178,1.604,2.364,2.441,3.558c-0.469,0.794-0.905,1.596-1.323,2.406
	c1.058,1.476,2.304,2.748,4.062,1.314c-0.239,0.742-0.452,1.493-0.631,2.261c0.358,0.751,0.742,1.502,1.152,2.27
	c-0.162,0.666-0.487,1.988-0.649,2.654l2.193-0.486c-0.136,0.503-0.401,1.51-0.537,2.014c1.254,0.35,2.415,0.947,3.021,2.21
	c0.973-0.623,1.946-1.254,2.961-1.86c-1.126,3.533-0.793,7.245-0.58,10.888c1.135-1.826,2.295-3.644,3.644-5.333
	c0.128,0.785,0.393,2.347,0.521,3.123c-2.21,2.355-0.99,5.325,0.043,7.91c0.828-0.392,1.672-0.785,2.543-1.161
	c0.811,1.971,0.128,3.789-1.169,5.35c0.273,0.41,0.828,1.229,1.101,1.638c-0.649,0.845-1.271,1.698-1.869,2.56
	c0.597,1.152,1.212,2.313,1.843,3.482c-0.691,1.664-1.348,3.336-1.98,5.018c0.836,0.862,1.681,1.732,2.552,2.62
	c1.177-0.913,2.389-1.801,3.635-2.662c-0.341,0.956-0.666,1.911-0.973,2.876c0.546,0.171,1.639,0.512,2.176,0.683
	c-0.469,1.382-0.905,2.773-1.34,4.164c1.178-0.683,2.364-1.357,3.593-2.005c0.017,0.785,0.06,2.355,0.085,3.14
	c0.486-0.461,1.459-1.365,1.954-1.818c0.375,0.683,0.776,1.382,1.203,2.091c-0.649,0.597-1.263,1.203-1.86,1.818
	c0.009,0.742,0.026,2.219,0.026,2.961c0.307,0.427,0.913,1.271,1.22,1.698c-0.742,1.126-1.468,2.261-2.176,3.405
	c0.461,0.358,1.382,1.092,1.843,1.459c-0.427,0.444-1.271,1.34-1.69,1.792c0.555,0.597,1.143,1.186,1.775,1.775
	c1.084-0.742,1.672-3.123,3.311-2.261c-0.316,2.27-1.263,4.557-0.026,6.758c-0.077,0.375-0.247,1.118-0.324,1.493
	c0.12,0.401,0.359,1.195,0.478,1.596c-0.307,0.691-0.58,1.391-0.828,2.108c1.306,0.742,2.654,1.519,3.976,2.313
	c0.401,1.22,0.836,2.441,1.314,3.661c-0.29,0.76-0.563,1.519-0.811,2.295c1.382,1.067,2.833,2.057,4.369,2.953
	c-0.009-1.331,0-2.645,0.025-3.959c1.263-0.128,2.552-0.239,3.857-0.307c0.359-0.341,0.751-0.674,1.186-0.981
	c1.195,0.597,2.492,0.418,3.763,0.034c1.092,0.196,2.091,0.35,3.251,0.555c-1.707-2.739-4.796-4.139-7.014-6.391
	c4.233-1.843,2.287-6.75,3.379-10.223c0.922,3.038,1.98,6.05,3.516,8.849c1.203-0.563,2.458-1.109,3.806-1.314
	c-0.393,1.254-0.777,2.526-1.143,3.797c0.555,0.043,1.672,0.128,2.236,0.171c-0.393,1.067-0.76,2.133-1.092,3.217
	c1.468,0.956,2.927,1.963,4.284,3.123c0.418,0.179,1.254,0.529,1.672,0.708c0.068,0.563,0.196,1.707,0.256,2.278
	c0.606,0,1.826-0.009,2.432-0.009c-0.35-1.963-0.759-3.9-1.34-5.794c0.154-0.196,0.469-0.589,0.623-0.785
	c0.529,1.271,1.033,2.526,1.613,3.814c2.806-1.94,2.302-1.58,4.002-2.185c0.009,0.265,0.017,0.785,0.026,1.05
	c-0.452-0.009-1.357-0.017-1.809-0.026c0.486,1.681,0.973,3.371,1.425,5.077c-0.708-0.06-2.142-0.188-2.85-0.247
	c0.06,0.734,0.196,2.21,0.265,2.953c1.092-0.503,2.031-1.229,2.782-2.202c0.367,0.512,0.768,1.05,1.195,1.587
	c2.031-0.333,4.079-0.58,6.152-0.819c-0.751-2.159-1.468-4.335-2.099-6.519c-0.427,0.307-1.28,0.905-1.698,1.203
	c1.161-1.237,2.364-2.449,3.627-3.618c-0.188-1.758-0.384-3.516-0.555-5.257c1.118,0.742,2.253,1.502,3.413,2.253
	c0.068-0.836,0.205-2.526,0.273-3.362c0.188,1.058,0.418,2.142,0.64,3.217c1.596,0.145,3.302,0.299,3.755,2.227
	c0.649-0.555,1.365-1.092,2.074-1.638c0.512,1.314,0.717,2.859,1.715,3.951c1.468,0.247,2.645-0.828,3.823-1.553
	c-0.964,1.357-1.852,2.765-2.671,4.215c0.793-0.299,1.613-0.58,2.458-0.862c1.92,1.442,3.464,3.285,4.079,5.675
	c0.452-0.136,1.357-0.41,1.809-0.546c-0.392,1.562-0.785,3.132-1.195,4.693c0.999-0.043,2.031-0.077,3.072-0.094
	c1.05-1.314,2.159-2.594,3.319-3.84c-0.879-2.039-1.621-4.122-2.261-6.229c1.869,1.289,3.686,2.697,5.282,4.352
	c0.213-0.341,0.64-1.015,0.853-1.348c-0.392-0.324-1.177-0.964-1.57-1.28c-0.068-0.981-0.111-1.963-0.128-2.927
	c-0.427-0.333-1.297-1.007-1.732-1.34c-0.026-1.357-0.043-2.705-0.017-4.045c0.879,0.375,1.784,0.742,2.714,1.118
	c-0.546-2.116-1.041-4.241-1.51-6.366c0.853,0.802,1.647,1.579,2.569,2.389c1.8-1.971,0.742-4.079-0.7-5.828
	c1.493,0.896,2.97,0.691,4.181-0.555c-0.768-0.802-1.459-1.579-2.176-2.364c1.254,0.324,2.483,0.631,3.797,0.964
	c0.819-2.722-0.333-5.333-1.707-7.637c1.57-0.461,2.799-1.493,3.712-2.842c0.785,0.256,1.596,0.529,2.441,0.802
	c-1.476-2.441-2.56-5.103-2.901-7.936c0.862,1.749,1.536,3.601,2.449,5.342c1.954,1.664,2.714-1.195,3.575-2.475
	c0.589,1.997,0.614,4.062-0.171,6.025c-0.162,1.459-0.41,2.918-0.819,4.344c0.469,0.742,0.973,1.51,1.545,2.253
	c0.717-0.717,1.442-1.442,2.168-2.176c-0.248,0.828-0.751,2.483-0.999,3.311c0.589-0.111,1.775-0.324,2.364-0.435
	c-0.427,1.946-0.648,3.934-0.7,5.931c-0.99,1.451-1.783,3.021-2.091,4.762c1.553-1.263,3.012-2.654,4.48-4.045
	c-0.06,0.683-0.179,2.039-0.239,2.722c0.862-0.879,1.792-1.724,2.714-2.577c0.119,1.715,0.29,3.439,0.512,5.163
	c-0.495,0.606-0.964,1.22-1.408,1.843c0.862,1.417,1.741,2.842,2.654,4.258c-0.469,0-1.399,0.009-1.869,0.017
	c-0.017,1.519-0.179,3.046-0.444,4.557c0.7-0.034,2.108-0.102,2.816-0.137c-0.947,0.828-1.877,1.647-2.79,2.475
	c0.563,0.529,1.161,1.084,1.784,1.638c0.179-0.597,0.529-1.784,0.708-2.372c0.76-0.418,1.536-0.819,2.338-1.212
	c0.017-1.015,0.06-2.031,0.128-3.029c0.981-0.87,1.937-1.749,2.978-2.594c-0.282,3.541-0.478,7.245-2.458,10.342
	c-0.128,0.802-0.29,1.596-0.367,2.415c0.597-0.077,1.809-0.23,2.406-0.316c-0.836,1.152-1.724,2.295-2.432,3.524
	c2.995-0.333,4.267-3.362,6.152-5.274c-0.034,2.953,0.239,5.905,0.606,8.841c5.157,1.945,4.583,2.667,10.027,3.584
	c0.811,0.503,1.673,1.024,2.543,1.545c-1.306,0.094-2.611,0.196-3.883,0.316c-1.92-0.384-3.874-0.435-5.786-0.282
	c-0.691-1.946-2.304-3.908-4.583-3.567c-1.869,1.041-0.555,3.166,0.623,4.173c-1.852,3.328-3.183,6.895-4.19,10.564
	c1.092-0.299,2.244-0.58,3.396-0.87c0.239,0.358,0.725,1.092,0.973,1.459c-0.504,1.246-1.016,2.492-1.451,3.755
	c0.828-0.154,2.475-0.478,3.303-0.64c-0.171,2.304-0.23,4.617-0.154,6.938c4.19-3.771,3.209-2.954,6.869-6.963
	c-0.828,3.371-2.031,6.707-1.801,10.24c0.802-0.964,1.655-1.92,2.458-2.893c-0.7,1.741-2.21,3.584-1.399,5.538
	c2.082,0.862,3.959-0.572,5.76-1.476c1.383,0.904,2.791,1.783,4.241,2.654c0.384-0.239,1.161-0.717,1.545-0.956
	c-0.145-0.802-0.213-1.57-0.299-2.355c-1.57-0.307-3.097-0.64-4.616-0.99c-0.017-0.529-0.068-1.57-0.094-2.091
	c-0.998-0.631-1.911-1.237-2.841-1.877c1.246-0.145,2.526-0.282,3.823-0.401c0.23-0.35,0.691-1.041,0.922-1.382
	c-0.666-0.137-1.997-0.393-2.662-0.521c-0.393-1.749-0.751-3.507-1.041-5.256c1.826,0.887,3.43,2.167,5.248,3.132
	c2.671,0.119,3.465-3.157,4.651-4.992c-2.517-0.085-5.034-0.205-7.518-0.341c0.657-0.683,1.34-1.357,2.048-2.014
	c2.039,0.845,3.934-0.273,5.794-1.05c-0.026-0.393-0.085-1.178-0.111-1.579c-3.157,2.321-7.33,0.623-10.385,3.012
	c-1.485-0.41-2.935-0.862-4.335-1.365c2.628-0.299,5.47-0.358,7.62-2.159c-1.587-0.734-3.072-1.562-4.668-2.185
	c-1.775,0.017-3.516,0.461-5.231,0.725c0.282-0.956,0.589-1.903,0.922-2.833c0.512,0.367,1.536,1.092,2.057,1.451
	c1.801-0.41,3.661-0.794,5.419-1.485c2.073,0.452,3.644-1.033,5.487-1.69c1.527,0.111,3.046,0.58,4.548,1.024
	c4.286,5.991,4.621,0.266,5.034-0.734c0.717-0.102,2.151-0.307,2.859-0.41c-2.859-2.611-6.792,0.802-9.762-1.792
	c1.485-0.563,3.038-1.084,4.591-1.579c-0.99-0.751-1.937-1.519-2.842-2.313c-0.35,0.188-1.05,0.555-1.399,0.742
	c-1.254-0.495-2.509-0.99-3.712-1.468c0.828-0.87,1.724-1.707,2.603-2.543c-3.336,0.247-6.494-1.331-8.218-4.19
	c3.814,0.324,7.416,1.732,10.923,3.217c-0.836,0.7-1.656,1.408-2.44,2.125c0.554,0.068,1.672,0.188,2.227,0.256
	c1.212-1.058,2.518-2.005,3.823-2.97c0.427,0.128,0.896,0.265,1.399,0.418c0.871-0.742,1.801-1.451,2.756-2.125
	c1.007-1.417,1.852-2.944,2.56-4.531c-2.372,1.126-4.924,1.792-7.458,2.381c-0.947-0.401-1.86-0.785-2.748-1.169
	c2.509-1.502,6.869-0.589,7.603-4.156c-1.195,0.58-2.355,1.195-3.473,1.843c-0.205-0.555-0.606-1.664-0.811-2.21
	c-1.929,0.555-3.814,1.126-5.692,1.741c0.742-1.092,1.476-2.185,2.219-3.294c-1.374,0.401-2.534,1.152-3.49,2.176
	c-1.997,0.085-3.985,0.094-5.939,0.017c1.442-0.725,2.952-1.34,4.497-1.894c0.35-0.265,1.041-0.794,1.391-1.05
	c0.657-0.119,1.98-0.358,2.645-0.478c-0.068-3.038-4.036-0.896-5.845-0.794c0.725-1.092,1.476-2.167,2.253-3.234
	c1.246,0.333,2.893,1.178,3.763-0.375c-1.655-0.572-3.234-1.109-4.821-1.707c-0.734,0.017-2.201,0.043-2.936,0.051
	c1.186-2.543,4.344-2.193,6.682-2.458c0.64,1.015,1.63,1.715,2.722,2.253c2.611,2.295,5.427,0.051,7.842-1.357
	c-0.828-0.555-1.655-1.092-2.449-1.613c0.751-0.085,2.253-0.256,3.004-0.341c-1.493-2.159-3.678-0.956-5.598-0.572
	c-1.306-2.057-3.652-2.918-5.487-4.335c4.412,0.862,9.054,3.32,13.525,1.323c1.57-0.887,4.855,0.136,5.111-2.236
	c-2.799-0.162-5.956,0.896-8.243-1.212c2.005-0.41,4.642-0.35,5.137-2.884c-1.007,0.265-1.997,0.555-2.97,0.862
	c-1.314-0.828-2.611-1.638-3.874-2.475c0.717-0.051,2.142-0.162,2.859-0.222c-0.154-0.64-0.469-1.937-0.631-2.586
	c0.52-0.119,1.553-0.358,2.065-0.469c0.299-0.512,0.887-1.519,1.186-2.022c0.085,0.845,0.248,2.534,0.333,3.379
	c2.005,0.06,4.087-0.051,5.913-1.067c-2.637-0.649-5.726-1.203-6.792-4.087c-0.392,0.23-1.169,0.691-1.562,0.922
	c-1.041-0.657-2.082-1.314-3.072-1.963c-1.621,0.486-3.226,1.041-4.77,1.664c0.034-0.307,0.094-0.905,0.128-1.203
	c-1.596-0.299-3.149-0.597-4.676-0.981c1.408-1.118,2.987-0.239,4.514,0.06c1.596,0.418,3.277,0.154,4.949,0.102
	c1.101-1.306,2.261-2.551,3.473-3.772c-1.084-0.401-2.142-0.794-3.166-1.178c-0.171-0.521-0.504-1.57-0.666-2.099
	c-1.178-0.111-2.321-0.222-3.448-0.324c2.543-1.015,5.265-2.091,6.622-4.693c-3.763,1.365-7.373,3.78-11.563,3.191
	c1.067-0.358,2.133-0.7,3.26-1.015c0.367-0.247,1.092-0.751,1.459-1.007c1.783-0.478,3.465-1.271,5.06-2.236
	c-0.298-0.333-0.896-0.99-1.195-1.323c-2.697-0.282-5.581-0.794-7.526,1.527c-0.026-0.486-0.085-1.451-0.111-1.937
	c-1.092,0.137-2.167,0.273-3.217,0.418c0.213-0.896,0.452-1.784,0.708-2.662c-1.544-0.162-3.046-0.316-4.548-0.435
	c0.051-3.106-3.012-4.386-5.12-5.922c2.645-0.367,4.13,2.833,6.758,1.758c1.323,0.546,2.662,1.075,4.036,1.613
	c0.341,0.333,1.032,1.007,1.383,1.34c0.281-0.418,0.862-1.246,1.152-1.664c0.333,0.128,1.015,0.375,1.357,0.503
	c0.452-0.282,1.365-0.845,1.826-1.126c1.613,0.828,3.047,0.759,3.712-1.101c-1.203,0.102-2.381,0.213-3.524,0.35
	c-0.529-0.546-1.57-1.638-2.091-2.185c-1.101,0.555-2.133,1.126-3.174,1.707c-0.324-0.512-0.606-1.015-0.862-1.51
	c0.674-0.495,1.374-0.973,2.108-1.459c1.032,0.137,2.031,0.256,3.132,0.41c-0.393-1.357-0.793-2.705-1.135-4.045
	c-0.529,0.128-1.579,0.375-2.108,0.495c-0.802-1.271-1.604-2.534-2.389-3.789c-0.435-0.12-1.297-0.358-1.732-0.478
	c-0.563-1.016-1.109-2.022-1.63-3.021c1.186,0.179,2.398,0.367,3.635,0.555c-0.196-0.538-0.598-1.621-0.794-2.167
	c4.352,0.862,8.79,1.664,12.587,4.113c0.589,0.794,2.296-0.145,1.553-0.939c-1.536-2.014-1.928-4.489-2.014-6.946
	c1.681,1.101,3.371,2.193,5.086,3.285c0.615-0.145,1.843-0.452,2.458-0.597c0.734,0.819,1.51,1.63,2.33,2.432
	c0.572-0.708,1.007-1.493,1.553-2.236c-2.756-0.461-5.683-1.314-6.912-4.088c1.374,0.239,2.799,0.589,4.181,1.007
	c0.401-0.222,1.203-0.674,1.604-0.896c0.853,0.606,1.758,1.22,2.671,1.835c1.34-1.425,2.799-2.825,3.55-4.693
	c-1.263,0.64-2.483,1.331-3.661,2.057c-0.051-1.22-0.068-2.423-0.068-3.627c-1.305,0.529-2.603,1.058-3.857,1.613
	c-0.99-1.323-1.963-2.645-2.927-3.951c-1.169-0.444-2.347-0.913-3.439-1.382c1.869-0.29,3.806-0.503,5.717-0.717
	c0.162,0.435,0.478,1.289,0.64,1.715c0.486,0.196,1.468,0.58,1.954,0.768c1.698-0.162,3.43,0.026,5.112,0.469
	c1.212-1.331,2.449-2.79,0.836-4.386c-1.016,0.845-2.014,1.707-2.978,2.577c-2.227-3.601-5.999-5.385-9.429-7.544
	c1.749,0.068,3.55,0.205,5.35,0.35c0.248,0.384,0.742,1.161,0.998,1.545c0.341-0.145,1.024-0.427,1.365-0.563
	c1.092,0.009,2.201,0.009,3.336,0.009c-0.913-0.836-1.809-1.664-2.671-2.483c-0.418,0.273-1.246,0.811-1.656,1.075
	c-0.316-0.41-0.93-1.246-1.237-1.664c0.538,0.085,1.596,0.247,2.125,0.333c0.12-0.606,0.35-1.818,0.461-2.432
	c1.963-0.734,5.461,0.282,5.999-2.381l-2.134,1.058c-0.529-1.084-0.99-2.167-1.382-3.268c0.708-0.367,1.459-0.7,2.253-1.016
	c0.537,2.091,2.901,2.424,4.634,1.579c1.024-2.449-2.918-2.347-4.275-1.937c0.819-1.135,1.724-2.253,2.56-3.396
	c-0.973-0.077-1.911-0.137-2.824-0.196c0.128,0.632,0.281,1.272,0.469,1.92c-2.347,0.401-4.557,1.272-6.357,2.816
	c-2.142-3.746-5.274-6.844-7.629-10.47c4.73,2.365,1.879,1.323,6.144,2.603c0.324-0.324,0.981-0.973,1.314-1.297
	c0.461-0.068,1.399-0.188,1.869-0.256c0.76-1.075,1.562-2.125,2.381-3.183c-0.76-0.06-2.261-0.179-3.012-0.239
	c0.034-0.461,0.119-1.382,0.162-1.843c2.04,0.631,4.096,2.355,6.332,1.289c-1.723-1.075-3.456-2.065-5.137-3.14
	c1.382-0.154,2.858-0.273,4.292-0.435c0.418,0.222,1.255,0.666,1.681,0.887c0.393-0.188,1.195-0.563,1.596-0.751
	c0.742,0.171,1.502,0.35,2.295,0.538c0.452-1.007,1.084-1.997,1.007-3.149c-1.451,0.256-2.739,0.973-4.002,1.63
	c-1.186-0.794-2.33-1.587-3.439-2.415c1.195,0.111,2.406,0.222,3.644,0.333c-1.638-1.988-4.25-2.543-6.383-3.755
	c1.638-0.572,3.354-0.947,5.103-1.254c-0.401-1.382-0.802-2.748-1.075-4.139c1.348-0.41,2.731-0.657,4.122-1.007
	c-1.382-1.084-3.217-0.964-4.685-1.826c1.391-0.435,2.969-0.623,4.309-1.34c-1.271-1.203-2.705-2.21-4.028-3.294
	c0.742,0.051,2.236,0.171,2.978,0.23c-0.239-0.589-0.708-1.758-0.947-2.347c1.135,0.614,2.304,1.263,3.482,1.92
	c-0.034-2.722-0.222-5.453,0.111-8.158c0.742-0.862,1.946-1.033,2.995-1.459c-0.682,3.9-0.478,7.885-1.015,11.81
	c1.937-0.759,3.874-1.553,5.854-2.261c-0.862,1.033-1.75,2.048-2.586,3.081l1.425-0.282c-0.725,1.809-0.452,3.661,0.316,5.41
	c0.444-0.546,1.323-1.647,1.758-2.193c0.401,0.768,0.828,1.536,1.28,2.313c-0.23,0.333-0.682,0.998-0.904,1.323
	c1.323-0.171,2.713-0.341,4.079-0.495c1.041-0.555,2.082-1.101,3.2-1.587c-0.99,2.415-4.531,3.541-4.011,6.426
	c-1.126,0.077-2.227,0.162-3.302,0.256c0.699,0.768,1.391,1.527,2.167,2.313c-0.828,0.452-1.587,0.922-2.347,1.391
	c-0.41-0.418-1.22-1.237-1.63-1.647c-1.297,0.375-1.877,1.673-2.662,2.62c1.058,0.06,2.142,0.12,3.251,0.179
	c0.93,1.391,2.005,2.688,3.208,3.9c0.282,2.594-2.748,5.043-1.109,7.441c2.389-0.725,3.08-3.541,4.429-5.385
	c0.299,0.341,0.896,1.024,1.186,1.357c1.306-0.597,2.637-1.195,3.994-1.758c-0.427,1.152-0.828,2.313-1.195,3.482
	c-0.555,0.119-1.647,0.35-2.193,0.469c-0.025,0.58-0.085,1.749-0.111,2.33c-1.152-0.418-2.278-0.828-3.379-1.212
	c0.307,0.64,0.922,1.92,1.229,2.56c-1.869,0.7-3.627,1.664-4.753,3.345c2.389-0.674,4.83-1.297,7.356-1.399
	c-1.766,2.603-4.565,3.968-7.697,3.712c-1.024,0.563-1.698,2.176-2.978,1.86c-0.418-0.503-1.254-1.51-1.673-2.014
	c-0.64,0.503-1.271,1.007-1.852,1.527c0.521,0.981,1.058,1.98,1.621,2.978c3.448-1.843,7.339-2.517,11.213-2.825
	c-1.766,2.21-4.71,2.441-7.228,3.089c1.4,2.458,4.326,0.58,6.46,0.435c1.741-0.614,2.27,1.766,3.208,2.748
	c1.545-1.143,3.166-2.202,4.89-3.089c-0.666,1.801-2.278,2.867-3.704,3.994c0.674,0.768,1.408,1.553,2.15,2.338
	c1.869-1.126,3.797-2.168,5.863-2.935c-1.63,2.099-4.122,4.045-3.345,7.023c-0.921,0.247-1.809,0.503-2.671,0.768
	c0.998,1.417,2.15,2.714,3.354,4.002c-2.586,0.998-5.914,0.341-7.91,2.534c2.014,0.452,4.198,1.715,6.315,0.896
	c1.229-1.075,2.099-2.5,3.089-3.797c0.512-0.043,1.536-0.136,2.04-0.179c2.618-2.375,3.113-2.043,4.309-3.669
	c0.427,0.051,1.28,0.154,1.707,0.205c1.186-1.161,2.381-2.313,3.584-3.473c1.485,0.794,2.995,1.579,4.506,2.398
	c0.666-0.213,1.997-0.623,2.662-0.828c-0.913-0.973-1.818-1.937-2.688-2.893c0.717,0.35,1.425,0.691,2.201,1.05
	c0.572-0.068,1.732-0.196,2.313-0.256c0.896,0.674,1.844,1.348,2.808,2.014c0.111-0.631,0.35-1.894,0.461-2.526
	c0.751-1.118,1.493-2.244,2.253-3.379c-2.543,0.538-5.018,1.263-7.475,2.057c-0.094-0.538-0.282-1.621-0.384-2.159
	c-1.647-0.333-3.567-0.29-4.522-1.894c1.895-0.29,3.797-0.495,5.734-0.7c-0.751-1.604-2.005-1.92-3.533-1.468
	c-3.566-2.825-5.087-4.156-6.212-5.376c6.326,2.065,20.335,5.332,22.076-1.101c-6.754,1.9-9.731,0.353-11.895-0.341
	c1.237-1.254,2.458-2.526,3.593-3.908c-1.528,0.555-2.944,1.203-4.352,1.929c-1.16-0.102-2.347-0.205-3.439-0.273
	c0.503-0.649,1.041-1.289,1.604-1.92c-0.478-0.734-0.947-1.468-1.323-2.219c1.417-0.572,3.678-0.196,3.917-2.27
	c-1.22-0.418-2.372,0.307-3.388,0.777c-5.512-3.78-6.869-4.664-7.834-6.852c5.052,2.56,10.453,4.702,16.188,4.966
	c1.323,1.527,2.961,2.133,4.813,0.964c0.555,0.666,1.22,1.374,1.852,2.065c2.662-1.775,6.682-1.263,7.996-4.77
	c-2.953-0.034-5.999,0.034-8.568-1.621c0.879-0.12,2.628-0.35,3.507-0.469c0.026-0.64,0.068-1.929,0.085-2.569
	c-1.314,0.811-2.594,1.672-3.934,2.398c-1.468-0.913-2.876-1.92-4.378-2.731c0.017-0.905,0.06-1.809,0.128-2.697
	c2.04,0.179,4.216,0.776,6.221-0.094c-1.391-0.947-2.611-2.022-3.541-3.388c-0.905-0.051-1.784-0.102-2.62-0.137
	c-1.459-1.399-2.816-2.884-4.002-4.497c2.304,0.742,4.557,1.63,6.912,2.295c-0.128-0.444-0.393-1.323-0.521-1.766
	c1.075-0.29,2.244-0.512,3.345-0.913c-0.452-1.024-0.93-2.005-1.391-2.987c-1.553-0.196-3.106-0.41-4.608-0.614
	c0.137-0.333,0.401-0.99,0.538-1.314c-1.775-1.562-3.516-3.14-5.103-4.872c2.722,0.051,5.052,1.493,7.288,2.91
	c0.461-0.179,1.391-0.529,1.86-0.708c1.502,0.222,3.012,0.41,4.565,0.58c0.085-0.597,0.248-1.8,0.333-2.406
	c-2.654-1.084-5.18-2.398-7.629-3.831c0.938-1.254,1.911-2.492,2.637-3.9c0.23-1.766-1.604-2.219-2.714-3.012
	c-0.35-1.775-0.597-3.618,0.248-5.299c0.64,0.947,1.297,1.903,1.963,2.867c0.512,0.051,1.519,0.162,2.031,0.213
	c-0.265,0.896-0.503,1.792-0.708,2.697c1.101-0.631,2.193-1.28,3.328-1.92c1.707,1.22,3.516,2.304,5.376,3.337
	c0.555-0.247,1.135-0.486,1.749-0.717c1.801,1.777,4.484,4.538,6.75,0.333c1.101-0.87,2.236-1.741,3.328-2.662
	c-1.203-0.085-2.381-0.111-3.524-0.068c-0.324,0.947-0.572,1.92-0.751,2.901c-3.764-1.799-0.849-0.776-6.016-3.089
	c-0.469,0.265-1.408,0.794-1.869,1.058c-1.468-0.768-2.944-1.502-4.318-2.355c0.29-1.203,1.715-1.092,2.663-1.545
	c-1.186-1.254-2.261-2.611-3.046-4.13c1.212,0.555,2.466,1.161,3.703,1.775l0.367-0.666c1.997,0.879,4.019,1.596,6.153,2.202
	c-1.075-1.058-2.074-2.065-3.132-3.063c1.767,0.077,4.318,0.956,4.088-1.818c-2.031,0.171-4.011,0.375-5.999,0.623
	c-2.022-0.469-4.497-0.691-5.001-3.123c0,0,7.043,0.764,7.322,0.794c-0.29-0.367-0.862-1.109-1.152-1.476
	c1.843-0.691,4.591-0.657,4.855-3.191c-0.657,0.026-1.971,0.085-2.628,0.111c-1.766-0.555-3.55-0.145-5.291,0.111
	c-0.58-0.725-1.109-1.459-1.57-2.219c-1.655-0.486-3.379-0.956-4.36-2.449c1.451-0.171,2.935-0.316,4.437-0.452
	c-2.125-1.766-4.54-3.098-6.869-4.506c4.198-3.396,8.841,2.005,13.423,0.418c1.186-1.425,2.705-2.5,4.25-3.533
	c-2.884-0.29-5.325-1.749-7.543-3.482c-0.717-1.408-1.442-2.799-2.159-4.181c0.717-1.05,1.442-2.099,2.261-3.089
	c1.306,0.452,2.509,1.203,3.644,2.048c1.075-0.367,2.176-0.717,3.302-1.084c0.589,0.367,1.766,1.109,2.347,1.476
	c0.085-0.657,0.256-1.988,0.341-2.654c-0.461,0.009-1.365,0.017-1.826,0.026c0.034-0.375,0.085-1.118,0.111-1.485
	c-2.014-0.256-3.985-0.529-5.939-0.862c0.41-0.819,0.836-1.638,1.297-2.441c0.956-0.503,1.98-0.99,2.978-1.493
	c-1.579-0.623-3.089-1.22-4.634-1.792c-0.589-1.203-1.135-2.406-1.647-3.601c-0.461-0.102-1.374-0.29-1.834-0.384
	c-1.075-1.109-2.133-2.21-3.157-3.319c1.041,0.026,2.116,0.068,3.217,0.119c1.595,1.314,3.49,2.167,5.487,2.765
	c0.248-1.161,0.614-2.304,0.938-3.447c-0.512-0.299-1.544-0.896-2.056-1.195c2.935-0.205,5.7,0.828,8.473,1.664
	c0.248-0.375,0.743-1.126,0.99-1.502c0.64-0.085,1.92-0.239,2.56-0.316c-0.981-2.056-3.029-3.157-4.787-4.42
	c0.375-0.41,1.118-1.22,1.493-1.63c-1.306-0.393-2.552-0.768-3.84-1.058c-1.118-0.666-2.202-1.323-3.269-1.988
	c1.639,0.29,2.833-0.247,3.576-1.604c3.606-0.704,3.284-0.619,5.931-0.478c-0.213-0.546-0.64-1.63-0.853-2.176
	c-2.287,0.239-4.728,1.374-6.707-0.307c0.495-0.29,1.493-0.879,1.988-1.178c-0.444-1.007-0.973-1.963-1.442-2.918
	c-0.64,0.009-1.928,0.034-2.569,0.051c-0.99-1.476-1.988-2.927-2.995-4.361c1.673,0.418,3.371,0.819,5.103,1.161
	c0.683,0.785,1.391,1.579,2.125,2.381c0.768-0.674,1.544-1.348,2.372-2.005c-0.179-0.307-0.538-0.93-0.717-1.237
	c0.674-0.666,1.383-1.323,2.116-1.971c-0.589-0.273-1.775-0.802-2.364-1.067c0.281-0.358,0.853-1.058,1.135-1.417
	c-0.248-0.375-0.742-1.135-0.99-1.51c1.698-2.782-1.357-5.094-2.04-7.68c1.493-0.324,2.987-0.657,4.523-0.99
	c-3.025-2.243-2.437-1.554-5.154-3.789c0.35-1.596,0.691-3.2,1.032-4.804c2.321,0.751,4.608,1.698,6.494,3.337
	c0.717-0.538,1.442-1.067,2.193-1.63c-0.606-0.666-1.203-1.306-1.783-1.929c-2.748,0.853-3.823-1.732-4.924-3.652
	c-0.742-0.393-1.459-0.785-2.142-1.178c0.026-0.58,0.085-1.749,0.12-2.338c-0.469-1.365-0.879-2.756-1.144-4.156
	c1.459,1.34,2.799,2.842,4.079,4.378c0.333-0.538,0.99-1.621,1.314-2.159c-0.435-0.905-0.845-1.801-1.229-2.697
	c0.947,0.375,1.92,0.751,2.919,1.126c-0.265,0.461-0.811,1.374-1.084,1.835c5.226-0.906,3.181-1.076,3.055-4.292
	c0.554-0.896,1.152-1.775,1.749-2.662c-0.768-0.068-2.313-0.196-3.089-0.265c0.281-0.393,0.845-1.195,1.126-1.587
	c-0.657-0.085-1.971-0.239-2.628-0.324c0.034-0.87,0.128-1.724,0.239-2.586c-1.135-1.169-1.161-2.799-1.306-4.292
	c0.725,0.674,1.536,1.374,2.321,2.065c0.248-2.099,1.425-3.703,3.277-4.693c-0.657-0.981-1.348-1.954-1.971-2.91
	c0.427,0.154,1.289,0.452,1.715,0.606c-0.051-2.244-0.06-4.48-0.06-6.716l0.785,0.009c0.077,0.691,0.213,2.065,0.282,2.756
	c0.887-0.384,1.801-0.759,2.73-1.135c-0.725,1.63-1.493,3.226-2.244,4.83c0.529-0.128,1.596-0.401,2.125-0.538
	c-0.281,0.316-0.845,0.956-1.118,1.28c0.418,1.178,0.845,2.355,1.323,3.533l0.469-1.374l1.101,0.119
	c-1.033,1.033-2.074,2.048-3.063,3.089c0.375-0.137,1.126-0.401,1.502-0.529c0.29,1.297,0.623,2.586,1.015,3.874
	c-0.307,1.843-0.905,3.627-2.185,5.018c-0.051,0.529-0.154,1.587-0.196,2.116c0.384-0.145,1.152-0.427,1.536-0.563
	c0.64,1.63,1.297,3.268,1.98,4.907c-0.281,0.495-0.836,1.476-1.109,1.971c1.468,0.094,2.569-0.759,3.482-1.843
	c0.529-0.068,1.57-0.196,2.091-0.265c-0.589,1.178-1.152,2.355-1.715,3.533c0.154,0.35,0.452,1.058,0.597,1.408
	c-1.989,1.485-4.053,2.859-6.255,3.968c1.297,1.835,2.654,0.265,3.977-0.555c0.794,0.58,1.621,1.152,2.432,1.801
	c-0.444,0.631-1.323,1.877-1.758,2.509c1.067-0.077,2.167-0.265,3.26-0.546c-0.486,1.468-0.819,2.978-1.118,4.489
	c-0.666,1.007-1.109,2.099-0.785,3.328c-1.357,0.324-2.731,0.614-4.045,0.913c0.222,0.674,0.469,1.357,0.743,2.039
	c0.768-0.222,1.578-0.41,2.423-0.555c0.546,0.777,1.033,1.621,1.57,2.466c0.717-1.903,2.492-2.859,4.241-3.678
	c-0.623,1.101-1.229,2.193-1.852,3.277c0.145,0.213,0.452,0.623,0.606,0.828c-2.108,0.922-3.917,2.347-5.632,3.823
	c1.365,0.017,2.756,0.034,4.182,0.06c-1.126,1.997-2.671,3.678-4.454,5.086c0.597,0,1.792,0,2.398,0.009
	c-0.162,0.521-0.478,1.562-0.64,2.082c3.695-1.719,3.364-1.748,7.202-2.918c-2.04,2.048-3.994,4.224-5.248,6.861
	c0.597-0.179,1.801-0.529,2.406-0.708c-0.427,0.853-0.819,1.707-1.186,2.577c1.357-0.794,2.705-1.579,4.139-2.313
	c0.085,3.439-2.458,5.777-5.572,6.613c-0.435,0.666-0.836,1.348-1.212,2.039c-1.032,0.299-1.997,0.614-2.953,0.947
	c-0.64-1.434-1.98-1.946-3.473-1.698c1.067,2.142,3.55,2.304,5.581,2.927c-0.358,0.717-0.683,1.442-0.981,2.176
	c-0.614,0.102-1.843,0.316-2.458,0.418c0.213,0.512,0.649,1.545,0.862,2.057c1.314-0.657,2.645-1.297,4.045-1.869
	c-0.853,2.193-2.449,3.925-4.079,5.555c0.674,0,2.014-0.009,2.688-0.009c0.06,0.495,0.179,1.485,0.239,1.98
	c0.487-0.691,1.007-1.382,1.545-2.065c0.384,0.196,1.152,0.58,1.536,0.777c0.597-0.606,1.22-1.212,1.869-1.809
	c-0.248-0.333-0.751-1.015-0.998-1.348c-0.683-0.06-2.031-0.179-2.705-0.239c0.529-0.879,1.101-1.749,1.698-2.603
	c1.971,1.152,4.122,1.963,6.451,2.116c-0.529,0.597-1.024,1.195-1.485,1.801c0.188,1.766,0.35,3.541,0.546,5.316
	c-2.099,0.444-4.045,1.323-5.751,2.577c1.417,0.802,2.969,1.22,4.608,1.476c1.519-0.905,3.243-1.195,5.018-1.391
	c-0.555,1.161-1.084,2.33-1.587,3.499c-6.3,1.623-6.411,3.436-7.398,7.219c1.783-0.35,3.575-0.751,5.393-1.135
	c-1.22,1.818-2.628,3.482-4.122,5.06c1.135-0.299,2.295-0.58,3.481-0.828c-2.073,2.79-5.265,4.395-8.363,5.743
	c1.314,0.273,2.679,0.563,4.045,0.896c10.828-2.588,1.744,0.343,11.452-7.654c-0.034,1.476-0.043,2.953-0.034,4.446
	c-1.024,0.922-2.031,1.869-2.953,2.867c2.893-0.555,5.265-2.458,8.132-3.157c-0.606,0.887-1.186,1.775-1.741,2.662
	c-1.92,0.674-3.814,1.391-5.675,2.15c-0.444,2.116-1.28,4.113-1.954,6.161c0.401,0.213,1.203,0.648,1.604,0.87
	c-0.307,1.391-0.58,2.799-0.819,4.207c3.226-1.468,6.238-3.721,9.916-3.917c-2.338,2.816-5.999,3.874-8.644,6.306
	c0.87-0.017,2.603-0.06,3.473-0.077c-0.947,1.92-2.065,3.746-3.277,5.504c-1.442,0.495-2.859,1.024-4.233,1.562
	c0.495,0.171,1.485,0.503,1.988,0.674c-0.725,1.058-1.425,2.125-2.074,3.226c2.577-1.417,4.898-3.251,7.51-4.617
	c-0.828,1.92-1.963,3.678-3.115,5.41c-0.691,0.085-2.073,0.265-2.765,0.35c1.331,1.434,3.217,2.338,4.343,0.068
	c2.526-0.384,4.71-1.766,6.63-3.413c0.29,0.094,0.871,0.282,1.161,0.375c0.717-0.794,1.442-1.596,2.21-2.372
	c1.766-0.026,3.55-0.043,5.359-0.051c0.341-0.384,1.033-1.152,1.374-1.536c2.202-0.35,4.42-0.717,6.682-0.845
	c0.64-0.879,1.323-1.741,1.954-2.654c-0.708-1.178-1.562-2.236-2.338-3.337c2.645-0.521,5.316-0.956,8.03-1.195
	c0.742-0.649,1.485-1.297,2.295-1.929c0.461-1.229,0.896-2.466,1.408-3.686c-2.628,0.905-5.965,5.197-8.448,2.15
	c2.722-1.067,5.837-1.732,7.731-4.19c-2.372-0.631-3.976,0.495-4.898,2.62c-0.316-0.384-0.939-1.161-1.254-1.544
	c-1.886,0.853-3.84,1.553-5.863,1.877c-0.785-0.888-1.519-1.758-2.253-2.628c1.553-0.802,3.157-1.57,4.761-2.338
	c2.065-0.239,3.669-1.382,4.497-3.337c-1.51,0.179-2.953,0.41-4.412,0.623c-3.089,1.493-5.325,4.745-9.165,4.25
	c0.375-1.203,0.811-2.398,1.254-3.584c-1.536,0.64-3.063,1.271-4.591,1.835c-0.887-0.947-1.783-1.86-2.688-2.731
	c3.789-1.417,6.622-4.89,10.888-4.975c3.729-1.169,7.672-0.776,11.477-1.544c-0.154-0.384-0.452-1.169-0.606-1.553
	c1.263-0.7,2.492-1.434,3.772-2.193c-0.802-0.179-2.415-0.555-3.217-0.742c0.7-1.033,1.382-2.074,2.082-3.123
	c-4.241,0.341-3.524,7.467-7.927,6.391c-0.034-2.065,1.784-3.362,2.91-4.872c-0.401,0.051-1.186,0.171-1.587,0.23
	c-3.277-1.075-5.453,2.133-7.851,3.712c-0.563-0.341-1.084-0.7-1.553-1.075c-2.27,0.273-4.557,0.375-6.81,0.384
	c0.725-1.246,1.502-2.458,2.287-3.678c-1.809,0.546-3.592,1.126-5.359,1.732c0.017-0.495,0.051-1.476,0.06-1.971
	c0.589-0.631,1.203-1.254,1.852-1.869c-0.418-0.845-0.776-1.681-1.084-2.534c2.748-1.195,6.204-1.058,8.218-3.618
	c-1.451,0.137-2.867,0.299-4.267,0.486c-0.452-0.811-0.879-1.613-1.28-2.415c-2.381,0.623-4.736,1.297-7.066,2.022
	c0.358-1.152,0.785-2.287,1.203-3.43c-0.785-0.077-2.338-0.222-3.123-0.299c2.082-1.894,5.657-2.722,5.811-6.042
	c-1.169,0.853-2.279,1.758-3.345,2.688c-1.28,0.06-2.526,0.119-3.738,0.188c-0.009-1.425,0.017-2.833,0.06-4.25
	c-1.041-0.068-2.048-0.128-3.029-0.179c-0.589-0.947-1.152-1.877-1.69-2.807c1.365-1.237,3.157-1.425,4.958-1.425
	c0.734,0.794,1.493,1.587,2.295,2.372c0.264-1.075,0.708-2.116,1.041-3.174c0.793,1.178,1.621,2.355,2.458,3.541
	c0.409-0.307,1.238-0.922,1.656-1.237c0.615,0.64,1.289,1.28,1.971,1.937c0.452-0.759,0.973-1.485,1.553-2.185
	c3.285-0.316,6.588-0.154,9.907-0.316c0.102-0.751,0.307-2.253,0.41-3.004c-1.613-1.041-3.473-2.005-4.967-0.247
	c0.7,0.222,2.091,0.674,2.782,0.896c0.026,0.188,0.077,0.555,0.094,0.742c-2.432,1.655-5.197-1.058-7.91-0.068
	c1.519-1.886,2.594-4.079,2.995-6.485c-2.116,1.178-3.251,3.627-5.666,4.275c-0.554-1.502-1.075-2.987-1.595-4.48
	c-1.4-0.094-2.808-0.188-4.139-0.265c0.213-0.631,0.657-1.894,0.87-2.517c-1.032,0.316-2.056,0.649-3.029,0.99
	c-0.717-1.203-1.408-2.406-2.073-3.601c-0.393,0.273-1.169,0.828-1.562,1.101c-2.099-2.85,2.364-2.876,4.139-2.807
	c6.239-8.163,4.413-5.187,10.376-4.855c-0.026-1.417-0.009-2.825,0.026-4.224c1.954,0.009,5.163-2.321,5.675,0.862
	c0.87-0.623,1.766-1.254,2.697-1.877c1.493,0.299,3.021,0.546,4.548,0.819c1.365-1.86,2.432-3.934,2.842-6.229
	c1.724-0.196,3.473-0.213,5.222-0.094c0.794-1.69,1.57-3.388,2.176-5.163c-1.092,0.905-2.125,1.86-3.115,2.842
	c-1.195-0.333-2.389-0.649-3.541-0.956c0.145,0.469,0.452,1.417,0.597,1.886c-0.708,0.333-1.391,0.674-2.04,1.015
	c-0.478-0.922-0.973-1.826-1.485-2.697c-1.647-0.188-3.14,0.674-4.634,1.126c-1.784,1.382-3.857,2.27-5.965,2.97
	c-1.041-0.717-2.056-1.399-3.081-2.065c-1.391,0.205-2.799,0.179-4.156-0.077c0.384-1.741,0.726-3.482,1.067-5.231
	c-2.21-0.085-4.412-0.247-6.536-0.734c1.997-2.278,4.727-3.217,7.731-2.901c0.999-1.058,2.014-2.108,3.038-3.157
	c1.297-0.051,2.62-0.119,3.976-0.171c0.085,0.64,0.265,1.929,0.35,2.569c0.546,0.009,1.63,0.034,2.176,0.043
	c0.026-0.691,0.085-2.065,0.119-2.748c1.621-0.461,3.243-0.836,4.958-1.101c-0.615,1.647-2.159,2.483-3.516,3.362
	c2.091,0.341,4.198-0.162,6.323-0.324c0.947-1.468,1.118-3.004-0.043-4.395c-0.589,0-1.775,0.017-2.364,0.026
	c-0.409-0.836-0.785-1.672-1.135-2.5c-1.007,0.333-1.997,0.657-2.961,0.973c0.922-3.337,4.531-4.156,7.518-4.651
	c-0.828-1.126-1.579-2.253-2.347-3.379c-0.392,0.478-1.186,1.425-1.579,1.903c-2.338-0.162-5.555,1.007-6.758-1.69
	c0.93-1.254,2.056-2.355,3.174-3.473c-1.271,0.009-2.509,0.017-3.72,0.034c-0.06-0.512-0.188-1.545-0.256-2.065
	c-0.708-1.075-1.382-2.15-2.005-3.243c1.254,0.247,2.603,0.529,3.908,0.819c0.879-0.29,1.775-0.572,2.713-0.845
	c-0.068,0.41-0.205,1.212-0.273,1.621c1.937-0.401,3.917-0.597,5.931-0.708c0.111,0.742,0.333,2.227,0.444,2.97
	c1.604-0.555,3.183-1.229,4.838-1.732c1.16,0.836,2.261,1.792,3.396,2.714c0.341-1.22,0.7-2.432,1.075-3.644
	c0.478-0.333,1.442-0.998,1.92-1.323c-0.99-0.947-1.971-1.894-2.935-2.807c-0.085-1.22-0.128-2.423-0.145-3.627
	c-2.5-0.119-5.299,0.401-7.262-1.493c0.7-0.102,2.082-0.316,2.782-0.418c8.876-9.417,7.283-7.917,10.027-9.737
	c-1.032-0.529-1.988-1.033-2.961-1.536c2.133-2.304,4.625-4.207,7.663-5.197c0.427,0.367,1.289,1.092,1.724,1.459
	c0.367-0.256,1.118-0.759,1.493-1.015c1.596,0.435,3.234,0.768,4.907,1.101c0.922,0.461,1.775,1.314,2.918,1.365
	c1.775-0.742,3.405-1.809,5.103-2.765c0.367,0.247,1.092,0.751,1.459,0.998c2.116-1.673,4.267-3.311,6.511-4.83
	c-0.632-0.444-1.229-0.879-1.801-1.306c-0.026-0.563-0.077-1.681-0.103-2.244c-0.478-0.034-1.425-0.102-1.903-0.137
	c0.324-0.375,0.964-1.126,1.289-1.502c0.811,0.196,1.655,0.401,2.526,0.614c-0.265-0.794-0.503-1.579-0.708-2.364
	c-1.365,0.495-2.876,1.195-3.311-0.742c-1.254-0.444-2.5-0.93-3.738-1.263c-1.852,1.843-3.533,3.849-5.658,5.367
	c-0.572-0.239-1.109-0.469-1.613-0.691c-1.109,0.657-2.227,1.306-3.311,1.937c0.392-1.152,0.819-2.287,1.289-3.413
	c-0.572,0.043-1.732,0.128-2.313,0.171c1.98-1.732,4.693-2.705,5.982-5.154c-3.226,0.649-5.342,3.319-7.945,5.069
	c0.213-0.981,0.41-1.963,0.606-2.944c-1.92-1.638-4.343-0.341-6.451,0.043c-1.05,1.015-2.108,2.031-3.123,3.055
	c-1.263,0.324-2.492,0.717-3.729,0.947c-0.589-0.794-1.126-1.596-1.596-2.423c-0.316-0.119-0.947-0.375-1.271-0.495
	c-1.775,0.239-3.567,0.41-5.316,0.572c-0.222,0.401-0.657,1.212-0.879,1.621c-0.708-0.06-2.116-0.179-2.824-0.239
	c1.289-1.126,2.611-2.202,3.942-3.311c-2.773,0.051-5.487,0.529-8.183,1.033c1.745-6.117,11.052-1.036,12.885-5.743
	c0.572-0.307,1.698-0.913,2.27-1.22c-0.768-0.316-2.304-0.947-3.072-1.263c8.227-2.833,12.08,0.324,14.524-2.551
	c1.297-0.512,2.628-1.015,3.977-1.536c1.425,0.486,2.859,0.947,4.36,1.357c-1.707-1.135-3.422-2.227-5.018-3.456
	c-0.87-0.119-1.698-0.239-2.483-0.35c-1.399-2.645-4.48-0.452-6.656-0.503c0.375-0.99,0.785-1.971,1.212-2.953
	c1.28,0.35,2.603,0.725,3.926,1.109c-0.299-0.862-0.563-1.724-0.811-2.577c0.597-0.017,1.783-0.034,2.372-0.043
	c0.333-0.777,0.7-1.553,1.101-2.321c2.415,0.7,4.941,0.7,7.467,0.597c0.085-0.546,0.265-1.63,0.359-2.167
	c2.987-0.068,3.857-2.884,4.079-5.402c-0.64-1.015-1.408-1.92-2.065-2.867c-0.836,0.666-1.63,1.357-2.406,2.039
	c-0.904-1.468-1.843-2.901-2.773-4.326c-1.254,1.323-2.449,2.697-3.524,4.147c-1.075,0.026-2.125,0.06-3.132,0.085
	c0.905-0.956,1.852-1.903,2.824-2.842c-0.375-0.683-0.708-1.357-1.015-2.031c-0.828-0.401-1.621-0.794-2.389-1.186
	c-2.936,2.185-5.53,4.898-8.857,6.494c0.691-3.439,4.753-4.19,6.016-7.305c-6.148,0.547-0.625,0.505-7.595,0.017
	c-1.289,0.956-2.509,2.091-4.045,2.569c-2.987-0.102-5.811-1.22-8.474-2.458c-1.724,0.717-3.473,1.365-5.214,1.946
	c0.273-1.451,0.597-2.893,0.922-4.335c3.166,1.391,4.548-2.722,6.374-4.471c-4.275-1.212-8.618-0.222-12.843,0.614
	c1.024-1.399,2.116-2.756,3.251-4.087c-1.647-0.026-3.285-0.051-4.881-0.094c0.64-0.998,1.323-1.98,2.039-2.953
	c-2.005-0.905-3.755-2.244-5.41-3.635c1.177-1.075,2.432-2.057,3.746-3.004c0.461,0.358,1.383,1.084,1.844,1.442
	c0.606-0.657,1.229-1.314,1.886-1.971c3.874,0.324,7.74-0.239,11.272-1.946c0.606,0.725,1.237,1.459,1.903,2.193
	c-0.171,0.29-0.512,0.887-0.683,1.186c0.853,0.35,1.732,0.708,2.628,1.075c0.58-1.408,1.169-2.816,1.775-4.215
	c0.555-0.017,1.673-0.051,2.227-0.06c0.333-0.41,0.999-1.212,1.323-1.621c0.256,0.102,0.751,0.307,0.998,0.401
	c0.999-1.22,2.057-2.406,3.183-3.541c0.973-0.555,1.98-1.118,2.987-1.707c-1.357-0.162-2.679-0.307-3.985-0.435
	c0.009-0.393,0.009-1.161,0.009-1.553c1.886-0.043,3.78-0.111,5.709-0.222c-1.988-2.031-4.889-1.519-7.424-1.562
	c0.58-2.219,0.913-4.489,1.212-6.758c-0.35-0.393-1.058-1.186-1.408-1.587c1.647-0.179,3.345-0.358,5.035-0.631
	c0.418,0.137,0.87,0.282,1.374,0.427c0.708-1.271,1.989-1.732,3.371-2.031c-0.299,1.826-0.683,3.635-0.922,5.461
	c0.862,0.06,1.758,0.094,2.697,0.111c0.819-1.587,2.21-2.697,3.72-3.627c0.068,1.903-0.162,3.797-0.725,5.623
	c1.425-0.555,2.893-1.075,4.386-1.553c0.017-0.512,0.06-1.545,0.077-2.065c1.126-0.444,2.261-0.896,3.439-1.331
	c-0.068-0.41-0.213-1.229-0.29-1.638c-1.476-0.896-2.867-1.886-4.233-2.884c-2.04,0.495-4.053,1.007-6.042,1.536
	c0.35-0.905,0.725-1.809,1.144-2.714c-0.632,0.017-1.886,0.043-2.517,0.06c-0.034-0.427-0.111-1.289-0.145-1.715
	c-2.406-0.009-4.796-0.051-7.168-0.119c0.529-0.853,1.101-1.698,1.698-2.534c3.046,2.074,6.187-0.085,8.909-1.621
	c2.057,1.613,4.728,2.458,6.989,0.606c-0.145-1.434-1.425-2.261-2.261-3.26c-1.408,0.64-2.953,1.442-3.575-0.546
	c-2.278,0.495-5.419,1.673-6.801-0.973c-0.939-0.051-1.852-0.094-2.731-0.128c-0.307,0.435-0.913,1.314-1.212,1.749
	c-0.896-0.026-1.749-0.043-2.577-0.06c-0.785-1.161-1.741-2.15-2.876-2.91c0.256-1.015,0.495-2.039,0.811-3.046
	c-2.389,1.306-4.557,0.043-6.528-1.323c0.666-1.391,1.177-3.046,2.688-3.78c0.922,0.503,1.826,1.118,2.824,1.604
	c1.015-0.324,1.963-0.819,2.978-1.263c2.978,0.606,6.033,0.623,9.088,0.486c-0.794,0.819-1.562,1.638-2.278,2.475
	c0.691,0.06,2.073,0.188,2.765,0.247c-0.051-0.555-0.154-1.664-0.205-2.219c1.399,0.529,2.842,1.067,4.292,1.587
	c0.401-0.811,0.836-1.613,1.314-2.415c1.877-0.887,2.969-2.62,3.695-4.506c-0.648-0.145-1.946-0.427-2.594-0.563
	c1.126-2.014,3.191-2.953,5.325-3.567c0.359-1.109,0.794-2.193,1.109-3.319c-0.768-0.461-1.528-0.905-2.253-1.323
	c-1.562,0.785-3.243,0.845-4.787,0.102c0.307-0.452,0.93-1.365,1.246-1.818c-0.648,0-1.945-0.009-2.594-0.009
	c-0.043,0.41-0.119,1.229-0.154,1.638c-0.956,0.06-1.877,0.119-2.773,0.196c-2.219,2.867-6.332,1.263-9.404,1.579
	c0.615-0.7,1.263-1.391,1.929-2.074c0.009-0.444,0.009-1.34,0.009-1.783c-0.896-0.444-1.758-0.879-2.594-1.314
	c-1.229-0.222-2.44-0.461-3.61-0.717c0.631-0.828,1.314-1.655,2.005-2.475c-0.358-0.273-1.084-0.828-1.442-1.109
	c-0.119-1.886-0.128-4.019,2.142-4.599c-0.359-0.7-0.683-1.4-0.982-2.091c-0.367,0.247-1.092,0.742-1.45,0.99
	c-0.802-0.29-1.562-0.58-2.304-0.862c-0.35,0.384-1.05,1.152-1.4,1.536c-0.623-0.009-1.869-0.026-2.492-0.034
	c0.282-0.768,0.597-1.536,0.947-2.295c-0.648-0.631-1.263-1.263-1.852-1.894c-1.741-0.034-3.473-0.06-5.163-0.102
	c0.316-1.143,0.469-2.415,1.305-3.345c2.023-0.043,4.002,0.546,6.016,0.922c1.374-1.732,2.884-3.354,4.335-5.035
	c0.947,0.393,1.903,0.777,2.927,1.161c0.162,0.358,0.495,1.067,0.666,1.425c1.152-0.521,2.406-1.186,3.712-0.452
	c0.239-0.102,0.717-0.299,0.956-0.393c-1.621-0.777-3.063-1.835-3.883-3.439c0.717-0.051,2.151-0.154,2.859-0.205
	c5.794-3.485,8.05,5.174,15.411,0.051c0.179-1.109,0.469-2.219,0.742-3.319c1.783,0.358,3.576,0.674,5.41,0.947
	c-0.017-0.563-0.051-1.698-0.077-2.261c-0.802-0.683-1.57-1.374-2.313-2.065c-1.289-0.077-2.577-0.154-3.797-0.222
	c0.35-0.819,0.726-1.638,1.135-2.449c-0.785-0.051-2.347-0.145-3.132-0.196c0.879-1.195,1.792-2.364,2.756-3.524
	c-1.895,0.401-3.738,0.853-5.589,1.28c-0.068-0.572-0.213-1.707-0.282-2.278c-1.084-0.853-2.082-1.783-2.944-2.816
	c2.005-0.145,4.045-0.213,6.093-0.307c-1.775-1.954-4.147-3.081-6.622-3.789c1.152-0.239,2.304-0.469,3.516-0.7
	c0.017-0.452,0.051-1.365,0.068-1.818c-1.971-0.802-3.968-1.596-5.598-2.961c1.57,0.111,3.225,0.299,4.83,0.529
	c-0.017-0.435-0.051-1.314-0.068-1.758c-2.048-1.374-4.71-2.347-5.547-4.881c1.289,0.333,2.637,0.708,3.968,1.075
	c-0.572-0.7-1.118-1.408-1.647-2.099c-0.06-0.495-0.179-1.468-0.247-1.954c-0.93-0.913-1.826-1.826-2.705-2.739
	c0.538-0.068,1.604-0.188,2.133-0.256c-0.401-1.51-0.811-3.012-1.152-4.514c1.246,0.742,2.492,1.519,3.763,2.295
	c-0.452-1.229-0.887-2.449-1.289-3.669c1.425,0.717,2.884,1.399,4.378,2.057c0-0.521-0.009-1.57-0.009-2.091
	c0.879,0.41,1.783,0.811,2.722,1.22c-0.393-0.99-0.768-1.98-1.109-2.97c1.203,0.742,2.432,1.485,3.695,2.202
	c-0.913-2.569-1.835-5.129-2.859-7.646c2.432-2.714,6.144,1.314,8.397-1.937c0.52-0.06,1.553-0.188,2.065-0.256
	c-0.009-1.118,0.009-2.236,0.051-3.354c-2.782-1.69-5.7-3.157-8.235-5.188c1.562,0.239,3.123,0.546,4.728,0.836
	c-1.613-2.705-4.574-3.797-7.253-5.077c2.586,0.171,5.154,0.666,7.748,1.007c-2.372-1.69-4.941-3.14-6.886-5.333
	c1.844,0.299,3.567,1.084,5.06,2.253c0.887,0.043,1.698,0.068,2.663,0.111c-0.845-1.673-1.877-3.226-3.03-4.676
	c1.254-0.341,2.534-0.683,3.84-1.015c-0.597-2.031-1.391-3.977-2.287-5.871c0.666-0.009,2.005-0.043,2.671-0.051
	c0.409-0.87,0.836-1.741,1.289-2.611c0.409,0.051,1.212,0.162,1.613,0.213c0.921,0.717,1.911,1.399,2.927,2.048
	c0.592-8.353-2.857-13.141,1.715-14.669c-1.035,4.526,0.637,16.664,0.836,17.664c1.024-0.077,2.074-0.154,3.157-0.23
	c5.877-4.978,7.251-1.248,10.598-6.042c-0.794-1.22-1.536-2.458-2.227-3.703c-1.357-0.546-2.713-1.092-4.019-1.63
	c0.751-1.271,1.553-2.509,2.381-3.755c-1.289-0.034-2.543-0.068-3.772-0.102c0.171-0.367,0.512-1.101,0.683-1.468
	c-1.707-1.092-4.019-1.809-4.395-4.07c1.169,0.614,2.364,1.306,3.516,2.039c4.045,0.179,8.149-0.162,12.168,0.623
	c0.282-0.333,0.862-1.007,1.144-1.348c-0.64-0.017-1.92-0.06-2.56-0.077c-0.29-0.341-0.871-1.015-1.161-1.357
	c1.485-0.427,3.004-0.87,4.523-1.331c-0.29-0.717-0.546-1.425-0.777-2.142c1.033-1.195,1.997-2.475,2.654-3.942
	c-0.811-0.717-1.596-1.442-2.347-2.159c0.802-0.077,2.398-0.239,3.191-0.316c-0.964-1.673-3.336-3.337-1.655-5.35
	c-0.196-0.981-0.41-1.963-0.529-2.935c0.41-0.017,1.229-0.051,1.638-0.06c0.299,2.005,0.7,4.011,1.126,6.007
	c0.819-0.452,1.664-0.887,2.543-1.331c-0.384-0.896-0.76-1.801-1.075-2.697c1.51-0.282,3.08-0.563,4.642-0.887
	c-0.256,0.657-0.487,1.314-0.683,1.98c0.342,1.186,0.717,2.381,1.109,3.567c1.126-0.009,2.27,0.009,3.448,0.026
	c-0.836,2.33-0.657,4.804,0.06,7.142c-4.153,4.313,0.328,8.477,0.068,20.079c0.836-0.128,1.707-0.265,2.62-0.401
	c0.597,1.101,1.178,2.202,1.834,3.311c1.195-1.434,2.372-2.91,3.576-4.361c1.015,0.017,2.056,0.034,3.132,0.068
	c0.768-1.724,1.459-3.473,2.099-5.257c0.999,0.171,2.023,0.341,3.081,0.521c1.459-1.698,2.841-3.465,4.352-5.137
	c-0.324,1.024-0.632,2.048-0.921,3.063c0.554,0.393,1.143,0.785,1.766,1.178c-1.442,0.836-2.824,1.698-4.207,2.56
	c0.188,0.333,0.546,0.998,0.734,1.331c0.521-0.256,1.57-0.759,2.091-1.015c-0.777,2.278-2.679,3.755-4.668,4.907
	c-0.017,0.503-0.043,1.502-0.06,2.005c-0.913,0.41-1.801,0.819-2.654,1.22c-0.316,0.734-0.597,1.468-0.853,2.202
	c0.794,0.444,1.63,0.853,2.509,1.237c0.393-0.444,1.169-1.34,1.562-1.783c1.613-0.401,3.251-0.819,4.915-1.186
	c-2.756,3.226-6.246,5.692-9.617,8.209c-0.281,1.254-0.537,2.509-0.768,3.772c-0.657,0.077-1.971,0.23-2.628,0.307
	c-0.042,0.512-0.136,1.545-0.188,2.057c0.7,0.239,1.434,0.478,2.21,0.717c1.63-2.227,3.345-4.412,5.171-6.519
	c2.918,2.048,5.538-1.323,8.38-1.963c-0.922,1.101-1.835,2.185-2.765,3.243c-0.06,0.495-0.179,1.476-0.248,1.963
	c-0.785,0.375-1.536,0.759-2.261,1.143c-0.136,1.041-0.282,2.082-0.435,3.115c-1.024,1.604-3.217,0.973-4.761,1.357
	c-0.042,0.546-0.136,1.647-0.179,2.193c2.091,0.316,4.19,0.631,6.315,0.913c-0.239,1.51-0.444,3.021-0.623,4.531
	c0.836-0.461,1.698-0.913,2.585-1.365c0.87-1.476,1.749-2.927,2.662-4.386c2.381,1.843-2.142,4.395-0.35,6.767
	c-0.06,1.937,0.742,3.661,1.971,5.146c-0.489,1.15-3.193,9.27-8.934,12.902c-1.519,2.039-0.632,4.454-0.034,6.647
	c2.33-0.717,4.608-1.638,6.767-2.816c-0.598,1.024-1.144,2.065-1.664,3.106c0.546,0.862,1.101,1.732,1.698,2.603
	c3.072,0.324,6.118,0.981,8.994,2.21c-1.041,1.655-2.125,3.268-3.242,4.855c1.826,0.759,3.797,0.853,5.751,0.358
	c0.717,0.205,1.468,0.41,2.261,0.623c-0.452-3.823-1.724-7.45-2.995-11.051c-0.751-0.725-1.459-1.451-2.133-2.176
	c0.521-4.642-4.395-6.528-5.786-10.385c2.842,0.836,4.028,3.994,6.844,5.009c1.075,1.86,2.372,3.618,3.994,5.069
	c-0.777-3.021-2.125-5.905-2.022-9.097c0.512,0.017,1.536,0.043,2.048,0.051c-0.435-2.295-1.348-4.702-0.196-6.946
	c0.375,0.35,1.144,1.033,1.519,1.374c0.478,3.61,1.809,7.049,2.125,10.684c0.375,0.341,1.118,1.024,1.493,1.365
	c-0.009,1.681-0.009,3.362,0.034,5.052c1.118-0.077,2.253-0.154,3.422-0.222c0.102-3.618-0.077-7.253-0.981-10.769
	c0.461-0.29,1.383-0.879,1.844-1.178c-0.043-1.707-0.06-3.413-0.043-5.12l0.734-0.009c0.042,1.954,0.077,3.959,0.862,5.794
	c0.128,2.432,0.119,4.873,0.196,7.313c4.26-8.236,1.149-12.43,5.973-15.394c-0.35,5.171-2.406,10.061-2.671,15.283
	c0.777-0.358,1.587-0.717,2.424-1.075c0.248-4.403,1.903-8.533,3.029-12.757l0.555-0.009c0,0.759,0,2.278,0,3.038
	c1.084-1.084,2.21-2.167,3.371-3.234c-5.146,11.347-0.837,3.012-4.446,9.25c0.486,0.316,1.468,0.956,1.954,1.271
	c0.666-0.418,1.357-0.828,2.082-1.237c1.118-1.673,2.526-3.123,4.19-4.284c-2.21,4.873-5.939,8.866-7.944,13.858
	c1.305-0.017,2.654-0.017,4.028-0.009c3.55-3.763,6.357-8.26,10.624-11.324c-1.041,1.647-2.125,3.268-3.047,4.975
	c1.767-0.828,3.354-1.988,5.001-3.063c-2.927,4.301-7.646,7.083-11.477,10.547c-0.41,1.886-0.802,3.772-1.109,5.675
	c0.648,0.043,1.937,0.119,2.586,0.162c1.493,1.766,2.927,3.618,4.54,5.316c-0.452-2.287-1.271-4.463-1.937-6.682
	c1.246,1.715,2.313,3.575,3.584,5.308c-0.171-2.927-0.794-5.803-0.879-8.73c0.401,1.135,0.785,2.287,1.177,3.456
	c0.324-0.111,0.99-0.333,1.314-0.444c-0.136,3.311,0.196,6.622,1.135,9.805c0.742-0.461,1.51-0.913,2.304-1.374
	c0-1.519-0.009-3.038,0.068-4.557c0.375,0.683,1.109,2.048,1.485,2.731c0.247-3.183-0.35-6.596,1.28-9.506
	c0.555,1.348,1.203,2.705,1.809,4.053c0.35-1.246,0.742-2.492,1.169-3.729c0.102,0.981,0.307,2.935,0.41,3.917
	c1.041-1.101,2.073-2.21,3.157-3.311c-0.29-0.435-0.87-1.306-1.16-1.741c3.464-1.638,6.528,1.152,9.975,0.93
	c-0.487-0.981-0.939-1.954-1.365-2.927c-0.896-0.461-1.766-0.913-2.603-1.365c0.512-0.87,1.075-1.724,1.664-2.586
	c0.751,1.229,1.544,2.458,2.321,3.703c0.512,0.009,1.544,0.026,2.056,0.026c0.324-0.435,0.964-1.323,1.289-1.758
	c-1.596-2.449-3.174-4.915-5.077-7.117c-0.375-1.203-0.751-2.406-1.041-3.618c2.961,1.707,3.866,5.274,5.845,7.876
	c0.384-0.333,1.135-0.99,1.51-1.323c1.041,0.333,2.159,0.734,3.328,0.469c1.502,0.828,3.08,1.143,4.369-0.324
	c-0.52-0.265-1.57-0.802-2.099-1.075c-0.521-4.105-1.903-8.064-2.065-12.211c0.938,0.811,1.886,1.63,2.867,2.458
	c1.391-0.589,2.808-1.161,4.258-1.715c-0.324-0.35-0.99-1.058-1.314-1.408c1.587,0.7,3.175,1.425,4.813,2.091
	c-0.726-0.922-1.417-1.835-2.091-2.748c-1.826-0.418-3.669-0.828-5.496-0.401c-0.247-0.333-0.734-1.015-0.981-1.357
	c-2.261,0.179-4.335,1.169-6.443,1.852c0.529-1.365,1.118-2.731,1.707-4.096c0.947-0.145,1.929-0.29,2.944-0.444
	c-1.16-0.811-2.261-1.638-3.345-2.492c-2.987,0.725-5.948,1.527-8.841,2.526c0.819-1.399,1.57-2.816,2.423-4.224
	c-1.195,0.597-2.389,1.22-3.524,1.826c1.263-1.988,2.816-3.772,4.514-5.419c-0.939-0.009-2.807-0.009-3.746-0.009
	c0.751-0.23,2.253-0.683,3.004-0.905c-0.913-0.58-1.783-1.152-2.628-1.732c2.398-1.715,5.197-2.543,8.167-2.543
	c-0.956-1.015-1.894-2.005-2.799-3.012c-1.758,0.503-4.275,1.613-5.052-0.845c-3.123,0.529-6.246,0.99-9.318,1.647
	c-0.085-0.631-0.239-1.894-0.316-2.526c2.671-1.024,5.453-1.835,8.363-1.997c0.341-0.777,0.708-1.536,1.109-2.304
	c-0.265-0.418-0.794-1.271-1.058-1.698c1.067-0.384,2.15-0.777,3.285-1.152c0.538-0.905,1.109-1.801,1.715-2.688
	c-0.435-0.273-1.297-0.828-1.724-1.101c-3.473,0.691-6.878,1.758-10.394,2.125c1.417-1.067,2.893-2.099,4.344-3.174
	c-1.34-0.034-2.654-0.06-3.942-0.077l0.026-0.538c2.978-0.734,5.871-1.783,8.9-2.415c-2.321-1.186-4.779-2.099-7.253-2.816
	c3.456-1.758,7.432-0.887,11.17-1.041c-0.017-0.546-0.06-1.647-0.077-2.193c-0.76-0.922-1.468-1.86-2.21-2.731
	c-1.357,0.154-2.688,0.521-3.968,0.785c0.887-0.845,1.809-1.673,2.739-2.526c-2.765-0.111-5.649,0.051-8.09-1.434
	c0.7-0.247,2.091-0.734,2.782-0.981c-2.134-1.399-5.59-0.785-6.187-3.883c4.463,0.247,8.38,3.294,12.937,2.543
	c-1.308-1.703-23.841-8.952-40.175-9.327c0.512-0.836,1.075-1.673,1.672-2.5c3.866,0.751,8.346,3.029,11.87,0.179
	c-0.793-0.085-2.389-0.256-3.183-0.341c0.324-0.239,0.981-0.7,1.306-0.93c-1.34-0.683-2.62-1.374-3.874-2.108
	c-1.28-0.137-2.509-0.256-3.72-0.367c-0.35-0.375-1.05-1.109-1.4-1.476c-1.365-0.017-2.713-0.026-4.01-0.026l0.068-0.623
	c1.792-0.41,3.584-0.87,5.402-1.331c-1.297-1.092-2.611-2.15-3.908-3.183c0.614,0,1.86,0.009,2.483,0.009
	c0.375,0.486,1.118,1.459,1.493,1.946c0.572-0.137,1.707-0.41,2.279-0.538c0.691-2.074-1.895-3.2-2.918-4.668
	c2.176-0.29,4.992,0.265,6.579-1.681c-0.529-2.543-3.319-3.985-3.14-6.75c0.392-0.239,1.169-0.725,1.553-0.973
	c0.785,1.323,1.613,2.637,2.432,3.968c1.306,0.375,2.628,0.777,3.985,1.169c-0.811-1.929-1.579-3.866-2.424-5.76
	c0.205-0.358,0.614-1.084,0.819-1.442c-0.554,0.043-1.664,0.128-2.21,0.179c0.836-0.58,1.724-1.152,2.62-1.724
	c0.853,2.765,2.022,5.513,4.275,7.45c0.273-2.389-0.094-5.086,1.673-7.014c-0.06,0.666-0.171,2.014-0.23,2.688
	c3.84-1.22,3.925-5.914,4.343-9.259c1.383,1.28,3.038,2.125,4.941,2.423c0.401-3.994-2.313-6.963-5.59-8.704
	c0.589-0.154,1.767-0.452,2.355-0.606c-0.444-0.802-0.879-1.596-1.288-2.372c0.213-0.358,0.657-1.075,0.87-1.434
	c-0.725-1.263-1.434-2.526-2.056-3.806c1.067,0.708,2.167,1.434,3.285,2.142c0.555-2.108-1.212-3.729-2.167-5.393
	c1.067,0.06,2.167,0.119,3.294,0.188c-0.128-0.546-0.393-1.63-0.529-2.176c0.998,0.58,2.022,1.161,3.063,1.749
	c0.077-0.546,0.222-1.647,0.299-2.193c0.606,0.623,1.203,1.348,2.039,1.783c0.316-0.734,0.666-1.476,1.033-2.219
	c-0.546-1.34-1.562-2.765-0.469-4.13c0.811,1.084,1.707,2.167,2.603,3.234c-0.29-1.348-0.529-2.679-0.811-4.011
	c0.521,0.111,1.57,0.324,2.099,0.435c0.239-0.606,0.708-1.826,0.939-2.432c0.154,3.029,0.563,6.042,1.237,8.994
	c1.417-0.358,2.833-0.708,4.301-1.033c-0.333,0.87-0.649,1.741-0.93,2.611c0.367,0.256,1.109,0.759,1.476,1.015
	c0.487-2.807,0.299-5.956,2.347-8.218c0.692,8.213,0.149,5.516,3.559,7.936c0.649-0.759,1.323-1.527,2.022-2.295
	c0.581,0,1.724-0.009,2.304-0.017c1.553-1.852,2.645-4.053,2.918-6.485c-0.768-0.563-1.51-1.118-2.227-1.673
	c-1.331-0.538-2.62-1.092-3.883-1.69c0.708-0.154,2.116-0.469,2.816-0.623c-1.775-1.621-3.644-3.149-5.171-4.983
	c1.741,0.273,3.499,0.631,5.265,0.964c-0.922-2.756-3.891-3.661-5.999-5.257c2.338,0.213,4.668,0.674,7.006,1.109
	c-0.444-0.521-1.323-1.545-1.758-2.065c1.178,0.375,2.406,0.777,3.644,1.169c0.026-0.606,0.068-1.826,0.085-2.432
	c-2.014-1.715-4.113-3.311-6.195-4.907c2.244-1.024,4.42-2.219,6.537-3.507c-1.485-1.178-3.012-2.295-4.565-3.337
	c0.264-0.495,0.785-1.493,1.05-1.988c1.067-0.026,2.167-0.06,3.294-0.094c0.648,1.101,1.297,2.21,1.98,3.319
	c0.947,0.034,1.929,0.068,2.944,0.102c-1.707-3.652-1.766-7.714-2.398-11.614c1.69,1.732,2.586,3.977,2.688,6.391
	c0.913,0.085,1.86,0.162,2.833,0.247c0.896,0.64,1.817,1.271,2.782,1.894c0.427,0.947,0.862,1.894,1.348,2.85
	c0.981,0.333,1.997,0.708,3.081,0.956c1.143-0.777,2.048-1.86,3.089-2.825c-0.742-2.466-1.724-5.026-0.896-7.595
	c-0.282,0.35-0.854,1.058-1.135,1.417c-0.162-1.169-0.282-2.33-0.358-3.49c-1.101-1.024-2.159-2.048-3.208-3.072
	c-0.06-0.478-0.171-1.434-0.23-1.911c-0.631-0.811-1.229-1.621-1.792-2.441c2.338,0.785,4.369,3.328,7.006,2.611
	c-0.845-2.432-3.055-3.951-4.642-5.837c1.05,0.307,2.125,0.631,3.226,0.956c-0.162-0.887-0.299-1.775-0.401-2.654
	c-1.186-0.922-2.193-2.014-2.944-3.277c1.681,0.623,3.362,1.331,5.077,1.997c-0.708-0.862-1.391-1.707-2.057-2.551
	c0.777-0.41,1.587-0.811,2.441-1.212c0.819,0.375,1.672,0.759,2.56,1.143c-0.265-1.391-0.563-2.773-0.717-4.173
	c2.492,1.587,3.021,5.094,6.033,6.093c0.034-2.884-0.29-5.751-0.666-8.61c2.483,2.91,2.44,6.912,3.644,10.385
	c0.777,0.393,1.579,0.794,2.424,1.195c0.119-2.338-2.21-5.495,0.042-7.253c0.401,1.553,0.905,3.14,1.997,4.386
	c0.103-1.664,0.196-3.328,0.29-4.992c0.418,0.324,1.237,0.964,1.647,1.28c-0.009,2.202,0,4.403,0.043,6.605
	c0.657-0.026,1.988-0.068,2.654-0.094c-0.375-1.894-0.751-3.78-1.067-5.675c0.393-0.239,1.178-0.717,1.57-0.956
	c0.094,0.461,0.299,1.382,0.393,1.843c4.163-5.217,4.481-3.604,3.226-9.54c0.401,0.256,1.212,0.759,1.613,1.015
	c0.418-0.316,1.246-0.956,1.664-1.28c0.444,2.33,0.913,4.659,1.331,6.997c1.144-0.026,2.338-0.043,3.559-0.06
	C2470.801,24.083,2471.782,20.046,2473.711,16.36 M2473.045,32.36c-1.033,0.393-1.673,2.313-0.35,2.748
	C2474.445,35.466,2475.196,31.711,2473.045,32.36 M2468.676,49.717c-0.06,0.572-0.171,1.732-0.23,2.304
	c0.546-0.137,1.621-0.41,2.167-0.546c0.111-0.512,0.341-1.527,0.461-2.031C2470.468,49.512,2469.273,49.649,2468.676,49.717
	 M2423.202,63.763c0.128,0.58,0.384,1.749,0.521,2.33c0.921,0.077,1.886,0.137,2.893,0.196
	C2426.095,64.693,2424.857,63.831,2423.202,63.763 M2464.648,70.197c-0.009,0.521-0.034,1.579-0.043,2.108
	c0.435-0.034,1.314-0.094,1.75-0.119c0.35-0.717,0.734-1.425,1.144-2.133C2466.509,70.094,2465.562,70.146,2464.648,70.197
	 M2463.095,87.153c2.483-0.137,4.625-2.159,4.719-4.702C2465.596,83.261,2464.06,85.079,2463.095,87.153 M2458.897,94.739
	c3.14-0.29,5.231-3.593,6.357-6.272C2462.472,89.764,2460.279,92.042,2458.897,94.739 M2465.408,93.109
	c-1.033,0.41-1.681,3.149,0.128,2.176C2467.2,95.165,2467.336,92.076,2465.408,93.109 M2409.916,97.58
	c0.025,0.87,0.094,1.741,0.188,2.611c0.478,0.094,1.425,0.273,1.903,0.367c0.017-1.092,0.06-2.176,0.136-3.26
	C2411.58,97.367,2410.47,97.503,2409.916,97.58 M2390.605,98.792c0.64,2.287,2.424,4.079,4.728,4.727
	C2394.888,101.164,2393.114,98.971,2390.605,98.792 M2459.409,98.98c-1.741,0.879-2.757,2.372-2.799,4.335
	c1.954-0.947,4.036-1.971,5.265-3.883C2461.261,99.321,2460.023,99.091,2459.409,98.98 M2458.53,104.97
	c-1.075,0.307-1.852,2.278-0.358,2.56C2460.006,107.991,2460.74,104.194,2458.53,104.97 M2475.648,108.631
	c-0.615,1.237-1.254,2.458-1.954,3.627c0.017,0.538,0.06,1.621,0.077,2.159c1.271-0.299,2.569-0.666,3.874-1.058
	c0.495-0.64,1.024-1.263,1.587-1.886c-0.444-1.391-0.887-2.773-1.399-4.122C2477.073,107.778,2476.348,108.204,2475.648,108.631
	 M2468.565,110.798c-1.297,0.247-1.476,2.355-0.094,2.603C2470.272,113.691,2470.682,110.141,2468.565,110.798 M2420.275,112.155
	c-1.152,0.666-0.538,2.97,0.905,2.108C2422.972,113.538,2421.999,110.227,2420.275,112.155 M2389.333,112.71
	c-0.068,1.075-0.103,2.159-0.119,3.234c0.538-0.051,1.621-0.154,2.159-0.196c-0.094-0.939-0.154-1.869-0.179-2.799
	C2390.733,112.889,2389.803,112.77,2389.333,112.71 M2477.935,114.28c-0.887,1.271-1.007,2.799-1.058,4.292c0.418,0,1.246,0,1.664,0
	c-0.077,1.843,0.102,3.686,0.341,5.521c1.075-0.631,2.099-1.382,3.123-2.185c-1.485-1.903-1.767-4.267-1.656-6.596
	C2479.513,114.971,2478.711,114.63,2477.935,114.28 M2488.2,115.287c0.128,1.894,0.196,3.797,0.162,5.709
	c0.563-0.017,1.69-0.051,2.253-0.068c0.026-2.167-0.017-4.335-0.128-6.494C2489.694,114.715,2488.934,114.997,2488.2,115.287
	 M2428.723,118.001c-0.444,0.998-0.828,1.997-1.203,2.995c0.572,0.009,1.724,0.026,2.295,0.034c0.205-0.939,0.486-1.86,0.76-2.79
	C2430.114,118.18,2429.192,118.06,2428.723,118.001 M2563.336,118.026c-0.017,0.589-0.051,1.766-0.077,2.364
	c1.357-0.649,3.191-0.896,3.703-2.586C2565.717,117.864,2564.514,117.941,2563.336,118.026 M2555.58,122.745
	c-0.008,0.674-0.025,2.005-0.034,2.679c0.648-0.034,1.937-0.094,2.577-0.119c-0.179-0.444-0.529-1.331-0.708-1.766
	C2556.953,123.342,2556.041,122.941,2555.58,122.745 M2379.52,124.776c0.478,1.271,1.433,2.142,2.713,2.654
	c-0.043-0.649-0.119-1.946-0.162-2.603C2381.431,124.819,2380.16,124.785,2379.52,124.776 M2480.469,124.947
	C2479.257,127.575,2483.251,124.955,2480.469,124.947 M2470.323,126.355c-1.271-0.017-1.894,2.295-0.384,2.423
	C2471.083,128.761,2471.697,126.517,2470.323,126.355 M2480.734,128.667c0.879,0.444,1.792,0.444,2.731,0.009
	C2484.301,125.937,2480.307,126.363,2480.734,128.667 M2503.714,126.986c0.742,2.671,1.143,5.427,0.776,8.201
	c1.877,3.533,1.041,7.808,1.647,11.665c0.401-0.239,1.203-0.708,1.604-0.947c0.455-5.46-0.876-5.076-1.783-19.891
	C2505.139,126.269,2504.388,126.594,2503.714,126.986 M2495.667,133.343c1.826-0.418,2.731-1.988,3.541-3.541
	C2497.289,130.007,2495.531,131.193,2495.667,133.343 M2525.141,129.751c-0.102,1.135-0.171,2.27-0.188,3.413
	c0.845-0.393,1.715-0.785,2.611-1.178C2527.061,130.903,2526.242,130.135,2525.141,129.751 M2376.363,131.816
	c-0.819,1.22,1.203,2.705,2.261,1.783C2379.366,132.413,2377.318,131.065,2376.363,131.816 M2372.983,134.53
	c0.051,0.623,0.154,1.869,0.205,2.492c0.597,0.043,1.783,0.119,2.381,0.162C2375.552,135.63,2374.135,135.093,2372.983,134.53
	 M2478.498,138.404c1.997-0.196,3.9-1.493,3.584-3.763C2480.367,135.332,2479.292,136.825,2478.498,138.404 M2488.439,135.05
	c-0.051,0.657-0.154,1.98-0.205,2.645c1.468-0.341,2.27-1.442,2.679-2.85C2490.291,134.897,2489.063,134.999,2488.439,135.05
	 M2386.739,143.592c-0.913,1.289,1.408,2.406,2.295,1.382C2389.914,143.635,2387.567,142.551,2386.739,143.592 M2563.703,149.838
	c0.7,0.085,2.108,0.247,2.816,0.324c-0.017-0.725-0.051-2.167-0.068-2.893C2565.385,147.995,2564.463,148.849,2563.703,149.838
	 M2499.063,151.127c0.648,2.304,0.538,5.555,3.456,6.195c0.085-2.458,0.094-4.915,0.06-7.373
	C2501.316,150.171,2500.13,150.521,2499.063,151.127 M2486.673,151.067c-1.536,0.316-1.468,3.029,0.35,2.415
	C2488.661,153.286,2488.499,150.274,2486.673,151.067 M2554.521,154.6c2.074-0.213,3.456-1.929,4.608-3.507
	C2556.962,150.99,2554.735,152.313,2554.521,154.6 M2568.115,154.182c-1.22,1.681,1.544,2.202,2.782,2.074
	c0.393-1.408,0.811-2.807,1.271-4.207C2570.513,152.228,2568.542,152.202,2568.115,154.182 M2493.474,154.498
	c-1.442,0.427-1.323,3.132,0.452,2.534C2495.479,156.725,2495.206,153.764,2493.474,154.498 M2403.78,155.445
	c-1.007,1.254,1.186,2.697,2.261,1.86C2407.04,156.042,2404.855,154.284,2403.78,155.445 M2564.309,155.419
	c0.034,0.546,0.094,1.638,0.128,2.185c0.546,0.043,1.63,0.137,2.176,0.179c-0.06-0.538-0.188-1.621-0.248-2.167
	C2565.854,155.573,2564.821,155.47,2564.309,155.419 M2304.922,157.348c-0.017,0.503-0.06,1.51-0.077,2.022
	c0.546-0.06,1.63-0.162,2.176-0.213c0-0.469,0-1.408,0.009-1.869C2306.5,157.297,2305.45,157.331,2304.922,157.348
	 M2297.856,164.593c0.017,0.742,0.051,2.227,0.068,2.97c1.144-0.341,1.818-1.289,2.321-2.33
	C2299.648,165.07,2298.453,164.755,2297.856,164.593 M2482.142,169.388c0.06,0.529,0.179,1.579,0.248,2.108
	c1.963-1.741,4.634-3.388,4.958-6.255C2484.941,165.804,2484.122,168.305,2482.142,169.388 M2488.738,169.064
	c1.758-0.759,4.053-1.246,4.591-3.413C2490.974,165.173,2489.139,166.854,2488.738,169.064 M2535.279,166.589
	c-1.323,0.111-1.749,2.21-0.264,2.466C2536.892,169.61,2537.404,166.018,2535.279,166.589 M2516.395,169.525
	c1.058,1.007,2.978-0.725,1.86-1.835C2517.077,166.214,2515.081,168.322,2516.395,169.525 M2508.885,171.615
	c0.999,1.135,2.526-0.085,3.772-0.213c-0.307-0.956,0.102-2.526-1.391-2.406C2510.208,169.602,2509.321,170.498,2508.885,171.615
	 M2540.808,172.375c0.632-0.085,1.886-0.273,2.517-0.358c-0.017-0.546-0.051-1.621-0.077-2.167
	C2541.901,170.19,2540.664,170.754,2540.808,172.375 M2306.458,171.411c-0.273,1.067-0.435,2.142-0.648,3.217
	c0.99,0.119,2.014,0.247,3.063,0.367c0.273-1.067,0.956-2.125,0.691-3.268C2308.514,171.487,2307.473,171.385,2306.458,171.411
	 M2495.283,171.462c-0.077,0.614-0.23,1.86-0.298,2.475c0.691-0.026,2.082-0.077,2.773-0.102
	C2497.246,172.716,2496.316,171.991,2495.283,171.462 M2568.943,175.532c0.674,0.026,2.022,0.094,2.697,0.119
	c-0.026-0.683-0.085-2.048-0.111-2.731C2570.206,173.314,2569.361,174.269,2568.943,175.532 M2304.99,176.053
	c-0.026,0.597-0.085,1.775-0.119,2.364c1.34-0.23,2.21-1.075,2.509-2.432C2306.782,176.002,2305.587,176.036,2304.99,176.053
	 M2416.136,178.459c1.783-0.017,3.55-0.691,3.814-2.697C2418.398,176.249,2416.247,176.377,2416.136,178.459 M2520.934,175.976
	c-0.128,0.563-0.384,1.69-0.512,2.253c1.22,0.102,2.475,0.196,3.755,0.299C2524.194,176.59,2522.3,176.471,2520.934,175.976
	 M2282.752,176.701c-1.476,0.324-1.101,2.731,0.435,2.313C2284.604,178.681,2284.433,175.942,2282.752,176.701 M2539.597,176.881
	c-1.118,1.246,1.169,2.133,1.869,0.973C2542.652,176.607,2540.228,175.797,2539.597,176.881 M2549.692,176.915
	c0.034,0.572,0.111,1.724,0.145,2.304c1.169-0.495,2.406-1.015,2.44-2.517C2551.629,176.753,2550.332,176.855,2549.692,176.915
	 M2567.868,177.009c-1.067,1.195,1.05,2.833,2.167,1.869C2571.153,177.606,2568.951,175.976,2567.868,177.009 M2480.956,180.806
	c0.699-0.009,2.099-0.026,2.79-0.034c-0.042-0.657-0.128-1.971-0.17-2.637C2482.44,178.775,2481.254,179.449,2480.956,180.806
	 M2557.764,180.959c1.971,0.171,3.942-0.503,3.942-2.85C2559.94,178.417,2558.293,179.116,2557.764,180.959 M2541.082,179.483
	c0.051,0.555,0.162,1.664,0.213,2.219c0.947-0.546,1.8-1.254,2.517-2.125C2543.129,179.551,2541.764,179.5,2541.082,179.483
	 M2269.747,180.132c0.025,0.563,0.085,1.681,0.119,2.244c0.606,0.06,1.817,0.179,2.423,0.239c-0.145-0.512-0.435-1.527-0.58-2.031
	C2271.215,180.473,2270.233,180.243,2269.747,180.132 M2517.163,179.876c0.017,1.792,0.051,3.593,0.111,5.393
	c1.715-0.811,3.439-1.621,5.222-2.364C2521.353,180.977,2519.467,179.705,2517.163,179.876 M2307.021,183.17
	c0.632,0.026,1.886,0.085,2.517,0.119c-0.009-0.631-0.017-1.894-0.026-2.526C2308.344,181.207,2307.49,182.035,2307.021,183.17
	 M2554.794,182.035C2553.395,184.595,2557.568,182.214,2554.794,182.035 M2474.906,183.042c-0.043,0.444-0.136,1.331-0.179,1.775
	c0.529,0.077,1.596,0.23,2.125,0.307c0.009-0.589,0.026-1.766,0.034-2.355C2476.39,182.837,2475.401,182.973,2474.906,183.042
	 M2532.25,182.666c-0.316,2.594,3.652,2.261,3.968,0.085C2534.869,182.709,2533.547,182.675,2532.25,182.666 M2545.169,184.373
	c0.085,0.538,0.247,1.621,0.324,2.159c0.487-0.009,1.468-0.034,1.963-0.043c0.051-0.503,0.145-1.51,0.196-2.005
	C2547.029,184.458,2545.792,184.398,2545.169,184.373 M2522.803,189.22c0.623-0.043,1.861-0.137,2.475-0.179
	c0.06-0.614,0.171-1.826,0.23-2.432C2524.442,187.317,2523.452,188.127,2522.803,189.22 M2414.959,189.911
	c-0.051,0.452-0.145,1.365-0.196,1.826c0.537-0.068,1.621-0.205,2.159-0.265c0.111-0.478,0.341-1.425,0.452-1.903
	C2416.768,189.655,2415.565,189.826,2414.959,189.911 M2309.879,191.49c0.068,0.802,0.35,1.545,0.845,2.236
	c0.452-0.111,1.348-0.333,1.801-0.444c0.009-0.93,0.051-1.86,0.128-2.79C2311.706,190.807,2310.733,191.114,2309.879,191.49
	 M2527.394,192.957c-1.152,0.828,0.077,3.106,1.374,2.15C2529.911,194.254,2528.666,191.985,2527.394,192.957 M2506.3,193.87
	c-1.024,1.271,1.237,2.466,2.15,1.399C2509.423,193.998,2507.11,192.744,2506.3,193.87 M2518.374,193.913
	c-1.152,1.271,1.186,2.406,2.022,1.237C2521.549,193.811,2519.142,192.625,2518.374,193.913 M2464.316,196.081
	c0.034,0.614,0.102,1.843,0.145,2.458c0.563,0.009,1.69,0.017,2.253,0.026C2466.227,197.42,2465.672,196.243,2464.316,196.081
	 M2515.123,196.345c0.58,1.024,1.067,2.534,2.577,2.381c0.068-0.563,0.205-1.69,0.281-2.253
	C2517.265,196.439,2515.84,196.379,2515.123,196.345 M2317.235,200.706c1.144-0.461,2.33-0.93,3.533-1.391
	c0-0.606,0.017-1.818,0.017-2.423C2319.274,197.855,2315.989,198.257,2317.235,200.706 M2447.385,197.343
	c-0.034,0.546-0.094,1.647-0.12,2.202c0.461-0.034,1.383-0.111,1.844-0.145c-0.017-0.435-0.051-1.297-0.06-1.724
	C2448.631,197.591,2447.795,197.42,2447.385,197.343 M2527.838,197.164c-0.367,0.674-0.691,1.357-0.973,2.039
	c1.348,0.358,2.739,0.734,4.139,1.143c0.418-0.572,0.896-1.143,1.399-1.707C2530.833,198.171,2529.34,197.668,2527.838,197.164
	 M2533.461,198.154c-1.024,1.289,1.28,2.569,2.142,1.357C2536.482,198.231,2534.263,197.019,2533.461,198.154 M2541.099,198.965
	c-0.435,0.845-0.828,1.69-1.186,2.534c1.664,0.307,3.132-0.393,3.61-2.082C2542.916,199.306,2541.705,199.084,2541.099,198.965
	 M2546.765,198.658c0.085,1.647,1.169,2.475,2.722,2.714C2549.939,199.451,2548.113,199.135,2546.765,198.658 M2513.34,200.953
	c0.563,0.06,1.69,0.162,2.253,0.222c0.06,0.649,0.17,1.946,0.23,2.594c0.683-0.29,1.391-0.597,2.125-0.913
	c-0.196-1.365-1.135-2.458-1.672-3.669C2515.174,199.656,2514.202,200.202,2513.34,200.953 M2425.907,200.509
	c-0.572,1.519,0.981,2.21,1.852,3.132C2430.25,202.523,2427.964,199.178,2425.907,200.509 M2317.534,201.55
	c0.102,0.58,0.324,1.732,0.435,2.313c0.99-0.614,1.86-1.365,2.372-2.475C2319.642,201.431,2318.233,201.508,2317.534,201.55
	 M2441.114,201.431c0.077,0.683,0.213,2.039,0.281,2.722c1.007-0.649,1.997-1.391,2.654-2.466
	C2443.315,201.627,2441.847,201.499,2441.114,201.431 M2543.761,201.593c-1.024,1.331,1.365,2.33,2.219,1.263
	C2546.944,201.516,2544.521,200.458,2543.761,201.593 M2311.569,204.622c0.58,0.017,1.741,0.051,2.321,0.068
	c-0.043-0.666-0.111-1.988-0.154-2.654C2312.081,202.02,2311.594,203.172,2311.569,204.622 M2419.524,202.233
	c0.64,1.707,2.27,2.415,3.968,2.756C2423.381,202.771,2421.478,201.909,2419.524,202.233 M2539.639,203.231
	c-0.862,1.186,1.084,2.697,2.185,1.971C2542.925,203.94,2540.766,201.969,2539.639,203.231 M2455.731,203.923
	c-0.359,0.708-0.683,1.425-0.981,2.15c1.604-0.521,4.471,0.341,4.574-2.074C2458.095,203.965,2456.9,203.94,2455.731,203.923
	 M2546.381,204.085c-1.237,1.092,0.759,3.123,1.877,1.92C2549.308,204.861,2547.524,202.847,2546.381,204.085 M2567.543,204.29
	c-1.144,1.382,1.476,2.176,2.287,1.143C2570.812,204.051,2568.26,203.206,2567.543,204.29 M2553.634,205.006
	c0,1.391,1.101,1.775,2.278,2.039c0.009-0.469,0.034-1.417,0.051-1.894C2555.384,205.117,2554.223,205.041,2553.634,205.006
	 M2542.916,205.791c-0.393,1.664,0.281,2.449,2.031,2.364c-0.768,0.589-1.553,1.143-2.219,1.792c-0.162,1.323,1.007,1.22,1.92,1.399
	c0.393-1.212,0.836-2.432,1.485-3.558C2546.014,206.133,2544.051,206.303,2542.916,205.791 M2561.877,207.259
	c0.76,0.708,2.739-0.111,2.202-1.271C2563.268,205.467,2561.476,206.227,2561.877,207.259 M2263.706,206.858
	c-1.263,1.246,0.862,3.354,2.082,1.971C2266.624,207.694,2264.729,206.184,2263.706,206.858 M2269.022,207.669
	c-1.468,1.178,0.606,3.234,1.775,1.749C2272,208.317,2270.08,206.542,2269.022,207.669 M2509.858,207.507
	c-1.058,1.289,1.169,2.492,2.005,1.246C2512.913,207.438,2510.583,206.099,2509.858,207.507 M2454.306,208.471
	c-1.169,1.314,1.152,2.483,1.988,1.212C2457.438,208.394,2455.049,207.191,2454.306,208.471 M2512.998,209.213
	c-0.469,1.391,0.009,1.86,1.425,1.399C2514.824,209.213,2514.347,208.744,2512.998,209.213 M2417.758,209.973
	c0.516,1,0.901,1.237,5.367,4.335c0.734-2.372-2.014-2.56-2.953-4.122C2419.575,210.126,2418.364,210.024,2417.758,209.973
	 M2468.668,214.069c1.519-0.725,2.876-1.741,4.07-2.97C2470.699,209.623,2468.01,211.705,2468.668,214.069 M2480.93,214.257
	c2.091,0.299,3.499-0.836,3.968-2.85C2483.294,211.944,2481.928,212.925,2480.93,214.257 M2533.495,211.842
	c0.068,0.478,0.205,1.434,0.273,1.911c0.495,0.077,1.493,0.23,1.988,0.307c0.009-0.614,0.026-1.826,0.034-2.432
	C2535.219,211.679,2534.067,211.782,2533.495,211.842 M2538.402,214.077c0.128,0.623,0.392,1.869,0.529,2.5
	c0.615-0.341,1.246-0.691,1.903-1.058C2541.124,214.043,2539.153,214.504,2538.402,214.077 M2462.72,215.084
	c-2.287,1.28,1.101,2.85,2.125,1.297C2465.732,215.101,2463.513,214.385,2462.72,215.084 M2482.15,217.491
	c0.922,0.64,1.988,0.282,2.995-0.009c0.102-0.666,0.316-1.997,0.418-2.654C2483.993,214.939,2481.903,215.502,2482.15,217.491
	 M2492.049,218.361c-0.12,0.683-0.367,2.065-0.486,2.756c0.759-0.35,1.544-0.7,2.355-1.05c-0.401-0.375-1.195-1.135-1.596-1.519
	c1.152-0.998,1.621-2.202,0.691-3.516C2491.682,215.793,2489.003,217.832,2492.049,218.361 M2557.406,215.409
	c-0.051,0.589-0.162,1.766-0.213,2.355c1.374-0.521,3.652-0.478,3.584-2.526C2559.625,215.289,2558.498,215.349,2557.406,215.409
	 M2566.921,217.013c-1.374,1.143,0.427,2.799,1.647,1.69C2570.385,217.747,2568.047,215.588,2566.921,217.013 M2496,218.506
	c-0.077,1.178-0.128,2.364-0.154,3.55c1.007-0.196,2.082-0.435,3.08-0.896c-0.836-0.981-0.99-2.227-1.024-3.447
	C2497.237,217.977,2496.606,218.242,2496,218.506 M2519.202,219.317c-0.512,1.084,1.348,1.818,2.125,1.186
	C2521.822,219.394,2519.902,218.54,2519.202,219.317 M2462.558,225.333c2.458-0.478,4.045-3.302,5.342-5.333
	C2465.519,220.793,2462.097,222.261,2462.558,225.333 M2474.718,224.659c2.756-0.879,6.528-1.527,7.697-4.599
	C2479.642,220.998,2475.69,221.459,2474.718,224.659 M2522.76,222.474c1.519,0.102,3.072,0.171,4.651,0.247
	c-0.06-0.657-0.188-1.954-0.256-2.603C2525.295,219.991,2523.571,220.767,2522.76,222.474 M2547.115,220.213
	c0.026,0.521,0.085,1.57,0.12,2.099c1.86,0.461,3.558,0.222,4.207-1.809C2549.965,220.383,2548.523,220.281,2547.115,220.213
	 M2528.572,224.898l-0.708,0.282c0.094,2.065,1.707,1.408,3.004,0.845C2531.097,224.275,2529.732,224.411,2528.572,224.898
	 M2316.851,225.461c-0.009,0.614-0.009,1.852-0.017,2.475c1.033-0.657,2.014-1.408,2.679-2.492
	C2318.848,225.452,2317.517,225.452,2316.851,225.461 M2462.703,226.331c-0.034,0.649-0.111,1.937-0.154,2.577
	c1.553-0.154,2.304-1.459,2.807-2.799C2464.691,226.161,2463.368,226.28,2462.703,226.331 M2487.467,227.406
	c-1.323,1.169,0.768,3.183,1.997,2.065C2490.18,228.379,2488.602,226.399,2487.467,227.406 M2455.449,231.101
	c0.64-0.043,1.92-0.128,2.56-0.171c0.648-0.964,1.357-1.911,1.971-2.935C2457.89,226.818,2456.593,229.813,2455.449,231.101
	 M2320.887,228.669c0.026,0.529,0.085,1.579,0.111,2.108c1.186-0.196,2.33-0.393,3.584-0.597
	C2324.087,228.473,2322.185,228.934,2320.887,228.669 M2483.968,231.127c0.572,0.708,1.212,1.408,1.895,2.091
	c0.06-1.451,1.459-3.089,0.486-4.437C2484.668,227.901,2484.565,230.171,2483.968,231.127 M2558.088,228.422
	c0.051,0.708,0.145,2.125,0.188,2.833c0.632-0.068,1.886-0.205,2.509-0.273C2560.145,229.838,2559.513,228.575,2558.088,228.422
	 M2403.14,229.582c-0.768,1.109,1.186,2.295,2.065,1.434C2406.059,229.821,2403.968,228.721,2403.14,229.582 M2521.566,230.683
	c-1.203,1.382,1.323,2.338,2.099,1.092C2524.808,230.41,2522.257,229.497,2521.566,230.683 M2521.975,236.58
	c2.381-0.478,5.444-1.749,5.581-4.599C2524.97,232.467,2522.854,234.088,2521.975,236.58 M2406.34,233.32
	c-0.87,1.067,0.956,2.492,1.92,1.638C2409.096,233.917,2407.228,232.603,2406.34,233.32 M2506.325,233.073
	c-1.101,0.213-1.963,2.219-0.461,2.5C2507.682,235.974,2508.689,232.313,2506.325,233.073 M2452.454,234.95
	c-1.16,1.152,0.708,3.191,1.997,2.253C2455.228,236.119,2453.649,234.028,2452.454,234.95 M2464.879,234.668
	c-0.23,0.666-0.691,1.997-0.93,2.662c0.845,0.811,1.758,1.545,2.935,1.929C2466.918,237.407,2466.526,235.658,2464.879,234.668
	 M2546.295,238.141c-0.973,1.118,0.836,3.123,2.031,2.176C2549.248,239.174,2547.499,237.092,2546.295,238.141 M2342.553,238.961
	c-0.119,0.631-0.358,1.886-0.478,2.509c1.109,0.247,2.253,0.486,3.422,0.725c0.043-0.614,0.137-1.843,0.179-2.458
	C2344.602,239.464,2343.569,239.208,2342.553,238.961 M2351.804,239.165c-1.357,1.118,0.648,3.465,1.63,1.647
	C2354.73,239.575,2352.998,237.749,2351.804,239.165 M2493.082,242.502c1.468-0.324,2.705-1.186,3.618-2.398
	C2494.703,239.532,2493.047,240.232,2493.082,242.502 M2542.908,240.778c-1.024,1.229,1.144,2.705,2.04,1.451
	C2545.886,240.991,2543.752,239.635,2542.908,240.778 M2441.523,241.606c-1.058,0.265-1.946,2.219-0.469,2.534
	C2442.837,244.55,2443.861,240.718,2441.523,241.606 M2444.689,241.674c-1.869,0.23-1.178,3.072,0.597,2.432
	C2446.814,243.782,2446.199,241.23,2444.689,241.674 M2478.746,242.161c0.026,0.717,0.068,2.133,0.094,2.842
	c0.751-0.725,2.415-0.956,2.27-2.304C2480.521,242.562,2479.334,242.297,2478.746,242.161 M2334.729,243.441
	c-1.271,0.794-0.094,3.2,1.254,2.108C2337.092,244.738,2336.009,242.399,2334.729,243.441 M2328.533,244.106
	c0.128,0.597,0.384,1.792,0.512,2.389c1.05-0.444,1.758-1.289,2.295-2.295C2330.641,244.174,2329.233,244.132,2328.533,244.106
	 M2444.8,244.968c-0.469,1.365,0,1.869,1.417,1.519C2446.635,245.105,2446.165,244.601,2444.8,244.968 M2321.801,249.815
	c0.751-0.188,1.527-0.375,2.338-0.572c-0.034-1.263-0.034-2.526-0.009-3.789C2321.894,245.659,2322.244,248.228,2321.801,249.815
	 M2373.82,247.135c0.017,1.314,0.094,2.637,0.222,3.951c0.956-0.58,1.638-1.442,1.843-2.577
	C2375.279,247.886,2374.596,247.426,2373.82,247.135 M2443.682,247.878c0.623,0.794,1.22,1.57,1.911,2.355
	c0.913-0.546,1.937-1.067,2.773-1.792C2446.823,248.014,2445.227,247.929,2443.682,247.878 M2270.865,248.561
	c-1.519,0.29-1.57,3.132,0.307,2.381C2272.742,250.677,2272.742,247.665,2270.865,248.561 M2465.997,249.977
	c-0.017,0.495-0.043,1.485-0.051,1.98c1.28-0.282,3.029-0.145,3.627-1.673C2468.352,250.165,2467.157,250.062,2465.997,249.977
	 M2496.119,251.052c-1.092-0.111-2.637,1.058-1.783,2.167C2495.599,254.67,2498.099,251.675,2496.119,251.052 M2507.162,250.737
	c-0.017,1.271,0.009,2.543,0.094,3.823C2508.868,253.518,2509.414,251.377,2507.162,250.737 M2480.512,251.863
	c0.034,0.64,0.102,1.911,0.145,2.543c0.93-0.657,1.809-1.417,2.543-2.33C2482.526,252.025,2481.186,251.914,2480.512,251.863
	 M2509.833,251.974c-1.126,0.828-0.051,3.166,1.305,2.185C2512.196,253.339,2511.112,250.89,2509.833,251.974 M2477.201,254.577
	c0.998,0.623,3.081-0.614,2.244-1.843C2478.362,251.786,2476.433,253.416,2477.201,254.577 M2500.275,256.01
	c1.152-0.879,3.738-1.306,2.961-3.243C2501.282,251.94,2500.07,254.38,2500.275,256.01 M2548.821,252.767
	c-1.126,1.323,1.288,2.458,2.142,1.289C2551.953,252.759,2549.598,251.675,2548.821,252.767 M2447.804,255.891
	c1.015-0.154,2.065-0.307,3.149-0.461c0.051-0.512,0.154-1.527,0.205-2.039C2449.579,253.629,2448.128,254.167,2447.804,255.891
	 M2460.279,255.02c1.161,0.247,1.596-0.222,1.314-1.408C2460.382,253.373,2459.947,253.843,2460.279,255.02 M2269.764,254.38
	c-0.017,0.623-0.068,1.86-0.094,2.483c0.572-0.068,1.724-0.213,2.295-0.282c0.06-0.529,0.171-1.579,0.23-2.108
	C2271.59,254.449,2270.379,254.406,2269.764,254.38 M2496.683,257.563c0.828-0.29,1.673-0.58,2.552-0.862
	c-0.085-0.623-0.248-1.869-0.324-2.492C2497.177,254.611,2496.401,255.814,2496.683,257.563 M2492.194,258.434
	c1.229,0.119,2.458-0.205,3.593-0.794c-0.077-0.683-0.222-2.031-0.299-2.714C2493.858,255.532,2492.928,256.966,2492.194,258.434
	 M2504.883,255.711c-0.068,0.401-0.196,1.22-0.264,1.63c0.538,0.051,1.604,0.162,2.142,0.222c0.034-0.623,0.111-1.869,0.145-2.492
	C2506.402,255.225,2505.386,255.549,2504.883,255.711 M2513.963,256.386c-0.777,2.927,2.569,4.454,3.968,6.639
	c0.486-1.067,1.041-2.125,1.587-3.183c-0.999-0.222-1.971-0.444-2.918-0.657c-0.171-0.555-0.512-1.664-0.674-2.219
	C2515.243,256.77,2514.585,256.573,2513.963,256.386 M2548.454,259.586c1.493-0.333,3.029-0.751,4.531-1.212
	c-0.41-0.64-0.785-1.28-1.135-1.911C2550.494,257.29,2548.446,257.631,2548.454,259.586 M2472.345,257.128
	c-1.007,1.22,1.092,2.594,1.989,1.365C2475.392,257.222,2473.139,255.805,2472.345,257.128 M2482.253,257.026
	c-1.305,0.179-1.69,2.321-0.213,2.569C2483.917,260.072,2484.25,256.599,2482.253,257.026 M2500.309,256.906
	c-1.246,0.265-1.656,2.825,0.102,2.159C2502.084,259.074,2502.195,256.087,2500.309,256.906 M2448.998,261.838
	c2.219-0.742,3.618-2.748,5.035-4.514C2450.987,256.676,2449.766,259.551,2448.998,261.838 M2468.437,257.666
	c-0.751,1.596-2.603,1.843-3.917,2.688c1.664-0.154,3.422-0.384,5.069-0.913C2469.299,258.758,2468.915,258.169,2468.437,257.666
	 M2524.399,257.631c-0.111,0.666-0.324,1.988-0.435,2.654c0.666-0.128,1.988-0.401,2.654-0.538
	C2525.978,258.92,2525.218,258.212,2524.399,257.631 M2506.556,259.509c1.007,2.492,5.862,5.513,2.253,7.885
	c0.307,0.213,0.922,0.64,1.229,0.845c2.321-1.647,6.033-1.766,7.339-4.565c-1.485,0.341-3.567,0.017-3.78,2.039
	c-0.674-0.085-2.022-0.265-2.697-0.358c1.707-1.323,0.939-3.098-0.845-3.797c-0.009-0.708-0.026-2.108-0.034-2.807
	C2508.8,258.724,2507.631,259.125,2506.556,259.509 M2251.588,259.133c0.99,1.988-0.683,3.285-1.809,4.659
	c2.091,0.341,3.84-0.836,3.866-3.072c1.877,1.254,3.43,3.089,5.555,3.977c1.007-0.247,2.364-1.374,1.826-2.569
	C2258.142,260.294,2254.438,261.011,2251.588,259.133 M2451.268,262.478c0.503-0.06,1.502-0.188,1.997-0.256
	c0.555-1.041,1.126-2.082,1.732-3.132C2453.291,259.662,2452.113,260.977,2451.268,262.478 M2456.627,261.557
	c0.708,0.759,1.442,1.51,2.219,2.27c0.384-0.896,0.802-1.792,1.246-2.679C2458.837,260.081,2457.463,260.072,2456.627,261.557
	 M2474.871,264.356c0.487-0.947,0.538-1.903,0.179-2.884C2472.857,260.456,2472.627,265.525,2474.871,264.356 M2487.398,261.113
	c-0.247,0.631-0.452,1.263-0.623,1.903c0.657,0.828,1.289,1.673,1.988,2.526c0.196-0.393,0.589-1.178,0.793-1.57l-1.578,0.162
	c0.699-0.99,1.416-1.98,2.159-2.97C2489.446,261.156,2488.081,261.13,2487.398,261.113 M2435.797,264.484
	c0.64-0.034,1.929-0.119,2.577-0.154c0.06-0.674,0.188-2.022,0.248-2.697C2437.128,261.983,2436.412,263.246,2435.797,264.484
	 M2476.151,265.9c1.459-0.777,2.808-1.741,4.258-2.586c-0.017-0.478-0.06-1.442-0.077-1.929
	C2478.49,262.436,2477.03,263.997,2476.151,265.9 M2462.413,262.589c-0.025,1.152-0.017,2.304,0.034,3.465
	c0.964-0.358,1.963-0.717,2.987-1.075C2464.657,263.903,2463.462,263.272,2462.413,262.589 M2498.517,262.931
	c0.025,0.691,0.077,2.065,0.102,2.748c0.973-0.666,1.792-1.553,2.398-2.594C2500.395,263.042,2499.14,262.973,2498.517,262.931
	 M2519.27,264.671c0.649,0.99,1.724,1.374,2.842,1.758c0.444-0.324,1.331-0.973,1.775-1.297c-0.009-0.606-0.043-1.818-0.06-2.423
	C2522.283,263.34,2520.747,263.989,2519.27,264.671 M2483.354,263.801c-1.28,0.495-1.374,3.132,0.435,2.5
	C2485.393,266.045,2485.128,262.82,2483.354,263.801 M2527.693,264.151c-1.8,2.893,2.816,1.997,2.944-0.137
	C2529.57,263.485,2528.589,263.528,2527.693,264.151 M2542.635,263.775c-0.222,2.748,2.594,2.483,4.497,2.295
	C2546.893,263.639,2544.409,263.997,2542.635,263.775 M2255.659,265.602c-0.76,0.973-1.528,1.937-2.279,2.884
	c1.118,1.058,2.321,2.065,3.968,2.167c0.742-0.614,1.502-1.246,2.261-1.894c-0.299-1.28-0.717-2.517-1.041-3.772
	C2257.562,265.192,2256.597,265.397,2255.659,265.602 M2562.756,266.557c-0.043,0.478-0.119,1.417-0.162,1.886
	c0.529-0.026,1.579-0.077,2.108-0.102c-0.017-0.469-0.043-1.408-0.06-1.877C2564.173,266.489,2563.226,266.532,2562.756,266.557
	 M2480.486,267.325c-0.461,1.382,0.026,1.826,1.459,1.34C2482.338,267.266,2481.852,266.822,2480.486,267.325 M2498.517,268.153
	c-1.126,1.314,1.237,2.5,2.065,1.263C2501.538,268.119,2499.268,266.899,2498.517,268.153 M2341.47,268.887
	c0.034,0.546,0.094,1.63,0.128,2.176c0.546,0.026,1.655,0.085,2.21,0.111c-0.042-0.503-0.136-1.519-0.179-2.022
	C2343.091,269.083,2342.007,268.955,2341.47,268.887 M2466.099,268.938c-1.195,0.734-0.341,3.319,1.084,2.167
	C2468.326,270.38,2467.456,267.846,2466.099,268.938 M2478.14,270.995c1.007,0.503,2.074-1.22,1.374-2.031
	C2478.43,268.435,2477.5,270.193,2478.14,270.995 M2267.264,269.809C2267.733,270.303,2267.733,270.303,2267.264,269.809
	 M2486.98,271.02c1.144,0.666,2.364,1.323,3.584,1.929c-0.264-1.382-0.845-2.671-1.544-3.857
	C2488.311,269.723,2487.629,270.372,2486.98,271.02 M2260.062,273.495c0.785,0.179,1.604,0.358,2.449,0.546
	c0.29,1.34,1.28,2.193,2.535,2.688c0.384-1.178,0.862-2.347,1.305-3.516c-0.495,0.205-1.502,0.614-1.997,0.819
	c-0.136-1.459,0.375-2.611,1.519-3.439C2263.501,270.252,2261.931,272.479,2260.062,273.495 M2257.152,271.549
	c-0.154,1.161,2.645,1.937,3.038,0.589C2259.763,270.986,2258.005,271.037,2257.152,271.549 M2278.374,271.515
	c-0.051,0.666-0.154,1.997-0.205,2.671c1.383-0.171,2.227-1.161,2.569-2.466C2280.149,271.669,2278.963,271.566,2278.374,271.515
	 M2451.166,272.147c0.41,0.862,0.307,2.475,1.647,2.56c0.427-0.734,0.862-1.468,1.305-2.219
	C2453.385,272.403,2451.908,272.232,2451.166,272.147 M2480.93,271.848c-0.734,1.161-1.818,1.741-3.243,1.741
	C2478.524,276.584,2484.668,273.162,2480.93,271.848 M2498.449,272.744c-0.128,0.785-0.119,1.596-0.102,2.389
	c0.725-0.196,1.468-0.418,2.253-0.649c0.026-0.393,0.094-1.178,0.128-1.57C2499.917,272.855,2499.174,272.804,2498.449,272.744
	 M2268.996,274.297c0.487,1.545,1.075,3.183,3.064,3.012c-0.35-1.331-0.743-2.662-1.084-3.985
	C2270.285,273.649,2269.628,273.973,2268.996,274.297 M2520.738,273.384c-0.896,0.205-1.186,1.075-1.562,1.741
	c0.905,0.375,1.869,0.435,2.885,0.162C2522.001,274.604,2521.583,272.932,2520.738,273.384 M2471.876,273.708
	c-0.017,2.133,0.017,4.267,0.213,6.391c2.142-0.887,0.99-3.507,1.305-5.282C2473.011,274.545,2472.252,273.99,2471.876,273.708
	 M2274.099,274.86c-0.026,1.408-0.026,2.825,0,4.241c0.486-0.102,1.459-0.29,1.946-0.393c-0.145-1.331-0.29-2.662-0.35-3.994
	C2275.294,274.758,2274.5,274.826,2274.099,274.86 M2246.989,275.722c-1.007,0.469-1.707,3.191,0.154,2.372
	C2248.55,277.855,2248.772,274.843,2246.989,275.722 M2468.599,280.808c0.964,1.451,1.621,3.055,2.005,4.77
	c0.478-0.017,1.442-0.051,1.92-0.068c-0.529-0.922-1.118-1.835-1.672-2.688c0.051-0.521,0.162-1.57,0.213-2.099
	C2470.451,280.748,2469.214,280.791,2468.599,280.808 M2478.105,280.979c-1.032,1.331,1.365,2.321,2.202,1.229
	C2481.297,280.859,2478.848,279.801,2478.105,280.979 M2485.572,282.131c0.034,0.717,0.102,2.159,0.136,2.884
	c0.614-0.085,1.843-0.256,2.458-0.341C2487.706,283.428,2486.75,282.609,2485.572,282.131 M86.622,285.186
	c0.358,0.7,0.777,1.365,1.246,1.988c1.169-0.196,2.133-0.879,2.697-1.92C89.25,285.203,87.936,285.177,86.622,285.186
	 M2316.51,286.065c0.521,0.845,0.853,1.946,1.843,2.432c1.485,0.077,1.314-1.417,1.604-2.441
	C2318.78,286.039,2317.628,286.047,2316.51,286.065 M2466.79,286.184c-0.793,0.751,0.026,2.731,1.203,2.202
	C2468.506,287.635,2467.84,285.783,2466.79,286.184 M2534.972,288.113c1.024,0.333,2.074,0.879,3.226,0.964
	c1.237-1.399-0.981-2.432-1.835-3.149C2534.98,285.629,2535.287,287.302,2534.972,288.113 M2322.253,289.265
	c0.674-0.068,2.031-0.213,2.714-0.29c-0.119-0.486-0.358-1.451-0.478-1.937C2322.961,286.193,2322.867,288.488,2322.253,289.265
	 M2470.306,289.119c0.009,0.632,0.009,1.894,0.017,2.526c0.606-0.017,1.835-0.068,2.449-0.085
	C2472.226,290.442,2471.706,289.188,2470.306,289.119 M2529.212,290.246c-0.947,1.195,0.973,3.046,2.185,2.142
	C2532.267,291.21,2530.364,289.307,2529.212,290.246 M96.529,295.323C96.674,298.122,99.106,294.018,96.529,295.323
	 M2322.031,295.494c-1.271,1.186,0.947,1.715,1.638,0.811C2325.009,295.246,2322.585,294.555,2322.031,295.494 M2325.427,295.349
	c-0.103,0.606-0.307,1.818-0.41,2.423c1.135,0.009,2.287,0.017,3.473,0.034c0.273-0.41,0.819-1.212,1.092-1.621
	C2328.277,295.485,2326.852,295.255,2325.427,295.349 M2479.804,296.108c-0.068,0.614-0.205,1.835-0.273,2.449
	c0.7,0.239,1.425,0.469,2.193,0.708c0.128-0.58,0.401-1.741,0.538-2.321C2481.417,296.654,2480.597,296.381,2479.804,296.108
	 M90.402,297.064c-0.93,1.237,1.271,2.611,2.159,1.502C93.449,297.311,91.307,296.006,90.402,297.064 M2516.941,297.457
	c0.239,1.178,0.734,2.338,1.289,3.439c-0.657,0.555-1.263,1.126-1.843,1.698c0.631,0.265,1.297,0.521,1.988,0.794
	c0.248-0.307,0.76-0.913,1.007-1.22C2518.434,300.554,2519.065,298.199,2516.941,297.457 M2524.97,297.96
	c-0.427,1.391,0.077,1.826,1.493,1.323C2526.831,297.892,2526.336,297.448,2524.97,297.96 M2258.927,299.513
	c-0.896,0.35-2.552,1.203-1.869,2.355C2258.424,303.003,2261.589,299.121,2258.927,299.513 M2263.228,299.726
	c-1.382,0.452-1.51,3.14,0.375,2.483C2265.173,301.979,2264.977,298.813,2263.228,299.726 M2271.488,300.58
	c0.009,0.597,0.026,1.801,0.034,2.398c1.237-0.375,2.381-0.99,2.663-2.406C2273.51,300.58,2272.162,300.58,2271.488,300.58
	 M99.004,302.269C99.507,302.764,99.507,302.764,99.004,302.269 M94.609,303.089c-1.152,1.152,1.135,1.69,1.766,0.862
	C97.587,302.884,95.215,302.269,94.609,303.089 M89.779,304.59c-0.128,0.657-0.367,1.98-0.495,2.637
	c0.7-0.137,2.091-0.41,2.782-0.546C91.401,305.879,90.624,305.188,89.779,304.59 M2370.961,308.618
	c0.623,0.188,1.852,0.563,2.466,0.751c-0.137-0.29-0.401-0.862-0.538-1.152c0.401-0.469,1.195-1.417,1.596-1.894
	c-0.273-0.486-0.811-1.468-1.084-1.954C2372.215,305.555,2371.243,306.937,2370.961,308.618 M2342.451,305.751
	c-0.947,0.648-0.342,2.833,0.921,1.937c0.828-0.538,1.109-1.22,0.845-2.065C2343.782,305.657,2342.895,305.717,2342.451,305.751
	 M2383.377,313.013c2.04,0.529,3.465-1.758,4.267-3.328C2386.176,310.393,2382.37,310.623,2383.377,313.013 M2401.015,314.071
	c2.202,0.171,2.679-1.809,3.251-3.49C2401.903,310.137,2401.527,312.347,2401.015,314.071 M2448.853,310.751
	c0.103,0.58,0.307,1.732,0.41,2.304c1.058-0.538,2.091-1.152,2.458-2.398C2451.004,310.683,2449.57,310.734,2448.853,310.751
	 M2256.205,311.682C2256.666,312.185,2256.666,312.185,2256.205,311.682 M2345.199,316.17c-1.86,1.212-3.311,2.884-4.702,4.565
	c-0.026,1.178-0.026,2.372,0,3.558c4.736-1.766,6.605-6.972,10.291-9.856c-0.401-0.998-0.785-1.997-1.101-2.987
	C2348.339,313.175,2346.445,314.37,2345.199,316.17 M2358.861,311.511c-0.922,0.7-1.801,1.374-2.714,2.005
	c-1.681,2.62-3.354,5.222-5.188,7.723c0.529,2.244-1.767,3.328-2.082,5.359c0.512-0.06,1.536-0.179,2.048-0.247
	c2.466-2.5,4.258-5.581,6.869-7.979c-0.95,0.166-0.405,0.015-3.089,0.973c1.963-2.671,4.284-5.069,6.255-7.748
	C2360.431,311.571,2359.381,311.537,2358.861,311.511 M2386.722,313.337c-0.495,1.237-0.128,1.604,1.101,1.092
	C2388.301,313.218,2387.934,312.851,2386.722,313.337 M2406.426,313.346c-1.297,0.836,0.145,2.901,1.391,1.98
	C2409.156,314.481,2407.646,312.313,2406.426,313.346 M2332.313,316.691l1.715,0.239l0.444-1.783
	c0.375,0.529,0.768,1.067,1.186,1.613c-0.137,0.734-0.418,2.202-0.555,2.935c-1.775,0.572-3.021,1.98-3.448,3.763
	c3.132-1.007,5.99-3.533,5.709-7.108c-1.05-0.956-2.099-1.911-3.055-2.91C2333.551,314.481,2332.868,315.556,2332.313,316.691
	 M2340.787,314.276c-0.009,0.64-0.017,1.92-0.017,2.56c1.169-0.631,1.963-1.664,2.603-2.842
	C2342.724,314.062,2341.436,314.207,2340.787,314.276 M2392.798,314.02c-0.504,1.399-0.973,2.807-1.357,4.233l0.708-0.043
	c0.776-1.4,1.749-2.688,2.79-3.925C2394.402,314.216,2393.327,314.088,2392.798,314.02 M2363.622,314.899
	c-0.025,0.631-0.085,1.877-0.111,2.5c0.435-0.171,1.297-0.512,1.724-0.683c0.205-0.469,0.623-1.391,0.828-1.86
	C2365.457,314.873,2364.237,314.89,2363.622,314.899 M2468.651,317.288c1.032,1.161,3.405-0.495,2.33-1.801
	C2469.922,314.694,2467.712,316.008,2468.651,317.288 M2405,315.564c-0.777,0.981-1.476,1.997-2.159,3.012l-0.23-1.186l-0.7,0.196
	c0.461,0.913,0.913,1.826,1.425,2.739c-0.136,0.418-0.41,1.237-0.546,1.647C2405.914,321.503,2405.487,317.86,2405,315.564
	 M2438.665,319.131c2.014,0.119,3.524-1.297,3.166-3.371C2440.337,316.443,2439.313,317.672,2438.665,319.131 M2447.411,317.262
	c0.811,0.631,2.765,0.597,3.021-0.666C2450.099,314.771,2446.592,315.505,2447.411,317.262 M2513.877,315.812
	c-1.271,0.068-2.056,2.244-0.393,2.364C2515.319,318.653,2516.198,315.121,2513.877,315.812 M2379.392,318.047
	c0.606,0.111,1.817,0.333,2.432,0.444c-0.956,0.597-1.877,1.195-2.799,1.758c-0.589,1.382-1.015,2.816-1.186,4.301
	c3.866-0.981,5.291-5.385,8.098-7.885C2383.787,316.785,2380.399,315.342,2379.392,318.047 M2298.137,318.431
	c-0.819,1.135,0.333,1.809,1.109,2.466c0.265-0.444,0.794-1.331,1.067-1.775C2300.766,317.774,2298.735,317.621,2298.137,318.431
	 M2425.788,317.911c0.12,0.939,0.521,1.758,1.203,2.449c-0.077,0.606-0.222,1.826-0.299,2.441c1.434-0.128,1.784-1.468,2.278-2.603
	c-0.478-0.043-1.442-0.128-1.92-0.171c0.23-0.768,0.504-1.527,0.819-2.287C2427.349,317.783,2426.308,317.868,2425.788,317.911
	 M2464.947,317.51c0.196,1.195,0.708,2.321,1.348,3.379c0.452-1.126,0.956-2.253,1.459-3.388
	C2467.046,317.51,2465.647,317.51,2464.947,317.51 M2294.707,318.474c-0.094,0.529-0.282,1.579-0.376,2.099
	c0.666-0.009,1.997-0.017,2.654-0.026c-0.068-0.529-0.222-1.587-0.299-2.125C2296.192,318.44,2295.202,318.466,2294.707,318.474
	 M2348.459,320.761c1.152,0.503,2.935-1.212,2.039-2.347C2349.218,317.663,2347.972,319.703,2348.459,320.761 M2373.086,321.922
	l-0.35-1.033l-0.384,0.085c0.213,0.947,0.384,1.894,0.623,2.859c-2.108,1.843-2.995,4.565-3.729,7.159
	c2.304,0.717,4.395-0.512,6.494-1.34c-0.529-0.094-1.596-0.29-2.133-0.393c1.289-1.399,2.492-2.901,3.558-4.497
	c-0.026-1.041-0.026-2.074,0.009-3.098l1.032,0.307c-1.135-1.289-1.109-2.688-0.154-4.053
	C2375.987,318.696,2374.622,320.471,2373.086,321.922 M2363.904,319.353c-1.263,0.717-0.341,3.251,1.067,2.108
	C2366.174,320.727,2365.278,318.193,2363.904,319.353 M2418.108,322.758c1.843-0.333,1.98-2.347,2.577-3.78
	C2418.475,319.097,2417.963,320.881,2418.108,322.758 M2450.824,319.387c-1.229,0.759-0.205,3.26,1.169,2.116
	C2453.103,320.727,2452.147,318.235,2450.824,319.387 M2447.368,320.147c-1.101,1.178,1.05,2.381,1.801,1.143
	C2450.116,320.121,2448.068,319.012,2447.368,320.147 M2273.229,321.213c-0.017,0.597-0.051,1.775-0.068,2.364
	c1.297-0.35,2.355-1.084,2.543-2.534C2275.089,321.085,2273.843,321.171,2273.229,321.213 M2356.25,322.365
	c1.169,0.171,1.587-0.316,1.237-1.468C2356.267,320.718,2355.848,321.213,2356.25,322.365 M2393.523,323.978
	c0.572,0.043,1.715,0.119,2.287,0.154c0.205-1.101,0.469-2.193,0.717-3.294C2394.513,320.676,2394.078,322.485,2393.523,323.978
	 M2405.043,325.446c1.493-1.092,2.833-2.398,4.045-3.823C2406.417,320.462,2405.342,323.355,2405.043,325.446 M2424.567,321.162
	c-0.725,0.717-1.161,2.014-0.666,2.953C2425.344,324.584,2426.069,321.171,2424.567,321.162 M2501.769,322.527
	c-0.452,1.655,1.144,1.621,2.313,1.911c-0.068-1.237-0.111-2.466-0.111-3.695C2503.202,321.316,2502.426,321.887,2501.769,322.527
	 M82.859,322.673c-0.691,1.596,2.816,2.586,3.123,0.828C85.666,322.246,83.797,321.981,82.859,322.673 M2508.749,324.669
	c-0.102,1.007,1.007,0.913,1.698,1.28c0.7-1.169,1.519-2.278,2.347-3.388C2511.104,322.451,2509.739,323.432,2508.749,324.669
	 M2525.005,322.476c-1.613,0.435-0.845,2.833,0.683,2.492C2527.385,324.686,2526.652,321.742,2525.005,322.476 M2262.98,323.406
	c-0.341,2.304-1.28,4.446-1.886,6.682c1.664-0.828,3.354-1.604,5.265-1.775c-0.239,0.572-0.717,1.724-0.956,2.295
	c0.939-0.154,2.816-0.444,3.746-0.597c-0.776-1.041-1.57-2.039-2.33-3.055c1.203-0.077,2.432-0.137,3.687-0.196
	c0-0.41,0.009-1.229,0.009-1.638c-1.109-0.017-2.185-0.026-3.226-0.026c-0.324-0.427-0.973-1.271-1.306-1.698
	C2264.951,323.398,2263.953,323.398,2262.98,323.406 M2513.834,323.423c-0.904,0.572-1.092,1.323-0.546,2.236
	C2515.413,327.468,2516.48,323.005,2513.834,323.423 M2277.154,327.374c0.572-0.068,1.698-0.213,2.27-0.282
	c0-0.845,0.043-1.673,0.12-2.5C2278.289,325.139,2277.291,325.949,2277.154,327.374 M2320.239,324.43
	c-1.22,0.179-1.903,2.278-0.358,2.526C2321.655,327.34,2322.449,323.765,2320.239,324.43 M2537.856,324.465
	c-1.152,0.819-0.043,3.183,1.306,2.167C2540.271,325.813,2539.127,323.458,2537.856,324.465 M2360.499,326.231
	c-0.734,0.401-1.741,2.142-0.452,2.432C2361.327,328.748,2362.359,325.318,2360.499,326.231 M2505.318,326.973
	c-1.289,0.828,0.051,3.012,1.323,1.988C2507.895,328.142,2506.564,325.838,2505.318,326.973 M2513.075,329.73
	c-0.657,1.596,1.391,1.212,2.398,1.391c0.862-1.271,1.288-2.773,1.792-4.224C2515.746,327.647,2513.971,328.211,2513.075,329.73
	 M2330.931,330.225c1.818,0.171,3.251-1.033,3.874-2.688C2333.039,327.741,2331.298,328.279,2330.931,330.225 M2519.091,327.878
	c-0.401,0.708-0.708,1.442-0.922,2.219c1.067,0.572,2.697,0.316,3.311-0.845C2521.318,328.134,2520.132,327.161,2519.091,327.878
	 M2346.957,330.02c-0.478,0.085-1.442,0.239-1.92,0.316c-1.51,1.579-5.299,2.714-4.147,5.461c-0.606,1.69,0.384,3.337,2.236,2.159
	c-0.094-0.631-0.273-1.894-0.367-2.526c2.679-0.956,5.461-1.715,8.354-1.69c0.529-0.324,1.587-0.964,2.116-1.289l0.051-0.307
	c-1.212-0.341-2.347-0.683-3.482-1.05c0.068-0.7,0.213-2.091,0.281-2.782C2349.005,328.876,2347.972,329.439,2346.957,330.02
	 M2354.978,328.757c-0.418,1.417,0.068,1.86,1.468,1.331C2356.804,328.68,2356.309,328.236,2354.978,328.757 M2357.7,329.678
	c-1.075,1.374,1.417,2.304,2.21,1.152C2360.909,329.457,2358.417,328.535,2357.7,329.678 M2464.392,329.405
	c-1.698,0.367-1.246,3.627,0.478,3.012C2465.28,331.641,2466.082,328.919,2464.392,329.405 M74.991,330.438
	C73.737,333.083,77.798,330.489,74.991,330.438 M2315.861,330.455c-0.896,1.195,1.033,3.132,2.219,2.082
	C2319.002,331.317,2316.962,329.593,2315.861,330.455 M2336.648,334.355l-1.621-0.316c2.285,2.539,5.594-1.49,5.7-3.2
	C2339.081,331.334,2335.71,331.846,2336.648,334.355 M88.363,332.537c-0.017,0.427-0.051,1.28-0.077,1.707
	c1.152,0.017,2.295,0.043,3.447,0.06c0.034-0.597,0.102-1.801,0.137-2.398C90.701,332.102,89.532,332.315,88.363,332.537
	 M2532.608,331.726c-0.034,1.596-0.06,3.191-0.034,4.796c0.819-0.563,1.715-1.126,2.594-1.698c-0.503-0.094-1.51-0.273-2.014-0.367
	c0.555-0.768,1.195-1.527,1.775-2.321C2534.349,332.034,2533.188,331.829,2532.608,331.726 M2259.567,333.083
	c-0.009,0.555-0.034,1.673-0.051,2.227c1.621-0.051,3.49-0.034,4.053-1.903C2262.212,333.288,2260.873,333.177,2259.567,333.083
	 M2536.969,332.785c-1.451,0.358-1.331,3.072,0.401,2.475C2538.982,334.969,2538.803,331.982,2536.969,332.785 M2272.554,336.761
	c0.657,0.41,1.365,0.794,2.116,1.143c-0.034-1.314-0.281-2.586-0.956-3.703C2273.203,334.926,2271.445,335.797,2272.554,336.761
	 M2328.184,335.925c1.186,0.077,2.406,0.145,3.66,0.205c0.017-0.521,0.06-1.553,0.077-2.074
	C2330.53,334.466,2328.806,334.389,2328.184,335.925 M2360.277,334.67c-1.084,1.348,1.399,2.261,2.219,1.186
	C2363.571,334.5,2361.011,333.553,2360.277,334.67 M82.492,335.404c0.094,0.529,0.282,1.587,0.375,2.116
	c0.521,0.017,1.562,0.051,2.082,0.068c-0.034-0.418-0.102-1.254-0.137-1.673C84.233,335.788,83.072,335.532,82.492,335.404
	 M2355.601,337.768c0.623-0.034,1.869-0.085,2.5-0.111c0-0.58,0-1.741,0-2.321C2357.043,335.916,2356.019,336.599,2355.601,337.768
	 M2518.921,335.285c-0.137,0.503-0.401,1.502-0.538,2.005c-0.793,0.759-1.519,1.545-2.193,2.355
	c1.459-0.418,2.884-1.033,4.301-1.681c0.23-0.794,0.487-1.587,0.785-2.372C2520.687,335.515,2519.509,335.362,2518.921,335.285
	 M2337.783,336.889c-0.111,0.316-0.342,0.956-0.461,1.271c0.597,0.128,1.792,0.393,2.381,0.521
	c-0.119-0.546-0.358-1.647-0.478-2.193C2338.867,336.59,2338.15,336.787,2337.783,336.889 M2527.172,339.56
	c0.999-0.111,2.04-0.23,3.115-0.341c0.111-1.092,0.256-2.185,0.427-3.268C2528.819,336.275,2528.136,338.195,2527.172,339.56
	 M2564.309,339.185c0.973-0.23,1.928-0.469,2.969-0.691c0.145-0.35,0.427-1.058,0.563-1.408
	C2566.05,335.225,2563.874,336.898,2564.309,339.185 M79.206,337.7c-1.271,1.289,1.271,2.91,2.295,1.69
	C82.5,338.084,80.205,336.932,79.206,337.7 M112.393,339.79c0.521,1.621,2.372,0.802,3.541,0.657
	c-0.179-0.503-0.546-1.51-0.725-2.014c0.085-0.418,0.265-1.254,0.35-1.681C114.398,337.546,112.538,338.195,112.393,339.79
	 M2252.894,339.483c1.169,1.092,3.115-0.947,2.295-2.176C2254.012,336.65,2252.083,338.254,2252.894,339.483 M88.294,340.217
	c1.22,0.35,2.432,0.708,3.661,1.041c-0.23-1.143-0.043-3.524-1.92-2.944C88.806,338.084,88.755,339.458,88.294,340.217
	 M2506.735,337.964c-0.734,0.529-1.425,1.067-2.082,1.613c-0.273-0.265-0.836-0.811-1.109-1.075c0.06,0.683,0.179,2.057,0.239,2.748
	c0.554,0.034,1.647,0.119,2.193,0.162C2506.496,340.328,2506.675,339.15,2506.735,337.964 M2531.541,344.057
	c2.867-1.084,4.438-4.113,5.171-6.929C2534.169,338.673,2532.19,341.139,2531.541,344.057 M2369.758,338.98
	c-1.297-0.162-2.492,1.792-0.87,2.295C2370.287,341.941,2371.371,339.21,2369.758,338.98 M2479.701,339.449
	c-1.101,1.237,1.101,2.671,2.074,1.476C2482.824,339.705,2480.563,338.621,2479.701,339.449 M2569.165,338.971
	c0.077,1.186,0.512,2.176,1.783,2.551c-0.196,0.341-0.589,1.024-0.785,1.357c1.109,0.384,2.236,0.759,3.422,1.118
	c-0.077-1.408-0.725-2.569-1.724-3.499l1.92-0.358C2572.552,338.783,2570.82,338.638,2569.165,338.971 M399.846,341.89
	c1.391,1.527,3.362,2.654,4.002,4.753c1.263-0.879,2.5-1.783,3.729-2.688c-2.125-1.033-3.891-2.603-5.419-4.378
	C401.382,340.345,400.614,341.113,399.846,341.89 M110.524,342.999c2.014,0.998,4.318,0.427,6.46,0.879
	c-0.205-2.816-2.816-2.142-4.591-1.323c0.017-0.614,0.051-1.835,0.06-2.449C110.771,340.226,110.575,341.591,110.524,342.999
	 M2261.777,343.878c0.333,0.435,0.998,1.306,1.331,1.732c1.186-1.758,2.5-3.447,3.448-5.367
	C2264.448,340.721,2262.716,341.915,2261.777,343.878 M2280.26,340.669c-1.075,0.145-3.072,0.947-2.586,2.261
	c0.478-0.017,1.442-0.034,1.92-0.051C2280.175,342.547,2281.284,341.096,2280.26,340.669 M2517.18,340.755
	c-0.999,0.853,0.273,2.782,1.425,1.911C2519.586,341.83,2518.238,339.91,2517.18,340.755 M87.876,341.369
	c-0.964,1.254,1.246,2.526,2.116,1.417C91,341.523,88.747,340.2,87.876,341.369 M2249.728,344.893
	c1.178-0.41,2.398-0.853,3.618-1.289c0.009-0.649,0.034-1.946,0.043-2.594C2251.938,342.052,2250.155,342.99,2249.728,344.893
	 M2268.083,341.343c-0.23,0.802-0.444,1.604-0.614,2.406c1.28-0.367,2.406-1.067,3.268-2.125
	C2270.08,341.557,2268.749,341.42,2268.083,341.343 M2256.853,344.168c0.589,0.085,1.775,0.256,2.372,0.341
	c0.358-0.836,0.742-1.664,1.135-2.492C2258.842,342.12,2257.86,343.229,2256.853,344.168 M2280.175,343.409
	c-1.203,1.195,1.169,3.49,2.261,1.971c0.461-1.067,0.572-2.236,0.939-3.345C2282.266,342.453,2281.165,342.845,2280.175,343.409
	 M2508.57,344.629c1.689,0.009,2.748-1.075,3.072-2.714C2509.875,341.924,2509.005,343.153,2508.57,344.629 M2527.522,344.168
	c0.913,0.657,2.27-0.836,1.51-1.698C2528.077,341.813,2526.686,343.332,2527.522,344.168 M2566.562,344.535
	c0.666-0.043,2.005-0.145,2.68-0.196C2569.497,341.992,2566.306,342.555,2566.562,344.535 M74.078,343.485
	c-0.478,1.442,2.816,2.185,2.927,0.7C76.305,343.468,75.008,342.999,74.078,343.485 M2480.196,343.4
	c-2.074,0.384-4.13,0.734-6.076,1.459c0.998,1.425,2.645,2.236,4.19,0.939c0.521-0.111,1.57-0.324,2.091-0.435
	C2480.29,344.706,2480.222,344.049,2480.196,343.4 M101.624,345.303c-1.34,0.222-2.705,0.222-4.045,0.495
	c0.614,0.333,1.826,0.981,2.432,1.306c1.451,0.171,3.209,0.111,3.413,2.005c1.715-0.282,3.405-0.666,5.103-1.007
	c1.084,0.836,2.099,2.534,3.661,1.596c0.196-1.109,0.35-2.219,0.452-3.337c1.673,0.333,3.362,0.606,5.052,0.836
	c-0.358-0.811-0.725-1.613-1.092-2.406c-3.012-0.077-6.025-0.06-9.045,0c-0.145,0.427-0.452,1.289-0.606,1.715
	c-1.553-0.026-1.749-1.937-2.569-2.884C103.356,344.006,102.451,344.603,101.624,345.303 M2239.138,344.612
	c0.017,0.631,0.034,1.894,0.042,2.526c1.084-0.051,2.193-0.102,3.328-0.154C2241.843,345.55,2240.793,344.526,2239.138,344.612
	 M2496.87,344.927c-1.536,1.126,0.333,3.422,1.545,1.707C2499.771,345.61,2498.014,343.647,2496.87,344.927 M2320.094,345.678
	c0.051,0.99,0.153,1.98,0.29,2.97c0.948-0.563,1.92-1.143,2.552-2.125C2321.963,346.233,2321.015,345.951,2320.094,345.678
	 M2502.792,345.576c-1.135,0.265-1.442,2.116-0.145,2.381C2504.328,348.307,2504.781,344.817,2502.792,345.576 M2504.883,348.273
	c0.905-0.341,1.835-0.674,2.791-1.024c0.17-0.452,0.521-1.365,0.7-1.826C2506.735,345.73,2505.054,346.37,2504.883,348.273
	 M2565.163,345.636c-0.051,0.666-0.154,1.997-0.205,2.654c0.819-0.23,1.613-0.572,2.398-1.015
	C2567.927,345.55,2566.306,345.73,2565.163,345.636 M87.637,349.544c1.86,1.237,1.801-1.357,1.587-2.441
	C88.354,347.308,86.264,348.887,87.637,349.544 M2340.377,349.74c0.615,0.683,1.297,1.34,2.031,1.98
	c0.384-3.388,3.601-1.399,5.572-0.887c0.299-0.401,0.896-1.195,1.186-1.596c-1.792-0.375-3.481-0.947-5.239-1.306
	C2342.699,348.486,2341.538,349.151,2340.377,349.74 M2352.521,346.975c-0.034,0.64-0.094,1.92-0.128,2.56
	c-1.655,0.137-1.8,1.493-1.826,2.833c-0.384,0.026-1.161,0.077-1.553,0.102c0,0.555-0.009,1.673-0.009,2.227
	c-0.529,0.247-1.579,0.734-2.108,0.973c-2.424-0.094-4.693,0.589-6.605,2.039c0.23,0.99,0.538,1.98,0.785,2.987
	c-0.683,0.649-1.348,1.254-1.997,1.869c0.042-0.393,0.128-1.186,0.171-1.579c-0.683-0.068-2.04-0.196-2.722-0.256
	c-0.93-0.725-1.869-1.408-2.91-1.843c0.333,0.981,0.7,1.954,1.101,2.944c0.401-0.145,1.195-0.418,1.596-0.555
	c-0.06,1.331-0.085,2.679-0.077,4.019c0.7-0.247,1.433-0.495,2.193-0.742c-0.12,0.435-0.359,1.297-0.478,1.732
	c1.417-0.171,2.739-0.725,3.772-1.775c0.503-0.051,1.502-0.162,2.005-0.213c0.179-1.143,0.009-2.509,1.041-3.319
	c1.451-0.256,2.799,0.503,4.173,0.947c0.009-1.613,0.043-3.209,0.171-4.804c0.768,0.563,1.442,1.246,2.005,2.065
	c0.555,0,1.664,0.009,2.219,0.017c-0.435-1.417-0.811-2.833-0.965-4.292c0.802,0.196,1.63,0.41,2.483,0.631
	c0.324-0.393,0.964-1.169,1.289-1.553c0.7,0.213,2.108,0.64,2.807,0.862c-0.196-0.734-0.572-2.202-0.768-2.944
	c-0.597-0.922-1.144-1.835-1.672-2.756C2355.174,348.401,2353.843,347.667,2352.521,346.975 M2526.609,347.615
	c-0.905,0.879,0.205,2.91,1.442,2.099C2528.913,348.87,2527.667,346.941,2526.609,347.615 M2533.982,350.986
	c1.579-0.393,2.978-1.365,2.995-3.191C2535.014,347.914,2533.931,349.023,2533.982,350.986 M2291.362,349.16
	c-0.606,0.828-0.341,3.132,1.169,2.415C2294.238,350.875,2292.685,348.776,2291.362,349.16 M2325.76,350.107
	c1.749,0.606,3.49,1.297,5.214,2.082c-0.009-0.538-0.026-1.613-0.034-2.142C2329.208,349.356,2327.177,348.401,2325.76,350.107
	 M2542.404,349.228c-0.162,1.058-0.572,1.988-1.22,2.799c0.06,0.478,0.179,1.442,0.239,1.929c0.572-0.299,1.178-0.597,1.818-0.887
	c-0.264-0.452-0.802-1.357-1.067-1.818c1.152-0.41,2.398-0.828,2.679-2.244C2544.247,349.066,2543.019,349.169,2542.404,349.228
	 M2555.537,349.305c-0.401,1.51-1.365,2.543-2.901,2.833c0.008-0.64,0.025-1.928,0.034-2.577c-2.091,0.64-3.558,2.27-5.077,3.712
	c1.988,1.869,3.49-0.256,4.642-1.877c0.051,0.589,0.145,1.783,0.196,2.372c1.604-0.222,3.209-0.435,4.864-0.631
	c-0.162-0.546-0.478-1.63-0.64-2.176c1.775,0.35,3.626,0.512,5.478,0.58c0.034-0.708,0.085-2.125,0.119-2.833
	c-0.435,0.597-1.305,1.801-1.732,2.406c-0.538-0.7-1.041-1.399-1.485-2.099C2557.815,349.1,2556.664,349.194,2555.537,349.305
	 M77.423,349.544c0.094,0.666,0.273,1.997,0.367,2.654c0.546,0.017,1.647,0.051,2.202,0.068
	C79.633,350.892,78.976,349.715,77.423,349.544 M81.621,351.959c1.041,0.358,2.091,0.717,3.14,1.067
	c0.094-0.623,0.299-1.86,0.401-2.475C83.866,350.705,82.688,351.234,81.621,351.959 M2296.405,351.071
	c-0.341,1.22,0.094,1.638,1.289,1.246C2297.975,351.097,2297.549,350.687,2296.405,351.071 M2332.006,350.423
	c0.051,0.666,0.171,1.997,0.23,2.662c0.606,0.017,1.817,0.051,2.423,0.068C2334.575,351.515,2333.559,350.619,2332.006,350.423
	 M2501.888,350.585c-0.597,1.041-1.135,2.099-1.664,3.166c0.623,0.717,1.271,1.451,1.937,2.193c0.487-0.119,1.476-0.358,1.963-0.478
	c0.631-1.681-1.596-2.287-2.381-3.328c0.213-0.316,0.64-0.947,0.845-1.271L2501.888,350.585 M2513.946,350.875
	c-1.297,0.64-0.452,3.268,0.973,2.15C2516.139,352.351,2515.388,349.783,2513.946,350.875 M30.558,351.225
	c-0.017,0.691-0.051,2.074-0.068,2.765c1.434,0.478,2.867,0.939,4.318,1.365C34.347,353.213,32.239,352.283,30.558,351.225
	 M2229.743,351.814c-1.365,1.237,0.538,2.867,1.724,1.613C2233.208,352.403,2230.801,350.449,2229.743,351.814 M2476.698,351.891
	c-0.111,0.964-0.179,1.928-0.213,2.901c0.964-0.273,1.997-0.512,3.012-0.768c-0.119-0.521-0.358-1.562-0.478-2.082
	C2478.199,351.916,2477.423,351.899,2476.698,351.891 M2526.464,353.111c0.802,0.452,1.639,0.87,2.534,1.254
	c-0.06-0.7-0.162-2.099-0.213-2.799C2527.761,351.797,2526.413,351.694,2526.464,353.111 M2535.936,351.703
	c0.102,0.435,0.307,1.306,0.409,1.732c1.306,0.367,2.628,0.734,4.019,1.101c-0.674-0.998-1.254-1.963-1.86-2.944
	C2537.865,351.618,2536.576,351.677,2535.936,351.703 M109.321,352.07c-0.051,1.178-0.102,2.364-0.137,3.558
	c1.007,0.085,2.022,0.179,3.038,0.273c-0.017,1.886,1.092,2.219,2.586,1.289c-0.205-1.237-1.075-2.185-1.698-3.209
	c-0.273,0.179-0.828,0.555-1.101,0.742C111.172,353.777,110.276,352.881,109.321,352.07 M390.562,352.403
	c0.119,0.674,0.358,2.031,0.478,2.705c0.862-0.555,1.562-1.28,2.005-2.202C392.422,352.778,391.185,352.531,390.562,352.403
	 M2518.084,353.538c-1.356,0.862,0.128,2.953,1.4,1.929C2520.823,354.621,2519.262,352.522,2518.084,353.538 M2531.26,353.768
	c-1.016,1.169,0.725,2.091,1.357,2.918c0.691-0.358,2.082-1.058,2.773-1.417C2534.323,354.297,2532.71,352.855,2531.26,353.768
	 M85.197,354.357c-1.033,0.905,0.188,3.217,1.476,2.21C87.706,355.671,86.426,353.486,85.197,354.357 M104.977,354.331
	c-0.973,1.263,1.289,2.517,2.142,1.382C108.075,354.434,105.83,353.179,104.977,354.331 M123.733,355.057
	c-1.63,0.384-0.819,2.884,0.725,2.475C126.242,357.267,125.312,354.485,123.733,355.057 M2261.103,356.081
	c0.068,1.101,0.35,2.133,1.63,2.338c0.29,1.434,1.681,1.417,2.876,1.724c-0.205-1.476-1.169-2.185-2.568-2.278
	c0.085-0.657,0.256-1.963,0.341-2.62C2262.596,355.517,2261.837,355.799,2261.103,356.081 M102.963,356.2
	c0.691,1.229,1.724,2.176,2.91,2.927c0.538-0.111,1.621-0.333,2.159-0.444C107.315,356.482,104.875,356.55,102.963,356.2
	 M2326.084,357.233c-0.315,0.879-1.877,2.441-0.418,3.089c1.271,0.759,2.927,1.365,3.115,3.106c-1.126-0.009-2.21-0.017-3.268-0.026
	c-0.418-0.23-1.254-0.7-1.681-0.93c-0.427,0.674-1.28,2.014-1.707,2.688c3.251-0.316,6.502,0.521,9.779,0.034
	c0.043,0.742,0.137,2.21,0.179,2.953c1.084-0.666,2.099-1.459,3.115-2.278c-1.015-2.901-2.73-6.34-6.374-5.931
	C2327.876,359.033,2326.98,358.129,2326.084,357.233 M2501.734,357.898c-0.666,1.058,1.144,2.108,1.98,1.348
	C2504.183,358.231,2502.477,357.25,2501.734,357.898 M2509.867,358.086c-0.119,0.521-0.367,1.57-0.495,2.091
	c0.555-0.094,1.673-0.273,2.227-0.367c-0.034-0.435-0.094-1.306-0.12-1.741C2511.078,358.077,2510.268,358.086,2509.867,358.086
	 M2559.428,359.323c1.032-0.452,3.635,0.384,3.541-1.459C2561.553,356.337,2560.154,358.205,2559.428,359.323 M390.562,358.495
	C390.63,361.294,393.182,357.25,390.562,358.495 M2298.948,361.841c1.118-0.58,2.133-1.323,3.157-2.116
	c-0.64-0.486-1.254-0.956-1.869-1.399C2298.231,358.325,2299.136,360.578,2298.948,361.841 M2516.275,358.649
	c0.503,1.732,2.33,2.679,4.079,2.005C2520.473,358.623,2517.632,358.897,2516.275,358.649 M2538.385,360.723
	c0.657,0.017,1.971,0.034,2.637,0.051c-0.085-0.555-0.247-1.664-0.324-2.219C2539.119,357.958,2538.769,359.647,2538.385,360.723
	 M2566.699,360.117c-0.265,2.261,2.423,0.119,3.413-0.154c-0.316-0.401-0.947-1.195-1.263-1.587
	C2568.107,358.922,2566.144,358.734,2566.699,360.117 M83.738,361.235c-0.282,0.631-0.572,1.263-0.862,1.903
	c0.452,0.017,1.357,0.043,1.809,0.06c0.998-0.418,1.997-0.828,2.995-1.229C86.767,360.373,85.222,360.45,83.738,361.235
	 M92.996,361.781c1.664,0.503,3.337,0.981,4.992,1.519c-0.119-0.947-0.239-1.894-0.358-2.833
	C96.026,360.671,94.106,360.287,92.996,361.781 M2502.75,360.262c-0.06,1.058-0.085,2.125-0.06,3.2
	c0.699-0.657,1.988-0.922,2.253-1.98C2504.183,361.055,2503.458,360.646,2502.75,360.262 M2559.821,362.498
	c0.427,0.913,0.998,1.801,1.527,2.705c0.461-0.026,1.374-0.068,1.835-0.085c-0.213-0.614-0.632-1.835-0.845-2.449
	c0.631,0.247,1.894,0.734,2.526,0.973c-0.478-1.169,0.367-3.157-0.921-3.814C2562.355,360.356,2561.058,361.465,2559.821,362.498
	 M406.118,360.91C406.289,363.743,408.687,359.605,406.118,360.91 M2353.152,361.704c-1.844,1.033,0.435,2.953,1.613,1.57
	C2356.139,362.071,2354.313,360.253,2353.152,361.704 M2480.606,361.218c-1.775,0.324-1.16,3.063,0.623,2.398
	C2482.893,363.325,2482.116,360.697,2480.606,361.218 M75.076,361.96c-1.024,1.263,1.263,2.509,2.133,1.408
	C78.234,362.097,75.938,360.885,75.076,361.96 M2475.546,361.934c-0.828,1.229,1.288,2.569,2.219,1.527
	C2478.601,362.182,2476.416,360.851,2475.546,361.934 M2539.52,362.907c-1.092,1.297,1.28,2.449,2.133,1.28
	C2542.711,362.873,2540.305,361.798,2539.52,362.907 M2311.432,363.641c0.043,0.614,0.128,1.852,0.171,2.466
	c0.87-0.162,1.775-0.324,2.705-0.486c0.145-0.529,0.427-1.604,0.563-2.142C2313.694,363.53,2312.55,363.581,2311.432,363.641
	 M2477.841,369.606c0.853-0.393,1.732-0.785,2.637-1.178l-1.246-0.35c0.76-1.109,1.451-2.261,1.997-3.507
	C2477.995,363.581,2477.85,367.413,2477.841,369.606 M2512.435,365.203c0.64,1.792,2.569,1.408,4.088,1.69
	C2516.548,364.529,2514.005,364.657,2512.435,365.203 M110.899,365.442c-1.033,0.836,0.367,2.688,1.451,1.937
	C113.34,366.517,112.017,364.631,110.899,365.442 M2471.612,367.413c1.408,2.227,4.497,1.306,4.753-1.263
	C2474.76,366.517,2473.148,366.961,2471.612,367.413 M31.352,367.08c0.068,0.538,0.222,1.621,0.29,2.167
	c0.555,0.068,1.673,0.213,2.227,0.29c0.009-0.666,0.026-1.98,0.034-2.637C33.263,366.943,31.983,367.037,31.352,367.08
	 M114.236,367.097c-1.05,1.314,1.348,2.449,2.159,1.289C117.402,367.08,115.046,365.979,114.236,367.097 M2360.439,367.054
	c-1.186,0.128-2.082,2.202-0.478,2.423C2361.736,369.879,2362.829,366.227,2360.439,367.054 M2504.49,369.188
	c1.109,0.896,3.183-0.819,2.244-2.048C2505.557,366.158,2503.569,367.967,2504.49,369.188 M130.543,367.967
	C131.055,368.445,131.055,368.445,130.543,367.967 M2333.397,368.957c0.597,1.024,1.664,1.365,2.791,1.681
	c-0.077-0.683-0.23-2.057-0.316-2.739C2335.019,368.241,2334.191,368.59,2333.397,368.957 M2511.684,367.899
	c0.64,1.775,3.772,2.901,5.069,1.22c-0.674-0.316-2.005-0.947-2.679-1.263C2513.476,367.865,2512.281,367.891,2511.684,367.899
	 M2572.825,368.113c-0.939,0.222-1.544,0.768-1.826,1.647c0.162,1.152,1.963,0.956,2.663,0.324
	C2574.08,369.41,2573.636,368.181,2572.825,368.113 M57.097,369.026c-0.265,1.178,0.205,1.604,1.399,1.28
	C58.744,369.128,58.274,368.701,57.097,369.026 M111.36,369.307c-0.068,1.536,1.041,2.202,2.304,2.654
	c0.017-0.555,0.051-1.664,0.068-2.219C113.144,369.64,111.949,369.418,111.36,369.307 M2325.231,369.614
	c-1.109,0.836,0.043,3.2,1.34,2.133C2327.646,370.894,2326.511,368.505,2325.231,369.614 M2507.332,369.623
	c-0.504,1.399-0.051,1.86,1.348,1.391C2509.133,369.589,2508.681,369.128,2507.332,369.623 M2549.308,373.036
	c1.101-0.358,2.347-0.717,3.516-1.092c0.042-0.64,0.128-1.929,0.171-2.569C2551.364,370.212,2549.18,370.741,2549.308,373.036
	 M2553.796,369.41c0.034,0.751,0.111,2.244,0.145,2.995c0.93-0.7,1.86-1.485,2.62-2.423
	C2555.87,369.845,2554.487,369.555,2553.796,369.41 M2366.404,370.579c-1.22,1.314,1.186,2.389,2.005,1.178
	C2369.587,370.425,2367.146,369.282,2366.404,370.579 M2500.369,370.783c0.384,0.905,0.196,1.911,0.23,2.884
	c0.469-0.017,1.417-0.043,1.886-0.06c0.009-1.143,0.042-2.278,0.111-3.413C2501.803,370.357,2501.06,370.553,2500.369,370.783
	 M31.317,371.142c0.051,1.092,0.111,2.193,0.162,3.302c0.572,0.034,1.707,0.094,2.27,0.128c-0.051-1.101-0.094-2.185-0.145-3.277
	C33.033,371.261,31.889,371.176,31.317,371.142 M69.769,371.236c0,1.135,0,2.27,0.009,3.405c0.529-0.145,1.579-0.444,2.108-0.597
	c0.171-0.964,0.341-1.92,0.512-2.867C71.74,371.193,70.426,371.219,69.769,371.236 M107.426,371.389
	c-0.358,0.623-0.725,1.254-1.084,1.894c1.323-0.068,2.432,0.546,3.234,1.579c2.372,0.828,3.968,3.098,6.545,3.43
	c-0.563-3.507-5.035-3.285-6.69-6.033C108.766,371.961,108.092,371.671,107.426,371.389 M2286.464,372.081
	c0.239,2.901,3.908,2.039,4.873,0.051C2289.681,372.072,2288.068,372.063,2286.464,372.081 M2362.069,374.214
	c1.041,1.152,3.388-0.521,1.758-1.596C2362.624,371.244,2360.533,372.985,2362.069,374.214 M2365.798,374.197
	c0.896,1.186,1.826,2.364,2.936,3.405c1.382-1.562,4.395-3.473,2.577-5.769C2369.408,372.473,2367.693,373.582,2365.798,374.197
	 M2513.988,372.26c-1.135,1.323,1.229,2.449,2.031,1.186C2517.111,372.123,2514.705,370.963,2513.988,372.26 M2517.325,372.302
	c-1.186,1.323,1.229,2.014,1.928,0.905C2520.465,371.944,2517.914,371.278,2517.325,372.302 M2538.871,375.596
	c-1.032,0.811-2.065,1.604-3.055,2.406c1.024,0.444,2.057,0.836,3.166,1.246l-0.367,1.297c0.76-0.614,1.553-1.229,2.381-1.835
	c-1.084-0.452-1.997-0.87-2.969-1.391c1.297-0.299,5.282-3.584,2.534-4.352c-0.324-0.247-0.973-0.742-1.297-0.99
	C2537.071,372.465,2537.506,374.376,2538.871,375.596 M2277.069,375.332c0.691-0.017,2.065-0.051,2.756-0.068
	c0-0.657,0-1.98,0-2.645C2278.391,372.985,2277.419,373.907,2277.069,375.332 M2553.651,375.562
	c1.766-0.265,3.806-0.503,3.866-2.807C2555.929,373.335,2553.412,373.181,2553.651,375.562 M2569.088,377.457
	c2.569-0.213,2.074-3.132,2.577-4.915C2569.523,373.164,2569.728,375.767,2569.088,377.457 M405.112,374.12
	c0.051,1.809,2.961-0.29,0.742-0.452L405.112,374.12 M2260.915,373.932c0.111,2.577,3.337,2.014,3.832-0.034
	C2263.441,373.881,2262.169,373.898,2260.915,373.932 M73.984,375.443c0.333,1.28,2.278,1.365,3.209,0.708
	C77.747,374.547,74.283,373.702,73.984,375.443 M2372.625,374.487c0.154,0.35,0.444,1.067,0.589,1.417l1.067,0.725
	c-0.495,0.742-0.956,1.493-1.383,2.244c2.244,0.393,2.475-1.28,2.432-3.072C2374.408,375.349,2373.504,374.914,2372.625,374.487
	 M2549.188,377.158c1.28-0.077,2.842,0.06,3.746-1.152C2552.738,373.343,2549.811,375.963,2549.188,377.158 M2564.924,374.623
	c0,0.649,0,1.946,0.009,2.594c0.444-0.145,1.331-0.444,1.784-0.589c0.188-0.512,0.563-1.527,0.759-2.039
	C2566.835,374.598,2565.564,374.615,2564.924,374.623 M30.643,375.571c-1.015,0.879,0.205,3.123,1.459,2.236
	C33.109,376.902,31.949,374.581,30.643,375.571 M68.062,375.98c-0.572,1.178,1.476,1.843,2.202,1.092
	C70.69,375.989,68.762,375.374,68.062,375.98 M403.576,377.823c0.384,1.425,0.495,2.884,0.41,4.361
	c0.717,0.111,2.15,0.341,2.867,0.452c0.026-2.551,0.102-5.103,0.435-7.637C406.016,375.903,404.745,376.808,403.576,377.823
	 M2278.724,378.958c1.459-0.35,2.918-0.785,4.395-1.22c0.051-0.64,0.162-1.903,0.213-2.543
	C2281.591,376.185,2279.629,377.064,2278.724,378.958 M2382.737,375.852c-1.545,1.033,0.145,2.859,1.263,1.323
	C2385.783,376.151,2383.872,374.265,2382.737,375.852 M2384.905,378.327c0.546,0,1.647-0.017,2.201-0.026
	c0.478-0.99,0.853-2.014,1.374-3.012C2386.688,375.537,2385.638,376.842,2384.905,378.327 M2357.009,376.689
	c-6.14,2.128-6.619-1.067-7.373,1.732c1.638,0.29,3.678,0.452,3.976,2.56c1.732-0.99,3.669-1.374,5.683-1.399
	c0.009-0.538,0.009-1.621,0.009-2.159C2358.511,377.175,2357.743,376.927,2357.009,376.689 M105.301,377.209
	c0.304,2.014,4.056,4.073,5.615,4.335c-0.478-1.69-1.664-3.004-2.748-4.335C107.452,377.209,106.018,377.209,105.301,377.209
	 M122.624,378.796c0.725,0.521,1.502,0.964,2.27,1.442c0.085-1.058,0.171-2.116,0.247-3.166
	C124.058,377.354,122.394,377.166,122.624,378.796 M2266.564,377.286c-1.084,0.188-2.031,2.193-0.486,2.441
	C2267.912,380.145,2268.988,376.424,2266.564,377.286 M69.752,379.257c0.666,0.674,1.331,1.357,2.005,2.039
	c0.742-0.751,2.543-0.964,2.278-2.287C73.08,377.294,71.142,379.206,69.752,379.257 M2333.005,379.214
	c0.136,1.545,2.15,0.905,3.217,1.399c-0.119-0.555-0.367-1.655-0.495-2.21C2334.643,378.335,2333.397,377.9,2333.005,379.214
	 M2503.125,378.011c-0.862,0.896-1.843,1.638-2.859,2.278c-0.589,1.34-1.135,2.705-1.553,4.087c2.33-1.545,4.736-3.089,6.52-5.325
	C2504.499,378.702,2503.799,378.353,2503.125,378.011 M2566.485,385.247c1.656-1.988,4.258-4.207,3.319-7.1
	C2567.117,378.813,2566.46,382.781,2566.485,385.247 M60.672,379.027c-1.041,1.271,1.195,2.526,2.014,1.297
	C63.821,379.035,61.483,377.764,60.672,379.027 M102.349,378.873c-0.009,0.7-0.017,2.091-0.026,2.79
	c1.075-0.623,3.541-0.666,3.021-2.441C104.354,379.069,103.356,378.958,102.349,378.873 M2489.37,379.18
	c-1.451,0.427-1.459,3.123,0.418,2.423C2491.366,381.348,2491.11,378.225,2489.37,379.18 M2560.537,382.414
	c2.261,0.137,2.347-2.108,2.603-3.755C2561.382,379.103,2561.135,381.015,2560.537,382.414 M45.065,379.667
	c-0.077,0.649-0.23,1.937-0.299,2.586c0.486,0.017,1.468,0.034,1.954,0.051v0.811c-0.538,0.017-1.621,0.051-2.167,0.06
	c-1.749,1.997-3.123,4.813-5.734,5.726c1.041,0.87,2.074,1.749,3.081,2.671c-0.546,0.043-1.638,0.145-2.185,0.196
	c0.333,1.186,0.657,2.372,1.075,3.541c1.877-0.068,3.755-0.213,5.632-0.265c0.734,0.853,1.476,1.707,2.219,2.569
	c-0.495,0.017-1.493,0.06-1.988,0.085c0.043,0.333,0.128,1.015,0.171,1.357c2.569,0.265,5.129,0,7.612-0.708l-0.051,0.956
	c-0.555,0.034-1.664,0.111-2.21,0.145c-1.357,1.374-2.91,2.526-4.565,3.524c0.026,1.22-0.486,2.603,0.427,3.661
	c-0.273,0.734-0.555,1.476-0.828,2.219c-0.742-0.282-1.485-0.563-2.227-0.845c0.085,1.263-0.631,2.756,0.179,3.883
	c1.766,0.29,3.558,0.273,5.333,0.427c2.176,0.845,4.548,0.802,6.801,1.314c-0.742-1.092-1.51-2.167-2.253-3.243
	c1.28-0.026,2.56-0.026,3.84-0.017c-1.707-1.655-3.413-3.345-4.745-5.316c1.715,0.649,3.14,1.809,4.531,2.978
	c0-0.512,0.009-1.553,0.017-2.074c0.896,0.785,1.749,1.655,2.731,2.364c1.792,0.043,3.567-0.341,5.308-0.7
	c-1.894-2.825-6.178-0.538-8.499-3.14c1.673-0.094,3.354-0.102,5.026-0.162c-2.654-2.048-6.349-3.695-6.519-7.586
	c0.862,0.111,2.594,0.316,3.456,0.418c-1.323-1.434-2.705-2.799-3.968-4.275c-0.307-1.22,1.229-0.93,1.954-1.237
	c0.947,1.314,3.362,1.485,3.285-0.572c-2.022-0.367-4.019-0.836-6.042-1.229c-1.007-1.084-1.963-2.236-2.295-3.712
	c-0.725,0.188-1.442,0.384-2.167,0.589c0.358-0.887,0.717-1.775,1.075-2.654c-0.649-0.034-1.937-0.111-2.586-0.145
	c0.188-0.393,0.572-1.169,0.759-1.562c-0.452-0.845-0.896-1.681-1.34-2.526C47.599,379.769,46.328,379.709,45.065,379.667
	 M2338.782,381.868c1.001-0.128,6.296,1.092,8.09,2.85c0.64-0.469,1.348-0.905,2.031-1.408c-0.35-1.075-0.691-2.133-1.033-3.174
	c-1.126,0.512-2.21,1.041-3.268,1.579c-1.766-1.015-3.84-0.23-5.479-1.604C2338.961,380.691,2338.85,381.279,2338.782,381.868
	 M2483.507,382.133c0.691-0.043,2.082-0.119,2.773-0.162c0.017-0.589,0.042-1.775,0.06-2.364
	C2484.924,379.923,2483.789,380.631,2483.507,382.133 M2532.838,379.701c-0.068,0.708-0.188,2.133-0.247,2.842
	c0.811-0.256,1.562-0.64,2.27-1.161C2535.39,379.812,2533.879,379.871,2532.838,379.701 M2547.183,383.805
	c2.961,0.751,5.052-1.92,6.067-4.326C2550.904,380.383,2548.54,381.604,2547.183,383.805 M2557.457,384.258
	c1.604-1.041,2.295-2.842,2.355-4.71C2557.295,379.53,2557.824,382.577,2557.457,384.258 M2365.457,380.785
	c-1.365,1.152,0.546,2.782,1.707,1.664C2368.947,381.407,2366.575,379.402,2365.457,380.785 M2544.725,380.861
	c-1.28,0.742-0.205,3.209,1.16,2.082C2547.029,382.175,2546.056,379.684,2544.725,380.861 M105.762,381.407
	c0.162,1.28,0.205,3.388,2.048,3.226C109.551,383.003,107.281,381.698,105.762,381.407 M2381.517,382.355
	c-0.128,0.367-0.376,1.109-0.504,1.485c1.724,0.23,3.064-0.683,3.166-2.517C2383.257,381.655,2382.37,381.996,2381.517,382.355
	 M2542.208,381.723c-0.136,0.35-0.401,1.058-0.529,1.408C2542.993,385.862,2544.956,380.179,2542.208,381.723 M96.256,382.611
	c0.085,3.021,4.07,3.055,6.17,2.167C100.966,382.79,98.466,382.85,96.256,382.611 M2325.973,381.902
	c0.17,1.545,0.384,3.081,0.666,4.625c-0.205-0.384-0.606-1.143-0.811-1.527c-2.091,0.119-6.17-1.391-6.776,1.357
	c1.041,1.024,2.526,0.981,3.934,1.067c0.597-0.299,1.809-0.887,2.415-1.186c-1.075,1.126-2.27,2.176-2.995,3.55
	c2.048-0.367,4.104-1.015,6.135-1.647c-1.852-1.553,0.998-2.645,1.792-3.849C2329.173,382.961,2327.74,382.013,2325.973,381.902
	 M2353.758,382.321c1.536,1.126,4.045,3.106,4.983,0.213C2357.06,382.414,2355.388,382.321,2353.758,382.321 M2538.667,384.582
	c1.647,0.734,1.929-1.391,2.646-2.372C2539.588,382.158,2537.779,382.483,2538.667,384.582 M2534.238,383.089
	c0.051,0.734,0.145,2.193,0.188,2.927c0.879-0.717,1.69-1.545,2.406-2.458C2536.183,383.438,2534.886,383.208,2534.238,383.089
	 M2334.191,383.831c-1.135,0.811-2.21,1.638-3.242,2.526c1.553,0.102,3.132,0.171,4.753,0.205
	C2335.181,385.631,2334.694,384.727,2334.191,383.831 M2368.853,384.241c-1.135,1.331,1.331,2.321,2.15,1.203
	C2372.104,384.095,2369.604,383.106,2368.853,384.241 M2489.173,384.334c-1.169,1.399,1.399,2.244,2.159,1.058
	C2492.416,383.993,2489.839,383.123,2489.173,384.334 M389.077,385.077c-1.033,1.357,1.485,2.219,2.321,1.263
	C392.414,384.991,389.888,384.078,389.077,385.077 M2493.099,385.461c-1.016,3.081-4.011,4.471-5.897,6.869
	c2.784-0.549,7.196-5.225,7.689-7.168C2494.438,385.239,2493.542,385.384,2493.099,385.461 M2556.715,384.872
	c-0.879,0.777-1.877,2.116-1.408,3.319C2556.612,388.78,2558.899,384.522,2556.715,384.872 M2558.046,389.13
	c-1.075,2.85-3.482,5.146-4.164,8.098c1.28,0.521,2.628,0.964,3.985,1.417c0.905-4.77,2.389-9.412,3.166-14.217
	C2559.129,385.316,2558.788,387.432,2558.046,389.13 M2270.438,387.27c0.717,0.273,1.476,0.521,2.27,0.751
	c0.102-0.614,0.307-1.852,0.41-2.475C2272.043,385.922,2270.498,385.785,2270.438,387.27 M2374.562,386.758
	c0.068,0.461,0.205,1.382,0.273,1.843c1.732-0.418,4.19-0.64,4.326-2.961C2377.591,385.981,2376.081,386.374,2374.562,386.758
	 M2354.73,389.053c0.999-0.341,2.065-0.674,3.132-0.964c0.221,1.118,0.938,1.741,2.15,1.869c-0.025-1.143-0.042-2.278-0.068-3.413
	c-0.342,0.265-1.032,0.802-1.383,1.067C2357.069,385.87,2354.475,386.502,2354.73,389.053 M2364.919,389.318
	c1.212-0.555,3.652-0.717,3.567-2.543C2366.72,385.119,2365.602,388.174,2364.919,389.318 M59.699,387.543
	c-0.964,1.289,1.34,2.432,2.193,1.374C62.865,387.62,60.544,386.434,59.699,387.543 M2343.228,387.825
	c0.017,0.597,0.034,1.801,0.051,2.406c0.99-0.521,1.843-1.254,2.355-2.295C2345.037,387.91,2343.833,387.85,2343.228,387.825
	 M2546.278,387.773c-0.785,1.058-0.615,1.715,0.529,1.98C2547.499,388.686,2547.328,388.029,2546.278,387.773 M443.64,388.396
	c-1.203,0.828,0.034,3.132,1.314,2.014C446.225,389.599,444.894,387.321,443.64,388.396 M2274.748,389.395
	c0.051,0.589,0.145,1.758,0.196,2.347c1.98-0.29,5.393-0.401,4.932-3.268C2278.187,388.942,2276.463,389.292,2274.748,389.395
	 M2331.417,388.379C2331.486,391.17,2334.029,387.091,2331.417,388.379 M2345.625,389.574c0.077,0.589,0.213,1.178,0.41,1.775
	c1.741-0.7,3.695-0.708,5.589-0.853c-0.068-0.503-0.205-1.493-0.273-1.988C2349.466,389.045,2347.529,389.361,2345.625,389.574
	 M24.644,388.9c0.034,0.717,0.111,2.15,0.145,2.859c0.947-0.35,2.389-0.29,2.287-1.707C26.308,389.591,25.489,389.207,24.644,388.9
	 M65.459,389.062c0.043,0.623,0.111,1.852,0.145,2.475c0.546-0.034,1.621-0.094,2.167-0.119c0.017-0.529,0.06-1.587,0.085-2.116
	C67.26,389.241,66.057,389.122,65.459,389.062 M406.178,389.122c-0.213,0.273-0.649,0.819-0.862,1.084
	C406.272,392.97,409.481,388.302,406.178,389.122 M2317.218,389.062c0.324,0.939,0.674,1.877,1.058,2.816
	c0.887-0.853,1.809-1.715,2.722-2.586C2319.71,389.198,2318.455,389.122,2317.218,389.062 M105.592,391.895
	c0.589,0.068,1.758,0.205,2.347,0.273c-0.051-0.546-0.154-1.638-0.205-2.185C106.914,390.495,106.163,391.101,105.592,391.895
	 M2323.959,392.415c2.185,0.017,4.429,0.119,6.571,0.751c-0.452-1.314-1.434-2.304-2.33-3.285
	C2326.767,390.709,2325.333,391.554,2323.959,392.415 M2270.763,391.067c-1.169,1.715,1.297,3.268,2.278,1.425
	C2274.33,391.042,2271.804,390.001,2270.763,391.067 M2332.979,390.589c-0.034,0.742-0.085,2.21-0.111,2.953
	c1.101-0.162,2.261-0.503,3.294-1.075C2335.061,391.818,2334.029,391.195,2332.979,390.589 M69.316,393.866
	c-0.06,0.572-0.179,1.724-0.239,2.295c1.186-0.589,2.551-1.152,2.543-2.705C71.049,393.559,69.888,393.764,69.316,393.866
	 M72.44,393.806c-0.956,0.862,0.316,2.825,1.451,1.954C74.854,394.899,73.523,393.03,72.44,393.806 M77.176,396.955
	c0.657-0.213,1.98-0.64,2.645-0.853c0.154,1.92,2.347,1.57,3.644,2.15c-0.154-0.956-0.324-1.903-0.486-2.85
	c-0.683,0.119-2.048,0.358-2.731,0.469c-0.068-0.657-0.205-1.971-0.273-2.637C77.986,393.252,77.944,395.633,77.176,396.955
	 M389.197,393.294c-1.007,0.239-1.596,2.065-0.23,2.236C390.776,396.008,391.347,392.424,389.197,393.294 M2521.608,393.55
	C2522.052,394.062,2522.052,394.062,2521.608,393.55 M2319.855,394.395c-0.222,2.534,3.473,0.956,3.55,3.081
	c-0.683,1.374-1.775,2.56-3.422,2.33c0.085,0.486,0.239,1.451,0.324,1.937c0.657-0.299,1.348-0.589,2.065-0.879
	c0.401-0.836,0.828-1.664,1.28-2.492c0.759,0.23,1.544,0.478,2.364,0.725c1.177-0.666,2.355-1.314,3.61-1.937
	c-1.323-1.084-2.944-1.34-4.565-1.237C2323.345,395.291,2321.604,394.771,2319.855,394.395 M2360.9,394.267
	c0.869,2.609,1.198,5.154,4.07,5.103c-0.529-1.51-0.956-3.191-2.543-3.883c0.256-0.358,0.776-1.084,1.032-1.442
	C2362.82,394.097,2361.54,394.207,2360.9,394.267 M2374.007,394.694c-1.34,0.802,0,3.132,1.323,2.022
	C2376.49,395.889,2375.236,393.704,2374.007,394.694 M60.024,397.561c1.032,2.342,9.133,2.65,10.138,0.913
	c-1.442-0.324-2.961-0.384-4.343-0.947c-0.128-0.742-0.384-2.219-0.512-2.953C63.462,395.402,61.653,396.349,60.024,397.561
	 M113.476,395.428c0.034,0.418,0.102,1.254,0.137,1.664c0.512,0.051,1.527,0.145,2.039,0.196c0.299-0.597,0.597-1.178,0.896-1.758
	C115.524,395.487,114.5,395.453,113.476,395.428 M126.097,396.068c0.009,0.341,0.034,1.007,0.043,1.34
	c1.118,0.026,2.236,0.068,3.354,0.119c-0.085-0.495-0.256-1.485-0.341-1.98C128.128,395.726,127.113,395.889,126.097,396.068
	 M408.824,396.614c0.93,0.478,1.869,0.461,2.79-0.043C412.348,394.737,408.516,395.129,408.824,396.614 M2560.299,397.416
	c0.742-0.358,1.604-2.304,0.333-2.458C2559.565,395.103,2559.027,397.279,2560.299,397.416 M2277.487,396.119
	c-1.024,1.323,1.383,2.338,2.261,1.323C2280.729,396.102,2278.289,395.086,2277.487,396.119 M2369.357,396.136
	c0.358,1.161,0.811,2.56,2.364,2.372c-0.034-0.572-0.085-1.724-0.111-2.295C2371.046,396.196,2369.92,396.153,2369.357,396.136
	 M428.211,396.554c0.964,1.399,0.469,2.85-0.171,4.25c1.118-0.29,2.236-0.546,3.371-0.777c0.256-0.828,0.521-1.655,0.794-2.475
	C430.899,397.1,429.551,396.793,428.211,396.554 M2562.253,396.699c-0.666,1.075-1.195,2.21-1.741,3.328
	c3.336,2.295,7.492,2.074,11.349,2.662C2568.653,400.676,2565.427,398.73,2562.253,396.699 M395.913,399.865
	c1.186,0.111,2.372,0.222,3.567,0.324c-0.794-0.836-1.604-1.655-2.415-2.458C396.681,398.44,396.288,399.148,395.913,399.865
	 M2348.092,397.783c0.853,1.553,1.579,3.157,2.313,4.796c1.818-1.289,0.265-3.311,0.051-4.966
	C2349.867,397.655,2348.681,397.74,2348.092,397.783 M439.245,398.781c0.58,1.143,1.229,2.287,2.611,2.594
	c-0.128-0.614-0.384-1.835-0.512-2.449C440.824,398.892,439.774,398.824,439.245,398.781 M2366.985,398.491
	c0.136,0.589,0.392,1.775,0.529,2.364c0.708-0.58,1.408-1.203,2.074-1.886C2368.939,398.85,2367.642,398.611,2366.985,398.491
	 M2374.571,399.003c-0.29,0.879-0.913,1.732-0.64,2.697c0.819-0.205,1.655-0.435,2.517-0.683c0.401,0.23,1.212,0.708,1.622,0.939
	c-0.094-0.93-0.154-1.86-0.171-2.782C2376.747,399.131,2375.654,399.08,2374.571,399.003 M22.733,400.812
	c1.161,0.051,2.313,0.094,3.473,0.145c0.205-0.41,0.623-1.22,0.828-1.621C25.506,399.541,22.716,398.389,22.733,400.812
	 M76.792,399.831c-0.026,0.597-0.077,1.792-0.102,2.389c0.905-0.563,1.673-1.28,2.321-2.116
	C78.456,400.036,77.346,399.899,76.792,399.831 M2296.849,401.649c1.672-0.026,5.401,1.118,4.096-1.835
	C2299.272,399.729,2297.182,399.558,2296.849,401.649 M125.107,400.701c-0.324,0.836-0.64,1.673-0.964,2.509
	c-1.126,0.026-1.655,0.674-1.596,1.954c0.708,0.068,2.125,0.213,2.833,0.29c-1.562-2.133,1.63-2.953,1.604-4.915
	C126.515,400.582,125.577,400.659,125.107,400.701 M2364.45,400.3c-1.075,0.905,0.23,3.055,1.493,2.133
	C2367.019,401.538,2365.662,399.285,2364.45,400.3 M80.981,403.185c1.092,0.341,1.647-0.102,1.664-1.306
	C82.082,399.848,79.556,401.99,80.981,403.185 M87.603,401.196c0.043,0.572,0.137,1.707,0.188,2.27
	c0.563-0.026,1.69-0.077,2.253-0.102c0.06-0.555,0.171-1.664,0.222-2.227C89.6,401.154,88.269,401.179,87.603,401.196
	 M22.861,403.039c0.034,1.92,2.611,1.34,3.925,1.92c-0.085-0.375-0.239-1.126-0.316-1.502
	C25.276,403.005,23.902,401.503,22.861,403.039 M67.78,403.884c0.521,2.466,4.54,1.877,5.786,0.171
	C71.706,402.783,69.7,402.715,67.78,403.884 M77.722,402.749c-0.751,1.118,1.178,2.355,2.005,1.399
	C80.495,402.997,78.549,401.7,77.722,402.749 M401.621,403.022c0.666,0.862,1.152,2.065,2.389,2.219
	c0.631-0.64,1.058-1.408,1.289-2.295C404.07,402.946,402.842,402.971,401.621,403.022 M2313.745,403.022
	c0.734,0.862,1.536,1.69,2.381,2.492c0.103-0.691,0.316-2.074,0.418-2.765C2315.844,402.818,2314.445,402.954,2313.745,403.022
	 M2373.154,403.091c-1.152,1.374,1.365,2.261,2.133,1.092C2376.397,402.792,2373.837,401.862,2373.154,403.091 M2554.308,405.079
	c0.682,0.077,2.039,0.23,2.722,0.307c0.324,0.41,0.973,1.22,1.306,1.621c0.563,0.009,1.698,0.017,2.27,0.026
	c0.009-0.375,0.034-1.135,0.043-1.51c-1.869-0.119-3.993-0.213-3.891-2.594C2555.793,403.509,2554.769,404.021,2554.308,405.079
	 M75.844,403.961c-1.673,0.572,0.555,2.714,1.587,1.886C78.148,404.891,76.826,403.697,75.844,403.961 M2356.864,404.593
	c-1.391,1.485,1.604,2.995,2.296,1.263C2359.791,404.507,2357.692,403.603,2356.864,404.593 M426.513,405.258
	c-0.887,1.604-1.34,3.174,1.169,3.499c-1.229,1.109-2.381,2.304-3.311,3.669c2.091-0.316,4.89,0.341,5.879-2.116l-2.372,0.572
	c0.247-1.084,0.503-2.167,0.811-3.226c-0.444-0.41-0.887-0.802-1.323-1.195c0.418-0.256,1.237-0.759,1.655-1.015
	C428.39,405.403,427.136,405.309,426.513,405.258 M2291.081,405.454c-0.7,0.99-1.016,2.091-0.674,3.294
	c1.024-0.589,1.979-1.212,3.046-1.826C2292.634,406.427,2291.848,405.932,2291.081,405.454 M2295.168,405.147
	c-0.58,2.509,1.792,3.405,3.789,3.721C2298.598,406.914,2297.037,405.54,2295.168,405.147 M2299.469,405.267
	c0.222,0.896,0.512,1.783,0.862,2.662c1.391,0.119,2.773-0.358,3.661-1.51C2302.481,405.941,2300.97,405.565,2299.469,405.267
	 M103.211,408.441c1.135,1.092,2.884-0.734,1.775-1.809C103.817,405.198,101.717,407.298,103.211,408.441 M2563.396,406.35
	c-1.101,1.314,1.314,2.355,2.185,1.297C2566.596,406.325,2564.181,405.267,2563.396,406.35 M61.466,409.969
	c0.589,0.759,0.811,2.057,1.963,2.133c0.597-0.708,1.075-1.493,1.434-2.347C63.727,409.806,62.601,409.883,61.466,409.969
	 M2250.24,410.062c-0.034,0.529-0.103,1.57-0.136,2.091c1.237-0.333,2.543-0.811,2.73-2.304
	C2252.186,409.9,2250.888,410.011,2250.24,410.062 M2264.806,410.011c-0.009,0.589-0.034,1.758-0.051,2.338
	c1.05-0.495,1.886-1.289,2.449-2.33C2266.607,410.02,2265.412,410.011,2264.806,410.011 M2301.466,409.823
	c-1.075,0.725-0.103,2.91,1.118,1.843C2303.761,410.95,2302.643,408.74,2301.466,409.823 M2372.634,409.431
	c3,4.559,3.172,4.787,4.335,5.231C2376.021,412.605,2375.475,409.405,2372.634,409.431 M2290.799,411.82
	c0.76,0.631,1.485,1.246,2.321,1.886c0.068-1.007,0.145-2.022,0.299-3.012C2292.514,411.061,2291.644,411.436,2290.799,411.82
	 M2295.774,412.136c0.589-0.188,1.766-0.58,2.355-0.777c-0.051,0.674-0.154,2.031-0.205,2.705c0.819-0.418,1.647-0.853,2.492-1.314
	C2299.435,411.001,2296.422,409.124,2295.774,412.136 M400.384,411.675c-0.051,0.512-0.154,1.527-0.205,2.031
	c0.572,0.043,1.715,0.137,2.287,0.179c-0.068-0.657-0.196-1.971-0.256-2.628C401.749,411.359,400.836,411.573,400.384,411.675
	 M2318.541,411.53c-1.015,1.314,1.357,2.355,2.202,1.271C2321.732,411.462,2319.309,410.387,2318.541,411.53 M81.894,411.991
	c-0.35,0.862-0.708,1.724-1.058,2.594c1.101,0.299,2.193,0.589,3.294,0.896c0.026-0.597,0.085-1.783,0.119-2.381
	C83.465,412.725,82.68,412.349,81.894,411.991 M75.614,414.986c1.562,0.469,3.132,0.939,4.71,1.348
	c-0.521-1.451-1.493-2.697-2.927-3.328C76.723,413.587,76.169,414.295,75.614,414.986 M2328.004,413.186
	c-0.06,0.572-0.179,1.715-0.239,2.287c0.674,0.196,1.365,0.401,2.091,0.606c0-0.486,0.009-1.459,0.017-1.937
	C2329.216,413.817,2328.593,413.501,2328.004,413.186 M46.293,413.996c-1.655,0.922-3.413,2.295-5.436,1.783
	c-0.316,0.384-0.956,1.161-1.271,1.545c-0.99-0.341-1.98-0.683-2.97-1.007c-0.119,2.193-0.196,4.395-0.265,6.605
	c1.024-0.213,2.039-0.41,3.072-0.589c1.041,0.657,2.099,1.297,3.149,1.954c0.358-0.367,1.067-1.092,1.425-1.451
	c-0.401-1.297-1.946-1.98-2.765-3.021c1.801-0.06,3.618,0.026,5.367,0.512c0.196-0.367,0.597-1.101,0.794-1.459
	c0.461,0.265,1.382,0.802,1.843,1.075c0.188-0.495,0.546-1.485,0.725-1.98c1.033,0.145,2.074,0.316,3.106,0.486
	c1.135-0.222,2.261-0.444,3.396-0.657c-1.05-2.398-3.925-1.161-5.922-1.417c-0.546-1.092-1.502-1.835-2.423-2.586
	C47.667,413.843,46.754,413.945,46.293,413.996 M2321.22,415.737c-1.067,1.092,0.631,3.2,1.843,2.082
	C2324.113,416.744,2322.372,414.517,2321.22,415.737 M2324.207,415.396c0.009,1.408,0.367,2.79,0.913,4.096
	c0.785,0.299,1.604,0.614,2.449,0.939c0.759-0.845,1.962-1.51,1.928-2.816c-0.589-0.128-1.758-0.393-2.346-0.521
	C2326.784,415.643,2325.427,415.515,2324.207,415.396 M2290.278,416.531c0.009,0.649,0.043,1.937,0.06,2.577
	c1.041-0.683,2.056-1.434,2.705-2.551C2292.352,416.548,2290.969,416.539,2290.278,416.531 M2244.19,417.358
	c-0.017,0.606-0.06,1.809-0.077,2.415c0.529-0.102,1.604-0.299,2.133-0.393c0-0.41-0.009-1.237-0.009-1.647
	C2245.726,417.64,2244.702,417.452,2244.19,417.358 M2275.567,420.371c0.666,0.077,1.997,0.23,2.662,0.299
	c0.068-1.067,0.145-2.125,0.256-3.183C2277.171,418.109,2275.891,418.86,2275.567,420.371 M2293.333,420.891
	c1.698-0.162,2.791-1.118,2.85-2.893C2294.835,418.57,2293.256,419.116,2293.333,420.891 M2330.965,417.99
	c-0.435,1.195-0.034,1.596,1.212,1.22C2332.561,418.015,2332.16,417.614,2330.965,417.99 M2348.015,421.975
	c1.766-0.435,3.106-1.698,4.574-2.731c-0.887-0.461-1.672-1.792-2.748-1.143C2348.126,418.536,2348.279,420.644,2348.015,421.975
	 M395.887,419.261c-1.306,0.811,0.077,3.021,1.331,1.997C398.464,420.413,397.158,418.178,395.887,419.261 M641.289,419.441
	c-0.009,0.401-0.017,1.212-0.026,1.613c0.461,0.111,1.374,0.333,1.835,0.444c0.034-0.649,0.094-1.946,0.128-2.586
	C642.739,419.039,641.775,419.313,641.289,419.441 M388.275,422.129c-0.802-0.512-1.596-1.033-2.372-1.545
	c-1.246-0.034-2.492-0.085-3.721-0.154C383.488,422.274,386.552,424.945,388.275,422.129 M420.147,419.901
	c0.401,1.178,1.399,1.86,2.449,2.423c0.128-0.606,0.393-1.835,0.521-2.441C422.374,419.884,420.89,419.901,420.147,419.901
	 M48.435,420.337c0.051,0.657,0.154,1.98,0.196,2.637c0.649,0.009,1.929,0.017,2.569,0.017
	C50.586,421.813,49.656,420.891,48.435,420.337 M2562.466,420.746c-1.237,0.589-1.305,1.152-0.213,1.715
	C2563.413,421.864,2563.482,421.292,2562.466,420.746 M2302.455,421.651c-0.956,1.22,1.152,2.551,2.082,1.451
	C2305.527,421.855,2303.292,420.447,2302.455,421.651 M415.531,422.513c-1.067,0.862,0.145,3.106,1.417,2.227
	C418.005,423.861,416.819,421.54,415.531,422.513 M2274.27,422.854c-0.649,2.022,2.85,0.819,3.712,0.205
	C2277.598,421.676,2275.081,421.992,2274.27,422.854 M2294.904,426.464c-0.777-0.23-1.519-0.452-2.244-0.657
	c0.068,1.459,0.162,2.918,0.239,4.395c-1.869-1.101-3.55,2.876-1.195,2.313c2.569-1.758,5.845-1.468,8.346-3.439
	c0.145,0.461,0.444,1.382,0.589,1.843c0.495,0,1.476-0.009,1.963-0.009c0.205-0.512,0.623-1.545,0.828-2.057
	c0.495-0.247,1.485-0.751,1.988-0.998c-0.034-0.469-0.085-1.399-0.111-1.86c-0.265-0.196-0.794-0.589-1.058-0.776
	c-0.512,0.99-0.956,2.005-1.493,2.97c-0.964,0.196-1.801-0.06-2.509-0.768c0.589-1.715,0.93-3.499,1.152-5.291
	C2298.334,421.838,2296.32,424.091,2294.904,426.464 M55.023,423.519c0.794,0.939,1.647,1.826,2.551,2.671
	c0.026-0.742,0.085-2.219,0.111-2.961C57.02,423.306,55.689,423.451,55.023,423.519 M402.244,423.554
	c-0.06,0.725-0.119,1.459-0.179,2.193c1.161-0.06,2.33-0.119,3.499-0.171c0-0.503-0.009-1.519-0.009-2.022
	C404.446,423.545,403.337,423.545,402.244,423.554 M2331.827,423.545c0.452,1.246,1.22,2.355,2.33,3.149
	c0.034-1.024,0.094-2.039,0.179-3.046C2333.713,423.622,2332.459,423.571,2331.827,423.545 M31.693,424.296
	c-1.126,1.118,0.393,2.807,1.621,1.749C35.226,425.107,32.879,422.803,31.693,424.296 M2253.67,427.667
	c0.35,0.845,0.725,1.69,1.144,2.543c1.613-1.417,2.313-3.328,1.894-5.461C2255.65,425.687,2254.635,426.668,2253.67,427.667
	 M2286.105,425.926c-1.459,1.306-2.748,2.799-3.396,4.659c-0.572-0.179-1.101-0.35-1.596-0.521c0.179-0.794,0.384-1.587,0.614-2.372
	c-1.809-0.392-3.601-0.802-5.401-1.041c0.384,0.862,0.811,1.724,1.263,2.586c-0.435,0.751-0.845,1.502-1.22,2.261
	c11.604,2.114,1.115,0.643,12.032-0.299c1.331-1.766,1.767-3.951,1.912-6.11C2288.939,425.567,2287.505,425.798,2286.105,425.926
	 M2320.947,426.813c-0.273,0.333-0.836,0.99-1.109,1.323c1.621-0.077,3.208,0.154,4.446,1.391c2.603,0.043,3.354-2.765,3.806-4.796
	C2325.564,425.038,2323.524,426.788,2320.947,426.813 M36.966,427.317c1.101,0.085,2.202,0.179,3.294,0.282
	c0.879,0.503,1.826,0.836,2.842,0.981c0.068-0.708,0.205-2.133,0.273-2.842C41.318,426.404,38.383,425.346,36.966,427.317
	 M75.076,431.02c-0.196-1.971,1.869-2.185,3.14-2.935c-0.333-0.751-0.674-1.493-1.015-2.236
	C75.273,426.353,71.492,430.525,75.076,431.02 M84.523,425.542c-0.094,0.64-0.273,1.929-0.367,2.569
	c-0.393-0.154-1.169-0.461-1.553-0.614c-0.034,0.444-0.102,1.331-0.137,1.775c-0.759-0.102-2.295-0.299-3.063-0.401
	c0.819,0.973,1.647,1.963,2.466,2.953c0.802-0.384,1.613-0.759,2.415-1.135c0.657-1.289,1.63-2.381,2.304-3.644
	C86.118,426.267,85.436,425.764,84.523,425.542 M131.012,426.984c0.674,1.067,1.22,2.688,2.807,1.954
	c1.331,0-0.009-1.86-0.137-2.586C132.796,426.557,131.9,426.771,131.012,426.984 M522.24,426.165
	c-1.647,1.263,1.22,3.558,1.86,1.425C524.066,426.284,523.452,425.806,522.24,426.165 M2244.25,426.89
	c-1.169,0.802-0.068,3.217,1.271,2.133C2246.63,428.204,2245.53,425.823,2244.25,426.89 M47.718,427.709
	c-1.118,1.34,1.365,2.338,2.159,1.212C51.021,427.581,48.512,426.617,47.718,427.709 M2260.463,427.47
	c-1.732,0.401-0.836,2.867,0.742,2.347C2262.963,429.527,2262.076,426.728,2260.463,427.47 M2245.743,431.37
	c0.58,0.06,1.741,0.188,2.321,0.247c0.427-1.152,0.862-2.313,1.178-3.516C2247.723,428.785,2246.34,429.774,2245.743,431.37
	 M44.16,431.959c0.265,0.418,0.794,1.246,1.067,1.655c-1.417-0.393-2.816-0.819-4.233-1.186c0,0.939,0,1.894-0.009,2.85
	c2.005,0.273,4.07,0.486,5.862,1.519c0.375-0.324,1.126-0.981,1.51-1.306c0.631,0.171,1.903,0.521,2.543,0.691
	c0.222-0.486,0.666-1.451,0.887-1.937c-0.785-1.553-2.569-2.739-0.777-4.412C48.708,430.474,46.43,431.199,44.16,431.959
	 M85.803,434.357c0.657-0.06,1.963-0.179,2.611-0.247c-0.034-0.657-0.111-1.971-0.145-2.628c0.265-0.367,0.794-1.118,1.067-1.485
	C86.801,429.646,86.818,432.812,85.803,434.357 M2456.644,434.306c0.495,1.843-0.017,3.721-0.171,5.572
	c2.347-2.705,3.055-6.374,5.061-9.318l-0.051-0.785C2459.887,431.319,2458.257,432.804,2456.644,434.306 M419.439,431.601
	c-1.485,1.169,0.461,2.987,1.63,1.604C422.434,432.036,420.506,429.911,419.439,431.601 M54.682,432.027
	c-1.229,0.742-0.239,3.217,1.135,2.125C56.986,433.393,56.047,430.892,54.682,432.027 M2426.078,431.925
	c-0.811,1.152,1.177,2.628,2.253,2.031C2429.414,432.625,2427.272,430.722,2426.078,431.925 M2440.533,431.916
	c-1.092,0.828-0.06,3.285,1.297,2.244C2442.812,433.324,2441.83,430.892,2440.533,431.916 M2296.141,436.473
	c1.22,0.128,2.458,0.265,3.746,0.401c0.683-2.048,2.808-2.867,4.301-4.233C2300.792,431.831,2298.095,434.015,2296.141,436.473
	 M131.157,433.265c-0.171,1.212-0.324,2.441-0.452,3.669c1.604-0.179,3.174-1.536,2.961-3.251
	C133.035,433.58,131.78,433.367,131.157,433.265 M2360.243,433.64c-0.947,1.28,1.331,2.483,2.27,1.485
	C2363.349,433.845,2361.105,432.642,2360.243,433.64 M2494.788,433.546c0.512,1.476,0.648,3.575,2.713,3.575
	c-0.213-1.084-0.392-2.15-0.546-3.217C2496.418,433.819,2495.326,433.64,2494.788,433.546 M396.049,434.613
	c-0.768,1.672,2.688,2.031,2.876,0.384C398.592,433.836,396.817,434.024,396.049,434.613 M407.68,434.408c0,0.597,0,1.801,0,2.398
	c0.589-0.119,1.758-0.358,2.338-0.478c-0.051-0.307-0.162-0.93-0.213-1.237C409.276,434.92,408.209,434.579,407.68,434.408
	 M647.799,435.372c-1.604,0.205-1.382,3.046,0.358,2.381C649.779,437.437,649.685,434.562,647.799,435.372 M2285.773,436.968
	c0.103,0.947,0.64,1.485,1.596,1.604c0.998-0.939,0.819-2.364,1.032-3.592C2287.462,435.586,2286.362,435.995,2285.773,436.968
	 M2241.604,437.139c-0.119,1.28,1.229,1.681,2.074,2.372c0.41-1.195,0.854-2.389,1.289-3.584
	C2243.746,436.123,2242.475,436.209,2241.604,437.139 M2290.321,436.951c-0.23,0.247-0.691,0.751-0.922,1.007
	C2290.142,440.799,2293.675,436.379,2290.321,436.951 M2447.403,442.941c0.478,1.203,1.101,2.347,1.698,3.516
	c0.58-0.649,1.195-1.306,1.834-1.946c0.359,0.23,1.084,0.708,1.442,0.947c0.017,0.708,0.034,2.116,0.051,2.825
	c1.348-0.375,2.091-1.451,2.654-2.679c-0.597,0.026-1.783,0.077-2.381,0.102c0.93-3.251,1.988-6.477,2.534-9.83
	C2452.523,438.137,2450.253,440.859,2447.403,442.941 M2477.926,437.028c-0.35,1.152,0.085,1.647,1.314,1.468
	C2479.522,437.326,2479.087,436.84,2477.926,437.028 M2501.214,437.036c-0.811,0.666-1.05,1.459-0.725,2.372
	C2501.99,440.305,2503.236,436.012,2501.214,437.036 M2302.191,440.151c1.246,0.768,2.364-1.493,1.357-2.321
	C2302.165,436.883,2301.099,439.289,2302.191,440.151 M51.081,438.598c-0.017,0.427-0.043,1.28-0.051,1.707
	c-2.782,0-5.564-0.017-8.337,0.102c1.186,2.219,4.181,3.439,6.025,1.195c1.519,1.647,3.746,0.998,5.632,0.538
	c2.731,1.169,5.828,2.321,8.721,0.93c-2.441-1.348-5.299-2.116-6.997-4.497C54.417,438.342,52.736,438.547,51.081,438.598
	 M408.815,440.816c1.118,0.922,2.748-1.178,1.929-2.202C409.387,437.378,407.774,439.605,408.815,440.816 M38.434,439.895
	c-1.417,1.161,0.794,3.14,1.877,1.732C41.481,440.467,39.509,438.786,38.434,439.895 M419.209,439.656
	c-0.845,1.118,1.007,2.79,2.091,2.057C422.417,440.458,420.42,438.367,419.209,439.656 M627.209,439.673
	c-1.698,0.239-1.229,3.473,0.444,2.833C628.216,441.747,628.403,439.886,627.209,439.673 M2359.373,439.673
	c-1.067,1.331,1.348,2.449,2.202,1.314C2362.428,439.69,2360.149,438.564,2359.373,439.673 M2468.207,446.568
	c0.342,0.188,0.734,0.358,1.169,0.512c2.517-2.304,5.214-4.386,7.885-6.519c-0.393-0.247-1.161-0.759-1.545-1.007
	C2473.148,441.832,2470.929,444.477,2468.207,446.568 M402.68,440.518c0,0.563-0.009,1.69-0.009,2.253
	c1.664-0.341,3.328-0.666,5.009-0.93c0-0.367,0.009-1.109,0.017-1.476C406.025,440.467,404.344,440.433,402.68,440.518
	 M446.857,440.296c0.077,0.597,0.247,1.801,0.333,2.406c0.41,0.145,1.229,0.427,1.638,0.563c0.546-0.828,1.084-1.664,1.63-2.492
	C449.254,440.612,448.051,440.45,446.857,440.296 M2267.025,441.141c0.051,0.597,0.137,1.801,0.188,2.398
	c1.348-0.648,2.91-1.288,3.268-2.944C2269.295,440.748,2268.151,440.936,2267.025,441.141 M2304.563,440.501
	c0.29,0.836,0.623,1.664,1.016,2.483c1.792,0.333,1.468-1.527,1.741-2.714C2306.628,440.33,2305.254,440.441,2304.563,440.501
	 M2267.665,446.457c1.954-0.649,3.755-1.69,5.41-2.944c-0.026-0.606-0.068-1.801-0.094-2.398
	C2270.985,442.651,2268.518,443.914,2267.665,446.457 M2478.25,441.26c-0.879,0.666-1.724,1.331-2.56,1.988
	c-1.385,1.896-10.866,9.164-13.457,10.607c0.068-0.683,0.213-2.048,0.29-2.739c-1.007,0.802-1.928,1.613-2.859,2.441
	c0.58,0.102,1.741,0.316,2.313,0.418c-1.741,1.126-3.797,1.903-4.949,3.712c13.447-4.593,11.749-6.188,13.824-8.431
	c0.051,0.563,0.162,1.681,0.222,2.244c2.586-1.877,5.41-3.422,8.073-5.214c1.109-0.026,2.244-0.026,3.413,0.009
	c-3.106,3.311-9.284,3.379-10.104,8.712c2.048-0.631,4.088-1.391,6.127-2.142c0.461-1.143,0.938-2.278,1.451-3.413
	c0.085,1.946,1.186,2.509,2.636,1.067c1.493,0.666,2.995,1.348,4.54,2.005c-0.384-1.007-0.725-2.014-1.032-3.012
	c1.084,0.734,2.193,1.502,3.336,2.253c0.213,2.005,1.715,3.575,3.849,3.465c-0.648-1.007-1.246-1.963-1.766-2.987
	c0.461-0.444,1.399-1.323,1.86-1.766C2488.286,447.566,2482.859,445.066,2478.25,441.26 M2235.784,442.557
	c-0.094,0.521-0.281,1.553-0.367,2.065c0.947-0.196,1.928-0.393,2.953-0.572c0.009-0.375,0.042-1.126,0.051-1.502
	C2237.508,442.54,2236.629,442.549,2235.784,442.557 M405.402,444.238c1.101,1.161,2.594,1.485,3.831,0.256
	c-0.239-0.444-0.725-1.34-0.964-1.792C407.305,443.206,406.349,443.718,405.402,444.238 M2365.466,443.871
	c-0.06,0.589-0.171,1.775-0.23,2.364c-1.169,0.077-2.304,0.154-3.422,0.23c-0.009,0.632-0.026,1.894-0.034,2.526
	c0.393-0.307,1.169-0.939,1.553-1.246c0.913,0.444,1.86,0.896,2.833,1.34c-0.051-1.229-0.094-2.458-0.111-3.678
	c1.152-0.017,2.338-0.034,3.55-0.043C2370.21,442.318,2367.172,443.846,2365.466,443.871 M53.734,444.691
	c-0.068,0.589-0.205,1.766-0.282,2.355c0.555,0.06,1.655,0.188,2.202,0.256c1.024,0.546,2.116,0.683,3.277,0.41
	C57.574,446.15,55.526,445.578,53.734,444.691 M125.756,444.204c0.265,1.553,1.442,2.483,2.816,3.038
	C128.922,445.339,127.701,443.999,125.756,444.204 M129.297,444.605c0.162,0.64,0.495,1.92,0.657,2.56
	c0.853-0.657,1.621-1.417,2.261-2.278C131.482,444.819,130.022,444.674,129.297,444.605 M392.986,445.015
	c-0.017,0.375-0.051,1.126-0.068,1.502c1.118,0.077,2.244,0.162,3.379,0.247c0.077-0.529,0.23-1.579,0.307-2.108
	C395.392,444.767,394.189,444.887,392.986,445.015 M2431.949,444.793C2432.418,445.288,2432.418,445.288,2431.949,444.793
	 M2455.927,447.447c-1.007,1.596-1.997,3.226-2.355,5.103c3.567-0.998,4.386-5.239,5.76-8.209
	C2457.386,444.273,2456.772,446.116,2455.927,447.447 M2247.449,447.31c0.043,0.64,0.136,1.928,0.179,2.577
	c1.348-1.212,2.586-2.586,3.251-4.318C2249.472,445.578,2248.439,446.474,2247.449,447.31 M376.875,447.566
	c0.589,1.271,1.212,2.517,1.886,3.746c0.41-0.239,1.229-0.717,1.647-0.956c-0.341-1.289-0.666-2.577-0.973-3.857
	C378.581,446.85,377.728,447.208,376.875,447.566 M2234.291,446.969c-0.137,0.478-0.401,1.425-0.529,1.894
	c1.007-0.043,2.057-0.085,3.123-0.119c0.213-0.486,0.623-1.468,0.828-1.954C2236.535,446.85,2235.392,446.909,2234.291,446.969
	 M2263.979,446.449c-1.229-0.077-2.995,1.971-1.203,2.543C2264.218,449.589,2265.677,446.867,2263.979,446.449 M380.86,449.009
	c0.666-0.239,1.997-0.725,2.662-0.964c1.57,0.998,2.978,2.227,4.591,3.166c1.442-0.179,2.842-0.597,4.275-0.896
	c-0.213-0.384-0.64-1.161-0.853-1.545c-1.186,0.017-2.364,0.051-3.533,0.085c0.111-0.41,0.35-1.229,0.461-1.638
	C385.775,447.14,382.251,445.791,380.86,449.009 M2516.514,453.301c-1.024,0.973-1.656,2.244-2.159,3.533
	c1.758-0.7,2.773-2.569,4.642-3.072c-1.536,3.925-5.368,6.519-8.73,8.841c0.435,1.57,0.922,3.115,1.57,4.634
	c-0.094,0.239-0.265,0.717-0.359,0.956c0.324,3.14,3.43,5.111,3.379,8.294c1.118-0.589,2.679-1.254,2.278-2.842
	c-1.135-0.905-2.056-1.98-2.799-3.183c1.178-0.734,2.398-1.442,3.626-2.159c-0.128-0.265-0.401-0.785-0.537-1.05
	c1.186-4.412,2.125-9.003,4.719-12.86c0.009-2.748,0.111-5.495,0.648-8.192C2520.371,448.275,2518.75,451.057,2516.514,453.301
	 M140.322,448.283c0.973,2.065,3.354,1.545,4.301-0.29C143.181,448.044,141.747,448.138,140.322,448.283 M2234.47,454.922
	c3.72-0.504,5.094-4.676,7.833-6.827C2238.421,448.002,2236.186,452.029,2234.47,454.922 M2258.014,447.976
	c-1.118,2.057-2.842,3.661-3.994,5.675c0.725-0.162,1.468-0.324,2.253-0.478c1.297,0.597,2.671,1.152,4.045,1.681
	c-0.213-0.273-0.648-0.828-0.87-1.109c0.316-0.683,0.657-1.365,1.024-2.039c-0.7-0.29-2.116-0.879-2.816-1.178l1.946-0.171
	C2259.217,449.393,2259.516,447.694,2258.014,447.976 M2373.999,448.198c-1.305,0.102-1.971,2.313-0.384,2.551
	C2375.296,451.099,2376.124,447.592,2373.999,448.198 M2467.405,448.258C2467.55,448.565,2467.55,448.565,2467.405,448.258
	 M36.497,448.94c-1.664,0.444-0.785,3.055,0.819,2.543C39.023,451.133,37.914,448.59,36.497,448.94 M115.994,449.196
	c0,0.546,0.009,1.63,0.017,2.176c1.254-0.145,2.423-0.461,2.321-1.963C117.752,449.358,116.574,449.247,115.994,449.196
	 M2243.465,449.111c-0.947,0.051-2.142,1.109-1.596,2.074C2242.978,452.115,2245.069,449.666,2243.465,449.111 M2248.721,451.193
	c1.024,0.759,2.611-0.888,1.937-1.929C2249.634,448.735,2248.004,450.161,2248.721,451.193 M41.865,449.794
	c-1.143,1.323,1.271,2.372,2.048,1.178C45.124,449.631,42.641,448.531,41.865,449.794 M2494.25,450.092
	c-0.905,0.853,0.239,2.918,1.434,2.022C2496.563,451.253,2495.317,449.316,2494.25,450.092 M392.798,451.918
	c0.563,1.664,2.731,0.64,4.028,0.93C396.049,451.526,393.643,449.708,392.798,451.918 M2245.146,450.792
	c-0.324,0.794-0.614,1.604-0.879,2.415c1.553-0.239,2.671-1.246,3.473-2.56C2247.091,450.681,2245.794,450.749,2245.146,450.792
	 M2482.133,453.335c-0.034,1.306-0.034,2.62,0,3.942c0.409,0.213,1.22,0.64,1.63,0.853c0.026-2.014,0.051-4.028,0.094-6.033
	C2483.422,452.405,2482.56,453.028,2482.133,453.335 M35.951,455.451c1.178,1.442,3.499-0.811,1.63-1.749
	C36.352,452.584,34.79,454.316,35.951,455.451 M39.04,454.376c0.538,0.734,1.101,1.451,1.69,2.159
	c0.913-0.179,1.801-0.478,2.637-0.896c0.99,0.555,1.997,1.707,3.081,0.666c-0.768-1.323-2.27-1.92-3.49-2.722
	C41.643,452.567,40.294,453.949,39.04,454.376 M525.474,453.284c-1.195,0.87,0.213,2.987,1.442,2.048
	C528.111,454.453,526.711,452.26,525.474,453.284 M522.249,454.274c-0.973,1.203,1.229,2.193,2.014,1.229
	C525.107,454.333,523,453.275,522.249,454.274 M2245.18,454.163c-0.085,0.674-0.248,2.014-0.324,2.688
	c1.203-0.572,2.142-1.519,2.722-2.748C2246.98,454.12,2245.777,454.146,2245.18,454.163 M408.286,457.337
	c0.64-0.051,1.92-0.154,2.569-0.205c0.009-0.631,0.034-1.894,0.051-2.526C409.813,455.289,408.849,456.151,408.286,457.337
	 M451.755,456.373c-0.853-0.256-1.698-0.503-2.543-0.734c0.375,0.939,0.87,1.86,1.468,2.688c1.033-0.444,2.074-0.879,3.123-1.297
	c-0.137-0.589-0.418-1.766-0.555-2.347C452.736,455.238,452.241,455.801,451.755,456.373 M2476.407,456.117
	c1.348,0.87,2.756,0.085,3.448-1.229C2478.746,455.229,2475.793,454.111,2476.407,456.117 M136.704,456.211
	c0.077,0.742,0.162,1.502,0.239,2.261c0.87,0.128,1.732,0.256,2.603,0.401c0.043-0.572,0.111-1.715,0.145-2.287
	C138.692,456.45,137.702,456.339,136.704,456.211 M393.728,457.789c1.143,1.417,3.166-0.742,1.903-1.911
	C394.505,454.871,392.738,456.671,393.728,457.789 M518.904,455.938c-1.408,1.135,0.631,3.439,1.647,1.655
	C521.788,456.398,520.064,454.564,518.904,455.938 M629.598,457.9c0.606,0.094,1.818,0.265,2.424,0.35
	c0.162-0.734,0.333-1.468,0.512-2.193c-0.529-0.171-1.596-0.521-2.125-0.7C629.871,456.083,629.606,456.936,629.598,457.9
	 M2418.261,455.426c-0.042,0.444-0.119,1.331-0.162,1.775c1.203,0.273,2.449,0.572,3.712,0.819
	C2421.171,456.526,2419.55,456.083,2418.261,455.426 M2462.754,457.235c0.947,0.648,3.2-0.085,2.961-1.417
	C2464.725,455.443,2462.677,456.074,2462.754,457.235 M2179.379,456.723c-1.152,0.828,0.034,3.174,1.357,2.193
	C2181.751,458.054,2180.659,455.724,2179.379,456.723 M2395.435,456.723c-1.16,1.195,0.956,2.654,1.988,1.707
	C2398.669,457.149,2396.527,455.204,2395.435,456.723 M2471.868,456.518c-0.555,1.476-0.896,3.012-0.811,4.599
	c2.065-0.811,3.754-2.423,4.574-4.514C2474.351,456.561,2473.088,456.535,2471.868,456.518 M2489.301,456.612
	c-0.145,0.64-0.452,1.92-0.597,2.569c2.022,0.631,4.147,0.981,6.281,1.306c-0.111-0.427-0.324-1.272-0.427-1.69
	C2492.604,458.489,2491.136,457.004,2489.301,456.612 M42.616,457.585c-1.664,0.427-0.435,2.577,0.905,2.219
	C45.15,459.462,43.989,456.979,42.616,457.585 M59.682,457.602c-1.033,1.357,1.468,2.278,2.261,1.22
	C62.976,457.457,60.484,456.543,59.682,457.602 M427.477,457.602c0.094,1.451,0.973,3.285-1.075,3.857
	c0.7,0.708,1.417,1.408,2.142,2.116c0.836-1.092,1.681-2.176,2.5-3.277l-1.835,0.401c0.137-0.734,0.418-2.219,0.555-2.961
	C429.193,457.704,428.049,457.636,427.477,457.602 M2475.929,459.914c1.289,0,2.603-0.009,3.951-0.026
	C2479.642,457.149,2475.435,456.475,2475.929,459.914 M380.109,458.37c-0.64,0.64-1.271,1.297-1.911,1.954
	c0.196,0.256,0.58,0.776,0.777,1.041c1.058-0.375,2.099-0.751,3.166-1.109C381.466,459.615,380.783,458.993,380.109,458.37
	 M423.057,458.523c0.555,0.93,0.87,2.313,2.236,2.253c0.828-0.708,1.22-1.604,1.186-2.688
	C425.327,458.182,424.192,458.327,423.057,458.523 M2245.624,462.372c0.58,0.009,1.74,0.017,2.321,0.026
	c1.903-1.186,3.78-2.423,5.564-3.823C2250.411,457.866,2247.373,459.965,2245.624,462.372 M2252.587,461.237
	c-0.034,1.075-0.043,2.15,0,3.234c3.072-0.742,4.309-3.883,5.214-6.579C2256.009,458.941,2254.344,460.17,2252.587,461.237
	 M2401.348,461.041c-0.205,2.526-2.773,0.674-4.267,0.631c-0.085,1.331-0.12,2.671-0.12,4.011c1.058,0.094,2.142,0.196,3.26,0.299
	c0,0.401-0.009,1.212-0.017,1.613c-0.418,0.026-1.254,0.085-1.673,0.111c0.23,2.603-0.87,6.972,2.483,7.876
	c-0.281-2.995-0.444-6.059,1.067-8.789l0.623-0.051c0.051-0.521,0.171-1.57,0.222-2.099c1.152,1.997-0.947,4.548-0.068,6.844
	c0.393-0.879,0.725-1.783,0.99-2.714c1.263-2.074,2.364-4.429,4.489-5.777c0.888-1.604,1.698-3.251,2.415-4.941
	C2407.441,458.472,2404.471,460.025,2401.348,461.041 M2461.636,459.462c1.357,1.203,2.167,3.695,4.062,3.883
	c0.973-1.562,1.092-3.422,1.195-5.214C2465.126,458.583,2463.368,459.035,2461.636,459.462 M2521.677,458.259
	c-0.256,0.521-0.76,1.579-1.007,2.099c-0.145,2.526-0.418,5.052-0.87,7.543c0.316-0.154,0.947-0.461,1.263-0.623
	c0.665-2.978,1.212-6.007,1.263-9.071L2521.677,458.259 M2524.066,458.446c-0.913,0.623-0.145,3.038,0.905,1.741
	C2525.662,459.522,2525.175,457.311,2524.066,458.446 M36.984,458.941c-0.717,0.734-1.442,1.468-2.176,2.21
	c-0.009,0.486-0.034,1.468-0.043,1.963c0.691-0.137,1.391-0.273,2.082-0.392c0.93,0.623,1.877,1.22,2.833,1.809l0.094,0.58
	c-0.572,0.145-1.707,0.435-2.278,0.58c0.649,1.092,1.621,1.792,2.876,1.98c-0.034,2.244,2.406,2.125,2.33-0.068
	c0.444-0.026,1.323-0.068,1.766-0.085c-1.067-2.048-2.748-3.098-5.009-1.63l0.802-1.562c0.631-0.128,1.886-0.384,2.517-0.512
	c-0.7-2.304-2.842-1.485-4.617-1.152C39.672,461.023,38.434,459.897,36.984,458.941 M388.292,459.266
	c-0.879,1.246,1.323,2.5,2.193,1.434C391.45,459.385,389.163,458.156,388.292,459.266 M411.324,459.291
	c-1.058,1.297,1.306,2.449,2.116,1.28C414.515,459.257,412.134,458.148,411.324,459.291 M445.935,459.121
	c0.504,1.809,2.193,2.381,3.866,2.688C449.357,459.906,447.77,459.086,445.935,459.121 M2182.084,459.633
	c-0.043,0.896-0.051,1.792-0.034,2.705c0.427-0.06,1.28-0.188,1.707-0.256c0.299-0.845,0.631-1.69,1.007-2.517
	C2183.842,459.59,2182.946,459.615,2182.084,459.633 M2261.427,462.79c1.911-0.597,3.951-1.673,4.173-3.917
	C2263.552,459.317,2262.05,460.827,2261.427,462.79 M2427.23,459.496c0.819,1.314,2.338,1.271,3.763,1.51
	c0.213-0.495,0.631-1.493,0.845-1.988C2430.268,459.146,2428.732,459.3,2427.23,459.496 M2481.689,459.086
	c-0.717,1.118,1.186,2.338,2.056,1.408C2484.489,459.325,2482.483,458.088,2481.689,459.086 M437.581,460.273
	c-0.444,1.374,0.043,1.835,1.451,1.399C439.45,460.29,438.963,459.829,437.581,460.273 M2305.638,460.145
	c-1.058,1.34,1.391,2.321,2.219,1.22C2308.889,460.008,2306.389,459.061,2305.638,460.145 M2457.208,460.059
	c0.358,0.828,0.759,1.655,1.195,2.483c-0.512,0.026-1.536,0.077-2.048,0.102c-0.102,1.212,0.35,2.074,1.365,2.569
	c-0.23,1.399-1.075,3.456,1.152,3.447c-0.009-0.478-0.034-1.425-0.043-1.894c0.7-0.026,2.108-0.077,2.808-0.102
	c0.009-0.375,0.034-1.135,0.043-1.51c-1.391-0.35-2.825-0.657-4.198-0.828c0.947-0.819,1.997-1.587,3.132-2.21
	c-0.333-0.742-0.64-1.476-0.913-2.202C2459.076,459.948,2457.83,460.025,2457.208,460.059 M47.539,461.493
	c0.043,0.64,0.111,1.937,0.145,2.586c1.101-0.469,2.202-0.93,3.302-1.408C50.04,461.698,48.896,461.1,47.539,461.493
	 M2438.332,463.191c0.657-0.205,1.98-0.606,2.646-0.802c-0.103,0.759-0.29,2.287-0.393,3.055c0.427-0.111,1.263-0.333,1.681-0.444
	c-0.145-0.828-0.358-1.681-1.032-2.219c0.085-0.495,0.247-1.485,0.333-1.98C2439.953,460.802,2438.562,461.476,2438.332,463.191
	 M2475.059,463.293c1.126,0.017,2.287,0.034,3.482,0.051c0.265-0.41,0.793-1.229,1.058-1.638
	C2477.918,461.595,2475.025,460.614,2475.059,463.293 M443.443,463.763c0.64,0.06,1.903,0.188,2.534,0.256
	c-0.051-0.631-0.162-1.894-0.222-2.534C444.911,462.167,444.134,462.926,443.443,463.763 M2488.67,464.189
	c3.149-0.93,8.329,1.545,9.865-2.364c-2.073,0.137-4.096,0.316-6.135,0.521c-0.461-0.205-1.374-0.623-1.835-0.828
	C2489.728,462.269,2488.969,463.097,2488.67,464.189 M646.52,464.317c1.135,0.418,2.278,0.862,3.43,1.306
	c-0.017-1.126-0.026-2.253-0.026-3.371C648.61,462.636,647.253,463.071,646.52,464.317 M2262.767,464.266
	c-1.05,0.683-1.562,1.98-2.33,2.927c0.205,2.278,1.237,5.308-0.708,7.049c-2.108-0.734-0.734-3.311-1.007-4.915l-0.905-0.034
	c-0.06,0.504-0.171,1.493-0.23,1.988c-0.845-0.384-1.664-0.777-2.441-1.161c-1.331,2.85,1.186,4.83,2.321,7.185
	c0.401-0.614,0.819-1.229,1.263-1.835c1.203,0.973,2.355,0.333,3.448-0.504c0.521,0.102,1.553,0.316,2.065,0.418
	c-0.444-1.22-0.213-2.432,0.281-3.592c-0.512-0.358-0.99-0.708-1.442-1.05c1.229-0.256,2.278-0.964,3.413-1.553
	c-0.444,1.903-1.126,3.729-1.613,5.615c1.98-1.195,3.542-3.149,6.067-3.226c1.195-0.708,2.475-1.246,3.84-1.673
	c0.358,0.23,1.075,0.683,1.434,0.913c0.495-0.444,1.485-1.314,1.98-1.749c-1.22-0.512-2.458-0.043-3.601,0.247
	c0.222-0.512,0.657-1.545,0.879-2.057c-0.64,0.128-1.929,0.367-2.577,0.495c2.726-3.327,1.052-2.674-4.446-2.705
	c1.058-0.947,2.15-1.869,3.251-2.816C2268.493,462.141,2265.916,464.727,2262.767,464.266 M2293.641,463.225
	c0.222,0.435,0.665,1.306,0.887,1.741c1.016,0.162,2.099,0.341,3.183,0.521c0.367-1.033,0.708-2.074,1.126-3.106
	C2297.071,462.645,2295.364,462.935,2293.641,463.225 M139.238,463.507c-0.819,1.946,3.524,2.065,2.62,0.034
	C140.996,462.926,140.117,462.918,139.238,463.507 M549.487,463.515C549.973,464.01,549.973,464.01,549.487,463.515
	 M2421.137,465.41c-0.478,0.538-0.913,1.092-1.306,1.655c0.384,0.196,1.144,0.589,1.519,0.777c0.683,1.348-0.333,2.748-0.478,4.113
	c2.321-1.272,3.064-4.019,4.31-6.187c-1.015,0.367-2.006,0.734-2.961,1.101c-1.57-0.58,0.546-1.877,0.777-2.782
	C2421.786,463.865,2420.352,463.549,2421.137,465.41 M2434.79,464.01c-0.614,1.101-1.963,0.964-2.961,1.246
	c-0.222,0.393-0.649,1.186-0.862,1.579c2.91-0.973,6.536-0.222,8.883-2.5C2438.135,464.172,2436.454,464.078,2434.79,464.01
	 M547.046,464.343c-0.486,1.186-0.111,1.57,1.118,1.161C548.651,464.309,548.284,463.925,547.046,464.343 M2238.856,464.172
	c-0.495,1.399-0.034,1.835,1.399,1.314C2240.691,464.07,2240.222,463.626,2238.856,464.172 M2413.227,464.326
	c-1.126,1.263,1.144,2.56,2.014,1.34C2416.29,464.411,2414.02,463.089,2413.227,464.326 M46.157,465.922
	c-0.009,0.521-0.017,1.562-0.026,2.082c3.191-0.794,6.281,0.828,9.489,0.461c-0.896-1.178-2.116-1.852-3.584-1.971
	C50.287,464.923,48.188,464.872,46.157,465.922 M525.295,465.102c0.316,1.237,1.203,2.167,2.065,3.072
	c0.546-0.06,1.621-0.179,2.167-0.239c-0.017-0.401-0.043-1.195-0.06-1.596C528.077,465.905,526.694,465.478,525.295,465.102
	 M2299.759,465.051c-1.416,0.358-0.811,2.569,0.606,1.946C2301.85,466.681,2301.175,464.275,2299.759,465.051 M2406.502,466.152
	c-0.555,2.33-1.434,4.557-2.219,6.81c0.444-0.119,1.323-0.341,1.766-0.461c1.126-2.355,1.519-5.001,2.432-7.45
	C2407.791,465.418,2407.134,465.785,2406.502,466.152 M2426.812,465.93c-0.239,0.435-0.699,1.297-0.938,1.724
	c1.63,0.111,5.086,0.282,3.951-2.389C2428.783,465.418,2427.767,465.683,2426.812,465.93 M2474.948,465.077
	c-0.887,1.152,1.118,2.167,1.954,1.28C2477.909,465.188,2475.69,464.036,2474.948,465.077 M2414.899,471.366
	c1.911-0.905,4.309-3.251,3.191-5.538C2415.727,466.579,2415.599,469.395,2414.899,471.366 M2441.608,468.166l0.742,0.085
	c0.094,0.341,0.273,1.024,0.367,1.365c-2.27,0.469-4.13,1.843-5.803,3.337c0.256,0.418,0.777,1.254,1.033,1.673
	c1.195-0.111,2.261-0.563,3.191-1.365c0.409,0.401,0.836,0.819,1.297,1.237l-1.647,0.742c1.186,0.034,2.406,0.06,3.652,0.085
	c-0.06-0.546-0.171-1.621-0.23-2.167c-0.683-0.444-1.323-0.887-1.92-1.348c2.185,1.109,3.703-0.819,4.761-2.338
	c-0.742-1.152-3.302-0.563-3.217-1.894C2446.148,464.556,2440.473,467.176,2441.608,468.166 M2501.316,465.87
	c-1.246,2.082-2.364,4.232-3.191,6.511c1.451,0.341,2.978,0.734,4.437,1.22c0.563-0.981,1.152-1.963,1.758-2.944
	c0.358,0.418,0.743,0.853,1.152,1.297c-2.697,1.801-0.333,4.301,1.86,4.838c0.409-2.167-0.521-4.173-1.178-6.17
	c-0.333-0.179-0.998-0.546-1.34-0.725c-0.162-1.348-0.273-2.688-0.376-4.019C2503.364,465.862,2502.323,465.862,2501.316,465.87
	 M2430.242,468.575c-0.887,1.075-1.775,2.193-2.159,3.55c3.123-1.323,6.46-2.483,8.986-4.864
	C2434.765,467.662,2432.512,468.174,2430.242,468.575 M419.832,467.935c-1.101,1.28,1.289,2.534,2.125,1.357
	C422.904,468.012,420.676,466.835,419.832,467.935 M512.802,467.859c-1.263,0.034-1.707,2.381-0.213,2.406
	C513.792,470.188,514.202,467.901,512.802,467.859 M2240.162,470.726c0.478-0.009,1.434-0.017,1.911-0.017
	c0.026-1.135,0.068-2.27,0.128-3.396C2240.554,467.714,2240.503,469.395,2240.162,470.726 M2491.016,473.329
	c1.536-0.947,3.063-4.25,4.745-1.579c0.981-1.331,1.86-2.748,2.697-4.19c-1.203-0.034-2.364-0.017-3.499,0.06
	C2492.612,468.516,2491.46,471.025,2491.016,473.329 M2530.577,472.996c2.773-0.085,3.977-2.654,4.873-4.881
	C2533.367,469.147,2530.594,470.239,2530.577,472.996 M588.442,472.671c0.418,0.171,1.254,0.504,1.664,0.666
	c0.034-1.459,0.051-2.918,0.051-4.369C588.672,469.702,588.484,471.187,588.442,472.671 M627.866,469.352
	c0.085,0.649,0.256,1.946,0.341,2.594c1.485-0.188,2.594-1.075,3.277-2.389C630.272,469.471,629.069,469.403,627.866,469.352
	 M2234.052,469.463c-1.425,1.126,0.444,3.49,1.57,1.715C2236.894,470.069,2235.213,468.098,2234.052,469.463 M83.891,472.697
	c1.161-0.043,2.313-0.094,3.473-0.145c0.026-0.529,0.085-1.57,0.111-2.099C86.11,470.914,84.856,471.605,83.891,472.697
	 M2423.202,470.052c0.06,0.614,0.188,1.852,0.248,2.466c0.563,0.085,1.698,0.247,2.261,0.324
	C2426.682,470.1,2428.039,469.921,2423.202,470.052 M2434.381,472.731c1.135,0.495,2.833-1.024,2.44-2.219
	C2435.729,470.333,2434.133,471.605,2434.381,472.731 M2458.163,474.677c1.365-1.246,4.438-3.217,1.963-5.069
	C2458.615,470.803,2458.709,472.987,2458.163,474.677 M2507.11,470.282c-0.913,1.058,0.725,2.91,1.894,2.065
	C2509.85,471.298,2508.211,469.429,2507.11,470.282 M2250.061,473.943c0.316,0.589,0.956,1.766,1.271,2.364
	c-2.859,0.299-3.968-2.586-5.12-4.582c-1.485,0.085-2.662,0.896-3.729,1.792c-0.444,2.406-1.101,4.779-1.801,7.125
	c1.417-0.666,2.85-1.34,4.292-2.031c0.316,0.145,0.956,0.427,1.28,0.572c2.116-1.459,4.864-1.084,7.057-2.355
	c-0.546-2.048-0.879-4.122-0.486-6.229C2251.759,471.605,2250.871,472.74,2250.061,473.943 M2394.343,471.229
	c-1.664,0.435-1.075,3.209,0.699,2.594C2396.442,473.405,2395.767,470.803,2394.343,471.229 M2416.657,475.061
	c0.717-0.247,1.468-0.495,2.244-0.734c0.376-1.169,0.76-2.423-0.111-3.482C2416.964,471.451,2417.041,473.542,2416.657,475.061
	 M88.841,472.467c1.442,1.271-0.597,2.389-1.135,3.465c-0.845,0.256-1.681,0.529-2.517,0.811c-0.649-1.621-2.406-3.831-3.089-0.435
	c-0.521-1.169-1.024-2.313-1.545-3.465c-0.503,0.299-1.51,0.905-2.014,1.212c1.869,1.835,3.507,4.053,5.965,5.154
	c3.038-0.74,6.386-3.87,7.245-4.873c-0.188-0.896-0.41-1.784-0.666-2.654C90.334,471.938,89.592,472.194,88.841,472.467
	 M2229.282,474.31c0.7,0.026,2.091,0.077,2.782,0.094c-0.009-0.666-0.034-1.997-0.051-2.662
	C2230.878,472.364,2229.538,472.885,2229.282,474.31 M2391.04,472.194c-1.195,0.845,0.128,3.106,1.399,2.099
	C2393.497,473.405,2392.286,471.127,2391.04,472.194 M444.416,473.798c0.947,1.058,1.929,2.082,2.944,3.081
	c0.41-0.341,1.212-1.041,1.613-1.391c0.094-0.427,0.29-1.28,0.384-1.715c-0.546,0.068-1.638,0.188-2.185,0.247
	C446.805,471.844,445.321,473.064,444.416,473.798 M451.413,473.081c-0.998,1.203,1.05,3.004,2.185,2.031
	C454.528,473.9,452.531,472.091,451.413,473.081 M518.75,474.028c0.486,0.051,1.468,0.137,1.954,0.179
	C521.865,472.253,517.419,471.801,518.75,474.028 M2289.664,472.825c-0.171,1.801-3.2,1.724-2.551,3.644
	c2.551,1.092,3.456-2.338,4.898-3.755C2291.422,472.748,2290.253,472.799,2289.664,472.825 M2370.739,473.277
	c-1.195,0.179-2.722,0.341-3.004,1.724c1.528,1.451,4.651,2.253,5.897-0.026C2373.163,473.747,2371.959,473.209,2370.739,473.277
	 M2429.448,472.919c-0.538,1.195-0.162,1.57,1.118,1.135C2431.036,472.859,2430.669,472.484,2429.448,472.919 M99.908,474.062
	c-1.434,1.152,0.649,3.191,1.775,1.724C102.921,474.677,100.975,472.834,99.908,474.062 M460.877,474.037
	c-0.009,0.392-0.017,1.178-0.026,1.562c0.563,0.051,1.681,0.162,2.244,0.213c-0.051-0.427-0.162-1.297-0.213-1.724
	C462.387,474.071,461.38,474.054,460.877,474.037 M464.973,473.678C465.161,476.477,467.516,472.33,464.973,473.678
	 M2237.039,475.163c-0.691,1.647-2.637-0.461-3.456,0.973c-0.205,1.203-0.307,2.415-0.299,3.644c1.664,0.06,3.115-1.161,4.821-1.263
	c1.118-1.519,2.031-3.183,2.833-4.907C2239.403,473.653,2237.807,473.636,2237.039,475.163 M2406.562,473.892
	c-1.058,1.246,1.152,2.176,1.835,0.964C2409.497,473.585,2407.168,472.68,2406.562,473.892 M2412.074,478.073
	c2.313-0.384,2.773-2.901,2.816-4.855C2413.295,474.421,2411.819,475.897,2412.074,478.073 M2431.462,473.371
	c0.034,0.341,0.094,1.015,0.119,1.357c-0.546,0.392-1.63,1.169-2.176,1.553c0.922,0.205,1.877,0.401,2.859,0.606
	c0.742-1.297,1.485-2.586,2.27-3.874C2433.766,473.107,2432.23,473.286,2431.462,473.371 M2179.277,474.498
	c0.094,0.512,0.273,1.519,0.358,2.031c0.35-0.009,1.05-0.034,1.399-0.043c0.094-0.478,0.282-1.442,0.367-1.92
	C2180.873,474.549,2179.806,474.515,2179.277,474.498 M2278.553,475.743c0.017,0.375,0.06,1.135,0.077,1.519
	c0.674,0.495,1.399,0.973,2.167,1.442c1.664-1.092,0.29-2.79-0.418-3.994C2279.74,475.044,2279.125,475.385,2278.553,475.743
	 M2383.889,474.455l-0.359,0.947c2.304,0.29,3.832,2.039,5.666,3.285c1.544,0.205,2.483-1.493,3.652-2.295
	c-2.219-0.614-4.463-0.998-6.724-1.178c-0.333-0.213-1.007-0.648-1.34-0.862L2383.889,474.455 M2394.223,474.882
	c0.657,1.451,1.911,2.167,3.524,2.321C2398.054,474.865,2395.759,475.18,2394.223,474.882 M2496.247,474.6
	c-0.947,1.254,1.195,2.5,2.022,1.263C2499.319,474.532,2496.973,473.175,2496.247,474.6 M2529.015,476.127
	c-2.014,4.787-3.584,9.771-5.188,14.703c3.983-0.372,6.56-9.876,8.815-16.913C2531.226,474.335,2529.391,474.421,2529.015,476.127
	 M409.702,476.332c0.051,0.606,0.102,1.22,0.154,1.852c1.271-0.239,2.543-0.461,3.823-0.683c-0.555-0.649-0.444-2.099-1.519-2.133
	C411.332,475.658,410.513,475.974,409.702,476.332 M507.256,475.428c0.87,1.519,1.997,2.901,3.635,3.635
	c-0.358-1.237-0.717-2.475-1.169-3.661C509.107,475.411,507.878,475.419,507.256,475.428 M532.727,476.93
	c1.886,0.094,3.772,0.085,5.666,0.051C537.464,474.25,534.118,475.112,532.727,476.93 M2267.921,476.145
	c-1.886,0.973,0.316,2.85,1.468,1.451C2270.541,476.537,2268.945,474.899,2267.921,476.145 M2403.84,475.436
	c-1.126,0.64-0.299,3.055,0.964,1.937C2405.82,476.691,2405.103,474.335,2403.84,475.436 M2433.195,477.868
	c0.683-0.034,2.04-0.102,2.722-0.145c0.171-0.358,0.512-1.084,0.683-1.442C2434.927,474.958,2433.323,475.726,2433.195,477.868
	 M2447.198,475.086c-0.512,0.853-0.887,1.766-1.109,2.731c0.691-0.06,2.065-0.179,2.756-0.239
	C2448.631,476.477,2448.597,475.189,2447.198,475.086 M2499.345,475.487c-0.998,1.323,1.383,2.355,2.279,1.34
	C2502.545,475.513,2500.147,474.455,2499.345,475.487 M2523.938,476.375c0.068,2.543,0.136,5.103,0.273,7.654
	c1.442-2.79,2.389-5.794,3.61-8.687C2526.507,475.684,2525.201,476.034,2523.938,476.375 M455.475,476.418
	c-1.024,0.853,0.41,2.714,1.502,1.954C457.89,477.476,456.593,475.624,455.475,476.418 M2271.872,476.648
	c-1.101,1.075-2.27,2.176-2.458,3.78c1.903-0.666,3.712-1.681,4.335-3.763C2273.28,476.665,2272.341,476.657,2271.872,476.648
	 M2502.955,476.366c-1.05,1.161,1.067,2.261,1.877,1.229C2505.813,476.443,2503.688,475.308,2502.955,476.366 M2281.463,480.317
	c1.425-0.742,4.096-1.195,3.268-3.405C2283.204,477.595,2282.095,478.79,2281.463,480.317 M2340.284,482.109
	c0.538-0.691,1.041-1.391,1.638-2.074c-0.785-0.973-1.545-1.946-2.244-2.918C2337.135,478.295,2339.678,480.488,2340.284,482.109
	 M2517.248,478.969c-0.145,0.546-0.418,1.63-0.554,2.176c0.981,1.502,1.442,3.669,3.396,4.292c-0.154-1.818-0.316-3.618-0.35-5.427
	c1.519,0.913,0.913,2.85,1.186,4.318c-0.828,1.613,0.017,3.388,0.307,5.035c0.375-0.222,1.126-0.674,1.502-0.905
	c-1.169-3.772,0.947-7.91-0.538-11.511C2520.405,477.271,2518.869,478.286,2517.248,478.969 M515.209,478.833
	c0.009,0.64,0.026,1.92,0.034,2.56c2.074-0.452,4.147-0.896,6.246-1.237C520.849,476.853,517.402,477.919,515.209,478.833
	 M536.858,478.03c0.247,1.101,0.887,1.263,1.929,0.495C538.522,477.416,537.873,477.245,536.858,478.03 M2508.766,478.03
	c-1.425,1.178,0.478,3.072,1.689,1.698C2511.974,478.696,2509.85,476.742,2508.766,478.03 M522.513,478.79
	c-0.862,0.93,0.461,2.995,1.604,1.963C525.321,479.763,523.682,477.681,522.513,478.79 M2258.039,478.705
	c-2.611,1.809-5.205,3.977-6.221,7.108c3.379-1.698,6.135-4.352,8.721-7.066C2259.917,478.73,2258.662,478.713,2258.039,478.705
	 M2265.66,478.918c-1.024,0.188-2.108,1.28-1.527,2.347C2265.25,482.459,2267.921,478.423,2265.66,478.918 M2373.803,482.203
	c2.475,0.845,5.094,1.476,7.245,3.115c0.597-0.538,1.212-1.067,1.886-1.596c-0.7-1.271-1.34-2.569-1.895-3.883
	C2378.445,477.877,2375.953,481,2373.803,482.203 M2445.551,478.884c-1.058,1.314,1.4,2.304,2.27,1.297
	C2448.845,478.833,2446.344,477.877,2445.551,478.884 M2496.367,481.427c1.135-0.085,2.244-0.367,3.32-0.853
	c0.76,0.35,1.604,0.725,2.44,1.092c0.162-0.896,0.376-1.792,0.623-2.679C2500.634,480.642,2496.512,477.493,2496.367,481.427
	 M2559.94,479.771c-0.051,0.597-0.154,1.783-0.205,2.381c2.295-0.717,4.471-1.818,6.238-3.516
	C2563.891,478.773,2561.903,479.242,2559.94,479.771 M529.946,479.814c-1.451-0.35-2.671,1.886-0.939,2.415
	C530.543,482.946,531.678,480.113,529.946,479.814 M2236.723,479.882c-1.254,1.263,0.947,2.739,1.809,1.289
	C2239.59,479.933,2237.5,478.44,2236.723,479.882 M2256.666,485.924c1.05-0.179,2.099-0.367,3.208-0.538
	c1.203-2.031,2.842-3.874,3.302-6.281C2260.284,480.437,2258.782,483.645,2256.666,485.924 M2415.274,481.99
	c1.263,0.879,2.611,1.741,4.216,1.963c-0.137-0.367-0.401-1.118-0.538-1.493c0.282-0.64,2.33-2.304,0.939-2.671
	C2417.817,478.619,2416.512,480.744,2415.274,481.99 M394.47,481.913c-1.766,0.307-3.345,1.126-4.83,2.099
	c0.077,1.084,0.162,2.176,0.239,3.277c1.382-0.666,2.756-1.34,4.139-1.988c0.009-0.597,0.009-1.801,0.009-2.406
	c1.86,0.307,3.721-0.666,3.652-2.765C396.442,480.394,395.426,481.128,394.47,481.913 M2175.957,480.65
	c-1.067,1.34,1.383,2.347,2.21,1.22C2179.2,480.522,2176.717,479.558,2175.957,480.65 M2249.591,481.401
	c-0.845,2.039-2.875,3.115-4.625,4.241c-1.075-0.862-2.193-1.655-3.482-2.074c0.333,0.879,0.7,1.758,1.084,2.637l0.896-0.307
	c0.921,2.645,3.089-0.384,4.736-0.691c0.367-0.683,0.768-1.357,1.186-2.022c1.34-0.597,3.149-0.981,3.302-2.773
	C2251.614,480.71,2250.59,481.043,2249.591,481.401 M535.083,483.961c1.169,0.947,2.543,1.51,4.053,1.698
	c-0.043,0.614-0.12,1.843-0.162,2.458c1.57-0.017,3.132-0.043,4.71-0.077c-0.324-1.527-1.365-2.492-2.85-2.859l-0.051-0.478
	c0.495-0.051,1.468-0.154,1.954-0.196c-0.367-0.887-0.734-1.775-1.092-2.645C539.128,480.787,536.713,481.973,535.083,483.961
	 M2274.756,481.341c-5.012,5.094-0.616,3.352-9.335,8.474c-0.922,0.512-2.261,0.939-1.707,2.253
	c1.783,1.348,3.806,2.389,5.598,3.772c1.28-0.307,2.56-0.683,3.883-1.024c0.009,1.493-0.222,2.901-2.005,3.021
	c0.487,0.478,1.024,0.956,1.621,1.434c0.981-0.597,2.031-1.178,3.208-1.442c0.478-0.691,0.981-1.382,1.51-2.074
	c0.495-0.043,1.485-0.128,1.98-0.171c1.485-2.261,4.19-0.649,6.34-1.545c-0.009-0.64-0.017-1.911-0.017-2.543
	c0.64,0.111,1.928,0.341,2.577,0.452c-0.026-0.751-0.068-2.261-0.094-3.021c-0.512,0.051-1.527,0.162-2.031,0.213
	c-3.305,5.274-5.132,2.063-6.665,2.995c-1.365,0.58-2.833,1.673-4.138,0.418c0.648-1.929,1.809-3.994,4.19-3.584
	c-0.017-1.408,0.043-2.807,0.196-4.198c0.683,0.051,2.048,0.154,2.731,0.196c0.768-0.896,1.468-1.86,1.801-3.038
	c-1.715,0.538-3.371,1.178-4.992,1.886C2277.803,483.048,2276.284,482.169,2274.756,481.341 M2327.808,481.461
	c-1.058,1.289,1.297,2.492,2.15,1.323C2330.914,481.486,2328.602,480.351,2327.808,481.461 M2423.245,480.974
	c0.009,0.452,0.034,1.365,0.043,1.818c1.195-0.068,2.415-0.145,3.661-0.222C2426.325,481.051,2424.508,481.307,2423.245,480.974
	 M2428.57,482.118c-0.111,1.178-0.205,2.364-0.273,3.55c1.118-0.094,2.27-0.179,3.448-0.256c0-0.529-0.009-1.57-0.009-2.091
	c-0.99,0.222-1.946,0.452-2.876,0.674c0.563-0.879,1.22-1.732,1.843-2.603C2429.96,481.623,2429.252,481.87,2428.57,482.118
	 M2505.6,481.341c-0.341,1.468-2.867,1.732-2.193,3.43c2.005,0.051,3.413-1.903,4.736-3.226
	C2507.511,481.495,2506.231,481.393,2505.6,481.341 M2563.883,482.348c0.128,0.563,0.384,1.681,0.512,2.244
	c0.87-0.06,1.758-0.256,2.646-0.589c0.281,0.068,0.828,0.196,1.109,0.265c0.478-0.358,1.433-1.067,1.911-1.417
	c-0.529-0.316-1.587-0.939-2.108-1.254C2566.588,481.87,2565.188,482.101,2563.883,482.348 M435.174,482.485
	c-0.444,0.606-0.87,1.237-1.263,1.886c0.58,0,1.732,0,2.313,0c0.026,0.614,0.085,1.843,0.111,2.458
	c0.512,0.128,1.545,0.375,2.065,0.503c-0.051-1.067-0.094-2.133-0.137-3.183C437.06,483.918,436.122,483.185,435.174,482.485
	 M2408.26,482.152c-1.075,0.179-1.886,2.406-0.41,2.526C2408.943,484.575,2409.779,482.161,2408.26,482.152 M2507.17,485.531
	c-1.024,2.577-3.917,4.198-3.814,7.194c2.244-1.365,3.959-3.456,6.383-4.582c-0.504,1.084-0.981,2.159-1.434,3.251
	c2.065-0.794,4.258-0.836,6.272,0.247c-1.263-1.434-2.483-2.944-3.089-4.762c2.722,0.486,3.584,3.55,5.735,5.001
	c0.111,0.956,0.264,1.929,0.452,2.901c0.299-0.358,0.896-1.084,1.195-1.442c-1.425-4.181-3.601-8.021-5.709-11.87
	C2511.104,482.698,2508.203,483.142,2507.17,485.531 M2171.605,483.304c-0.563,1.681,1.425,1.869,2.474,2.517
	c-0.06-0.708-0.188-2.125-0.247-2.833C2173.278,483.074,2172.168,483.227,2171.605,483.304 M2182.152,483.381
	c0.171,0.546,0.495,1.638,0.657,2.176c0.554-0.119,1.655-0.358,2.21-0.478C2185.148,483.091,2183.654,483.133,2182.152,483.381
	 M2338.517,486.18c0.589,0.034,1.766,0.102,2.355,0.137c0.521-0.282,1.579-0.845,2.099-1.126c-0.009-0.521-0.026-1.57-0.034-2.091
	C2341.239,483.825,2339.448,484.499,2338.517,486.18 M2433.698,483.074C2433.937,485.847,2436.241,481.691,2433.698,483.074
	 M2439.774,485.309c1.613,0.657,3.277,1.297,4.983,1.818c-0.452-1.963-2.739-2.526-3.481-4.326
	C2440.764,483.628,2440.209,484.456,2439.774,485.309 M2560.845,482.997c-0.239,1.169,0.239,1.553,1.451,1.161
	C2562.483,482.963,2562.005,482.57,2560.845,482.997 M2213.214,486.299c-0.137,0.418-0.41,1.246-0.546,1.664
	c1.45,0.265,2.517-0.102,2.568-1.801c0.282,0.307,0.836,0.93,1.118,1.246c0.999-1.109,2.227-2.176,2.244-3.823
	C2216.721,484.362,2214.955,485.318,2213.214,486.299 M2260.889,489.03c2.543-0.265,4.455-2.56,3.362-5.103
	C2262.861,485.437,2261.632,487.11,2260.889,489.03 M2328.678,484.49c0.922,1.519,1.758,3.106,2.552,4.727
	C2334.694,488.526,2331.153,483.176,2328.678,484.49 M2498.534,483.97c-0.52,1.382-0.068,1.835,1.357,1.357
	C2500.361,483.935,2499.9,483.475,2498.534,483.97 M2558.182,484.072c-1.468,0.29-0.41,2.15,0.76,1.69
	C2560.418,485.506,2559.24,483.594,2558.182,484.072 M2414.182,485.13c1.083,0.666,2.585,1.067,3.9,0.964
	C2418.167,484.55,2414.49,483.637,2414.182,485.13 M22.093,486.478c0.051,0.461,0.162,1.374,0.213,1.835
	c1.075-0.009,2.133,0.145,3.174,0.41c0.7-0.299,1.408-0.597,2.108-0.879c0.589,1.015,1.621,1.545,2.594,2.099
	c-0.111-0.87-0.222-1.715-0.333-2.569c-1.502,0.026-3.012,0.06-4.514,0.102c-0.606-0.572-1.212-1.126-1.826-1.673
	C23.16,485.966,22.443,486.308,22.093,486.478 M2500.992,489.141c-1.758,1.57-4.344,2.287-5.137,4.745
	c0.674-0.196,2.022-0.597,2.696-0.802c-0.742,0.947-1.433,1.894-2.082,2.867c0.444-0.102,1.331-0.299,1.775-0.401
	c2.586-2.381,3.84-5.99,6.852-7.996c0.009-0.58,0.043-1.741,0.051-2.321C2503.714,486.495,2502.417,487.886,2500.992,489.141
	 M409.95,487.801c1.527,0.145,3.081,0.375,3.541,2.116c1.28-0.691,1.707-2.108,0.631-3.234
	C412.706,486.129,410.266,485.804,409.95,487.801 M430.123,486.555c-0.99,1.254,1.263,2.492,2.133,1.399
	C433.323,486.658,430.985,485.454,430.123,486.555 M452.668,489.397c0.683-0.777,0.845-2.79-0.461-2.978
	C450.364,486.641,450.842,490.165,452.668,489.397 M517.316,486.786c-1.135,1.365,1.451,2.125,2.202,1.092
	C520.67,486.478,518.033,485.565,517.316,486.786 M2221.884,488.816c1.169,0.973,2.867-1.092,2.099-2.185
	C2222.711,485.83,2220.732,487.562,2221.884,488.816 M2333.611,486.402c-0.435,1.348,0.06,1.818,1.502,1.417
	C2335.488,486.453,2334.993,485.975,2333.611,486.402 M2438.007,489.371c1.724,0.759,2.347-2.816,0.546-3.012
	C2437.222,486.598,2437.256,488.552,2438.007,489.371 M2186.317,487.246c-0.418,1.015-0.811,2.039-1.178,3.063
	c1.161-0.589,2.381-1.169,3.558-1.801C2187.878,488.083,2187.085,487.665,2186.317,487.246 M2411.076,490.591
	c1.664,0.708,3.405,1.007,5.256,0.742c0,0.469,0,1.425-0.009,1.903c1.818-1.971,4.421-3.379,5.419-5.99
	c-1.775,0.555-3.61,1.425-3.9,3.465c-0.64,0.137-1.911,0.41-2.552,0.546c0.708-1.382,1.528-2.722,2.261-4.105
	C2414.831,486.82,2412.194,488.049,2411.076,490.591 M33.792,488.424c0.785,1.408,2.406,1.263,3.806,1.399
	C37.581,487.451,35.32,488.279,33.792,488.424 M418.04,488.356c-1.075,1.34,1.425,2.313,2.219,1.22
	C421.308,488.219,418.825,487.281,418.04,488.356 M465.391,488.049c0.256,1.408,1.434,2.074,2.603,2.662
	c0.29-0.785,0.589-1.562,0.896-2.338C467.712,488.245,466.552,488.134,465.391,488.049 M660.284,488.415
	c-0.316,1.169,0.128,1.587,1.348,1.237C661.931,488.475,661.478,488.066,660.284,488.415 M2231.808,488.091
	c0.41,0.879,0.862,1.758,1.348,2.637c0.913-0.725,1.86-1.468,2.671-2.347C2234.462,488.253,2233.122,488.151,2231.808,488.091
	 M2254.575,488.373c-1.459,1.118,0.384,3.388,1.485,1.57C2257.655,488.782,2255.795,486.871,2254.575,488.373 M2326.417,488.27
	c-0.819,1.126,1.169,2.27,1.988,1.288C2329.276,488.364,2327.168,487.229,2326.417,488.27 M2336.623,488.842
	c2.227,1.946,6.084,3.925,8.465,1.161c-1.195-0.23-2.236-0.41-3.362-0.623C2340.207,488.322,2338.295,488.774,2336.623,488.842
	 M2422.101,491.121c3.072,0.111,6.631,0.154,8.388-2.901C2427.366,487.647,2423.893,488.287,2422.101,491.121 M2442.53,488.1
	c0.939,2.005,4.42,4.241,6.144,2.227C2447.607,488.287,2444.501,488.441,2442.53,488.1 M2452.318,488.262
	c0.094,0.597,0.273,1.792,0.367,2.389c0.879-0.751,1.698-1.57,2.517-2.432C2454.477,488.228,2453.043,488.245,2452.318,488.262
	 M2526.319,492.588c1.647-1.28,3.575-2.586,4.045-4.787C2528.666,489.03,2526.558,490.284,2526.319,492.588 M446.319,489.329
	c-0.358,2.031,0.981,3.268,2.987,2.816c0.034-0.58,0.111-1.741,0.145-2.313C448.401,489.653,447.36,489.482,446.319,489.329
	 M2347.315,489.713c0.009,0.512,0.026,1.527,0.043,2.039c1.289-0.102,2.543,0.239,3.865,0.469c0.725-0.7,1.451-1.425,2.185-2.167
	C2351.351,489.832,2349.337,489.713,2347.315,489.713 M2561.536,489.286c-0.939,1.143,0.802,3.055,2.022,2.116
	C2564.198,490.353,2562.56,488.671,2561.536,489.286 M148.326,490.028c0.538,1.314,0.563,3.217,2.313,3.482
	c0.043-1.357,0.085-2.705,0.119-4.045C150.153,489.602,148.941,489.883,148.326,490.028 M425.523,491.906
	c0.589,0.017,1.758,0.043,2.347,0.06c0.077-0.614,0.247-1.852,0.324-2.466C426.906,489.815,426.155,490.822,425.523,491.906
	 M547.499,490.498c-0.307,0.87-0.606,1.741-0.896,2.628c0.683-0.034,2.048-0.111,2.739-0.154c-0.154-0.964-0.29-1.911-0.435-2.859
	C548.557,490.207,547.857,490.404,547.499,490.498 M2236.655,493.766c0.469,0.051,1.408,0.162,1.877,0.213
	c0.427-1.442,0.913-2.884,1.365-4.326C2237.619,489.858,2236.894,491.803,2236.655,493.766 M2240.742,492.264
	c1.16,1.041,3.089-1.015,2.125-2.15C2241.655,489.311,2239.795,491.061,2240.742,492.264 M2259.089,489.585
	c0.008,0.896,0.111,1.792,0.264,2.688c-1.928-0.111-3.575,0.717-4.992,1.911c0.205,0.324,0.606,0.981,0.811,1.314
	c-1.681,0.956-3.217,2.116-4.616,3.422c-2.526,0.154-3.448,2.355-3.977,4.471c1.809-0.205,3.584-0.631,5.419-0.939
	c-1.101,0.87-2.185,1.741-3.166,2.697c1.024-0.009,2.082-0.017,3.174-0.034c0.7-2.167,2.722-2.756,4.813-2.654
	c0.026-0.512,0.085-1.527,0.111-2.031c1.348-0.555,1.724-1.98,2.278-3.2c-0.341-1.246-0.691-2.543-0.828-3.797
	c1.519,0.41,2.347,1.732,3.234,2.961c1.562-1.067,1.34-2.782,1.075-4.429C2261.453,491.18,2260.275,490.37,2259.089,489.585
	 M2321.527,489.619c0.094,1.297,0.282,2.697,1.801,3.055c-0.41,0.956-0.836,1.903-1.101,2.901c0.589,0.427,1.22,0.828,1.903,1.203
	c0.264,0.887,0.623,1.792,0.973,2.697c2.603,0.58,4.984,2.167,7.714,2.278c0.981-1.118,1.817-2.364,2.748-3.55
	c-1.536-0.666-3.063-1.331-4.557-1.98c-1.101,1.092-2.184,2.185-3.268,3.26c-0.034-0.717-0.111-2.15-0.145-2.867
	c-0.682,0.068-2.056,0.213-2.739,0.282C2324.966,494.303,2324.838,489.96,2321.527,489.619 M2329.566,490.062
	c-0.964,1.348,1.51,2.261,2.372,1.263C2332.919,489.96,2330.343,489.107,2329.566,490.062 M2458.948,489.977
	c0.248,0.239,0.751,0.708,0.998,0.947c-0.179,0.435-0.537,1.306-0.717,1.741c1.169-0.589,2.185-1.425,3.089-2.415
	C2461.167,490.148,2460.041,490.062,2458.948,489.977 M34.185,490.924c0.188,0.7,0.375,1.399,0.563,2.116
	c1.178-0.529,2.722-0.759,2.884-2.313C36.48,490.779,35.337,490.839,34.185,490.924 M453.154,491.223
	c0.623,0.853,1.323,1.647,2.125,2.364c0.051-0.742,0.171-2.236,0.23-2.978C454.921,490.762,453.743,491.069,453.154,491.223
	 M2358.033,493.109c1.203,0.009,2.423,0.009,3.678,0c0.009-0.657,0.017-1.963,0.026-2.62
	C2360.32,491.103,2358.485,491.411,2358.033,493.109 M2441.975,490.779c0.017,1.05,0.06,2.108,0.154,3.174
	c0.751,0.034,2.253,0.111,3.003,0.145c-0.418,1.05-0.759,2.116-1.092,3.183c0.904-0.384,1.809-0.759,2.756-1.195
	c-0.811-1.493-1.886-2.807-2.654-4.292C2443.384,491.445,2442.667,491.103,2441.975,490.779 M539.742,491.658
	c0.623,2.449,3.755,1.161,5.572,1.579C545.178,490.31,541.619,491.906,539.742,491.658 M577.579,491.573
	c-1.869,0.265-1.041,2.884,0.64,2.372C579.968,493.766,579.115,491.069,577.579,491.573 M2243.729,495.285
	c-0.999,1.314-2.065,2.56-3.2,3.729c2.313,2.287,4.761-0.111,6.673-1.664c0.367-2.142,2.603-3.925,2.219-6.067
	C2247.757,492.989,2246.366,495.063,2243.729,495.285 M2436.872,491.709c0.103,0.41,0.307,1.229,0.401,1.638
	c0.469,0.017,1.391,0.051,1.852,0.068c0.111-0.461,0.341-1.374,0.452-1.835C2438.904,491.615,2437.547,491.675,2436.872,491.709
	 M2510.643,491.692c-0.956,0.119-2.389,0.939-1.997,2.031C2509.645,494.414,2512.316,492.324,2510.643,491.692 M2226.133,493.988
	c0.265,0.435,0.785,1.306,1.05,1.741c1.536-0.768,3.285-1.903,1.229-3.439C2227.61,492.836,2226.842,493.373,2226.133,493.988
	 M2417.886,495.583c3.132-1.775,7.33-0.657,10.01-3.132c-2.73-0.119-5.461-0.247-8.158,0.043
	C2418.671,493.194,2418.372,494.508,2417.886,495.583 M2501.931,492.588c-0.956,0.469-1.229,1.161-0.828,2.082
	C2502.921,495.985,2504.55,492.187,2501.931,492.588 M2559.752,492.452c0.034,0.589,0.094,1.766,0.12,2.355
	c0.648,0.35,1.323,0.7,2.039,1.05c0.119-1.178,0.273-2.355,0.461-3.524C2561.715,492.358,2560.409,492.418,2559.752,492.452
	 M492.425,496.164c1.271,0.58,2.603,1.015,3.925,1.493l0.085-1.075l1.195,0.188c-1.101,1.109-2.27,2.193-3.746,2.765
	c0.538,0.717,1.075,1.451,1.621,2.193c0.657,0,1.98,0.009,2.645,0.017l-0.051,0.572c-1.715,0.913-3.448,1.809-5.359,2.244
	c0.068,0.572,0.205,1.707,0.273,2.278c1.22-0.324,2.432-0.64,3.678-0.922c-0.265,0.58-0.794,1.741-1.05,2.321
	c0.563,0.495,1.126,0.998,1.707,1.51c-0.299,0.35-0.905,1.041-1.212,1.382c-0.555,0.009-1.673,0.017-2.236,0.026
	c0.384,0.777,0.768,1.562,1.143,2.355c0.563,0.068,1.681,0.196,2.244,0.265c-0.009,0.341-0.009,1.033-0.009,1.374
	c-1.041,0.247-2.048,0.555-3.046,0.922c-0.503,0.026-1.51,0.068-2.022,0.094l0.017,1.348c-0.691-0.017-2.074-0.06-2.765-0.077
	c0.794,0.998,1.801,1.783,2.978,2.304c0-0.546,0-1.63,0-2.176c2.065,0.247,4.164,0.401,6.255,0.435
	c0.068-2.534,2.782-1.587,4.454-1.835c-0.205-0.393-0.623-1.161-0.828-1.553c0.666-0.077,1.997-0.247,2.662-0.333
	c-0.171-1.101-0.444-2.167-0.836-3.191c0.802,0.009,2.423,0.043,3.234,0.051c-1.801-1.391-3.302-3.089-4.309-5.129
	c1.28,0.009,2.56,0.009,3.849,0.017c-0.026-2.645-3.115-2.355-4.915-2.85c1.212-1.485,3.046-1.536,4.821-1.502
	c-0.29-0.401-0.879-1.203-1.169-1.604c0.546-0.043,1.647-0.119,2.202-0.154c-0.384-0.887-0.87-1.707-1.459-2.441
	c-1.459-0.111-2.799,0.572-4.156,0.973c0.282-0.486,0.836-1.468,1.109-1.963c-1.417,0.418-2.833,0.845-4.267,1.212
	c-0.06-0.666-0.179-1.988-0.239-2.654c-1.075,0.307-2.15,0.597-3.217,0.905c-0.043-0.777-0.119-2.321-0.162-3.089
	C494.327,493.655,491.46,494.363,492.425,496.164 M2510.123,495.49c1.869-0.145,3.712-0.435,5.598-0.742
	C2515.004,491.906,2510.882,493.45,2510.123,495.49 M381.286,494.252c0.137,0.572,0.41,1.698,0.538,2.27
	c0.998-0.572,1.835-1.348,2.321-2.406C383.428,494.15,381.995,494.218,381.286,494.252 M407.714,495.054
	c0.017,0.606,0.043,1.835,0.06,2.449c1.092-0.137,2.185-0.265,3.285-0.401c-0.572-0.939-1.143-1.869-1.724-2.782
	C408.926,494.5,408.115,494.867,407.714,495.054 M411.503,494.278c-0.538,1.015,0.998,2.526,2.014,1.92
	C414.259,495.106,412.527,493.638,411.503,494.278 M474.018,497.913c0,0.529,0,1.57,0.009,2.099
	c-1.348,0.094-2.68,0.205-4.011,0.324c0.094,0.503,0.29,1.51,0.393,2.014c1.57,0.043,3.098-0.393,4.651-0.632
	c-0.111-0.7-0.324-2.116-0.435-2.816c1.758-0.085,2.731,1.237,3.465,2.62c0.751,0.461,1.51,0.922,2.27,1.391
	c0.759-0.666,2.662-2.449,0.802-3.004c-1.237-0.956-4.164-1.033-3.456-3.149c0.785-1.024,3.917-1.178,2.833-2.765
	C477.56,493.595,476.8,497.486,474.018,497.913 M2330.428,494.244c-0.802,1.118,1.084,2.449,2.022,1.562
	C2333.124,494.705,2331.29,493.373,2330.428,494.244 M2405.171,496.078c0.734,0.418,2.193,1.271,2.927,1.69
	c-1.442,0.785-3.49,1.041-3.934,2.876c1.391,0.316,2.842,0.623,4.292,0.887c0.546,1.289,1.519,1.468,2.919,0.538
	c-0.717-0.307-2.133-0.905-2.842-1.212c0.905-1.613,1.895-3.191,2.884-4.779c-0.93-0.691-1.749-1.476-2.722-2.005
	C2407.176,493.971,2406.229,495.276,2405.171,496.078 M2420.42,496.189c1.408,1.911,3.84,1.562,5.769,0.614
	c0.947,1.271,1.297,2.842,1.792,4.343c0.418-0.947,0.879-1.886,1.357-2.825c0.401,0.23,1.195,0.683,1.587,0.913
	c2.995-1.254,6.255-1.835,9.148-3.362c-2.159-0.188-4.31-0.128-6.315,0.614c0.503-0.614,1.05-1.22,1.613-1.826
	C2430.327,494.005,2425.208,494.551,2420.42,496.189 M2503.356,496.36c1.16-0.836,2.304-0.853,3.447-0.06
	c-0.845,0.964-2.073,1.391-3.268,1.57c-0.026,0.35-0.085,1.033-0.111,1.374c0.632-0.102,1.895-0.307,2.526-0.41l-0.256,1.041
	c-2.048-0.205-4.275,0.273-4.113,2.782c2.073-0.478,4.224-0.913,6.084-2.082c0.981,1.058,1.937,2.193,3.567,2.202
	c0.469-2.662-2.611-4.258-4.906-3.832c0.23-0.478,0.674-1.425,0.896-1.903c0.836-0.384,1.383-1.05,1.647-1.997
	c-0.674,0.102-2.014,0.299-2.679,0.401c-0.222-0.469-0.666-1.408-0.887-1.877C2504.013,494.03,2503.62,495.191,2503.356,496.36
	 M387.704,498.57c1.314-0.265,2.714-0.35,3.968-0.905c0.205-0.905,0.367-1.809,0.495-2.714
	C390.246,495.566,388.105,496.283,387.704,498.57 M536.67,495.174c-0.666,1.186,1.374,2.295,2.244,1.434
	C539.529,495.43,537.515,494.423,536.67,495.174 M2264.934,494.798c-0.589,1.092-1.229,2.133-1.946,3.132
	c0.247,0.529,0.742,1.596,0.99,2.133c1.075,0.444,1.928,1.289,2.901,1.997c1.331-0.862,2.782-1.348,4.42-1.442
	c-0.853-1.058-1.8-1.988-2.961-2.628c-0.35,0.606-1.041,1.826-1.391,2.432c-0.606-0.623-1.178-1.237-1.724-1.852
	c0.529-0.375,1.578-1.126,2.108-1.502C2266.513,496.309,2265.702,495.549,2264.934,494.798 M2563.533,495.199
	c-1.425,1.519,1.664,3.012,2.27,1.143C2566.4,494.961,2564.301,494.167,2563.533,495.199 M543.258,496.027
	c0.128,0.538,0.375,1.621,0.503,2.168c1.365,0.154,2.108-1.075,3.012-1.869C545.596,496.223,544.418,496.113,543.258,496.027
	 M596.924,498.954c-0.299-0.111-0.904-0.333-1.212-0.444c0.35,1.323,0.691,2.662,1.067,4.002c1.417-0.35,3.046-2.509,0.802-3.209
	c1.033-1.152,1.92-2.432,2.662-3.797C597.487,494.124,597.18,497.051,596.924,498.954 M2455.671,496.65
	c-1.724,0.998,0.333,2.731,1.451,1.408C2458.394,496.94,2456.695,495.165,2455.671,496.65 M2518.374,496.053
	c-1.135,1.084,0.862,1.946,1.553,0.93C2521.028,495.908,2518.972,494.986,2518.374,496.053 M2526.447,495.711
	c-0.239,2.287,2.005,2.859,3.849,2.458C2529.433,496.77,2527.846,496.275,2526.447,495.711 M26.564,496.701
	c-0.742,1.135,1.357,2.159,2.167,1.357C29.619,496.829,27.409,495.882,26.564,496.701 M452.062,496.829
	c-1.143,1.323,1.289,2.5,2.108,1.289C455.134,496.846,452.864,495.703,452.062,496.829 M456.269,496.633
	c-0.478,1.391-0.009,1.852,1.391,1.365C458.121,496.59,457.66,496.13,456.269,496.633 M488.593,496.642
	c0.094,1.698,2.005,3.029,3.328,1.562C491.785,496.394,489.899,496.659,488.593,496.642 M530.594,496.718
	c-1.63,0.452-0.691,2.97,0.819,2.671C533.001,499.005,532.036,496.343,530.594,496.718 M533.282,496.676
	c-0.367,1.178,0.051,1.596,1.254,1.254C534.886,496.727,534.468,496.309,533.282,496.676 M2228.83,496.462
	c-0.384,0.742-0.751,1.493-1.075,2.244c1.673,0.503,2.765,1.903,3.908,3.166c0.077-0.546,0.23-1.63,0.299-2.176
	c-0.487-0.964-0.939-1.928-1.34-2.901C2230.169,496.71,2229.282,496.548,2228.83,496.462 M2336.367,496.991
	c-1.067,1.365,1.459,2.253,2.27,1.178C2339.618,496.795,2337.101,495.908,2336.367,496.991 M2411.238,499.807
	c1.843-0.341,2.969-1.331,2.355-3.354C2412.416,497.273,2411.767,498.536,2411.238,499.807 M2460.262,496.625
	c0.008,1.715-1.092,3.004-2.065,4.275c0.572-0.017,1.724-0.06,2.295-0.077c0.589-0.631,1.203-1.263,1.86-1.886
	c-0.188-0.29-0.555-0.87-0.734-1.161c0.273-0.282,0.811-0.853,1.084-1.135C2462.088,496.642,2460.877,496.625,2460.262,496.625
	 M2555.127,496.744c-0.333,0.307-1.007,0.905-1.34,1.203c1.331,1.271,2.56,2.901,4.531,3.226
	C2557.833,499.321,2557.329,497.094,2555.127,496.744 M657.357,498.758c-0.461-0.034-1.374-0.085-1.826-0.119
	c-0.469,0.759-0.93,1.536-1.382,2.313c0.563-0.06,1.698-0.171,2.261-0.23c1.28-1.015,2.586-1.98,3.84-3.029
	C658.867,497.247,657.903,497.597,657.357,498.758 M2517.794,498.698c0.879,1.604,1.493,3.703,3.524,4.215
	c-0.478-1.749-0.811-3.541-0.964-5.333C2519.467,497.947,2518.613,498.323,2517.794,498.698 M2531.985,497.205
	c-0.717,1.732-1.382,3.473-2.048,5.214c1.399,2.057,2.424,4.787,5.026,5.632C2535.074,504.219,2535.97,499.441,2531.985,497.205
	 M2560.683,497.162c-0.068,1.801-0.068,3.618-0.043,5.436c0.452,0,1.348,0,1.801,0
	C2562.048,500.755,2563.217,497.683,2560.683,497.162 M516.975,498.408c-0.41,1.382,0.077,1.869,1.459,1.451
	C518.827,498.459,518.34,497.973,516.975,498.408 M535.381,498.041c-0.597,0.495-1.792,1.485-2.389,1.971
	c1.109,0.623,2.287,1.126,3.482,1.596C536.738,500.268,537.148,498.527,535.381,498.041 M554.547,500.661
	c1.126,0.529,2.321,0.828,3.533,1.195C558.805,499.526,554.138,497.367,554.547,500.661 M2235.23,501.975
	c0.768-0.077,2.321-0.222,3.098-0.29c-0.239-0.512-0.717-1.527-0.956-2.031c0.128-0.427,0.384-1.28,0.512-1.707
	C2236.45,498.869,2235.742,500.422,2235.23,501.975 M2436.625,498.434c-0.196,1.681-0.538,3.354-1.084,4.966l-0.435-1.809
	c-1.16,0.691-2.287,1.374-3.379,2.082c0.555,0.35,1.673,1.033,2.236,1.374c0.512-0.265,1.058-0.521,1.638-0.768
	c0.751,0.973,1.689,1.246,2.816,0.828c0.162-1.715,0.043-3.43-0.009-5.137l1.109,0.094c0.256,1.101,0.589,2.21,1.579,2.927
	c-0.009-1.502,0.077-3.012-0.162-4.489C2439.603,497.478,2438.016,498.22,2436.625,498.434 M2492.331,498.57
	c-0.529,1.399-0.077,1.843,1.365,1.331C2494.148,498.502,2493.696,498.058,2492.331,498.57 M2536.729,498.152
	c0,1.357,0.06,2.722,0.128,4.096c0.7-0.358,1.468-0.469,2.296-0.341c0.452,1.203-0.538,2.278-0.794,3.405
	c4.664-0.327,2.572-0.233,3.618-3.106c-1.544-0.222-2.321-1.502-3.157-2.577C2538.086,499.125,2537.386,498.63,2536.729,498.152
	 M45.858,500.14c-0.034,0.614-0.085,1.843-0.111,2.458c0.87-0.393,1.741-0.768,2.611-1.135c-0.051-0.546-0.154-1.647-0.205-2.202
	C47.386,499.551,46.618,499.842,45.858,500.14 M542.438,499.039c0.137,0.708,0.401,2.125,0.538,2.833l-1.024-0.034
	c0.375,1.212,0.461,2.449-0.41,3.507c0.563-0.085,1.69-0.256,2.253-0.341c1.374-0.674,2.765-1.323,4.147-1.988
	c-0.819-0.572-1.655-1.126-2.475-1.672C544.674,500.311,543.573,499.611,542.438,499.039 M2175.846,501.369
	c0.648,0.051,1.928,0.154,2.577,0.205c-0.12-0.521-0.367-1.553-0.487-2.074C2176.58,498.997,2176.384,500.55,2175.846,501.369
	 M2461.585,502.7c1.937-0.435,2.901-2.048,3.49-3.823C2463.266,499.441,2461.986,500.874,2461.585,502.7 M2552.354,499.287
	c-0.811,1.135,0.854,2.987,2.048,2.125C2555.571,500.251,2553.506,498.169,2552.354,499.287 M2222.396,500.063
	c0.171,0.563,0.521,1.698,0.691,2.261c1.434,0.7,1.724-1.203,2.406-2.057C2224.717,500.217,2223.172,500.115,2222.396,500.063
	 M2430.404,502.274c1.126,0.896,3.183-0.887,2.176-2.108C2431.343,499.142,2429.619,501.087,2430.404,502.274 M2465.075,501.233
	c0.119,1.894,2.005,2.304,3.601,2.432c-0.273-1.246-0.196-2.628-1.084-3.618C2466.458,499.833,2465.621,500.226,2465.075,501.233
	 M2512.631,500.191c0.068,1.647,1.374,2.611,2.526,3.627c-1.399,0.213-2.782-0.077-4.087-0.375c0.384,0.836,0.794,1.681,1.229,2.534
	c-2.432,0.23-5.06,0.794-6.69,2.748c0.64-0.068,1.92-0.222,2.56-0.299c2.014-1.732,4.753-2.082,7.1-3.26
	c0.051,0.392,0.154,1.186,0.205,1.579c0.768-0.324,1.57-0.64,2.406-0.947c-0.87,1.545-0.145,2.79,1.126,3.78
	c0.538-0.589,1.084-1.178,1.647-1.783c-1.587-2.901-4.702-4.83-5.538-8.098C2514.492,499.825,2513.254,500.072,2512.631,500.191
	 M382.643,503.255c0.649-0.026,1.946-0.077,2.594-0.102c0.051-0.623,0.162-1.886,0.213-2.509
	C384.171,501.13,382.857,501.736,382.643,503.255 M509.295,502.111c0.068,0.555,0.222,1.655,0.299,2.21
	c0.87-0.529,1.647-1.186,2.287-1.98C511.232,502.282,509.935,502.171,509.295,502.111 M657.152,501.992
	c-1.382,1.092,0.478,3.345,1.562,1.655C660.224,502.487,658.389,500.61,657.152,501.992 M2274.056,502.111
	c0.043,0.58,0.145,1.741,0.188,2.321c0.802-0.623,1.57-1.323,2.219-2.142C2275.865,502.248,2274.654,502.163,2274.056,502.111
	 M2444.911,501.804c0,0.324-0.008,0.981-0.008,1.306C2447.326,504.936,2447.249,499.5,2444.911,501.804 M389.103,502.82
	c0.111,0.461,0.324,1.391,0.435,1.86c1.485,0.213,2.654-0.179,2.876-1.826C391.305,502.837,390.204,502.82,389.103,502.82
	 M394.342,502.845c-1.246-0.111-2.031,2.236-0.41,2.21C395.179,505.149,395.93,502.837,394.342,502.845 M479.829,503.613
	c1.724,1.357,4.113,2.133,5.666,0.111C483.635,503.17,481.707,503.306,479.829,503.613 M518.084,502.794
	c-0.87,1.263,1.331,2.483,2.167,1.348C521.216,502.828,518.895,501.582,518.084,502.794 M663.083,503.579
	c-1.024,1.288,1.271,2.458,2.091,1.297C666.223,503.571,663.885,502.342,663.083,503.579 M2231.484,503.904
	c-0.017,0.427-0.043,1.297-0.051,1.724c0.555,0.026,1.681,0.06,2.244,0.077c0.009-0.529,0.017-1.579,0.026-2.099
	C2233.148,503.673,2232.038,503.827,2231.484,503.904 M28.032,504.305c-0.93,1.314,1.365,2.364,2.21,1.331
	C31.232,504.33,28.851,503.135,28.032,504.305 M385.647,504.339c-1.417,0.478-0.597,2.671,0.768,2.347
	C388.053,506.395,387.072,503.81,385.647,504.339 M2567.083,504.407C2567.083,507.24,2569.643,503.178,2567.083,504.407
	 M453.606,504.91c-0.375,1.348,0.137,1.835,1.536,1.459C455.518,504.996,455.006,504.509,453.606,504.91 M555.972,506.267
	c0.614,0.418,1.237,0.845,1.869,1.28c1.391-0.085,2.79-0.128,4.198-0.137c0.265-0.623,0.538-1.237,0.819-1.852
	c-0.973,0.213-2.022,1.229-2.961,0.367C558.669,504.927,557.235,506.071,555.972,506.267 M2169.088,505.226
	C2169.054,508.042,2171.691,504.057,2169.088,505.226 M2523.682,508.546c0.512,0,1.536,0,2.04,0
	c-0.154-1.178-0.299-2.355-0.41-3.516C2523.605,505.371,2523.989,507.274,2523.682,508.546 M18.057,506.131
	c-0.478,1.382-0.017,1.809,1.391,1.263C19.934,505.986,19.465,505.567,18.057,506.131 M390.008,508.153
	c1.587-0.162,3.575-0.307,4.403-1.911c-0.794-0.128-2.389-0.367-3.183-0.495C390.938,506.523,388.685,507.359,390.008,508.153
	 M422.306,506.498c0.503,0.939,1.075,1.877,2.142,2.27c0-0.64,0-1.937,0-2.577C423.91,506.267,422.844,506.421,422.306,506.498
	 M475,507.965c0.802,0.486,1.613,0.973,2.432,1.468c0.111,1.783-0.06,3.763,1.212,5.222c1.075-0.785,2.304-1.169,3.686-1.135
	c-0.239-0.333-0.717-0.998-0.956-1.331c1.271-0.751,2.859-1.297,3.277-2.893c-1.835,0.384-3.644,0.87-5.453,1.323
	c-0.589-1.63-0.776-3.362-0.913-5.069C477.167,506.318,476.066,507.129,475,507.965 M516.651,507.206
	c1.135,0.452,2.287,0.913,3.447,1.357c0.094-0.666,0.273-2.014,0.367-2.679C519.091,506.071,517.478,505.849,516.651,507.206
	 M543.488,505.815c-0.06,0.495-0.196,1.485-0.256,1.98l0.981-0.154l0.051,1.033c-0.683-0.35-1.357-0.683-2.031-1.007
	c-0.589,0.333-1.783,1.015-2.381,1.348c2.125,3.387,2.28,4.115,3.814,8.508c0.751-0.521,1.493-1.058,2.219-1.621
	c0.171-1.604-2.039-1.459-2.978-2.176c0.188-1.016,0.375-2.022,0.58-3.021c1.783-0.631,2.807-2.048,2.645-3.968
	C545.246,506.421,544.367,506.114,543.488,505.815 M2267.264,508.008c0.324,0.188,0.973,0.58,1.306,0.777
	c0.239,0.964,0.503,1.946,0.793,2.927c0.973-0.06,2.125-0.034,2.091-1.408c-0.742-0.597-1.468-1.101-2.185-1.647
	c1.638-0.384,2.355-1.34,2.15-2.867C2269.688,505.081,2268.552,507.223,2267.264,508.008 M2421.803,506.173
	c-0.043,0.58-0.137,1.749-0.188,2.33c0.904-0.299,1.817-0.606,2.79-0.896c0.99,1.195,2.637,0.896,4.036,1.169
	c0.009,0.58,0.034,1.758,0.042,2.338c-0.921-0.316-1.835-0.597-2.722-0.853c0.085,1.169,0.512,2.287,0.922,3.405
	c1.613,0.034,3.354,0.213,4.881-0.614c-0.683-1.835-0.887-4.326-3.234-4.719C2427.529,505.909,2423.868,506.472,2421.803,506.173
	 M577.911,507.692c0.137,0.393,0.401,1.186,0.529,1.587c0.725,0.085,2.176,0.265,2.901,0.35
	C581.581,507.394,579.669,506.762,577.911,507.692 M600.994,508.409c1.152,0.273,2.33,0.529,3.516,0.759
	c-0.213-0.819-0.427-1.638-0.631-2.449C602.718,506.933,601.557,507.249,600.994,508.409 M380.433,507.359
	c0.137,1.621-0.111,3.379,0.589,4.924C384.162,512.343,382.609,507.778,380.433,507.359 M409.131,508.153
	c0.674,0.922,1.212,2.031,2.287,2.594c0.768-0.495,1.442-1.118,2.159-1.681C412.262,508.145,410.658,508.196,409.131,508.153
	 M533.163,508.494c0.452,1.297,0.853,2.79,2.5,2.927c-0.043-1.126-0.111-2.244-0.196-3.345
	C534.699,508.204,533.922,508.349,533.163,508.494 M2261.436,507.965c-1.238,0.828,0.102,2.961,1.391,2.099
	C2264.055,509.228,2262.665,507.01,2261.436,507.965 M2292.975,507.684c-0.495,1.425-1.178,2.782-1.954,4.053
	c0.418,0.427,0.862,0.862,1.331,1.306c1.237-1.655,2.432-3.345,3.669-5.009C2294.972,507.906,2293.956,507.786,2292.975,507.684
	 M2512.247,507.82c-1.092,0.282-1.818,2.27-0.333,2.534C2513.809,510.815,2514.466,507.138,2512.247,507.82 M2556.757,508.187
	c-0.35,2.458-1.041,4.83-2.108,7.074c1.178,0.785,2.372,1.545,3.652,2.253c-1.265-3.358-2.184-6.729,0.717-4.634
	c-0.828-1.724-1.135-3.618-1.186-5.495C2557.568,507.59,2557.03,507.982,2556.757,508.187 M2279.501,508.58
	c-0.282,2.807-2.73,0.922-4.309,0.58c0.793,1.852,2.586,2.944,4.01,4.309c-2.014-0.29-2.944,1.126-3.789,2.569
	c0.796,1.156,2.966,2.891,3.405-1.877c0.418,0.111,1.254,0.324,1.672,0.435c0.068-1.783,0.222-3.567,0.469-5.325
	C2280.593,509.1,2279.868,508.75,2279.501,508.58 M148.369,509.595c-1.229,1.203,0.725,2.995,1.894,1.86
	C151.484,510.363,149.47,508.366,148.369,509.595 M563.379,509.595c-0.043,0.521-0.128,1.553-0.171,2.074
	c0.666,0.051,1.997,0.154,2.662,0.205C565.393,510.739,564.403,510.124,563.379,509.595 M582.118,509.749
	c-0.956,1.067,0.768,2.739,1.818,1.766C585.08,510.44,583.151,508.622,582.118,509.749 M2273.22,509.774
	c-0.486,1.391-0.009,1.835,1.425,1.331C2275.063,509.706,2274.585,509.262,2273.22,509.774 M429.747,512.812
	c0.179,2.219,2.509,2.091,2.91,0.009c0.691,0.555,1.391,1.126,2.108,1.681c0.188-1.485-0.444-2.808-1.067-4.088
	C432.367,511.174,430.788,511.66,429.747,512.812 M508.544,510.534c-1.186,1.365,1.348,2.33,2.108,1.118
	C511.821,510.295,509.286,509.365,508.544,510.534 M420.881,511.293c-0.06,0.58-0.188,1.758-0.256,2.347
	c0.614,0,1.835-0.017,2.441-0.017c-0.077-0.563-0.247-1.681-0.333-2.236C422.272,511.362,421.35,511.31,420.881,511.293
	 M568.977,511.438c-1.425,1.442,1.869,3.098,2.441,1.263C571.793,511.379,569.865,510.653,568.977,511.438 M2181.495,512.266
	c0.819,0.384,1.664,0.776,2.543,1.178c1.092-0.469,2.15-1.024,2.185-2.432C2184.61,511.336,2183.031,511.78,2181.495,512.266
	 M2257.852,514.058c2.125,0.674,3.874,1.988,5.214,3.797c0.512-0.777,1.058-1.545,1.63-2.295c-1.63,0.486-2.765-0.529-3.371-1.818
	c-1.306-0.461-2.142-1.527-2.782-2.628C2258.159,512.01,2256.751,513.188,2257.852,514.058 M2265.677,511.259
	c-0.922,1.271,1.28,2.458,2.15,1.34C2268.792,511.293,2266.453,510.013,2265.677,511.259 M2283.605,513.145
	c-1.024,0.521-2.006,1.118-2.355,2.27c1.169-0.188,2.875,0.512,3.695-0.794c1.314-1.451,3.627-1.604,4.642-3.43
	C2287.368,511.029,2285.073,511.336,2283.605,513.145 M2302.174,511.421c-0.452,1.391,0.802,2.167,1.656,3.038
	c0.708-0.128,2.133-0.393,2.85-0.529c-0.068,0.572-0.205,1.724-0.273,2.295c0.563-0.094,1.681-0.273,2.244-0.367
	c0.017-0.401,0.051-1.22,0.068-1.621c-0.606-0.768-1.178-1.545-1.707-2.321C2305.391,511.711,2303.77,511.507,2302.174,511.421
	 M2309.828,511.259c0.495,0.922,1.024,1.843,1.578,2.773c0.35,0.008,1.058,0.008,1.408,0.017c-0.068-0.939-0.094-1.869-0.094-2.799
	C2311.723,511.242,2310.758,511.251,2309.828,511.259 M43.981,514.263c1.894,0.137,3.797,0.222,5.7,0.29
	c0,0.572-0.017,1.715-0.026,2.287c1.263-0.316,2.099-1.058,2.509-2.227c-0.521,0.068-1.562,0.213-2.074,0.282
	C50.219,511.908,44.689,511.481,43.981,514.263 M484.6,511.754c0.06,1.186,0.145,2.372,0.256,3.567
	c0.512-0.435,1.015-0.879,1.502-1.34C486.46,512.846,485.342,512.334,484.6,511.754 M2416.503,511.421
	c0.171,0.461,0.495,1.391,0.666,1.852l-0.666,1.015c2.987,2.074,6.306,3.942,8.047,7.33c1.382-1.877,3.328-3.593,3.823-5.965
	c-1.545-0.905-3.217-1.57-4.736-2.432c-0.179,0.563-0.529,1.681-0.708,2.236c-0.674-0.333-1.314-0.657-1.92-0.981
	C2420.728,512.087,2418.492,511.609,2416.503,511.421 M53.24,515.415c1.28-0.043,2.56-0.094,3.84-0.145
	c-0.06-0.623-0.171-1.86-0.23-2.483C55.356,513.137,53.393,513.546,53.24,515.415 M489.182,513.137
	c-0.913,1.237,1.297,2.483,2.108,1.323C492.288,513.128,489.993,511.839,489.182,513.137 M518.451,515.577
	c0.99,1.297,2.091-0.085,3.038-0.632c-0.358-0.555-1.067-1.664-1.425-2.219C519.39,513.503,516.813,514.442,518.451,515.577
	 M669.423,513.563c0.913,1.237,1.792,2.995,3.575,1.792C673.562,512.983,670.916,513.06,669.423,513.563 M680.081,513.751
	c-0.563,1.502,1.451,1.425,2.295,1.903c-0.026-0.768-0.085-2.304-0.111-3.072C681.515,512.932,680.781,513.316,680.081,513.751
	 M2270.959,513.026c-0.077,1.109-0.136,2.227-0.162,3.354c1.374-0.384,3.123-0.717,3.226-2.509
	C2272.981,513.521,2271.966,513.282,2270.959,513.026 M2413.227,512.863c-0.717,1.152,1.246,2.313,2.159,1.476
	C2416.068,513.179,2414.063,512.036,2413.227,512.863 M376.303,513.896c-1.092,1.314,1.323,2.372,2.159,1.28
	C379.52,513.87,377.114,512.761,376.303,513.896 M2227.507,516.9c0.717,0.051,2.159,0.154,2.875,0.205
	c0.546-1.101,1.126-2.176,1.724-3.268C2229.777,513.154,2228.326,515.031,2227.507,516.9 M2295.228,514.741
	c1.135,1.758,3.021,0.717,4.6,0.222C2298.854,512.872,2296.755,514.118,2295.228,514.741 M2185.728,515.748
	c1.306,1.835,3.669,1.272,5.632,1.4c-0.461-0.87-0.896-1.732-1.314-2.586C2188.587,514.929,2187.119,515.321,2185.728,515.748
	 M2250.965,514.536c-0.077,1.254-0.111,2.517-0.137,3.789c-1.05,0.913-0.427,2.022-0.111,3.089c-2.21,1.203-4.548,2.15-6.784,3.26
	c2.21,2.611,4.881-1.237,7.595-1.135c0.35-0.341,1.032-1.024,1.374-1.365c1.656,0.094,3.32,0.239,5.026,0.401
	c-0.068-2.082-0.111-4.156-0.188-6.229c-0.7,0.06-2.108,0.179-2.816,0.23C2254.063,515.014,2252.672,514.382,2250.965,514.536
	 M2287.24,516.422c-0.999,0.324-1.988,0.64-2.927,0.964c-0.017,0.648-0.051,1.954-0.068,2.603c1.408-0.666,2.5-1.775,3.396-3.046
	c1.86,0.614,3.499-0.563,3.328-2.577C2289.613,514.86,2288.333,515.517,2287.24,516.422 M384.828,515.697
	c-1.118,1.382,1.417,2.261,2.15,1.075C388.113,515.389,385.545,514.493,384.828,515.697 M522.24,515.765
	c-1.015,1.698,2.867,2.193,3.115,0.631C524.962,515.159,523.11,514.929,522.24,515.765 M2166.938,516.857
	c1.306,0.06,2.645,0.111,4.011,0.137C2170.632,514.596,2167.654,514.963,2166.938,516.857 M2222.114,515.517
	c-1.161,0.222-1.852,2.244-0.35,2.5C2223.633,518.461,2224.358,514.818,2222.114,515.517 M2433.399,516.942
	c1.323,0.333,1.843-0.179,1.553-1.536C2433.536,515.039,2433.024,515.551,2433.399,516.942 M398.012,518.461
	c1.314,0.102,2.637,0.188,3.959,0.265C401.937,516.063,398.933,516.831,398.012,518.461 M417.826,516.61
	c0.828,1.152,1.877,2.099,3.319,2.406c0.026-0.64,0.06-1.937,0.085-2.586C420.088,516.473,418.953,516.533,417.826,516.61
	 M2265.771,516.251c-0.102,1.109-0.179,2.236-0.213,3.362c0.589-0.051,1.758-0.154,2.347-0.205
	C2267.674,517.992,2267.358,516.558,2265.771,516.251 M2397.099,517.454c-0.333,1.441,4.12,5.133,5.649,5.538
	c0.12,0.503,0.367,1.519,0.487,2.022c1.118-0.794,1.152-2.142,1.331-3.388c-0.444,0.137-1.34,0.418-1.784,0.563
	c-0.017-0.563-0.051-1.681-0.077-2.236c-1.186-0.324-2.313-0.666-3.405-1.041c0.333-0.973,0.683-1.946,1.05-2.91
	C2399.198,516.413,2398.071,516.772,2397.099,517.454 M20.07,518.316c0.393,1.126,1.143,1.971,2.278,2.406l0.034-1.306
	c1.246,0.179,2.483,0.213,3.729,0.094c0.589-1.289-0.589-1.911-1.536-2.432C23.211,518.001,21.76,518.666,20.07,518.316
	 M371.345,518.777c0.862,0.683,2.449-0.572,1.894-1.545C372.378,516.601,370.773,517.779,371.345,518.777 M386.944,521.994
	c-1.041,0.316-2.091,0.597-3.149,0.853c0.887,2.782,4.335,1.195,6.187,0.469c0.768-2.082,0.247-4.565,0.64-6.775
	C387.968,517.139,389.009,520.723,386.944,521.994 M2259.558,519.221c1.084,0.717,2.33-1.203,1.391-2.014
	C2259.746,516.371,2258.552,518.385,2259.558,519.221 M2270.788,517.403c0.051,0.93,0.145,1.869,0.264,2.816
	c0.478-0.452,1.434-1.374,1.911-1.826c0.666,0.375,1.374,0.734,2.133,1.075c0.239-0.572,0.717-1.724,0.964-2.295
	C2274.278,517.344,2272.529,517.42,2270.788,517.403 M2299.563,517.147c-0.051,1.587-0.094,3.174-0.085,4.77
	c6.841-0.99,4.621-0.976,4.258-3.627c0.828,0.367,1.69,0.742,2.577,1.118c0.648-0.666,0.922-1.417,0.802-2.227
	c-1.323-1.417-2.364,0.239-3.302,1.041C2302.353,517.89,2300.962,517.531,2299.563,517.147 M2309.034,518.291
	c0.017,0.529,0.068,1.596,0.085,2.133c0.487-0.043,1.459-0.12,1.954-0.154c0.119-1.016,0.281-2.022,0.469-3.021
	C2310.673,517.591,2309.845,517.941,2309.034,518.291 M2417.05,517.028c0.734,3.081,3.669,5.18,6.468,6.332
	C2422.741,520.159,2419.584,518.623,2417.05,517.028 M540.536,518.24c2.581,4.208,6.23,1.705,5.069,0
	C543.974,517.565,542.2,518.103,540.536,518.24 M669.312,521.013c1.553,0.009,3.081,0.495,4.241,1.57
	c1.05-1.903-2.244-2.423-1.843-4.54C670.831,518.965,670.012,519.938,669.312,521.013 M678.374,518.001
	c-0.461,1.348,0.017,1.835,1.417,1.476C680.252,518.103,679.782,517.608,678.374,518.001 M683.204,520.458
	c0.546-0.051,1.647-0.162,2.193-0.213c0.017-0.503,0.051-1.519,0.068-2.031C683.947,517.984,682.214,518.7,683.204,520.458
	 M2293.325,521.132c-1.57,0.538-3.336,0.222-4.864,0.751c-0.614-0.785-1.126-1.63-1.587-2.466c-0.196,0.64-0.58,1.928-0.768,2.568
	c0.196,0.324,0.427,0.657,0.691,0.998c-1.306,0.742-1.98,2.074-2.782,3.234c-0.862-0.538-1.707-1.058-2.517-1.57
	c-0.171,1.536,0.469,2.935,0.964,4.361c-3.719,1.946-3.666,2.479-5.47,2.893c0.085,0.521,0.264,1.553,0.358,2.065
	c-2.133,0.521-4.258,1.05-6.306,1.741c1.476,2.654,3.72,0.725,5.726,0.077c1.681,1.391,4.778,3.191,6.024,0.316
	c1.758-0.862,3.584-1.613,5.453-2.304c0.068-1.041,0.171-2.065,0.299-3.089c0.973,0.085,1.98,0.179,3.021,0.282
	c0.324,0.367,0.981,1.109,1.305,1.476c0.256-0.316,0.76-0.956,1.007-1.28c2.057-0.589,3.661-1.98,4.565-3.934
	c-0.862-0.717-1.579-1.536-2.159-2.44c-0.026,0.887-0.017,1.783,0.025,2.688c-1.664-0.043-3.336-0.154-4.932-0.299
	c0.793-0.734,1.698-1.348,2.697-1.852c0.58-0.555,1.186-1.109,1.818-1.664c0.546-2.005,1.314-3.934,2.056-5.871
	C2296.422,518.948,2294.989,520.228,2293.325,521.132 M27.264,518.7c-0.7,0.913-1.399,1.835-2.091,2.773
	c1.596-0.051,3.2-0.222,4.574-1.092C28.92,519.81,28.092,519.255,27.264,518.7 M401.425,521.269c1.178-0.026,2.364-0.051,3.55-0.085
	c-0.06-0.529-0.171-1.604-0.23-2.142C403.294,519.212,401.528,519.426,401.425,521.269 M2332.51,522.651
	c-1.587-0.444-0.572-2.048-0.606-3.106c-2.585-0.452-2.193,3.32-2.278,5.06c2.167,0.802,4.25-0.768,6.272-1.425
	c-0.256-0.427-0.785-1.28-1.041-1.698c0.341-1.05,0.708-2.099,1.101-3.14C2333.935,518.896,2331.784,520.185,2332.51,522.651
	 M2445.448,518.99c-2.073,0.648-1.306,3.14-2.022,4.651c-1.715,1.417-3.405,3.123-3.285,5.538c1.63-0.99,3.243-2.039,4.855-3.106
	c0.162-0.478,0.487-1.417,0.657-1.894c0.905,0.102,2.722,0.307,3.635,0.41c-0.316,1.118-1.007,1.843-2.074,2.185
	c-0.017,0.35-0.051,1.041-0.068,1.391c0.546-0.026,1.638-0.077,2.185-0.111c0.854-1.417,1.758-2.825,2.62-4.258
	c-0.776,0.137-2.321,0.418-3.089,0.555c-0.896-0.896-1.604-1.911-2.654-2.551c0.461-1.075,0.922-2.159,1.434-3.226
	C2447.095,518.675,2445.995,518.888,2445.448,518.99 M381.841,519.682c-0.196,2.62,3.422,2.185,3.994,0.171
	C384.504,519.767,383.172,519.707,381.841,519.682 M421.922,519.775c-0.777,1.016,0.563,3.251,1.818,2.261
	C424.917,520.91,423.236,518.641,421.922,519.775 M479.42,520.177c-1.203,1.237,1.05,3.123,2.142,1.809
	C482.586,520.74,480.461,519.374,479.42,520.177 M564.873,522.847c0.068,0.333,0.205,0.99,0.273,1.323
	c0.529,0.162,1.604,0.495,2.142,0.657c-0.068-0.717-0.205-2.142-0.273-2.859c1.186,0.631,2.398,1.246,3.61,1.852
	c0.017-0.563,0.051-1.681,0.068-2.244c-0.896-0.589-1.613-1.57-2.679-1.801C567.151,520.996,566.391,522.335,564.873,522.847
	 M2188.868,519.929c-1.075-0.145-2.603,1.024-1.707,2.099C2188.476,523.556,2190.933,520.526,2188.868,519.929 M2242.884,519.963
	c0.009,0.444,0.026,1.323,0.034,1.766c0.504,0.435,1.05,0.887,1.63,1.348c0.469-0.401,1.417-1.203,1.886-1.613
	C2245.879,519.852,2244.352,519.656,2242.884,519.963 M2517.026,521.491c0.248,1.391,2.219,0.768,2.535-0.29
	C2519.851,519.4,2516.676,519.852,2517.026,521.491 M392.422,520.484c-0.171,1.271-0.401,2.543-0.461,3.831
	c1.143,0.589,1.997-0.853,2.97-1.263c-0.034-0.606-0.102-1.818-0.137-2.423C394.197,520.595,393.011,520.526,392.422,520.484
	 M395.785,521.482c0.273,1.775,3.857,0.836,3.063-0.802C397.926,519.997,396.075,520.228,395.785,521.482 M425.327,520.526
	c0.375,1.391,1.22,2.44,2.671,2.799c-0.085-0.606-0.239-1.818-0.324-2.423C427.085,520.808,425.916,520.62,425.327,520.526
	 M561.408,520.774c-1.041,1.323,1.382,2.398,2.219,1.297C564.54,520.765,562.219,519.656,561.408,520.774 M2279.782,523.573
	c1.536-0.085,3.115-0.102,4.659,0.222c-0.811-1.34-0.913-3.669-2.944-3.516C2280.806,521.32,2280.303,522.455,2279.782,523.573
	 M2306.765,520.859c-1.817,1.681-4.079,3.115-4.941,5.538c1.314-0.649,2.637-1.34,3.977-2.014c0.811-0.836,1.724-1.613,2.569-2.458
	C2309.171,521.414,2307.183,519.861,2306.765,520.859 M2336.725,521.354c-0.521,1.681,2.278,2.065,2.748,0.563
	C2340.036,520.253,2337.22,520.142,2336.725,521.354 M2424.687,527.387c4.122-0.239,6.827-4.395,8.73-7.663
	C2430.233,521.841,2425.967,523.462,2424.687,527.387 M497.758,521.678c-1.229,0.495-4.386,1.382-2.978,3.14
	c1.485-0.282,2.901-0.828,4.335-1.306C498.731,522.796,499.012,520.927,497.758,521.678 M518.008,521.456
	c-1.681,0.282-0.896,2.867,0.64,2.458C520.405,523.65,519.663,520.868,518.008,521.456 M531.712,521.926
	c-0.102,0.512-0.29,1.527-0.384,2.031c1.092-0.102,2.185-0.205,3.285-0.299c0.102-0.597,0.299-1.792,0.393-2.389
	C533.905,521.482,532.804,521.704,531.712,521.926 M2396.979,521.397c-0.043,0.512-0.128,1.536-0.171,2.048
	c0.879,0.341,1.784,0.691,2.714,1.041c-0.512,1.587-0.938,3.2-1.22,4.847c2.039,0.136,3.746-1.587,1.698-3.149
	c0.239-0.478,0.521-0.947,0.845-1.408C2399.351,523.854,2398.438,522.318,2396.979,521.397 M410.718,524.307
	c1.152,1.289,2.927-0.7,1.732-1.8C411.273,521.201,409.242,523.189,410.718,524.307 M546.355,522.404
	c-0.794,0.819-1.587,1.638-2.364,2.475c1.741,0,3.601-0.034,4.779-1.536C547.96,523.018,547.149,522.711,546.355,522.404
	 M609.024,526.713c1.459-1.297,3.115-2.662,3.541-4.676C610.355,522.489,609.894,524.989,609.024,526.713 M686.959,523.18
	c-0.199,0.738-0.712,2.579-0.947,3.32c0.555-0.12,1.664-0.358,2.219-0.478c0.017-1.109,0.026-2.219,0.051-3.311
	C687.949,522.822,687.292,523.061,686.959,523.18 M2546.253,522.566c-0.034,0.512-0.102,1.527-0.136,2.039
	c0.409,0.222,1.229,0.683,1.638,0.905c0.452-0.614,0.777-1.306,0.956-2.057C2547.857,523.138,2547.038,522.839,2546.253,522.566
	 M25.464,522.899c-0.794,1.22,1.212,2.731,2.142,1.587C28.399,523.283,26.377,521.721,25.464,522.899 M445.884,525.348
	c0.631-0.051,1.886-0.145,2.509-0.196c0.043-0.555,0.137-1.656,0.179-2.202C447.249,523.249,446.259,524.017,445.884,525.348
	 M771.115,523.24c0.299,0.99,0.094,2.654,1.638,2.33c0.375-0.836,0.742-1.681,1.092-2.526
	C773.163,523.086,771.797,523.189,771.115,523.24 M851.763,523.402c-1.024,1.143,0.794,3.157,1.997,2.056
	C854.835,524.307,852.907,522.387,851.763,523.402 M2240.725,522.779c0.017,0.657,0.06,1.98,0.077,2.637c0.683,0,2.04,0,2.722,0.009
	C2242.807,524.307,2242.073,523.112,2240.725,522.779 M2237.73,523.863c-0.854,0.845-1.664,1.707-2.449,2.577
	c1.135,0.649,2.398,0.623,3.499-0.154C2239.01,525.314,2238.08,524.639,2237.73,523.863 M2263.475,524.042
	c-0.58,1.852,1.007,2.884,2.475,3.618c0.205-1.28,0.521-2.526,0.811-3.789C2265.634,523.923,2264.542,523.974,2263.475,524.042
	 M2276.659,524.119c-0.896,1.161,1.195,2.338,2.031,1.331C2279.441,524.298,2277.436,523.206,2276.659,524.119 M2408.772,524.29
	c-0.793,1.203,1.297,2.347,2.15,1.331C2411.503,524.477,2409.531,523.428,2408.772,524.29 M412.911,524.93
	c-1.067,0.879,0.23,3.089,1.459,2.116C415.445,526.141,414.174,523.846,412.911,524.93 M846.481,524.861
	C846.583,527.652,849.092,523.59,846.481,524.861 M2273.809,524.742c0.188,1.536-0.316,3.669,1.536,4.361
	c0.939-0.401,1.903-0.819,2.876-1.254C2277.094,526.346,2275.473,525.45,2273.809,524.742 M2358.997,525.928
	c1.203,1.434,3.456,1.886,4.804,0.35c-0.631-0.367-1.903-1.101-2.543-1.468C2360.473,525.169,2359.723,525.544,2358.997,525.928
	 M2541.073,524.759c0.102,0.64,0.307,1.911,0.41,2.552c1.314,0.094,2.347-0.512,3.234-1.51
	C2543.479,525.433,2542.259,525.083,2541.073,524.759 M154.027,525.732c0.299,1.357,0.512,2.842,1.63,3.823
	c0.922-0.828,1.843-1.647,2.756-2.466c-0.239-0.367-0.734-1.101-0.973-1.468C156.297,525.646,155.162,525.681,154.027,525.732
	 M499.132,525.86c-1.067,1.34,1.391,2.33,2.219,1.271C502.392,525.8,499.934,524.793,499.132,525.86 M2432.717,525.902
	c-1.109,1.28,1.28,2.091,1.971,0.973C2435.72,525.612,2433.34,524.895,2432.717,525.902 M544.461,526.73
	c-1.041,1.178,0.811,3.132,2.048,2.185C547.413,527.797,545.621,525.774,544.461,526.73 M556.664,526.577
	c0.486,1.263,1.306,2.33,2.364,3.191c0.691-0.631,1.502-1.246,1.374-2.304C559.189,527.054,557.909,526.833,556.664,526.577
	 M666.965,526.79c0.12,0.521,0.358,1.562,0.478,2.082c1.476,0.7,1.809-1.212,2.5-2.091
	C669.201,526.781,667.716,526.79,666.965,526.79 M2301.175,528.249c0.06,0.64,0.162,1.92,0.222,2.551
	c1.707-1.033,3.448-2.202,4.053-4.233C2303.966,527.02,2302.541,527.592,2301.175,528.249 M2544.103,526.414
	c0.213,2.065,4.198,3.106,4.625,0.614C2547.174,526.807,2545.604,526.594,2544.103,526.414 M501.009,529.017
	c-0.512,0.026-1.519,0.06-2.031,0.085c0.009,0.452,0.026,1.357,0.034,1.809c2.099-0.512,4.215-1.007,6.349-1.408
	c-0.239-0.708-0.469-1.417-0.7-2.116C503.424,527.891,502.195,528.42,501.009,529.017 M2396.049,527.43
	c-0.572,1.408-0.145,1.852,1.288,1.34C2397.867,527.379,2397.44,526.935,2396.049,527.43 M448.205,528.531
	c-0.137,0.538-0.401,1.596-0.538,2.133c0.717-0.017,2.142-0.034,2.859-0.043c0.034,0.384,0.094,1.169,0.128,1.562
	c0.589-0.06,1.766-0.171,2.355-0.23C452.489,529.734,449.911,529.478,448.205,528.531 M535.808,528.403
	c-1.067,0.853,0.085,3.183,1.391,2.193C538.3,529.742,537.071,527.481,535.808,528.403 M681.199,528.181
	c0.401,0.461,1.212,1.382,1.613,1.843c-1.553,1.041-3.089,2.091-4.582,3.209c0.768,0.478,1.527,0.939,2.321,1.425
	c0.051-0.563,0.162-1.69,0.213-2.244c0.461-0.034,1.391-0.102,1.852-0.128c0.469-1.357,1.007-2.679,1.553-4.002
	C683.426,528.258,681.941,528.206,681.199,528.181 M857.515,528.351c-1.314,0.034-1.596,2.338-0.154,2.406
	C858.624,530.741,858.863,528.42,857.515,528.351 M2420.565,530.971c1.724,0.375,4.377-2.048,1.783-2.901
	C2420.352,527.165,2420.753,529.896,2420.565,530.971 M521.327,530.34c-0.802,1.314,1.442,1.929,2.133,0.981
	c0.06-0.486,0.171-1.451,0.23-1.937C522.735,529.12,521.941,529.444,521.327,530.34 M2171.674,530.28
	c-1.229,1.374,1.297,2.372,2.082,1.135C2174.823,530.075,2172.382,529.077,2171.674,530.28 M228.634,530.886
	c-1.033,1.271,1.263,2.534,2.125,1.382C231.697,531.005,229.487,529.742,228.634,530.886 M548.207,530.801
	c-0.206,2.339,0.706,3.85,2.97,0.196C550.434,530.946,548.949,530.852,548.207,530.801 M2175.027,530.946
	c-0.726,2.022,4.104,4.984,3.174,1.604C2177.843,531.065,2176.409,530.34,2175.027,530.946 M2546.534,532.311
	c0.862,0.768,2.663,0.358,3.021-0.836C2549.291,529.674,2545.724,530.664,2546.534,532.311 M232.781,532.405
	c1.067,0.683,1.749,0.486,2.057-0.58C233.762,531.125,233.071,531.321,232.781,532.405 M397.372,536.049
	c1.698-0.503,3.226-1.408,4.292-2.842c-0.964-0.435-1.92-0.87-2.859-1.323C398.234,533.233,397.824,534.649,397.372,536.049
	 M479.326,532.576c0.034,0.469,0.102,1.399,0.137,1.869c0.495-0.247,1.493-0.725,1.988-0.973c1.954,0.683,5.342,1.886,6.135-0.853
	C484.838,532.268,482.065,532.046,479.326,532.576 M533.299,531.842c0.026,0.452,0.077,1.357,0.111,1.809
	c0.521,0.043,1.57,0.128,2.091,0.171c0.026-0.512,0.068-1.545,0.094-2.057C535.023,531.782,533.871,531.825,533.299,531.842
	 M389.496,535.485c-1.741,2.108-4.002,3.686-6.007,5.521c1.451-0.06,2.978,0.026,4.292-0.734c0.512,0.179,1.024,0.367,1.545,0.572
	c2.108-2.458,6.323-1.869,7.603-5.146c-1.254,0.06-2.5,0.137-3.746,0.23c0.956-1.203,1.903-2.424,2.807-3.661
	C393.72,533.036,391.057,533.506,389.496,535.485 M2171.307,532.857c-0.887,0.981-1.715,1.98-2.577,2.961
	c1.775,0.111,3.61,0.273,5.427,0.196C2173.227,534.922,2172.271,533.864,2171.307,532.857 M2267.375,533.557
	c0.205,0.427,0.606,1.297,0.811,1.724c-0.597,0-1.783,0.009-2.372,0.009c0.017,0.512,0.06,1.545,0.085,2.057
	c0.444,0.068,1.348,0.188,1.792,0.256c0.077-0.435,0.222-1.297,0.299-1.732c2.185,0.265,3.166-1.835,3.874-3.524
	C2270.327,532.703,2268.851,533.139,2267.375,533.557 M2302.08,533.574c0.051,0.486,0.154,1.468,0.205,1.963
	c1.28-0.469,2.654-0.828,3.883-1.536C2306.867,531.236,2303.326,533.019,2302.08,533.574 M2464.418,532.772
	c-0.478,1.254-0.094,1.587,1.135,1.015C2465.971,532.533,2465.596,532.191,2464.418,532.772 M423.509,535.844
	c1.536,0.58,3.038,1.246,4.617,1.741c1.801-0.307,2.347-2.313,0.538-3.123c-0.734,0.614-1.476,1.246-2.21,1.869
	c-0.034-0.469-0.102-1.425-0.145-1.894c-0.7-0.299-2.108-0.896-2.816-1.186C423.501,534.111,423.518,534.965,423.509,535.844
	 M504.491,533.378c-0.154,1.212-0.29,2.423-0.393,3.644c1.186-0.435,2.859-1.016,2.475-2.637
	C505.882,534.026,505.19,533.685,504.491,533.378 M687.625,533.591c-1.22,1.135,0.879,3.098,2.057,2.142
	C690.569,534.572,688.819,532.755,687.625,533.591 M855.211,533.625c-1.237,0.845,0.077,3.038,1.357,2.022
	C857.83,534.811,856.431,532.576,855.211,533.625 M2298.675,533.224c0.043,1.801,0.085,3.61,0.145,5.419
	c0.717-0.41,1.459-0.828,2.227-1.237c-0.538-0.648-1.058-1.289-1.544-1.92c0.23-0.648,0.495-1.288,0.785-1.92
	C2299.887,533.48,2299.076,533.309,2298.675,533.224 M843.102,534.453C843.597,534.948,843.597,534.948,843.102,534.453
	 M2293.478,537.516c0.487,1.485,1.348,2.953,3.174,2.645c0.009-2.022-0.009-4.036-0.077-6.05
	C2295.304,534.948,2293.017,535.579,2293.478,537.516 M489.6,536.168c0.87,0.887,1.929,1.519,3.234,1.442
	c0.179-0.469,0.538-1.425,0.717-1.894C492.228,535.827,490.906,535.972,489.6,536.168 M495.215,536.125
	c0.939,1.971,3.482,1.348,5.248,1.886c-0.205-0.819-0.41-1.63-0.606-2.44C498.304,535.75,496.751,535.912,495.215,536.125
	 M2194.807,536.211c-1.126,1.357,1.374,2.244,2.15,1.109C2198.024,535.929,2195.507,534.956,2194.807,536.211 M548.864,537.201
	c0.358,1.067,0.742,2.168,2.074,2.278c-0.026-0.657-0.06-1.98-0.085-2.645C550.357,536.927,549.359,537.107,548.864,537.201
	 M674.697,536.68c0.111,1.536,1.092,2.543,2.415,3.2C677.333,538.105,676.651,536.68,674.697,536.68 M2259.746,536.97
	c-1.118,0.64-0.307,2.927,0.973,1.92C2261.854,538.233,2261,535.878,2259.746,536.97 M683.17,539.684
	c0.683,0.094,2.057,0.282,2.739,0.375c-0.085-0.683-0.247-2.057-0.333-2.739C684.612,537.934,683.759,538.694,683.17,539.684
	 M842.735,538.003c0.503,1.075,1.007,2.244,2.116,2.867c1.212-0.597,1.118-2.176,1.63-3.243c-0.512,0.179-1.527,0.546-2.039,0.734
	C844.015,538.267,843.162,538.088,842.735,538.003 M2274.799,537.755c0.128,0.606,0.384,1.801,0.512,2.406
	c0.751-0.606,1.451-1.28,2.082-2.048C2276.745,538.028,2275.448,537.849,2274.799,537.755 M160.393,538.574
	c0.085,0.469,0.256,1.399,0.341,1.869c0.503,0.111,1.519,0.333,2.031,0.444c-0.034-0.572-0.102-1.715-0.137-2.295
	C162.074,538.591,160.956,538.583,160.393,538.574 M563.192,538.259c0.009,0.759,0.034,2.261,0.051,3.012
	c-1.041,0.512-2.014,1.143-2.116,2.432c1.152-0.529,2.927-0.802,2.483-2.517C566.127,540.878,565.094,538.404,563.192,538.259
	 M2298.82,539.701c-0.58,1.604,1.28,1.843,2.304,2.492c0.009-0.777,0.034-2.321,0.043-3.089
	C2300.578,539.249,2299.401,539.556,2298.82,539.701 M2421.76,539.402c-1.63,0.273-1.528,3.226,0.358,2.449
	C2423.646,541.535,2423.484,538.856,2421.76,539.402 M534.605,542.449c0.7,0.077,2.091,0.222,2.782,0.299
	c-0.009-0.717-0.034-2.15-0.043-2.876C535.817,539.965,535.202,541.254,534.605,542.449 M554.658,544.181
	c1.442-0.009,2.441-1.075,3.533-1.852c0.546,0.29,1.638,0.87,2.185,1.161c0-0.563,0-1.681,0-2.236
	c-1.109-0.41-2.21-0.828-3.302-1.229C556.254,541.407,554.317,542.346,554.658,544.181 M566.212,542.79
	c1.451-0.034,2.918-0.043,4.369-0.247C571.443,539.633,565.265,539.283,566.212,542.79 M2182.921,540.307
	c-0.93,0.205-1.937,1.323-1.391,2.27C2182.682,543.455,2184.593,540.605,2182.921,540.307 M2429.781,540.119
	c0.256,1.075,0.768,2.261,1.98,2.62c0.887-0.478,1.22-1.553,1.818-2.321C2432.29,540.29,2431.019,540.187,2429.781,540.119
	 M157.09,541.169c-1.092,1.263,1.229,2.475,2.082,1.357C160.205,541.245,157.944,539.974,157.09,541.169 M2267.486,541.237
	c-1.067,1.263,1.195,2.483,2.057,1.306C2270.626,541.228,2268.271,539.999,2267.486,541.237 M2288.734,541.211
	c-1.135,0.802-0.085,3.243,1.254,2.133C2291.106,542.517,2290.039,540.085,2288.734,541.211 M2291.703,543.089
	c0.768,0.759,1.621,1.544,2.449,2.313c0.154-1.485,1.613-3.337-0.017-4.463C2292.847,541.015,2292.352,542.218,2291.703,543.089
	 M159.957,542.815c-0.521,1.613,2.082,2.321,2.406,0.683C162.424,542.355,160.239,541.271,159.957,542.815 M502.144,543.985
	c0.708,0.068,2.125,0.188,2.833,0.256c-0.017-0.674-0.068-2.022-0.094-2.697C503.603,541.902,502.852,542.952,502.144,543.985
	 M668.621,542.252c-0.188,2.33,2.577,2.099,4.164,2.142c0-0.572,0.009-1.715,0.017-2.287
	C671.403,542.15,670.003,542.201,668.621,542.252 M2250.658,543.421c0.324,0.469,0.973,1.399,1.297,1.869
	c0.862-0.444,1.75-0.888,2.662-1.314c-0.819-0.785-1.724-1.57-2.492-2.33C2251.605,542.235,2251.119,542.824,2250.658,543.421
	 M2282.376,542.807c-0.768,1.553-0.828,3.285-0.922,4.983c1.135-0.452,2.33-0.794,3.584-1.007c1.459-1.391,2.338-3.294,2.014-5.35
	C2285.414,541.689,2283.972,542.534,2282.376,542.807 M2424.414,542.918c0.367,1.024,0.375,2.125,0.273,3.217
	c0.393-0.085,1.169-0.265,1.553-0.35c-0.205-1.34-0.427-2.679-0.606-4.011C2425.19,542.141,2424.781,542.525,2424.414,542.918
	 M527.121,543.993c0.239,0.742,0.623,1.391,1.152,1.946c1.186,0.111,1.067-1.212,1.434-1.954
	C529.067,543.985,527.77,543.993,527.121,543.993 M855.629,546.86c1.101-0.137,2.253-0.316,2.756-1.459
	c0.401,0.188,1.186,0.572,1.579,0.768c-0.043-0.691-0.145-2.065-0.196-2.756C858.232,544.36,855.979,544.778,855.629,546.86
	 M2189.705,543.814c-1.144,0.674-0.529,2.918,0.913,2.074C2192.674,545.401,2191.351,542.107,2189.705,543.814 M2193.007,543.609
	c-0.964,1.101,0.964,2.475,1.852,1.451C2195.644,543.993,2193.834,542.585,2193.007,543.609 M154.172,545.23
	c0.094,0.708,0.273,2.125,0.367,2.833c2.074-0.077,2.637-1.937,3.132-3.61C156.493,544.642,155.324,544.898,154.172,545.23
	 M673.263,544.923c0.273,1.775,1.741,3.021,3.004,4.173c0.026-0.623,0.068-1.877,0.085-2.5c-0.29-0.589-0.879-1.783-1.169-2.372
	C674.705,544.394,673.741,544.744,673.263,544.923 M2423.176,544.428c-1.391,2.27-3.371,4.036-5.308,5.803
	c1.092,0.418,2.219,0.828,3.371,1.22c0.316-0.478,0.939-1.451,1.246-1.937C2422.673,547.816,2422.963,546.126,2423.176,544.428
	 M2183.731,545.265c-0.538,1.143-0.171,1.545,1.101,1.212C2185.335,545.316,2184.969,544.915,2183.731,545.265 M2274.56,548.482
	c-0.248,0.299-0.751,0.896-0.999,1.203c-0.136,0.99-0.435,1.946-0.555,2.935c3.968-0.247,3.465-4.873,3.422-7.714
	C2274.773,545.427,2274.696,547.057,2274.56,548.482 M492.442,546.067c-0.068,0.623-0.222,1.869-0.299,2.492
	c0.333,0.145,0.99,0.435,1.314,0.58c2.065-2.62,4.966,0.06,7.637-0.444c-1.621-1.664-4.454-2.756-6.579-1.434
	C493.816,546.852,493.124,546.459,492.442,546.067 M564.096,546.493c0.051,1.869,3.661,1.51,3.456-0.503
	C566.349,545.862,565.197,546.033,564.096,546.493 M2193.434,547.748c1.886,0.205,3.755-0.333,5.197-1.63
	C2196.762,546.246,2194.756,546.271,2193.434,547.748 M2291.968,546.152c0.034,0.495,0.085,1.468,0.12,1.963
	c0.555-0.009,1.16,0,1.801,0.017c0.043-0.495,0.119-1.476,0.154-1.971C2293.521,546.161,2292.489,546.152,2291.968,546.152
	 M158.737,547.287c-0.981,1.289,1.323,2.534,2.125,1.289C161.784,547.27,159.539,546.067,158.737,547.287 M378.283,546.63
	c-0.538,1.109-1.067,2.236-1.604,3.354c-2.526,1.032-4.489,3.371-7.322,3.524c0.29,0.58,0.589,1.169,0.887,1.766
	c0.384-0.265,1.143-0.802,1.519-1.075c0.666,0.162,1.988,0.478,2.654,0.632c0.401-0.606,0.802-1.203,1.22-1.792
	c0.811,1.835,2.458,1.869,3.908,0.708c-0.009-0.631-0.017-1.877-0.026-2.509c-0.461,0.06-1.374,0.179-1.835,0.239
	C378.206,549.881,380.612,547.782,378.283,546.63 M385.826,547.662c-0.06,0.41-0.188,1.237-0.256,1.647
	c0.614,0.06,1.835,0.171,2.449,0.23c-0.111-0.478-0.333-1.442-0.444-1.92C387.14,547.628,386.261,547.654,385.826,547.662
	 M389.828,546.715c-1.033,2.185,1.067,3.49,2.5,4.676C392.696,549.284,391.765,547.569,389.828,546.715 M2468.429,549.48
	c1.783-0.35,3.542-0.776,5.35-1.186c-0.836-0.623-1.672-1.536-2.773-1.365C2469.879,547.483,2469.222,548.593,2468.429,549.48
	 M2270.95,548.14c-0.956,1.135,1.092,2.381,1.86,1.22C2273.672,548.183,2271.676,546.971,2270.95,548.14 M2274.475,558.141
	c2.679-0.017,5.333-0.563,7.987-1.015c1.459,0.154,2.824,0.811,4.309,1.075c-0.342-1.783-0.87-3.584-0.282-5.367
	c-0.597-0.589-1.195-1.135-1.792-1.638c-1.579,0.538-3.217,2.193-4.873,1.084c0.589-1.519,0.99-3.115,1.05-4.753
	C2278.187,550.683,2276.463,554.54,2274.475,558.141 M2429.303,547.697c0.205,1.562-0.495,3.755,1.263,4.676
	C2431.326,550.837,2431.752,547.799,2429.303,547.697 M539.196,548.977c-0.777,1.169,1.05,2.953,2.202,2.099
	C542.037,549.932,540.262,548.422,539.196,548.977 M2228.889,549.847c0.068,0.444,0.205,1.331,0.282,1.766
	c1.758-0.734,4.437-0.606,4.966-2.918C2232.345,549.002,2230.613,549.386,2228.889,549.847 M2264.755,548.729
	c-0.068,0.631-0.213,1.894-0.29,2.526c0.521-0.162,1.562-0.503,2.082-0.674c0-0.41,0-1.229-0.009-1.638
	C2266.095,548.891,2265.208,548.78,2264.755,548.729 M2303.164,549.122c-2.21,1.579-5.094,2.398-6.588,4.787
	c0.632-0.162,1.886-0.486,2.517-0.649c1.971,0.085,3.575-0.913,4.77-2.449c0.486,0.862,1.254,1.365,2.304,1.493
	c0.06-0.589,0.171-1.766,0.23-2.355C2305.271,549.693,2304.205,549.412,2303.164,549.122 M2308.361,548.925
	c-1.545,1.109,0.35,3.166,1.562,1.681C2311.398,549.557,2309.512,547.526,2308.361,548.925 M2346.812,549.062
	c-1.058,1.084,0.487,1.715,1.297,2.278c1.212-0.128,1.374-1.707,2.005-2.543C2348.945,548.686,2347.844,548.772,2346.812,549.062
	 M2462.097,553.038c1.425-1.092,1.895-2.876,2.355-4.531C2461.807,548.336,2462.498,551.323,2462.097,553.038 M118.613,551.118
	c1.707,0.606,3.866,0.879,4.181-1.442C121.31,549.855,119.381,549.429,118.613,551.118 M543.411,553.09
	c2.108,0.119,2.159-2.287,2.705-3.721C543.607,548.746,543.838,551.477,543.411,553.09 M546.859,549.668
	c-0.435,1.374,0.043,1.843,1.442,1.408C548.727,549.685,548.241,549.215,546.859,549.668 M2195.482,549.489
	c-0.316,1.246-0.154,2.517,0.136,3.763c0.691-0.154,1.408-0.299,2.159-0.435c0.58-0.717,1.229-1.417,1.869-2.108
	C2198.229,550.282,2196.873,549.804,2195.482,549.489 M2424.277,549.676c-0.921,1.365-1.544,2.807-0.128,4.07
	c0.435-0.538,1.314-1.621,1.758-2.159C2425.327,550.939,2424.798,550.308,2424.277,549.676 M533.052,550.683
	c0,0.751-0.922,1.818-0.043,2.355c0.839,1.082,5.875-2.153,3.465-2.603c-0.478,0.179-1.434,0.546-1.911,0.725
	C534.187,551.042,533.427,550.803,533.052,550.683 M2268.988,551.17c-1.758,1.434-1.007,3.576-0.145,5.257
	c-1.468,0.034-2.91,0.051-4.318,0.06c-0.239,0.99-0.444,1.997-0.537,3.021c2.389,0.981,2.85-3.208,5.504-2.654
	c1.468-1.143,2.552-2.662,3.302-4.369C2271.625,551.852,2270.199,549.284,2268.988,551.17 M72.806,551.596
	c-1.015,1.143,1.058,2.287,1.946,1.493C75.964,551.852,73.754,550.18,72.806,551.596 M387.302,553.525
	c0.981-0.119,1.963-0.23,2.953-0.341c0.077-0.486,0.239-1.451,0.316-1.937C388.838,551.118,387.396,551.545,387.302,553.525
	 M528.495,551.204c-0.12,1.195-0.213,2.398-0.273,3.618C530.278,554.353,530.577,551.861,528.495,551.204 M679.424,553.405
	c0.051,0.922,0.119,1.852,0.162,2.79c1.126-0.009,2.27-0.009,3.422-0.009c-0.111-1.749-0.239-3.541-1.391-4.958
	C680.883,551.955,680.158,552.689,679.424,553.405 M857.583,551.468c-1.229,0.819,0.094,3.081,1.365,2.125
	C860.049,552.731,858.863,550.461,857.583,551.468 M2467.857,551.255c-1.399,0.23-1.109,2.441,0.358,1.57
	C2469.538,552.552,2469.299,550.376,2467.857,551.255 M775.006,557.373c1.775,0.06,3.063-1.075,4.352-2.116
	c-0.418,0.614-1.246,1.852-1.664,2.466c2.577,0.137,4.676,1.638,6.647,3.166c-0.068-0.478-0.205-1.434-0.273-1.911
	c0.503-0.026,1.502-0.094,2.005-0.128c-2.628-2.321-6.477-3.627-7.697-7.202C776.508,553.03,775.851,555.325,775.006,557.373
	 M2307.661,554.412c2.321,0.188,5.717,0.862,6.494-2.159C2311.833,552.287,2309.034,552.168,2307.661,554.412 M2417.502,552.177
	c-1.007,1.254,1.297,2.381,2.227,1.442C2420.787,552.33,2418.347,551.187,2417.502,552.177 M2445.798,552.236
	c-1.084,0.205-1.997,2.185-0.503,2.492C2447.095,555.138,2448.188,551.434,2445.798,552.236 M390.374,557.331
	c2.287,0.119,3.917-2.082,4.275-4.105C391.91,552.185,391.211,555.487,390.374,557.331 M396.757,552.927
	c-1.109,0.333-1.655,2.381-0.247,2.697C398.242,555.871,398.908,552.219,396.757,552.927 M493.688,554.847
	c1.237,1.28,2.987-0.503,1.604-1.664C494.123,551.835,491.819,553.909,493.688,554.847 M544.725,554.31
	c1.237,0.299,2.509,0.614,3.763,0.93c-0.026-0.555-0.068-1.647-0.085-2.202C547.072,553.218,545.237,552.629,544.725,554.31
	 M553.796,552.697c-0.034,0.7-0.102,2.082-0.145,2.773c1.331,0.597,2.773,1.246,3.695-0.316
	C556.339,554.079,555.255,553.064,553.796,552.697 M674.039,553.107c-1.254,0.043-1.749,2.347-0.222,2.372
	C675.064,555.479,675.456,553.115,674.039,553.107 M688.785,552.194c0.29,1.263,0.589,2.543,0.905,3.823
	c0.922-0.077,1.86-0.154,2.807-0.213c0.051-0.435,0.162-1.323,0.222-1.758C691.405,553.422,690.091,552.774,688.785,552.194
	 M857.489,555.052c-0.034,0.555-0.111,1.664-0.154,2.219c0.981-0.529,1.98-1.041,2.995-1.527c0.486-1.041,0.947-2.091,1.425-3.132
	C860.459,553.644,859.273,554.975,857.489,555.052 M2176.64,555.76c1.596,0.333,2.073-2.176,0.726-2.748
	C2175.889,552.671,2174.976,555.419,2176.64,555.76 M2184.38,553.218c-0.273,1.186,0.205,1.638,1.417,1.365
	C2186.001,553.397,2185.523,552.945,2184.38,553.218 M2187.093,553.183c-1.015,1.331,1.399,2.304,2.219,1.212
	C2190.344,553.03,2187.844,552.049,2187.093,553.183 M2263.066,553.414c0.085,1.109,1.152,1.647,1.928,2.338
	c0.06-0.759,0.188-2.278,0.256-3.046C2264.704,552.885,2263.612,553.235,2263.066,553.414 M798.874,553.994
	c-1.357,0.444-1.391,3.285,0.435,2.492C800.862,556.145,800.64,553.183,798.874,553.994 M2416.452,555.854
	c0.768,1.869,3.704,0.469,5.308,0.572c-0.051-0.58-0.171-1.732-0.222-2.313C2419.806,554.617,2417.749,554.464,2416.452,555.854
	 M131.96,554.975c0.358,0.742,0.742,1.476,1.152,2.21c1.604,0.034,3.098-0.538,3.285-2.313
	C134.912,554.873,133.436,554.907,131.96,554.975 M847.292,554.882c-1.041,1.289,1.297,2.517,2.167,1.391
	C850.355,555.001,848.128,553.789,847.292,554.882 M2287.445,554.574c-0.341,3.558,5.512,3.038,6.349,0.282
	C2291.661,554.728,2289.544,554.651,2287.445,554.574 M2300.629,554.694c-0.623,2.714-1.459,5.385-1.844,8.149
	c-0.094-0.017-0.281-0.06-0.375-0.077c-0.043-1.169,0.034-2.372-0.393-3.482c0.205-0.631,0.435-1.263,0.708-1.886
	c-0.879-0.469-1.724-0.922-2.551-1.374c-0.282,0.478-0.845,1.425-1.126,1.894c-0.504,0.085-1.51,0.265-2.014,0.358
	c-0.998,1.186-2.142,2.244-3.268,3.285c0.555-0.111,1.673-0.333,2.227-0.435c-0.606,2.167-0.725,5.052-3.14,6.05
	c0.154-1.485,1.118-2.637,1.834-3.874c-2.722-0.828-3.499,2.142-2.176,3.908c-1.809,1.835-3.669,3.746-4.335,6.323
	c0.273,2.21,2.577,2.79,4.506,2.56c-0.742-0.623-1.434-1.254-2.073-1.903c-0.128-2.756,2.534-0.529,3.959-0.247
	c2.279-1.715,5.692-2.065,7.219-4.659c8.734-0.715,5.619,0.477,8.772-3.473c-2.449-1.869-3.635,1.126-4.548,2.901
	c0-0.759,0.009-2.278,0.017-3.038c-1.143,0.87-2.313,1.758-3.703,2.091c0.017-1.237,0.068-2.458,0.154-3.678
	c1.067-0.051,2.159-0.154,3.277-0.29c0.699-1.527,1.331-3.089,2.014-4.625c-0.358-0.205-1.075-0.614-1.434-0.819
	c0.845-1.382,1.775-2.748,2.201-4.344C2303.198,554.207,2301.901,554.438,2300.629,554.694 M2386.722,555.189
	c-0.043,0.777-0.128,2.321-0.171,3.098c0.828-0.307,3.422-0.299,2.372-1.894C2388.275,555.82,2387.541,555.411,2386.722,555.189
	 M2307.422,556.418c-0.06,0.503-0.162,1.51-0.213,2.022c1.604-0.35,3.2,0.068,4.796,0.469c0.103-0.606,0.324-1.809,0.427-2.415
	C2310.775,557.032,2309.06,556.477,2307.422,556.418 M241.442,557.373c-1.229,0.017-1.826,2.33-0.35,2.449
	C242.278,559.763,242.833,557.51,241.442,557.373 M370.278,557.22c-0.247,0.427-0.759,1.289-1.007,1.715
	c0.358,1.527,2.091,1.459,3.115,0.649C373.052,558.141,371.072,557.928,370.278,557.22 M2314.829,557.203
	c0.162,1.126,0.359,2.253,0.589,3.388c0.887-0.546,1.749-1.152,2.313-2.091C2316.732,558.065,2315.767,557.629,2314.829,557.203
	 M380.894,559.421c1.476,0.384,2.97,0.768,4.454,1.143c0.051-0.606,0.145-1.826,0.188-2.432
	C383.992,558.474,381.696,557.57,380.894,559.421 M2269.107,558.278c-0.939,1.169,1.212,2.176,1.997,1.195
	C2272.068,558.261,2269.833,557.288,2269.107,558.278 M419.814,559.191c-1.135,0.828,0.017,3.226,1.323,2.116
	C422.195,560.462,421.094,558.082,419.814,559.191 M780.032,559.831c-0.427,0.614-0.828,1.246-1.203,1.894
	c1.758,0.375,1.877,1.98,2.057,3.482c1.007-0.375,2.398-0.486,2.355-1.903C782.652,561.802,781.304,560.727,780.032,559.831
	 M786.739,559.788c-1.067,1.28,1.237,2.415,2.108,1.357C789.914,559.874,787.567,558.594,786.739,559.788 M846.857,559.643
	c0.572,1.69,2.057,2.773,3.251,4.011c0.444-0.538,0.888-1.075,1.348-1.596C850.031,561.009,848.802,559.507,846.857,559.643
	 M859.315,559.857c-1.724,0.341-0.981,2.884,0.64,2.381C861.662,561.964,860.979,559.106,859.315,559.857 M2209.502,560.258
	c-1.698,0.367-3.226,1.092-4.318,2.449c-1.869,0.956-3.388,2.406-4.889,3.814c-0.478,0.077-1.425,0.23-1.903,0.307
	c-0.887,0.691-1.834,1.289-2.782,1.818c-0.171,0.282-0.512,0.853-0.674,1.143c2.27,0.35,5.197-1.596,6.767,0.811
	c-2.449,0.29-4.958,0.802-6.895,2.406c-0.572,1.092,1.067,1.237,1.673,1.843c6.521-1.689,6.443-1.675,8.098-1.937
	c-1.135,1.067-2.287,2.048-3.465,3.029c1.323,0.034,2.671,0.077,4.045,0.145c-1.007,0.913-2.022,1.792-3.08,2.594
	c0.213,0.964,0.521,1.937,0.819,2.91c-1.834,1.135-4.437,1.724-5.069,4.053c1.331,0.205,2.73,0.452,4.121,0.708
	c-0.29,0.341-0.87,1.024-1.16,1.365c0.487,0.06,1.459,0.171,1.946,0.222c1.015-1.152,2.082-2.287,3.038-3.516
	c-0.649,0.307-1.954,0.93-2.611,1.237c-0.521-0.435-1.007-0.87-1.459-1.306c1.869-1.869,4.164-1.399,6.392-0.572
	c1.595-1.758,3.328-3.482,5.393-4.668c2.193,0.495,3.849-1.801,5.88-1.425c-0.504,2.244-3.43,2.748-5.163,3.84
	c0.307,0.717,0.64,1.442,0.998,2.176c-1.28,0.188-2.534,0.333-3.789,0.427c0.597,0.879,1.22,1.749,1.834,2.68
	c-2.705,0.717-4.301,2.748-5,5.367c-0.743,0.043-2.227,0.137-2.97,0.188c0.853,1.271,2.62,2.406,1.562,4.105
	c-2.108-0.998-3.712,0.009-4.975,1.638c-0.358-0.145-1.058-0.435-1.417-0.58c0.316,0.794,0.657,1.596,1.024,2.406
	c1.988-0.401,4.019-0.93,6.076-0.393c-1.05,1.297-2.313,1.707-3.797,1.237c-1.468,1.323-2.645,2.918-3.464,4.702
	c-1.306,0.657-2.731,1.229-3.482,2.534c1.698-0.307,3.422-0.521,5.197-0.572c0.324-0.41,0.964-1.229,1.289-1.63
	c0.623-0.026,1.869-0.068,2.483-0.094c-0.017-0.666-0.068-1.997-0.094-2.671c2.295,0.674,5.427-0.435,7.194,1.519
	c-1.28,0.521-2.492,1.05-3.72,1.562c-0.35,0.742-0.683,1.493-0.981,2.253c0.316,0.384,0.939,1.161,1.246,1.545
	c-1.237,0.256-2.824,0.034-3.123,1.57c1.246,0.12,2.517,0.239,3.823,0.367c-0.666,0.751-1.263,1.519-1.86,2.295
	c0.503,0.162,1.51,0.486,2.014,0.649c-1.869,1.434-4.471,1.638-5.794,3.78c12.532-0.311,1.468,2.424,14.464-1.033
	c-0.316,0.546-0.956,1.647-1.271,2.193c0.145,0.299,0.427,0.896,0.572,1.195c-2.073,0.452-3.575,1.698-3.891,3.866
	c2.04,0.128,5.257,1.86,5.956-1.092c1.092,0.657,2.185,1.306,3.345,1.929c-0.359-1.246-0.734-2.475-1.067-3.695
	c2.475-0.247,5.248,0.708,7.526-0.649c0.666,0.299,1.357,0.606,2.083,0.93c0.35-0.41,1.05-1.229,1.391-1.638
	c0.777,0.546,1.579,1.084,2.406,1.638c0.188-1.493,0.393-3.089,2.295-3.123c-0.128-2.372-2.534-1.254-3.132,0.196
	c-2.901-0.896-5.666,0.751-8.457,1.229c0.077-3.507,4.318-1.715,5.76-3.96c-1.809-0.367-3.533-0.904-4.975-2.022
	c7.647-1.327,7.273,2.24,17.186-3.183c0.264-0.725,0.555-1.451,0.87-2.167c-3.217,0.375-5.589,2.671-8.499,3.772
	c0.299-1.246,0.717-2.449,1.118-3.661c0.521-0.051,1.562-0.162,2.091-0.213c3.243-2.594,6.46-5.257,9.241-8.371
	c-1.852-0.077-3.977-0.461-4.924,1.519c-3.115,1.28-6.127,2.731-9.02,4.42c-0.094-0.393-0.265-1.178-0.35-1.57
	c8.352-6.36,8.975-9.956,11.708-9.856c-0.239-0.427-0.717-1.289-0.956-1.715c-1.001,0.742-10.449,7.022-12.578,6.511
	c2.807-2.696,6.869-3.78,9.293-6.938c-2.313-0.495-4.634,0.751-5.555,2.901c-0.529-0.742-1.041-1.519-1.485-2.261
	c-0.734,0.162-2.21,0.495-2.944,0.657c0.359-1.263,0.819-2.5,1.263-3.746c-2.159-0.683-4.395-1.067-6.622-1.229
	c0.597-1.374,1.664-2.389,2.91-3.209c1.493,0.512,3.063,0.683,4.685,0.717c-0.64-0.836-1.229-1.638-1.835-2.441
	c-1.391,0.299-2.688,0.606-4.028,0.905c-0.793-0.794-1.561-1.57-2.313-2.347c0.939-0.649,1.852-1.391,2.97-1.801
	c1.485,0.043,2.893,0.657,4.412,0.879c-0.282-0.606-0.845-1.809-1.126-2.415c0.742-0.427,1.51-0.853,2.321-1.263
	c0.017-1.28,0.06-2.551,0.145-3.814c-0.981,0.341-1.937,0.691-2.867,1.05c-2.449-0.813-3.142,0.623-9.045,1.399
	c0.017-0.648,0.043-1.954,0.06-2.603c2.748-1.647,5.709-2.978,8.26-4.966c0.435-1.007-0.776-1.775-1.51-2.074
	c-2.022,0.384-3.951,1.92-6.024,1.033c-0.367,0.188-1.092,0.555-1.459,0.742c-0.478-1.425-0.913-2.85-1.434-4.233
	c-0.956-0.136-1.425,0.393-1.399,1.587c-1.135-1.161-2.022,0.034-2.85,0.785c-0.512,0.034-1.545,0.085-2.057,0.111
	c-0.196-0.435-0.589-1.314-0.785-1.749c-1.595,0.307-3.26,0.768-4.676-0.256c1.28-1.348,2.731-2.543,4.625-2.935
	C2213.803,560.437,2211.439,559.063,2209.502,560.258 M2216.755,561.947c1.869,0.452,3.977-0.29,4.625-2.261
	C2219.546,559.754,2217.779,560.334,2216.755,561.947 M2267.29,559.959c-0.87,1.126,1.084,2.381,1.988,1.468
	C2270.029,560.317,2268.117,559.037,2267.29,559.959 M2368.87,559.993c-0.99,0.811,0.265,2.748,1.399,2.048
	C2371.132,561.179,2370.031,559.114,2368.87,559.993 M2417.05,560.787c0.58,0.836,1.229,1.655,1.928,2.458
	c1.69-0.239,1.63-2.389,1.911-3.661C2419.396,559.421,2418.176,559.976,2417.05,560.787 M2422.519,559.908
	c-0.503,1.399-0.051,1.869,1.365,1.408C2424.337,559.916,2423.876,559.438,2422.519,559.908 M230.281,560.838
	c-1.365,1.442,1.826,2.961,2.389,1.229C233.173,560.693,231.142,559.848,230.281,560.838 M2155.546,560.659
	c-1.05,1.272,1.169,2.56,2.022,1.314C2158.541,560.71,2156.313,559.285,2155.546,560.659 M104.226,561.179
	c-0.111,1.169-0.213,2.347-0.299,3.524c0.802-0.341,1.604-0.674,2.406-1.007C105.643,562.843,104.952,561.998,104.226,561.179
	 M223.616,561.879c-0.077,0.538-0.23,1.621-0.307,2.159c0.538-0.128,1.621-0.384,2.159-0.512c0.102-0.452,0.324-1.34,0.427-1.792
	C225.323,561.768,224.188,561.845,223.616,561.879 M544.128,561.41c0.111,0.674,0.324,2.014,0.427,2.688
	c0.879-0.597,1.672-1.306,2.33-2.133C546.193,561.828,544.819,561.546,544.128,561.41 M2303.966,561.461
	c-1.297,0.307-1.007,2.483,0.418,1.638C2305.647,562.809,2305.331,560.616,2303.966,561.461 M2306.901,561.572
	c-0.196,1.707-0.247,3.439,0,5.163c1.442-0.154,2.91-0.512,4.309-1.032c0.469,0.546,0.965,1.101,1.493,1.664
	c1.741-1.51,3.994-2.219,5.811-3.652c-2.082-1.178-4.557-0.879-6.076,0.973c0.299-0.99,0.606-1.971,0.93-2.953
	c-0.555,0.299-1.664,0.896-2.219,1.186C2311.202,560.164,2308.523,561.58,2306.901,561.572 M2338.278,561.794
	c0.12,1.391,0.256,2.799,0.538,4.181c0.887-0.7,1.809-1.408,2.705-2.15c-0.384-0.563-1.152-1.673-1.536-2.227
	C2339.558,561.649,2338.705,561.742,2338.278,561.794 M2341.657,561.759c-1.007,1.195,1.186,2.185,1.954,1.152
	C2344.55,561.691,2342.366,560.693,2341.657,561.759 M2352.128,563.654c-0.427-0.222-1.263-0.649-1.681-0.862
	c-0.58,1.775,1.971,2.321,2.944,3.456c-1.656,0.273-3.686-0.12-4.685,1.519c0.657,0.375,1.963,1.135,2.62,1.51
	c2.219-1.528,4.762-2.261,7.475-2.33c-0.887-0.811-1.775-1.579-2.671-2.295c-0.376-1.468-1.041-2.833-1.826-4.087
	C2353.51,561.572,2352.836,562.613,2352.128,563.654 M697.95,562.732c-0.964,0.811,0.145,2.884,1.34,2.039
	C700.254,563.961,699.068,561.981,697.95,562.732 M2195.669,562.519c-0.726,0.657-1.365,1.34-2.031,2.022
	c1.084,0.06,2.193,0.119,3.337,0.179c0.23-0.674,0.478-1.348,0.76-2.014C2197.214,562.655,2196.181,562.562,2195.669,562.519
	 M2224.751,563.475c0.213,0.435,0.64,1.306,0.853,1.741c1.178-0.623,1.989-1.638,2.415-2.918
	C2226.893,562.655,2225.801,563.057,2224.751,563.475 M2346.965,561.981c-0.597,1.032-0.922,2.125-1.459,3.166
	c-0.392-0.418-1.177-1.237-1.57-1.656c-1.826,1.297-4.531,2.33-5.214,4.574c1.468,1.886,4.002,0.026,6.008,0.23
	c2.176,0.094,2.901-2.415,4.173-3.763C2348.339,563.577,2347.665,562.775,2346.965,561.981 M247.33,566.837
	c0.41-0.213,1.237-0.649,1.647-0.862c-2.185-1.109-4.437-2.091-6.451-3.507C241.664,565.523,245.547,565.497,247.33,566.837
	 M538.078,564.593c0.776,1.203,1.067,2.603,1.382,4.002c1.152-0.725,2.125-1.681,3.166-2.552c1.86-0.051-0.196,1.954-0.23,2.876
	c1.937-0.375,3.891-0.742,5.803-1.288c0.265,0.324,0.794,0.964,1.058,1.28c2.168-0.188,0.111-2.842,0.154-4.104
	c-2.116,0.964-4.378,1.314-6.084-0.606c-0.981,1.024-2.133,1.792-3.61,1.749c-0.06-0.7-0.179-2.108-0.247-2.807
	C539,563.611,538.53,564.098,538.078,564.593 M562.287,563.355c-1.135,0.819-0.026,3.174,1.297,2.167
	C564.762,564.712,563.576,562.382,562.287,563.355 M665.549,563.5c-1.05,1.365,1.459,2.313,2.253,1.212
	C668.706,563.381,666.308,562.374,665.549,563.5 M2229.734,563.398c0.043,0.606,0.136,1.8,0.188,2.406
	c1.05-0.623,2.082-1.331,2.517-2.56C2231.765,563.287,2230.408,563.364,2229.734,563.398 M2359.083,564.174
	c0.128,1.502,0.427,2.995,0.913,4.446c1.331-0.341,2.619-0.913,3.754-1.775c0.444,2.261,2.33,2.517,4.335,2.355
	c-0.452-1.86-2.287-2.722-3.371-4.113c-0.461-0.077-1.365-0.222-1.826-0.299c-0.273-0.452-0.828-1.357-1.109-1.818
	C2360.857,563.364,2359.953,563.765,2359.083,564.174 M938.658,566.837c0.58,0.094,1.749,0.265,2.338,0.358
	c-0.29-1.229-0.649-2.44-1.058-3.618C939.059,564.482,938.436,565.531,938.658,566.837 M2370.082,567.596
	c1.459-0.427,2.944-0.896,4.42-1.399c-0.563-0.717-0.504-2.313-1.647-2.21C2371.413,564.686,2370.372,566.018,2370.082,567.596
	 M2485.717,564.183c-1.067,0.828-0.009,3.226,1.331,2.261C2488.073,565.599,2487.006,563.21,2485.717,564.183 M363.58,565.036
	c0.196,0.734,0.393,1.476,0.606,2.219c0.896,0,1.792-0.009,2.705,0.009C367.096,565.019,365.389,564.738,363.58,565.036
	 M552.508,566.623c0.29,2.074,2.483,0.657,3.755,0.794c-0.418-0.93-0.828-1.86-1.229-2.782
	C553.984,565.011,552.678,565.267,552.508,566.623 M689.425,565.019c-1.041,1.101,0.777,2.714,1.86,1.86
	C692.292,565.838,690.492,563.995,689.425,565.019 M696.286,565.215c-1.604,0.418-0.862,2.944,0.759,2.338
	C698.982,567.281,697.882,564.576,696.286,565.215 M855.535,566.299c1.015,0.666,2.057,1.297,3.132,1.92
	c0.128-1.178,0.256-2.347,0.393-3.507C857.873,565.215,856.687,565.753,855.535,566.299 M2161.271,564.925
	c-0.358,0.819-0.7,1.638-1.007,2.475c1.92-0.495,5.034,0.521,5.461-2.261C2164.215,565.045,2162.722,564.985,2161.271,564.925
	 M684.945,566.077c0.597,1.28,0.981,2.833,2.424,3.465c0.162-1.246,0.375-2.449,0.427-3.695
	C687.078,565.907,685.653,566.018,684.945,566.077 M2190.336,566.077c0.051,0.495,0.154,1.485,0.213,1.98
	c0.64,0.913,2.91,0.324,2.099-0.964C2192.026,566.154,2191.249,565.821,2190.336,566.077 M2279.322,567.144
	c-0.708,0.7-1.382,1.408-2.022,2.133c0.324,0.785,0.674,1.579,1.05,2.381c-0.58,0.077-1.724,0.239-2.295,0.316
	c1.835,0.461,3.9,1.664,5.811,0.845c0.017-1.963-2.219-2.927-2.082-4.907c0.563,0.162,1.681,0.486,2.244,0.657
	c-0.478,1.826,0.273,2.671,2.219,2.44c-0.051-1.306-0.068-2.594-0.06-3.883c-1.664,1.562-2.671-0.58-3.695-1.434
	C2279.945,566.052,2279.561,566.53,2279.322,567.144 M239.061,566.939c-0.751,0.683-0.896,2.637,0.375,2.825
	C241.28,569.585,241.033,565.813,239.061,566.939 M558.157,566.973c-0.99,0.853,0.358,2.739,1.476,2.022
	C560.504,568.117,559.266,566.171,558.157,566.973 M560.666,566.564c-1.186,1.084,0.64,3.251,1.741,1.894
	C563.49,567.332,561.877,565.13,560.666,566.564 M2274.987,566.82c-1.425,1.178,0.427,3.319,1.638,1.69
	C2278.059,567.468,2276.079,565.565,2274.987,566.82 M495.778,568.518c-1.186,0.853,0.094,3.072,1.357,1.997
	C498.424,569.678,497.024,567.417,495.778,568.518 M839.757,569.934c0.666,0.836,2.654,0.009,1.826-1.067
	C840.917,567.972,838.989,568.876,839.757,569.934 M2167.629,568.774c-0.068,0.452-0.196,1.357-0.265,1.809
	c0.538-0.043,1.613-0.145,2.151-0.196c0.017-0.41,0.042-1.237,0.051-1.655C2169.088,568.748,2168.115,568.765,2167.629,568.774
	 M2307.857,570.165c0.862,0.905,1.399,1.988,1.613,3.26c0.427-0.171,1.28-0.521,1.707-0.7c-0.29,0.819-0.546,1.638-0.76,2.475
	c-0.572,0.102-1.707,0.316-2.278,0.418c0.913,2.483,2.739,1.289,4.377,0.427c0.316,0.154,0.939,0.469,1.246,0.631
	c0.342-1.562,0.768-3.089,1.22-4.617c0.606-0.674,1.229-1.34,1.877-2.014c-0.887-0.299-1.732-0.589-2.56-0.879
	c-1.237,1.067-2.441,2.21-3.465,3.465c-0.188-1.348-0.341-2.688-0.478-4.019C2309.419,569.03,2308.565,569.482,2307.857,570.165
	 M541.082,570.259c-0.06,0.503-0.171,1.493-0.222,1.988c1.186,0.461,2.364,0.93,3.575,1.382c-0.384-1.178-0.759-2.313-1.161-3.465
	C542.729,570.19,541.628,570.242,541.082,570.259 M2306.969,570.335c-3.448,1.374-8.764,2.423-9.327,6.784
	c1.331-0.478,2.449-1.348,3.208-2.577c1.203-0.504,2.432-0.99,3.712-1.417c-0.009-0.418-0.017-1.263-0.026-1.69
	c1.203,0.623,2.074,1.835,2.586,3.106c-0.879-0.06-2.628-0.179-3.507-0.239c-0.725,2.816,2.355,4.412,4.122,6.025
	c0.418-0.435,1.263-1.314,1.681-1.749c-1.016-1.186-2.381-1.903-3.789-2.398c-0.017-0.247-0.034-0.734-0.051-0.973
	c0.401,0.324,1.203,0.99,1.604,1.314C2308.506,574.483,2309.137,572.025,2306.969,570.335 M535.799,570.933
	c0.017,0.512,0.051,1.527,0.068,2.039c0.486,0.06,1.476,0.196,1.963,0.265c-0.051-0.751-0.094-1.502-0.119-2.236
	C537.233,570.984,536.277,570.95,535.799,570.933 M766.276,572.162c-0.964,0.828,0.213,2.884,1.391,1.997
	C768.58,573.331,767.377,571.368,766.276,572.162 M776.499,572.324c0.085,0.299,0.273,0.879,0.367,1.178
	c1.434,0.401,2.867,0.862,4.275,1.399c0.247-0.683,0.503-1.357,0.777-2.022c-0.316-0.341-0.939-1.007-1.254-1.34
	C779.273,571.812,777.882,572.076,776.499,572.324 M841.25,572.025c-1.024,1.348,2.031,2.765,2.577,1.297
	C843.767,572.162,842.189,571.334,841.25,572.025 M2188.092,575.618c2.304,0.836,2.082-2.654,2.125-4.062
	C2188.936,572.477,2186.999,573.791,2188.092,575.618 M697.489,572.776c-0.981,1.067,0.504,3.311,1.818,2.167
	C700.484,573.843,698.718,571.837,697.489,572.776 M2156.979,573.749c-0.17,2.364,2.449,3.14,4.139,4.062
	c-0.179-1.963-1.22-4.224,1.075-5.325C2160.35,572.52,2158.541,572.785,2156.979,573.749 M2317.218,573.245
	c-0.554,1.058-1.229,1.997-2.347,2.441c0.119,0.452,0.358,1.357,0.478,1.8c1.306-0.444,2.603-0.947,3.9-1.536
	c-0.239-0.998-0.444-1.988-0.606-2.97C2318.285,573.049,2317.568,573.177,2317.218,573.245 M783.855,575.874
	c0.691-0.017,2.065-0.051,2.756-0.06c-0.128-0.555-0.375-1.647-0.503-2.193C784.452,572.759,784.179,574.747,783.855,575.874
	 M159.923,576.044c0.29,1.254,2.202,1.911,3.311,1.425C163.516,576.061,160.589,574.423,159.923,576.044 M212.881,575.046
	c-0.162,2.833,3.166,2.082,4.855,1.562C216.781,574.764,214.596,575.191,212.881,575.046 M700.399,576.334
	c-0.725,0.853,0.572,2.381,1.553,1.912C702.652,577.367,701.38,575.839,700.399,576.334 M801.792,577.734
	c0.128,2.21,2.654,0.888,4.062,1.084c0.043-0.41,0.128-1.229,0.171-1.638c-0.828-0.444-1.638-0.879-2.432-1.323
	C802.961,576.454,802.355,577.085,801.792,577.734 M2282.47,576.309c-1.109,1.306,0.939,3.285,2.185,2.005
	C2285.508,577.162,2283.469,575.652,2282.47,576.309 M536.789,577.077c-0.99,1.229,1.246,2.193,1.929,1.041
	C539.759,576.872,537.464,576.019,536.789,577.077 M2165.359,579.261c0.674-0.094,2.022-0.282,2.705-0.375
	c0.367,0.35,1.101,1.033,1.476,1.382c0.717-1.05,2.185-1.877,1.835-3.345c-0.726,0.137-1.408,0.29-2.065,0.469
	c-0.58-0.222-1.749-0.666-2.33-0.888C2165.657,576.77,2165.709,578.271,2165.359,579.261 M376.969,577.563
	c0.026,0.623,0.068,1.886,0.094,2.509c0.503-0.111,1.502-0.35,2.005-0.461c0.077-0.435,0.222-1.306,0.299-1.732
	C378.769,577.794,377.566,577.64,376.969,577.563 M541.824,579.389c-0.119,0.58-0.358,1.749-0.478,2.33
	c1.758-0.145,2.236,1.186,2.551,2.628c0.683-0.265,1.374-0.521,2.074-0.768c-0.256-1.007-0.495-1.997-0.734-2.987
	C546.237,579.977,548.482,576.34,541.824,579.389 M697.873,577.93c-0.947,1.101,0.913,2.509,1.903,1.672
	C700.518,578.621,698.778,577.23,697.873,577.93 M2272.316,578.263c-1.212,1.323,1.109,2.987,2.227,1.724
	C2275.567,578.724,2273.271,577.452,2272.316,578.263 M2199.1,578.826c-1.775,0.111-1.357,3.004,0.401,2.295
	C2201.19,580.814,2200.943,578.126,2199.1,578.826 M393.08,581.173c0.051,1.365,1.391,1.357,2.389,1.63
	c0.119-1.246,0.23-2.475,0.324-3.703C394.812,579.543,392.96,579.645,393.08,581.173 M781.235,583.101
	c1.963-0.179,2.662-2.082,3.465-3.567C783.113,580.26,781.065,580.908,781.235,583.101 M164.019,580.507
	c-1.092,1.374,2.005,3.123,2.415,1.28C166.827,580.413,164.907,579.381,164.019,580.507 M937.728,580.302
	c-1.28,0.068-1.502,2.347-0.145,2.475C938.837,582.7,939.085,580.388,937.728,580.302 M350.353,582.547
	c1.929,0.828,3.874,1.638,5.734,2.628c0.563-0.102,1.69-0.307,2.244-0.418c0.708,1.237,1.348,2.517,1.86,3.857
	c1.041,0.401,2.074,0.828,3.157,1.135c2.253-1.05,4.489-2.705,4.625-5.419c-0.828,0.478-1.655,0.939-2.5,1.382
	c-0.777-1.818-2.372-1.161-3.601-0.427c-0.742-0.239-1.485-0.478-2.219-0.717c0.666-0.836,1.348-1.664,2.039-2.475
	c-1.98,0.7-4.036,1.178-6.093,1.596c0.239-0.939,0.478-1.869,0.725-2.799C354.253,581.087,352.094,581.275,350.353,582.547
	 M2162.261,583.101l-0.162-1.348c-3.584,0.367-7.211-0.35-10.752,0.265c-0.128,0.35-0.375,1.05-0.495,1.408
	c1.988,0.546,4.036,0.887,6.144,0.905c0.333,0.324,1.007,0.973,1.34,1.297c0.887-0.29,1.963-0.444,2.944-0.367
	c0.341-0.171,1.015-0.512,1.348-0.683c0.264,0.998,2.014,2.492,2.705,0.862c-0.427-0.41-1.271-1.22-1.689-1.63
	c1.587-0.922,3.609,0.674,4.77-1.374c-1.331-0.41-2.585-0.776-3.857-1.169C2163.772,581.873,2162.987,582.478,2162.261,583.101
	 M384.316,583.153c0.759,0.811,1.596,1.553,2.492,2.219c0.094-1.152,0.188-2.295,0.265-3.439
	C386.15,582.325,385.229,582.734,384.316,583.153 M2092.39,582.18c-1.493,1.212,0.393,3.166,1.613,1.604
	C2095.607,582.769,2093.466,580.849,2092.39,582.18 M759.185,583.886c-0.051,1.502,1.724,1.732,2.671,2.526
	c-0.06-0.452-0.188-1.348-0.247-1.792c0.512-0.102,1.519-0.299,2.031-0.401C762.419,582.504,760.713,582.683,759.185,583.886
	 M785.946,583.067c0.043,0.555,0.136,1.673,0.179,2.236c1.109-0.512,2.116-1.203,2.398-2.475
	C787.883,582.888,786.594,583.007,785.946,583.067 M16.512,583.852c1.263,1.621,2.893,4.386,5.308,2.867
	c-0.41-1.476-2.517-1.408-2.893-2.987C18.321,583.758,17.109,583.818,16.512,583.852 M377.165,584.689
	c0.265,0.811,0.546,1.613,0.853,2.415c0.546-0.043,1.638-0.128,2.176-0.171c0.034-1.161,0.06-2.313,0.085-3.456
	C379.23,583.852,378.18,584.228,377.165,584.689 M395.921,584.851c0.051,0.623,0.145,1.877,0.188,2.5
	c1.818-0.495,3.243-1.894,3.029-3.908C398.08,583.921,397.261,585.67,395.921,584.851 M767.983,583.733
	c-0.401,0.7-0.777,1.425-1.118,2.159c0.7,0.017,2.082,0.06,2.773,0.077C769.306,585.021,769.331,583.665,767.983,583.733
	 M772.932,585.653c0.58,0.026,1.749,0.06,2.33,0.077c0.043-0.597,0.137-1.783,0.179-2.381
	C774.229,583.707,773.214,584.364,772.932,585.653 M235.085,586.856c1.647,0.068,3.294,0.111,4.949,0.137
	c0.026-0.597,0.068-1.792,0.085-2.389c-0.341,0.213-1.041,0.649-1.382,0.862c-0.461-0.265-1.382-0.802-1.843-1.075
	C236.203,585.141,235.554,585.934,235.085,586.856 M368.495,587.283c0.375,0.316,1.126,0.964,1.502,1.28l-1.877,0.666
	c0.785,0.759,1.775,1.203,2.807,1.553c-0.171-0.444-0.512-1.348-0.683-1.792c2.065-0.683,1.365-2.748,1.152-4.361
	C369.903,584.578,369.442,586.421,368.495,587.283 M694.921,584.526c0.444,1.126,1.195,2.108,1.963,3.046
	c-1.237,0.725-2.449,1.502-3.644,2.287c0.017-0.393,0.043-1.178,0.06-1.57c-1.357,0.179-3.763-0.597-2.978,1.766
	c-0.648,0.461-1.289,0.922-1.92,1.391c0.273,0.35,0.828,1.041,1.101,1.382c0.785-0.478,1.587-0.947,2.406-1.408
	c0.811,0.256,1.638,0.495,2.492,0.708c2.193-1.954,3.9-4.395,5.598-6.775C698.317,584.97,696.627,584.697,694.921,584.526
	 M849.673,584.347c0.136,0.683,0.41,2.039,0.546,2.722c0.521-0.393,1.041-0.802,1.544-1.22
	C852.506,584.45,850.347,584.782,849.673,584.347 M863.65,584.74c-1.493,0.068-1.621,2.628,0.136,1.997
	C865.647,586.83,865.562,584.117,863.65,584.74 M178.424,585.678c-1.417,1.161,0.478,2.799,1.63,1.57
	C181.888,586.19,179.558,584.142,178.424,585.678 M2165.419,587.854c-0.103,2.782,3.336,1.562,5.103,1.749
	c0.034-0.597,0.094-1.784,0.128-2.381C2168.815,586.446,2166.835,586.233,2165.419,587.854 M158.788,587.223
	c-1.024,1.289,1.28,2.432,2.116,1.323C161.937,587.24,159.608,586.003,158.788,587.223 M553.771,587.172
	c-1.058,1.109,0.836,3.012,1.937,1.911C556.971,587.923,554.931,585.977,553.771,587.172 M222.37,590.986
	c2.987,2.705,7.415,2.227,10.803,0.503c-0.478-1.237-0.939-2.466-1.399-3.695C229.359,590.397,225.442,589.629,222.37,590.986
	 M378.684,590.833c0.316,0.597,0.64,1.203,0.964,1.818c0.947,0.58,1.877,1.186,2.807,1.818c0.316-0.077,0.956-0.23,1.271-0.307
	c1.092,0.179,2.185,0.358,3.294,0.538c0.06-0.768,0.171-2.304,0.23-3.063c-1.357,0.222-2.739,0.384-3.985,1.007
	c0.282-1.792-0.683-3.388-1.22-5.009C380.894,588.665,379.793,589.749,378.684,590.833 M2234.385,590.577
	c2.21,0.119,4.309-0.529,5.419-2.586C2237.764,588.008,2234.675,587.897,2234.385,590.577 M2448.503,590.628
	c1.775-0.154,4.147-0.666,3.959-2.995C2450.645,587.88,2449.45,589.211,2448.503,590.628 M2457.148,591.199
	c2.713-0.384,7.62-0.154,8.124-3.507C2462.404,588.443,2459.477,589.331,2457.148,591.199 M2470.528,588.042
	c-0.461,1.399,0.009,1.886,1.383,1.476C2472.32,588.085,2471.851,587.598,2470.528,588.042 M16.435,592.778
	c1.954,0.708,3.985,1.118,6.067,1.161c0.99-0.879,3.379-1.638,1.442-3.046c0.111-0.35,0.341-1.032,0.452-1.382
	C21.709,590.21,17.749,589.8,16.435,592.778 M859.716,589.484c0.888,1.459,1.92,2.97,3.874,2.927
	C863.522,590.056,861.662,589.561,859.716,589.484 M2347.324,592.155c0.384,0.794,0.802,1.604,1.237,2.423
	c0.981-0.623,2.031-1.22,2.679-2.261C2349.909,592.241,2348.604,592.181,2347.324,592.155 M2373.922,592.351
	c-1.246,0.102-1.024,2.074,0.111,2.193C2375.245,594.493,2375.049,592.36,2373.922,592.351 M552.798,595.022
	c0.606,0.649,1.22,1.297,1.843,1.963c0.461-0.026,1.382-0.068,1.843-0.094c-0.213-1.374-0.41-2.748-0.597-4.104
	C554.829,593.495,553.805,594.246,552.798,595.022 M692.77,595.15c0.068,0.512,0.222,1.527,0.29,2.039
	c2.116-0.503,4.523-1.502,3.891-4.181C695.637,593.87,694.255,594.647,692.77,595.15 M2202.283,595.005
	c0.708,0.597,2.62-0.026,2.219-1.135C2203.733,593.137,2201.745,593.879,2202.283,595.005 M2298.428,596.055
	c2.014,0.137,5.205,2.142,5.675-1.075C2302.114,594.348,2299.443,593.572,2298.428,596.055 M2447.59,597.147
	c0.623,0.026,1.86,0.068,2.483,0.094c1.553-1.587,3.849-1.519,5.905-1.843C2453.615,592.155,2449.34,594.519,2447.59,597.147
	 M758.443,594.971c-1.502,0.273-0.444,2.219,0.742,1.69C760.61,596.371,759.518,594.519,758.443,594.971 M785.664,594.775
	c0.034,0.922,0.085,1.843,0.145,2.782c0.956-0.06,1.92-0.094,2.901-0.111c0.137-0.887,0.273-1.775,0.427-2.645
	C787.968,594.784,786.816,594.775,785.664,594.775 M245.7,595.833c-0.162,0.631-0.316,1.271-0.469,1.92
	c1.041,0.947,2.347,1.408,3.755,1.468c-0.393-1.22-0.811-2.449-1.212-3.652C247.253,595.637,246.221,595.765,245.7,595.833
	 M249.182,595.867c-1.041,1.34,1.408,2.287,2.202,1.203C252.416,595.705,249.95,594.698,249.182,595.867 M403.593,598.794
	c0.896-0.239,1.809-0.444,2.722-0.657c0.119-0.956,0.239-1.929,0.375-2.867C404.779,595.534,404.19,597.224,403.593,598.794
	 M864.29,595.79c0.043,0.631,0.137,1.894,0.188,2.517c1.126-0.606,2.313-1.246,2.551-2.645
	C866.347,595.697,864.973,595.756,864.29,595.79 M2190.635,595.825c-1.544,0.503-3.319,3.669-0.503,3.072
	c1.963-0.785,5.368-0.759,5.521-3.499c-1.007,0.307-1.989,0.631-2.936,0.981C2192,596.191,2191.3,596.004,2190.635,595.825
	 M2343.159,595.91c-0.521,1.391-0.068,1.826,1.365,1.314C2344.986,595.825,2344.533,595.389,2343.159,595.91 M369.51,596.191
	c-0.119,0.717-0.35,2.142-0.461,2.85c1.254-0.06,2.526-0.12,3.789-0.179C371.866,597.796,370.808,596.814,369.51,596.191
	 M376.405,596.558c-1.468,0.316-1.63,3.234,0.273,2.432C378.266,598.743,378.24,595.833,376.405,596.558 M554.59,598.342
	c-1.067,0.828-0.026,3.268,1.314,2.227C556.971,599.741,555.87,597.403,554.59,598.342 M2276.173,600.492
	c0.7,0.034,2.108,0.111,2.808,0.154c-0.077-0.648-0.23-1.929-0.307-2.577C2277.751,598.794,2276.898,599.596,2276.173,600.492
	 M2315.793,600.066c0.017,0.666,0.034,1.98,0.051,2.637c0.785-0.589,1.596-1.169,2.432-1.749c0.504,0.597,1.05,1.203,1.638,1.818
	c-0.222,0.657-0.666,1.963-0.879,2.611c3.592-0.572,2.594-4.804,2.466-7.364C2319.94,599.536,2318.003,600.501,2315.793,600.066
	 M225.852,603.53c-2.406-2.167-6.101-1.545-7.876,1.075c11.242,1.39,7.355-0.899,11.153-1.613c-0.128-0.299-0.375-0.913-0.503-1.212
	c1.382,0.461,3.183,0.239,4.113,1.579c-0.469,0.367-1.408,1.101-1.877,1.468c1.297,0.393,2.586,0.811,3.9,1.178
	c-0.307-0.973-0.836-1.792-1.579-2.458c0.589-0.887,1.067-1.818,1.451-2.799c-0.956,0.247-1.911,0.495-2.867,0.725
	c-1.186-0.768-2.176-1.766-3.234-2.679C227.456,600.262,226.099,601.636,225.852,603.53 M242.918,599.187
	c0.674,1.604,1.314,3.439,3.379,3.533c0.026-0.725,0.077-2.176,0.111-2.901C245.24,599.596,244.079,599.383,242.918,599.187
	 M405.171,600.731c1.34,0.759,2.688,1.545,4.028,2.338c0.026-1.118,0.051-2.236,0.077-3.337
	C407.825,599.665,406.349,599.784,405.171,600.731 M2465.348,598.999c-1.024,0.23-2.091,2.338-0.538,2.552
	C2466.534,601.781,2467.789,598.222,2465.348,598.999 M2157.448,601.439c-1.604-0.751-3.43-1.818-4.104,0.503
	c1.587,0.683,3.132,1.502,4.761,2.167c1.109-0.589,2.108-1.365,3.226-2.014c-0.384,1.016-0.751,2.031-1.101,3.046
	c0.708-0.307,1.442-0.614,2.21-0.905c1.707,1.596,2.807-1.775,4.616-1.92c0.281-0.452,0.845-1.357,1.126-1.809
	c-3.009,1.065-2.046,1.019-6.059,1.698c-0.64-0.759-1.152-1.579-1.715-2.321C2159.368,600.339,2158.396,600.893,2157.448,601.439
	 M2086.963,600.885c0.077,0.546,0.23,1.647,0.307,2.193c0.597,0.051,1.784,0.154,2.372,0.213c-0.06-0.529-0.171-1.596-0.23-2.125
	C2088.798,601.098,2087.577,600.962,2086.963,600.885 M236.809,602.455c0.102,0.427,0.316,1.271,0.427,1.698
	c1.946,0.128,3.9-0.043,5.854-0.085c-0.137-0.469-0.401-1.408-0.538-1.869C240.666,602.719,238.729,602.822,236.809,602.455
	 M756.181,601.687C756.395,604.486,758.707,600.313,756.181,601.687 M764.433,601.397c-0.435,1.357,0.043,1.852,1.442,1.485
	C766.302,601.482,765.815,600.987,764.433,601.397 M2269.133,602.66c-0.154,0.393-0.46,1.178-0.606,1.57
	c1.963,0.316,4.139,0.273,5.632-1.28l0.068,2.116c1.152-0.597,2.167-1.434,3.123-2.347
	C2274.628,601.892,2271.846,602.054,2269.133,602.66 M388.113,602.506c-0.316,0.819-0.247,1.707-0.23,2.577
	c0.58-0.077,1.732-0.239,2.313-0.324c0.009-0.546,0.034-1.647,0.043-2.193C389.709,602.549,388.642,602.515,388.113,602.506
	 M539.238,602.643c-1.289,0.478-0.759,2.833,0.631,2.662C541.252,604.878,540.638,602.361,539.238,602.643 M663.808,602.651
	c-1.314,1.203,0.58,3.311,1.783,1.826C666.812,603.428,664.875,601.559,663.808,602.651 M2196.386,603.965
	c-1.459-0.307-2.167,0.87-2.918,1.818c1.169,0.017,2.364,0.034,3.593,0.06c-0.017-0.307-0.051-0.922-0.068-1.237
	c1.126-0.316,1.843-1.067,2.133-2.236C2197.828,602.429,2196.309,602.25,2196.386,603.965 M26.488,603.377
	c-0.427,1.391,2.099,2.807,2.987,1.476C29.679,603.462,27.503,602.6,26.488,603.377 M759.467,603.445
	c-0.939,1.28,1.34,2.475,2.185,1.357C762.607,603.496,760.286,602.318,759.467,603.445 M2085.581,603.641
	c-1.51,0.026-1.51,2.458,0.145,1.809C2087.262,605.407,2087.27,602.975,2085.581,603.641 M378.752,607.123
	c0.478,0.043,1.425,0.119,1.894,0.162c0.521-1.118,1.05-2.236,1.562-3.345C380.373,604.196,378.906,605.169,378.752,607.123
	 M685.944,604.597c0.811,1.263,1.596,3.49,3.439,2.236c-0.384-0.879-0.768-1.749-1.143-2.611
	C687.667,604.315,686.515,604.503,685.944,604.597 M2314.684,606.773c1.562,0.102,2.978-0.393,3.968-1.673
	C2317.15,604.17,2314.624,604.674,2314.684,606.773 M24.781,605.058c-0.708,1.135,1.306,2.244,2.142,1.391
	C27.75,605.245,25.626,604.213,24.781,605.058 M213.47,605.407c-1.024,1.894,2.85,1.929,2.91,0.188
	C216.158,604.221,214.127,604.588,213.47,605.407 M174.379,606.116c-0.111,0.572-0.324,1.724-0.435,2.295
	c0.606-0.043,1.801-0.111,2.406-0.154c-0.009-0.589-0.026-1.775-0.034-2.372C175.829,605.945,174.865,606.056,174.379,606.116
	 M694.955,606.696c-0.145,0.563-0.418,1.698-0.555,2.261c0.512,0.205,1.536,0.614,2.048,0.819c-0.427,0.282-1.289,0.845-1.724,1.135
	c-1.707-0.068-1.186-2.603-1.929-3.652c-2.355,2.236,0.845,6.357-2.193,8.09c0.563,0.521,1.135,1.05,1.724,1.587
	c1.741-1.724,3.362-3.618,4.053-6.025c0.887-0.171,1.698-0.546,2.423-1.109C698.539,607.805,696.627,607.131,694.955,606.696
	 M872.9,606.815c-1.203-0.111-2.261,1.963-0.836,2.432C873.523,609.794,874.53,607.106,872.9,606.815 M2277.453,606.926
	c-1.075,1.28,1.212,2.654,2.082,1.382C2280.414,607.054,2278.263,605.783,2277.453,606.926 M526.575,607.788
	c-0.93,1.152,0.836,3.149,2.048,2.116C529.656,608.77,527.718,606.781,526.575,607.788 M2260.523,607.669
	c0,1.075,0.026,2.15,0.085,3.243c2.27,0.469,3.917-1.143,4.224-3.311C2263.356,607.592,2261.931,607.626,2260.523,607.669
	 M2265.6,610.101c0.393,1.348,1.767,2.065,3.132,1.528c2.645-1.05,5.359,0.606,8.03-0.529c0.042-3.004-3.072-2.014-4.625-3.524
	c-1.451,0.077-2.85,0.196-4.233,0.273C2267.119,608.582,2265.677,608.872,2265.6,610.101 M941.935,608.804
	c-0.7,1.118-0.136,2.236,0.401,3.277c0.333-0.836,0.666-1.664,1.007-2.492C942.993,609.393,942.285,609,941.935,608.804
	 M945.51,608.829c-1.024,0.785,0.12,2.867,1.297,1.963C947.755,609.99,946.645,607.925,945.51,608.829 M2314.991,609.393
	c-0.512,1.229-0.819,2.509-0.102,3.763c0.512,0,1.528,0,2.031,0c-1.075,0.529-2.201,0.922-3.311,1.271
	c-0.819-1.067-1.928-1.604-3.319-1.604c-0.76,2.022-1.579,4.028-2.261,6.076c1.203,1.246,2.466-0.546,3.695-0.973
	c1.826,0.981,3.814,0,5.64-0.503c1.741,2.031,4.113,0.307,6.247,0.051c-0.256-1.724-1.579-2.645-2.944-3.447
	c0.487-0.06,1.451-0.179,1.928-0.247c-0.077-0.614-0.239-1.86-0.316-2.483c-0.785-0.759-1.57-1.732-0.674-2.731
	c-1.015-0.034-1.997-0.051-2.952-0.06c-0.333,0.853-0.64,1.724-0.921,2.594c0.017-0.708,0.042-2.142,0.06-2.85
	C2316.834,608.625,2315.895,609.009,2314.991,609.393 M259.311,609.452c-0.495,1.348-0.034,1.826,1.391,1.425
	C261.188,609.503,260.719,609.034,259.311,609.452 M2356.941,611.62c-2.159-2.022-3.959,4.437-4.872,0.196
	c-1.007,0.026-1.971,0.06-2.91,0.111c-0.042,0.674-0.119,2.039-0.162,2.722c1.101-0.256,2.227-0.495,3.396-0.708
	c0.009,0.666,0.026,1.988,0.034,2.654c2.074,0.017,2.023-1.98,3.089-3.089c0.87,1.212,1.356,4.386,3.524,3.132
	c-0.452-1.553-1.818-3.072-0.708-4.642c1.895,0.162,3.763-0.879,3.678-3.029C2360.2,609.631,2358.852,611.389,2356.941,611.62
	 M251.179,609.862c-0.111,2.159,1.783,4.318,3.729,2.406C253.867,611.15,252.817,609.939,251.179,609.862 M256.017,610.041
	c-1.408,0.529-0.939,2.927,0.657,2.552C258.355,612.311,257.673,609.282,256.017,610.041 M689.4,610.391
	c-1.775,0.247-1.195,3.106,0.58,2.415C691.584,612.482,690.944,609.913,689.4,610.391 M2338.125,610.237
	c-2.244,1.365,1.101,2.79,2.142,1.271C2341.205,610.212,2338.91,609.512,2338.125,610.237 M809.216,611.176
	c-0.64,1.707,3.021,2.424,3.499,0.998C811.896,611.304,810.334,610.434,809.216,611.176 M2255.403,612.089
	c-1.408,1.519,1.707,2.918,2.33,1.143C2258.313,611.842,2256.179,611.074,2255.403,612.089 M2261.427,612.217
	c-0.572,0.179-1.715,0.538-2.296,0.717c0.41,2.261,3.926,1.715,4.941,0.102C2263.159,612.746,2262.281,612.473,2261.427,612.217
	 M2362.078,612.072c-1.186,1.314,1.195,2.057,1.86,0.879C2365.141,611.654,2362.667,610.937,2362.078,612.072 M757.76,612.883
	c-1.323,1.502,1.758,2.995,2.372,1.22C760.67,612.729,758.596,611.935,757.76,612.883 M2141.09,612.866
	c-0.922,1.246,1.263,2.483,2.142,1.374C2144.248,612.934,2141.909,611.688,2141.09,612.866 M2146.185,612.797
	c-0.085,0.563-0.265,1.681-0.35,2.244c0.845,0.393,1.707,0.802,2.594,1.212c0.435-0.393,1.297-1.178,1.724-1.57
	c0.717,0.384,1.451,0.777,2.219,1.178c0.068-0.495,0.213-1.476,0.281-1.963C2150.46,613.617,2148.369,612.806,2146.185,612.797
	 M2182.169,613.651c1.109,0.802,2.236,1.519,3.43,2.287c0.341-0.282,1.007-0.845,1.348-1.126
	C2185.625,613.608,2183.902,612.849,2182.169,613.651 M2339.328,614.598c0.555,0.614,1.144,1.237,1.758,1.869
	c0.068-0.742,0.205-2.227,0.273-2.97l-1.084,0.495c-0.435-0.367-1.323-1.109-1.758-1.476
	C2336.606,613.019,2337.536,615.46,2339.328,614.598 M2246.255,614.692c1.331,1.579,4.181,1.067,4.019-1.331
	C2248.738,613.233,2247.432,613.855,2246.255,614.692 M172.331,615.101c1.271,0.998,2.526,2.031,3.831,3.004
	c0.034-0.725,0.102-2.167,0.137-2.893C174.985,614.572,173.577,614.043,172.331,615.101 M367.352,614.538
	c1.092,1.587,2.671,2.697,4.702,2.475C371.977,614.487,369.229,614.367,367.352,614.538 M2090.47,616.322
	c0.043,0.606,0.136,1.826,0.179,2.441c1.57-1.246,3.533-2.355,4.156-4.395C2093.321,614.956,2091.895,615.656,2090.47,616.322
	 M844.194,615.844c-0.008,0.341-0.026,1.033-0.034,1.374c0.521,0.145,1.545,0.444,2.065,0.589c0-0.649,0.017-1.937,0.017-2.586
	C845.73,615.374,844.706,615.682,844.194,615.844 M930.91,615.033c0.35,0.759,0.725,1.527,1.109,2.304
	c0.862,0.358,1.724,0.734,2.594,1.118c1.818-0.794,3.669-0.085,5.47,0.401c-0.324-1.263-0.555-2.552-0.708-3.823
	c-1.732,0.247-3.32,0.922-4.855,1.681C933.393,615.98,932.181,615.391,930.91,615.033 M2165.06,615.374
	c-0.896,0.128-2.799,0.845-1.928,1.997C2164.454,618.788,2167.765,615.101,2165.06,615.374 M2347.477,615.34
	c-0.896,1.271,1.399,2.372,2.279,1.374C2350.72,615.366,2348.305,614.299,2347.477,615.34 M2382.455,615.536
	c-0.905,0.666-0.017,2.56,1.067,1.749C2384.521,616.637,2383.471,614.717,2382.455,615.536 M186.982,617.175
	c-0.529,0.879-1.075,1.758-1.621,2.637c0.572,1.434,0.375,2.714-1.084,3.49c-1.374-0.196-2.739-0.358-4.113-0.486
	c0.162-0.623,0.469-1.877,0.631-2.5c-2.449,0.981-5.052,1.246-7.578,0.418c-0.043,0.495-0.128,1.485-0.171,1.98
	c2.261,0.768,4.54,0.239,6.554-0.922c-0.623,1.323-0.717,2.611,0.666,3.465l-0.64,1.152c0.461-0.009,1.382-0.026,1.843-0.034
	l-0.213-1.434c1.664,0.589,3.413,0.836,5.163,1.101c0.887,1.007-0.7,1.638-1.152,2.424c0.094,0.751,0.179,1.51,0.239,2.278
	c-0.224,0.202-6.788,4.918-7.45,4.907c-0.051-0.853-0.162-2.569-0.213-3.422c-0.956,0.888-1.818,1.869-2.56,2.953
	c1.527,0.478,2.987,1.143,3.925,2.526c-0.614,0.068-1.835,0.213-2.449,0.282c-0.99-2.176-3.669-0.981-5.282-0.29
	c-1.109-0.742-2.202-2.167-3.558-0.99c0.981,3.106,5.239,2.893,7.629,4.557c-1.092,0.077-2.185,0.162-3.277,0.273
	c-0.879-0.29-1.766-0.563-2.645-0.819c-0.034,1.476,0.026,2.995,0.794,4.335c2.133-0.683,4.326-1.143,6.528-1.536
	c0.887,0.64,1.783,1.289,2.688,1.92c-0.802,0.29-1.604,0.606-2.398,0.93c-0.452-1.579-2.057-1.289-3.345-1.417
	c0.085,1.161,0.171,2.33,0.247,3.507c0.597-0.017,1.792-0.051,2.389-0.068c-0.213,0.435-0.64,1.297-0.853,1.724
	c1.536,1.135,3.217,3.277,5.214,1.664c-0.503-1.203-1.357-2.048-2.714-2.116c0.691-0.375,1.382-0.76,2.082-1.126
	c0.085,0.486,0.265,1.468,0.358,1.963c2.074-0.469,4.079-1.604,6.374-1.434c-0.333-0.333-0.998-0.981-1.331-1.306
	c1.28,0.444,2.765,1.254,3.644-0.273c-0.307-0.58-0.905-1.724-1.212-2.295c1.05,0.811,2.219,1.451,3.499,1.835
	c0.085-0.529,0.247-1.596,0.333-2.133c2.005,0.708,3.115,2.389,3.447,4.454c-2.244-0.956-4.489,0.265-6.656,0.811
	c3.737,2.759,1.73,3.251,12.826,2.475c-1.024-0.751-2.253-0.973-3.465-1.195c2.236-5.75-0.542,0.91-0.879-8.363
	c0.674-0.742,1.306-1.51,1.894-2.313c-4.715,1.059-5.248,1.274-6.724,1.792c1.92-1.903,4.463-2.901,7.177-2.773
	c0.051-0.486,0.171-1.468,0.222-1.954c0.555-1.186,0.162-2.423-0.111-3.601c0.222-0.29,0.674-0.879,0.896-1.169
	c-0.657,0.034-1.963,0.111-2.611,0.145c0.196-1.459-0.017-3.055,0.794-4.343c1.365,0.017,2.116,1.399,3.268,1.954
	c1.118-0.077,2.236-0.179,3.354-0.316c-0.034,0.401-0.094,1.212-0.128,1.621c2.737,0.403,12.563,3.95,12.544-0.64
	c-0.691,0.026-2.082,0.06-2.773,0.077c-0.973-0.469-1.946-0.922-2.927-1.365c0.358-0.282,1.067-0.836,1.417-1.118
	c2.628,0.35,5.274,0.99,7.945,0.606c1.331-1.058,0.811-2.918,1.092-4.361c-1,0.206-8.701,1.894-11.358,2.85
	c0.444-1.186,1.143-2.295,1.297-3.558c-1.34-0.213-2.423,0.725-3.584,1.195c-0.06-0.785-0.179-2.355-0.239-3.14
	c-1.263,1.169-2.014,3.021-3.627,3.729c-0.077-0.418-0.213-1.263-0.282-1.69c-7.874-0.227-2.622-2.167-12.211,0.606
	c1.357-2.202,3.575-3.507,5.871-4.523c0.06-0.529,0.179-1.57,0.247-2.099c-0.631,0.154-1.877,0.444-2.5,0.597
	c-1.084-1.075-2.398-1.792-3.891-2.074C190.908,614.837,188.74,616.177,186.982,617.175 M243.081,616.364
	c0.077,0.418,0.23,1.246,0.307,1.664c0.469,0.026,1.399,0.077,1.869,0.111c0.043-0.444,0.119-1.331,0.162-1.775
	C244.83,616.364,243.661,616.364,243.081,616.364 M365.013,616.424c0.333,1.041,0.606,3.106,2.185,2.594
	c1.545,0.094,0.461-1.852,0.367-2.628C366.925,616.398,365.653,616.415,365.013,616.424 M399.872,617.166
	c1.809,1.638,2.859,3.891,4.454,5.726c0.666-0.119,2.278-1.323,1.101-1.946c-1.681-1.135-1.587-3.183-1.57-4.966
	C402.475,616.202,401.144,616.586,399.872,617.166 M2138.974,616.245c-1.024,1.348,1.493,2.278,2.321,1.229
	C2142.268,616.1,2139.75,615.238,2138.974,616.245 M2233.216,616.347c-0.956,1.314,1.374,2.381,2.21,1.263
	C2236.425,616.27,2233.984,615.195,2233.216,616.347 M2370.551,616.185c-0.657,1.545,2.867,2.637,3.157,0.888
	C2373.316,615.827,2371.482,615.468,2370.551,616.185 M2396.049,616.467c0.452,1.673,2.278,1.186,3.644,1.434
	C2399.454,615.963,2397.474,615.75,2396.049,616.467 M2465.621,615.938c0.017,2.551,2.535,2.44,4.182,1.536
	C2468.625,616.356,2467.14,616.006,2465.621,615.938 M20.6,617.431c-0.836,0.375-1.263,1.058-1.297,2.022
	c1.442,0.862,2.765-0.222,3.618-1.323C22.246,617.047,21.47,616.817,20.6,617.431 M719.36,617.107
	c-1.135,0.776-0.111,3.234,1.212,2.125C721.749,618.446,720.691,615.98,719.36,617.107 M2106.735,618.702
	c-1.57,0.879,0.725,2.415,1.673,1.69c1.8,0.034,2.765-1.92,1.92-3.43C2109.252,617.841,2107.964,618.233,2106.735,618.702
	 M2242.313,618.771c0.657,0.017,1.971,0.06,2.628,0.077c3.291,3.124,3.312,0.697,5.222-0.87c-1.801-0.265-3.558-0.623-5.333-0.751
	C2243.951,617.491,2241.98,617.303,2242.313,618.771 M2248.798,620.895c0.717,0.077,2.15,0.222,2.867,0.299
	c-0.017-0.521-0.06-1.562-0.077-2.082c1.8,0.666,4.053-0.034,3.507-2.372C2252.945,618.028,2250.368,618.882,2248.798,620.895
	 M373.205,617.764c0.393,0.896,0.87,1.766,1.417,2.603c1.203,0.034,1.801-0.648,1.801-2.048
	C375.364,618.037,374.289,617.849,373.205,617.764 M2093.209,618.037c-1.05,1.246,1.254,2.125,1.971,1.041
	C2096.188,617.841,2093.875,617.021,2093.209,618.037 M167.1,619.795c-1.314,1.536,1.92,2.876,2.5,1.143
	C170.027,619.564,167.936,618.941,167.1,619.795 M681.993,619.615c-0.094,0.606-0.299,1.809-0.401,2.406
	c1.357-0.683,3.473-0.648,3.738-2.526C684.211,619.522,683.093,619.564,681.993,619.615 M950.059,619.462
	c-0.119,0.623-0.35,1.86-0.469,2.483c0.708-0.043,2.125-0.128,2.833-0.162C951.697,620.93,950.903,620.162,950.059,619.462
	 M2085.973,619.479c-0.341,2.637,3.319,2.176,3.934,0.23C2088.576,619.607,2087.262,619.53,2085.973,619.479 M2346.129,621.621
	c1.621,0.154,3.2,0.324,4.873,0.478C2350.754,619.069,2346.965,618.796,2346.129,621.621 M2467.968,619.829
	c-1.493,2.304,1.476,3.558,3.396,3.191C2470.827,621.664,2469.743,619.069,2467.968,619.829 M2109.303,622.602
	c-1.075,0.282-1.954,0.777-2.654,1.579c0.333,0.29,1.007,0.87,1.34,1.161c2.142-1.382,3.968-3.61,6.716-3.712
	C2113.476,619.121,2110.302,620.75,2109.303,622.602 M2195.234,622.781c1.229,0.29,2.5,0.614,3.78,0.905
	c-0.76-1.306-1.638-2.552-2.782-3.507C2194.961,620.435,2195.081,621.843,2195.234,622.781 M762.769,621.544
	C761.472,624.087,765.636,621.646,762.769,621.544 M236.314,622.414c-1.058,0.87-0.102,2.859,1.254,2.466
	C239.309,624.343,237.773,621.331,236.314,622.414 M362.189,625.734c1.638-0.836,2.927-2.125,3.721-3.78
	C363.827,622.227,362.778,623.891,362.189,625.734 M755.311,623.78c1.741,1.246,3.772,1.835,5.914,1.792
	c-0.93-1.263-1.826-2.551-2.884-3.686C756.941,621.314,756.258,623.191,755.311,623.78 M2097.988,626.254
	c1.698,0.87,3.575,0.768,5.427,0.375c-0.785-1.05-1.416-2.722-2.91-2.756C2099.311,624.189,2098.765,625.444,2097.988,626.254
	 M223.505,624.761c-0.427,1.382,0.051,1.852,1.434,1.391C225.365,624.744,224.888,624.283,223.505,624.761 M721.894,624.88
	c-0.964,0.981,0.077,2.808,1.425,2.526C725.06,626.929,723.209,623.805,721.894,624.88 M2111.838,624.275
	c0,1.195,0.026,2.398,0.094,3.61c0.538-0.12,1.621-0.367,2.167-0.495C2113.963,625.939,2113.212,624.804,2111.838,624.275
	 M2250.146,624.727c-0.478,1.408-0.017,1.852,1.391,1.34C2251.972,624.65,2251.511,624.206,2250.146,624.727 M2264.422,625.495
	c0.068,0.589,0.196,1.775,0.256,2.372c1.587-0.631,3.234-1.391,3.473-3.311C2266.837,624.71,2265.608,625.026,2264.422,625.495
	 M935.151,625.273c0,1.186,0.026,2.381,0.077,3.584c0.785-0.683,1.57-1.374,2.372-2.039
	C936.858,626.161,936.081,625.572,935.151,625.273 M2127.206,630.018c0.196,0.282,0.589,0.836,0.785,1.118
	c-0.205,0.256-0.623,0.776-0.836,1.033c-2.134-1.468-4.949-0.947-5.47,1.835c2.347-0.017,4.693-0.188,7.065-0.341
	c-1.391,2.671-4.335,2.116-6.81,1.997c-0.375-0.418-1.118-1.254-1.493-1.664c-1.177,0.666-1.766,1.724-1.886,3.012
	c0.922,0.384,1.869,0.776,2.85,1.178c-0.196,1.34,0.512,2.415,1.399,3.354c-1.527,0.094-3.038,0.222-4.369,0.93
	c0.529,0.41,1.57,1.246,2.091,1.655c-1.442,0.358-2.842,0.751-4.241,1.143c-0.068,0.503-0.205,1.519-0.273,2.031
	c-1.024,0.572-1.98,1.186-2.927,1.818c0.657,0.136,1.954,0.401,2.603,0.529c-1.024,2.125-2.927,0.751-4.404,0.051
	c0.145,0.529,0.427,1.596,0.572,2.133c-1.698-0.683-3.388-0.299-4.822,0.691c-0.23-0.034-0.699-0.102-0.93-0.137
	c0.025,0.367,0.077,1.101,0.102,1.468c-0.597-0.017-1.792-0.034-2.389-0.043c0.145,1.331,1.365,1.758,2.526,2.057
	c1.383-2.142,4.395-0.802,6.212-2.406c1.041-0.93,2.27,0.077,3.473,0.205c0.87-1.109,1.476-2.406,2.219-3.61
	c-0.051-0.452-0.154-1.348-0.205-1.8c6.702,0.516,5.089-4.086,5.129-5.086c0.998,0.64,1.741,1.911,3.029,2.048
	c2.313-1.041,4.284-2.679,6.503-3.917c-0.307,1.562-1.365,2.697-2.338,3.857c1.195,0.751,2.458,1.434,3.712,2.176
	c0.657-0.486,1.34-0.964,2.048-1.434c0.751,0.794,1.553,1.596,2.364,2.389c1.314-1.143,2.261-0.512,2.85,0.964
	c1.34,0.265,2.671-0.853,4.002-1.178c-2.014,1.596-0.29,2.842,1.041,3.977c0.674-0.819,1.399-1.63,2.125-2.432
	c-1.383-0.785-2.739-1.596-3.917-2.62c-1.203-0.128-2.295-0.205-3.439-0.299c-0.375-1.323-1.578-1.604-2.73-1.766
	c-0.871-0.666-1.732-1.34-2.15-2.355c3.294,0.998,6.639,1.971,9.779,3.456c1.348-1.417,3.268-2.543,3.584-4.651
	c-2.449,0.188-4.847-0.171-7.194-0.742c0.333-0.998,0.666-1.997,1.041-2.978c1.92,0.333,3.473-0.964,3.55-2.927
	c-1.229,0.171-2.364,0.375-3.524,0.589l0.555-1.297c-0.555-0.043-1.647-0.137-2.202-0.179c-1.758,1.434-3.567-0.469-5.248-0.973
	c0.196-0.341,0.597-1.041,0.794-1.391c1.698-0.051,3.413-0.102,5.163-0.154c0.555,0.811,1.143,1.638,1.817,2.415
	c1.135-1.229,2.953-2.048,3.473-3.772c-0.512-0.094-1.527-0.282-2.04-0.375c0.034,0.614,0.111,1.835,0.154,2.449
	c-2.279-1.425-4.224-3.371-6.801-4.25c-2.304,1.442-3.652-0.87-4.728-2.483C2131.593,626.161,2129.749,628.627,2127.206,630.018
	 M2159.002,626.442c-1.357-0.154-2.68,1.98-0.981,2.543C2159.531,629.668,2160.674,626.784,2159.002,626.442 M2164.01,626.51
	c0,1.468,0.026,2.935,0.111,4.42c1.562-0.648,2.056-2.219,2.492-3.712C2165.726,626.98,2164.855,626.741,2164.01,626.51
	 M2170.914,626.425c-1.126,0.256-1.715,2.227-0.282,2.483C2172.1,629.079,2172.885,625.597,2170.914,626.425 M2219.409,626.621
	c-0.504,1.382-0.034,1.843,1.391,1.374C2221.235,626.587,2220.774,626.135,2219.409,626.621 M2240.905,628.686
	c1.638,0.802,3.097-0.751,3.43-2.278C2243.029,626.809,2239.548,626.434,2240.905,628.686 M382.473,629.838
	c2.057,0.222,4.13-0.469,4.122-2.884C385.067,627.637,382.959,627.918,382.473,629.838 M663.689,630.879
	c-0.077-1.314-0.171-2.611-0.282-3.908C661.453,627.645,661.231,630.837,663.689,630.879 M2210.185,627.236
	c-1.126,0.188-1.954,2.227-0.444,2.5C2211.575,630.154,2212.506,626.63,2210.185,627.236 M2213.444,631.545
	c2.014-0.009,4.659,0.102,5.931-1.792c-0.87-1.015-1.818-1.937-2.714-2.884C2215.381,628.285,2214.374,629.898,2213.444,631.545
	 M2320.307,628.277c0.725,1.118,1.51,2.236,2.261,3.396c-2.176-0.051-2.278-2.97-4.454-3.14c0.213,1.365,0.632,2.799,2.031,3.465
	c-0.427,0.179-1.271,0.529-1.698,0.7c0.068,0.614,0.196,1.835,0.265,2.449c-0.623,0-1.877,0-2.5,0
	c1.893,3.84,14.622,3.139,8.781-7.825C2323.388,627.458,2321.826,627.935,2320.307,628.277 M2343.714,628.354
	c0.512,0.691,1.075,1.391,1.656,2.091c1.314-0.418,2.611-0.811,4.002-1.178C2348.117,627.005,2345.685,627.825,2343.714,628.354
	 M2350.865,627.287c-1.195,0.623-1.229,1.203-0.119,1.741C2351.872,628.396,2351.915,627.816,2350.865,627.287 M757.35,628.029
	c0.521,0.742,1.05,1.485,1.587,2.244c1.357-0.341,2.697-0.691,4.07-1.024C761.72,627.466,759.185,628.413,757.35,628.029
	 M764.51,628.371c-0.964,0.811,0.171,2.859,1.331,1.997C766.822,629.548,765.636,627.526,764.51,628.371 M816.478,632.262
	c2.347,0.384,3.866-1.784,3.925-3.908C818.091,627.5,817.766,631.016,816.478,632.262 M832.725,628.149
	c-0.427,1.382,0.06,1.869,1.451,1.451C834.577,628.191,834.091,627.714,832.725,628.149 M2161.647,628.115
	c-0.333,1.186,0.103,1.596,1.297,1.22C2163.234,628.115,2162.807,627.705,2161.647,628.115 M240.589,628.755
	c0.316,0.862,0.649,1.724,1.007,2.594c1.169,0.478,1.604-0.64,2.15-1.417C242.697,629.523,241.647,629.122,240.589,628.755
	 M248.465,628.866c0.043,0.572,0.119,1.698,0.154,2.27c0.597,0.051,1.783,0.171,2.381,0.222
	C250.556,630.146,249.489,629.506,248.465,628.866 M834.842,631.204c0.742,0.06,2.227,0.196,2.97,0.256
	c-0.085-0.7-0.256-2.108-0.341-2.807C836.361,629.241,835.447,630.094,834.842,631.204 M871.902,629.335
	c-0.307,1.169,0.154,1.562,1.382,1.161C873.549,629.318,873.088,628.925,871.902,629.335 M2103.185,629.019
	c0.572,1.263,1.16,2.637,2.833,2.705C2106.01,629.779,2105.216,628.567,2103.185,629.019 M2315.23,630.077
	c-1.28,1.818,0.862,2.85,2.261,3.43C2317.585,632.134,2317.363,628.729,2315.23,630.077 M698.018,630.615
	c-0.247,1.203,0.213,1.638,1.374,1.323C699.622,630.709,699.17,630.265,698.018,630.615 M661.495,631.758
	c-0.922,1.118,0.87,3.055,2.039,2.074C664.627,632.646,662.665,630.675,661.495,631.758 M820.813,631.69
	c0.102,0.529,0.29,1.57,0.384,2.099c0.759-0.538,1.493-1.126,2.167-1.783C822.724,631.929,821.453,631.767,820.813,631.69
	 M957.995,631.46c-0.162,1.587,0.555,3.797,2.586,3.26C961.792,632.927,958.985,632.356,957.995,631.46 M2214.007,634.395
	c0.401,0.734,0.819,1.468,1.28,2.202l-1.118,0.128l-0.119-1.391c-1.28,0.674-2.645,1.451-2.714,3.072
	c1.126-0.043,2.279-0.085,3.456-0.119c0.29-0.418,0.862-1.263,1.152-1.681c2.313,0.717,4.343-0.205,5.905-1.946
	c-0.154-1.101-0.282-2.193-0.375-3.285C2218.777,631.818,2216.448,633.303,2214.007,634.395 M2311.637,631.647
	c-0.452,1.22-0.068,1.587,1.161,1.101C2313.216,631.528,2312.832,631.161,2311.637,631.647 M750.276,634.344
	c1.126,1.101,2.756-0.802,1.775-1.86C750.848,631.11,748.698,633.226,750.276,634.344 M2100.864,634.677
	c1.144,0.64,2.748-1.169,2.185-2.278C2101.837,631.767,2100.181,633.516,2100.864,634.677 M2159.778,633.772
	c0.521,1.118,2.449,1.092,3.089,0.051C2163.217,632.006,2159.607,632.083,2159.778,633.772 M2349.124,632.313
	c0.085,0.58,0.265,1.724,0.359,2.295c-1.417-0.589-2.765-1.186-4.139-1.707c0.768,1.502,1.783,4.147,3.533,1.852
	c0.589,0.691,1.399,1.058,2.406,1.118c0.811-1.135,1.63-2.278,2.355-3.49C2352.094,632.33,2350.609,632.313,2349.124,632.313
	 M202.223,633.414c-1.152,0.828,0.06,3.149,1.365,2.159C204.715,634.719,203.503,632.484,202.223,633.414 M224.35,634.19
	c0.06,0.486,0.188,1.468,0.247,1.954c0.922-0.213,1.835-0.418,2.765-0.606c0.256-0.725,0.512-1.434,0.777-2.133
	C226.876,633.653,225.613,633.909,224.35,634.19 M845.474,633.235c0.171,0.29,0.521,0.862,0.691,1.152
	c-0.222,0.614-0.674,1.852-0.896,2.466c1.801-0.435,2.978-1.698,2.893-3.601C847.488,633.252,846.148,633.243,845.474,633.235
	 M698.837,634.096c-0.452,1.365,0.026,1.852,1.425,1.468C700.706,634.182,700.228,633.695,698.837,634.096 M757.367,634.276
	c0.495,1.246,1.544,2.014,2.722,2.603c-0.051-0.657-0.154-1.98-0.205-2.637C759.253,634.25,757.999,634.267,757.367,634.276
	 M884.565,637.382c0.631-0.043,1.894-0.137,2.526-0.179c0.043-1.161,0.077-2.313,0.119-3.456
	C885.606,634.404,884.523,635.564,884.565,637.382 M895.147,633.849c-0.93,1.135-2.756,1.894-2.799,3.533
	c-0.392,2.193,2.057,3.149,3.251,4.565c1.058-1.519,2.048-3.098,2.935-4.719c-1.306,0.333-2.594,0.717-3.874,1.092
	c0.299-1.724,1.28-3.149,2.475-4.378C896.64,633.926,895.65,633.875,895.147,633.849 M2086.238,635.325
	c1.16,0.299,1.621-0.128,1.374-1.28C2086.374,633.713,2085.914,634.139,2086.238,635.325 M1903.778,635.095
	c-1.391,1.195,0.623,3.2,1.698,1.596C1906.901,635.522,1904.87,633.713,1903.778,635.095 M2110.916,637.877
	c0.836-0.196,1.698-0.384,2.577-0.572c-0.051-0.606-0.145-1.826-0.188-2.432C2112.171,635.624,2110.916,636.358,2110.916,637.877
	 M2152.712,636.674c1.34,1.536,2.432-0.828,2.918-1.886C2154.658,634.822,2151.108,635.155,2152.712,636.674 M2308.19,634.984
	c-1.126,0.794-0.085,3.243,1.254,2.167C2310.588,636.349,2309.487,633.969,2308.19,634.984 M205.747,636.068
	c-1.024-0.171-2.731,0.853-1.86,2.005C205.167,639.592,207.778,636.767,205.747,636.068 M217.958,636.639
	c-0.111,0.836-0.239,1.672-0.367,2.534c0.597-0.051,1.792-0.145,2.389-0.196c0.273-1.058,0.529-2.133,0.785-3.183
	C219.827,636.051,218.897,636.349,217.958,636.639 M708.651,637.339c0.58,1.297,2.099,1.775,3.166,2.637
	c0.717-0.026,2.159-0.085,2.884-0.111c-0.759-0.666-1.527-1.314-2.287-1.954c-0.435-0.981-0.853-1.954-1.263-2.927
	C710.246,635.615,708.429,635.871,708.651,637.339 M221.858,636.52c-0.085,1.126-0.154,2.261-0.23,3.413
	c1.664-0.102,3.319-0.188,4.983-0.282c0.017-0.478,0.051-1.425,0.068-1.903c-0.794,0.29-1.587,0.589-2.372,0.896
	C223.488,637.919,222.677,637.22,221.858,636.52 M246.076,636.477c1.007,2.253,2.526,5.393,5.367,5.393
	c0.939-1.237,1.783-2.526,2.731-3.755C251.452,637.681,248.764,637.049,246.076,636.477 M346.266,637.484
	c0.034,0.691,0.102,2.091,0.128,2.79c0.759-0.504,1.51-1.007,2.253-1.51c0.393,0.06,1.169,0.188,1.553,0.256
	c0-0.623-0.017-1.86-0.026-2.475C348.86,636.81,347.563,637.134,346.266,637.484 M899.337,638.278
	c0.904,0.794,2.773-0.538,2.116-1.621C900.506,635.897,898.611,637.134,899.337,638.278 M2107.204,637.868
	c-0.418,1.126,0.939,1.425,1.622,2.014c0.068-1.186,0.136-2.364,0.222-3.541C2108.382,636.81,2107.767,637.322,2107.204,637.868
	 M206.729,638.645c1.169,1.05,2.483,1.963,4.028,2.398c-0.196-1.408-0.299-3.029-1.835-3.576
	C208.376,637.757,207.283,638.355,206.729,638.645 M2301.781,639.95c1.604,0.017,3.234,0.017,4.889,0
	c-0.478-1.058-0.896-2.295-2.039-2.722C2303.539,637.996,2302.66,638.986,2301.781,639.95 M2342.997,638.355
	c0.051,0.589,0.154,1.758,0.213,2.338c0.649,0.043,1.946,0.137,2.594,0.179c-0.239-1.297-0.589-2.552-1.186-3.695
	C2344.209,637.476,2343.398,638.065,2342.997,638.355 M2380.74,637.655c-1.058,1.357,1.417,2.278,2.261,1.246
	C2384,637.535,2381.508,636.605,2380.74,637.655 M817.212,639.259c0.026,0.589,0.068,1.775,0.094,2.364
	c1.075,0.051,2.15,0.111,3.243,0.188c0.819,0.845,1.707,1.638,2.944,1.877c-0.273-2.577-3.004-3.644-4.506-5.436
	C818.543,638.5,817.656,639.003,817.212,639.259 M2220.459,638.235c-0.563,1.169-0.213,1.562,1.05,1.178
	C2222.037,638.218,2221.688,637.826,2220.459,638.235 M351.573,639.131c-0.478,1.357-0.017,1.869,1.382,1.527
	C353.425,639.276,352.964,638.764,351.573,639.131 M760.081,639.473c0.64,0.87,1.399,1.656,2.236,2.364
	c0.051-0.649,0.154-1.937,0.205-2.577C761.916,639.31,760.695,639.421,760.081,639.473 M166.17,640.002
	c0.043,0.64,0.119,1.92,0.162,2.56c0.546-0.077,1.647-0.23,2.193-0.307c0.009-0.452,0.026-1.357,0.034-1.818
	C167.962,640.334,166.767,640.113,166.17,640.002 M243.499,642.195c1.237,0.111,2.483,0.213,3.729,0.299
	c-0.068-0.606-0.205-1.818-0.282-2.424C245.692,640.582,244.429,641.162,243.499,642.195 M1885.833,640.036
	c-0.444,1.408,0.034,1.877,1.417,1.408C1887.642,640.01,1887.172,639.549,1885.833,640.036 M2217.779,640.275
	c-1.758,0.35-1.143,3.012,0.632,2.389C2220.092,642.408,2219.298,639.686,2217.779,640.275 M214.852,640.974
	c-0.393,1.382,0.111,1.835,1.502,1.357C216.738,640.932,216.235,640.479,214.852,640.974 M262.4,641.341
	c-0.179,0.469-0.538,1.399-0.717,1.869c1.988,0.085,3.977,0.213,5.973,0.256c-1.05-1.143-2.423-1.869-3.772-2.569
	C263.518,641.009,262.776,641.23,262.4,641.341 M2234.888,641.12c-1.135,1.289,1.237,2.082,1.912,0.922
	C2237.943,640.761,2235.494,640.053,2234.888,641.12 M2317.628,641.265c-0.043,0.538-0.111,1.604-0.145,2.142
	c0.563-0.051,1.707-0.154,2.278-0.205c0.077-0.58,0.23-1.741,0.307-2.321C2319.462,640.974,2318.233,641.171,2317.628,641.265
	 M2322.15,641.102c-0.504,1.391-0.998,2.773-1.485,4.164c1.058-0.845,2.185-1.638,3.439-2.244c-0.034-0.546-0.103-1.655-0.137-2.21
	C2323.507,640.889,2322.603,641.034,2322.15,641.102 M372.028,643.739c0.998,0.486,3.174-0.623,2.381-1.869
	C373.308,640.966,371.371,642.493,372.028,643.739 M1883.179,641.879c-0.23,0.7-0.444,1.399-0.623,2.116
	c1.186-0.478,2.585-0.777,3.336-1.954C1885.218,641.998,1883.861,641.922,1883.179,641.879 M2078.805,641.742
	c-1.092,0.87-1.724,2.116-2.313,3.345c1.536-0.836,3.115-1.553,4.796-2.108C2080.503,642.468,2079.829,641.375,2078.805,641.742
	 M2114.483,641.708c0.06,0.503,0.188,1.519,0.247,2.022c0.615-0.077,1.289-0.128,1.98-0.171c0.051-0.435,0.162-1.297,0.213-1.724
	C2116.318,641.802,2115.089,641.734,2114.483,641.708 M206.686,642.425c-0.085,0.614-0.265,1.843-0.358,2.449
	c0.708,0.034,2.133,0.102,2.842,0.137C208.555,643.944,207.761,643.031,206.686,642.425 M697.907,643.005
	c-1.05,0.794,0.137,2.884,1.314,1.98C700.169,644.174,699.025,642.203,697.907,643.005 M742.69,642.587
	c0.879,1.16,2.065,1.818,3.558,1.724c0.256-0.435,0.776-1.306,1.032-1.741C745.745,642.553,744.209,642.545,742.69,642.587
	 M845.995,642.647c-0.725,1.016,0.879,2.637,1.911,1.911C849.007,643.423,847.104,641.486,845.995,642.647 M2064.026,642.476
	c0.034,0.649,0.102,1.946,0.136,2.594c0.538-0.077,1.63-0.239,2.167-0.324c-0.017,1.476,0.922,2.509,2.202,3.123
	c1.05-1.007,2.15-1.971,3.285-2.927C2069.367,643.236,2066.287,644.499,2064.026,642.476 M2310.852,642.741
	c-0.188,1.143-0.29,2.295-0.324,3.465c0.922-0.776,1.835-1.57,2.799-2.338C2312.533,643.278,2311.902,642.212,2310.852,642.741
	 M747.443,643.748c0.427,1.528,1.365,2.884,0.998,4.574c0.913,0.017,1.835,0.026,2.773,0.017c1.126,1.084,2.441,2.031,3.285,3.396
	c0.973,0.418,1.963,0.836,2.97,1.237c-0.222-0.461-0.666-1.374-0.887-1.826c0.546-0.094,1.647-0.282,2.202-0.367
	c-0.828-0.725-1.784-1.195-2.876-1.109c-0.58-2.099-2.688-2.022-4.446-1.877c-0.324-0.29-0.981-0.862-1.314-1.152
	c0.119-0.922,0.247-1.843,0.384-2.739C749.491,643.825,748.467,643.773,747.443,643.748 M2195.106,643.159
	c0.597,0.725,1.22,1.451,1.869,2.176c-0.154,0.341-0.461,1.032-0.614,1.374c2.184-0.836,5.717-0.094,6.11-3.191
	c-0.572,0-1.698-0.009-2.261-0.009C2198.647,645.002,2196.77,643.466,2195.106,643.159 M260.651,644.379
	c0.222,1.357,1.408,1.988,2.492,2.628c0.034-0.614,0.119-1.835,0.162-2.449C262.648,644.516,261.316,644.422,260.651,644.379
	 M885.504,644.413c0.282,0.717,0.572,1.442,0.879,2.176c-0.913,0.836-1.818,1.69-2.705,2.552c1.348,0.742,2.534,1.715,3.669,2.756
	c0.461-2.15,1.732-3.951,3.217-5.521c0.034-0.521,0.111-1.579,0.145-2.108C888.969,644.328,887.236,644.371,885.504,644.413
	 M2088.866,644.473c-0.119,0.324-0.367,0.973-0.486,1.306C2089.924,648.33,2091.767,643.014,2088.866,644.473 M1888.802,647.536
	c1.34-0.094,2.722-0.239,4.122-0.367c-0.341-0.691-0.649-1.374-0.93-2.048C1890.594,645.48,1889.374,646.163,1888.802,647.536
	 M1909.274,645.361c0.41,0.725,0.87,1.451,1.382,2.159c1.177,0.111,1.655-1.033,2.313-1.801
	C1911.714,645.582,1910.485,645.463,1909.274,645.361 M2379.145,646.564c0.452,0.913,2.952,1.391,2.219-0.282
	C2381.082,644.917,2378.641,645.096,2379.145,646.564 M2386.85,645.258c-0.034,0.563-0.111,1.707-0.145,2.27
	c-0.674,0.051-2.031,0.145-2.705,0.188c0,0.614,0,1.843,0,2.458c-0.427,0.026-1.28,0.085-1.698,0.111
	c-0.009,0.41-0.017,1.229-0.017,1.63c0.64,0.009,1.937,0.026,2.586,0.034c0.563-0.853,1.16-1.707,1.792-2.543
	c0.956,0.341,1.946,0.683,2.97,1.015c-0.06-1.476-0.836-2.261-2.33-2.355c0.64-0.648,1.288-1.297,1.988-1.928
	C2388.446,645.83,2387.635,645.54,2386.85,645.258 M201.481,646.026c0.077,0.546,0.222,1.638,0.29,2.185
	c-3.191,0.862-0.282,2.91,1.553,2.671c-0.35-0.768-1.05-2.295-1.391-3.063c0.956,0.171,2.876,0.521,3.84,0.691
	C205.295,646.453,203.418,645.693,201.481,646.026 M203.657,650.284c1.527,0.478,3.072,0.947,4.497,1.681
	c0.947-0.043,1.894-0.077,2.842-0.111c0.461-1.254,1.621-1.672,2.79-2.022c-0.06-0.998-0.111-1.971-0.145-2.961
	c-0.341-0.205-1.033-0.623-1.382-0.828C209.033,646.623,206.097,648.091,203.657,650.284 M564.966,646.265
	c-1.075,1.246,1.229,2.125,1.903,0.99C567.962,645.992,565.624,645.164,564.966,646.265 M757.589,648.287
	c2.005-0.674,4.011-0.017,5.973,0.478c0.196-0.444,0.589-1.331,0.785-1.775c-0.444-0.282-1.34-0.845-1.783-1.118
	c-0.691,0.265-1.365,0.538-2.039,0.811C759.228,645.796,757.103,646.436,757.589,648.287 M2072.636,646.052
	c-0.128,0.486-0.384,1.468-0.512,1.963c-1.067,0.623-2.099,1.28-3.089,1.971c1.622,0.213,3.345,0.478,4.975,0.93
	c0.751-1.434,2.5-2.415,2.773-4.036C2076.262,644.849,2074.01,645.71,2072.636,646.052 M690.807,649.388
	c0.614-0.085,1.843-0.256,2.458-0.341c-0.205-0.913-0.41-1.809-0.614-2.697C691.593,647.059,691.2,648.245,690.807,649.388
	 M2110.362,646.999c-1.144,1.135,0.742,2.825,1.869,1.792C2113.775,647.682,2111.462,645.685,2110.362,646.999 M224.452,649.337
	c1.186,0.333,1.655-0.094,1.417-1.28C224.691,647.724,224.222,648.151,224.452,649.337 M2120.695,651.769
	c-0.683-0.111-2.065-0.35-2.748-0.469c-0.393,2.611,2.637,2.227,4.198,2.91c0.452-0.759,0.947-1.493,1.468-2.219
	c-0.248,0.461-0.743,1.391-0.99,1.86c0.153,0.691,0.461,2.082,0.614,2.782c-1.374-0.196-2.722-0.375-4.045-0.521
	c0.529,3.507,4.693,2.483,7.091,1.792c-0.333,0.401-1.007,1.195-1.34,1.596c0.392,0.461,1.186,1.374,1.579,1.835
	c-2.142-0.614-6.135-2.859-6.511,0.905c-0.632-0.094-1.911-0.273-2.543-0.367c-0.435,2.287,2.193,2.748,3.251,1.015
	c0.41-0.119,1.212-0.358,1.613-0.469c1.041,0.282,2.048,0.529,3.157,0.811c-0.026,0.401-0.085,1.203-0.12,1.604
	c-1.067-0.341-2.091-0.76-3.149-0.93c-0.478,0.811-0.905,1.638-1.289,2.492c-0.529,0.017-1.57,0.06-2.099,0.077
	c-0.111,0.623-0.341,1.86-0.452,2.475c-1.963-1.161-3.678-0.29-5.265,0.998c0.478-1.681,1.86-2.799,2.961-4.07
	c-1.058-1.016-2.586-1.818-0.768-3.157c-0.64,0.12-1.911,0.367-2.552,0.495c0.248,0.777,0.512,1.57,0.811,2.364
	c-1.016,0.111-2.005,0.239-2.961,0.367c-0.546,1.323-1.118,2.628-1.681,3.942c0.486,0.265,1.451,0.811,1.928,1.084
	c2.842,0.009,5.82,0.273,8.03-1.92c3.72-0.426,7.83,0.321,9.634,1.067c4.016,3.851,12.748,3.371,15.206-0.393
	c-2.173,0.604-3.01,0.831-5.803,1.732c0.956-2.202,2.978-3.499,4.796-4.932c-0.563,0.009-1.69,0.026-2.261,0.034
	c-0.607,0.329-3.357,0.933-5.538,1.894c1.118-1.348-0.41-2.287-0.29-3.644c0.367-2.287-2.202-1.016-3.336-1.912
	c1.579-0.179,3.166-0.265,4.796-0.358c-0.802-0.691-1.596-1.374-2.355-2.048c1.178-0.538,2.441-0.87,3.763-1.067
	c-0.273-0.418-0.819-1.246-1.092-1.664c1.51,0.051,3.038,0.111,4.599,0.179c-0.367-1.451-0.725-2.876-1.109-4.301
	c-0.623-0.017-1.877-0.06-2.509-0.077c-1.314-1.212-2.663-2.372-3.866-3.652c-1.254-0.452-2.5-0.879-3.704-1.297
	c-0.316,0.853-0.665,1.707-0.904,2.586c-1.485,0.435-3.098,0.435-4.045-0.973c0.76-0.06,2.27-0.171,3.029-0.23
	c-0.008-0.512-0.025-1.545-0.034-2.057C2125.269,648.723,2122.709,649.969,2120.695,651.769 M889.139,652.785
	c0.717,1.101,2.193,1.698,3.243,2.586c-0.154-2.355-0.171-4.702-0.341-7.04C890.701,649.542,890.232,651.377,889.139,652.785
	 M1909.641,648.185c0.196,0.922,0.418,1.835,0.665,2.765c-0.93-0.35-1.852-0.657-2.748-0.939c-0.401,1.067-0.248,2.125,0.452,3.166
	C1912.271,653.307,1914.795,651.263,1909.641,648.185 M2379.051,648.544c-0.478,1.152-0.094,1.553,1.161,1.203
	C2380.664,648.578,2380.279,648.177,2379.051,648.544 M234.701,649.474c-1.186,0.145-1.69,2.372-0.247,2.526
	C235.657,651.923,236.041,649.576,234.701,649.474 M2237.056,649.397c0.111,0.631,0.341,1.886,0.452,2.517
	c1.604,0.188,2.338-1.63,3.439-2.517C2239.625,649.38,2238.327,649.38,2237.056,649.397 M115.2,650.395
	c-0.247,0.247-0.734,0.725-0.981,0.964C115.106,654.133,118.63,649.755,115.2,650.395 M758.835,651.564
	c2.014,0.495,4.028,0.998,6.033,1.562c-0.23-0.623-0.683-1.869-0.904-2.5C762.214,650.737,760.44,650.805,758.835,651.564
	 M2226.389,649.934c-0.307,1.246-0.504,2.526-0.743,3.797c1.383-0.06,3.584-0.666,3.021-2.517
	C2227.9,650.745,2227.14,650.318,2226.389,649.934 M186.035,652.025c0.017,0.529,0.06,1.579,0.085,2.099
	c0.708-0.213,2.133-0.64,2.85-0.853c-0.043-0.469-0.128-1.417-0.171-1.886C187.87,651.556,186.931,651.709,186.035,652.025
	 M224.461,651.317c-0.333,0.99-0.649,1.997-0.956,3.004c1.886,0.239,3.678-0.307,5.376-1.075c0.094-0.427,0.299-1.28,0.393-1.707
	C227.669,651.684,226.048,651.573,224.461,651.317 M239.292,650.907c1.212,1.357,2.441,2.739,3.226,4.412
	c1.254-0.008,2.509-0.008,3.772,0c0.29-0.41,0.862-1.246,1.152-1.655c-1.169-0.435-2.381-0.657-3.584-0.213
	c-0.384-0.777-0.751-1.553-1.118-2.33C241.587,651.044,240.435,650.975,239.292,650.907 M2075.887,651.086
	c-0.35,2.133,1.835,2.739,3.243,3.686C2080.248,652.06,2082.265,651.984,2075.887,651.086 M2141.594,650.899
	c-0.435,1.365,0.051,1.843,1.468,1.442C2143.454,650.95,2142.968,650.472,2141.594,650.899 M2367.274,651.206
	c-0.503,1.357-0.042,1.835,1.383,1.408C2369.118,651.223,2368.657,650.754,2367.274,651.206 M171.452,652.153
	c-0.896,1.178,1.212,2.287,2.022,1.314C174.259,652.324,172.254,651.189,171.452,652.153 M255.019,654.355
	c1.476,1.451,2.594,0.008,3.456-1.246C257.186,652.827,254.208,651.871,255.019,654.355 M374.34,657.137
	c1.545-0.29,3.755,0.111,4.497-1.698c-1.092-1.092-0.512-2.33-0.026-3.516c-0.939,0.401-1.877,0.802-2.816,1.203l-0.171-1.621
	C374.929,653.262,374.682,655.216,374.34,657.137 M2080.905,655.029c1.732-0.751,3.405-1.698,4.728-3.089
	C2083.422,651.394,2080.452,652.179,2080.905,655.029 M745.301,653.023c0.87,1.766,2.654,2.731,4.122,3.968
	c2.645-0.017,5.35,0.35,7.91-0.58c-0.58-0.649-1.169-1.297-1.732-1.92c-1.408,0.597-2.867,1.067-4.122,1.937
	c0.171-1.707-1.229-2.714-2.15-3.866C748.058,653.186,746.65,653.066,745.301,653.023 M894.225,653.356
	c0.512,1.024,1.118,2.014,2.355,2.295c-0.06-0.649-0.196-1.954-0.265-2.603C895.795,653.126,894.746,653.279,894.225,653.356
	 M907.179,652.972c-0.93,1.272,1.34,2.389,2.176,1.331C910.413,652.964,907.998,651.812,907.179,652.972 M2149.265,657.205
	c0.981-0.085,1.997-0.154,3.038-0.23c0.572-1.417,3.584-3.575,0.76-4.395c-0.103,0.614-0.324,1.852-0.427,2.466
	c-0.265-0.418-0.794-1.254-1.067-1.673c-0.657-0.077-1.289-0.137-1.886-0.196C2149.521,654.508,2149.359,655.848,2149.265,657.205
	 M2347.58,653.041c-0.973,1.212,1.271,2.108,2.073,1.195C2350.558,653.015,2348.305,652.119,2347.58,653.041 M166.46,653.757
	c-0.683,0.205-1.886,1.826-0.666,2.116C170.508,655.123,167.927,652.29,166.46,653.757 M2349.764,654.466
	c0.06,0.597,0.179,1.801,0.239,2.398c1.971,1.058,3.9,3.593,6.391,2.423c1.528-0.435,3.012-1.459,3.072-3.234
	c-2.048,0.708-4.147,1.203-6.281,1.289c0-1.323,0.017-2.637,0.06-3.934C2352.026,653.672,2350.874,654.022,2349.764,654.466
	 M1906.765,658.126c0.887-0.375,1.801-0.751,2.722-1.135c-0.009-0.683-0.026-2.057-0.034-2.739
	C1908.079,655.191,1906.731,656.292,1906.765,658.126 M2067.584,656.522l-0.281-1.493c-1.502,0.836-3.192,1.075-4.822,1.365
	c-0.99,1.579,1.732,1.86,2.586,2.671c2.85,0.23,5.547-1.818,6.025-4.668C2069.504,654.355,2068.591,655.575,2067.584,656.522
	 M192.896,655.72c0.094,0.375,0.29,1.118,0.384,1.493c-1.613,0.12-4.25-1.05-4.335,1.476c0.964,0.111,1.929,0.23,2.893,0.358
	c-0.503,0.282-1.519,0.845-2.031,1.118c-2.074-0.094-4.275,0.725-4.267,3.166c0.452-0.009,1.365-0.017,1.826-0.026
	c-0.93,0.819-2.714,1.382-2.219,2.944c2.799,0.512,5.427-1.041,7.885-2.15c1.724-0.137,3.874-1.263,5.146,0.521
	c-1.783,0.273-3.593,0.384-5.385,0.512c-0.077,0.836-0.213,2.517-0.282,3.354c-1.033-0.367-1.698-1.075-2.005-2.142
	c-0.538,0.213-1.621,0.64-2.159,0.853c0.794,1.237,1.596,2.483,2.389,3.746c-0.657,0.017-1.971,0.051-2.62,0.068
	c0.435,1.792,2.185,2.458,3.593,3.354c-0.213,0.205-0.649,0.623-0.87,0.828c-1.306-0.341-2.005,0.58-2.56,1.647l-0.802,0.316
	c0.512,0.674,1.024,1.34,1.545,2.031c1.246-0.836,2.509-1.63,3.755-2.44c0.649-0.017,1.929-0.051,2.569-0.06
	c0.708-1.126,1.374-2.27,2.099-3.371c0.802,0.009,1.331,0.666,1.937,1.109c-0.461,0.393-1.382,1.186-1.843,1.579
	c4.122,2.048,9.011-0.452,13.389-0.759c2.961,0.734,8.661,2.193,9.284-2.125c-0.973,0.316-1.954,0.623-2.927,0.913
	c-1.058-0.623-1.92-1.51-2.825-2.304c-1.749-0.29-3.482-0.631-5.197-1.033c-0.631,0.862-1.28,1.732-1.92,2.603
	c-0.205-0.512-0.606-1.545-0.811-2.057c0.393-0.375,1.161-1.126,1.553-1.502c0.333-1.254-1.263-1.664-1.929-2.389
	c2.261,0.358,5.001,0.555,6.451-1.604c-1.186-0.094-2.372-0.171-3.558-0.23c-0.23-0.392-0.683-1.169-0.905-1.562
	c-2.645,0.401-5.265-0.316-7.706-1.246c-1.135-2.953-4.053-0.23-5.82,0.469c0.137-0.811,0.41-2.423,0.546-3.234
	c-0.845-0.734-1.715-1.425-2.603-2.082c2.85-0.913,5.786-0.427,8.678-0.068c0.051,0.299,0.145,0.896,0.196,1.195
	c2.415,0.427,5.53,0.29,6.05-2.731c-1.988,0.529-4.011,1.075-6.084,1.075c-0.128-0.495-0.375-1.502-0.495-1.997
	c-2.355,0.026-4.924-0.597-7.074,0.623C193.596,655.899,193.126,655.78,192.896,655.72 M226.074,657.333
	c0.401,0.853,1.186,1.425,1.835,2.116c0.538-0.589,1.075-1.178,1.613-1.749C228.497,656.889,227.029,655.711,226.074,657.333
	 M893.38,656.753c-0.751,1.237,1.229,2.995,2.347,1.903C896.717,657.324,894.447,655.865,893.38,656.753 M898.202,656.795
	c0.717,1.05,1.459,2.091,2.227,3.14c0.777-1.246,1.297-2.62,1.758-4.002C900.992,656.795,899.618,657.043,898.202,656.795
	 M923.238,656.522c-1.647,0.341-0.998,3.038,0.674,2.432C925.628,658.613,924.817,655.967,923.238,656.522 M2114.099,658.485
	c1.297,0.094,2.611,0.179,3.951,0.265c-0.478-0.964-0.947-1.911-1.399-2.85C2115.635,656.599,2114.424,657.196,2114.099,658.485
	 M2362.01,656.377c-1.092,0.725-0.06,2.799,1.152,1.886C2364.365,657.555,2363.17,655.413,2362.01,656.377 M907.477,662.018
	c0.273-1.732,0.171-3.499,0.102-5.231C905.668,657.99,904.064,661.864,907.477,662.018 M2072.986,657.358
	c-0.751,0.358-1.988,2.099-0.58,2.406C2074.078,660.14,2075.196,656.531,2072.986,657.358 M2224.606,660.797
	c-0.554-0.495-1.664-1.476-2.219-1.971c-2.261,1.357-0.717,3.797,1.135,4.642c0.103,0.631,0.299,1.886,0.393,2.517
	c1.383-1.502,3.661-4.659,0.905-6.033c0.998-0.862,2.21-1.638,2.347-3.106C2224.947,656.949,2224.085,658.818,2224.606,660.797
	 M2345.404,657.444c0.854,1.331,2.219,2.065,3.763,2.441c-0.401-1.058-0.785-2.099-1.16-3.132
	C2347.358,656.923,2346.052,657.273,2345.404,657.444 M209.092,657.879c-1.34,0.461-0.529,2.492,0.768,1.929
	C211.422,659.449,210.466,657.06,209.092,657.879 M378.308,660.371c0.725,0.009,2.167,0.034,2.893,0.043
	c-0.034-0.7-0.111-2.116-0.145-2.825C380.006,658.365,379.093,659.313,378.308,660.371 M913.101,659.56
	c0.794,0.879,2.987-0.111,2.108-1.306C914.406,657.35,912.324,658.391,913.101,659.56 M929.169,659.21
	c-0.631,1.246,1.178,3.063,2.355,1.988C932.514,659.893,930.236,658.34,929.169,659.21 M1909.026,658.801
	c-1.041,0.239-2.022,2.236-0.52,2.543C1910.281,661.727,1911.39,658.067,1909.026,658.801 M2083.004,660.763
	c0.393,0.742,0.828,1.485,1.289,2.236c1.118-1.306,2.193-2.671,3.012-4.198C2085.828,659.398,2084.395,660.081,2083.004,660.763
	 M2153.865,660.379c0.776,0.887,2.953-0.085,2.082-1.289C2155.136,658.118,2153.071,659.227,2153.865,660.379 M382.592,660.806
	c0.887,2.517,3.541,2.338,5.76,2.219c-1.015-1.161-2.057-2.287-3.055-3.439C384.393,659.978,383.488,660.388,382.592,660.806
	 M1902.234,659.628c-0.452,1.459-0.939,2.91-1.382,4.378c1.058-0.068,2.116-0.239,3.192-0.503c0.486-1.178,0.179-2.517,0.324-3.738
	C1903.829,659.731,1902.763,659.662,1902.234,659.628 M2078.003,659.611c-0.939,0.973-1.869,1.937-2.791,2.893
	c-0.077,0.606-0.213,1.809-0.281,2.406c2.398-0.119,4.113-1.852,5.939-3.208c0.009-0.427,0.017-1.289,0.026-1.724
	C2079.906,659.85,2078.942,659.731,2078.003,659.611 M2100.028,659.688c-1.766,0.247-1.485,2.961,0.393,2.244
	C2102.161,661.779,2101.811,658.92,2100.028,659.688 M2229.76,659.816c-1.468,1.195,0.418,3.115,1.579,1.536
	C2233.139,660.277,2230.886,658.425,2229.76,659.816 M915.337,661.395c0.384,1.323,1.57,2.091,2.611,2.901
	c0.469-1.306,0.171-2.654-0.06-3.96C916.992,660.601,916.139,660.951,915.337,661.395 M1578.53,660.61
	c-1.118,0.862,0.162,3.081,1.442,2.176C1581.039,661.907,1579.785,659.662,1578.53,660.61 M2352.819,662.683
	c1.638,1.528,3.738,0.905,5.726,0.572c0.154,0.521,0.461,1.562,0.623,2.082c-2.031,1.323-1.493,3.465-1.007,5.461
	c1.562-0.137,3.396,0.102,4.625-1.161c-0.298-0.529-0.904-1.579-1.203-2.099c1.186-0.333,2.483-0.614,3.678-1.092
	c-0.222-0.401-0.674-1.195-0.896-1.587c-1.579,0.811-2.773,0.145-3.422-1.348c0.64-0.708,1.306-1.408,1.997-2.099
	C2359.561,660.925,2355.746,660.567,2352.819,662.683 M258.253,661.471c0.23,0.708,0.461,1.434,0.7,2.167
	c-0.964,0.256-1.92,0.538-2.867,0.828c-0.358-0.495-0.725-0.981-1.075-1.459c-1.143,0.845-2.304,1.664-3.473,2.483
	c0.802,1.263,1.766,2.398,2.782,3.507c0.589,0,1.758,0.009,2.338,0.017c-0.35-1.254-0.7-2.509-1.033-3.746
	c5.207,2.575,10.704-0.606,11.46-2.918c-0.991,0.055-2.661,0.232-3.593,0.623l0.905-1.536
	C262.349,661.574,260.292,661.736,258.253,661.471 M892.57,661.173c0.256,1.818,0.922,3.226,2.936,2.381
	c1.963,0.887,4.096,0.845,6.204,0.691c0.026-0.734,0.085-2.21,0.111-2.944c-1.476,1.246-3.567,0.171-4.881,1.63
	C896.137,661.164,894.157,661.352,892.57,661.173 M910.498,662.333c-0.282,0.7-0.555,1.408-0.819,2.116
	c0.631,0.077,1.894,0.213,2.517,0.282c-0.145-1.135-0.282-2.261-0.401-3.379C911.471,661.599,910.822,662.094,910.498,662.333
	 M925.457,664.373c1.186-0.23,2.364-0.461,3.575-0.657c0.256-0.41,0.777-1.22,1.033-1.63
	C928.06,661.958,926.123,662.248,925.457,664.373 M2229.325,666.557c1.937-1.101,4.864-1.86,4.702-4.676
	C2231.936,662.7,2229.316,663.946,2229.325,666.557 M2242.978,663.255c0.495,0.7,1.024,1.399,1.579,2.116
	c-1.579,0.512-5.274,3.558-1.417,3.959c-2.227,1.451-4.42,3.14-7.134,3.473c1.075,1.237,2.338,2.321,3.806,3.14
	c1.024-0.631,1.928-1.476,2.824-2.313c1.408,0.282,2.876,0.299,4.173-0.495c-0.632-0.913-1.237-1.818-1.826-2.722
	c0.017-0.597,0.034-1.792,0.051-2.389l-1.766,0.981c0.239-0.751,0.708-2.253,0.947-3.004c0.512,0.102,1.528,0.29,2.04,0.393
	c0.102-0.657,0.299-1.954,0.401-2.603C2245.726,662.094,2244.335,661.77,2242.978,663.255 M2347.341,663.093
	c-0.35,2.253-0.017,4.591,2.415,5.444c0.734-1.707,0.529-3.567,0.503-5.359C2349.252,663.135,2348.279,663.11,2347.341,663.093
	 M2136.584,666.199c1.656-0.102,3.038-0.845,3.72-2.441C2138.428,663.639,2137.003,664.339,2136.584,666.199 M2153.233,664.347
	c-0.051,0.555-0.162,1.673-0.222,2.236c1.135-0.606,2.347-1.237,2.577-2.662C2154.999,664.031,2153.822,664.245,2153.233,664.347
	 M1571.021,665.252c0.521,0.947,1.118,1.843,2.116,2.415c0.043-0.768,0.128-2.287,0.171-3.046
	C1572.736,664.774,1571.593,665.09,1571.021,665.252 M1878.323,664.697c-0.768,1.946-2.082,3.576-3.866,4.685
	c0,1.314,0.589,3.592,2.372,2.952c0.768-1.229,1.673-2.705,0.367-3.942c1.118-1.288,2.389-2.44,3.61-3.644
	C1880.183,664.731,1878.946,664.706,1878.323,664.697 M2154.53,669.407c0.742-0.393,1.485-0.811,2.244-1.254
	c0.094,0.64,0.281,1.92,0.375,2.569c1.033-0.913,1.963-2.039,2.714-3.226c-0.487-0.043-1.459-0.136-1.946-0.179
	c0.333-0.956,0.683-1.911,1.041-2.859C2156.518,664.825,2154.743,667.027,2154.53,669.407 M2494.276,664.757
	c-0.409,1.348,0.103,1.801,1.528,1.357C2496.179,664.74,2495.667,664.287,2494.276,664.757 M245.222,665.602
	c0.503,2.236,2.867,3.063,4.838,3.575C250.155,666.421,247.1,666.387,245.222,665.602 M896.188,665.747
	c-1.169,0.555-1.34,3.123,0.452,2.586C898.244,668.085,897.903,664.817,896.188,665.747 M902.434,665.704
	c0.256,1.186,0.606,2.355,1.032,3.516c-0.427-0.043-1.28-0.119-1.707-0.162c0.017,0.7,0.06,2.099,0.077,2.799
	c1.741-1.118,2.688-2.91,3.294-4.838C904.226,666.566,903.322,666.131,902.434,665.704 M2354.338,665.653
	c-0.034,0.708-0.094,2.116-0.119,2.825c1.28-0.401,2.184-1.348,2.671-2.603C2356.25,665.815,2354.978,665.704,2354.338,665.653
	 M911.915,667.59c0.427,1.075,0.879,2.15,1.331,3.234c0.512-1.195,1.041-2.381,1.545-3.576
	C914.074,667.334,912.631,667.505,911.915,667.59 M2080.094,667.206c0.094,1.109,0.264,2.236,0.435,3.371
	c0.785-0.666,1.587-1.323,2.423-1.971C2081.98,668.136,2081.007,667.667,2080.094,667.206 M2225.903,668.315
	c1.007,0.674,2.048,1.365,3.089,2.082c0.913-0.307,1.852-0.597,2.833-0.862c0.589-0.555,1.203-1.092,1.843-1.621
	c0.085,0.572,0.256,1.724,0.341,2.304c1.084-0.486,1.442-1.545,1.681-2.628C2232.388,667.138,2229.103,667.53,2225.903,668.315
	 M1914.957,668.46c-1.502,1.178,0.469,3.243,1.579,1.545C1918.037,668.836,1916.066,666.975,1914.957,668.46 M2238.293,670.32
	c1.05,0.649,2.654-1.178,2.04-2.219C2239.053,667.146,2237.611,669.16,2238.293,670.32 M747.034,671.694
	c1.331-0.154,2.611-0.597,3.789-1.28c-0.017,0.512-0.034,1.528-0.051,2.039c0.725-0.043,2.185-0.12,2.91-0.162
	c-0.759-1.408-2.244-2.074-3.499-2.918C748.851,669.791,747.128,669.894,747.034,671.694 M2101.188,672.172
	c1.348,0.188,2.765,0.384,4.164,0.606c0.811-1.263,2.185-2.304,2.236-3.925c-0.888,0.29-1.741,0.623-2.552,1.007
	C2103.142,668.537,2102.093,670.961,2101.188,672.172 M2073.233,670.38c-0.35,1.536-0.802,3.038-1.118,4.582
	c1.374-1.314,4.386-2.705,3.072-5.043C2074.701,670.039,2073.719,670.269,2073.233,670.38 M2118.383,674.041
	c1.485-0.384,2.961-0.811,4.48-1.178c0.41-1.109,0.717-2.253,1.195-3.345C2121.028,668.597,2119.671,671.993,2118.383,674.041
	 M2148.975,671.182c-0.828,0.606-1.587,1.246-2.321,1.911c1.033,0.316,2.099,0.631,3.192,0.939
	c-0.051-0.606-0.145-1.826-0.196-2.432c1.28-0.034,2.585-0.068,3.917-0.085c-0.009-0.478-0.034-1.434-0.043-1.911
	C2151.97,670.073,2150.46,670.619,2148.975,671.182 M894.208,673.785c0.973,0.341,1.852-0.845,2.21-1.621
	C896.213,669.339,892.877,672.095,894.208,673.785 M1878.921,672.667c-0.051,1.263,1.451,0.794,2.253,1.178
	c0.043-0.631,0.128-1.877,0.179-2.509C1880.499,671.746,1879.697,672.189,1878.921,672.667 M2159.462,672.77
	c-0.589-0.077-1.758-0.222-2.347-0.299c-0.06,0.674-0.188,2.031-0.256,2.705c1.528-0.367,3.063-0.776,4.616-1.169
	c0.162-0.896-0.034-1.809-0.17-2.68C2160.845,671.686,2159.923,672.411,2159.462,672.77 M2386.492,674.015
	c1.314,0.503,3.217,0.563,3.738-1.109c-0.657-0.358-1.98-1.067-2.645-1.417C2387.021,672.241,2386.441,673.026,2386.492,674.015
	 M898.628,672.693c-1.34,1.101,0.367,3.14,1.382,1.408C901.589,673.034,899.738,671.362,898.628,672.693 M2107.853,672.454
	c0.009,0.657,0.051,1.331,0.137,2.022c1.459,0.119,2.91,0.324,4.42,0.435c0.546-0.751,1.024-1.544,1.425-2.389
	C2111.821,672.445,2109.824,672.437,2107.853,672.454 M2125.585,672.369c-0.034,0.734-0.111,2.193-0.145,2.927
	c-1.135,0.427-2.116-0.282-2.867-0.998c-0.555,0.956-1.024,1.937-1.519,2.91c1.177,0.751,2.62,1.348,3.089,2.842
	c0.196-0.179,0.589-0.555,0.785-0.734c1.126,1.51,2.304,0.179,3.447-0.478c1.732-0.026,3.354-0.717,4.932-1.451
	c-0.444-0.939-0.862-1.86-1.254-2.773c-1.289,0.299-2.526,0.631-3.738,1.024c0.572-0.717,1.109-1.451,1.715-2.168
	C2128.521,673.094,2127.061,672.735,2125.585,672.369 M2154.837,672.514c-0.819,0.444-2.142,1.289-1.724,2.372
	C2154.428,675.927,2157.209,672.078,2154.837,672.514 M2329.583,672.42c-0.23,0.282-0.708,0.853-0.947,1.135
	C2329.455,676.285,2332.851,671.464,2329.583,672.42 M2349.525,673.358c-0.418,0.282-1.263,0.853-1.681,1.143
	c0.811,0.734,1.57,2.261,2.919,1.237C2350.532,674.843,2350.933,673.094,2349.525,673.358 M182.733,674.152
	c0.64,2.782,4.07,1.34,6.059,1.246C187.315,673.384,184.789,674.186,182.733,674.152 M1563.196,674.118
	c-0.009,0.631-0.026,1.894-0.034,2.526c0.606-0.017,1.818-0.043,2.424-0.06c-0.009-0.589-0.026-1.766-0.034-2.347
	C1564.962,674.203,1563.785,674.152,1563.196,674.118 M1921.451,677.796c1.212-0.93,2.074-2.193,2.586-3.635
	C1921.937,674.195,1921.22,675.918,1921.451,677.796 M179.038,674.963c-0.427,1.331,0.068,1.801,1.485,1.408
	C180.949,675.014,180.454,674.545,179.038,674.963 M1866.086,674.98c0.469,1.937,1.826,3.61,3.686,4.403
	c0.137-1.092,0.29-2.167,0.461-3.234c-0.486-0.265-1.451-0.794-1.929-1.05C1867.75,675.065,1866.641,675.005,1866.086,674.98
	 M2104.354,675.108c-0.819,1.169,1.331,2.133,2.134,1.237C2107.349,675.099,2105.105,674.212,2104.354,675.108 M263.168,676.516
	c0.683,1.579,1.579,3.055,2.398,4.574c1.613,0.137,2.637-0.768,3.268-2.159C266.846,678.35,265.003,677.437,263.168,676.516
	 M724.42,676.721c-0.119,1.254,0.094,2.492,1.101,3.371c0.418-0.742,0.853-1.476,1.212-2.236
	C725.965,677.446,725.197,677.062,724.42,676.721 M741.385,676.959c-0.768,1.212,1.212,2.688,2.27,1.869
	C744.405,677.651,742.383,676.209,741.385,676.959 M893.056,682.096c2.807-0.316,3.772-3.49,3.695-5.896
	C894.507,677.173,892.962,679.647,893.056,682.096 M906.94,676.908c0.12,0.973,0.58,1.869,0.939,2.799
	c0.469-0.171,1.391-0.503,1.852-0.674C909.141,677.864,908.604,675.935,906.94,676.908 M2076.629,679.374
	c1.732-0.171,2.987-1.408,3.976-2.748C2078.524,676.345,2076.433,676.84,2076.629,679.374 M2329.489,679.101
	c0.742,0.256,1.51,0.521,2.304,0.794c0.026-1.169,0.077-2.338,0.145-3.49C2330.394,676.507,2330.035,677.958,2329.489,679.101
	 M195.482,680.689c-1.212,0.461-2.423,0.913-3.635,1.365c-0.026,0.521-0.077,1.57-0.094,2.099c1.468,1.118,3.209,2.842,5.094,1.468
	c-0.043,0.239-0.137,0.717-0.179,0.964c-1.442,0-2.876,0.026-4.301,0.068c-0.282,2.517,2.022,2.133,3.755,2.057
	c0.145,0.734,0.29,1.476,0.427,2.227c0.99,0.691,1.946,1.451,2.987,2.091c2.236-1.485,2.995-4.471,3.567-6.972
	c-1.212-0.034-2.423-0.06-3.635-0.068c0-0.333,0-1.007,0.009-1.34c2.039-0.077,2.244-2.091,2.782-3.61
	c-1.604-0.009-3.191,0.145-4.719,0.64c0.444-1.476,0.879-2.953,1.033-4.471C197.222,678.018,195.78,678.999,195.482,680.689
	 M1561.634,677.975c-0.068,0.495-0.213,1.485-0.29,1.98c0.589,0.034,1.758,0.085,2.347,0.111c-0.153-0.751-0.256-1.476-0.35-2.21
	C1562.914,677.881,1562.061,677.949,1561.634,677.975 M1922.458,678.47c-0.188,0.418-0.563,1.254-0.742,1.664
	c0.392,0.794,0.802,1.596,1.237,2.415c-1.741,0.41-3.482,0.802-5.18,1.22c0.649,1.903,2.586,3.797,4.736,2.697
	c-0.009,0.597-0.034,1.792-0.043,2.389c1.331-0.623,2.773-1.212,3.601-2.526c-0.794-0.111-2.389-0.341-3.192-0.452
	c0.563-1.186,1.109-2.381,1.775-3.516c-0.35-0.196-1.041-0.58-1.382-0.776c0.367-0.649,0.759-1.289,1.195-1.911
	c1.169-0.375,2.381-0.802,2.381-2.278C1925.35,677.693,1923.9,678.077,1922.458,678.47 M233.37,680.893
	c0.657-0.026,1.954-0.068,2.611-0.094c0.043-0.631,0.145-1.911,0.196-2.543C234.581,678.24,233.711,679.477,233.37,680.893
	 M258.185,678.299c-0.427,1.51,0.666,2.705,1.331,3.942c0.674-0.256,1.348-0.486,2.022-0.717c0.12-0.512,0.367-1.527,0.495-2.039
	C260.753,679.05,259.499,678.555,258.185,678.299 M163.157,679.468c-0.947,0.529-1.963,1.05-2.458,2.082
	c2.133,0.23,4.25,0.597,6.323,1.195c-0.375-0.794-0.751-1.579-1.118-2.355C164.984,680.074,164.07,679.784,163.157,679.468
	 M225.707,679.255c-0.316,2.893,3.209,2.372,3.831,0.222C228.258,679.383,226.987,679.306,225.707,679.255 M2118.929,679.315
	c-0.06,0.631-0.188,1.903-0.256,2.534c0.572-0.077,1.707-0.239,2.278-0.316c0-0.512,0.009-1.536,0.009-2.048
	C2120.456,679.443,2119.441,679.357,2118.929,679.315 M2012.902,680.177c-0.026,0.563-0.068,1.707-0.094,2.27
	c1.067,0.009,2.159,0.017,3.268,0.043c0.043-0.589,0.128-1.775,0.171-2.372C2015.104,680.125,2013.995,680.142,2012.902,680.177
	 M2138.086,680.04c0.111,0.614,0.333,1.835,0.444,2.449c1.485,0.657,1.689-1.297,2.381-2.176
	C2140.203,680.245,2138.794,680.108,2138.086,680.04 M380.134,681.09c0.333,1.015,1.05,1.818,1.792,2.594
	c0.444-0.316,1.34-0.93,1.792-1.246c-0.239-0.375-0.725-1.135-0.964-1.519C382.097,680.962,380.792,681.047,380.134,681.09
	 M730.505,682.83c0.93,0.666,1.929,1.297,2.961,1.86c0.546,0.802,1.092,1.621,1.647,2.458c1.553,0.836,3.098,1.724,4.702,2.5
	c-0.614-2.15-1.306-4.42-3.328-5.658c-0.555-1.186-1.092-2.424-2.108-3.285C732.919,681.038,731.588,681.815,730.505,682.83
	 M906.163,683.035c0.358-0.145,1.067-0.427,1.416-0.563C907.972,680.125,904.474,681.456,906.163,683.035 M210.825,681.832
	c-1.553,0.324-1.417,2.978,0.375,2.398C212.864,684.017,212.659,680.996,210.825,681.832 M723.209,684.085
	c0.597,0.734,1.212,1.468,1.852,2.193c0.572-0.819,1.152-1.63,1.749-2.424c0.486,3.243,5.914,2.389,5.06,6.11
	c1.451,0.333,2.338,1.502,3.072,2.722c0.427,0.145,1.289,0.427,1.724,0.572c1.408,1.084,3.081,1.724,4.898,1.434
	c-1.135-1.579-2.526-2.927-3.951-4.232c-0.452-0.111-1.374-0.333-1.835-0.444c-1.783-2.628-3.482-5.367-6.042-7.313
	c-0.213,0.666-0.64,2.005-0.862,2.671c-0.401-1.092-0.819-2.142-1.459-3.089C726.289,683.436,724.676,683.692,723.209,684.085
	 M739.576,683.547c0.384,1.246,2.313,1.323,3.26,0.674C743.416,682.557,739.925,681.883,739.576,683.547 M2129.331,682.668
	c-0.324,1.954-0.401,3.917-0.461,5.905c-0.742-0.811-1.442-1.613-2.116-2.424c-0.444-0.077-1.331-0.247-1.784-0.324
	c-0.862-0.93-1.852-1.101-2.944-0.521c-0.529,1.442,1.306,2.261,1.946,3.371c-0.93,0.265-1.835,0.546-2.713,0.828
	c-1.203-0.819-2.389-1.698-3.798-1.98c0.64,2.364,3.132,3.26,4.676,4.966c1.101-0.137,2.219-0.273,3.362-0.418l-0.026,0.777
	c-2.586,0.171-6.178,1.135-7.535-1.894c-1.016,0.683-0.922,1.869-0.811,2.953c-1.246,0.29-2.475,0.597-3.678,0.896
	c0.862,0.879,1.698,1.741,2.628,2.637c-0.776-0.034-2.313-0.094-3.081-0.128c-1.34-1.809-3.294-3.038-5.589-2.645
	c0.042,2.381,1.425,4.207,3.26,5.606c8.12-2.044,5.408,2.497,12.168-2.091c2.108,0.009,5.06-0.051,5.521-2.731
	c0.939-0.043,1.903-0.068,2.901-0.077c-0.461-1.485-0.043-2.406,1.254-2.782c0.034,0.683,0.102,2.048,0.136,2.731
	c0.316-0.137,0.939-0.41,1.254-0.546c-0.051-1.169-0.085-2.33-0.119-3.49c-1.69-0.896-3.652-0.939-5.436-1.357
	c2.167-0.435,4.275-1.263,5.982-2.731c-1.237-1.51-4.804-0.299-4.702-2.62c0.512-0.154,1.528-0.469,2.04-0.623
	C2130.927,683.436,2130.091,683.035,2129.331,682.668 M204.945,685.041c0.905,0.708,2.833,0.546,3.123-0.742
	C207.804,682.549,204.262,683.436,204.945,685.041 M234.667,683.701c-1.673,0.205-1.05,2.799,0.529,2.193
	C236.655,685.553,236.228,683.095,234.667,683.701 M711.689,683.829c-1.041,1.212,1.186,2.304,1.869,1.067
	C714.487,683.692,712.371,682.694,711.689,683.829 M897.246,683.342c-0.947,1.399-1.246,3.627-3.243,3.857
	c1.741,1.51,3.029,3.413,3.029,5.82c0.7-1.365,1.86-2.748,1.033-4.326C897.033,686.926,898.935,684.742,897.246,683.342
	 M1577.114,683.522c-1.365,0.794-1.246,2.347-1.34,3.712c1.016-0.316,1.937-0.811,2.773-1.485
	C1578.624,684.75,1578.283,683.539,1577.114,683.522 M2506.991,685.476c0.981,0.666,2.62-0.904,2.048-1.937
	C2507.861,682.455,2505.907,684.273,2506.991,685.476 M913.365,685.032c-0.794,1.749-2.287,2.987-3.482,4.446
	c2.423,0.597,4.89-0.956,5.513-3.294C915.797,684.767,914.099,685.262,913.365,685.032 M2081.741,688.838
	c1.032-0.401,2.022-0.981,2.987-1.596c1.587-0.495,3.541-1.092,3.388-3.2C2085.624,685.049,2083.234,686.56,2081.741,688.838
	 M2261.256,684.494c-1.391,0.58-1.041,3.098,0.7,2.611C2263.663,686.824,2262.724,684.042,2261.256,684.494 M242.193,685.39
	c-0.99,1.323,1.382,2.5,2.202,1.323C245.24,685.416,243.012,684.273,242.193,685.39 M1581.798,687.31
	c0.725,1.135,2.116,1.135,3.311,1.519c-0.026-0.606-0.085-1.809-0.111-2.415C1583.812,686.397,1582.515,686.141,1581.798,687.31
	 M1868.296,685.911c1.041,1.289,2.193,2.526,3.379,3.729c0.316-0.486,0.947-1.468,1.271-1.954
	C1871.582,686.653,1869.943,686.107,1868.296,685.911 M2013.636,688.01c1.05,0.188,2.133,0.384,3.234,0.58
	c0-0.64,0.009-1.929,0.009-2.569C2015.454,686.15,2014.285,686.722,2013.636,688.01 M2088.943,686.107
	c-1.101,0.299-1.86,2.398-0.324,2.645C2090.394,689.043,2091.068,685.484,2088.943,686.107 M2489.95,686.935
	c-0.23,0.247-0.691,0.759-0.921,1.007C2489.813,690.792,2493.252,686.44,2489.95,686.935 M725.171,687.771
	c-0.026,0.418-0.068,1.263-0.094,1.681c-0.486,0.034-1.442,0.094-1.92,0.128c0.196,1.775,1.237,3.14,2.773,4.011
	c-1.357,0.179-2.688,0.41-4.019,0.683c-0.341-0.282-1.033-0.845-1.374-1.126c-0.358,0.205-1.058,0.597-1.408,0.794
	c0.606,0.768,1.229,1.536,1.852,2.313c-0.649,0.009-1.963,0.009-2.611,0.009c-0.247,0.461-0.742,1.374-0.99,1.835
	c0.998,2.33,3.814,1.348,5.589,0.623c0.41,2.671-2.893,4.915-0.606,7.245c-0.725-0.222-2.185-0.657-2.91-0.879
	c-1.05,0.657-2.074,1.34-3.063,2.074c-2.458,0.512-4.915,1.178-7.441,1.109c-0.128,0.572-0.392,1.715-0.521,2.287
	c-1.28,0.222-3.046-0.094-3.2,1.655c2.935,0.341,5.564,1.92,8.542,2.039l-0.137,0.683c-1.57,0.051-3.012,0.563-4.258,1.485
	c-1.152,0.137-2.304,0.256-3.439,0.384c-0.64-0.7-1.34-1.306-2.099-1.826c-1.178,0.811-2.202,1.903-3.644,2.21
	c0.06,0.555,0.196,1.664,0.265,2.219c1.195,0.239,1.655-1.22,2.458-1.519c0.845,0.512,1.63,1.135,2.449,1.732
	c0.051-0.563,0.162-1.707,0.213-2.278c0.649,0.648,1.297,1.306,1.954,1.98c0.529,0.009,1.596,0.043,2.125,0.051
	c0.393-0.811,0.794-1.63,1.186-2.432c1.493-0.034,3.004-0.077,4.506-0.128c1.024-2.987,3.917-5.18,7.066-5.393
	c-0.162,0.606-0.503,1.835-0.666,2.44c2.125-0.546,2.739-2.551,2.953-4.497c1.527-1.51,3.123-3.021,3.951-5.06
	c-0.546-0.947-1.075-1.894-1.587-2.842c-1.434,0.905-1.818,2.261-1.152,4.062c-0.811-0.853-1.587-1.707-2.372-2.551
	c0.862-1.775,1.809-3.507,2.842-5.188c0.7,1.075,1.485,2.15,1.656,3.482c1.664,0.009,3.328,0.017,5.009,0.051
	c0.068-0.435,0.213-1.306,0.282-1.741c1.161,0.427,2.321,0.862,3.499,1.297c0.717,0.597,1.451,1.203,2.202,1.792
	c0.452-1.792-1.152-2.731-2.167-3.78c0.58-0.043,1.741-0.128,2.321-0.171c-1.331-1.05-2.782-1.92-4.258-2.714
	c-0.009-0.555-0.026-1.673-0.043-2.227c-1.203-0.034-2.406-0.06-3.601-0.085c-0.538-1.459-1.186-2.867-1.894-4.224
	c0.503-0.008,1.519-0.034,2.031-0.043c-0.324-0.401-0.973-1.22-1.289-1.621C728.465,687.78,726.818,687.78,725.171,687.771
	 M2263.893,687.899c-1.101,0.691-0.162,2.799,1.067,1.877C2266.189,689.102,2265.114,686.841,2263.893,687.899 M702.276,688.88
	c-0.981,1.314,1.399,2.381,2.21,1.263C705.468,688.804,703.07,687.746,702.276,688.88 M268.032,691.773
	c-0.563-0.333-1.681-1.007-2.236-1.348l0.162,1.502c-0.503,0.102-1.493,0.29-1.988,0.384c-0.785,0.649-1.579,1.289-2.372,1.929
	c0.589,1.758,2.458,1.178,3.2-0.128c1.382-0.162,4.241,0.589,3.678-1.946c0.751,0.213,2.253,0.64,2.995,0.845
	c-0.631-1.306-1.476-2.475-2.389-3.584C268.732,690.203,268.382,690.988,268.032,691.773 M1566.874,689.776
	c-0.017,0.393-0.051,1.169-0.077,1.562c1.007,0.512,1.681,1.348,2.031,2.5c1.186-1.024,2.364-2.099,3.14-3.499
	C1570.244,690.161,1568.563,689.99,1566.874,689.776 M1582.02,690.758c-0.401,0.666-0.785,1.34-1.144,2.022
	c0.657,0.026,1.963,0.085,2.62,0.111c-0.017-0.538-0.051-1.613-0.077-2.15c0.691,0.128,2.057,0.393,2.748,0.529
	c-0.051-0.546-0.154-1.647-0.205-2.193C1584.606,689.58,1583.317,690.186,1582.02,690.758 M274.739,690.476
	c0.009,0.563,0.026,1.681,0.034,2.244c0.742,0.196,1.485,0.41,2.236,0.623c0.026-0.469,0.085-1.399,0.119-1.869
	C276.326,691.133,275.524,690.801,274.739,690.476 M2008.448,690.195c0.29,1.63,1.4,2.901,2.466,4.13
	c0.546-0.904,1.109-1.809,1.69-2.705C2011.213,691.116,2009.822,690.621,2008.448,690.195 M42.641,691.654
	c-1.152,1.417,1.536,2.167,2.202,0.981C45.884,691.193,43.315,690.434,42.641,691.654 M202.931,691.133
	c-0.051,0.614-0.145,1.843-0.188,2.458c1.237,0.273,2.415,0.768,3.524,1.374c-2.5,0.828-5.163,1.348-7.799,0.845
	c0.222,0.512,0.666,1.528,0.887,2.031c-0.58-0.452-1.758-1.365-2.347-1.818c0.128,0.623,0.393,1.869,0.521,2.492
	c-1.34,0.23-2.679,0.486-4.019,0.751c-0.521,2.193,1.374,2.825,3.174,2.082c2.466,0.06,4.941,0.154,7.407-0.051
	c1.51-0.896,5.521-0.495,3.081-3.089c2.355-2.876,6.46-2.918,8.9-5.504c-1.434-0.026-2.987,0.452-4.267-0.435
	C208.87,691.739,205.901,691.406,202.931,691.133 M250.377,691.031c0.171,2.449,2.987,2.91,4.958,2.935
	c0.631,0.196,1.894,0.572,2.526,0.759c-0.691-0.939-1.51-1.758-2.33-2.569C252.493,691.605,252.504,691.208,250.377,691.031
	 M1570.918,693.403c-0.051,0.751-0.137,2.253-0.179,3.004c0.742-0.043,2.219-0.111,2.953-0.154
	C1573.035,695.042,1572.113,694.043,1570.918,693.403 M202.095,694.811C202.581,695.298,202.581,695.298,202.095,694.811
	 M1555.601,694.854c-1.067,1.161,0.896,3.174,2.099,2.133C1558.545,695.827,1556.762,693.949,1555.601,694.854 M1579.375,694.794
	c-0.29,1.135-0.555,2.287-0.708,3.456c-0.614,0.845-1.212,1.69-1.801,2.543c-0.009,1.195,0.009,2.398,0.06,3.61
	c2.79-2.577,4.053-6.374,6.571-9.207C1582.182,694.555,1580.732,694.777,1579.375,694.794 M193.519,695.272
	c-0.435,1.306,0.085,1.749,1.553,1.314C195.516,695.255,194.995,694.82,193.519,695.272 M2135.91,695.605
	c-0.29,0.367-0.879,1.092-1.178,1.459c-1.476,0.35-2.876,0.905-2.714,2.705c1.553-1.169,3.396,0.23,5.018-0.717
	c0.307,0.128,0.93,0.375,1.246,0.495c2.142-0.828,3.951-2.261,5.239-4.173c-1.101,0.137-2.176,0.299-3.226,0.486
	c-0.179-0.051-0.546-0.162-0.725-0.213C2138.394,696.637,2137.114,695.946,2135.91,695.605 M2478.396,697.798
	c1.749,0.316,3.55,0.657,5.359,0.913c0.35,0.853,0.717,1.724,1.109,2.611c-0.956-0.213-1.886-0.427-2.782-0.631
	c-1.101,0.649-2.176,1.331-2.91,2.372c2.577,0.265,5.368,0.35,7.168-1.877c0.188,0.341,0.546,1.007,0.734,1.34
	c-0.102-0.41-0.299-1.22-0.401-1.621c1.741-0.623,2.645-2.091,2.91-3.866c-1.63,0.119-3.174,0.299-4.728,0.529
	C2481.5,695.057,2480.302,694.054,2478.396,697.798 M2496.051,695.528c-1.681,0.435-0.896,2.765,0.666,2.5
	C2498.355,697.764,2497.681,694.751,2496.051,695.528 M209.434,698.455c0.597,0.051,1.809,0.145,2.406,0.196
	c1.399,2.398,4.318,0.631,5.871-0.631c0.384,0.273,1.161,0.802,1.545,1.067c0.7-0.811,1.357-1.655,1.92-2.551
	c-2.551,0.307-5.581-0.29-7.347,2.048c-0.316-0.538-0.964-1.621-1.28-2.159C211.217,696.544,210.168,697.38,209.434,698.455
	 M188.749,698.114c-0.077,0.887-0.162,1.758-0.247,2.662c1.109-0.35,2.219-0.7,3.328-1.058c-0.017-0.683-0.06-2.065-0.077-2.756
	C190.746,697.329,189.747,697.721,188.749,698.114 M1562.359,698.745c0.358,1.331,3.072,1.169,2.432-0.461
	C1564.39,696.919,1561.958,697.235,1562.359,698.745 M2101.589,697.619c-0.666,1.254,2.295,2.159,2.372,0.7
	C2103.304,697.465,2102.519,697.235,2101.589,697.619 M2020.591,698.148c-1.229,0.777-0.085,3.14,1.246,2.133
	C2022.972,699.479,2021.905,697.064,2020.591,698.148 M340.907,700.955c3.331,2.374,3.816,3.037,5.666,3.2
	c-0.623-0.768-1.254-1.51-1.903-2.236c-0.145-1.075-0.29-2.133-0.427-3.191C343.117,699.453,342.008,700.187,340.907,700.955
	 M1557.666,698.993c0.265,1.246,1.391,1.741,2.458,2.227c0.128-0.597,0.375-1.775,0.503-2.372
	C1559.885,698.882,1558.409,698.958,1557.666,698.993 M1586.475,699.052c-1.212,0.777-2.381,1.801-2.44,3.354
	c1.212-0.708,2.432-1.434,3.558-2.304C1587.977,698.95,1587.601,698.6,1586.475,699.052 M2128.828,698.643
	c0.342,1.28,0.708,2.543,1.118,3.831c0.87-0.563,1.741-1.126,2.662-1.672C2131.345,700.034,2130.099,699.308,2128.828,698.643
	 M183.356,699.871c-1.135,1.425,1.911,3.226,2.569,1.562C186.01,700.23,184.38,699.044,183.356,699.871 M357.052,703.191
	c1.152,0.384,2.287,0.845,3.49,1.109c0.794-0.41,1.348-1.16,2.005-1.741c-0.683-0.085-2.048-0.248-2.731-0.333
	c0.41-0.964,0.819-1.929,1.22-2.884C358.938,699.598,358.17,701.715,357.052,703.191 M1870.575,700.119
	c-1.135,1.075,0.836,2.722,1.963,2.014C1873.297,701.075,1871.676,699.274,1870.575,700.119 M1920.435,701.971
	c-0.034-0.486-0.103-1.459-0.136-1.946C1917.576,697.9,1918.071,702.798,1920.435,701.971 M2490.291,699.999
	c-0.64,0.393-1.681,2.048-0.444,2.295C2491.332,702.466,2492.305,699.018,2490.291,699.999 M2491.298,703.822
	c-1.562,0.495-2.697,1.655-3.55,2.987c1.655-0.307,3.063-1.297,4.463-2.21c-0.137,0.469-0.401,1.399-0.529,1.869
	c0.29,0.034,0.888,0.102,1.178,0.128c0.922-1.69,1.903-3.345,2.833-5.043c-0.853-0.734-1.715-1.399-2.585-1.997
	C2491.955,700.708,2491.46,702.244,2491.298,703.822 M216.73,701.484c-0.282,0.367-0.853,1.109-1.135,1.476
	c-2.452,0.304-8.845,1.409-7.919,3.712c0.614-0.171,1.852-0.503,2.466-0.674c2.757,0.756,5.627-0.805,11.162-2.295
	c1.271-1.058,3.575-1.946,1.818-3.823C221.457,701.715,219.025,702.039,216.73,701.484 M354.014,700.861
	c-1.545,0.222-0.657,2.244,0.623,1.621C356.036,702.218,355.166,700.324,354.014,700.861 M717.03,700.802c0,0.521,0,1.562,0,2.091
	c1.681-0.273,3.345-0.606,5-1.015C721.084,699.837,718.763,700.759,717.03,700.802 M1926.741,700.759
	c-1.05,1.067,0.785,2.884,1.877,1.869C1929.6,701.57,1927.774,699.906,1926.741,700.759 M2066.679,700.486
	c-0.205,0.341-0.615,1.024-0.819,1.365c0.521,0.358,1.579,1.084,2.108,1.451c0.879-0.649,2.492-1.058,1.937-2.475
	C2068.804,700.665,2067.729,700.554,2066.679,700.486 M2510.515,702.995c-0.444-0.657-1.34-1.971-1.783-2.628
	C2506.394,701.732,2508.749,705.529,2510.515,702.995 M1865.028,700.759c1.314,1.784,2.637,3.593,3.866,5.47
	c-0.947,1.126-0.58,2.423-0.145,3.669c1.058,0.017,2.142,0.043,3.243,0.085c0.009-0.427,0.009-1.263,0.017-1.69
	c-0.58-0.017-1.741-0.051-2.33-0.077c0.802-0.76,1.647-1.502,2.492-2.253c0.452,0.179,1.357,0.521,1.809,0.691
	c-0.247-0.563-0.742-1.698-0.99-2.261c0.154-0.401,0.461-1.186,0.623-1.587c-1.195,0.614-2.278,1.365-3.294,2.193
	c0.077-0.461,0.222-1.382,0.298-1.843C1868.126,703.481,1867.563,700.042,1865.028,700.759 M2070.545,704.855
	c1.229-0.034,2.483-0.068,3.763-0.094c-0.111-0.597-0.316-1.792-0.418-2.381C2072.533,702.918,2070.605,702.995,2070.545,704.855
	 M2079.283,706.673c2.458-0.725,4.182-2.731,5.445-4.864C2082.765,703.114,2079.403,703.874,2079.283,706.673 M202.104,703.925
	c0.384,0.316,1.161,0.939,1.553,1.254l-0.051,0.606c-1.143-0.452-2.295-0.905-3.456-1.306c-0.034,2.043,6.493,5.374,7.663-0.307
	c-0.717-0.068-2.159-0.213-2.876-0.29C204.305,702.901,201.489,701.544,202.104,703.925 M336.674,704.548
	c1.741,1.263,3.985,1.28,5.965,1.988c0.444-0.734,0.896-1.459,1.348-2.176c-1.57-0.7-3.115-1.408-4.659-2.116
	C338.389,702.935,337.186,703.421,336.674,704.548 M354.244,703.174c-1.152,0.009-2.645,1.638-0.785,1.997
	C355.277,706.485,356.77,702.551,354.244,703.174 M1916.006,703.302c-1.63,0.299-1.263,2.731,0.427,2.33
	C1918.225,705.521,1917.824,702.295,1916.006,703.302 M2017.331,703.182c-0.384,0.836-0.734,1.681-1.075,2.526
	c0.555-0.009,1.655-0.034,2.21-0.043c0.265-0.845,0.563-1.681,0.879-2.509C2018.842,703.165,2017.835,703.174,2017.331,703.182
	 M2100.693,704.155c0,0.393,0,1.178,0,1.562c1.92-0.358,5.103,0.913,6.11-1.374C2105.242,702.329,2102.716,704.266,2100.693,704.155
	 M2107.767,703.208c-0.734,1.161,1.357,2.167,2.159,1.254C2110.771,703.208,2108.527,702.312,2107.767,703.208 M2496.811,702.807
	c-0.06,0.631-0.179,1.894-0.248,2.526c1.844,0.973,3.635,2.074,5.487,3.098C2501.504,705.657,2499.149,703.976,2496.811,702.807
	 M260.045,703.652c0.06,0.657,0.162,1.971,0.213,2.628c0.657,0.077,1.98,0.23,2.637,0.307
	C262.827,704.906,261.316,704.3,260.045,703.652 M272.76,703.942c-0.29,2.62,2.253,1.587,3.857,1.519
	C275.712,704.198,274.108,704.232,272.76,703.942 M2126.865,704.275c1.536,1.732,3.84,2.295,6.084,2.534
	c-0.367-0.947-0.708-1.877-1.024-2.807C2130.219,704.053,2128.521,704.13,2126.865,704.275 M268.911,705.034
	c-0.87-0.222-2.705,1.28-1.152,1.698C268.715,707.159,270.464,705.367,268.911,705.034 M330.837,705.794
	c0.051,0.521,0.154,1.545,0.205,2.065c1.545-0.452,3.439-0.802,3.328-2.833C333.184,705.256,332.006,705.512,330.837,705.794
	 M729.549,704.812c0.026,0.7,0.094,2.099,0.128,2.807c1.195-0.435,1.988-1.306,2.295-2.526
	C731.366,705.026,730.155,704.88,729.549,704.812 M1903.019,704.949c-1.084,0.282-1.442,2.057-0.179,2.372
	C1904.58,707.739,1905.032,704.206,1903.019,704.949 M2482.696,708.029c0.657,0.068,1.98,0.205,2.646,0.273
	c0.768-0.836,1.579-1.664,2.33-2.551C2485.572,704.94,2483.183,705.657,2482.696,708.029 M218.377,706.314
	c-0.649,0.836-1.306,1.673-1.971,2.517c0.58,0.247,1.732,0.751,2.304,0.998c-1.587,0.12-3.183,0.196-4.779,0.29
	c-0.034-0.546-0.102-1.647-0.137-2.193c-1.22,0.41-2.21,1.143-2.611,2.406c1.101,0.35,2.185,0.751,3.26,1.203
	c8.64-0.135,4.382-2.037,6.963-3.721c0.137,0.819,0.401,2.449,0.538,3.268c2.278-1.434,4.898-2.773,6.05-5.359
	c-1.57-0.017-3.14,0.119-4.676,0.444C221.67,705.99,220.015,706.101,218.377,706.314 M2108.698,705.828
	c0.154,1.135,0.768,1.331,1.835,0.597C2110.327,705.282,2109.713,705.077,2108.698,705.828 M2495.181,705.529
	c-0.495,0.828-0.956,1.673-1.382,2.517c3.955,0.734,3.346,0.935,5.692,1.246C2499.251,706.997,2496.853,706.459,2495.181,705.529
	 M209.092,707.637c-1.203,0.785-0.034,3.14,1.28,2.15C211.465,708.959,210.415,706.57,209.092,707.637 M734.609,711.673
	c0.896,0.375,1.809,0.734,2.739,1.092c-0.393-0.981-0.828-1.92-1.314-2.825c0.452-0.887,0.896-1.783,1.314-2.679
	C734.464,706.886,734.447,709.625,734.609,711.673 M1559.578,708.524c-0.435,3.729,3.985,3.302,6.443,3.985
	c0.691-0.981,1.459-1.903,2.261-2.807c-0.461-0.862-0.922-1.724-1.34-2.56c-1.212,1.425-1.946,3.601-4.045,3.908
	c0.589-1.203,1.596-2.21,1.911-3.533C1562.965,707.099,1561.242,707.884,1559.578,708.524 M1956.591,707.637
	c-1.101,0.87,0.154,3.14,1.468,2.253C1959.066,708.994,1957.82,706.826,1956.591,707.637 M2376.192,708.354
	c-0.674,1.647,1.843,1.664,2.423,0.555C2379.162,707.406,2376.516,706.877,2376.192,708.354 M1934.788,709.924
	c-1.86-0.077-3.388,0.35-3.166,2.534c0.998,0.299,2.014,0.597,3.046,0.913c-0.324-0.853-0.631-1.698-0.913-2.534
	c0.333-0.06,0.998-0.171,1.331-0.222c-0.068,0.751-0.205,2.253-0.264,3.004c1.323-0.7,2.637-1.485,3.669-2.628
	c-0.768-0.785-1.51-1.562-2.227-2.33C1935.735,709.07,1935.241,709.489,1934.788,709.924 M2500.651,708.831
	c0.102,2.15,1.971,3.405,3.865,3.985C2504.482,710.641,2502.639,709.156,2500.651,708.831 M1957.598,712.381
	c1.075,1.033,2.893-0.828,2.082-1.963C1958.605,709.719,1956.847,711.246,1957.598,712.381 M2122.317,709.821
	c-0.469,1.212-0.93,2.432-1.331,3.678c1.212-0.657,2.466-1.28,3.627-2.082C2124.894,709.847,2123.332,710.018,2122.317,709.821
	 M970.914,714.327c0.904-0.256,1.792-0.58,2.671-0.964c0,0.521,0.009,1.553,0.017,2.065c0.862-0.393,1.656-0.896,2.381-1.519
	c0.12-1.186-0.614-2.236-0.904-3.32C973.158,710.828,969.557,711.63,970.914,714.327 M2111.94,711.076
	c-1.476,1.766,2.56,3.038,2.279,0.776C2113.621,710.931,2112.862,710.675,2111.94,711.076 M256.051,711.801
	c-0.649,1.579,2.867,2.5,3.183,0.785C258.918,711.272,256.998,711.144,256.051,711.801 M261.982,711.477
	c-0.068,0.7-0.196,2.091-0.265,2.79c0.913,0.307,1.894,0.683,2.748-0.043C264.44,712.714,263.04,712.177,261.982,711.477
	 M2024.004,711.844c-1.067,1.323,1.34,2.423,2.176,1.28C2027.162,711.81,2024.781,710.743,2024.004,711.844 M2107.955,711.75
	c-0.819,1.101,1.109,2.321,1.971,1.4C2110.805,711.98,2108.766,710.769,2107.955,711.75 M223.633,712.398
	c-0.503,0.879-0.99,1.775-1.459,2.688c1.374-0.017,2.748-0.043,4.122-0.094C225.766,713.789,224.691,713.073,223.633,712.398
	 M1971.473,714.088c-0.922,0.068-1.809,0.145-2.662,0.222c-0.384,0.563-1.152,1.69-1.536,2.253
	c-0.025-0.486-0.085-1.459-0.111-1.946c-0.205,0.085-0.606,0.256-0.802,0.333c-0.58-0.188-1.724-0.563-2.296-0.759
	c0.341,1.485,1.28,2.56,2.936,2.568c0.188,0.717,0.401,1.451,0.631,2.193c-1.937,0.384-2.108,2.483-2.842,3.942
	c1.169,0.93,2.398,1.809,3.687,2.628c0.051-0.742,0.145-2.227,0.196-2.961c0.486,0.725,0.99,1.459,1.519,2.202
	c0.904-0.853,1.843-1.707,2.782-2.568c-0.751-0.555-1.476-1.092-2.176-1.613c1.903,0.35,2.696-1.468,3.789-2.603
	c-1.058-0.913-2.295-1.186-3.558-0.614c-0.205-0.29-0.631-0.887-0.836-1.186c1.604-1.143,3.729-1.877,4.599-3.814
	C1973.06,711.989,1971.422,711.886,1971.473,714.088 M344.849,714.421c0.171,0.401,0.521,1.203,0.7,1.604
	c0.393-0.222,1.178-0.666,1.57-0.896c1.527,0.401,3.115,0.418,4.625-0.137C350.43,712.373,347.076,715.035,344.849,714.421
	 M1862.204,714.429c0.862,1.015,1.869,1.903,2.961,2.722c-0.026-0.691-0.077-2.082-0.111-2.773
	C1864.234,713.456,1863.066,714.114,1862.204,714.429 M1927.45,713.252c0.316,0.461,0.947,1.382,1.254,1.852
	c-0.614,0.734-1.109,1.544-1.485,2.423c0.504,0.009,1.519,0.034,2.031,0.051c-0.026-0.384-0.068-1.161-0.094-1.553
	c1.664,1.084,3.925,0.256,4.608-1.553c-0.708-0.102-2.125-0.316-2.833-0.427C1929.847,713.439,1928.644,713.149,1927.45,713.252
	 M218.889,716.759c0.58-0.068,1.749-0.213,2.33-0.282c-0.034-0.546-0.102-1.638-0.137-2.185
	C220.032,714.822,219.017,715.445,218.889,716.759 M1936.41,713.815c-0.572,0.435-1.698,1.314-2.27,1.749
	c1.229,1.655,1.826,3.968,3.797,4.958c0.256-1.186,0.529-2.364,0.819-3.533c0.7-0.128,2.082-0.384,2.773-0.503
	C1940.42,714.685,1938.244,714.344,1936.41,713.815 M2094.438,714.933c-1.314,1.033-2.654,2.005-3.985,2.987
	c0.333,0.64,0.99,1.937,1.323,2.586c1.801-1.084,3.004-2.859,4.48-4.326C2095.607,715.743,2095.019,715.334,2094.438,714.933
	 M2102.084,716.563c-0.427-0.213-1.288-0.631-1.715-0.845c0.094,0.691,0.264,2.082,0.35,2.782c2.159,0.043,4.369,0.026,6.494-0.58
	c1.536,0.751,3.072,0.085,4.583-0.469l-0.026-0.546c-1.604-0.461-3.183-0.973-4.736-1.485c-0.657,0.171-1.289,0.35-1.903,0.529
	C2103.927,715.129,2102.349,714.591,2102.084,716.563 M184.883,716.836c0.435,0.759,0.853,1.527,1.271,2.313
	c1.391-0.324,3.106-2.543,4.241-0.486c0.759-1.212,0.171-2.099-0.93-2.765C188.041,716.87,186.53,718.286,184.883,716.836
	 M215.731,717.066c0.546,1.041,0.717,2.867,2.364,2.253c0.068-0.751,1.596-2.039,0.299-2.483
	C217.728,716.895,216.397,717.006,215.731,717.066 M239.326,716.529c0.265,2.219,1.169,4.54,3.866,3.814
	c-0.367,1.126-0.742,2.261-1.109,3.405c1.357-0.136,2.782-0.102,3.985-0.845c1.57,0.213,4.002,1.63,3.925-1.032
	c-1.613-0.751-3.319-2.68-4.907-0.683c-0.742-0.845-1.536-1.647-2.62-1.98C242.108,717.612,241.024,716.546,239.326,716.529
	 M243.209,717.075c-0.751,1.067,1.05,2.628,1.946,1.587C245.837,717.587,244.096,716.153,243.209,717.075 M2068.122,719.72
	c1.109-0.64,2.048-1.536,3.029-2.389c-0.077,0.785-0.239,2.355-0.316,3.14c0.529-0.256,1.587-0.759,2.116-1.016
	c0.367,0.213,1.101,0.649,1.468,0.862c-0.375-1.075-0.657-2.108-0.981-3.157c-1.22,0.307-2.432,0.154-3.559-0.265
	C2068.745,717.467,2067.968,718.346,2068.122,719.72 M2137.83,717.459c-0.614,0.094-1.843,0.299-2.449,0.401
	c0.905,2.799,5.043,1.519,4.813-1.297C2139.605,716.785,2138.419,717.237,2137.83,717.459 M1939.405,720.258
	c1.826,0.026,2.859-1.357,3.746-2.765C1941.282,717.638,1939.243,717.919,1939.405,720.258 M188.911,720.556
	c1.126,1.775,2.731,3.14,4.523,4.215c2.953-0.333,5.922-0.247,8.875-0.401c-0.811-1.451-1.143-3.516-2.773-4.25
	c-0.811,0.563-1.562,1.22-2.33,1.852c-0.128-0.538-0.375-1.63-0.503-2.176c-0.717-0.546-1.434-1.092-2.133-1.647
	C192.452,718.346,190.532,719.199,188.911,720.556 M235.597,718.457c-1.289,0.35-1.126,2.722,0.384,2.295
	C237.542,720.514,237.346,717.655,235.597,718.457 M280.952,719.865c-0.486-0.171-1.468-0.521-1.954-0.691l0.324,1.587
	c-0.555-0.384-1.664-1.161-2.219-1.553c-0.597,0.495-1.186,1.016-1.749,1.562c1.143,0.939,2.219,1.971,3.473,2.765
	c2.398,0.213,4.813,0,7.211-0.111c-1.399-1.877-2.884-3.738-3.652-5.982C281.72,718.124,281.242,718.935,280.952,719.865
	 M725.35,718.619c-0.64,1.63,2.833,2.458,3.183,0.785C728.158,718.158,726.281,717.885,725.35,718.619 M1567.275,718.491
	c-0.299,0.862-0.572,1.741-0.819,2.628c1.724,0.009,2.739-1.246,3.661-2.517C1569.408,718.568,1567.983,718.517,1567.275,718.491
	 M1570.918,718.798c-1.118,1.408,1.476,2.21,2.202,1.024C1574.221,718.406,1571.593,717.629,1570.918,718.798 M1962.01,718.79
	c-0.188,1.246,0.29,2.389,1.152,3.319c0.316-1.203,0.597-2.406,0.904-3.601C1963.554,718.577,1962.53,718.722,1962.01,718.79
	 M1913.259,719.37c0.162,0.41,0.478,1.229,0.631,1.63c-0.683,0.64-1.34,1.297-1.963,1.971c1.545-0.043,3.166-0.324,4.489-1.237
	C1916.826,719.66,1914.598,719.805,1913.259,719.37 M1930.095,719.49c0.017,1.34,0.06,2.697,0.264,4.053
	c0.777-0.503,1.408-1.161,2.159-1.749c-0.042-0.572-0.128-1.715-0.171-2.287C1931.785,719.498,1930.658,719.49,1930.095,719.49
	 M2034.748,721.742c0.657,0.026,1.971,0.077,2.637,0.102c-0.009-0.683-0.017-2.039-0.026-2.722
	C2035.857,719.31,2035.046,720.351,2034.748,721.742 M2494.25,719.601c-1.101,1.289,1.246,2.125,1.929,0.947
	C2497.263,719.276,2494.856,718.56,2494.25,719.601 M697.003,720.625c-0.99,0.853,0.341,2.782,1.476,1.98
	C699.418,721.725,698.086,719.899,697.003,720.625 M1919.257,720.292c-1.109,0.606-1.152,3.055,0.546,2.62
	C1921.442,722.63,1920.973,719.319,1919.257,720.292 M1976.115,720.181c-0.009,0.606-0.034,1.809-0.043,2.415
	c-1.451,0.367-1.843,1.596-1.707,2.961c1.263-0.273,1.783-1.297,2.116-2.432c1.067-0.64,1.434-1.707,1.519-2.901
	C1977.532,720.215,1976.585,720.198,1976.115,720.181 M2151.21,720.437c-1.092,1.34,1.391,2.278,2.236,1.237
	C2154.47,720.317,2151.979,719.362,2151.21,720.437 M1551.326,722.673c1.34-0.034,2.705-0.017,4.088,0.068
	c0.751,0.751,1.374,1.621,2.065,2.475c2.227-0.648,5.521-0.358,5.948-3.345c-2.406-0.282-4.497,0.802-6.562,1.826
	c-0.026-0.964,2.065-3.089,0.145-3.021C1555.055,720.957,1551.855,720.147,1551.326,722.673 M1923.26,724.465
	c1.698-0.239,3.354-0.7,5.06-1.058c0-0.333,0-0.99,0.009-1.314C1926.264,720.454,1921.98,721.051,1923.26,724.465 M257.476,721.845
	c0.009,0.375,0.026,1.109,0.034,1.485c0.811,0.35,1.621,0.725,2.441,1.109c0.145-0.546,0.444-1.638,0.597-2.185
	C259.516,722.118,258.492,721.956,257.476,721.845 M732.194,722.203c-1.033,0.811-0.205,2.944,1.178,2.295
	C735.155,723.859,733.696,720.821,732.194,722.203 M1863.339,722.143c0.452,1.22,2.184,2.765,3.242,1.135
	C1867.179,721.29,1864.371,722.399,1863.339,722.143 M1938.458,726.709c0.444,0.137,1.34,0.418,1.783,0.555
	c-0.043-0.563-0.119-1.698-0.162-2.27c1.161,0.708,2.347,1.442,3.533,2.176c-0.051-1.536-0.051-3.072-0.009-4.591
	C1941.035,722.442,1939.226,724.473,1938.458,726.709 M1598.421,723.586c-0.068,0.7-0.213,2.082-0.282,2.773
	c0.947-0.367,2.236-0.461,2.535-1.681C1599.915,724.285,1599.164,723.927,1598.421,723.586 M1907.157,725.873
	c1.4,0.418,4.053,0.154,2.927-1.954C1908.471,722.911,1907.891,724.849,1907.157,725.873 M1919.411,723.79
	c-0.802,0.418-1.971,2.244-0.512,2.603C1920.811,726.965,1921.647,722.903,1919.411,723.79 M288.58,724.857
	c-0.836,1.118,0.802,3.277,2.014,2.15C291.627,725.821,289.766,723.893,288.58,724.857 M329.31,724.669
	c-1.203,1.195,0.717,3.115,1.903,1.894C332.348,725.48,330.402,723.517,329.31,724.669 M697.225,724.78
	c-1.135,1.382,1.442,2.21,2.15,1.05C700.493,724.379,697.941,723.534,697.225,724.78 M1564.843,724.311
	c-0.649,1.229-1.306,2.458-1.877,3.721c1.399-0.324,2.466-1.263,3.362-2.355C1565.952,725.335,1565.218,724.652,1564.843,724.311
	 M1590.229,728.868c2.031-0.708,6.007-0.358,6.17-3.157C1594.308,726.555,1590.383,725.89,1590.229,728.868 M1991.535,725.83
	C1990.178,728.398,1994.3,726.026,1991.535,725.83 M2022.647,725.941c-0.87,1.263,0.708,1.835,1.69,2.039
	c0.094-0.708,0.282-2.125,0.375-2.833C2024.201,725.352,2023.168,725.745,2022.647,725.941 M691.814,726.001
	c0.026,0.614,0.085,1.835,0.12,2.449c1.553,0.981,3.618,1.792,4.318-0.58C695.313,726.393,693.325,726.359,691.814,726.001
	 M345.66,727.221c-1.237,0.785,0.034,3.038,1.297,2.057C348.169,728.433,346.982,726.094,345.66,727.221 M689.314,727.391
	c-0.905,0.7,0.085,2.603,1.152,1.809C691.285,728.492,690.338,726.649,689.314,727.391 M980.821,726.82
	c0.597,1.519,1.34,2.987,2.125,4.437c0.418-0.265,1.263-0.802,1.681-1.075C983.672,728.697,982.989,726.606,980.821,726.82
	 M1921.775,727.11c-1.032,0.265-2.048,2.304-0.469,2.543C1923.081,730.011,1924.096,726.291,1921.775,727.11 M2102.152,730.643
	c1.929-0.691,3.832-1.493,5.726-2.338C2106.018,726.478,2102.127,727.912,2102.152,730.643 M279.808,727.895
	c-0.427,1.331,0.068,1.843,1.468,1.544C281.702,728.091,281.216,727.571,279.808,727.895 M707.968,727.536
	C707.195,734.207,714.398,729.997,707.968,727.536 M723.558,728.159c-0.708,0.751-0.751,2.679,0.486,2.918
	C725.521,730.754,725.154,727.536,723.558,728.159 M261.154,728.979c-1.015,1.084,0.828,2.825,1.869,1.784
	C264.149,729.73,262.153,728.031,261.154,728.979 M1587.098,729.021c0.034,0.58,0.111,1.732,0.154,2.313
	c1.459-0.196,2.364-1.152,2.568-2.628C1589.146,728.791,1587.78,728.945,1587.098,729.021 M1956.181,728.979
	c-0.597,0.785-1.246,2.705,0.111,3.089C1958.016,732.025,1958.007,727.835,1956.181,728.979 M1902.515,733.51
	c1.263-0.503,2.543-1.041,3.823-1.613c-0.179,1.613,0.469,2.782,2.108,3.174c0.154-0.657,0.469-1.971,0.623-2.628
	c-1.22-0.546-1.775-1.545-1.664-2.995C1905.34,730.02,1902.626,731.001,1902.515,733.51 M1940.369,731.931
	c0.819,0.811,2.816-0.324,2.15-1.442C1941.624,729.636,1939.567,730.728,1940.369,731.931 M2115.371,732.17
	c1.28,0.666,2.534,1.476,3.516,2.62c2.466-0.222,4.625-1.579,6.059-3.593c-2.313-0.085-5.052,1.877-6.716-0.538
	C2117.256,731.155,2116.267,731.641,2115.371,732.17 M1546.308,730.967c0.171,2.108,2.176,2.074,3.806,2.338
	c0.922,0.998,1.852,2.031,2.458,3.294c1.024,2.432,3.362,3.891,4.992,5.897c1.314-0.367,2.637-0.76,3.977-1.126
	c-0.734-0.811-1.451-1.604-2.133-2.389c-0.504,0.017-1.493,0.06-1.988,0.077c-0.828-1.655-1.681-3.285-2.415-4.966
	c-0.444-0.068-1.323-0.222-1.766-0.299C1552.461,730.54,1548.783,731.377,1546.308,730.967 M1583.718,730.975
	c-0.051,0.529-0.154,1.579-0.213,2.108c-0.495,0.034-1.493,0.102-1.988,0.145c-0.034,0.452-0.102,1.357-0.136,1.809
	c0.606-0.06,1.246-0.094,1.92-0.12c0.93-0.58,1.869-1.152,2.85-1.707C1585.758,732.051,1584.76,731.428,1583.718,730.975
	 M1911.501,730.967c-0.367,1.22-0.623,2.483-0.922,3.729c1.357,0.085,2.978-0.171,3.396-1.741
	C1913.481,731.931,1912.311,731.598,1911.501,730.967 M1868.211,734.73c1.229-0.085,2.475-0.171,3.746-0.265
	c-0.375-0.896-0.734-1.775-1.075-2.645C1869.807,732.614,1868.783,733.501,1868.211,734.73 M2138.104,733.399
	c0.981,0.631,1.946,1.536,3.26,1.314c0.674-0.768,1.144-1.655,1.408-2.654C2141.082,732.059,2139.546,732.639,2138.104,733.399
	 M336.265,733.032c-0.239,0.247-0.717,0.759-0.964,1.015C336.094,736.829,339.635,732.409,336.265,733.032 M967.432,735.515
	c1.442,0.478,2.961,0.145,4.446,0.034c-0.393-0.93-0.785-1.86-1.161-2.773C969.267,733.092,966.861,733.45,967.432,735.515
	 M1544.823,733.16C1545.071,735.933,1547.392,731.803,1544.823,733.16 M1588.42,733.407c0.307,0.777,0.811,1.399,1.519,1.886
	c1.348,0.145,1.698-1.493,2.517-2.253C1591.091,733.143,1589.743,733.262,1588.42,733.407 M202.675,735.14
	c0.41,0.375,1.229,1.135,1.638,1.51c0.683,2.125,3.319,3.226,5.299,3.354c2.039,0,0.606-2.108-0.597-1.809
	c-0.128-0.657-0.375-1.971-0.495-2.628c-1.374,0.247-2.423,1.246-3.627,1.886c0.358-0.623,1.067-1.869,1.417-2.492
	C205.116,733.45,203.844,733.843,202.675,735.14 M209.528,734.244c0.435,0.614,0.887,1.22,1.365,1.826
	c1.382,0.239,2.159-1.092,2.603-2.167C212.233,734.483,210.867,734.508,209.528,734.244 M278.741,734.184
	c-0.512,1.391-0.051,1.852,1.357,1.374C280.585,734.175,280.124,733.715,278.741,734.184 M366.012,734.065
	c0.094,0.563,0.265,1.681,0.358,2.236c0.794-0.529,1.587-1.058,2.381-1.579C367.838,734.491,366.925,734.278,366.012,734.065
	 M978.628,735.148c1.195,1.229,2.304,2.748,4.096,3.149c-0.009-0.58-0.017-1.741-0.026-2.321c-0.631-0.102-1.894-0.299-2.526-0.401
	c0.171-0.486,0.503-1.459,0.666-1.946C980.045,734.065,979.32,734.585,978.628,735.148 M1940.958,734.978
	c0.444,1.476,2.961,0.521,2.696-0.836C1942.861,733.664,1941.077,733.894,1940.958,734.978 M341.632,735.677
	c0.512,0.887,1.05,1.775,1.604,2.662c0.785-0.947,1.587-1.869,2.372-2.807C344.277,735.558,342.955,735.609,341.632,735.677
	 M349.875,735.694c-1.152,1.075,0.811,3.081,1.869,1.869C353.05,736.377,351.061,734.5,349.875,735.694 M1545.301,736.684
	c0.017,0.427,0.043,1.28,0.06,1.698c1.126-0.162,2.244-0.324,3.43-0.461c-0.145-0.555-0.444-1.673-0.589-2.227
	C1547.179,735.891,1546.231,736.317,1545.301,736.684 M1963.537,735.899c-1.237,0.811-0.034,3.208,1.297,2.099
	C1965.918,737.162,1964.791,734.867,1963.537,735.899 M353.331,736.522c-1.101,0.998,0.708,3.012,1.621,1.613
	C356.105,737.017,354.432,735.199,353.331,736.522 M1579.554,738.553c0.99,0.273,2.022,0.614,3.089,0.828
	c1.024-0.887-0.247-2.321-0.154-3.362C1581.389,736.718,1580.416,737.589,1579.554,738.553 M1982.694,739.091
	c0.538-0.35,1.621-1.033,2.159-1.382c-0.256,2.167-0.136,4.352,0.316,6.494c1.604-1.545,2.048-5.299-0.051-6.417
	c0-0.384,0.009-1.152,0.009-1.536C1983.983,736.915,1982.72,737.572,1982.694,739.091 M213.956,738.263
	c0.034,1.186,0.085,2.389,0.145,3.601c1.57-0.631,2.697-1.86,3.396-3.362c-0.512-0.213-1.527-0.657-2.039-0.879
	C215.083,737.785,214.332,738.101,213.956,738.263 M1909.069,738.365c-1.254,0.734-0.256,3.226,1.135,2.091
	C1911.398,739.697,1910.408,737.205,1909.069,738.365 M202.334,739.509c-1.357,1.092,0.597,2.731,1.698,1.63
	C205.321,740.14,203.315,738.493,202.334,739.509 M984.482,738.707c-0.043,1.229-0.008,2.458,0.102,3.695
	c1.135-0.068,2.108-0.555,2.935-1.442c0.196,0.324,0.589,0.964,0.785,1.289c0.922-0.683,1.852-1.382,2.628-2.244
	c-0.7-0.008-2.099-0.026-2.799-0.034C986.948,739.423,985.719,739.014,984.482,738.707 M1546.112,739.859
	c-0.102,0.666-0.307,1.988-0.401,2.654c1.391-0.111,2.842-0.205,4.233-0.589c1.092-0.802,2.253-1.655,2.313-3.157
	C1549.01,739.895,1550.707,739.447,1546.112,739.859 M197.564,740.396c0.742,0.597,1.502,1.195,2.261,1.784
	c0.128-0.555,0.393-1.681,0.529-2.236C199.654,740.055,198.264,740.285,197.564,740.396 M220.126,739.492
	c-0.043,0.734-0.128,2.185-0.171,2.918c0.998,0.776,3.132,0.811,3.226,2.33c-0.444,0.077-1.34,0.222-1.783,0.299
	c-0.913-0.572-1.894-0.964-2.935-1.186c-0.947,1.673-1.843,3.379-2.466,5.205c0.981,0.427,1.988,0.776,3.038,1.041
	c2.125-1.169,4.497-2.483,5.325-4.924c0.452-0.137,1.357-0.427,1.809-0.572c-0.717-1.485-1.51-3.029-3.422-1.997
	C222.635,741.011,221.577,739.953,220.126,739.492 M317.039,745.252c2.381-0.631,4.087-2.551,4.318-5.018
	C319.471,741.378,317.184,742.777,317.039,745.252 M2060.809,740.695c-0.58,1.126-0.23,1.519,1.067,1.195
	C2062.43,740.738,2062.071,740.345,2060.809,740.695 M1630.387,742.734c0.486,2.065,3.226,1.067,4.761,1.382
	C1634.389,742.376,1631.744,740.678,1630.387,742.734 M2062.797,743.161c-1.528,0.06-3.063,0.111-4.54,0.213
	c0.683,2.236,3.311,1.536,5.111,1.544c1.673-0.145,1.28-2.125,1.476-3.285C2064.136,742.129,2063.454,742.641,2062.797,743.161
	 M2109.483,741.745c-1.152,1.075,0.768,3.046,1.843,1.818C2112.785,742.393,2110.635,740.524,2109.483,741.745 M201.839,742.453
	c-0.137,1.903,0.179,3.814,0.273,5.734c3.029,0.888,6.17,1.289,9.293,1.732c-0.247-0.469-0.742-1.399-0.99-1.869
	c0.913,0.674,1.801,1.408,2.748,2.065c2.466-0.666,2.637-3.174,2.807-5.291c-1.929,0.026-3.977,0.768-4.045,3.046
	c-1.058-0.247-2.091-0.58-3.081-0.998c0.64-0.094,1.937-0.299,2.577-0.393c-0.085-0.521-0.256-1.553-0.333-2.065
	c-0.495-0.068-1.476-0.196-1.963-0.256c-0.009,0.64-0.026,1.911-0.034,2.551c-2.142,0.759-3.772-0.538-5.265-1.86
	c0.205-1.135,0.35-2.27,0.41-3.405C203.64,741.693,202.436,742.197,201.839,742.453 M205.338,742.41
	c-0.435,1.357,0.06,1.801,1.493,1.348C207.241,742.376,206.746,741.924,205.338,742.41 M288.376,743.349
	c-0.333,1.741,2.039,1.493,3.081,2.193c0-0.555-0.017-1.673-0.026-2.236C290.406,743.238,289.374,743.05,288.376,743.349
	 M981.231,742.598c1.271,1.126,2.219,5.325,4.352,3.447c1.886-1.212-1.289-2.304-1.417-3.678
	C983.159,742.419,982.195,742.504,981.231,742.598 M996.198,745.021c2.031,0.247,3.968-0.495,3.968-2.85
	C998.315,742.214,997.257,743.75,996.198,745.021 M1981.457,743.434c-1.118,0.683-0.213,2.918,1.032,1.86
	C1983.667,744.612,1982.677,742.333,1981.457,743.434 M298.65,743.682c-0.384,0.836-0.768,1.681-1.152,2.534
	c2.338-1.007,4.301,0.7,6.357,1.553C303.155,745.329,301.329,743.451,298.65,743.682 M987.136,744.458
	c-0.862,0.879,0.358,2.833,1.502,2.014C989.508,745.593,988.211,743.673,987.136,744.458 M1546.889,743.98
	c-1.135,1.843-0.145,3.601,1.69,4.471c2.005-0.623,2.918-2.355,2.714-4.369c-0.896,0.452-1.775,0.913-2.628,1.391
	C1548.22,745.098,1547.332,744.347,1546.889,743.98 M1858.56,747.266c1.161,1.331,2.731-0.495,1.647-1.672
	C1859.234,743.656,1857.144,746.096,1858.56,747.266 M2103.279,744.91c-1.032,1.263-0.939,2.773-0.572,4.292
	c-0.333,0.708-0.64,1.434-0.922,2.159c0.58,0.145,1.732,0.418,2.304,0.555c-0.06-0.683-0.188-2.057-0.248-2.739
	c1.314-0.188,2.663-0.265,4.045-0.222C2106.923,747.027,2104.926,746.096,2103.279,744.91 M2107.776,745.124
	c-0.905,1.178,0.956,3.021,2.185,2.202C2110.891,746.148,2108.928,744.211,2107.776,745.124 M184.431,745.951
	c-1.084,1.297,1.203,2.509,1.971,1.195C187.503,745.815,185.182,744.535,184.431,745.951 M293.461,745.892
	c-1.126,1.161,0.922,3.021,2.014,1.886C296.602,746.6,294.588,744.757,293.461,745.892 M1866.283,745.892
	C1866.487,748.674,1868.826,744.526,1866.283,745.892 M2088.695,745.755l0.128,0.589c2.697,0.811-0.009,2.987-0.444,4.54
	c0.708,0.008,2.116,0.034,2.816,0.043c0.367-0.879,0.759-1.749,1.177-2.62c1.323,0.188,4.139,1.92,4.113-0.538
	C2094.31,745.943,2091.136,747.086,2088.695,745.755 M2147.976,748.127c1.459-0.094,2.441-1.084,2.449-2.603
	C2149.154,745.951,2146.671,746.259,2147.976,748.127 M285.688,747.539c-0.009,0.358-0.026,1.075-0.034,1.434
	c-0.836-0.256-1.69-0.469-2.543-0.64c-0.333,1.212,0.469,2.517,1.749,2.671c1.357-0.555,2.577-1.391,3.874-2.057
	c-0.239,2.227-2.313,2.867-4.147,3.362c-0.717,1.28-1.425,2.594-2.483,3.661c0.469,0.239,1.408,0.708,1.877,0.947
	c1.28-2.517,3.567-0.538,5.615-1.314c0.759,0.41,1.502,0.905,2.338,1.195c2.611-2.603,0.401-6.187,0.282-9.267
	C290.04,747.266,287.855,747.377,285.688,747.539 M1584.051,748.742c0.742,0.836,1.579,1.562,2.483,2.295
	c0.683-0.384,1.391-0.759,2.116-1.126c-0.009-0.623-0.034-1.886-0.043-2.517C1587.08,747.82,1585.545,748.264,1584.051,748.742
	 M1631.625,747.726c-0.427,1.306,2.449,1.229,1.997-0.051C1633.118,747.692,1632.12,747.718,1631.625,747.726 M1983.496,752.718
	c1.331-0.162,2.714-0.691,3.601-1.766c0.051-1.186,0.068-2.355,0.068-3.524C1985.766,749.066,1984.384,750.747,1983.496,752.718
	 M1952.759,750.824c0.725,0.026,2.159,0.06,2.884,0.085c-0.026-0.717-0.068-2.15-0.085-2.867
	C1954.039,748.281,1953.425,749.655,1952.759,750.824 M274.867,749.348c-1.186-0.051-2.876,1.997-1.118,2.543
	C275.26,752.522,276.642,749.706,274.867,749.348 M1546.59,749.228c0.145,0.384,0.444,1.135,0.589,1.519
	c1.451,0.998,3.755,3.627,5.402,1.621c0.461-1.417-1.4-1.229-2.142-1.673c-0.324-0.358-0.956-1.075-1.271-1.425
	C1548.527,749.262,1547.238,749.237,1546.59,749.228 M1859.78,750.517c-1.587,2.159-0.034,4.284,1.792,5.615
	c1.323-2.569,1.152-5.393-0.418-7.799C1859.439,748.546,1858.978,749.271,1859.78,750.517 M1920.136,753.051
	c1.016,0.418,2.057,0.845,3.106,1.306c-0.068-1.417-0.094-2.816-0.103-4.207C1922.133,751.123,1921.109,752.07,1920.136,753.051
	 M223.317,751.874c0.282,0.461,0.853,1.382,1.143,1.843c0.785-0.768,1.698-1.417,2.227-2.389
	C225.485,750.611,224.376,751.251,223.317,751.874 M1547.034,752.334c-1.852,2.577,1.57,4.156,3.243,5.504
	c1.348,0.009,1.519-1.63,2.031-2.526c-0.64-0.06-1.911-0.171-2.551-0.23C1548.826,754.143,1547.947,753.23,1547.034,752.334
	 M277.53,753.589c-0.845,1.118,1.152,2.321,1.929,1.271C280.405,753.674,278.306,752.539,277.53,753.589 M1947.895,754.28
	c0.265,1.749,3.789,1.033,3.166-0.64C1950.131,753,1948.194,752.991,1947.895,754.28 M2091.887,756.396
	c1.152,0.205,2.134,1.34,3.388,1.271c1.186-0.691,1.109-2.031,1.212-3.217C2094.865,754.809,2092.467,754.348,2091.887,756.396
	 M1863.663,755.432c-1.109,1.271,1.237,2.116,1.92,0.964C1866.709,755.125,1864.294,754.391,1863.663,755.432 M194.313,756.115
	c0.649,1.417,1.374,2.799,2.057,4.224c0.572-0.077,1.715-0.213,2.287-0.282c-0.179-0.947-0.469-1.809-1.451-2.125
	C196.454,756.951,195.721,755.833,194.313,756.115 M718.396,758.35c1.118,0.691,1.698-1.348,0.947-1.971
	C718.2,755.509,717.56,757.702,718.396,758.35 M984.38,758.129c1.084,1.237,1.843,3.081,3.721,3.268
	c-0.469-1.988-1.152-3.925-2.142-5.7C985.404,756.49,984.883,757.301,984.38,758.129 M992.282,755.731
	c0.102,1.306,0.282,2.637,0.265,3.968c-1.212-0.794-2.389-1.587-3.601-2.304c-0.111,2.287-0.154,4.582-0.154,6.886
	c1.135,0.435,2.287,0.896,3.43,1.365c-0.606,0.964-0.964,2.022-1.058,3.174c0.674,0.009,2.014,0.017,2.688,0.026
	c0.043-0.546,0.136-1.656,0.188-2.202c0.734,0.811,1.51,1.664,2.27,2.509c0.213-1,4.426-11.187-0.504-9.685
	c-0.657,1.109-1.152,2.295-1.672,3.473c-0.307-1.98,0.077-3.917,0.589-5.811C993.988,756.499,993.178,756.038,992.282,755.731
	 M1587.439,756.063c-0.333,0.563-1.007,1.69-1.34,2.253c0.427,0.393,0.862,0.802,1.323,1.22c0.862-1.101,1.724-2.21,2.492-3.396
	C1589.291,756.123,1588.053,756.081,1587.439,756.063 M2058.112,757.984c0.256,0.742,0.537,1.485,0.845,2.244
	c0.938,0.051,1.911,0.111,2.918,0.188c1.212,0.563,2.33,1.655,3.78,1.63c1.502-0.64,2.68-2.031,2.919-3.678
	c-1.05,0.64-2.04,1.34-3.03,2.031c0.23-0.922,0.495-1.826,0.794-2.722c-2.167,1.638-3.823-0.717-5.495-1.843
	C2059.904,756.533,2058.982,757.25,2058.112,757.984 M249.011,756.925c-0.478,1.374-0.026,1.843,1.374,1.391
	C250.88,756.925,250.419,756.465,249.011,756.925 M981.146,756.55c0.102,0.853,0.29,2.56,0.384,3.413
	c-0.631-0.384-1.903-1.161-2.543-1.553c0.23,1.638,1.169,2.901,2.534,3.814c0.998-0.256,2.039-0.546,2.492-1.604l-1.638-0.614
	c0.572-0.273,1.724-0.836,2.296-1.109C983.569,757.966,982.485,757.07,981.146,756.55 M2105.463,757.736
	c-0.734,0.384-1.451,0.785-2.142,1.186c1.195,2.057,3.285,2.534,5.572,2.321c-0.145,0.461-0.444,1.382-0.597,1.843
	c1.459-0.171,2.833-0.708,3.849-1.835c-0.777-0.06-2.33-0.171-3.106-0.23c1.203-1.323,2.748-2.534,2.995-4.429
	C2109.713,756.874,2106.488,762.096,2105.463,757.736 M202.53,757.668c-0.538,1.681-1.075,3.371-1.579,5.069
	c-0.623-0.23-1.246-0.444-1.86-0.64c-1.493,0.981-3.968,1.246-4.156,3.413c0.486-0.222,1.468-0.674,1.963-0.905
	c1.237,0.521,2.586,0.922,3.866,0.265c0.913,0.247,1.826,0.486,2.748,0.725c0.768-1.946,1.485-3.908,2.099-5.905
	C204.587,758.999,203.563,758.325,202.53,757.668 M326.289,760.245c1.306,0.026,2.611,0.051,3.925,0.068
	c-0.418-0.87-0.845-1.715-1.297-2.543C327.808,758.308,326.571,758.896,326.289,760.245 M1866.214,757.881
	c-1.024,0.06-1.784,2.116-0.427,2.202C1866.761,759.997,1867.563,757.949,1866.214,757.881 M998.426,759.554
	c0.546,2.295,1.596,4.437,2.492,6.63c1.417-0.896,2.637-1.169,2.714,0.947c0.375-0.017,1.126-0.043,1.502-0.06
	c-0.051-0.529-0.171-1.596-0.23-2.133c-0.904-0.887-2.142-1.8-0.725-2.927c-0.384-0.017-1.152-0.06-1.545-0.077
	C1002.249,760.407,999.799,757.745,998.426,759.554 M709.265,759.536c-0.486,1.382-0.051,1.886,1.323,1.51
	C711.074,759.63,710.639,759.127,709.265,759.536 M712.397,759.118c-0.034,0.674-0.094,2.039-0.128,2.714
	c1.246,0.401,2.492,0.819,3.763,1.195C715.273,761.346,714.155,759.818,712.397,759.118 M1553.997,759.647
	c-1.161,0.768-0.188,3.294,1.195,2.202C1556.241,761.055,1555.328,758.564,1553.997,759.647 M2113.289,760.108
	c0.734,0.99,1.493,1.971,2.304,2.97c0.896-0.845,1.783-1.698,2.697-2.56C2116.591,760.253,2114.969,759.869,2113.289,760.108
	 M209.016,760.561c-1.118,1.169,0.785,3.098,1.929,1.86C212.224,761.337,210.082,759.417,209.016,760.561 M1641.762,760.475
	c0,0.341,0,1.015,0,1.357C1643.563,763.163,1643.554,759.929,1641.762,760.475 M1606.912,761.559
	c-1.058,1.766,1.109,2.21,2.398,2.406C1609.506,762.19,1608.499,761.456,1606.912,761.559 M1946.684,761.499
	c0.102,1.348,0.666,2.475,2.27,2.458C1948.723,762.651,1948.561,760.313,1946.684,761.499 M2068.838,763.965
	c1.672-0.06,2.679-1.382,3.712-2.534C2070.545,760.629,2068.966,761.858,2068.838,763.965 M983.117,762.847
	c-0.358,0.836-0.7,1.69-1.041,2.543c1.092-0.009,2.202,0,3.319,0.017c-0.017-1.203-0.026-2.398-0.017-3.584
	C984.61,762.156,983.859,762.498,983.117,762.847 M2074.052,765.518c1.903-0.623,4.616-1.126,4.719-3.652
	C2076.365,761.619,2074.522,763.257,2074.052,765.518 M211.285,764.162c1.237,0.512,2.321,1.28,3.328,2.176
	c0.333-0.427,0.674-0.836,1.015-1.246c-1.263-0.452-2.859-0.503-3.337-1.98C211.951,763.453,211.618,763.795,211.285,764.162
	 M218.872,765.015c-4.904,0-1.624,2.261,1.911,5.675c0.845-0.521,1.698-1.015,2.551-1.502c-1.015-0.657-2.039-1.331-3.038-1.98
	c0.947-1.374,2.022-2.756,2.295-4.437C221.321,763.47,220.152,764.332,218.872,765.015 M1010.483,764.383
	c1.075,0.708,1.732,0.521,1.963-0.572C1011.354,763.086,1010.697,763.274,1010.483,764.383 M1558.963,764.802
	c-1.007,1.135,1.041,2.389,1.886,1.357C1561.677,765.058,1559.757,763.795,1558.963,764.802 M1610.598,765.553
	c-0.887,0.717-0.145,2.884,1.109,2.219C1612.689,767.046,1611.853,764.716,1610.598,765.553 M2041.941,766.594
	c-0.819,1.178,1.246,2.304,2.116,1.382C2044.714,766.833,2042.726,765.715,2041.941,766.594 M2089.284,766.38
	c0.154,1.101,0.777,1.28,1.877,0.546C2090.957,765.783,2090.334,765.604,2089.284,766.38 M205.662,767.003
	c-0.282,0.888-0.563,1.784-0.845,2.688c-0.794,0.026-2.381,0.077-3.174,0.094c1.092,1.314,2.688,1.749,4.318,1.954
	c-0.418,0.555-1.271,1.673-1.69,2.227c0.623-0.043,1.877-0.12,2.509-0.162c-0.179-0.666-0.521-1.988-0.7-2.645
	c1.22,0.845,2.449,1.69,3.678,2.534c-0.239-1.673,0.521-4.318-2.082-4.122c0.503-0.776,1.007-1.553,1.51-2.321
	C208.009,767.157,206.831,767.08,205.662,767.003 M2078.515,767.985c0,0.64-0.009,1.911-0.009,2.551
	c-1.024-0.12-2.014-0.23-2.978-0.333c-0.691-2.253-2.21-1.007-3.2,0.154c0.409,0.862,0.845,1.732,1.288,2.611
	c1.989,0.008,3.985,0.051,6.008,0.102c-0.051,0.521-0.162,1.553-0.213,2.074c1.493-0.418,2.509-1.451,2.884-3.004l1.195,0.196
	c-0.205,0.563-0.623,1.681-0.828,2.244c6.031,1.404,7.238,2.892,8.107,0.922c1.596-0.555,3.192-1.152,4.804-1.766
	c0-0.58,0.009-1.732,0.009-2.313c0.64-0.026,1.911-0.068,2.552-0.094c0-0.606,0-1.826,0-2.441c-1.374,0.009-2.714-0.017-4.028,0.085
	c-0.947,0.93-1.297,2.236-1.852,3.379c-0.103-1.254-0.171-2.509-0.213-3.746c-0.478,0.205-1.451,0.623-1.928,0.828
	c-0.546,0.051-1.639,0.145-2.185,0.188c-0.077-0.452-0.239-1.365-0.316-1.826c-1.425,0.495-2.773,1.143-4.045,1.92
	c0.094-0.435,0.273-1.297,0.367-1.732C2082.108,767.976,2080.307,767.976,2078.515,767.985 M1572.582,769.085
	c0.043,0.495,0.12,1.485,0.162,1.971c0.555,0.06,1.673,0.162,2.227,0.213c-0.017-0.529-0.034-1.579-0.043-2.108
	C1574.34,769.145,1573.163,769.102,1572.582,769.085 M1701.427,769.051c-1.015,1.331,1.4,2.389,2.296,1.374
	C1704.585,769.137,1702.246,768.053,1701.427,769.051 M1978.061,769.068c-1.28,0.358-1.382,3.072,0.333,2.253
	C1979.861,771.005,1979.776,768.275,1978.061,769.068 M1579.58,769.623c-1.485,0.435-0.973,2.918,0.614,2.475
	C1581.884,771.799,1581.312,768.812,1579.58,769.623 M347.529,770.698c-0.393,0.333-1.169,1.007-1.562,1.34
	c1.246,1.109,2.662,1.417,3.473-0.29C348.8,771.389,348.16,771.039,347.529,770.698 M292.216,774.111
	c0.896-0.35,1.801-0.691,2.705-1.033c0.179-0.452,0.546-1.34,0.725-1.783C293.948,771.355,292.309,772.209,292.216,774.111
	 M1631.42,771.867c-0.273,1.169,0.205,1.57,1.425,1.203C1633.058,771.901,1632.589,771.492,1631.42,771.867 M274.15,772.336
	c-0.777,1.101,1.178,2.261,2.014,1.399C277.086,772.55,274.995,771.389,274.15,772.336 M997.521,773.105
	c-0.427,1.212-0.862,2.423-1.246,3.669c0.606-0.256,1.826-0.751,2.432-0.998c0.29,0.077,0.879,0.239,1.169,0.324
	c-0.358-1.015-0.717-2.005-1.041-2.995C998.502,773.105,997.845,773.105,997.521,773.105 M1565.022,774.001
	c-0.239,0.256-0.725,0.768-0.964,1.033C1564.868,777.789,1568.375,773.344,1565.022,774.001 M2046.72,778.165
	c0.375-0.179,1.135-0.546,1.51-0.725c1.067,0.845,2.185,1.664,3.328,2.492c0.452-0.452,0.947-0.887,1.468-1.323
	c-2.466-0.563-3.2-2.517-2.765-4.838C2048.333,774.572,2047.317,776.262,2046.72,778.165 M272.64,776.083
	c1.161,2.278,4.429,1.203,4.813-1.092C275.857,775.383,274.244,775.724,272.64,776.083 M339.106,777.934
	c1.673,0.282,3.354,0.785,5.077,0.64c1.775,1.34,3.354-0.213,4.07-1.818c-1.212,0.29-2.423,0.589-3.635,0.896
	c-0.043-0.555-0.137-1.673-0.188-2.227c-0.811,0.358-1.613,0.734-2.406,1.126c-0.085-0.435-0.256-1.314-0.341-1.749
	C340.625,775.665,339.541,776.578,339.106,777.934 M1889.698,778.532c1.203-0.333,2.227-1.084,3.183-1.903
	c0.538-0.043,1.63-0.137,2.176-0.188c-0.026-0.452-0.068-1.357-0.085-1.809C1892.719,774.811,1889.877,775.946,1889.698,778.532
	 M212.326,775.946c-1.894,0.623,0.265,3.174,1.519,1.98C214.699,776.979,213.368,775.605,212.326,775.946 M298.684,777.508
	c-1.613,0.265-0.691,2.449,0.683,1.886C300.962,779.129,300.024,776.893,298.684,777.508 M200.926,779.871
	c0.623,0.026,1.877,0.085,2.5,0.12c-0.111,0.41-0.324,1.237-0.435,1.655c0.452-0.009,1.357-0.026,1.809-0.026
	c-0.367-1.195-0.742-2.372-1.118-3.541C202.325,778.079,200.909,778.139,200.926,779.871 M1964.808,778.182
	c0.649,2.15,2.483,3.004,4.668,2.551C1968.375,779.146,1966.746,778.199,1964.808,778.182 M196.915,779.249
	c-0.708,1.587,2.987,2.415,3.371,0.768C199.782,778.813,197.939,778.711,196.915,779.249 M1587.396,779.206
	c-0.76,1.109,1.178,2.287,2.014,1.348C1590.281,779.359,1588.198,778.216,1587.396,779.206 M215.211,780.947
	c-0.802,1.195,1.101,2.705,2.193,1.946C218.513,781.638,216.32,779.641,215.211,780.947 M2132.48,780.827
	c-2.261,1.254,1.067,2.91,2.099,1.357C2135.39,780.93,2133.316,780.102,2132.48,780.827 M1798.758,784.872
	c1.476,0.341,1.724-2.005,0.384-2.313C1797.41,781.817,1797.188,784.642,1798.758,784.872 M300.527,784.275
	c-0.717,1.118,1.246,2.295,2.091,1.417C303.428,784.497,301.38,783.387,300.527,784.275 M1557.478,784.078
	c-2.466,1.545-0.102,3.371,0.853,5.035l-1.203,0.23l-0.085,0.794c1.195-0.401,2.389-0.768,3.644-1.084
	c-0.597-0.58-1.161-1.143-1.715-1.707C1558.528,786.22,1558.997,784.386,1557.478,784.078 M995.49,788.541
	c-0.666-0.299-1.323-0.597-1.963-0.879c-0.981,0.998-2.159,2.253-3.601,1.143l-0.35,0.008c0.375,0.785,0.759,1.579,1.152,2.381
	c1.451-0.367,2.893-0.785,4.361-1.135c-0.964,1.109-1.963,2.193-2.961,3.268c0.572,0.478,1.152,0.956,1.758,1.451
	c-1.997,1.434,0.358,3.388,1.784,4.002c0.956-0.521,1.741-1.314,2.611-1.988c0.247,1.348-0.819,3.49,1.126,4.028
	c-1.05,0.939-2.466,1.655-2.987,3.029c2.125,0.111,4.258-0.06,6.4-0.179c-0.614-0.828-1.289-1.596-1.86-2.415
	c0.299-1.613,0.759-3.174,1.212-4.736c-0.23-0.657-0.435-1.297-0.64-1.929c1.126,0.051,2.27,0.102,3.422,0.154
	c0.017,0.265,0.051,0.802,0.068,1.067c-0.512,0.23-1.519,0.683-2.031,0.913c2.995-0.341,1.451,2.867,2.995,4.224
	c-0.239,0.401-0.717,1.212-0.956,1.621c-0.026,0.862-0.026,1.741-0.017,2.628c1.161,0.307,2.193,0.956,2.97,1.911
	c1.075,0.179,2.176,0.358,3.285,0.538c-0.12-1.314-0.802-2.142-2.039-2.475c0.171-1.51,0.623-3.029,0.196-4.531
	c-1.451,0.469-3.064,1.041-3.362-1.033c1.399-0.043,2.807-0.06,4.224-0.068c-0.649-4.625,0.102-9.156,0.853-13.696
	c-1.835,1.058-3.763,0.529-5.683,0.41c-1.417,1.058-1.766-1.126-2.927-1.178C1000.021,785.887,997.786,787.295,995.49,788.541
	 M2037.76,787.5c1.152,0.905,2.321-1.161,1.357-1.98C2037.982,784.744,2036.779,786.673,2037.76,787.5 M1678.583,786.92
	c0.196,1.126,0.828,1.323,1.877,0.58C1680.213,786.365,1679.59,786.169,1678.583,786.92 M2097.946,787.543
	c-0.563,0.324-1.681,0.99-2.244,1.323c0.213,0.265,0.64,0.802,0.862,1.067c0.871,0.35,1.767,0.708,2.697,1.075
	C2098.901,789.796,2098.509,788.635,2097.946,787.543 M154.3,788.456c-0.452,1.323,0.034,1.818,1.451,1.476
	C156.203,788.584,155.716,788.089,154.3,788.456 M305.886,791.921c0.708-0.017,2.125-0.051,2.833-0.068
	c-0.026-0.717-0.077-2.15-0.102-2.867C307.388,789.659,305.929,790.282,305.886,791.921 M301.722,790.316
	c0.171,1.476,1.34,2.398,2.33,3.388c-1.075-0.256-2.142-0.512-3.2-0.76c0.239,0.529,0.725,1.579,0.964,2.108
	c-0.418-0.068-1.263-0.205-1.681-0.273l0.077,1.596c1.033-0.23,2.031-0.555,2.995-0.99c0.614,0.043,1.852,0.145,2.475,0.196
	c-0.119-1.835-0.29-3.652-0.461-5.453C304.051,790.171,302.882,790.24,301.722,790.316 M1534.31,790.367
	c-0.853,0.905,0.299,2.645,1.451,1.98C1536.734,791.519,1535.309,789.267,1534.31,790.367 M1679.949,791.187
	c0.051,0.649,0.154,1.937,0.205,2.577c0.657-0.802,1.323-1.596,1.997-2.398c0.205,0.418,0.606,1.254,0.802,1.672
	c1.016-0.529,1.016-1.399,0.606-2.321C1682.355,790.888,1681.109,791.025,1679.949,791.187 M2129.536,793.644
	c0.256,1.613,3.03,0.751,2.406-0.7C2131.294,791.869,2129.084,792.108,2129.536,793.644 M297.848,795.88
	c1.118-0.111,2.202-2.867,0.649-3.174C296.73,792.671,296.704,795.027,297.848,795.88 M2065.135,792.962
	c-1.032-0.111-2.415,0.998-1.724,2.057C2064.546,796.17,2067.055,793.465,2065.135,792.962 M2133.146,795.846
	c0.99,0.461,2.073-1.203,1.476-2.031C2133.555,793.294,2132.446,794.993,2133.146,795.846 M1580.945,800.275
	c1.109,0,2.236,0,3.388,0c-0.034-0.64-0.094-1.92-0.128-2.56C1582.874,798.244,1581.833,799.199,1580.945,800.275 M187.853,801.546
	c-0.802,2.313,2.876,0.998,4.156,1.485C192.154,800.522,189.44,801.196,187.853,801.546 M1629.688,801.435
	c-1.016,1.314,1.34,2.526,2.219,1.382C1632.674,801.555,1630.507,800.351,1629.688,801.435 M1935.514,801.597
	c-0.947,0-2.586,0.734-2.039,1.886C1934.396,804.191,1937.161,802.22,1935.514,801.597 M1955.9,801.171
	c-0.333,0.751-0.631,1.519-0.913,2.295c0.23,0.512,0.683,1.536,0.913,2.057c0.333,0.009,1.007,0.017,1.34,0.026
	c-0.009-1.075,0.009-2.133,0.06-3.191C1956.949,802.067,1956.25,801.469,1955.9,801.171 M1972.309,802.468
	c0.862,0.435,1.553,1.621,2.705,1.331c0.085-0.708,0.265-2.125,0.35-2.833C1974.315,801.444,1973.299,801.939,1972.309,802.468
	 M1393.271,803.441c0.734,0.563,2.517-0.239,2.159-1.246C1394.62,801.435,1392.666,802.271,1393.271,803.441 M1689.984,802.775
	c-1.459,1.246,0.512,2.987,1.724,1.647C1692.902,803.304,1690.94,801.137,1689.984,802.775 M1949.193,801.905
	c0.094,0.401,0.299,1.212,0.401,1.613c-0.23,0.435-0.683,1.323-0.913,1.758c0.665,0.034,1.997,0.111,2.662,0.145
	C1951.181,803.901,1950.916,802.254,1949.193,801.905 M197.598,802.971c0.375,0.862,0.759,1.724,1.143,2.603
	c0.606,0.009,1.818,0.026,2.423,0.043c-0.384-0.887-0.759-1.775-1.135-2.645C199.424,802.971,198.212,802.971,197.598,802.971
	 M341.265,804.029c-0.461,1.382,0.009,1.843,1.408,1.365C343.117,803.987,342.648,803.534,341.265,804.029 M1961.719,803.773
	c-0.427,0.768-0.836,1.536-1.212,2.321c1.28,0.154,2.483,0.58,3.601,1.28c1.306-0.93,0.555-2.398,0.06-3.55
	C1963.554,803.816,1962.334,803.79,1961.719,803.773 M265.967,807.596c0.956,0.503,1.92,1.015,2.876,1.545
	c0.751-0.649,1.485-1.306,2.236-1.946c-0.017-0.631-0.06-1.911-0.077-2.543C269.278,805.548,267.563,806.478,265.967,807.596
	 M2096.034,804.49c-0.649,1.169-1.28,2.338-1.929,3.499c1.092,0.043,2.202,0.085,3.345,0.145
	C2097.374,806.828,2097.869,804.635,2096.034,804.49 M2119.68,804.763C2119.859,807.545,2122.249,803.432,2119.68,804.763
	 M342.818,806.538c0.384,0.768,0.759,1.553,1.135,2.355c1.306,0.009,2.62,0.034,3.934,0.094c-0.444-1.169-0.785-2.372-1.058-3.567
	C345.54,805.958,344.218,806.436,342.818,806.538 M1943.117,805.617c-0.478,1.246,1.596,2.816,2.782,2.364
	C1946.283,806.598,1944.448,804.831,1943.117,805.617 M1952.546,805.745c-1.195,0.725-0.307,3.277,1.109,2.21
	C1954.739,807.204,1953.911,804.644,1952.546,805.745 M1969.809,806.76c0.657,1.63,2.193,2.517,3.669,3.336
	c0.009-1.527,0.034-3.038,0.094-4.548C1972.318,805.966,1971.021,806.342,1969.809,806.76 M192.171,806.905
	c-1.033,1.067,1.015,1.963,1.673,1.007C194.671,806.871,192.811,805.881,192.171,806.905 M1394.065,806.675
	c-1.084,1.306,1.954,3.14,2.517,1.519C1396.429,807.118,1395.063,806.188,1394.065,806.675 M1790.071,806.53
	c-0.418,1.212-0.017,1.562,1.212,1.067C1791.659,806.376,1791.257,806.026,1790.071,806.53 M188.186,807.912
	c0.316,1.732,1.195,3.516,0.393,5.274c1.911,1.118,4.224,1.289,6.153,2.432c-1.271,0.085-2.543,0.154-3.814,0.213
	c0.009,0.452,0.034,1.348,0.051,1.801c1.51-0.128,3.012-0.401,4.378-1.101c1.118-0.051,2.236-0.196,3.243-0.725
	c0.085,0.64,0.247,1.911,0.333,2.543c0.896-0.265,1.783-0.58,2.662-0.93c-0.111,0.427-0.333,1.297-0.444,1.732
	c1.63,0.085,3.26,0.188,4.907,0.265c-1.169-2.219-4.326-3.669-3.183-6.648c1.596,1.126,2.611,3.174,4.787,3.294
	c0.179-1.715,0.299-3.473-1.041-4.753c-0.418-1.152-0.845-2.295-1.289-3.422c-1.254,0.631-2.509,1.263-3.763,1.903
	c-1.126-0.879-2.449-1.451-3.797-1.894c0.922,1.852,0.503,3.123-1.246,3.823c-0.077-0.555-0.213-1.656-0.29-2.21
	c-0.939-1.937-2.85,0.128-3.951,0.802C190.925,809.465,189.56,808.671,188.186,807.912 M207.087,807.75
	c0.111,1.51,0.265,3.046,0.435,4.565c0.452,0.162,1.365,0.478,1.826,0.64c1.178-1.152,2.449-2.236,4.019-2.799
	c-0.572-1.621-2.534-3.243-3.243-0.674C209.152,808.825,208.154,808.211,207.087,807.75 M214.255,808.33
	c-0.922,1.007,0.777,2.953,1.835,1.92C217.131,809.192,215.347,807.391,214.255,808.33 M180.344,808.808
	c0.836,2.287,3.106,3.831,5.555,3.635C185.182,809.96,182.844,808.689,180.344,808.808 M1688.858,809.917
	c-0.742,1.613,2.79,2.569,3.106,0.811C1691.631,809.465,1689.771,809.235,1688.858,809.917 M2092.561,810.19
	c0.111,0.794,0.265,1.596,0.452,2.415c0.99-0.785,2.193-1.451,2.765-2.662C2094.677,810.003,2093.602,810.088,2092.561,810.19
	 M1775.036,815.609c1.058,0.572,2.662-1.067,2.065-2.133C1775.974,812.895,1774.66,814.577,1775.036,815.609 M1850.001,813.254
	c-0.452,1.348,0.043,1.784,1.476,1.306C1851.904,813.186,1851.409,812.75,1850.001,813.254 M187.793,815.797
	C187.878,818.596,190.404,814.491,187.793,815.797 M1502.31,820.678c1.365,0.273,2.773,0.649,4.198,0.87
	c0.555-0.623,1.033-1.297,1.442-2.031c-1.024-0.341-2.031-0.674-3.012-1.007C1504.043,819.227,1503.164,819.944,1502.31,820.678
	 M1516.271,818.698c-0.461,1.391,0.017,1.843,1.417,1.357C1518.089,818.638,1517.619,818.186,1516.271,818.698 M196.676,819.253
	c0.802,0.964,1.664,1.869,2.534,2.782c0.435-0.879,0.87-1.749,1.297-2.62c-0.35,0.111-1.05,0.341-1.399,0.461
	C198.494,819.722,197.282,819.406,196.676,819.253 M327.663,819.5c-1.041,1.135,1.041,3.123,2.176,2.116
	C330.641,820.396,328.849,818.604,327.663,819.5 M1959.176,819.185c-0.264,1.161-0.478,2.338-0.674,3.516
	c1.715-0.64,3.072-1.929,4.326-3.234C1961.591,819.364,1960.371,819.27,1959.176,819.185 M1395.089,820.413
	c-1.323,1.169,0.905,2.995,1.997,1.758C1398.357,820.977,1396.139,819.432,1395.089,820.413 M1961.028,822.794
	c0.316,1.126,0.64,2.261,0.981,3.405c0.504-0.171,1.51-0.521,2.005-0.691c-0.469-1.092-1.195-2.048-1.809-3.004
	C1961.916,822.572,1961.327,822.726,1961.028,822.794 M1741.491,824.851c1.109,1.075,2.662,1.417,4.113,1.869
	c-0.99-1.263-2.022-2.483-3.038-3.678C1742.165,823.622,1741.807,824.228,1741.491,824.851 M1499.332,825.192
	c-1.237,0.307-0.922,2.517,0.444,1.69C1500.962,826.532,1500.706,824.347,1499.332,825.192 M1971.994,826.318
	c-1.51,0.23-0.785,2.458,0.563,2.048C1974.007,828.068,1973.333,825.875,1971.994,826.318 M1875.507,826.924
	c-0.452,1.382,0.026,1.818,1.442,1.323C1877.367,826.847,1876.89,826.412,1875.507,826.924 M186.837,829.638
	c-1.297,0.196-1.51,2.884,0.179,1.963C188.518,831.49,188.382,829.237,186.837,829.638 M1248.418,830.073
	c0.068,1.314,0.026,2.628-0.119,3.959c1.092-0.427,1.732-1.417,2.517-2.244c1.161,0.93,2.79,3.046,3.678,0.631
	C1252.429,831.729,1250.381,830.978,1248.418,830.073 M1522.662,835.381c1.792-0.828,3.738-1.152,5.726-1.237
	c-0.734-0.939-1.433-2.048-2.679-2.261C1524.079,832.351,1523.046,833.811,1522.662,835.381 M1744.265,832.002
	c-1.596,0.299-1.391,2.995,0.375,2.415C1746.253,834.143,1746.108,831.191,1744.265,832.002 M1843.183,833.555
	c0.375,1.724,3.106,1.084,2.509-0.666C1845.367,831.285,1842.79,832.13,1843.183,833.555 M1959.808,832.625
	c0.094,0.657,0.273,1.963,0.359,2.62c0.555-0.06,1.672-0.179,2.227-0.239C1961.847,833.811,1961.464,832.292,1959.808,832.625
	 M1955.746,833.205c-0.051,0.606-0.154,1.835-0.205,2.441c-0.495-0.188-1.485-0.563-1.988-0.751
	c-0.299,0.128-0.905,0.393-1.212,0.529c-0.392-0.265-1.195-0.777-1.587-1.033c-0.606,0.947-1.152,1.929-1.681,2.927
	c0.273,1.186-0.051,2.355-0.222,3.533c1.34-0.469,2.338-1.502,3.354-2.475c0.341,1.075,0.708,2.159,1.101,3.251
	c1.092-0.282,2.167-0.691,2.662-1.835c0.666,0.282,1.357,0.563,2.082,0.862c0.042-0.717,0.128-2.142,0.171-2.859
	c-1.186,0.384-2.338,0.794-3.473,1.203c0.017-0.631,0.051-1.886,0.068-2.517c0.887,0.247,1.8,0.512,2.739,0.776
	C1957.256,835.722,1957.803,833.529,1955.746,833.205 M1970.261,833.811c-0.042,0.614-0.128,1.826-0.171,2.441
	c0.444-0.077,1.323-0.222,1.766-0.299c-0.802,2.44-1.417,4.941-2.133,7.407c0.887-0.683,1.826-1.357,2.756-2.039
	c0.128,1.126,0.768,1.826,1.911,2.082c0,0.299,0.009,0.887,0.017,1.186c-0.606,0.162-1.818,0.486-2.423,0.657
	c1.195,0.401,2.5,0.87,3.755,1.314c0.904-1.502,2.27-3.038,0.708-4.625c-0.119-1.229-0.281-2.466-0.358-3.678
	c1.766-0.034,1.937-1.459,1.928-2.91c-0.384,0.171-1.169,0.503-1.553,0.666c-1.101-0.802-2.201-1.562-3.277-2.321
	C1972.19,833.717,1971.208,833.759,1970.261,833.811 M1250.074,835.125c-0.06,1.22-1.135,2.739-0.06,3.814
	c1.152-1.596,2.662-0.597,4.036-0.102c0.034-0.341,0.111-1.033,0.145-1.374C1252.736,836.797,1251.396,835.97,1250.074,835.125
	 M1434.283,835.5C1434.342,838.274,1436.911,834.246,1434.283,835.5 M1960.875,836.473
	C1961.344,836.959,1961.344,836.959,1960.875,836.473 M1390.575,838.077c0.162,1.818,3.934,0.99,3.166-0.64
	C1392.768,836.866,1390.968,836.806,1390.575,838.077 M1388.177,837.617c-0.512,1.877,0.538,3.413,1.647,4.821
	C1392.102,841.004,1388.937,839.076,1388.177,837.617 M1960.542,841.311c1.152-0.478,2.355-0.887,3.618-1.152
	c-0.009-0.589-0.017-1.775-0.026-2.364C1961.907,837.497,1960.311,839.093,1960.542,841.311 M1946.325,843.291
	c-0.504,1.365,1.331,2.236,1.911,3.345c0.401-0.461,0.836-0.904,1.306-1.34c-0.256-0.597-0.76-1.801-1.016-2.406
	c0.896,0.256,1.801,0.538,2.731,0.828c0.017-0.649,0.06-1.937,0.077-2.577C1949.594,841.695,1947.742,842.105,1946.325,843.291
	 M1390.857,844.904c0.247,0.444,0.742,1.323,0.99,1.766c1.101-1.126,2.364-2.236,2.594-3.9
	C1392.981,843.018,1391.906,843.974,1390.857,844.904 M1979.708,844.75c-0.862,1.425-1.707,2.859-2.517,4.301
	c1.246-0.307,2.517-0.623,3.806-0.922c-0.205-1.28,0.452-2.347,1.067-3.388C1981.474,844.75,1980.296,844.75,1979.708,844.75
	 M1327.044,846.594c-0.674,1.126,1.297,2.27,2.142,1.374C1329.937,846.747,1327.863,845.689,1327.044,846.594 M1873.894,846.628
	c-0.435,1.041,1.263,2.091,2.151,1.485C1876.437,847.046,1874.714,846.065,1873.894,846.628 M1293.508,847.566
	c-0.7,1.203,1.084,3.2,2.364,2.603C1296.58,848.923,1294.746,847.106,1293.508,847.566 M1942.553,847.345
	c-0.068,1.809,2.279,2.577,3.618,1.604C1947.23,846.32,1943.962,847.549,1942.553,847.345 M1444.557,850.075
	c-1.493,0.273-1.152,3.055,0.495,2.287C1446.545,851.995,1446.05,849.708,1444.557,850.075 M1484.604,852.217
	c0.973,0.64,2.176-1.109,1.22-1.801C1484.774,849.691,1483.742,851.466,1484.604,852.217 M1757.346,853.036
	c1.084-0.213,2.168-0.444,3.302-0.64c0.572,1.289,1.442,2.415,2.381,3.499c0.461-0.41,0.947-0.819,1.459-1.212
	c-1.681-0.939-3.533-2.005-3.772-4.122C1759.078,850.579,1757.833,851.492,1757.346,853.036 M1777.442,851.927
	c0.23,0.35,0.674,1.041,0.905,1.382c0.572-0.017,1.715-0.06,2.295-0.085c-0.009-0.666-0.026-1.997-0.034-2.662
	C1779.533,850.997,1778.475,851.458,1777.442,851.927 M1441.178,851.509c-0.393,1.348,0.111,1.835,1.51,1.442
	C1443.063,851.569,1442.56,851.082,1441.178,851.509 M1219.465,854.077c0.802,0.845,2.97-0.094,2.176-1.331
	C1220.83,851.842,1218.799,852.917,1219.465,854.077 M1298.21,853.557c0.196,1.186,1.067,2.014,1.988,2.739
	c0.009-1.4-0.009-2.799-0.043-4.181C1299.439,852.516,1298.79,852.994,1298.21,853.557 M1479.219,853.147
	c0.495,3.61,6.349,2.415,6.733,6.46c2.364-1.442,0.555-4.395,0.051-6.374C1483.759,854.146,1481.455,853.813,1479.219,853.147
	 M1757.559,855.391c1.459,0.828,2.901,1.724,4.403,2.551c-0.026-0.444-0.068-1.323-0.085-1.766c-1.229-0.256-1.92-1.015-2.082-2.27
	C1759.027,854.385,1758.276,854.879,1757.559,855.391 M1025.34,855.298c0.299,0.93,0.427,2.091,1.527,2.475
	c0.631-0.896,1.118-1.852,1.468-2.884C1027.584,854.99,1026.091,855.195,1025.34,855.298 M1040.998,856.014
	c-0.478,1.365-0.017,1.852,1.4,1.434C1042.85,856.057,1042.389,855.579,1040.998,856.014 M1756.928,857.764
	c-0.282,1.297,1.903,1.63,2.313,0.486C1759.548,856.851,1757.227,856.381,1756.928,857.764 M1029.931,859.675
	c-1.741-0.862-2.773,0.7-2.91,2.313c-0.367-0.247-1.109-0.742-1.476-0.99c-0.077,0.461-0.23,1.365-0.307,1.818
	c0.631-0.12,1.895-0.358,2.526-0.478l0.648,1.229c2.594-0.538,4.907-2.116,7.654-1.929
	C1035.375,859.334,1031.535,857.218,1029.931,859.675 M1064.866,858.677c-1.203,1.135,0.708,3.371,1.869,1.937
	C1067.836,859.513,1065.975,857.695,1064.866,858.677 M1417.429,858.506c-1.536,0.333-1.075,2.662,0.486,2.167
	C1419.494,860.435,1419.085,857.678,1417.429,858.506 M1762.142,858.549C1762.611,859.044,1762.611,859.044,1762.142,858.549
	 M1043.942,860.187c0.256,0.939,0.76,1.707,1.519,2.313c1.527,0.563,1.109-1.195,1.34-2.116
	C1046.084,860.341,1044.651,860.238,1043.942,860.187 M1063.313,860.955c-0.136,1.058-0.247,2.125-0.341,3.209
	c0.495,0.35,1.485,1.058,1.971,1.408c-0.256,1.681-2.568,4.907,0.478,5.035c0.094-1.545,0.41-3.046,0.751-4.54
	C1066.044,864.019,1064.9,862.201,1063.313,860.955 M1728.802,861.979c-0.401,0.811-0.793,1.638-1.169,2.466
	c0.316,0.435,0.947,1.297,1.255,1.724c0.708-0.376,1.434-0.734,2.184-1.084c0.657-1.306,1.869-2.492,1.809-4.036
	C1731.448,861.092,1730.116,861.561,1728.802,861.979 M1415.458,862.559c0.051,1.152,0.128,2.313,0.222,3.482
	c1.442-0.393,2.876-0.768,4.369-1.092C1419.307,862.875,1417.472,862.397,1415.458,862.559 M1032.149,864.3
	c0.495,1.195,1.05,2.389,1.638,3.576c0.393-0.759,0.777-1.519,1.144-2.287C1034.155,864.804,1033.233,864.377,1032.149,864.3
	 M1478.536,865.256c0.316,1.289,2.253,1.357,3.2,0.691C1482.274,864.352,1478.767,863.49,1478.536,865.256 M1036.049,867.594
	c1.118,0.828,2.466-1.118,1.425-1.937C1036.365,864.923,1035.017,866.749,1036.049,867.594 M1731.277,866.348
	C1729.963,868.951,1734.042,866.485,1731.277,866.348 M1033.335,871.025c1.954,0.375,1.399-2.765-0.316-2.398
	C1031.629,868.84,1032.26,870.803,1033.335,871.025 M1201.963,873.414c0.188,2.773,0.879,5.478,1.271,8.226
	c0.572-0.06,1.715-0.188,2.287-0.247c0.768-1.041,1.775-1.843,2.893-2.517c-0.375-0.41-1.109-1.229-1.485-1.638
	c-0.853,0.657-1.63,1.399-2.432,2.108c0.068-0.905,0.213-2.697,0.282-3.593C1203.891,874.899,1202.978,874.062,1201.963,873.414
	 M1357.867,876.264c-0.094,0.828-0.162,1.664-0.222,2.517c0.589,1.109,1.007,2.219,1.374,3.448c0.555-0.333,1.656-0.998,2.21-1.331
	c0.017,1.86,1.007,3.388,2.278,4.702c0.896-0.776,1.843-1.519,2.645-2.406c-1.015-0.922-2.091-1.749-3.106-2.611
	c0.759-0.009,2.287-0.017,3.046-0.026c-0.418-1.536-0.862-3.081-1.22-4.608C1362.517,875.829,1360.171,875.846,1357.867,876.264
	 M1778.202,876.622c-0.87,0.845,0.196,2.944,1.416,2.142C1780.437,877.919,1779.302,875.94,1778.202,876.622 M1152.674,883.825
	C1151.411,886.444,1155.456,883.935,1152.674,883.825 M1102.285,886.146c0.239,0.828,0.845,1.357,1.809,1.579
	c-0.452,1.033-0.853,2.074-1.186,3.166c0.708-0.077,2.125-0.213,2.833-0.282c-0.427-0.794-0.836-1.57-1.255-2.338
	c0.026-0.649,0.085-1.946,0.111-2.594C1104.017,885.796,1102.865,886.026,1102.285,886.146 M1075.951,889.252
	c-0.051,1.613-0.102,3.243-0.162,4.881c1.007-0.316,2.065-0.239,3.132-0.23c1.101-1.399,0.273-3.098,0.12-4.634
	C1077.999,889.252,1076.966,889.243,1075.951,889.252 M1092.053,891.291c-0.879,3.337-0.666,6.81-0.794,10.231
	c1.639,0.41,2.594,1.647,3.166,3.2c2.168-2.388,0.793-1.873,0.512-11.221c0.563,2.97,0.222,6.545,2.842,8.636
	c0.077-0.734,0.23-2.202,0.307-2.935c0.768,1.818,2.526,1.937,4.292,1.826c-1.476-2.133-2.748-4.437-3.191-7.014
	c0.674,0.043,2.005,0.137,2.679,0.188c-0.58-1.647-1.271-3.311-0.563-5.052C1097.762,888.458,1095.484,891.624,1092.053,891.291
	 M1173.077,894.764c2.205,0.968,4.66,3.559,6.784,3.669c0.606-1.502,0.922-3.098,1.331-4.634
	C1178.539,892.255,1175.219,892.537,1173.077,894.764 M1077.982,895.481c-0.913,1.28-1.801,2.586-2.449,4.028
	c1.749,0.009,3.234-0.811,4.463-1.997c0-0.58,0-1.741,0.009-2.321C1079.501,895.259,1078.485,895.413,1077.982,895.481z"
        />
      </svg>
    </div>
  );
}
